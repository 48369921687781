/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';
import { IDeviceCategory, IDFField } from '../../../../../config/deviceConfig/types';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';
import { appConsoleLog } from '../../../../../utility/appUtils';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.ir_remote;
type ComponentProps = {
  cat: IDeviceCategory;
  fieldConfig: IDFField;
  validateForm: number;
  deviceInfo: any;
  resetForm: IFormResetRef;
  onChange: (payload: any, isInitializing?: boolean) => void;
};
interface IformData {
  device: string;
  version: string;
  have_custom_device: boolean;
  custom_device: string;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldRemoteSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const [deb, setDeb] = useState<BehaviorSubject<{values: IformData; errors: any} | null>>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};

  const [deviceList, setDeviceList] = useState<any[]>([]);
  const [deviceKey, setDeviceKey] = useState<string>('');
  const [versionsList, setVersionsList] = useState<any[]>([]);

  const formRef = useRef<any>();
  // get remote names and version from config file
  // create  interface type for remotes
  const sendDefaults = () => {
    if (!props.fieldConfig || !deb) {
      return;
    }
    deb.next({
      values: {
        device: props.fieldConfig.default.device,
        version: props.fieldConfig.default.version,
        have_custom_device: props.fieldConfig.default.have_custom_device,
        custom_device: props.fieldConfig.default.custom_device,
      },
      errors: {},
    });
  };

  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      // appConsoleLog(props.resetForm);
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        // appConsoleLog({ name: props.resetForm.info.name });
        const values = props.resetForm.info.settings;
        if (values) {
          formRef.current.setValues({
            device: values.device,
            version: values.key_map_version,
            have_custom_device: values.have_custom_device,
            custom_device: values.custom_device,
          });

          if (values.device) {
            // appConsoleLog("device key ::", values);
            setDeviceKey(values.device);
          }
        }
      } else {
        formRef.current.resetForm();
      }
    }
  }, [props.resetForm]);

  useMemo(() => {
    const irDefaults: any = ITEM_DEFAULTS.IR_DEFAULTS;
    const deviceObjs = irDefaults[props.cat.subCategory];
    if (deviceObjs) {
      const devices = Object.keys(deviceObjs).map((key) => ({
        ...deviceObjs[key],
        device_key: key,
      }));
      // appConsoleLog("remote devices", devices);
      setDeviceList(devices);
    }
  }, [props.cat.subCategory]);
  useEffect(() => {
    if (deviceKey === '') {
      return;
    }
    const irDefaults: any = ITEM_DEFAULTS.IR_DEFAULTS;
    if (!irDefaults[props.cat.subCategory][deviceKey.toUpperCase()]) {
      // appConsoleLog(
      //   irDefaults,
      //   props.cat.subCategory,
      //   irDefaults[props.cat.subCategory][deviceKey.toUpperCase()]
      // );
      return;
    }
    const versionsObj = irDefaults[props.cat.subCategory][deviceKey.toUpperCase()].versions;
    const devices = Object.keys(versionsObj).map((key) => ({
      ...versionsObj[key],
      version_key: key,
    }));

    setVersionsList(devices);
  }, [props.cat.subCategory, deviceKey]);

  useEffect(() => {
    if (
      formRef
      && formRef.current
      && versionsList
      && versionsList.length > 0
      && formRef.current.values
    ) {
      const deviceRef = formRef.current.values.device;
      if (deviceRef) {
        const vkey = versionsList[versionsList.length - 1].version_key;
        formRef.current.setValues({
          ...formRef.current.values,
          version: vkey,
        });
      }
    }
  }, [versionsList]);
  const initilized = useRef(0);
  useEffect(() => {
    const b = new BehaviorSubject<{values: IformData; errors: any} | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }
        const irDefaults: any = ITEM_DEFAULTS.IR_DEFAULTS;
        const { values, errors } = v;
        const infoData: any = {
          settings: {},
        };

        if (values.device) {
          infoData.settings.key_map_version = values.version;

          const deviceItem = irDefaults[props.cat.subCategory][values.device.toUpperCase()];

          if (deviceItem) {
            infoData.settings.shadow_type = deviceItem.shadow_type;
            infoData.settings.device = deviceItem.device;
          }
          infoData.settings.have_custom_device = values.have_custom_device;

          if (values.have_custom_device === true) {
            infoData.settings.custom_device = values.custom_device;
          } else {
            infoData.settings.custom_device = '';
          }
        }

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    sendDefaults();
    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);

  let isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;
  if (isEditable) {
    if (props.deviceInfo.settings[`has_${props.fieldConfig.field}`] === false) {
      isEditable = false;
    }
  }

  return isEditable === true ? (
    <div>
      <Formik
        initialValues={{
          device: props.fieldConfig.default.device,
          version: props.fieldConfig.default.version,
          have_custom_device: props.fieldConfig.default.have_custom_device,
          custom_device: props.fieldConfig.default.custom_device,
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          device: Yup.string().required('Required'),
          version: Yup.string().required('Required'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values, setFieldValue,
        }) => {
          deb?.next({
            values,
            errors,
          });
          return (
            <form>
              <div className="row">
                <div className="text-fields-flex">
                  <div>
                    <FormControl
                      size="small"
                      variant="outlined"
                      className="dynamic-ui-form-control"
                    >
                      <InputLabel
                        id="device-label1"
                        error={Boolean(touched.device && errors.device)}
                      >
                        Device:
                      </InputLabel>

                      <Select
                        labelId="device-label1"
                        value={values.device || ''}
                        name="device"
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          setDeviceKey(e.target.value);
                          handleChange(e);
                        }}
                        label="Device"
                      >
                        {deviceList.map((item) => (
                          <MenuItem key={item.device_key} value={item.device_key.toLowerCase()}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl
                      size="small"
                      variant="outlined"
                      className="dynamic-ui-form-control col-md-2"
                    >
                      <InputLabel
                        id="device-v-label1"
                        error={Boolean(touched.version && errors.version)}
                      >
                        Remote Version:
                      </InputLabel>

                      <Select
                        labelId="device-v-label1"
                        value={versionsList.length > 0 ? values.version || '' : ''}
                        name="version"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        label="Remote Version"
                      >
                        {versionsList.map((item) => (
                          <MenuItem key={item.version_key} value={item.version_key}>
                            {item.version_key}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row dynamic-ui-form-control mt-1">
                  <div>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={values.have_custom_device}
                          onChange={(event) => {
                            // checked={values.have_custom_device}
                            // console.log('tv1', event.target.value);
                            // console.log('tv', values.have_custom_device);
                            // handleChange(event);
                            // console.log(event.target.checked);
                            setFieldValue('have_custom_device', event.target.checked);
                          }}
                          name="have_custom_device"
                          color="primary"
                        />
                      )}
                      label="Set Custom device"
                    />
                  </div>
                  <div>
                    {values.have_custom_device === true ? (
                      <TextField
                        error={Boolean(touched.custom_device && errors.custom_device)}
                        helperText={touched.custom_device && errors.custom_device}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values?.custom_device}
                        name="custom_device"
                        label="Custom device"
                        size="small"
                        variant="outlined"
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
