/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Reusable material ui snackbars.
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define two material ui snackbard configurations to show feedbacks
 * @author Mayantha
 * @since 1.0.0
 */

import React, { Fragment } from 'react';
import { Button } from '@mui/material';

export const SuccessSnackbarOptions: any = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  variant: 'success',
  preventDuplicate: true,
  autoHideDuration: 3000,
};

export const ErrorSnackbarOptions: any = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  variant: 'error',
  preventDuplicate: true,
  autoHideDuration: 3000,
};

/**
 * Use for show success feedbacks.
 * @param {Function} func Callback function to call when user click snackbar action
 * @returns JSX.Element
 */
export const SuccessSnackbarActions = (func: () => void) => (
  <Button
    onClick={() => {
      try {
        func();
        // eslint-disable-next-line no-empty
      } catch (err) {

      }
    }}
  >
    Dismiss
  </Button>
);

/**
 * Use for show error feedbacks.
 * @param {Function} func Callback function to call when user click snackbar action
 * @returns JSX.Element
 */
export const ErrorSnackbarActions = (func: () => void) => (
  <Button
    onClick={() => {
      try {
        func();
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }}
  >
    Dismiss
  </Button>
);
