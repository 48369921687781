/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Alert, AlertTitle, Box, Button,
} from '@mui/material';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { projectGetGraphQL } from 'src/services/location/location.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import useRestoreHelpers from 'src/containers/app/Backups/components/Restore/shared/hooks/useRestoreHelpers';
import NavBar from './NavBar';
import TopBar from './TopBar';

const RootWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  // border: '2vh solid linear-gradient(62.87deg, #021518 6.27%, #05363D 89.75%);',
  border: '2vh solid transparent',
  borderImage: 'linear-gradient(62.87deg, #021518 6.27%, #05363D 89.75%)',
  borderImageSlice: 1,
  backgroundImage:
    'url("https://smartlife-pro2-develop.web.app/static/media/background-img.4f95509e.png")',
  backgroundSize: 'cover',
  borderRadius: 10,
  backgroundColor: 'transparent',
  backgroundOrigin: 'content-box',
  backgroundPosition: '0 2%',
  // background: 'linear-gradient(62.87deg, #021518 6.27%, #05363D 89.75%) !important',
}));

const RootWrapperChild = styled('div')(({ theme }) => ({}));

const Root = styled('div')(({ theme }) => ({
  background: 'transparent',
  // display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '80%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '20px',
  paddingBottom: '20px',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  // paddingTop: 64,
  // paddingLeft: 256,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256,
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 70,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
  },
  margin: '20px 0 0 0',
  position: 'relative',
}));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  // wrapper: {
  //   display: 'flex',
  //   flex: '1 1 auto',
  //   overflow: 'hidden',
  //   paddingTop: 64,
  //   paddingLeft: 256,
  //   [theme.breakpoints.up('lg')]: {
  //     paddingLeft: 256,
  //   },
  // },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    flexDirection: 'row',
    height: '100%',
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { fetchSession } = useRestoreHelpers();
  const onStartRestore = async () => {
    try {
      await fetchSession(
        selectedProject.selected_project.restore_bsid.split('S-')[1],
        'lvar',
        false,
        true,
      );
      const res = await projectGetGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
      );
      const { project } = res.projectGet;

      if (!project.restore_bsid || project.restore_bsid.length === 0) {
        enqueueSnackbar('Restore session is already finished. Aborting the request', {
          variant: 'info',
        });

        await selectedProject.dispatch({
          selected_project: {
            id: project.id,
            title: project.name,
            description: project.description,
            project_country_code: project.project_country_code,
            project_timezone: project.project_timezone,
            image: project.image,
            project_status: project.project_status,
            project_type: project.project_type,
            access_level: project.access_level,
            demo_mode: project.demo_mode,
            restore_bsid: project.restore_bsid,
          },
        });
      } else {
        navigate(
          `/app/backup-restore/restore-sessions/restoring?projectId=${
            selectedProject.selected_project.id
          }&sessionId=${selectedProject.selected_project.restore_bsid.split('S-')[1]}`,
        );
      }
    } catch (err: any) {
      enqueueSnackbar(err, { variant: 'error' });
    }
  };

  return (
    <RootWrapper>
      <Root>
        {selectedProject
          && selectedProject.selected_project
          && selectedProject.selected_project.restore_bsid && (
            <>
              <Alert severity="error">
                The location configuration steps are not completed. Please
                {' '}
                <Button size="small" color="primary" onClick={onStartRestore}>
                  click here to
                  {' '}
                </Button>
                {' '}
                finish setting up the location
              </Alert>
              <Box height={10} />
            </>
        )}
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        {/* <Box height={10} style={{ background: 'transparent' }} /> */}
        <ContentWrapper>
          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </ContentWrapper>
        <Box height={10} />
      </Root>
    </RootWrapper>
  );
};

export default DashboardLayout;
