/* eslint-disable react/jsx-fragments */
import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ApolloAuthContext } from '../store/Apollo/ApolloContext';
import { AuthContext } from '../store/Auth/AuthContext';

const AuthGuard: React.FC<{children: React.ReactChild}> = ({ children }) => {
  const user = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const navigate = useNavigate();

  console.log('AUTH GUARD', userProfile, user);

  if (user == null || userProfile == null) {
    // return <Redirect to="/login" />;
    // navigate('/login');
    return <Navigate to="/login" />;
  }

  if (userProfile.user_profile == null || userProfile.user_profile.user_name == null) {
    // return <Redirect to="/login" />;
    // navigate('/login');
    return <Navigate to="/login" />;
  }

  // eslint-disable-next-line consistent-return
  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
