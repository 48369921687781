import {Box, Grid, Skeleton} from '@mui/material';
import {cloneDeep} from 'lodash';
import {useSnackbar} from 'notistack';
import {forwardRef, useContext, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {v2InfoUpdate, v2PullDroppedItem} from 'src/store/redux/features/backup-location-config';
import {RootState} from 'src/store/redux/store';
import {Board} from 'src/containers/app/LogicsEngine/components/board';
import {Group} from 'src/containers/app/LogicsEngine/components/board/Group';
import {Card} from 'src/containers/app/LogicsEngine/components/board/Card';
import {DropResult, ResponderProvided} from 'react-beautiful-dnd';
import {
  DEVICE_POSITIONS,
  removeDevice,
  updateConfigurableContainer,
} from 'src/store/redux/features/configurable-containers';
import ConfigurableDeviceContainer from '../ConfigurableDeviceContainer';
import DeviceContainer, {DEVICE_CONTAINER_LOC} from '../shared/DeviceContainer';
import Device, {DEVICE_STATUS_IN_RESTORE_STEP} from '../shared/Device';
import useHideSelectedDevices from '../shared/hooks/useHideSelectedDevices';
import {useRestoreType} from '../shared/hooks/useRestoreType';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { RestoreDone } from '../shared/RestoreDoneScreen';

export const SessionRestoreAirDevices = forwardRef((props, ref) => {
  const sessionId = useQueryStringFirst('sessionId');
  const auth = useContext(AuthContext);

  const {
    checkedDevicesState,
    allSelectedState,
    hiddenItems,
    showCheckBoxState,
    configurableContainerRef,
    configurableList,
    devicePendingComp,
    isStepCompleted,
  } = useRestoreType('airdc', sessionId);

  const {checkedDevices, setCheckDevices} = checkedDevicesState;
  const {allSelected, setAllSelected} = allSelectedState;

  const airdc = useSelector((state: RootState) => state.backup_location_config.info.v2.airdc);
  const [_, groupCheckedDevicesByContId, showHiddens, setShowHiddens, forceHide] =
    useHideSelectedDevices(airdc, checkedDevices, setCheckDevices);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const {showCheckBox, setShowCheckBox} = showCheckBoxState;
  const airDevicePendingComp = devicePendingComp;

  useImperativeHandle(ref, () => ({
    getConfigurableContainerRef() {
      return configurableContainerRef;
    },
  }));

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    try {
      console.log(result);
      const {destination} = result;
      if (!destination) return;

      const {droppableId} = destination;

      // dropped onto a container. so it is a dropped as a new device
      if (droppableId.indexOf('configContainer') > -1) {
        const configurableContainerId = droppableId.split('configContainer');
        const container = configurableList[configurableContainerId[1]];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_air_device': {
            const deviceRoot =
              airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = {...deviceRoot.data, id: droppedDedviceId};
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  toExisting: container.deviceMap.toExisting,
                  asNew: {
                    ...container.deviceMap.asNew,
                    [droppedDedviceId]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            })
          );

          dispatch(
            v2InfoUpdate({
              value: {
                ...airDevicePendingComp,
                pendingComps: {
                  ...airDevicePendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: null,
                      },
                    },
                  },
                },
              },
              key: 'airdc',
              mergeErrorComps: false,
            })
          );
        }
      }
      // dropped on to the existng item. So update existing item list
      if (droppableId.indexOf('configExistingDevice') > -1) {
        const existingDeviceId = droppableId.split('configExistingDevice')[1];
        const configurableContainerId = existingDeviceId.split('_')[1];
        const container = configurableList[configurableContainerId];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_air_device': {
            const deviceRoot =
              airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = {...deviceRoot.data, id: droppedDedviceId};
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  asNew: container.deviceMap.asNew,
                  toExisting: {
                    ...container.deviceMap.toExisting,
                    [existingDeviceId]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            })
          );
          console.log({
            dropped_contatainer_id: container.dc.id,
            dropped_on: existingDeviceId,
          });
          dispatch(
            v2InfoUpdate({
              value: {
                ...airDevicePendingComp,
                pendingComps: {
                  ...airDevicePendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: existingDeviceId,
                      },
                    },
                  },
                },
              },
              key: 'airdc',
              mergeErrorComps: false,
            })
          );
        }
      }

      // dropped on to the availble item. So update available item list
      if (droppableId.indexOf('configAvailableDevice') > -1) {
        // eslint-disable-next-line no-debugger
        // debugger;
        const existingDeviceId = droppableId.split('configAvailableDevice')[1];
        const configurableContainerId = existingDeviceId.split('_')[1];
        const tuyaId = existingDeviceId.split('_')[0];
        const container = configurableList[configurableContainerId];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_air_device': {
            const deviceRoot =
              airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = {...deviceRoot.data, id: droppedDedviceId};
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  asNew: container.deviceMap.asNew,
                  toExisting: container.deviceMap.toExisting,
                  toAvailable: {
                    ...container.deviceMap.toAvailable,
                    [tuyaId]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            })
          );
          console.log({
            dropped_contatainer_id: container.dc.id,
            dropped_on: existingDeviceId,
          });
          dispatch(
            v2InfoUpdate({
              value: {
                ...airDevicePendingComp,
                pendingComps: {
                  ...airDevicePendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...airDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: `${DEVICE_POSITIONS.TO_AVAILABLE}_${tuyaId}`, // adding to_available flag so can identify from to_existing when pulling device
                      },
                    },
                  },
                },
              },
              key: 'airdc',
              mergeErrorComps: false,
            })
          );
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
      throw err;
    }
  };

  if (isStepCompleted) {
    return <RestoreDone />;
  }

  if (!airdc) {
    return (
      <Grid container spacing={1}>
        <Grid item md={6}>
          <Skeleton sx={{bgcolor: '#D9DFE0'}} variant="rectangular" width="100%" height={200} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Board onDragEnd={onDragEnd}>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {airdc && airdc.pendingComps && (
            <>
              {airdc.pendingComps &&
                Object.keys(airdc.pendingComps).map(contId => {
                  const container = airdc.pendingComps[contId];
                  const containerInfo = airdc.collections[contId];
                  return (
                    <>
                      <DeviceContainer
                        title={containerInfo ? containerInfo.name : 'Template Air Container'}
                        position={DEVICE_CONTAINER_LOC.IN_TEMPLATE}
                        allSelected={allSelected && allSelected[contId]}
                        onToggleAllSelect={checked =>
                          setAllSelected(state => ({
                            // ...state,
                            [contId]: checked,
                          }))
                        }
                        checkedDevices={checkedDevices[contId] ? checkedDevices[contId] : {}}
                        hideSelectedDevices={groupCheckedDevicesByContId}
                        hasHiddenItems={
                          hiddenItems &&
                          hiddenItems[contId] &&
                          Object.keys(hiddenItems[contId]).length > 0
                        }
                        showHiddenItems={showHiddens}
                        onToggleShowHiddenItems={setShowHiddens}
                        type="airdc"
                        dcId={contId}
                      >
                        {({searchText, devices}) => (
                          <Group
                            droppableId={`template_air_dc_container_${contId}`}
                            droppableType="air"
                            isDropDisabled
                          >
                            {() => (
                              <Grid container spacing={1}>
                                {Object.keys(devices).map(key => {
                                  const airDevice = devices[key];

                                  if (searchText && searchText.length > 0) {
                                    if (
                                      !(airDevice.data.name.toLowerCase().indexOf(searchText) > -1)
                                    ) {
                                      return <></>;
                                    }
                                  }
                                  return (
                                    <Grid item md={3} key={airDevice.old_id}>
                                      <Card
                                        draggableId={`template_air_device___${airDevice.old_id}`}
                                        index={1}
                                        dragDisabled={
                                          !!(
                                            airDevice.clientSideMeta &&
                                            airDevice.clientSideMeta.dropped_contatainer_id
                                          )
                                        }
                                      >
                                        {({provided}) => (
                                          <Box style={{height: '100%'}}>
                                            <Device
                                              deviceStatus={
                                                DEVICE_STATUS_IN_RESTORE_STEP.IN_TEMPLATE_DEVICE
                                              }
                                              isHidden={
                                                hiddenItems &&
                                                hiddenItems[contId] &&
                                                hiddenItems[contId][key]
                                              }
                                              device={airDevice.data as any}
                                              dragHandlerProps={{...provided.dragHandleProps}}
                                              hasError={airDevice.clone_status === 'e'}
                                              dragDisabled={
                                                !!(
                                                  airDevice.clientSideMeta &&
                                                  airDevice.clientSideMeta.dropped_contatainer_id
                                                )
                                              }
                                              bgColor={
                                                airDevice.clientSideMeta &&
                                                airDevice.clientSideMeta.dropped_contatainer_id
                                                  ? '#d6d6d6'
                                                  : null
                                              }
                                              onLongPress={() => setShowCheckBox(!showCheckBox)}
                                              onClickUnHide={() => {
                                                console.log(hiddenItems);
                                                if (
                                                  hiddenItems &&
                                                  Object.keys(hiddenItems).length > 0
                                                ) {
                                                  const cpy = cloneDeep(hiddenItems);
                                                  delete cpy[contId][airDevice.old_id];
                                                  console.log(cpy);
                                                  forceHide(cpy);
                                                }
                                                // setCheckDevices({ ...cpy });
                                              }}
                                              showCheckBox
                                              checked={
                                                checkedDevices[contId] &&
                                                checkedDevices[contId][airDevice.old_id]
                                              }
                                              handleCheckChange={(checked: boolean) => {
                                                // if manually clicked, reset allCheck,
                                                setAllSelected(null);
                                                if (!checked) {
                                                  const cpy = cloneDeep(checkedDevices);
                                                  delete cpy[contId][airDevice.old_id];

                                                  setCheckDevices({...cpy});
                                                } else {
                                                  setCheckDevices({
                                                    ...checkedDevices,
                                                    [contId]: {
                                                      ...checkedDevices[contId],
                                                      [airDevice.old_id]: checked,
                                                    },
                                                  });
                                                }
                                              }}
                                              // eslint-disable-next-line consistent-return
                                              handleOnPullClick={() => {
                                                if (!airDevice.clientSideMeta) return null;

                                                if (
                                                  airDevice.clientSideMeta.dropped_on &&
                                                  airDevice.clientSideMeta.dropped_on.startsWith(
                                                    DEVICE_POSITIONS.TO_AVAILABLE
                                                  )
                                                ) {
                                                  dispatch(
                                                    removeDevice({
                                                      type: DEVICE_POSITIONS.TO_AVAILABLE,
                                                      configurableDcId:
                                                        airDevice.clientSideMeta
                                                          .dropped_contatainer_id,
                                                      key: airDevice.clientSideMeta.dropped_on,
                                                    })
                                                  );
                                                } else if (airDevice.clientSideMeta.dropped_on) {
                                                  dispatch(
                                                    removeDevice({
                                                      type: DEVICE_POSITIONS.TO_EXISTING,
                                                      configurableDcId:
                                                        airDevice.clientSideMeta
                                                          .dropped_contatainer_id,
                                                      key: airDevice.clientSideMeta.dropped_on,
                                                    })
                                                  );
                                                } else {
                                                  dispatch(
                                                    removeDevice({
                                                      type: DEVICE_POSITIONS.AS_NEW,
                                                      configurableDcId:
                                                        airDevice.clientSideMeta
                                                          .dropped_contatainer_id,
                                                      key: airDevice.old_id,
                                                    })
                                                  );
                                                }

                                                dispatch(
                                                  v2PullDroppedItem({
                                                    type: 'airdc',
                                                    dcId: contId,
                                                    deviceId: airDevice.old_id,
                                                  })
                                                );
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Card>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                          </Group>
                        )}
                      </DeviceContainer>
                      <Box style={{height: 10}} />
                    </>
                  );
                })}
            </>
          )}
        </Grid>
        <Grid item md={6}>
          <ConfigurableDeviceContainer type="airdc" dcCat={2} ref={configurableContainerRef} />
        </Grid>
      </Grid>
    </Board>
  );
});

export default SessionRestoreAirDevices;
