/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import {
  ConfigurableContainerItem,
  ConfigurableContainerState,
  DeviceFromRestoreContainer,
  DEVICE_POSITIONS,
} from './type';

const initialState: ConfigurableContainerState = {};

const configurableContainerSlice = createSlice({
  name: 'configurable-container-slice',
  initialState,
  reducers: {
    resetConfigCotnainers: (state) => {
      const cpy = cloneDeep(state);
      Object.keys(cpy).map((key) => {
        delete state[key];
        // delete cpy[key];
        return 0;
      });

      state = cpy;
    },
    updateConfigurableContainer: (
      state,
      action: {
        payload: {
          item: {
            [key: string]: any;
          },
          key: string,
          flag: 'UPDATE' | 'CREATE'
        };
      },
    ) => {
      if (action.payload.flag === 'CREATE') {
        state[action.payload.key] = action.payload.item as any;
        return;
      }
      if (action.payload.flag === 'UPDATE') {
        const copy: any = state[action.payload.key];
        if (!copy) throw new Error('Unable to find container');

        Object.keys(action.payload.item).map((key) => {
          copy[key] = action.payload.item[key];
          return 0;
        });

        state[action.payload.key] = copy;
      }
    },
    pushNewContainer: (state, action: { payload: ConfigurableContainerItem }) => {
      state[action.payload.dc.id] = action.payload;
    },
    addDevice: (
      state,
      action: {
        payload: {
          device: DeviceFromRestoreContainer;
          type: DEVICE_POSITIONS;
          configurableDcId: string;
          existingDeviceId?: string;
        };
      },
    ) => {
      if (action.payload.type === DEVICE_POSITIONS.AS_NEW) {
        state[action.payload.configurableDcId]
          .deviceMap.asNew[action.payload.device.id] = {
            device: action.payload.device,
          };
      }

      if (action.payload.type === DEVICE_POSITIONS.TO_EXISTING) {
        state[action.payload.configurableDcId]
          .deviceMap.toExisting[action.payload.existingDeviceId] = {
            device: action.payload.device,
          };
      }
    },
    removeDevice: (
      state,
      action: {
          payload: {
            type: DEVICE_POSITIONS;
            configurableDcId: string;
            key: string;
          };
        },
    ) => {
      if (action.payload.type === DEVICE_POSITIONS.AS_NEW) {
        const copy = { ...state[action.payload.configurableDcId].deviceMap.asNew };
        delete copy[action.payload.key];

        state[action.payload.configurableDcId].deviceMap.asNew = copy;
      }

      if (action.payload.type === DEVICE_POSITIONS.TO_EXISTING) {
        const copy = { ...state[action.payload.configurableDcId].deviceMap.toExisting };
        delete copy[action.payload.key];

        state[action.payload.configurableDcId].deviceMap.toExisting = copy;
      }

      if (action.payload.type === DEVICE_POSITIONS.TO_AVAILABLE) {
        const copy = { ...state[action.payload.configurableDcId].deviceMap.toAvailable };

        if (action.payload.key) {
          const tuyaId = action.payload.key.split('to_available_')[1];
          delete copy[tuyaId];
        }
        state[action.payload.configurableDcId].deviceMap.toAvailable = copy;
      }
    },
  },
});

export const { reducer } = configurableContainerSlice;

export const {
  updateConfigurableContainer,
  addDevice,
  removeDevice,
  resetConfigCotnainers,
  pushNewContainer,
} = configurableContainerSlice.actions;
