// eslint-disable-next-line import/prefer-default-export
export const THEMES: {[key: string]: any} = {
  dark: {
    primary: '#1ca086',
    separatorColor: 'rgba(255,255,255,0.20)',
    textColor: 'white',
    backgroundColor: '#121212',
    headerBackgroundColor: 'rgba(255,255,255,0.05)',
    blockquoteColor: 'rgba(255,255,255,0.20)',
    icon: 'white',
    buttonTextColor: '#fefefe',
  },
  light: {
    primary: '#1ca086',
    separatorColor: 'rgba(0,0,0,0.08)',
    textColor: 'black',
    backgroundColor: 'white',
    headerBackgroundColor: '#f6f6f6',
    blockquoteColor: 'rgba(0,0,0,0.80)',
    icon: '#121212',
    buttonTextColor: '#fefefe',
  },
};
