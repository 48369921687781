/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';

import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton/IconButton';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ITuyaDevice } from '../../../AirDeviceCreateEdit/types';
import styles from './FieldCard.module.css';
import { cloneObject } from '../../../../../utility/appUtils';

interface IFieldDefault {
  enabled: boolean;
  data_type: string;
  value: string;
}
type ComponentProps = {
  name: string;
  code: string;
  tuyaDevice: ITuyaDevice;
  onDelete: () => void;
  customDefaultValue: any;
  onCustomDefaultChange: (data: any) => void;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const FieldCard: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles();
  const [valText, setValText] = useState<string | null>(null);
  const [customDefault, setCustomDefault] = useState<IFieldDefault>({
    enabled: false,
    data_type: 'boolean',
    value: 'false',
  });

  useEffect(() => {
    if (props.tuyaDevice && props.tuyaDevice.status && props.tuyaDevice.status.length > 0) {
      props.tuyaDevice.status.forEach((statusValue) => {
        if (statusValue && statusValue.code === props.code) {
          setValText(JSON.stringify(statusValue.value));
        }
      });
    }
  }, [props.code]);

  useEffect(() => {
    if (props.customDefaultValue && props.customDefaultValue.data_type) {
      setCustomDefault({
        enabled: props.customDefaultValue.enabled,
        data_type: props.customDefaultValue.data_type,
        value: `${props.customDefaultValue.value}`,
      });
    }
  }, [props.customDefaultValue]);

  const sendChanges = (payloadData: IFieldDefault) => {
    let v: any = null;
    let invalid = false;
    const payload = cloneObject(payloadData);
    if (payload.enabled) {
      try {
        if (payload.data_type === 'boolean') {
          const c = payload.value.trim().toLowerCase();
          if (['false', 'true'].includes(c)) {
            v = c === 'true';
          } else {
            invalid = true;
          }
        } else if (payload.data_type === 'string') {
          const c = payload.value.trim().toLowerCase();

          if (c !== undefined && c != null && c.length > 0) {
            v = `${c}`;
          } else {
            invalid = true;
          }
        } else if (payload.data_type === 'number') {
          const c = payload.value.trim().toLowerCase();
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(c)) {
            v = +c;
          } else {
            invalid = true;
          }
        } else if (payload.data_type === 'json') {
          const c = payload.value.trim();
          if (typeof c !== 'string') {
            invalid = true;
          }
          JSON.parse(c);
          v = c;
        }
      } catch (err) {
        invalid = true;
      }
    }

    payload.value = v;
    payload.invalid = invalid;

    if (JSON.stringify(props.customDefaultValue) !== JSON.stringify(payload)) {
      props.onCustomDefaultChange({ code: props.code, payload });
    }
  };
  const handleCustomDefaultChange = (tag: string, data: any) => {
    const v = { ...customDefault, [tag]: data };
    setCustomDefault(v);
    sendChanges(v);
  };

  return (
    <div className={styles.title}>
      <div>
        <div className={styles.flexRow}>
          <div className={styles.flexSpacer}>
            <div className="field-enable-card-data">{props.name}</div>
          </div>
          <IconButton
            size="small"
            className="ml-8"
            onClick={() => {
              props.onDelete();
              handleCustomDefaultChange('enabled', false);
            }}
            color="primary"
          >
            <Close fontSize="large" />
          </IconButton>
        </div>
        {valText != null ? (
          <div style={{ overflow: 'auto' }}>
            Value:
            {valText}
          </div>
        ) : (
          <div />
        )}
        <FormControlLabel
          control={(
            <Checkbox
              checked={customDefault.enabled}
              onChange={(event) => {
                handleCustomDefaultChange('enabled', event.target.checked);
              }}
              name="checkedB"
              color="primary"
            />
          )}
          label="Custom default value"
        />
        {customDefault && customDefault.enabled ? (
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Data type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customDefault.data_type}
                onChange={(event) => {
                  handleCustomDefaultChange('data_type', event.target.value);
                }}
              >
                <MenuItem value="boolean">Boolean</MenuItem>
                <MenuItem value="string">String</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="json">JSON</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-basic"
                label="Value"
                variant="outlined"
                value={
                  customDefault.value === 'null' || customDefault.value === null
                    ? ''
                    : customDefault.value
                }
                onChange={(event) => {
                  handleCustomDefaultChange('value', event.target.value);
                }}
              />
            </FormControl>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
