/* eslint-disable max-classes-per-file */

import axios, { AxiosInstance } from 'axios';
import { CloudIntegrationPlugin, EventManager } from '../shared/Plugin';
import EnodeIntegration from './enode.service';
import { ReactComponents } from './components';
import { EventType } from '../shared/PluginTypes';

class EnodePlugin extends CloudIntegrationPlugin {
  uiComponents: ReactComponents;
  // integration: EnodeIntegration;

  constructor(axiosClient: AxiosInstance, eventManager: EventManager) {
    const integration = new EnodeIntegration(axiosClient, eventManager);
    super(eventManager, integration);
    this.uiComponents = new ReactComponents(this.eventManager, integration);
  }
}

export default EnodePlugin;
