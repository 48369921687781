/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import {
  Box, CircularProgress, FormHelperText, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { cloneDeep, isArray } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  Action,
  ActionTypes,
  AirDevice,
  CheckTypes,
  DeviceCollectionSearchOut,
  DeviceCollectionSearchOutItem,
  ProDevice,
  RuleEngineState,
  Trigger,
  TriggerTypes,
} from '@smartlife-redux-state/common';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { airDCGetGraphQL } from '../../../../services/air-device/air-device.service';
import { dcSearchGraphQL } from '../../../../services/device-container/device-container.service';
import { proDCGetGraphQL } from '../../../../services/pro-container/pro-container.service';
import { proDeviceGetGraphQL } from '../../../../services/pro-device/pro-device.service';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { RootState, useSelector } from '../../../../store/redux/store';
import { airDeviceGet } from '../../AirDeviceCreateEdit/services/air-device.service';
import PageContentLayout from './PageContentLayout';
import { hydrate_logic_creation } from '../../../../store/redux/features/logic-engine';

const useStyle = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '10%',
  },
});
// eslint-disable-next-line import/prefer-default-export
export const VerifyLogic: React.FC<{
  changeVerifyVisiblity: (value: boolean) => any;
}> = ({ changeVerifyVisiblity }) => {
  const classes = useStyle();
  const user = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const dispatch = useDispatch();

  const logic = useSelector((state: RootState) => state.rule_engine);

  const [msg, setMsg] = useState('Start checking triggers..');
  const [isseus_found, setIssuesFound] = useState<string[]>([]);
  const [fetch_err, setFetchErr] = useState(null);
  const [is_done, setIsDone] = useState(false);

  useEffect(() => {
    if (!logic) {
      // setMsg("Logic not found. Please reload the app");
      return;
    }
    if (logic.verified) return;
    fetchLatestDCDevices(logic);
  }, [logic]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fetchLatestDCDevices = async (logic: RuleEngineState) => {
    const state = cloneDeep(logic);
    try {
      // debugger;
      const res: any = await dcSearchGraphQL(
        user.apollo_client,
        selectedProject.selected_project.id,
        null,
        null,
        '',
        20,
        0,
      );

      const cintRes = await pluginHook.commonIntegrationService.getCloudDeviceCollections({
        project_id: selectedProject.selected_project.id,
        limit: 100,
        skip: 0,
      });
      const convertedRes = pluginHook.commonIntegrationService.LogicMapper.convertCloudDeviceContainersToReduxFriendly(
        cintRes,
      );

      const fromGql = res.dcSearch as DeviceCollectionSearchOut;
      const fromRest = convertedRes;

      const gqlRes = fromGql && isArray(fromGql.result) ? [...fromGql.result] : [];
      const restRes = fromRest && isArray(fromRest.result) ? [...fromRest.result] : [];

      const allDcs = {
        __typename: 'DeviceCollectionSearchOut' as const,
        limit: 100,
        skip: 0,
        resultCount: gqlRes.length + restRes.length,
        result: [...gqlRes, ...restRes],
      };

      const latest_dcs: DeviceCollectionSearchOut = allDcs;
      let location_variable_container = null;
      if (latest_dcs.result && latest_dcs.result.length > 0) {
        const filtered = [];
        // eslint-disable-next-line array-callback-return
        latest_dcs.result.reverse().map((dc, index) => {
          if (dc.dc_type === 9) {
            location_variable_container = dc;
            return;
          }
          filtered.push(dc);
        });
        latest_dcs.result = filtered;
      }

      if (latest_dcs.result) {
        state.device_containers = latest_dcs;
      }

      if (location_variable_container) {
        state.location_variable_container = location_variable_container;
      }

      const verified = await verify(state);
      setIsDone(true);

      if (!fetch_err && isseus_found.length === 0) {
        changeVerifyVisiblity(false);
        dispatch(hydrate_logic_creation(verified));
      }
      return verified;
    } catch (err: any) {
      setIsDone(true);
      setFetchErr(err.message);
      changeVerifyVisiblity(false);
    }
    return null;
  };

  async function verify(logic_pointer: RuleEngineState) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const logic = cloneDeep(logic_pointer);
    // TODO - trigger verify
    const trigger_cpy = cloneDeep(logic.triggers);
    const verified_triggers: Trigger[] = [];
    if (trigger_cpy && isArray(trigger_cpy)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < trigger_cpy.length; i++) {
        const trigger = cloneDeep(trigger_cpy[i]);

        if (trigger.type === TriggerTypes.DEVICE) {
          // Check dc availability
          try {
            const found = logic.device_containers.result.find((item) => item.id === trigger.dc.id);
            if (!found) throw new Error('Non existing DC');
            trigger.dc = found;
            if (found.dc_type === 8) {
              throw new Error('Demo Containers cannot be in logics');
            }
          } catch (err: any) {
            trigger.dc.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }

          // check device availablity
          try {
            // eslint-disable-next-line no-await-in-loop
            const res = await getDevice(
              trigger.device.id,
              // eslint-disable-next-line no-nested-ternary
              trigger.dc.dc_cat === -1
                ? 'cint'
                : trigger.dc.dc_cat === 1
                  ? 'pro'
                  : trigger.dc.dc_cat === 2
                    ? 'air'
                    : null,
              logic,
            );
            trigger.device = res;
          } catch (err: any) {
            trigger.device.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }
        }

        if (trigger.type === TriggerTypes.LOCATION_VARIABLE) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const res = (await getDevice(trigger.device.id, 'pro', logic)) as ProDevice;
            trigger.device = res;
          } catch (err: any) {
            trigger.device.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }
        }

        verified_triggers.push(trigger);
      }
      logic.triggers = verified_triggers;
    }

    // TODO - check verify
    const check_cpy = cloneDeep(logic.checks);
    const checks_list = check_cpy.content;

    const verified_checks_list = [];

    if (checks_list && isArray(checks_list)) {
      for (let i = 0; i < checks_list.length; i++) {
        const check = cloneDeep(checks_list[i]);

        if (check.type === CheckTypes.DEVICE) {
          // check dc availability
          try {
            const found = logic.device_containers.result.find((item) => item.id === check.dc.id);
            if (!found) throw new Error('Non existing DC');
            check.dc = found;
            if (found.dc_type === 8) {
              throw new Error('Demo Containers cannot be in logics');
            }
          } catch (err: any) {
            check.dc.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }

          // check device availablity
          try {
            // eslint-disable-next-line no-await-in-loop
            const res = await getDevice(
              check.device.id,
              // eslint-disable-next-line no-nested-ternary
              check.dc.dc_cat === -1
                ? 'cint'
                : check.dc.dc_cat === 1
                  ? 'pro'
                  : check.dc.dc_cat === 2
                    ? 'air'
                    : null,
              logic,
            );
            check.device = res;
          } catch (err: any) {
            check.device.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }
        }

        if (check.type === CheckTypes.LOCATION) {
          try {
            const res = (await getDevice(check.device.id, 'pro', logic)) as ProDevice;
            check.device = res;
          } catch (err: any) {
            check.device.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }
        }

        verified_checks_list.push(check);
      }

      logic.checks.content = verified_checks_list;
    }

    // TODO - action verify
    const action_cpy = cloneDeep(logic.actions);
    const verified_actions: Action[] = [];

    if (action_cpy && isArray(action_cpy)) {
      for (let i = 0; i < action_cpy.length; i++) {
        const action = cloneDeep(action_cpy[i]);

        if (action.type === ActionTypes.DEVICE) {
          // Check dc availability
          try {
            const found = logic.device_containers.result.find((item) => item.id === action.dc.id);
            if (!found) throw new Error('Non existing DC');
            action.dc = found;

            if (found.dc_type === 8) {
              throw new Error('Demo Containers cannot be in logics');
            }
          } catch (err: any) {
            action.dc.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }

          // check device availablity
          try {
            const res = await getDevice(
              action.device.id,
              // eslint-disable-next-line no-nested-ternary
              action.dc.dc_cat === -1
                ? 'cint'
                : action.dc.dc_cat === 1
                  ? 'pro'
                  : action.dc.dc_cat === 2
                    ? 'air'
                    : null,
              logic,
            );
            // debugger;
            action.device = res;
          } catch (err: any) {
            action.device.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }
        }

        if (action.type === ActionTypes.FIREBASE) {
          try {
            const res = (await getDevice(action.device.id, 'pro', logic)) as ProDevice;
            action.device = res;
          } catch (err: any) {
            action.device.has_verification_error = err.message;
            setIssuesFound([...isseus_found, err.message]);
          }
        }

        const child_action_cpy = cloneDeep(action.child_actions);

        const verified_child_actions: Action[] = [];
        if (child_action_cpy && isArray(child_action_cpy)) {
          for (let i = 0; i < child_action_cpy.length; i++) {
            const child_action = cloneDeep(child_action_cpy[i]);
            if (child_action.type === ActionTypes.DEVICE) {
              // Check dc availability
              try {
                const found = logic.device_containers.result.find(
                  (item) => item.id === child_action.dc.id,
                );
                if (!found) throw new Error('Non existing DC');
                child_action.dc = found;
              } catch (err: any) {
                child_action.dc.has_verification_error = err.message;
                setIssuesFound([...isseus_found, err.message]);
              }

              // check device availablity
              try {
                const res = await getDevice(
                  child_action.device.id,
                  // eslint-disable-next-line no-nested-ternary
                  child_action.dc.dc_cat === -1
                    ? 'cint'
                    : child_action.dc.dc_cat === 1
                      ? 'pro'
                      : child_action.dc.dc_cat === 2
                        ? 'air'
                        : null,
                  logic,
                );
                child_action.device = res;
              } catch (err: any) {
                child_action.device.has_verification_error = err.message;
                setIssuesFound([...isseus_found, err.message]);
              }
            }
            if (child_action.type === ActionTypes.FIREBASE) {
              try {
                const res = (await getDevice(child_action.device.id, 'pro', logic)) as ProDevice;
                child_action.device = res;
              } catch (err: any) {
                child_action.device.has_verification_error = err.message;
                setIssuesFound([...isseus_found, err.message]);
              }
            }
            verified_child_actions.push(child_action);
          }
        }
        action.child_actions = verified_child_actions;
        verified_actions.push(action);
      }
      logic.actions = verified_actions;
    }

    logic.verified = true;
    return logic;
  }

  async function getDevice(
    device_id: string,
    type: 'air' | 'pro' | 'cint',
    logic: RuleEngineState,
  ) {
    if (type === 'pro') {
      const res = await proDeviceGetGraphQL(user.apollo_client, device_id);
      if (!res) throw new Error('Non existing item');

      const device = res as ProDevice;
      const prodid = device.prodc_id;

      const foundDc = device.cat === 'location_variables'
        ? logic.location_variable_container
        : logic.device_containers.result.find((item) => item.id === prodid);
      if (!foundDc) throw new Error('Missmatching dc container and device');

      return res as ProDevice;
    }

    if (type === 'air') {
      const res: any = await airDeviceGet(user.apollo_client, device_id);
      if (!res) throw new Error('Non existing item');

      const device = res as AirDevice;
      device.settings = device.settings && typeof device.settings !== 'string'
        ? JSON.stringify(device.settings)
        : device.settings;
      const airDcId = device.airdc_id;
      const foundDc = logic.device_containers.result.find((item) => item.id === airDcId);
      if (!foundDc) throw new Error('Missmatching dc container and device');

      return res as AirDevice;
    }

    if (type === 'cint') {
      const res = await pluginHook.commonIntegrationService.getCloudDevice(device_id);
      const device = pluginHook.commonIntegrationService.LogicMapper.convertCloudDeviceToReduxFriendly(
        res.result.device,
      );

      const cDcId = device.airdc_id;
      const foundDc = logic.device_containers.result.find((item) => item.id === cDcId);
      if (!foundDc) throw new Error('Missmatching dc container and device');

      return device as AirDevice;
    }

    return null;
  }

  return (
    <PageContentLayout className={classes.root}>
      {!is_done && <CircularProgress color="secondary" />}
      <Typography>{is_done ? 'Verification Result' : 'Verifying the logic'}</Typography>
      <FormHelperText>{msg}</FormHelperText>
      <ul>
        {isseus_found.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <FormHelperText style={{ color: 'yellow' }}>{item}</FormHelperText>
          </li>
        ))}
      </ul>
    </PageContentLayout>
  );
};
