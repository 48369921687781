/* eslint-disable camelcase */
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import React, { useEffect, useState } from 'react';
import { cloneDeep, keys } from 'lodash';
import { Action } from '@smartlife-redux-state/common';
import { update_action } from 'src/store/redux/features/logic-engine';
import { useDispatch } from 'react-redux';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { RootState, useSelector } from '../../../../../../../../store/redux/store';
import { Board } from '../../../../board';
import { Group } from '../../../../board/Group';
import { Card } from '../../../../board/Card';
import { FormContainer } from '../../FormContainer';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  child_head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  child_root: {
    // backgroundColor: '#d5e3e5 !important',
    margin: '0 !important',
    padding: '0 !important',
    width: '100%',
  },
});

const ChildRow = ({
  row,
  index,
  setKeyHandler,
  setDelayHandler,
  deleteChild,
  remoteKeyMap,
  isLast = false,
  classes,
}: any) => (
  <Grid
    container
    spacing={2}
    key={row.index}
    style={{
      marginTop: '5px',
      padding: '5px',
    }}
  >
    <Grid item xs={12} md={8}>
      <Autocomplete
        size="small"
        onChange={(_, value) => {
          console.log(value);
          setKeyHandler(index, value || null);
        }}
        value={row.remote_button}
        className={classes.formControl}
        id={`remote_Key_${row.index}`}
        options={remoteKeyMap}
        getOptionLabel={(option: any) => (option ? option.label : '23')}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="Remote Key" variant="outlined" />}
        multiple
      />
    </Grid>
    <Grid item xs={12} md={3}>
      {!isLast && (
        <TextField
          // disabled={isLast}
          id={`delay_${row.index}`}
          type="number"
          className={classes.formControl}
          label="Delay(sec)"
          value={row.delay}
          onChange={(e: any) => {
            setDelayHandler(index, e.target.value);
          }}
          error={row.delay < 0 || (row.delay !== 0 && !row.delay)}
          variant="outlined"
          size="small"
        />
      )}
    </Grid>
    <Grid item xs={12} md={1}>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={() => deleteChild(index)} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
);

const RemoteActions: React.FC<{
  remoteKeyMap: any;
  onChange: any;
  action: Action;
  isChild?: boolean;
  parentAction?: Action;
}> = ({
  remoteKeyMap, onChange, action, isChild, parentAction,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [child, setChild] = useState<any[]>(action.variable ? JSON.parse(action.variable) : []);
  // console.log(remoteKeyMap);

  const AddNewChild = () => {
    const newArray = [
      ...child,
      {
        remote_button: [],
        delay: 1,
        index: Date.now(),
      },
    ];
    setChild(newArray);
  };

  const deleteChild = (index) => {
    const newArray = [...child];
    newArray.splice(index, 1);
    setChild(newArray);
  };

  const setKeyHandler = (index, remote_button) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      remote_button,
    };
    setChild(newArray);
  };

  const setDelayHandler = (index, val) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      delay: parseInt(val, 10) < 0 ? 0 : parseInt(val, 10),
    };
    setChild(newArray);
  };

  useEffect(() => {
    if (child) {
      let value;
      if (child.length === 0) {
        value = JSON.stringify([]);
      } else {
        value = JSON.stringify([...child]);
        onChange('IR_REMOTE', '', { field: value, type: 'ir_remote' });
      }
      // onChange("IR_REMOTE", "", { field: value, type: "ir_remote" });
    }
  }, [child]);

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const actionCpy = cloneDeep(action);
    const variables = JSON.parse(actionCpy.variable);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const temp = variables[sourceIndex];
    variables[sourceIndex] = variables[destinationIndex];
    variables[destinationIndex] = temp;
    actionCpy.variable = JSON.stringify(variables);

    dispatch(
      update_action({
        action: actionCpy,
        isChild,
        parentAction,
      }),
    );

    setChild(variables);
  };

  return (
    <Grid
      container
      style={{
        border: '1px solid #D9DFE0',
        padding: '10px',
        borderRadius: '10px',
        marginTop: '10px',
      }}
    >
      <Box className={classes.child_head}>
        <Typography>Remote Keys</Typography>
        <IconButton onClick={() => AddNewChild()} size="large">
          <AddIcon />
        </IconButton>
      </Box>
      <Board onDragEnd={onDragEnd}>
        <Group droppableId="TVChildList">
          {() => (
            <>
              {child
                && child.map((row, index) => (
                  <Card draggableId={`TVChildListRow_${index}`} index={index}>
                    {({ provided, snapshot }) => (
                      <FormContainer
                        dragHandlerProps={provided.dragHandleProps}
                        className={classes.child_root}
                      >
                        <ChildRow
                          row={row}
                          index={index}
                          isLast={child.length === index + 1}
                          setKeyHandler={setKeyHandler}
                          setDelayHandler={setDelayHandler}
                          deleteChild={deleteChild}
                          remoteKeyMap={remoteKeyMap}
                          classes={classes}
                        />
                      </FormContainer>
                    )}
                  </Card>
                ))}
            </>
          )}
        </Group>
      </Board>
    </Grid>
  );
};

RemoteActions.defaultProps = {
  isChild: false,
  parentAction: null,
};

export default RemoteActions;
