import { IUserViewPath, UserViewItemCategory } from './types/types';

export const pathRefToUserviewPaths = (pathRef: string, displayPathRef: string) => {
  const items: IUserViewPath[] = [];
  if (pathRef.length > 0) {
    const ids = pathRef.split('-');
    const names = displayPathRef.split('/');
    let npathRef = '';
    // items.push({
    //   display_name: "Home",
    //   folder_id: -1,
    //   path_ref: "root",
    // });
    npathRef += '';
    if (ids.length > 0) {
      ids.forEach((id, idx) => {
        if (id.length === 0) {
          return;
        }
        npathRef += `-${id}`;
        items.push({
          display_name: names[idx],
          folder_id: id,
          path_ref: npathRef,
        });
      });
    }
  }

  return items;
};
export const getFolderIdFromPathRef = (pathRef: string) => {
  let folderId = ''; // -1;
  if (pathRef === 'root') {
    folderId = ''; // -1;
  } else {
    const lastFolderPart = pathRef.substr(pathRef.lastIndexOf('-') + 1);
    const numV = lastFolderPart;
    if (numV !== '' /* > 0 */) {
      folderId = numV;
    }
  }
  return folderId;
};

export const UVCreateCommDeviceId = (dcType: string, id: any) => `${dcType}_${id}`;
export const UVGetDeviceIdFromCommId = (key: string) => {
  const keyParts = key.split('_');
  return { id: `${keyParts[1]}_${keyParts[2]}_${keyParts[3]}`, dc_type: keyParts[0] };
};
export const UVItemCatName = (userviewItemCat: number) => {
  let name = '';
  if (userviewItemCat === UserViewItemCategory.folder) {
    name = 'Folder';
  } else if (userviewItemCat === UserViewItemCategory.item) {
    name = 'Item';
  } else if (userviewItemCat === UserViewItemCategory.linkedFolder) {
    name = 'Linked folder';
  } else if (userviewItemCat === UserViewItemCategory.linkedItem) {
    name = 'Linked item';
  } else if (userviewItemCat === (UserViewItemCategory as any).other) {
    name = 'Other item';
  }
  return name;
};
