/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  Alert, AlertColor, Box, FormControlLabel, Grid, Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Action, Check, LOGIC_CARD_PERMISSION, LOGIC_CARD_VISIBILITY, Trigger,
} from '@smartlife-redux-state/common';
import clsx from 'clsx';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { update_action, update_check, update_trigger } from '../../../../../../../store/redux/features/logic-engine';
import { RootState, useSelector } from '../../../../../../../store/redux/store';

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

// placeholderRoot: {
//   background: '#F5B37C !important',
// },
// errorRoot: {
//   background: '#EB88AD !important',
// },
// disabledRoot: {
//   background: '#E8E8E8 !important',
// },
const useStyle = makeStyles({
  placeholderRoot: {
    background: '#F5B37C !important',
  },
  errorRoot: {
    background: '#EB88AD !important',
  },
  disabledRoot: {
    background: '#AFAFB6 !important',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export enum CARD_TYPES {
  TRIGGER_CARD = 'trigger_card',
  CHECK_CARD = 'check_card',
  ACTION_CARD = 'action_card',
  ACTION_CHILD_CARD = 'action_child_card',
}

const CardStatus: React.FC<{
  cardType: CARD_TYPES;
  cardId: string;
  parentCardId?: string;
  meta?: {
    is_enabled: boolean;
    card_permission: LOGIC_CARD_PERMISSION;
    card_visibility: LOGIC_CARD_VISIBILITY;
  };
  children: (props: {
    is_enabled: boolean;
    card_visibility: LOGIC_CARD_VISIBILITY;
    classes: string;
  }) => React.ReactChild;
}> = ({
  meta, children, cardType, cardId, parentCardId,
}) => {
  const classes = useStyle();
  const [hidden, setHidden] = useState(false);
  const [issues, setIssues] = useState<{text: string, type: AlertColor}[]>([]);
  const dispatch = useDispatch();
  const renderProp = unwrapArray(children);

  const parentAction = useSelector((state: RootState) => state
    .rule_engine.actions.find((item) => item.id === parentCardId));

  const cardContent = useSelector((state: RootState) => {
    switch (cardType) {
      case CARD_TYPES.TRIGGER_CARD:
        return state.rule_engine.triggers.find((item) => item.id === cardId);
      case CARD_TYPES.CHECK_CARD:
        return state.rule_engine.checks.content.find((item) => item.id === cardId);
      case CARD_TYPES.ACTION_CARD:
        return state.rule_engine.actions.find((item) => item.id === cardId);
      case CARD_TYPES.ACTION_CHILD_CARD: {
        const action = state.rule_engine.actions.find((item) => item.id === parentCardId);
        if (isArray(action.child_actions)) {
          return action.child_actions.find((item) => item.id === cardId);
        }
        return null;
      }
      default:
        return null;
    }
  });

  const [statusClasses, setStatusClasses] = useState('');

  useEffect(() => {
    if (meta) {
      const classval = meta && !meta.is_enabled
        ? classes.disabledRoot
        : meta.card_visibility === LOGIC_CARD_VISIBILITY.PLACEHOLDER
          ? classes.placeholderRoot
          : meta.card_visibility === LOGIC_CARD_VISIBILITY.DEVICE_NOT_FOUND
            ? classes.errorRoot
            : '';

      const errorList = [];

      if (meta) {
        if (!meta.is_enabled) {
          errorList.push({
            text: 'The card is disabled',
            type: 'warning',
          });
        }

        if (meta.card_visibility === LOGIC_CARD_VISIBILITY.PLACEHOLDER) {
          errorList.push({
            text: 'This card was in the template but did not configured when restoring. This will not execute. Delete the card or replace with new devices',
            type: 'warning',
          });
        }

        if (meta.card_visibility === LOGIC_CARD_VISIBILITY.DEVICE_NOT_FOUND) {
          errorList.push({
            text: 'Unable to find the device in following card. Delete the card or add avaible device',
            type: 'error',
          });
        }

        if (meta.card_visibility === LOGIC_CARD_VISIBILITY.HIDDEN) {
          setHidden(true);
        }
      }
      setIssues([...errorList]);
      setStatusClasses(classval);
    }
  }, [meta]);

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;

    const updated = {
      ...cardContent,
      meta: {
        ...cardContent.meta,
        is_enabled: val,
      },
    };

    switch (cardType) {
      case CARD_TYPES.TRIGGER_CARD:
        dispatch(update_trigger({
          trigger: updated as Trigger,
        }));
        break;
      case CARD_TYPES.CHECK_CARD:
        dispatch(update_check({
          check: updated as Check,
        }));
        break;
      case CARD_TYPES.ACTION_CARD:
        dispatch(update_action({
          action: updated as Action,
          isChild: false,
          parentAction: null,
        }));
        break;
      case CARD_TYPES.ACTION_CHILD_CARD: {
        if (parentAction === null) return;
        dispatch(update_action({
          action: updated as Action,
          isChild: true,
          parentAction,
        }));
        break;
      }
      default:
        break;
    }
  };

  if (hidden) <></>;

  return (
    <Box className={clsx(classes.root)}>
      <Box>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <FormControlLabel
              control={(
                <Switch
                  checked={cardContent && cardContent.meta ? cardContent.meta.is_enabled : true}
                  onChange={handleStatusChange}
                />
              )}
              label={cardContent && cardContent.meta ? (cardContent.meta.is_enabled ? 'Enabled' : 'Disabled') : 'Enabled'}
            />
            {/* <Switch
              // checked={checked}
              // onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            /> */}
          </Grid>
          <Grid item md={10}>
            {issues.map((issue, index) => (
              <>
                <Alert key={index} severity={issue.type}>{ issue.text }</Alert>
                <Box height={10} />
              </>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box>{renderProp({ ...meta, classes: statusClasses })}</Box>
    </Box>
  );
};
// statusClasses
CardStatus.defaultProps = {
  meta: {
    is_enabled: true,
    card_permission: LOGIC_CARD_PERMISSION.LEVEL1,
    card_visibility: LOGIC_CARD_VISIBILITY.DEVICE_NOT_FOUND,
  },
  parentCardId: null,
};

export default CardStatus;
