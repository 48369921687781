/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { SortableContainer as SortableContainerFactory, SortableElement } from 'react-sortable-hoc';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@mui/material/transitions';
import { Close, Done } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
} from '../../../../Alters/Snackbar/SnackbarOptions';
import uvService, { userViewGetOrder, userViewOrderSetAll } from '../../../service/uv.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { UVItemCatName } from '../../../utils';
import styles from './ItemSortDialog.module.css';
import { UVContext } from '../../../UVContext';
import { IItemSortCardItem, ItemSortCardItem } from './ItemSortCardItem';
import { LoadingComponent } from '../../../../shared/Loading/Loading';
import { appConsoleLog, appConsoleError } from '../../../../../utility/appUtils';
import { ProjectContext } from '../../../../../store/Project/ProjectContext';

// eslint-disable-next-line max-len
const SortableItem = SortableElement<{ value: any }>(({ value }: any) => <div style={{ zIndex: 9999 }}>{value}</div>);

// eslint-disable-next-line max-len
const SortableContainer: any = SortableContainerFactory(({ children }: any) => <div>{children}</div>);

type ComponentProps = {
  dialogOpen: boolean;
  dialogCloseEvent: (flag: any) => void;
  folderId?: string;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title11" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {children: React.ReactElement<any, any>},
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

export const ItemSortDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
  folderId,
}) => {
  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);
  const selectedProject = useContext(ProjectContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [sorted, setSorted] = useState(null);

  useEffect(() => {
    if (userViewContext.projectId !== '' /* -1 */ && userViewContext.userViewId !== '' /* -1 */) {
      const fetch = async () => {
        try {
          setLoading(true);

          const response = await uvService.userviewListFolderItems(
            userProfile.apollo_client,
            userViewContext.userViewId,
            folderId === 'root' || folderId === undefined ? '' /* -1 */ : folderId,
            100,
            0,
          );

          const res = await userViewGetOrder(userProfile.apollo_client, folderId, 2);

          if (res.data && res.data.itemOrderGet && res.data.itemOrderGet.items) {
            const card_order: any = [];
            const list: {[key: string]: any} = res.data.itemOrderGet.items;
            // eslint-disable-next-line no-restricted-syntax
            for (const [_, val] of Object.entries(list)) {
              card_order.push(val.ref_val);
            }
            setSortedOrder(card_order);
          }

          const temp_sort: string[] = [];
          const mappedItems: any[] = response.items.result.map((item) => {
            const v = {
              id: item.id,
              title: item.display_name,
              subTitle: UVItemCatName(item.item_cat),
              icon_name: item.icon_name,
            };
            temp_sort.push(item.id);
            return v;
          });
          if (
            !(res.data && res.data.itemOrderGet && res.data.itemOrderGet.items)
            && sortedOrder.length === 0
          ) {
            setSortedOrder(temp_sort);
          }
          setSelectedItems(mappedItems);

          setFolder(response.folder);
        } catch (err) {
          appConsoleError(err);

          snackbar.enqueueSnackbar('Unexpacted error', {
            key: 'uv-sort-cd-error',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        }
        setLoading(false);
      };
      fetch();
    }
  }, [
    dialogOpen,
    folderId,
    userViewContext.projectId,
    userViewContext.userViewId,
    userViewContext.userViewPath,
    userViewContext.updated,
  ]);

  const closeDialog = (res: any) => {
    dialogCloseEvent(res);
  };

  const [selectedItems, setSelectedItems] = useState<IItemSortCardItem[]>([]);
  const [sortedOrder, setSortedOrder] = useState<string[]>([]);
  const [folder, setFolder] = useState(null);

  const [dragItem, setDragItem] = useState<{
    item: IItemSortCardItem;
    type: string;
    index: number;
  } | null>();

  const onSave = async () => {
    try {
      await userViewOrderSetAll(
        userProfile.apollo_client,
        selectedProject.selected_project.id,
        folderId,
        2,
        sortedOrder,
      );
      closeDialog(true);
      snackbar.enqueueSnackbar('Item Sorted', { variant: 'success' });
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const onSortStart = ({
    node, index, collection, isKeySorting,
  }: any, event: any) => {
    appConsoleLog({
      node,
      index,
      collection,
      isKeySorting,
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const shallow_cpy = [...sortedOrder];
    const newSorted = arrayMove(shallow_cpy, oldIndex, newIndex);
    setSortedOrder(newSorted);
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      onClose={() => {
        closeDialog(false);
      }}
      aria-labelledby="alert-dialog-slide-title1"
      aria-describedby="alert-dialog-slide-description"
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title11">
        Sort folder items
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className={`row mt-2 ${styles.dialogContent}`}>
            <div className="col-md-12">
              <div className={styles.DragSelectorListCont}>
                <div className={styles.DragSelectorListInnerCont}>
                  <SortableContainer onSortEnd={onSortEnd} onSortStart={onSortStart}>
                    {sortedOrder.map((item_id, index) => {
                      const item = selectedItems.find((each_item) => each_item.id === item_id);
                      if (!item) return <></>;
                      return (
                        <SortableItem
                          key={item.id}
                          value={(
                            <>
                              <ItemSortCardItem item={item} key={item_id} itemIndex={index} />
                              <div className={styles.DragSelectorItemCardDivider} />
                            </>
                          ) as any}
                          index={index}
                          collection={1}
                        />
                      );
                      // return (
                      //   <div
                      //     onDragOver={(event) => {
                      //       event.preventDefault();
                      //     }}
                      //     onDrop={(event) => {
                      //       event.preventDefault();
                      //       onDividerDropHandler(index);
                      //     }}
                      //     key={"srt" + item_id}
                      //   >
                      //     <ItemSortCardItem
                      //       item={item}
                      //       key={item_id}
                      //       itemIndex={index}
                      //       dragStartHandler={dragStartHandler}
                      //     ></ItemSortCardItem>
                      //     <div
                      //       className={styles.DragSelectorItemCardDivider}
                      //     ></div>
                      //   </div>
                      // );
                    })}
                  </SortableContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onSave} color="primary">
            <Done fontSize="large" />
            Save
          </Button>
          <Button
            onClick={() => {
              closeDialog(false);
            }}
            color="primary"
          >
            <Close fontSize="large" />
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ItemSortDialog.defaultProps = {
  folderId: null,
};
