/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Example Usage of the extend dragDrop library
 *
 * @author Maduka Dilshan
 *
 */
import { Box, Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/Add';
// import AddBoxIcon from "@mui/icons-material/AddBox";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React, { useState } from 'react';
import {
  DraggableLocation,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { v4 as uuid } from 'uuid';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { Check } from '@smartlife-redux-state/common';
import clsx from 'clsx';
import { Board } from '../board';
import { Card } from '../board/Card';
import { Group } from '../board/Group';
import { FormContainer } from './components/FormContainer';
import { OperatorSelector } from './components/checks/OperatorSelector';
import { RootState } from '../../../../../store/redux/store';
import {
  add_check_empty_group,
  add_empty_check,
  check_list_reorder,
  define_check_expression,
  delete_check,
  duplicate_check,
} from '../../../../../store/redux/features/logic-engine/slice';
import { CheckSelector } from './components/CheckSelector';
import { DivButton } from '../ui/DivButton';
import { CofirmDialog } from '../ui/Confirm';
import { RealtimeValueCompartorComponent } from './components/shared/RealtimeValueCompartor';
import { useFirebasePathBuilder } from './components/shared/ExpressionSelector';
import CardStatus, { CARD_TYPES } from './components/shared/CardStatus';

const useStyles = makeStyles({
  boardRoot: {
    width: '100%',
  },
  groupRoot: {
    minHeight: '100px',
  },
  addGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  equal_values: {
    background: '#dbffe1',
  },
});

const BoardCheckCard: React.FC<{
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  groupIndex: number;
  cardIndex: number;
  groupItemsCount: number;
  card: {id: string};
  onDeleteTrigger: (groupIndex: number, checkIndex: number) => void;
  onAddEmptyCard: (group_index: number, last_card_index: number) => void;
  onDuplicateCard: (group_index: number, last_card_index: number, check: Check) => void;
}> = ({
  provided,
  snapshot,
  groupIndex,
  cardIndex,
  groupItemsCount,
  card,
  onDeleteTrigger,
  onAddEmptyCard,
  onDuplicateCard,
}) => {
  const classes = useStyles();
  const checkContent = useSelector((state: RootState) => state
    .rule_engine.checks.content.find((item) => item.id === card.id));

  const [firebase_path] = useFirebasePathBuilder(checkContent.dc, checkContent.device, {
    field: checkContent.variable,
    type: checkContent.possible_variable_type,
  });

  return (
    <RealtimeValueCompartorComponent path_to_check={firebase_path} current_value={checkContent.val}>
      {({ isEqual }) => (
        <>
          <CardStatus
            cardType={CARD_TYPES.CHECK_CARD}
            cardId={checkContent.id}
            meta={checkContent && checkContent.meta ? checkContent.meta : null}
          >
            {({ classes: cardStatusClasses }) => (
              <FormContainer
                dragHandlerProps={provided.dragHandleProps}
                className={clsx(isEqual ? classes.equal_values : '', cardStatusClasses)}
                action={() => (
                  <>
                    <IconButton onClick={() => onAddEmptyCard(groupIndex, cardIndex)} size="large">
                      <AddBoxIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => onDuplicateCard(groupIndex, cardIndex, checkContent)}
                      size="large"
                    >
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteTrigger(groupIndex, cardIndex)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              >
                <CheckSelector check={card} />
              </FormContainer>
            )}
          </CardStatus>
          {!snapshot.isDragging && groupItemsCount !== cardIndex + 1 && (
          <OperatorSelector
            preId={`g${groupIndex}-${cardIndex}`}
            nextId={`g${groupIndex}-${cardIndex + 1}`}
          />
          )}
        </>
      )}
    </RealtimeValueCompartorComponent>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const BoardCheck = () => {
  const classes = useStyles();
  const [deleteCheck, setDeleteCheck] = useState<{groupIndex: number; checkIndex: number}>(null);
  const boardItems = useSelector((state: RootState) => state.rule_engine.checks.list);
  const dispatch = useDispatch();

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    dispatch(check_list_reorder({ result, provided }));
  }

  function onAddNewGroup() {
    dispatch(add_check_empty_group());
  }

  function setExpressoin() {
    dispatch(define_check_expression());
  }

  function onAddEmptyCard(group_index: number, last_card_index: number) {
    dispatch(
      add_empty_check({
        groupIndex: group_index,
        lastIndex: last_card_index,
      }),
    );
  }

  function onDuplicateCard(group_index: number, last_card_index: number, check: Check) {
    dispatch(
      duplicate_check({
        groupIndex: group_index,
        lastIndex: last_card_index,
        check,
      }),
    );
  }

  function onDeleteTrigger(groupIndex: number, checkIndex: number) {
    setDeleteCheck({ groupIndex, checkIndex });
  }

  function deleteTrigger() {
    dispatch(
      delete_check({
        groupIndex: deleteCheck.groupIndex,
        checkIndex: deleteCheck.checkIndex,
      }),
    );
    setDeleteCheck(null);
  }

  return (
    <Board
      // eslint-disable-next-line react/jsx-no-bind
      onDragEnd={onDragEnd}
      className={classes.boardRoot}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {boardItems.map((group, groupIndex) => (
        <>
          <Group droppableId={`${groupIndex}`} key={groupIndex} className={classes.groupRoot}>
            {() => (
              <>
                {group.map((card, cardIndex) => (
                  <React.Fragment key={cardIndex + groupIndex}>
                    <Card draggableId={`drop${groupIndex}drag${cardIndex}`} index={cardIndex}>
                      {({ provided, snapshot }) => (
                        <BoardCheckCard
                          provided={provided}
                          snapshot={snapshot}
                          groupIndex={groupIndex}
                          cardIndex={cardIndex}
                          groupItemsCount={group.length}
                          card={card}
                          onDeleteTrigger={onDeleteTrigger}
                          onAddEmptyCard={onAddEmptyCard}
                          onDuplicateCard={onDuplicateCard}
                        />
                      )}
                    </Card>
                  </React.Fragment>
                ))}
              </>
            )}
          </Group>
          {boardItems.length !== groupIndex + 1 && (
            <OperatorSelector preId={`g${groupIndex}`} nextId={`g${groupIndex + 1}`} />
          )}
        </>
      ))}

      <DivButton
        onClick={() => {
          onAddNewGroup();
        }}
      />

      <CofirmDialog
        dialogOpen={!!deleteCheck}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteTrigger();
          } else {
            setDeleteCheck(null);
          }
        }}
        title={"Confirm to delete the 'Trigger' ?"}
        text="It will delete the trigger and you will not able to redo it!"
      />
    </Board>
  );
};
