import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  Popover,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@mui/styles';
import {
  CronParser,
  CronState,
  cronSunSetSunRiseParser,
  EXECUTION_LIMIT,
} from '@smartlife-redux-state/common';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import parseCustomApiError from 'src/utility/parseError';
import { DaysOfWeek } from '../../../../checks/TimeCheck';
import { CronRunCountSelector } from '../components/CronRunCountSelector';

const useStyles = makeStyles({
  top: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  dayCard: {
    background: '#818181',
    borderRadius: '2px',
    margin: '2px',
    padding: '5px',
    minWidth: '50px',
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
  },
  dayCardActive: {
    background: '#048696',
  },
  popover: {},
  paper: {
    padding: 5,
  },
  execution_show: {
    padding: '2px',
    textAlign: 'center',
    background: '#ebd234',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    fontSize: '12px',
    color: '#fe0000',
  },
});

const sunValues = [
  { type: 'sun_set', text: 'Sunset' },
  { type: 'sun_rise', text: 'Sunrise' },
];

const TimeDifferenceSelector = ({ sunType, onChange, value }) => {
  const marks = [
    {
      value: 0,
      label: `${sunType === 'sun_set' ? 'Sunset' : 'Sunrise'} [ ${value} min (${(
        Number(value) / 60
      ).toFixed(2)} hrs) ]`,
    },
  ];

  function valuetext(slideValue: number) {
    return `${slideValue} min (${(Number(slideValue) / 60).toFixed(2)} hrs)`;
  }

  return (
    <Box>
      <Box sx={{ width: 300, display: 'flex', alignItems: 'flex-start' }}>
        <Slider
          value={typeof value === 'number' ? value : 0}
          // getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          step={10}
          valueLabelDisplay="auto"
          marks={marks}
          min={-360}
          max={360}
          onChange={(e, val) => onChange(val)}
          aria-labelledby="input-slider"
        />
        {/* <TextField
          type="number"
          value={value}
          variant="outlined"
          size="small"
          onChange={(e) => onChange(e.target.value)}
          inputProps={{
            min: -360,
            max: 360,
            'aria-labelledby': 'input-slider',
          }}
          style={{
            width: 90,
            marginLeft: 10,
          }}
        /> */}
      </Box>
    </Box>
  );
};

// eslint-disable-next-line max-len
const SunsetSunrise: React.FC<{state: any; onChange: any}> = ({ state, onChange }) => {
  const projectId = useQueryStringFirst('projectId');
  const authUser = useContext(AuthContext);
  const styles = useStyles();
  const val = 1;
  const { enqueueSnackbar } = useSnackbar();
  const [showNextExList, setShowNextExList] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [nextExeMap, setNextExeMap] = useState<any[]>([]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, check = false) => {
    if (check && anchorEl != null) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      getNextExecutionList();
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setNextExeMap([]);
  };
  const open = Boolean(anchorEl);

  const onValChanges = (type: string, payload: any) => {
    console.log('value change triggered');
    switch (type) {
      case 'offset': {
        return onChange({
          ...state,
          time_diff: {
            ...payload,
          },
        });
      }
      case 'suntype': {
        return onChange({
          ...state,
          sun_type: payload,
        });
      }
      case 'timediff': {
        console.log(payload);
        return onChange({
          ...state,
          time_diff: {
            ...state.time_diff,
            // eslint-disable-next-line no-nested-ternary
            type: Number(payload) === 0 ? 'during' : payload > 0 ? 'after' : 'before',
            value: payload,
          },
        });
      }
      case 'dayitem': {
        return onChange({
          ...state,
          extra: {
            ...state.extra,
            days: payload,
          },
        });
      }
      case 'weekdays': {
        return onChange({
          ...state,
          extra: {
            ...state.extra,
            weekdays: payload,
          },
        });
      }
      case 'execlimit': {
        console.log('working limit');
        return onChange({
          ...state,
          extra: {
            ...state.extra,
            execution_limit: payload,
          },
        });
      }
      default: {
        console.log('defualt');
        return null;
      }
    }
  };

  const handleDayItemClick = (dKey: string) => {
    const sdlist = { ...state.extra.days };
    if (sdlist[dKey]) {
      if (hasAnotherSelectedDate(sdlist, dKey)) {
        delete sdlist[dKey];
      } else {
        return;
      }

      // setSelectedDays(sdlist);
    } else {
      sdlist[dKey] = true;
      // setSelectedDays(sdlist);
    }
    onValChanges('dayitem', sdlist);
  };

  function hasAnotherSelectedDate(days: {[day: string]: boolean}, tryingToRemove: string) {
    const copy = cloneDeep(days);

    delete copy[tryingToRemove];
    const found = Object.keys(copy).find((key) => copy[key] === true);

    if (found) {
      return true;
    }
    return false;
  }

  const getNextExecutionList = async () => {
    try {
      const trigger = CronParser(state); // cronSunSetSunRiseParser(state);
      const key = uuid();
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/try-it-now`,
        {
          project_id: projectId,
          bypass_parser: true,
          status: 'active',
          name: 'vcheck 1',
          input_json: '7',
          tags: [],
          other_dep_map: {
            userview_ids_map: {},
            logics_ids_map: {},
          },
          triggers: [
            {
              type: 'sunset_sunrise',
              key,
              condition: JSON.stringify({
                ...trigger,
              }),
            },
          ],
          rules: [
            {
              op: '=',
              key: 'bc5793c3-a191-493d-a8b0-2d2f17fd94d1',
              val: 1,
              id: 9999,
              msg: '',
              info: {
                path: '',
                device_id: 'CONST_ALWAYS_ONE',
              },
            },
          ],
          logic: '9999',
          actions: [
            {
              key: '09f4fbe6-ab70-45db-9765-770b847ca610',
              id: 0,
              integration: 'send_notification',
              payload:
                '{"title":"test","plain_message":"test","userview_ids":[],"notification_level":2}',
              child_action_delay: 1,
              child_actions: [],
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${authUser.access_token.token}`,
          },
        },
      );
      if (res && res.data && res.data.result && res.data.result.trigger_eval) {
        const list = res.data.result.trigger_eval;
        if (!(list[key] && list[key].scheduledDates)) return;

        setNextExeMap(list[key].scheduledDates);
      }
    } catch (err: any) {
      const msg = parseCustomApiError(err.response.data && err.response.data.error);
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  return (
    <Box>
      <Box className={styles.top}>
        <Select
          size="small"
          style={{ width: '100px', marginRight: 10 }}
          value={state.time_diff.type}
          onChange={(e) => {
            onValChanges('offset', {
              type: e.target.value,
              // eslint-disable-next-line no-nested-ternary
              value: e.target.value === 'during' ? 0 : e.target.value === 'before' ? -10 : 10,
            });
          }}
        >
          <MenuItem value="during">At</MenuItem>
          <MenuItem value="before">Before</MenuItem>
          <MenuItem value="after">After</MenuItem>
        </Select>
        <Select
          size="small"
          style={{ width: '100px' }}
          value={state.sun_type}
          placeholder="Sunset/Sunrise"
          onChange={(e) => {
            onValChanges('suntype', e.target.value);
          }}
        >
          {sunValues.map((item) => (
            <MenuItem value={item.type} key={item.type}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <TimeDifferenceSelector
          value={state.time_diff.value}
          onChange={(e) => onValChanges('timediff', e)}
          sunType={state.sun_type}
        />
      </Box>
      <Box>
        <Box style={{ marginBottom: 10 }}>
          <Select
            size="small"
            style={{ width: 250, marginRight: 10 }}
            value={state.extra.weekdays}
            onChange={(e) => {
              onValChanges('weekdays', e.target.value);
            }}
            disabled
          >
            <MenuItem value="all_weekdays">All Week Days</MenuItem>
            <MenuItem value="only_selected_weekdays">Only Selected Week Days</MenuItem>
          </Select>
        </Box>
        {state.extra.weekdays === 'only_selected_weekdays' && (
          <Grid container>
            {DaysOfWeek.map((d) => (
              <Box
                className={`${styles.dayCard} ${
                  state.extra && state.extra.days[d.value] ? styles.dayCardActive : ''
                }`}
                onClick={() => handleDayItemClick(d.value)}
                key={d.value}
              >
                {d.label}
              </Box>
            ))}
          </Grid>
        )}
      </Box>
      <Box>
        <CronRunCountSelector
          typekey="type4"
          initialValue={state.extra.execution_limit}
          onChange={(count) => {
            console.log(count);
            onValChanges('execlimit', count);
            // onExecutionLimitChange(count);
          }}
        />
      </Box>
      <Box>
        <Typography
          aria-owns={showNextExList ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
          className={styles.execution_show}
        >
          ?
        </Typography>
        <Popover
          id="mouse-over-popover"
          className={styles.popover}
          classes={{
            paper: styles.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <FormHelperText>Next Executions</FormHelperText>
          {nextExeMap.map((v, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={i}>{v.schaduledDate}</Typography>
          ))}
        </Popover>
      </Box>
    </Box>
  );
};

export default SunsetSunrise;
