/* eslint-disable camelcase */
export interface IProDevice {
  id?: string;
  created_by?: number;

  project_id: number;
  prodc_id: number;

  name: string;

  tags: string[];
  system_tags: string[];

  shadow_type: string;

  cat: string;
  sub_cat: string;
  device_type: string;

  device_status: number;
  item_type?: number;

  settings: string | any;

  created_at?: number | string;
  updated_at?: number | string;
  dc_name?: string;
}

export enum ProDeviceStatus {
  pending = 1,
  active = 2,
  disabled = 3,
}
export interface IFormResetRef {
  ref: number;
  action: string;
  info: any;
}
export enum FormResetAction {
  toDefault = 'toDefault',
  toCurrentInfo = 'toCurrentInfo',
}
