/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { isArray, property } from 'lodash';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress, Typography } from '@mui/material';
import { AuthContext } from '../../../../../store/Auth/AuthContext';
import { CustomDeleteOptions } from './CustomDelete';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(
            props.value,
          )}%`}

        </Typography>
      </Box>
    </Box>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function ConfirmLocDelete(props) {
  const { options } = props;

  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');

  const authUser = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  // const [progress, setProgress] = React.useState(0);

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    setText('');
    // setProgress(0)
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.open) {
      getDeleteInfo(props.loc_id);
    }
  }, [props.open]);

  // useEffect(() => {
  //   if(loading) {
  //     const timer = setInterval(() => {
  //       if(progress >= 100) {
  //         setLoading(false);
  //         return;
  //       }
  //       setProgress((prevProgress) => (prevProgress + 25));
  //     }, 10000);
  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }
  // }, [loading]);

  async function getDeleteInfo(project_id: string) {
    try {
      const { token } = authUser.access_token;
      const res = await axios.post(
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/project/component/v1/info`,
        {
          project_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setInfo(res.data.result);
    } catch (err: any) {
      enqueueSnackbar('Failed to init location wipe', { variant: 'error' });
    }
  }

  async function deleteLoc(project_id: string) {
    try {
      const { air_dcs, pro_dcs, userviews } = info;

      const pro_dcs_list = [];
      const air_dcs_list = [];
      const userview_list = [];

      if (pro_dcs && isArray(pro_dcs)) {
        pro_dcs.map((pro) => pro_dcs_list.push(pro.id));
      }
      if (air_dcs && isArray(air_dcs)) {
        air_dcs.map((air) => air_dcs_list.push(air.id));
      }
      if (userviews && isArray(userviews)) {
        userviews.map((userview) => userview_list.push(userview.id));
      }

      const { token } = authUser.access_token;
      setLoading(true);

      const clean_config = {
        air_dcs: air_dcs_list,
        air_devices_in_dc: [],
        pro_dcs: pro_dcs_list,
        pro_devices_in_dc: [],
        userviews: [],
        userview_items_in_uv: [],
        location_vars: true,
        logics: true,
        location: true,
        pro_hubs_online_check: false,
      };

      if (props.isCustom) {
        if (options.air_dcs) {
          clean_config.air_dcs = air_dcs_list;
        } else {
          clean_config.air_dcs = [];
        }
        if (options.air_devices_in_dc) {
          clean_config.air_devices_in_dc = air_dcs_list;
        } else {
          clean_config.air_devices_in_dc = [];
        }
        if (options.pro_dcs) {
          clean_config.pro_dcs = pro_dcs_list;
        } else {
          clean_config.pro_dcs = [];
        }
        if (options.pro_devices_in_dc) {
          clean_config.pro_devices_in_dc = pro_dcs_list;
        } else {
          clean_config.pro_devices_in_dc = [];
        }
        if (options.userviews) {
          clean_config.userviews = userview_list;
        } else {
          clean_config.userviews = [];
        }
        if (options.userview_items_in_uv) {
          clean_config.userview_items_in_uv = userview_list;
        } else {
          clean_config.userview_items_in_uv = [];
        }
        if (options.location_vars) {
          clean_config.location_vars = true;
        } else {
          clean_config.location_vars = false;
        }
        if (options.logics) {
          clean_config.logics = true;
        } else {
          clean_config.logics = false;
        }
        if (options.location) {
          clean_config.location = true;
        } else {
          clean_config.location = false;
        }
      }
      const res = await axios.post(
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/project/component/v1/clean`,
        {
          project_id,
          clean_config,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setTimeout(() => {
        setLoading(false);
        enqueueSnackbar('Start Redirecting...', { variant: 'info' });
        handleClose();

        if (props.isCustom) {
          props.setOptions({
            air_dcs: false,
            air_devices_in_dc: false,
            pro_dcs: false,
            pro_devices_in_dc: false,
            userviews: false,
            userview_items_in_uv: false,
            location_vars: false,
            logics: false,
            location: false,
            pro_hubs_online_check: false,
          });
        }

        if (!props.isCustom) {
          window.location.href = '/app';
        }
      }, 30000);

      enqueueSnackbar(
        'Location Deletion Process is Running in the Background. It might take few minutes to delete the location',
        { variant: 'info' },
      );
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  return (
    <Dialog open={props.open}>
      {loading ? (
        <DialogTitle>Deleting...</DialogTitle>
      ) : (
        <DialogTitle>
          {props.isCustom ? 'Confirm Custom Location Delete' : 'Confirm Location Delete'}
        </DialogTitle>
      )}

      <DialogContent>
        {loading && (
          <>
            <LinearProgress color="secondary" />
            {/* <LinearProgressWithLabel value={progress} /> */}
          </>
        )}
        {loading ? (
          <DialogContentText>
            {props.isCustom
              ? 'Deleting the selected items in background. This might take upto 30 seconds. Please wait..'
              : ' Deleting the project in background. This might take upto 30 seconds. Please wait...'}
          </DialogContentText>
        ) : (
          <DialogContentText>
            {props.isCustom
              ? 'You are about to wipe selected items in the location named'
              : 'You are about to wipe the location named'}
            ,
            {' '}
            <span style={{ fontWeight: 600 }}>{props.loc_name}</span>
            . If you wish to proceed please
            type the name of the location and click delete.
          </DialogContentText>
        )}

        <TextField
          onChange={(e) => setText(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="Location Name"
          fullWidth
          variant="standard"
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => deleteLoc(props.loc_id)}
          disabled={text !== props.loc_name || loading}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
