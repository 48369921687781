import {
  AirDevice,
  DeviceCollectionSearchOut,
  DeviceCollectionSearchOutItem,
  DeviceSearchOut,
} from '@smartlife-redux-state/common';
import { isArray } from 'lodash';
import {
  CLOUD_INTEGRATIONS,
  CiDevice,
  CiDeviceCollectionListResponse,
  CiDeviceContainer,
  CiGetDeviceCollectionDeviceResponse,
} from './cloud-integration.service';

export class CloudIntegrationLogicMapper {
  public convertCloudDeviceContainersToReduxFriendly(
    cloudDeviceContainers: CiDeviceCollectionListResponse,
  ): DeviceCollectionSearchOut {
    const convertedResult: DeviceCollectionSearchOutItem[] = [];

    if (isArray(cloudDeviceContainers.result.result)) {
      cloudDeviceContainers.result.result.map((item) => {
        const converted = this.convertCloudDeviceContainerToReduxFriendly(item);
        convertedResult.push(converted);
      });
    }

    return {
      limit: cloudDeviceContainers.result.limit,
      skip: cloudDeviceContainers.result.skip,
      resultCount: cloudDeviceContainers.result.resultCount,
      result: convertedResult,
    };
  }

  public convertCloudIntContainerDevicesToReduxFriendly(
    arg: CiGetDeviceCollectionDeviceResponse,
  ): DeviceSearchOut {
    // TODO: In future AirDevice will also transformed into cloud integrations.
    // so technically we can think of other integrations shelly, enodes as air type
    // this way we can just update the code parts in logic package only related with
    // type AirDevice to CloudDevice when it time to transform air to cloud integrations
    const convertedResult: AirDevice[] = [];

    if (isArray(arg.result.devices)) {
      arg.result.devices.map((item) => {
        const converted = this.convertCloudDeviceToReduxFriendly(item);
        convertedResult.push(converted);
      });
    }

    return {
      resultCount: arg.result.devices.length,
      result: convertedResult,
    };
  }

  /**
   * Note that the converted payloads has (-1) value for dc_cat. This value can use to identify
   * the cloud integration type. ( if dc_type == -1 then use dc_type to get the integration type )
   * @param cContainer
   * @returns
   */
  public convertCloudDeviceContainerToReduxFriendly(
    cContainer: CiDeviceContainer,
  ): DeviceCollectionSearchOutItem {
    return {
      __typename: 'DeviceCollectionSearchOutItem',
      id: cContainer.id,
      dc_cat: -1,
      created_by: cContainer.created_by,
      project_id: cContainer.project_id,
      name: cContainer.name,
      description: cContainer.description,
      image: null,
      unique_key: null,
      dc_type: Number(cContainer.cint_type),
      dc_status: cContainer.cint_status,
      created_at: cContainer.created_at,
      updated_at: cContainer.updated_at,
      has_verification_error: null,
    };
  }

  public convertCloudDeviceToReduxFriendly(cDevice: CiDevice): AirDevice {
    let settings = null;
    try {
      settings = JSON.stringify(cDevice.settings);
    } catch (err) {}
    return {
      __typename: 'AirDevice',
      id: cDevice.id,
      created_by: null,
      project_id: cDevice.project_id,
      airdc_id: cDevice.cint_coll,
      tuya_device_id: cDevice.cint_device_id,
      name: cDevice.name,
      tags: cDevice.tags,
      system_tags: cDevice.system_tags,
      shadow_type: 'cloudIntegration',
      cat: cDevice.cat,
      sub_cat: null,
      device_type: cDevice.cat,
      device_status: cDevice.device_status,
      settings,
      config: null,
      created_at: cDevice.created_at,
      updated_at: cDevice.updated_at,
      dc_name: null,
      has_verification_error: null,
    };
  }

  private getCloudIntegration(type: number) {
    if (type == CLOUD_INTEGRATIONS.ENODE) {
      return 'enode';
    }

    if (type == CLOUD_INTEGRATIONS.SHELLY) {
      return 'shelly';
    }

    return null;
  }

  public getCloudIntegrationOfConvertedPayload(payload: DeviceCollectionSearchOutItem): string {
    if (payload.dc_cat !== -1) {
      return null;
    }

    if (payload.dc_type == CLOUD_INTEGRATIONS.ENODE) {
      return 'enode';
    }

    if (payload.dc_type == CLOUD_INTEGRATIONS.SHELLY) {
      return 'shelly';
    }
  }
}
