/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import styles from './UVChildItemNotificationSelector.module.css';
import { IUserViewItem } from '../../../types/types';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { USERVIEW_NOTIFICATION_TYPES } from '../../../../../config/deviceConfig/Values';
import { IUVChildItemNotification, IUVChildItemNotifications } from './types';

type ComponentProps = {
  fieldConfig: any;
  title: string;
  fieldKey: string;
  uvItem: IUserViewItem | null;
  onChange: (payload: any) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  updateCustomFieldErrors: (error: any) => void;
};

const alertTypeLabels = {
  notify_alert: 'Alert (Burglary, Fire …)',
  notify_warning: 'Warning (Mains, Battery …)',
  notify_info: 'Info (Arm, Disarm …)',
};

// eslint-disable-next-line import/prefer-default-export
export const UVChildItemNotificationSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [childItems, setChildItems] = useState<IUVChildItemNotification[]>([]);
  const [resetSelectedBtn, resetSelectedNow] = useState<number>();

  const loadCurrentInfo = async () => {
    try {
      if (props.uvItem && props.uvItem.settings && props.uvItem.settings[props.fieldKey]) {
        console.log('1default child notifications', props.fieldConfig);
        const childItems_: IUVChildItemNotifications = props.uvItem.settings[props.fieldKey];

        if (childItems_ && childItems_.items) {
          console.log('props.fieldConfig', childItems_.items);
          // console.log('cameraConfig', props.uvItem.settings, props.fieldKey, cameraConfig);
          const initSort = notifyArraySort(childItems_.items);
          const finalSort = notifyArraySort(initSort);
          setChildItems(finalSort);
        }
      } else {
        const initSort = notifyArraySort(props.fieldConfig.items);
        const finalSort = notifyArraySort(initSort);
        setChildItems(finalSort);
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    loadCurrentInfo();
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.uvItem]);

  const notifyArraySort = (array: any) => {
    const sortedArr = [];
    array.forEach((e: any) => {
      if (e.notification === 4) {
        sortedArr.unshift(e);
      } else if (e.notification === 5) {
        sortedArr.unshift(e);
      } else {
        sortedArr.push(e);
      }
    });
    return sortedArr;
  };

  useEffect(() => {
    if (props.fieldConfig == null) {
      return;
    }
    const data: any = {};
    data[props.fieldKey] = {
      items: childItems,
    };

    props.onChange({
      action: 'data',
      form: props.fieldKey,
      data,
      errors: {},
    });
  }, [childItems]);

  const nameFormat = (input: string) => input.split('_').join(' ');
  const updateValue = (key: string, value: number) => {
    setChildItems(
      childItems.map((v) => {
        if (v.name_prefix == key) {
          v.notification = value;
        }
        return v;
      }),
    );
  };
  return (
    <div className={`${styles.root} root-border pt-2`}>
      <div className="pl-1">Child items notifications:</div>
      <div className="row">
        <div className="col-md-12 pt-2">
          <div className={styles.textInput}>
            {childItems.map((item) => (
              <div className="row p-2">
                <div className="col-md-5">
                  {alertTypeLabels[item.name_prefix]}
                  {' '}
                  :
                </div>
                <div className="col-md-7 pb-1">
                  <FormControl
                    size="small"
                    key={item.name_prefix}
                    variant="outlined"
                    className="dynamic-ui-form-control-full-width"
                  >
                    <InputLabel id="1custom_selector-label1">Notification Type</InputLabel>
                    <Select
                      labelId="1custom_selector-label1"
                      value={item.notification}
                      name="item_notification_type"
                      onChange={(e: any) => {
                        updateValue(item.name_prefix, e.target.value);
                      }}
                      label="Notification Type"
                    >
                      {USERVIEW_NOTIFICATION_TYPES.map((notifyType) => (
                        <MenuItem key={`${notifyType.value}`} value={notifyType.value}>
                          {notifyType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
