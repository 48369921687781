import CloudIntegration from '../shared/services/cloud-integration.service';

interface iRandomInterfacce {
    result: {
        url: string;
    },
    status: string;
}

export interface iAirIntegration extends CloudIntegration {
  integrationSpecificMethod: (dcId: string) => Promise<iRandomInterfacce>
}

class AirIntegration extends CloudIntegration implements iAirIntegration {
  public async integrationSpecificMethod(dcId: string) {
    const res = await this.axiosClient.post('/random-url', {
      dc_id: dcId,
    });
    return res.data as iRandomInterfacce;
  }
}

export default AirIntegration;
