import axios, { AxiosInstance } from 'axios';
import AirPlugin from './air.plugin';
import EnodePlugin from './enode.plugin';
import { EventManager } from './shared/Plugin';
import CloudIntegration from './shared/services/cloud-integration.service';
import ShellyPlugin from './shelly.plugin';
import CloudDeviceEdit from './shared/react-components/CloudDeviceEdit';

class SharedReactComponents {
  private cloudDeviceEditUi = CloudDeviceEdit;

  public get CloudDeviceEditUi() {
    return this.cloudDeviceEditUi;
  }
}

class PluginHook {
  private axiosClient: AxiosInstance;
  private eventManager = new EventManager();

  commonIntegrationService: CloudIntegration;
  sharedUiComponents: SharedReactComponents;
  airPlugin: AirPlugin;
  shellyPlugin: ShellyPlugin;
  enodePlugin: EnodePlugin;

  constructor(token: string) {
    this.axiosClient = axios.create({
      baseURL: `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/rest/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    this.sharedUiComponents = new SharedReactComponents();
    this.shellyPlugin = new ShellyPlugin(this.axiosClient, this.eventManager);
    this.airPlugin = new AirPlugin(this.axiosClient, this.eventManager);
    this.enodePlugin = new EnodePlugin(this.axiosClient, this.eventManager);

    this.commonIntegrationService = new CloudIntegration(this.axiosClient, this.eventManager);
  }

  public get EventManager() {
    return this.eventManager;
  }
}

export default PluginHook;