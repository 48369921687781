// eslint-disable-next-line import/prefer-default-export
export const APP_TEXT = {
  genericErrors: {
    GENERIC_ERROR_1: 'Something went wrong:(',
    PAGE_NOT_FOUND_TITLE: 'Page Not Found :(',
    PAGE_NOT_FOUND: "Oops. Looks like the page you're looking for no longer exists",
  },
  genericTexts: {
    HOME_REDIRECT: 'Back to Home',
  },
  loading: {
    SMART_HOMES_LIFESTYLES: 'Smart Homes & Lifestyles',
  },
  auth: {
    SMARTLIFE_PRO: 'Smartlife Connect',
    WELCOME_TO_SMARTLIFE_PRO: 'Welcome to Smartlife Connect',
    ADMIN_PANEL: 'Admin Panel',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    LOGIN_NOW: 'Login now',
    DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
    CREATE_AN_ACCOUNT: 'Create an account',
    CREATE_ACCOUNT: 'Create account',
    SIGN_IN: 'Sign in',
    LOGIN: 'Login',
    WELCOME_BACK: 'Welcome Back',
    NOT_USER: 'Not User?',
    FORGOTTEN_PASSWORD: 'Forgotten password?',
    RESET_PASSWORD: 'Reset Password',
    RESET_PASSWORD_NOW: 'Reset Password',
    EMAIL_LINK: 'Email Link',
    DONE_PWRESET_SNACKBAR: 'Password change request successfully sent',
    DONE_PWRESET_1: 'Password change request successfully sent to your email,',
    DONE_PWRESET_2: 'Please check your email',
    NAME: 'Name',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    PHONE_NUMBER: 'Phone Number',
    ADDRESS: 'Address',
    COUNTRY: 'Country',
    CITY: 'City',
    OR: 'OR',
    LOGIN_WITH_EMAIL_LINK: 'Login with email link',
    SIGN_IN_WITH_FACEBOOK: 'Sign in with Facebook',
    SIGN_IN_WITH_TWITTER: 'Sign in with Twitter',
    SEND_EMAIL_LINK: 'Send email',
    EMAIL_SENT_PLEASE_CHECK_EMAIL_INBOX: 'Link Sent, Please check your Email inbox',
    PROVIDE_EMAIL: 'Please provide your email for confirmation',
  },
  sidebar: {
    DASHBOARD: 'Dashboard',
    DEVICE_CONTAINERS: 'Device Collections',
    ENERRGY: 'Energy Monitoring',
    MEMBERS: 'Members',
    USER_VIEWS: 'User Views',
    PLUGINS: 'Plugins',
    LOGICS: 'Logic',
    BACKUP_RESTORE: 'Templates & Backups',
    TAGS: 'Tags',
    LOGS: 'Logs',
    HUB_FINDER: 'Hub Finder',
    HUB_STATS: 'Admin Reports',
    INVITATIONS: 'Invitations',
    SETTINGS: 'Settings',
    SIGN_OUT: 'Sign Out',
    LISTS: 'Smart Lists',
  },
  header: {
    VERIFY_EMAIL_ALERT: 'Please verify your email address,',
    VERIFY_EMAIL_ALERT_SENT: 'Verification email sent successfull , Please check your email inbox',
    RESEND_LINK: 'Resend Link',
    REFRESH_PAGE: 'Refresh page',
    OR: 'Or',
    PROFILE: 'Profile',
    SIGN_OUT: 'Sign Out',
  },
  profile: {
    PROFILE: 'Profile',
    NAME: 'Name',
    UPDATE: 'Update',
    UPLOAD_NEW_PHOTO: 'Upload New Photo',
    PROFILE_UPDATED: 'Profile Updated',
    PROFILE_PHOTO_UPDATED: 'Profile PHOTO Updated',
  },
  location: {
    RECENT_TITLE: 'Recent Locations',
    ADD_BTN: 'Add a Location',
    LOCATION_SEARCH: 'Search Locations',
    ALL_LINK: 'View All Locations',
    All_TITLE: 'All Locations',
    LOCATION_TYPE: 'Location Type',
    TYPE1_TITLE: 'SmartLife Connect Custom Location',
    TYPE1_DESC: 'Select this to build a custom location from ground up',
    LOREM: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam ',
    TYPE2_TITLE: 'SmartLife Care Templates',
    TYPE2_DESC: 'Select to start a location based on Smartlife Care template',
    TYPE3_TITLE: 'Group Housing Template',
    TYPE3_DESC: 'Select to start a location based on Smartlife Group Housing template ',
    TYPE4_TITLE: 'SmartLife Connect Demo Location',
    LOCATION_DETAILS: 'Location Details',
    CHOOSE_LOCATION: 'Choose a location type to continue',
    CONTINUE: 'Continue',
    CANCEL: 'Cancel',
    ENTER_LOCATION_DETAILS: 'Enter Location Details',
    UPDATE_LOCATION_DETAILS: 'Location Update',
    LOCATION_NAME: 'Location Name',
    LOCATION_DESCIPTION: 'Location Description',
    CREATE: 'Create Location',
    UPDATE: 'Update Location',
    DELETE: 'Delete',
    WIPE: 'Wipe Everything',
    WIPE_CUSTOM: 'Wipe Selected Parts',
    FORM_FIELD_NAME: 'Location Name',
    FORM_FIELD_DESCRIPTION: 'Location Description',
    FORM_FIELD_IMG: 'Drag & Drop Location Image',
    LOCATION_CREATE_SUCCESS: 'New location successfully created!',
    LOCATION_UPDATE_SUCCESS: 'Location details successfully updated!',
    LOCATION_CREATE_ERROR: 'Location creation failed!',
    LOCATION_DELETE_SUCCESS: 'Location successfully deleted!',
    LOCATION_DELETE_ERROR: 'Location deletion failed!',
    LOCATION_DASH_USER_VIEWS_TITLE: 'User Views',
    LOCATION_DASH_DEVICE_STATUS_TITLE: 'Device Status',
    LOCATION_DASH_PRO_DEVICE_CONTAINERS_TITLE: 'PRO Collection',
    LOCATION_DASH_AIR_DEVICE_CONTAINERS_TITLE: 'AIR Collection',
    LOCATION_DASH_DEVICE_CONTAINERS_TITLE: 'Device Collection',
    LOCATION_DASH_LOGS_TITLE: 'Logs',
    NO_LOCATIONS: 'No Locations Available!',
    LOCATION_SELECT_ERR: 'Selected location not available!',
  },
  members: {
    ADD_MEMBER: 'Add a new Member',
    EDIT_MEMBER: 'Edit Member',
    All_TITLE: 'Manage Members',
    ALL: 'All',
    MEMBER_TYPE1: 'Location Owner',
    MEMBER_TYPE2: 'Installer',
    MEMBER_TYPE3: 'Home Owner',
    MEMBER_TYPE4: 'Viewer',
    MEMBER_TYPE5: 'Pending',
    MEMBER_TYPE6: 'Invitation',
    MEMBER_TYPE7: 'Shareable Viewer',
    MEMBER_DETAILS: 'Member Details',
    ASSIGN_USER_VIEWS: 'Assign User Views',
    ADD_MEMBER_BTN: 'Add Member',
    EDIT_MEMBER_BTN: 'Update User',
    DELETE_MEMBER_BTN: 'Remove User',
    CANCEL_MEMBER_BTN: 'Cancel',
    FORM_FIELD_MEMBER_EMAIL: 'Email',
    FORM_FIELD_PERMISSION_TYPE: 'Permission Type',
    FORM_FIELD_MEMBER_DESCIPTION: 'Desciption',
    MEMBER_CREATE_SUCCESS: 'New member successfully added!',
    MEMBER_UPDATE_SUCCESS: 'Member successfully updated!',
    MEMBER_DELETE_SUCCESS: 'Member successfully deleted!',
    FORM_FIELD_MEMBER_LOCATION_PERMISSION: 'Select Location Permission',
    MEMBER_PERMISSION_TYPE_ERROR: 'Permission type required!',
    MEMBER_CREATE_ERROR: 'Failed to add new member!',
    MEMBER_UPDATE_ERROR: 'Failed to update member!',
    MEMBER_DELETE_ERROR: 'Failed to delete member!',
    MEMBER_DELETE_ERROR2: "You can't delete location owner!",
    MEMBER_ERROR: 'Something went wrong!',
    PENDING: 'Pending Member',
  },
  user_view: {
    ADD_USER_VIEW: 'Add a User View',
    UPDATE_USER_VIEW: 'Update User View',
    ADD_INTERFACE: 'Add an Inteface Item',
    ADD_FOLDER: 'Add a Folder',
    All_TITLE: 'Manage User Views',
    ALL: 'All',
    USER_VIEW_TYPE: 'Select Type',
    CONTINUE: 'Continue',
    USER_VIEW_SEARCH: 'User View Name',
    USER_VIEW_DETAILS: 'User View Details',
    ASSIGN_USER_VIEWS: 'Assign User Views',
    ADD_USER_VIEW_BTN: 'Add User View',
    DELETE_USER_VIEW_BTN: 'Delete',
    UPDATE_USER_VIEW_BTN: 'Update User View',
    CANCEL_MEMBER_BTN: 'Cancel',
    FORM_FIELD_MEMBER_EMAIL: 'Email',
    FORM_FIELD_USER_VIEW_SELECT_ICON: 'Select ICON',
    FORM_FIELD_USER_VIEW_NAME: 'User View Name',
    USER_VIEW_CREATE_SUCCESS: 'New user view successfully added!',
    USER_VIEW_EDIT_SUCCESS: 'User view edit successfully',
    USER_VIEW_CREATE_ERROR: 'Failed to add new user view!',
    USER_VIEW_UPDATE_SUCCESS: 'User view successfully updated!',
    USER_VIEW_UPDATE_ERROR: 'Failed to updated the user view!',
    USER_VIEW_DELETE_SUCCESS: 'User View successfully deleted!',
    USER_VIEW_DELETE_ERROR: 'Failed to delete user view!',
    USER_VIEW_TAB1: 'INTERFACES',
    USER_VIEW_TAB2: 'DETAILS',
    USER_VIEW_TAB3: 'MANAGE',
    INTERFACE_TYPE_1_TITLE: 'Folder',
    INTERFACE_TYPE_1_DESCRIPTION: 'Folder Description',
    INTERFACE_TYPE_2_TITLE: 'Item',
    INTERFACE_TYPE_2_DESCRIPTION: 'Item Description',
    INTERFACE_TYPE_3_TITLE: 'Smart Componenet',
    INTERFACE_TYPE_3_DESCRIPTION: 'Smart Componenet Description',
    INTERFACE_TYPE_4_TITLE: 'Link to Exsisting User View',
    INTERFACE_TYPE_4_DESCRIPTION: 'Link to Exsisting User View Description',
    INTERFACE_TYPE_5_TITLE: 'Link to Exsisting Item',
    INTERFACE_TYPE_5_DESCRIPTION: 'Link to Exsisting Item Description',
  },
  invitations: {
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    AVAILABLE_INVITATIONS: 'Available Invitations',
    INVITATIONS_AVAILABLE: 'No Invitations Available!',
    INVITATION_APPROVE_SUCCESS: 'Invitation successfully approved!',
    INVITATION_REJECT_SUCCESS: 'Invitation successfully rejected!',
    INVITATION_ERROR: 'Action failed!',
  },
  device_container: {
    ADD_DEVICE_CONTAINER: 'Add a Device Collection',
    ADD_DEVICE_CONTAINER2: 'Add New Device Collection',
    All_TITLE: 'Device Collections Management',
    ALL: 'All',
    DC_NOT_AVAILABLE: 'No Devices Collection Available!',
    SML_PRO_NOT_AVAILABLE: 'No SmartLife Connect Devices Available!',
    SML_AIR_NOT_AVAILABLE: 'No SmartLife Air Devices Available!',
    LIST_TAB1: 'CONNECT Devices',
    LIST_TAB2: 'AIR Devices',
    SEARCH_DC: 'Search Device Collection',
    SEARCH_PRO: 'Search Connect Device',
    SEARCH_AIR: 'Search Air Device',
    CONTAINER_TYPE: 'Collection Type',
    EDIT_BUTTON: 'Edit',
    SAVE_BUTTON: 'Save',
    SYNC_BUTTON: 'Sync Now',
    DELETE_HUB_BUTTON: 'Delete Hub',
    CLEAR_HUB: 'Clear Hub',
    DELETE_ACCOUNT: 'Delete Account',

    HUB_TYPE: 'Hub Type',
    HUB_TYPE1: 'SmartLife Hub',
    HUB_TYPE2: 'SmartLife V7+ Hub',
    HUB_TYPE3: 'SmartLife V8 5IR Hub',
    HUB_TYPE4: 'SmartLife V8 5IR Hub',
    HUB_TYPE5: 'SmartLife V8 5IR DEMO Hub',

    SMARTLIFE_HUB: 'SmartLife Hub',
    SMARTLIFE_AIR: 'SmartLife Air',
    SMARTLIFE_SHELLY: 'Smartlife Shelly',
    SMARTLIFE_ENODE: 'Smartlife Enode',

    SMARTLIFE_HUB_ACCOUNT: 'SmartLife Hub Account',
    SMARTLIFE_AIR_ACCOUNT: 'SmartLife Air Account',

    SMARTLIFE_LOCATION_VARIABLES: 'SmartLife Location Variables',

    HUB_DETAILS: 'Hub Details',
    DETAILS: 'Details',
    FORM_FIELD_HUB_NAME: 'Hub Name',
    FORM_FIELD_HUB_SERIAL_NUMBER: 'Hub Serial Number',
    FORM_FIELD_HUB_DESCRIPTION: 'Hub Description',

    SMARTLIFE_AIR_ACCOUNT_DETAILS: 'SmartLife Air Account Details',
    SMARTLIFE_AIR_EMAIL_VALIDATION: 'SmartLife Air Email Validation',
    FORM_FIELD_USER_NAME: 'Email',
    FORM_FIELD_PASSWORD: 'Password',
    FORM_FIELD_OTP: 'OTP',
    FORM_FIELD_NAME: 'AIR Collection Name',

    VALIDATE_EMAIL_CONTAINER_BTN: 'Validate Email',

    ADD_PRO_DEVICE_CONTAINER_BTN: 'Create Hub Collection',
    ADD_AIR_DEVICE_CONTAINER_BTN: 'Create Air Collection',
    REQUEST_OTP_BTN: 'Request OTP',
    CANCEL_BTN: 'Cancel',
    CONTINUE_BTN: 'Continue',

    DEVICE_CONTAINER_AIR_OTP_REQUEST_SUCCESS: 'Please check your email for OTP Code!',
    DEVICE_CONTAINER_AIR_OTP_REQUEST_ERROR: 'OTP request is failed!',

    DEVICE_CONTAINER_AIR__CREATE_SUCCESS: 'New Air device collection view successfully added!',
    DEVICE_CONTAINER_AIR__CREATE_ERROR: 'Failed to add device collection!',

    DEVICE_CONTAINER_PRO__CREATE_SUCCESS: 'New Connect device collection successfully added!',
    DEVICE_CONTAINER_PRO__CREATE_ERROR: 'Failed to add new pro device collection!',

    SINGLE_AIR_TAB1: 'Linked Devices',
    SINGLE_AIR_TAB2: 'Available Devices',
    SINGLE_AIR_TAB3: 'Collection Details',
    SINGLE_AIR_TAB4: 'Remote Connect',

    DELETE_SUCCESS: 'Collection Deleted',
    DELETE_FAILED: 'Collection Delete failed',

    UPDATE_SUCCESS: 'Collection details successfully updated!',
    UPDATE_FAILED: 'Collection update failed!',

    ADD_DEVICE: 'ADD A NEW DEVICE', // Add a Device',
    ADD_VARIABLE: 'ADD A NEW VARIABLE', // Add Variable',

    DUMMY_DEVICE_LIST_NAME: 'SmartLife V8 5lr Hub',
  },
  tag: {
    TAG: 'Tags',
    FORM_FIELD_TAG: 'Tag Label',
    FORM_BTN_TAG: 'Create',
    TAGS_AVAILABLE: 'No Tags Available!',
    TAGS_DELETE_SUCCESS: 'Tag successfully deleted!',
    TAGS_DELETE_ERROR: 'Failed to delete the selected tag!',
    TAGS_CREATE_SUCCESS: 'Tag successfully created!',
    TAGS_CREATE_ERROR: 'Failed to add new tag!',
  },
  settings: {
    SETTINGS: 'Settings',
    FIND: 'FIND',
    CLEAR: 'CLEAR',
    HF_LABEL1: 'Find the Location and Members',
    HF_LABEL2: 'Location Information',
    HF_LABEL3: 'Location Members',
    HF_LABEL4: 'Hub is not found!',
  },
  device: {
    tcp: {
      TEXT1: 'Size Modal',
      TEXT2: 'Add a',
    },
  },
};
