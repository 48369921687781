import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControlLabel,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomSwitch } from '../../../../../../../shared/Switch/Swtich';
import { IUserViewItem } from '../../../../../../types/types';
import { DeviceCollectionType, ICommDevice } from '../../../../../UVDeviceFinder/types';

type ComponentProps = {
  selectedDevice: ICommDevice;
  uvItem: IUserViewItem;
  onChange: (data: any) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
  },
}));
// eslint-disable-next-line import/prefer-default-export
export const UVItemAdvanceOptions: React.FunctionComponent<ComponentProps> = ({
  selectedDevice,
  uvItem,
  onChange,
}) => {
  const classes = useStyles();

  const [commandValidation, setCommandValidation] = useState(false);
  const [cvEnable, setCV] = useState(false);
  useEffect(() => {
    if (!uvItem || !uvItem.settings || !uvItem.settings.advance_options) {
      return;
    }

    const advanceOptions = uvItem.settings.advance_options;
    setCommandValidation(advanceOptions.command_validation);
  }, [uvItem]);
  useEffect(() => {
    if (selectedDevice && selectedDevice.dc_type === DeviceCollectionType.airDevice) {
      setCV(true);
    } else {
      setCV(false);
    }
  }, [selectedDevice]);

  useEffect(() => {
    const optionsData = {
      command_validation: commandValidation,
      command_validation_timeout: 100,
    };
    onChange(optionsData);
  }, [commandValidation]);

  const haveAdvanceOptions = cvEnable;
  return (
    <div>
      {haveAdvanceOptions && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Advance Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {cvEnable && (
              <FormControlLabel
                className="pl-8 noselect"
                control={(
                  <CustomSwitch
                    name="visibility"
                    value={commandValidation}
                    onChange={() => {
                      setCommandValidation(!commandValidation);
                    }}
                  />
                )}
                label="Command validation"
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
