/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  capitalize,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';

import { cloneDeep } from 'lodash';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import styles from './AirDeviceCreateEdit.module.css';

import '../ProDeviceCreateEdit/dynamic-ui.css';
import GreenButton from '../../../components/shared/buttons/AuthButton/GreenButton';
import {
  appApiErrorMessage,
  appConsoleError,
  appConsoleLog,
  cloneObject,
} from '../../../utility/appUtils';
import airDeviceService from './services/air-device.service';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import { IAirDevice, ITuyaDevice, ITuyaFuntion } from './types';
import { LoadingComponent } from '../../../components/shared/Loading/Loading';
import airDeviceUpdateUtils from '../DynamicDeviceCreate/utils/utils';
import { FormResetAction, IFormResetRef } from '../ProDeviceCreateEdit/types';
import { IDeviceCategory } from '../../../config/deviceConfig/types';
import { ITEM_DEFAULTS } from '../../../config/deviceConfig/item_defaults';
import ProDeviceNameCraeteComponent from '../DynamicDeviceCreate/DeviceFeatures/DeviceNameCreate/DeviceNameCreate';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../components/Alters/Snackbar/SnackbarOptions';

import { useQueryStringFirst } from '../../../utility/CustomHooks/useQueryNumber';
import { FieldCard } from '../DynamicDeviceCreate/DeviceFeatures/FieldCard/FieldCard';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import PageContentLayout, { PageConentHeader } from '../LogicsEngine/components/PageContentLayout';
import AirDeviceChannels from './components/AirDeviceChannels';

const defaultMap = (): any => ({});
const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    marginBottom: '4%',
    overflow: 'scroll',
  },
  root: {
    // height: "100%",
    display: 'flex',
    // width: '50%',
    flexDirection: 'column',
    flex: 1,
  },
  formRoot: {
    width: '100%',
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const DeviceEnergyMonitoringConfiguration = pluginHook.airPlugin.uiComponents.getDeviceEnergyConfigUi;

export const AirDeviceCreateEdit: React.FunctionComponent<any> = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyle();

  const userProfile = useContext(ApolloAuthContext);

  const selectedProjectId = useQueryString('projectId') as string;
  const selectedDcId = useQueryString('dcId') as string;
  const selectedDeviceId = useQueryString('deviceId') as string;
  const selectedDCName = useQueryString('dcName');
  const selectedAirDeviceExternalId = useQueryStringFirst('airDeviceExternalId');

  const navigateRoute = (path: string) => {
    navigate(path);
  };
  const getDefaultDeviceInfo = () => airDeviceUpdateUtils.defaultAirDeviceInfo('', '', '', [], {});
  const [tuyaDevice, setTuyaDevice] = useState<ITuyaDevice | null>(null);
  const [deviceApiResponse, setDeviceInfoFromAPI] = useState<any>({});
  const [device, setDeviceInfo] = useState(getDefaultDeviceInfo);
  const [templateId, setTemplateId] = useState<string>(null);

  const [hdcs, setHdcs] = useState<any>();
  const [deviceFunctions, setDeviceFunctions] = useState<ITuyaFuntion[]>([]);
  const [deviceFunctionsMap, setDeviceFunctionsMap] = useState<{[key: string]: ITuyaFuntion}>({});
  const [currentFunctionCode, setCurrentFunctionCode] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState(false);

  const [cat, setCat] = useState(airDeviceUpdateUtils.defaultAirCategory);
  const [customDefaults, setcustomDefaults] = useState<{[key: string]: any}>({});
  const [formValidate, detectFormValidate] = useState(0);
  const [resetRef, resetFormsAgain] = useState<IFormResetRef>({
    ref: 0,
    action: '-',
    info: {},
  });
  const [payload, detectPayloadPush] = useState<any>(null);
  const [infoValidations, setInfoValidations] = useState(defaultMap);
  const [selectedFieldsMap, setSelectedFieldsMap] = useState<{
    [key: string]: ITuyaFuntion;
  }>({});

  const [airDeviceTypeList, setAirDeviceTypeList] = useState<string[]>([]);
  const [deviceType, setDeviceType] = useState<string>('');

  const [airDeviceSubTypeList, setAirDeviceSubTypeList] = useState<string[]>([]);
  const [airDeviceCustomFieldVisibility, setAirDeviceCustomFieldVisibility] = useState<boolean>(true);
  const [isAirChannelsCustomForm, setisAirChannelsCustomForm] = useState(true); // TODO: temporaly disabled. set to 'false' once enabled

  const [deviceSubType, setDeviceSubType] = useState<string>('');
  const [sliderVisibility, setSliderVisibility] = useState<boolean>(true);
  const [showControls, setShowControls] = useState<boolean>(true);

  const [blockEdit, setBlockEdit] = useState<boolean>(false);
  const airDeviceTypeConfigs: any = ITEM_DEFAULTS.ITEM_CREATOR.AIR_DEVICES.configs;

  const [showPower, setShowPower] = useState<boolean>(false);

  const reDefineFields = () => {
    if (tuyaDevice == null) {
      return;
    }

    const defaultConfig = airDeviceTypeConfigs[deviceType];

    if (!defaultConfig) {
      throw new Error('Device  config not found in defaults');
    }
    let subTypes: string[] = [];

    setAirDeviceCustomFieldVisibility(
      typeof defaultConfig.features.custom_field_visibility === 'boolean'
        ? defaultConfig.features.custom_field_visibility
        : true,
    );

    if (defaultConfig.features.sub_types && defaultConfig.features.sub_types.length > 0) {
      subTypes = defaultConfig.features.sub_types;

      setAirDeviceSubTypeList(subTypes);

      if (selectedDeviceId) {
        setDeviceSubType(device.sub_cat);
      } else {
        setDeviceSubType(subTypes[0]);
      }
    } else {
      setDeviceSubType('');
      setAirDeviceSubTypeList([]);
    }
    const catConfig: IDeviceCategory = {
      category: 'AirItem',
      subCategory: '',
      sub3Category: '',
      isItem: true,
      config: defaultConfig,
    };

    let convertedInfo = {
      tags: defaultConfig.features.tags,
    };

    if (deviceApiResponse && deviceApiResponse.tags) {
      convertedInfo.tags = deviceApiResponse.tags;
    }
    setCat(catConfig);
    convertedInfo = airDeviceUpdateUtils.updateAirDeviceInfoOnlyPresentData(device, convertedInfo);
    appConsoleLog('convertedInfo', convertedInfo);

    setDeviceInfo(convertedInfo);
  };

  useEffect(() => {
    let active = true;
    const init = async () => {
      try {
        let tuyaDeviceInfo: ITuyaDevice | null;
        let deviceFromApi: IAirDevice;
        if (selectedDeviceId) {
          deviceFromApi = await airDeviceService.airDeviceGet(
            userProfile.apollo_client,
            selectedDeviceId,
          );
          setDeviceInfoFromAPI(deviceFromApi);
          // eslint-disable-next-line eqeqeq
          if (deviceFromApi.device_status == 4) {
            setBlockEdit(true);
          }
          if (deviceFromApi == null) {
            throw new Error('Device not found');
          }

          tuyaDeviceInfo = await airDeviceService.airDeviceInfo(
            userProfile.apollo_client,
            selectedDcId,
            deviceFromApi.tuya_device_id,
          );
        } else {
          tuyaDeviceInfo = await airDeviceService.airDeviceInfo(
            userProfile.apollo_client,
            selectedDcId,
            selectedAirDeviceExternalId,
          );
          if (tuyaDeviceInfo == null) {
            throw new Error('Air device not found');
          }
        }
        if (!active) return;
        // eslint-disable-next-line no-debugger
        // debugger;
        if (tuyaDeviceInfo != null) {
          // const ignoreFields: any =
          //   ITEM_DEFAULTS.ITEM_CREATOR.AIR_DEVICE_FIELDS_CODE_IGNORE_STARTWITH;

          const functionCodeMap: any = {};
          if (tuyaDeviceInfo.functions && tuyaDeviceInfo.functions.functions) {
            const { functions } = tuyaDeviceInfo.functions;
            const fnCopy = cloneDeep(functions);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < fnCopy.length; i++) {
              functionCodeMap[functions[i].code] = fnCopy[i];
            }
          }
          if (tuyaDeviceInfo.status) {
            const functions = tuyaDeviceInfo.status;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < functions.length; i++) {
              if (functionCodeMap[functions[i].code] === undefined) {
                functionCodeMap[functions[i].code] = {
                  ...functions[i],
                  name: functions[i].code,
                };
              }
            }
          }

          const usableFunctions: ITuyaFuntion[] = Object.values(functionCodeMap);
          setTuyaDevice(tuyaDeviceInfo);
          setDeviceFunctions(usableFunctions);
          setDeviceFunctionsMap(functionCodeMap);
          setHdcs(deviceFromApi?.settings?.hdc);
          if (selectedDeviceId && usableFunctions.length > 0) {
            addFunction(usableFunctions[0]);
          }

          if (selectedDeviceId) {
            setSelectedFieldsMap(deviceFromApi.settings.selected_functions);
            if (!deviceFromApi.settings.device_template_id) {
              snackbar.enqueueSnackbar(
                'Did not configured with a profile, switching to advanced view...',
                {
                  variant: 'info',
                },
              );
              setisAirChannelsCustomForm(true);
            }
            setDeviceInfo({ ...deviceFromApi });
            resetFormsAgain({
              ref: Date.now(),
              action: FormResetAction.toCurrentInfo,
              info: deviceFromApi,
            });

            setcustomDefaults(deviceFromApi.settings.custom_defaults || {});
            setDeviceType(deviceFromApi.device_type);

            setSliderVisibility(
              typeof deviceFromApi.settings.slider_visibility === 'boolean'
                ? deviceFromApi.settings.slider_visibility
                : true,
            );
            setShowControls(
              typeof deviceFromApi.settings.show_controls === 'boolean'
                ? deviceFromApi.settings.show_controls
                : true,
            );
            setShowPower(
              typeof deviceFromApi.settings.show_power === 'boolean'
                ? deviceFromApi.settings.show_power
                : false,
            );
            if (
              deviceFromApi
              && deviceFromApi.settings
              && deviceFromApi.settings.device_template_id != null
            ) {
              setTemplateId(deviceFromApi.settings.device_template_id);
            }
            // setTimeout(() => {

            // }, 600);
          }

          if (selectedDeviceId) {
            let name = tuyaDeviceInfo?.name;
            if (name === undefined) {
              name = '';
            }

            name = name.replace(/[^0-9a-zA-Z- ]/g, '');

            if (name.length > 35) {
              name = name.substr(0, 34);
            }
            const convertedInfo = airDeviceUpdateUtils.updateAirDeviceInfoOnlyPresentData(device, {
              name,
            });

            setDeviceInfo(convertedInfo);
          }
        }

        // sub cat list initiate
        const subCategoryList = Object.keys(airDeviceTypeConfigs);
        setAirDeviceTypeList(subCategoryList);
      } catch (err) {
        appConsoleError(err);
      } finally {
        setIsInit(true);
      }
    };
    init();
    return () => {
      active = false;
    };
  }, [selectedDcId]);

  useEffect(() => {
    reDefineFields();
  }, [deviceType]);

  useEffect(() => {
    if (!payload) {
      return;
    }
    appConsoleLog('ACTION', payload, deviceFunctions);

    if (payload.action === 'remove') {
      setDeviceInfo((oldDeviceInfo: any) => {
        const newInfo = { ...oldDeviceInfo };

        if (newInfo.settings) {
          const functMap = { ...selectedFieldsMap };
          newInfo.settings.selected_functions = functMap;
        }

        return newInfo;
      });
    } else if (payload.action === 'data') {
      let newInfo = device;
      // let newInfoValidations = infoValidations;

      const { data } = payload;

      setInfoValidations((oldInfoValidations: any) => {
        const cpy = { ...oldInfoValidations };
        cpy[payload.form] = payload.errors;
        // newInfoValidations = cpy;
        return cpy;
      });

      setDeviceInfo((oldDeviceInfo: any) => {
        newInfo = airDeviceUpdateUtils.updateAirDeviceInfoOnlyPresentData(oldDeviceInfo, data);

        newInfo.settings.selected_functions = selectedFieldsMap;

        return newInfo;
      });
    }
  }, [payload]);

  const resetForm = async () => {
    // reset all fields to default
    setDeviceInfo(
      airDeviceUpdateUtils.defaultAirDeviceInfo(
        selectedProjectId,
        selectedDcId,
        '',
        cat.config?.features?.tags,
        {},
      ),
    );

    setInfoValidations({});
    setTimeout(() => {
      resetFormsAgain({
        ref: Date.now(),
        action: FormResetAction.toDefault,
        info: {},
      });
    }, 200);
  };
  const submitForm = async (newHdcs?: any, stopNavigation?: boolean) => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    try {
      const updatingDevice = JSON.parse(JSON.stringify(device));
      updatingDevice.airdc_id = selectedDcId;
      updatingDevice.tuya_device_id = tuyaDevice?.id;
      updatingDevice.cat = tuyaDevice?.category;

      if (deviceSubType && deviceSubType.length > 0) {
        updatingDevice.sub_cat = deviceSubType;
      } else {
        updatingDevice.sub_cat = tuyaDevice?.product_name;
      }

      updatingDevice.shadow_type = cat.config?.features?.shadow_type;
      updatingDevice.device_type = deviceType;

      updatingDevice.config = null;
      updatingDevice.settings.selected_functions = selectedFieldsMap;
      updatingDevice.settings.debounce = 500;
      updatingDevice.settings.slider_visibility = sliderVisibility;
      updatingDevice.settings.show_controls = showControls;
      updatingDevice.settings.show_power = showPower;

      if (newHdcs) {
        updatingDevice.settings.hdc = newHdcs;
      }

      if (customDefaults) {
        const keys = Object.keys(customDefaults);
        let invalid: string = null;
        keys.forEach((k) => {
          if (customDefaults[k] && customDefaults[k].enabled && customDefaults[k].invalid) {
            invalid = `Invalid ${customDefaults[k].data_type} default value for : ${k}`;
          }
        });
        if (invalid) {
          throw invalid;
        }
      }
      updatingDevice.settings.custom_defaults = customDefaults;
      // templateId;
      updatingDevice.settings.device_template_id = templateId;

      appConsoleLog(JSON.stringify(updatingDevice));

      const validationKeys = Object.keys(infoValidations);

      let isValidForm = true;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < validationKeys.length; i++) {
        const key = validationKeys[i];
        const childKeys = Object.keys(infoValidations[key]);

        if (childKeys.length > 0) {
          isValidForm = false;
          break;
        }
      }

      if (!isValidForm) {
        throw new Error('Please fill all required fields');
      }
      if (deviceType === '') {
        throw new Error('Please select device type');
      }

      let infoText = 'Air device created';
      if (selectedDeviceId) {
        infoText = 'Air device updated';
        updatingDevice.id = selectedDeviceId;

        await airDeviceService.airDeviceUpdate(userProfile.apollo_client, updatingDevice);
      } else {
        await airDeviceService.airDeviceCreate(userProfile.apollo_client, updatingDevice);
      }

      snackbar.enqueueSnackbar(infoText, {
        key: 'air-device-update-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
      if (stopNavigation) return;
      resetForm();
      navigateRoute(
        `/app/device-container/air?projectId=${selectedProjectId}&dcId=${selectedDcId}&name=${selectedDCName}`,
      );
    } catch (err: any) {
      appConsoleError(err);
      let errorMessage = 'Unexpected error';
      if (typeof err === 'string') {
        errorMessage = err;
      } else if (err && err.message) {
        errorMessage = appApiErrorMessage(err.message);
      }
      snackbar.enqueueSnackbar(errorMessage, {
        key: 'air-device-update-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    } finally {
      setLoading(false);
    }
  };

  const addSelectedField = () => {
    if (currentFunctionCode === '') {
      return;
    }
    const found = deviceFunctions.find((item) => item.code === currentFunctionCode);

    if (found) {
      addFunction(found);
      setCurrentFunctionCode('');
    }
  };
  const removeField = (code: string) => {
    const mapdata = { ...selectedFieldsMap };
    delete mapdata[code];
    setSelectedFieldsMap(mapdata);
    setCurrentFunctionCode('');
  };
  const addFunction = (funct: ITuyaFuntion) => {
    const mapdata = { ...selectedFieldsMap };
    mapdata[funct.code] = funct;
    setSelectedFieldsMap(mapdata);
  };

  const handleCustomDefaulsChanges = (data: {code: string; payload: any}) => {
    const cv = cloneObject(customDefaults);
    if (payload && payload.enabled === false) {
      delete cv[data.code];
    } else {
      cv[data.code] = data.payload;
    }
    setcustomDefaults(cv);
  };

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            {selectedDeviceId ? 'Air Device Update' : 'Air Device Create'}
          </Typography>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <PageContentLayout className={classes.root}>
          <PageConentHeader>
            <Typography variant="h5" component="h2">
              Air Device Form
            </Typography>
            <Typography>
              {device && device.cat ? `${device.cat} > ${device.sub_cat} > ${device.name}` : ''}
              {!(device && device.cat) && tuyaDevice
                ? `${tuyaDevice.category} > ${tuyaDevice.product_name} > ${tuyaDevice.name}`
                : ''}
            </Typography>
          </PageConentHeader>
          <Box mt={1}>
            {blockEdit && (
              <>
                <Alert color="error">This device cannot edit as it is deleted.</Alert>
                <Box mb={2} />
              </>
            )}
            {tuyaDevice ? (
              <div>
                <Tabs value={currentTab} onChange={(_, val) => setCurrentTab(val)}>
                  <Tab label="Device Settings" value={0} />
                  <Tab disabled={!selectedDeviceId} label="Energy Configuration" value={1} />
                </Tabs>
                {currentTab === 0 && (
                  <>
                    <Box height={10} />
                    <div className="col-md-4 pt-2">
                      <div className="text-fields-flex">
                        <FormControl
                          size="small"
                          variant="outlined"
                          className="dynamic-ui-form-control-full-width"
                        >
                          <InputLabel id="device-type-label1">Select Device Type</InputLabel>
                          <Select
                            labelId="device-type-label1"
                            value={deviceType || ''}
                            name="device-type-air"
                            onChange={(e: any) => {
                              setDeviceType(e.target.value);
                            }}
                            label="Select device type"
                          >
                            {airDeviceTypeList.map((item) => (
                              <MenuItem key={item} value={item}>
                                {airDeviceTypeConfigs[item].title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {airDeviceSubTypeList.length > 0 ? (
                      <div className="col-md-4 pt-2">
                        <div className="text-fields-flex">
                          <FormControl
                            size="small"
                            variant="outlined"
                            className="dynamic-ui-form-control-full-width"
                          >
                            <InputLabel id="device-subtype-label1">
                              Select Device Sub Type
                            </InputLabel>
                            <Select
                              labelId="device-subtype-label1"
                              value={deviceSubType || ''}
                              name="device-subtype-air"
                              onChange={(e: any) => {
                                setDeviceSubType(e.target.value);
                              }}
                              label="Select device sub type"
                            >
                              {airDeviceSubTypeList.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}

                    <div className="dynamic-ui-form-features col-md-12">
                      <span>Features: </span>
                      <div className="row pro-device-dynamic-items">
                        <ProDeviceNameCraeteComponent
                          canEditName
                          canEditIotId
                          projectId={selectedProjectId}
                          tags={device.tags}
                          // eslint-disable-next-line no-nested-ternary
                          name={
                            tuyaDevice ? (device && device.cat ? device.name : tuyaDevice.name) : ''
                          }
                          validateForm={formValidate}
                          resetForm={resetRef}
                          onChange={(data: any) => {
                            // appConsoleLog("name update:", data.data, device);
                            detectPayloadPush(data);
                          }}
                        />
                      </div>
                      {deviceType === 'roller_blinds' ? (
                        <>
                          <div className={`col-md-12 ${styles.submitCont}`}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={sliderVisibility}
                                  onChange={(event) => {
                                    console.log(event.target.checked);
                                    setSliderVisibility(event.target.checked);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              )}
                              label="Slider Visibility"
                            />
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={showControls}
                                  onChange={(event) => {
                                    console.log(event.target.checked);
                                    setShowControls(event.target.checked);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              )}
                              label="Show controls"
                            />
                          </div>
                        </>
                      ) : (
                        <div />
                      )}
                      {deviceType === 'ac' ? (
                        <>
                          <div className={`col-md-12 ${styles.submitCont}`}>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={showPower}
                                  onChange={(event) => {
                                    console.log(event.target.checked);
                                    setShowPower(event.target.checked);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              )}
                              label="Power button ON/OFF"
                            />
                          </div>
                        </>
                      ) : (
                        <div />
                      )}
                      <FormControlLabel
                        control={(
                          <Switch
                            disabled
                            checked={isAirChannelsCustomForm}
                            onChange={() => setisAirChannelsCustomForm((state) => !state)}
                          />
                        )}
                        label="Advanced Settings"
                      />

                      <AirDeviceChannels
                        // key={deviceType}
                        airDeviceCustomFieldVisibility={airDeviceCustomFieldVisibility}
                        isAirChannelsCustomForm={isAirChannelsCustomForm}
                        deviceFunctions={deviceFunctions}
                        currentFunctionCode={currentFunctionCode}
                        selectedFieldsMap={selectedFieldsMap}
                        tuyaDevice={tuyaDevice}
                        customDefaults={customDefaults}
                        setSelectedFieldsMap={setSelectedFieldsMap}
                        setCurrentFunctionCode={setCurrentFunctionCode}
                        addSelectedField={addSelectedField}
                        removeField={removeField}
                        handleCustomDefaulsChanges={handleCustomDefaulsChanges}
                        profileProps={{
                          deviceType,
                          deviceSubType,
                          device,
                          templateId,
                          deviceFunctionsMap,
                        }}
                        onChangeProfile={(comProfile) => {
                          // eslint-disable-next-line no-debugger
                          // sdebugger;
                          if (
                            comProfile.template_data.selectedFieldsMap
                            && comProfile.template_data.deviceType
                          ) {
                            const canSetFields = true;
                            if (deviceFunctionsMap) {
                              // Object.keys(comProfile.template_data.selectedFieldsMap).forEach((nkey) => {
                              //   if (!deviceFunctionsMap[nkey]) {
                              //     canSetFields = false;
                              //   }
                              // });
                              if (canSetFields) {
                                setSelectedFieldsMap(comProfile.template_data.selectedFieldsMap);
                                setDeviceType(comProfile.template_data.deviceType);
                                setDeviceSubType(comProfile.template_data.deviceSubType);
                                // eslint-disable-next-line no-underscore-dangle
                                setTemplateId(comProfile._id);
                              } else {
                                snackbar.enqueueSnackbar(
                                  "Selected device profile doesn't   match with the device",
                                  {
                                    variant: 'error',
                                  },
                                );
                              }
                            }
                          }
                        }}
                      />
                    </div>
                    <div className={`col-md-12 ${styles.submitCont}`}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (!isLoading) {
                            detectFormValidate(Date.now());
                            setTimeout(() => {
                              submitForm();
                            }, 800);
                          }
                        }}
                        disabled={blockEdit || isLoading}
                      >
                        {selectedDeviceId ? 'Update' : 'Create'}
                      </Button>
                      <Box width={10} />
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          resetForm();
                        }}
                        disabled={blockEdit || isLoading}
                      >
                        Reset
                      </Button>
                      <Box width={10} />
                      <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
                {currentTab === 1 && (
                  <>
                    <Box height={20} />
                    <Typography variant="h6">Energy Monitoring Configurations</Typography>
                    <DeviceEnergyMonitoringConfiguration
                      functions={tuyaDevice?.status}
                      hdc={hdcs}
                      onSaveHdc={(hdcArg) => {
                        setHdcs(hdcArg);
                        submitForm(hdcArg, true);
                      }}
                    />
                  </>
                )}
              </div>
            ) : !isInit || isLoading ? (
              <div className={styles.loadingCom}>
                <LoadingComponent color="#06454d" />
              </div>
            ) : (
              <div> Nothing to show :( </div>
            )}
          </Box>
        </PageContentLayout>
      </Box>
    </Layout>
  );
};

export default AirDeviceCreateEdit;
