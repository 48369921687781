import { COMPONENT_UNIT_TYPES } from 'src/plugins/shared/item.config';
import { Tag } from 'src/plugins/shared/uvitem.config';

const tags: Record<'tags1' | 'tags2' | 'tags3' | 'tags4', Record<string, Tag>> = {
  tags1: {
    watt_tag: {
      type: COMPONENT_UNIT_TYPES.Sensor,
      tag: 'watt_tag',
      description: 'Wattage [f = (x(kW) X 1) kW]',
      display_suffix: 'kW',
      config: {
        scale: 1,
        decimal_points: 0,
      },
      main_key: null,
      command_key: null,
      can_send_command: false,
      command_v: 'v1',
    },
  },
  tags2: {
    watt_tag: {
      type: COMPONENT_UNIT_TYPES.Sensor,
      tag: 'watt_tag',
      description: 'Wattage [f = (x(W) X 1) W]',
      display_suffix: 'W',
      config: {
        scale: 1,
        decimal_points: 0,
      },
      main_key: null,
      command_key: null,
      can_send_command: false,
      command_v: 'v1',
    },
  },
  tags3: {
    watt_tag: {
      type: COMPONENT_UNIT_TYPES.Sensor,
      tag: 'watt_tag',
      description: 'Wattage [f = (x(W) x 0.001) kW]',
      display_suffix: 'kW',
      config: {
        scale: 0.001,
        decimal_points: 2,
      },
      main_key: null,
      command_key: null,
      can_send_command: false,
      command_v: 'v1',
    },
  },
  tags4: {
    watt_tag: {
      type: COMPONENT_UNIT_TYPES.Sensor,
      tag: 'watt_tag',
      description: 'Watt [f = (x(kW) x 1000) W]',
      display_suffix: 'W',
      config: {
        scale: 1000,
        decimal_points: 0,
      },
      main_key: null,
      command_key: null,
      can_send_command: false,
      command_v: 'v1',
    },
  },
};

export default tags;
