/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Item card which displays Re14item.
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the Re14Item component which display diagram link
 *       details and delete/edit options.
 * @author Maduka Dilshan
 * @since 0.1.15
 */

import React, { useContext } from 'react';
import PropsTypes from 'prop-types';
import {
  Button, CardContent, IconButton, Tooltip, Card, Typography, CardActions,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { getProjectDiagramUrl } from '../../../services/location/location.service';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    marginBottom: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export class Link {
  id: string;

  name: string;

  description: string;

  diagramUid: string;

  createdAt: number;

  updatedAt: number;
}

/**
 * Component which display single Re14 link and avaible actions ( edit/delete )
 * @param {Object} props                    component props
 * @param {Object} props.link               the Re14 link data payload
 * @param {String} props.link.id            id of the link
 * @param {String} props.link.name          display name of the link
 * @param {String} props.link.description   description of the link
 * @param {String} props.link.diagramUid    link to the diagram
 * @param {Number} props.link.createdAt     the time which link created
 * @param {Number} props.link.updatedAt     the time which link was edited
 * @returns JSX.Element
 */
const Re14Item: React.FC<{
  link: Link;
  // eslint-disable-next-line no-unused-vars
  onEdit: (link: Link) => void;
  // eslint-disable-next-line no-unused-vars
  onDelete: (diagramId: string) => void;
}> = ({ link, onEdit, onDelete }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const userProfile = useContext(ApolloAuthContext);
  const onLinkClick = async () => {
    try {
      const res = await getProjectDiagramUrl(
        userProfile.apollo_client,
        link.id,
      );
      if (res.data.projectDiagramCreateEditUrl?.url) {
        window.open(res.data.projectDiagramCreateEditUrl.url, '_blank');
      }
    } catch (err:any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Re14 Design Link
          </Typography>
          <Tooltip title="Delete this link">
            <IconButton onClick={() => onDelete(link.id)} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>

        <Typography variant="h5" component="h2">
          {link.name}
        </Typography>
        <Typography variant="body2" component="p">
          {link.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => onEdit(link)}>Edit</Button>
        <Button color="primary" onClick={onLinkClick}>
          Open Link
        </Button>
      </CardActions>
    </Card>
  );
};

Re14Item.propTypes = {
  link: PropsTypes.instanceOf(Link).isRequired,
  onEdit: PropsTypes.func.isRequired,
  onDelete: PropsTypes.func.isRequired,
};

export default Re14Item;
