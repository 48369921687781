/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../Alters/Snackbar/SnackbarOptions';
import { UVContext, UVReducerTypes } from '../../UVContext';
import uvService from '../../service/uv.service';
import { IUserViewItem } from '../../types/types';
import { UVConfirmDialog } from '../Dialogs/UVConfirmDialog/UVConfirmDialog';
import { appConsoleError } from '../../../../utility/appUtils';
import styles from './UVFolderItem.module.css';

type ComponentProps = {
  userviewItem: IUserViewItem;
  shortcut?: boolean;
  onEdit: (item: IUserViewItem) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVItem: React.FunctionComponent<ComponentProps> = ({
  userviewItem,
  shortcut = false,
  onEdit,
}) => {
  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);

  const [deleteState, setDeleteState] = useState<boolean>(false);

  const onDeleteItem = async () => {
    try {
      if (userviewItem === undefined || !userviewItem.id) {
        throw new Error('Item id not valid');
      }
      await uvService.userviewItemDelete(userProfile.apollo_client, userviewItem.id);
      snackbar.enqueueSnackbar('Item deleted', {
        key: 'uv-item-delete-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });
    } catch (err) {
      appConsoleError(err);

      snackbar.enqueueSnackbar('Cannot delete Item', {
        key: 'uv-item-create-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuAction?: string) => {
    setAnchorEl(null);
    if (menuAction === 'delete') {
      setDeleteState(true);
    }
  };

  return (
    <div className={styles.root} style={{ opacity: userviewItem.item_status === 2 ? 0.5 : 1 }}>
      <div
        className={styles.innerRoot}
        style={{
          backgroundColor: userviewItem.item_color || 'white',
          cursor: shortcut ? 'default' : userviewItem.item_status === 2 ? 'not-allowed' : 'pointer',
        }}
      >
        <div
          className={styles.moreIconHolder}
          style={{ cursor: userviewItem.item_status === 2 ? 'not-allowed' : 'pointer' }}
        >
          <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
            {shortcut && (
              <Tooltip title="Shortcut Item">
                <CopyAllIcon style={{ color: '#048696' }} />
              </Tooltip>
            )}
            {userviewItem.item_visibility !== 1 && (
              <Tooltip title="Visibility of this item is hidden">
                <VisibilityOffIcon />
              </Tooltip>
            )}
            {userviewItem.item_status === 2 && (
              <Tooltip title="Devices in this userview are disabled">
                <WarningAmberIcon color="warning" />
              </Tooltip>
            )}
          </div>
          <IconButton
            className={styles.moreIconButton}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            disabled={userviewItem.item_status === 2}
            onClick={handleClick}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleClose('')}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem
            key="edit"
            onClick={() => {
              handleClose('');
              onEdit(userviewItem);
            }}
          >
            Edit
          </MenuItem>

          <MenuItem key="delete" onClick={() => handleClose('delete')}>
            Delete
          </MenuItem>
        </Menu>
        <div
          className={styles.iconContainer}
          style={{ cursor: userviewItem.item_status === 2 ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (userviewItem.item_status === 2) return;
            onEdit(userviewItem);
          }}
        >
          <div>
            {shortcut ? (
              <span
                className={`calc calc-icon-uv-primary ${
                  userviewItem.icon_name === '' ? 'calc_output' : userviewItem.icon_name
                }`}
              />
            ) : (
              <span className={`calc calc-icon-uv-primary ${userviewItem.icon_name}`} />
            )}
          </div>
        </div>
        <div>
          <UVConfirmDialog
            dialogOpen={deleteState}
            dialogCloseEvent={(e: any) => {
              setDeleteState(false);
              if (e === true) {
                onDeleteItem();
              }
            }}
            title="Confirm to delete?"
            text="Are you sure to delete item"
          />
        </div>
        <div
          className={clsx(styles.data)}
          style={{ cursor: userviewItem.item_status === 2 ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (userviewItem.item_status === 2) return;
            onEdit(userviewItem);
          }}
        >
          <div className={styles.title}>{userviewItem.display_name}</div>
        </div>
      </div>
    </div>
  );
};

UVItem.defaultProps = {
  shortcut: false,
};
