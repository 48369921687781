/* eslint-disable camelcase */
import {
  Box, FormControl, Grid, InputLabel, MenuItem, Select, Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import {
  LOGIC_CARD_VISIBILITY,
  Trigger,
  TriggerTypes,
  TriggerTypesLabels,
} from '@smartlife-redux-state/common';
import { update_trigger } from '../../../../../../store/redux/features/logic-engine/slice';
import { DeviceTrigger } from './triggers/DeviceTrigger';
import { DirectPress } from './triggers/DirecPressTrigger';
import { DirectPressNodeRed } from './triggers/DirectPressNodeRedTrigger';
import { LocationVariableTrigger } from './triggers/LocationVariableTrigger';
import { SchedulerExactTime } from './triggers/SchedulerExactTime';
import { SchedulerTime } from './triggers/SchedulerTime';
import { VoiceTriggerForm } from './triggers/VoiceTrigger';
import HubStatus from './triggers/HubStatus';

const TriggerForm: React.FC<{trigger: Trigger}> = ({ trigger }) => {
  switch (trigger.type) {
    case TriggerTypes.DEVICE:
      return <DeviceTrigger trigger={trigger} />;
    case TriggerTypes.LOCATION_VARIABLE:
      return <LocationVariableTrigger trigger={trigger} />;
    case TriggerTypes.SCHEDULER_TIME:
      return <SchedulerTime trigger={trigger} />;
    case TriggerTypes.VOICE:
      return <VoiceTriggerForm trigger={trigger} />;
    case TriggerTypes.CONNECT_HUB_STATUS:
      return <HubStatus trigger={trigger} />;
    default:
      return <></>;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100%",
    //   border: "1px solid #7F8FA4",
    //   borderRadius: "5px",
    padding: '5px',
    width: '100%',
  },
  content: {},
  formControl: {
    width: '100%',
    paddingRight: '5px',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const TriggerSelector: React.FC<{trigger: Trigger}> = ({ trigger }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(
      update_trigger({
        trigger: {
          ...trigger,
          dc: null,
          device: null,
          type: event.target.value,
          variable: null,
          possible_variable_type: null,
          meta: {
            ...trigger.meta,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
            is_enabled: true,
          },
        },
      }),
    );
  };

  return (
    <Grid container className={classes.root}>
      {trigger.dc && trigger.dc.has_verification_error && (
        <Grid item md={12}>
          <Alert color="warning">
            There is an issue with Device Container, Make sure the device container is exists or
            select another device container from the dropdown or delete this card. Info -
            {' '}
            {trigger.dc.has_verification_error}
          </Alert>
          <Box height={15} />
        </Grid>
      )}

      {trigger.device && trigger.device.has_verification_error && (
        <Grid item md={12}>
          <Alert color="warning">
            There is an issue with the selected device/variable. If you deleted the device/variable
            make sure to select another item from the dropdown or delete this card. Info -
            {' '}
            {trigger.device.has_verification_error}
          </Alert>
          <Box height={15} />
        </Grid>
      )}

      <Grid item md={4}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel>Type</InputLabel>
          <Select label="Type" value={trigger.type} onChange={handleChange}>
            {Object.keys(TriggerTypesLabels).map((type, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem value={TriggerTypesLabels[type].key} key={index}>
                {TriggerTypesLabels[type].label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box height={15} />
      </Grid>
      <Grid item md={12} className={classes.content}>
        <TriggerForm trigger={trigger} />
      </Grid>
    </Grid>
  );
};
