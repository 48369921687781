/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * The inner container of the logic creation module.
 * Each steps of logic creation process will use below contianers to render their content
 * in a inner container of the main logic create container
 *
 * @author Maduka Dilshan
 */
import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyle = makeStyles({
  root: {
    width: '86%',
    background: 'white',
    margin: '2% auto',
    padding: '2%',
    borderRadius: '10px',
  },
  header: {
    borderBottom: '1px solid #D9DFE0',
    paddingBottom: '2%',
  },
  content: {
    paddingTop: '2%',
  },
});

export const PageConentHeader: React.FC<{children: any}> = ({ children }) => {
  const styles = useStyle();
  return <Box className={styles.header}>{children}</Box>;
};

export const PageContent: React.FC<{children: any; className?: string}> = ({
  children,
  className = '',
}) => {
  const styles = useStyle();
  return <Box className={clsx(styles.content, className)}>{children}</Box>;
};
PageContent.defaultProps = {
  className: '',
};

const PageContentLayout: React.FC<{children: any; className?: string}> = ({
  children,
  className = '',
}) => {
  const styles = useStyle();
  return <Box className={clsx(styles.root, className)}>{children}</Box>;
};
PageContentLayout.defaultProps = {
  className: '',
};

export default PageContentLayout;
