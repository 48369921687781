import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Tag } from "src/plugins/shared/uvitem.config";

const tags: Record<'tags1' | 'tags2' | 'tags3' | 'tags4' | 'tags5', Record<string, Tag>> = {
    tags1: {
        energy: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'energy',
            description: 'Energy [f = (x(kWh) X 1) kWh]',
            display_suffix: 'kWh',
            config: {
              scale: 1,
              decimal_points: 0,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags2: {
        energy: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'energy',
            description: 'Energy [f = (x(Wh) X 1) Wh]',
            display_suffix: 'Wh',
            config: {
              scale: 1,
              decimal_points: 0,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags3: {
        energy: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'energy',
            description: 'Energy [f = (x(Wh) x 0.001) kWh]',
            display_suffix: 'kWh',
            config: {
              scale: 0.001,
              decimal_points: 0,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags4: {
        energy: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'energy',
            description: 'Energy [f = (x(kWh) x 1000) Wh]',
            display_suffix: 'Wh',
            config: {
              scale: 1000,
              decimal_points: 0,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags5: {
        energy: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'energy',
            description: 'Energy [f = (x(Wmin) x 1/(60*1000)) Wh]',
            display_suffix: 'Wh',
            config: {
              scale: 1/(60*1000),
              decimal_points: 0,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
}

export default tags;