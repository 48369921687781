/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { AxiosInstance } from 'axios';
import { ApolloFunctions } from 'src/store/Apollo/ApolloFunctions';
import { appConsoleLog } from '../../utility/appUtils';
import { ImageUploadTypes, SharedFunctions } from '../shared/SharedFunctions';
import {
  DELETE_PROJECT_MUTATION,
  PROJECT_CREATE_MUTATION,
  PROJECT_DEVICE_UV_MAP,
  PROJECT_DIAGRAMS,
  PROJECT_DIAGRAM_CREATE,
  PROJECT_DIAGRAM_CREATE_EDIT_URL,
  PROJECT_DIAGRAM_DELETE,
  PROJECT_DIAGRAM_UPDATE,
  PROJECT_GET_QUERY,
  PROJECT_SEARCH_QUERY,
  PROJECT_UPDATE_MUTATION,
} from './graphql';
import { GENERATE_LOGIC_REPORT, GET_LOCATION_REPORT_INFO, MIGRATE_TO_V2 } from './graphqlV2';

// search project by keyword
const searchProjectsGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  keyword: string,
  limit: number,
  skip: number,
  project_status: any,
  project_type: any,
): Promise<any> => {
  appConsoleLog(keyword);
  const res = await apolloClient.query({
    query: PROJECT_SEARCH_QUERY,
    variables: {
      project_status,
      project_type,
      keyword,
      limit,
      skip,
    },
    fetchPolicy: 'network-only',
  });
  return res.data;
};

// Get project details by project id
const projectGetGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PROJECT_GET_QUERY,
    variables: {
      project_id,
    },
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const migrateToV2 = async (token: string, projectId: string) => {
  const client = ApolloFunctions.createAuthApolloClient(
    token,
    `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/graphqlv2`,
  );
  const res = await client.mutate({
    mutation: MIGRATE_TO_V2,
    variables: {
      projectId,
    },
  });
  return res.data;
};

const generateLogicReportV2 = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
) => {
  const res = await apolloClient.mutate({
    mutation: GENERATE_LOGIC_REPORT,
    variables: {
      projectId,
    },
  });
  return res.data;
};

const getLocationReportV2 = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
) => {
  const res = await apolloClient.mutate({
    mutation: GET_LOCATION_REPORT_INFO,
    variables: {
      projectId,
    },
  });
  return res.data;
};

// Create project
const createProjectGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  name: string,
  description: string,
  project_status: any,
  project_type: any,
  demo_mode: boolean,
  restore_bsid?: string,
  extra?: {
    project_contact_no1?: string;
    project_timezone?: string;
    project_address?: string;
    project_country?: string;
    project_country_code?: string;
    project_city?: string;
  },
): Promise<any> => {
  appConsoleLog(project_type);
  let input = {
    name,
    description,
    project_status,
    project_type,
    demo_mode,
    restore_bsid,
  };
  if (extra) {
    input = { ...input, ...extra };
  }
  const res = await apolloClient.mutate({
    mutation: PROJECT_CREATE_MUTATION,
    variables: {
      input,
    },
  });
  return res.data;
};

// Upload project image
const uploadProjectImageGraphQL = async (
  accessToken: string,
  file: any,
  item_id: any,
): Promise<string> => {
  const res = await SharedFunctions.imageUpload(
    accessToken,
    file,
    ImageUploadTypes.PROJECT_IMAGE,
    item_id,
  );
  return res.data.imageUpload.imageId;
};

const uploadProjectImageRest = async (
  axioInstance: AxiosInstance,
  file: any,
  item_id: any,
  path: string,
) => {
  const data = new FormData();
  data.set('image_file', file);
  data.set('upload_for', 'PROJECT_IMAGE');
  data.set('item_id', item_id);

  const res = await axioInstance.post(path, data);
  return res.data.imageId;
};

// Update project details
const updateProjectGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
  name: string,
  description: string,
  project_status: any,
  project_type: any,
  demo_mode: boolean,
  extra?: {
    project_contact_no1?: string;
    project_timezone?: string;
    project_address?: string;
    project_country?: string;
    project_country_code?: string;
    project_city?: string;
  },
): Promise<any> => {
  let input_item = {
    name,
    description,
    project_status,
    project_type,
    demo_mode,
  };

  if (extra) {
    input_item = { ...input_item, ...extra };
  }

  const res = await apolloClient.mutate({
    mutation: PROJECT_UPDATE_MUTATION,
    variables: {
      input: {
        item_id,
        item: input_item,
      },
    },
  });
  return res.data;
};

// Delete project
const deleteProjectGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: DELETE_PROJECT_MUTATION,
    variables: {
      item_id,
    },
  });
  return res.data;
};

const projectDeviceUvMapGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PROJECT_DEVICE_UV_MAP,
    variables: {
      project_id: projectId,
    },
  });
  return res.data;
};

const createProjectDiagram = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  name: string,
  description: string,
) => {
  const res = await apolloClient.mutate({
    mutation: PROJECT_DIAGRAM_CREATE,
    variables: {
      input: {
        project_id,
        name,
        description,
      },
    },
  });
  return res.data;
};

const updateProjectDiagram = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  diagram_id: string,
  project_id: string,
  name: string,
  description: string,
) => {
  const res = await apolloClient.mutate({
    mutation: PROJECT_DIAGRAM_UPDATE,
    variables: {
      diagram_id,
      item: {
        project_id,
        name,
        description,
      },
    },
  });
  return res.data;
};

const deleteProjectDiagram = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  diagram_id: string,
) => {
  const res = await apolloClient.mutate({
    mutation: PROJECT_DIAGRAM_DELETE,
    variables: {
      diagram_id,
    },
  });
  return res;
};

const getProjectDiagrams = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
) => {
  const res = await apolloClient.query({
    query: PROJECT_DIAGRAMS,
    variables: {
      project_id,
    },
  });
  return res;
};

const getProjectDiagramUrl = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  diagram_id: string,
) => {
  const res = await apolloClient.query({
    query: PROJECT_DIAGRAM_CREATE_EDIT_URL,
    variables: {
      diagram_id,
    },
  });
  return res;
};

export {
  searchProjectsGraphQL,
  projectGetGraphQL,
  createProjectGraphQL,
  uploadProjectImageGraphQL,
  uploadProjectImageRest,
  updateProjectGraphQL,
  deleteProjectGraphQL,
  projectDeviceUvMapGraphQL,
  createProjectDiagram,
  getProjectDiagrams,
  updateProjectDiagram,
  deleteProjectDiagram,
  getProjectDiagramUrl,
  migrateToV2,
  generateLogicReportV2,
  getLocationReportV2,
};
