/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Card component used for shelly integration
 *
 *
 * @file This file define the shelly device card
 * @author Maduka Dilshan
 */
import React, { useContext, useState } from 'react';
import PropsTypes from 'prop-types';
import {
  Box, Chip, Menu, MenuItem, Stack, Switch, Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import styles from './styles/device-card.module.css';
import { ECloudIntDeviceStatus } from 'src/plugins/shared/item.config';

type ComponentProps = {
  cintId?: string;
  category: any;
  id?: string;
  name: string;
  bgColor?: string;
  cursor?: string;
  snackbar?: any;
  showStatus?: boolean;
  status?: number;
  onClick?: () => void;
};
// TODO: move to shared folder
const ShellyDeviceCard: React.FunctionComponent<ComponentProps> = ({
  cintId,
  id,
  name,
  category,
  bgColor,
  cursor,
  snackbar,
  showStatus,
  status,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const project = useContext(ProjectContext);

  const handleClose = (menuAction?: string) => {
    setAnchorEl(null);
    if (menuAction === 'view') {
      navigate(
        `/app/device-container/device?projectId=${project.selected_project.id}&dcType=cint&deviceId=${id}`,
      );
    }
    if (menuAction === 'edit') {
      onClick();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div
      className={styles.root}
      style={{
        background: bgColor || 'white',
        cursor: cursor || 'pointer',
      }}

      // onClick={onClick}
    >
      <div className={styles.data}>
        <div
          style={{
            display: 'flex',
            justifyContent: showStatus ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {showStatus && (
            <Tooltip title={ECloudIntDeviceStatus[status].label}>
              <Box
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  cursor: 'pointer',
                  backgroundColor: ECloudIntDeviceStatus[status].color,
                }}
              />
            </Tooltip>
          )}

          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => handleClose('')}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem key="edit" onClick={() => handleClose('view')}>
              Device Links
            </MenuItem>
            <MenuItem key="edit" onClick={() => handleClose('edit')}>
              Edit
            </MenuItem>
          </Menu>
        </div>
        <div>
          <div className={`${styles.title}`}>{name}</div>
          <div className={`${styles.type}`}>
            {category}
            <Tooltip title="Copy the device ID">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(id);
                  snackbar.enqueueSnackbar('Device ID Coppied to Clipboard', {
                    variant: 'success',
                  });
                }}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <hr style={{ marginTop: 0 }} />
          <div style={{ overflow: 'hidden' }} className={`${styles.type}`}>
            Serial:
            {' '}
            {cintId}
            <Tooltip title="Copy the Serial">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(cintId);
                  snackbar.enqueueSnackbar('Serial Coppied to Clipboard', {
                    variant: 'success',
                  });
                }}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

ShellyDeviceCard.propTypes = {
  category: PropsTypes.string.isRequired,
  name: PropsTypes.string.isRequired,
  id: PropsTypes.string,
  cintId: PropsTypes.string,
  bgColor: PropsTypes.string,
  cursor: PropsTypes.string,
};

ShellyDeviceCard.defaultProps = {
  id: null,
  bgColor: 'white',
  cursor: 'pointer',
};

export default ShellyDeviceCard;
