export const FORM_DEBOUNCE_TIME = 400;
export const FORM_KEY_NEED_TO_DELETE = '::KEY::NEED::DELETE';

export const FORM_IOT_NAME_LEN = 15;
// oh input => wsdcg,sj
// oh toggle => ms,kg,cz
// oh slider => tgq
// oh press button => infrared_tv,wnykq
// dj: ["Smart Bulb (Lamp)", "Right RGB Light", "Left RGB Light"],
// oh toggle
// color picker

export const USERVIEW_NOTIFICATION_TYPES = [
  { value: 1, name: 'No notification' },
  { value: 2, name: 'Text only' },
  { value: 3, name: 'Text with Push Notification ( default sound)' },
  { value: 4, name: 'Text with Push Notification and chime sound' },
  { value: 5, name: 'Text with Push Notification and warning sound' },
];
