/* eslint-disable camelcase */
import { TextField } from '@mui/material';
import { CloudUpload, Save } from '@mui/icons-material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { APP_TEXT } from '../../../utility/AppTexts/DefaultAppText.utils';
import { ApolloAuthContext, profileReducerTypes } from '../../../store/Apollo/ApolloContext';
import { LoadingComponent } from '../../../components/shared/Loading/Loading';
import styles from './Tags.module.css';
import {
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
  SuccessSnackbarActions,
  SuccessSnackbarOptions,
} from '../../../components/Alters/Snackbar/SnackbarOptions';
import FormSubmitButton from '../../../components/shared/buttons/FormSubmitButton/FormSubmitButton';
import { UserAuthFunctions } from '../../../store/Auth/providers/UserAuthFunctions';
import { appConsoleLog, AppUtils } from '../../../utility/appUtils';
import { AuthContext } from '../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { userviewSearchGraphQL } from '../../../services/user-view/user-view.service';
import {
  tagCreateGraphQL,
  tagDeleteGraphQL,
  tagSearchGraphQL,
} from '../../../services/tag/tag.service';
import { TagCardComponent } from '../../../components/shared/TagCard/TagCard';

type ITagFormValue = {
  tag_name: string;
};
export const TagsComponent: React.FunctionComponent<any> = () => {
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const user = useContext(AuthContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [tags, setTagList] = useState([]);
  const navgiate = useNavigate();

  let uploadRef: HTMLInputElement | null;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const projectCheck = () => {
    if (
      !selectedProject
      || !selectedProject.selected_project
      || !selectedProject.selected_project.id
    ) {
      navigateRoute('/app');
    }
  };

  const tagSearch = () => {
    tagSearchGraphQL(userProfile?.apollo_client, selectedProject.selected_project.id, '', 100, 0)
      .then((res) => {
        setTagList(res.tagSearch.result ? res.tagSearch.result : 0);
      })
      .catch((err) => {
        // alert("Something went wrong!")
      });
  };
  useEffect(() => {
    projectCheck();
    if (!selectedProject.selected_project.id) {
      return;
    }
    tagSearch();
  }, [tags]);

  const deleteTag = async (tagId: number) => {
    try {
      setLoading(true);
      appConsoleLog('deleteTag');
      await tagDeleteGraphQL(userProfile?.apollo_client, tagId)
        .then(async (res) => {
          setLoading(false);
          tagSearch();
          snackbar.enqueueSnackbar(APP_TEXT.tag.TAGS_DELETE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
        })
        .catch((err) => {
          setLoading(false);
          appConsoleLog(err);
          snackbar.enqueueSnackbar(APP_TEXT.tag.TAGS_DELETE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (error) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.tag.TAGS_DELETE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const onSubmitForm = async (
    values: ITagFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    try {
      if (!selectedProject.selected_project.id) {
        return;
      }
      await tagCreateGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
        values.tag_name.trim(),
      )
        .then(async (res) => {
          setLoading(false);
          tagSearch();
          snackbar.enqueueSnackbar(APP_TEXT.tag.TAGS_CREATE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          resetForm();
        })
        .catch((err) => {
          setLoading(false);
          appConsoleLog(err);
          snackbar.enqueueSnackbar(APP_TEXT.tag.TAGS_CREATE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (err) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.tag.TAGS_CREATE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  return (
    <div className={`${styles.root} main-content`}>
      <div className="pageHeader">{APP_TEXT.tag.TAG}</div>
      {/* <div className={"flex-container width-full-fixed row"}> */}
      <div className="col-md-12 row mt-1">
        <div className="col-md-12 ">
          <div className={styles.profileForm}>
            <Formik
              initialValues={{
                tag_name: '',
              }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                tag_name: Yup.string()
                  .required('Required')
                  .min(3, 'Name must be at least 3 characters'),
              })}
              onSubmit={onSubmitForm}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                submitForm,
              }) => (
                <form autoComplete="off">
                  <div className="col-md-12 ">
                    <div className="col-md-2 ">
                      <div>
                        <TextField
                          className={`${styles.fieldSpace} width-full-fixed`}
                          error={Boolean(touched.tag_name && errors.tag_name)}
                          helperText={touched.tag_name && errors.tag_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // value={values.name}
                          name="tag_name"
                          label={APP_TEXT.tag.FORM_FIELD_TAG}
                          size="small"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 nopadding">
                      {/* <div className={"buttonBar"}> */}
                      <FormSubmitButton
                        text={APP_TEXT.tag.FORM_BTN_TAG}
                        onClick={submitForm}
                        disable={loading}
                      >
                        {loading ? <LoadingComponent size={20} /> : <Save />}
                      </FormSubmitButton>
                    </div>
                    {/* </div> */}
                  </div>
                </form>
              )}
            </Formik>
          </div>

          {tags.length > 0 ? (
            <div className="col-md-12 ">
              {tags.map((tag: any) => (
                <div className="col-md-3 mt-1" key={tag.id}>
                  <TagCardComponent
                    title={tag.name}
                    deleteAction={() => deleteTag(tag.id)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="col-md-12">
              <h2 className="custom-link1">{APP_TEXT.tag.TAGS_AVAILABLE}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default TagsComponent;
