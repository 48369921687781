/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Reusable switch component
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a switch component
 * @author Mayantha
 * @since -
 */
import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Switch from '@mui/material/Switch';
import { appConsoleLog } from '../../../utility/appUtils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

type ComponentProps = {
  value?: boolean;
  onChange: any;
  name: string;
  onBlur?: any;
  checked?: any;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomSwitch: React.FunctionComponent<ComponentProps> = ({
  name,
  value,
  onChange,
  onBlur,
  checked,
}) => {
  const classes = useStyles();

  return (
    <Switch
      name={name}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      value={value}
      checked={checked || value || false}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

CustomSwitch.defaultProps = {
  value: false,
  onBlur: () => appConsoleLog('default onBlur'),
  checked: false,
};
