import React, {
  useEffect, useState, memo, useRef,
} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextField } from '@mui/material';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FORM_DEBOUNCE_TIME, FORM_IOT_NAME_LEN } from '../../../../../config/deviceConfig/Values';
import { TagComponent } from '../../../ProDeviceCreateEdit/Tag/Tag';
import { FormResetAction, IFormResetRef } from '../../../ProDeviceCreateEdit/types';

const FIELD_NAME = 'name_update';
type ComponentProps = {
  name: string;
  projectId: string;
  tags: string[];
  canEditName?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  canEditIotId?: boolean;
  validateForm: number;
  onChange: (payload:any, isInitializing?: boolean) => void;
  resetForm: IFormResetRef;
};
interface IformData {
  name: string;
}
const ProDeviceNameCraeteComponent: React.FunctionComponent<ComponentProps> = (
  props = {
    name: '',
    projectId: '',
    canEditName: true,
    canEditIotId: true,
    tags: [],
    validateForm: 0,
    resetForm: { ref: 0, action: '-', info: {} },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: (payload: any) => {},
  },
) => {
  const [tags, setTags] = useState<string[]>(props.tags);
  const [name, setName] = useState<string>(props.name);

  const createIotId = (textArg: string) => {
    let text = textArg;
    if (text === undefined) {
      text = '';
    }
    // name = name.replace(/\s+$/, "").replace(/\s\s+/g, " ").trim(); //
    text = text.replace(/[^0-9a-zA-Z\s]/g, '');
    text = text.trim().toLowerCase();

    if (text.length > FORM_IOT_NAME_LEN) {
      text = text.substr(0, FORM_IOT_NAME_LEN);
    }
    text = text
      .trim()
      .split(' ')
      .map((v) => v.trim())
      .join('_');
    text = `${text}_${Date.now()}`;
    // setIotId(text);
    return text;
  };

  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({ ref: 0, action: '', info: {} });

  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        formRef.current.setValues({ name: props.resetForm.info.name });
        setTags(props.resetForm.info.tags);
      } else {
        formRef.current.resetForm();
        setTags([]);
      }
    }
  }, [props.resetForm]);

  useEffect(() => {
    if (JSON.stringify(tags) !== JSON.stringify(props.tags)) {
      setTags(props.tags);
    }
  }, [props.tags]);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};

  const [deb, setDeb] = useState<
    BehaviorSubject<{
      values: IformData;
      errors: any;
      newTags: string[];
    } | null>
  >();
  const initilized = useRef(0);
  useEffect(() => {
    const b = new BehaviorSubject<{
      values: IformData;
      errors: any;
      newTags: string[];
    } | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any; newTags: string[]} | null) => {
        if (v == null) {
          return;
        }

        const { values, errors, newTags } = v;
        setTags(newTags);
        if (values && values.name && values.name.length === 0) {
          errors.name = 'Required';
        }
        const createdIotId = createIotId(values.name);
        const infoData = {
          name: values.name,
          tags: newTags,
        };

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const formRef = useRef<any>();

  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);

  // useEffect(() => {
  //   if (formRef) {
  //     formRef.current.setValues({
  //       name: props.name,
  //     });
  //   }
  // }, [formRef, props.name]);

  return (
    <div>
      <Formik
        initialValues={{
          name,
        }}
        enableReinitialize
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Required')
            .min(2, 'Item name not valid')
            .max(35, 'Item name must have less than 35 characters')
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Item name not valid'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values,
        }) => {
          deb?.next({
            values,
            errors,
            newTags: tags,
          });
          return (
            <form>
              <div className="col-md-12 pl-0 ml-0">
                <div className="col-md-6 pb-8 ml-0">
                  <TextField
                    disabled={!props.canEditName}
                    className="dynamic-ui-form-control-full-width"
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      console.log('text change:', e.target.value);
                      handleChange(e);
                    }}
                    value={values.name || ''}
                    name="name"
                    label="Device Name"
                    size="small"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="col-md-6 ">
                <TagComponent
                  projectId={props.projectId}
                  tagsSelected={tags}
                  onChange={(newTags) => {
                    deb?.next({
                      values,
                      errors,
                      newTags,
                    });
                  }}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

ProDeviceNameCraeteComponent.defaultProps = {
  canEditName: false,
  canEditIotId: false,
};

export default ProDeviceNameCraeteComponent;
