import React from 'react';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { AirValueCustomMap } from './AirValueCustomMap';

import { AirChannelSelector } from './AirChannelSelector';
import styles from './UVAirGangSelector.module.css';

type input = {
  setChannelFunction: (channel: string, field: string) => void;
  setExtraProperties: (code: string, value: any) => void;
  updateCustomFieldErrors: (data: any) => void;
  setChannelProperties: (channel: string, data: any, clean?: boolean) => void;
  channelKey: string;
  elmntKey: string;
  deviceState: any;
  allFunctions: string[];
  channelFieldMap: any;
  extraConfigs: any;
  typeConfig: any;
};
// eslint-disable-next-line import/prefer-default-export
export const SensorAirChannel = (props: input) => {
  const uvItemFields: any[] = [];
  uvItemFields.push(
    <div className="col-md-6 pl-0" key={props.elmntKey}>
      <AirChannelSelector
        label="Select Sensor Channel"
        deviceState={props.deviceState}
        channels={props.allFunctions}
        selectedChannel={props.channelFieldMap[props.channelKey]}
        onChange={(item) => {
          props.setChannelFunction(props.channelKey, item);
        }}
      />
    </div>,
  );
  let sensorTypes = [];
  if (props.typeConfig.channels[props.channelKey]) {
    const channelConfig = props.typeConfig.channels[props.channelKey];
    if (channelConfig && channelConfig.types) {
      sensorTypes = channelConfig.types;
    }
  }

  let ctypeVal = '';
  let trueLabel = 'True';
  let falseLabel = 'False';
  let valueScale = '100';
  let displaySuffix = '';

  if (props.extraConfigs[props.channelKey] && props.extraConfigs[props.channelKey].type) {
    ctypeVal = props.extraConfigs[props.channelKey].type;
    trueLabel = props.extraConfigs[props.channelKey].true_label;
    falseLabel = props.extraConfigs[props.channelKey].false_label;
    valueScale = props.extraConfigs[props.channelKey].value_scale;
    displaySuffix = props.extraConfigs[props.channelKey].display_suffix;
  }

  uvItemFields.push(
    <div className="col-md-6 pl-0" key={`st${props.elmntKey}`}>
      <AirChannelSelector
        label="Select Sensor type"
        channels={sensorTypes}
        selectedChannel={ctypeVal}
        onChange={(item) => {
          props.setChannelProperties(props.channelKey, { type: item }, true);
        }}
      />
    </div>,
  );
  uvItemFields.push(<div className="col-md-12 mt-8m" key={`mg-${props.elmntKey}`} />);
  if (ctypeVal === 'boolean') {
    uvItemFields.push(
      <div className={`col-md-6 ${styles.textInput}`} key={`tl${props.elmntKey}`}>
        <TextField
          className="uv-ui-form-control-full-width"
          onChange={(e) => {
            props.setChannelProperties(props.channelKey, {
              true_label: e.target.value,
            });
          }}
          value={trueLabel}
          name="true_lable"
          label="True Label"
          size="small"
          variant="outlined"
        />
      </div>,
    );
    uvItemFields.push(
      <div className={`col-md-6 ${styles.textInput}`} key={`fl${props.elmntKey}`}>
        <TextField
          className="uv-ui-form-control-full-width"
          onChange={(e) => {
            props.setChannelProperties(props.channelKey, {
              false_label: e.target.value,
            });
          }}
          value={falseLabel}
          name="false_label"
          label="False Label"
          size="small"
          variant="outlined"
        />
      </div>,
    );
  } else if (ctypeVal === 'numeric') {
    uvItemFields.push(
      <div className={`col-md-6 ${styles.textInput}`} key={`numeric${props.elmntKey}`}>
        <TextField
          className="uv-ui-form-control-full-width"
          onChange={(e) => {
            props.setChannelProperties(props.channelKey, {
              value_scale: e.target.value,
            });
          }}
          value={valueScale}
          name="scale_label"
          label="Scale (value*Scale)"
          size="small"
          variant="outlined"
        />
      </div>,
    );
  } else if (ctypeVal === 'map') {
    uvItemFields.push(
      <div className="col-md-12 pl-0 ml-0" key={`map${props.elmntKey}`}>
        <AirValueCustomMap
          initialValueMap={props.extraConfigs.values_map}
          onChange={(payload: any) => {
            // appendSettings(payload);
            props.setExtraProperties('values_map', payload);
          }}
          updateCustomFieldErrors={(error: any) => {
            props.updateCustomFieldErrors(error);
          }}
        />
      </div>,
    );
  }

  uvItemFields.push(
    <div className={`col-md-4 ${styles.textInput}`} key={`suffix${props.elmntKey}`}>
      <TextField
        className="uv-ui-form-control-full-width"
        onChange={(e) => {
          props.setChannelProperties(props.channelKey, {
            display_suffix: e.target.value,
          });
        }}
        value={displaySuffix}
        name="suffix_label"
        label="Display Suffix ($,V,A,°C,...)"
        size="small"
        variant="outlined"
      />
    </div>,
  );
  return uvItemFields;
};
