/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Check, TIME_FRAME } from '@smartlife-redux-state/common';
import { useDispatch } from 'react-redux';
import { update_check } from 'src/store/redux/features/logic-engine';
import moment from 'moment';

export const DaysOfWeek = [
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
  { label: 'Sunday', value: '0' },
];

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
  },
  dayCard: {
    display: 'flex',
    alignItems: 'center',
    background: '#818181',
    borderRadius: '2px',
    height: '100%',
    width: '100%',
    // margin: "2px",
    padding: '8px',
    color: '#ffffff',
    cursor: 'pointer',
  },
  dayCardActive: {
    background: '#048696',
  },
});

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const TimeCheck: React.FC<{check: Check}> = ({ check }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [time_state, setTimeState] = useState({
    from: {
      hr: '12',
      min: '10',
      meridiem: TIME_FRAME.AM,
    },
    to: {
      hr: '11',
      min: '10',
      meridiem: TIME_FRAME.PM,
    },
  });

  const from24Val = moment(
    `${time_state.from.hr}:${time_state.from.min} ${time_state.from.meridiem}`,
    ['h:mm A'],
  );
  const to24Val = moment(`${time_state.to.hr}:${time_state.to.min} ${time_state.to.meridiem}`, [
    'h:mm A',
  ]);

  const range = to24Val.unix() - from24Val.unix();

  useEffect(() => {
    if (check.variable && isJson(check.variable)) {
      const parsed = JSON.parse(check.variable);
      setTimeState(parsed);
    } else if (!check.variable || !isJson(check.variable)) {
      dispatch(
        update_check({
          check: {
            ...check,
            variable: JSON.stringify({
              ...time_state,
            }),
          },
        }),
      );
    }
  }, [check]);

  const updateCheck = (payload: any) => {
    console.log('xxxxx', payload);
    dispatch(
      update_check({
        check: {
          ...check,
          variable: JSON.stringify({
            ...payload,
          }),
        },
      }),
    );
  };

  return (
    <Grid container spacing={2}>
      {range < 0 && (
        <Grid item md={12}>
          <Alert style={{ width: '100%' }} severity="error">
            Invalid Time Range
          </Alert>
        </Grid>
      )}
      <Grid item md={12}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <FormControl variant="outlined" size="small" className={classes.formRoot}>
                  <InputLabel>HH</InputLabel>
                  <Select
                    value={time_state.from.hr}
                    label="HH"
                    onChange={(e) => {
                      updateCheck({
                        ...time_state,
                        from: {
                          ...time_state.from,
                          hr: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={0}>Default</MenuItem>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl variant="outlined" size="small" className={classes.formRoot}>
                  <InputLabel>MM</InputLabel>
                  <Select
                    value={time_state.from.min}
                    label="MM"
                    onChange={(e) => {
                      updateCheck({
                        ...time_state,
                        from: {
                          ...time_state.from,
                          min: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={0}>Default</MenuItem>
                    {Array.from({ length: 60 }).map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem value={index} key={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl variant="outlined" size="small" className={classes.formRoot}>
                  <InputLabel>Meridiem</InputLabel>
                  <Select
                    value={time_state.from.meridiem}
                    label="Meridiem"
                    onChange={(e) => {
                      updateCheck({
                        ...time_state,
                        from: {
                          ...time_state.from,
                          meridiem: e.target.value,
                        },
                      });
                    }}
                  >
                    {Object.values(TIME_FRAME).map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Typography style={{ textAlign: 'center' }}>In Between</Typography>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <FormControl variant="outlined" size="small" className={classes.formRoot}>
                  <InputLabel>HH</InputLabel>
                  <Select
                    value={time_state.to.hr}
                    label="HH"
                    onChange={(e) => {
                      updateCheck({
                        ...time_state,
                        to: {
                          ...time_state.to,
                          hr: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={0}>Default</MenuItem>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl variant="outlined" size="small" className={classes.formRoot}>
                  <InputLabel>MM</InputLabel>
                  <Select
                    value={time_state.to.min}
                    label="MM"
                    onChange={(e) => {
                      updateCheck({
                        ...time_state,
                        to: {
                          ...time_state.to,
                          min: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={0}>Default</MenuItem>
                    {Array.from({ length: 60 }).map((item, index) => (
                      <MenuItem value={index} key={index}>
                        {index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl variant="outlined" size="small" className={classes.formRoot}>
                  <InputLabel>Meridiem</InputLabel>
                  <Select
                    value={time_state.to.meridiem}
                    label="Meridiem"
                    onChange={(e) => {
                      updateCheck({
                        ...time_state,
                        to: {
                          ...time_state.to,
                          meridiem: e.target.value,
                        },
                      });
                    }}
                  >
                    {Object.values(TIME_FRAME).map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
