/* eslint-disable camelcase */
import { Box } from '@mui/material';
import { ReCron } from '@sbzen/re-cron';
import { Trigger } from '@smartlife-redux-state/common';
import React, { useEffect, useState } from 'react';

import { update_trigger } from '../../../../../../../store/redux/features/logic-engine';
import { useDispatch } from '../../../../../../../store/redux/store';
import { CronSelector } from './shared/CronSelector/CronSelector';

// eslint-disable-next-line import/prefer-default-export
export const SchedulerTime: React.FC<{trigger: Trigger}> = ({ trigger }) => {
  const [cronValue, setCronValue] = useState('0,1,2 2/4 6/2 ? 2-7 SUN,MON 2019/1');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      update_trigger({
        trigger: {
          ...trigger,
          condition: '0 1 * * *',
          // variable: "{}",
          // possible_variable_type: null
        },
      }),
    );
  }, []);

  return (
    <Box>
      <CronSelector trigger={trigger} />
      {/* <ReCron
        value={trigger.variable || ""}
        onChange={(value) => {
          dispatch(
            update_trigger({
              trigger: {
                ...trigger,
                condition: value,
                // variable: value,
                // possible_variable_type: 'cron'
              }
            })
          )
        }} /> */}
    </Box>
  );
};
