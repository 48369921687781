import { COMPONENT_UNIT_TYPES } from 'src/plugins/shared/item.config';
import { Component, Tag } from '../../shared/uvitem.config';
import {
  CurrentTags,
  EnergyTags,
  VoltageTags,
  WattageTags,
  TemperatureTags,
  LightTags,
  MotionTags,
  PercentageTags,
  VibrationTags,
} from './tags';

const commonTagFields = {
  type: COMPONENT_UNIT_TYPES.Sensor,
  main_key: null,
  command_key: null,
  can_send_command: false,
};

const sensorTags = {
  sensor_value: {
    tag: 'sensor_value',
    description: 'Sensor Value',
    ...commonTagFields,
    command_v: 'v1',
  },
};

const sensorAsSwitchTags = {
  sensor_value: {
    tag: 'sensor_value',
    description: 'Sensor Value',
    ...commonTagFields,
    can_send_command: true,
    config: {
      mapping: {
        true: 'ON',
        false: 'OFF',
      },
      scale: 1,
    },
    command_v: 'v1',
  },
};

const config: Record<string, Component> = {
  sensor_1: {
    id: 'sensor_1',
    componentName: 'General Sensor 1',
    created_at: '2023.05.08',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: sensorTags,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_current: {
    id: 'emeter_current',
    componentName: 'Emeter Current | Amps',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: CurrentTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_current_as_watt: {
    id: 'emeter_current_as_watt',
    componentName: 'Emeter Current | Amps --> W',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: CurrentTags.tags2,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_current_as_k_watt: {
    id: 'emeter_current_as_k_watt',
    componentName: 'Emeter Current | Amps --> kW',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: CurrentTags.tags3,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_voltage: {
    id: 'emeter_voltage',
    componentName: 'Emeter Voltage | V',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: VoltageTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_wattage: {
    id: 'emeter_wattage',
    componentName: 'Emeter Power | kW',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: WattageTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_wattage_2: {
    id: 'emeter_wattage_2',
    componentName: 'Emeter Power | W',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: WattageTags.tags2,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_wattage_3: {
    id: 'emeter_wattage_3',
    componentName: 'Emeter Power | W --> kW',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: WattageTags.tags3,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_wattage_4: {
    id: 'emeter_wattage_4',
    componentName: 'Emeter Power | kW --> W',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: WattageTags.tags4,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_energy: {
    id: 'emeter_energy',
    componentName: 'Emeter Energy | kWh',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: EnergyTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_energy_1: {
    id: 'emeter_energy_1',
    componentName: 'Emeter Energy | Wh',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: EnergyTags.tags2,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_energy_2: {
    id: 'emeter_energy_2',
    componentName: 'Emeter Energy | Wh --> kWh',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: EnergyTags.tags3,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_energy_3: {
    id: 'emeter_energy_3',
    componentName: 'Emeter Energy | kWh --> Wh',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: EnergyTags.tags4,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_energy_4: {
    id: 'emeter_energy_4',
    componentName: 'Emeter Energy | Wmin --> Wh',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: EnergyTags.tags5,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_temperature_1: {
    id: 'emeter_temperature_1',
    componentName: 'Emeter Temperature | Celcius',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: TemperatureTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_temperature_2: {
    id: 'emeter_temperature_2',
    componentName: 'Emeter Temperature | Fahrenheit',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: TemperatureTags.tags2,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_light: {
    id: 'emeter_light',
    componentName: 'Emeter Light | Lux',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: LightTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_motion: {
    id: 'emeter_motion',
    componentName: 'Emeter Motion',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: MotionTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  sensor_percentage: {
    id: 'sensor_percentage',
    componentName: 'Percentage',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: PercentageTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_vibration: {
    id: 'emeter_vibration',
    componentName: 'Emeter Vibration',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: VibrationTags.tags1,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
  emeter_as_switch: {
    id: 'emeter_as_switch',
    componentName: 'Emeter As Switch',
    created_at: '2024.04.26',
    item_type: 'shelly.emeter',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: sensorAsSwitchTags,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
};

export default config;
