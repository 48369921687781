/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import Paper from '@mui/material/Paper';
import {
  Grid, Table, TableHeaderRow, TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import makeStyles from '@mui/styles/makeStyles';
import { useTree } from '.';

const Gridx: any = Grid;
type DataSourceCollectionItem = {
  devices: {
    cint_coll: string;
    dc_type: 'cint' | 'pro' | 'air';
    id: string;
    name: string;
  };
  uv_items: any[];
};
// const TableX: any = Table;
// const TableHeaderRowX: any = TableHeaderRow;
// const TableTreeColumnX: any = TableTreeColumn;

const useStyle = makeStyles({
  paper: {
    border: '1px solid #7f8fa4',
    boxShadow: 'none',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const Tree: React.FC<{
  dataSource: {
    json_map: {
      air: Record<string, DataSourceCollectionItem>;
      pro: Record<string, DataSourceCollectionItem>;
      cint: Record<string, DataSourceCollectionItem>;
      userviews: Record<string, {id: string; name: string}>;
    };
    project_id: string;
  };
}> = ({ dataSource }) => {
  const { treeData, dispatch } = useTree();
  const styles = useStyle();
  const [columns] = useState([
    // { name: "id", title: "ID" },
    { name: 'title', title: 'Tree View' },
    // { name: "uv", title: "USER VIEW ID" },
  ]);

  const [tablecolExtensions] = useState([
    // { columnName: "id", width: 240 },
    { columnName: 'title', width: 300 },
    // { columnName: "uv", width: 200 },
  ]);

  const [expandedRowIds, setExpandenRowIds] = useState<(string | number)[]>([
    0, 1, 14,
    // 2,
  ]);

  useEffect(() => {
    if (dataSource) {
      dispatch({
        type: 'SET',
        payload: { ...dataSource, onSelect: onDeviceSelect },
      });
    }
  }, [dataSource]);

  const onDeviceSelect = (selectedItem: string) => {
    dispatch({
      type: 'SELECT',
      payload: selectedItem,
    });
  };

  const getChildRows = (row: any, rootRows: any) => {
    const childRows = rootRows.filter((r: any) => r.parentId === (row ? row.id : null));
    // console.log("childrows", childRows);
    return childRows.length ? childRows : null;
  };

  // return <div>TEst</div>
  return (
    <Paper className={styles.paper}>
      <Gridx rows={treeData} columns={columns}>
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={(expanded) => {
            setExpandenRowIds(expanded);
          }}
        />
        <CustomTreeData getChildRows={getChildRows} />
        <Table columnExtensions={tablecolExtensions} />
        <TableHeaderRow />
        <TableTreeColumn for="title" />
      </Gridx>
    </Paper>
  );
};
