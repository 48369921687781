/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { appConsoleLog } from '../../utility/appUtils';
import {
  CHECK_USER_IS_SUPER_ADMIN,
  DELETE_LOGGED_IN_USER,
  PROJECT_INIVITE_MUTATION,
  PROJECT_LIST_USERS_QUERY,
  PROJECT_REMOVE_USER_MUTATION,
  PROJECT_USER_INFO,
  PROJECT_USER_PERMISSION_UPDATE_MUTATION,
} from './graphql';

const deleteLoggedInUser = async (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  const res = await apolloClient.mutate({
    mutation: DELETE_LOGGED_IN_USER,
  });
  return res;
};

const getProjectMembersGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  access_level: any,
  permission_status: any,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PROJECT_LIST_USERS_QUERY,
    variables: {
      project_id,
      access_level,
      permission_status,
    },
    fetchPolicy: 'no-cache',
  });
  return res.data;
};

const getProjectMemeberInfo = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  user_email: string,
) => {
  const res = await apolloClient.query({
    query: PROJECT_USER_INFO,
    variables: {
      project_id,
      user_email,
    },
  });

  return res.data;
};

const addProjectMemeberGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  user_email: string,
  user_title: string,
  access_level: number,
  userview_ids: any,
): Promise<any> => {
  if (userview_ids && userview_ids.length > 0) {
    const index = userview_ids.indexOf(0, 0);
    if (index > -1) {
      userview_ids.splice(index, 1);
    }
  }
  try {
    const res = await apolloClient.mutate({
      mutation: PROJECT_INIVITE_MUTATION,
      variables: {
        input: {
          project_id,
          user_email,
          user_title,
          access_level,
          userview_ids,
        },
      },
      fetchPolicy: 'no-cache',
    });
    // appConsoleLog(res)
    return res.data;
  } catch (err) {
    appConsoleLog(err);
    throw err;
  }
};

const projectUserPermissionUpdateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  user_email: string,
  user_title: string,
  access_level: number,
  userview_ids: any,
  permission_enabled = 1,
): Promise<any> => {
  if (userview_ids && userview_ids.length > 0) {
    const index = userview_ids.indexOf(0, 0);
    if (index > -1) {
      userview_ids.splice(index, 1);
    }
  }
  try {
    const res = await apolloClient.mutate({
      mutation: PROJECT_USER_PERMISSION_UPDATE_MUTATION,
      variables: {
        input: {
          project_id,
          user_email,
          user_title,
          access_level: Number(access_level),
          userview_ids,
          permission_enabled,
        },
      },
      fetchPolicy: 'no-cache',
    });
    // appConsoleLog(res)
    return res.data;
  } catch (err) {
    appConsoleLog(err);
    throw err;
  }
};

const deleteProjectUserGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  permission_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: PROJECT_REMOVE_USER_MUTATION,
    variables: {
      project_id,
      permission_id,
    },
  });
  return res.data;
};

const checkUserIsSystemAdmin = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => {
  const res = await apolloClient.query({
    query: CHECK_USER_IS_SUPER_ADMIN,
  });

  return res.data;
};

export {
  getProjectMembersGraphQL,
  addProjectMemeberGraphQL,
  projectUserPermissionUpdateGraphQL,
  deleteProjectUserGraphQL,
  getProjectMemeberInfo,
  deleteLoggedInUser,
  checkUserIsSystemAdmin,
};
