/* eslint-disable max-len */
import {Box, Button, Skeleton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {skip} from 'rxjs';
import {LogicStep} from 'src/containers/app/LogicsEngine/components/LogicStepper';
import PageContentLayout, {
  PageConentHeader,
  PageContent,
} from 'src/containers/app/LogicsEngine/components/PageContentLayout';
import {CofirmDialog} from 'src/containers/app/LogicsEngine/components/ui/Confirm';
import {FillButton} from 'src/containers/app/LogicsEngine/components/ui/FillButton';
import {userviewSearchGraphQL} from 'src/services/user-view/user-view.service';
import {ApolloAuthContext} from 'src/store/Apollo/ApolloContext';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {ProjectContext} from 'src/store/Project/ProjectContext';
import {
  v2InfoUpdate,
  v2InitHideItems,
  v2Reset,
} from 'src/store/redux/features/backup-location-config';
import toAPIPostFriendyAirDeviceInfoJson from 'src/store/redux/features/backup-location-config/parsers/apiFriendly/airDevice';
import toAPIPostFriendyLocVarInfoJson from 'src/store/redux/features/backup-location-config/parsers/apiFriendly/locVar';
import toAPIPostFriendyProDeviceInfoJson from 'src/store/redux/features/backup-location-config/parsers/apiFriendly/proDevice';
import toAPIPostFriendySmartListInfoJson from 'src/store/redux/features/backup-location-config/parsers/apiFriendly/smartLists';
import toAPIFriendyUVInfoJson from 'src/store/redux/features/backup-location-config/parsers/apiFriendly/userViews';
import {resetConfigCotnainers} from 'src/store/redux/features/configurable-containers';
import {RootState} from 'src/store/redux/store';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';
import LeftPanel from '../../ConfigureLocation/LeftPanel/LeftPanel';
import {CloneSuccessRateContext} from '../shared/context/cloneSuccessRateContext';
import CreateContainerDialog from '../shared/CreateContainerDialog';
import useRestoreHelpers from '../shared/hooks/useRestoreHelpers';
import useStepSummery from '../shared/hooks/useStepSummery';
import NodeRed from '../shared/NodeRed';
import RestoreStepBottomSummery from '../shared/RestoreStepBottomSummery';
import RestoreStepBottomButtons from '../shared/RstoreStepBottomButtons';
import RestoreLogTerminal from '../shared/Terminal';
import SessionRestoreAirDevices from './SessionRestoreAirDevice';
import SessionRestoreFinishUp from './SessionRestoreFinishUp';
import SessionRestoreLocVar from './SessionRestoreLocVar';
import SessionRestoreProDevices from './SessionRestoreProDevice';
import SessionRestoreCloudDevices from './SessionRestoreCloudDevice';
import toAPIPostFriendyCloudDeviceInfoJson from 'src/store/redux/features/backup-location-config/parsers/apiFriendly/cloudDevice';

const useStyle = makeStyles({
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '2%',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  bottom: {
    width: '100%',
    display: 'flex',
  },
  detailsBox: {
    width: '100%',
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "flex-end",
    // border: "1px solid #D9DFE0",
    padding: '2% 0',
    justifyContent: 'space-between',
    // borderRadius: "5px",
  },
});

const SessionRestoreContent: React.FC<{
  sessionId: string;
}> = ({sessionId}) => {
  const styles = useStyle();

  const disableLvarState = useQueryStringFirst('disabled-lvar');
  const disableProdState = useQueryStringFirst('disabled-prod');
  const disableAirdState = useQueryStringFirst('disabled-aird');

  const navgiate = useNavigate();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const successRate = useContext(CloneSuccessRateContext);
  const {enqueueSnackbar} = useSnackbar();

  const [cloneProgress, setCloneProgress] = useState(false);

  const [deleteActionId, setDeleteActionid] = useState(null);
  const [cloneTriggered, setCloneTriggered] = useState<string>(null);
  const [skipTriggered, setSkipTriggered] = useState<string>(null);
  const [lastStepCalled, setLastStepCalled] = useState(false);

  const [currentRunningStep, setCurrentRunningStep] = useState<string>();
  function deleteAction() {
    navgiate(
      `/app/backup-restore/restore-sessions/list?projectId=${selectedProject.selected_project.id}`
    );
    setDeleteActionid(null);
  }

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (disableLvarState && disableLvarState.length > 0) {
      onRestoreProgressEnd(
        sessionId,
        'lvar',
        locVarContentRef,
        stepSummery.reCheckStats,
        setCloneProgress,
        setLogPath,
        logPath,
        true
      );
    }
    if (disableProdState && disableProdState.length > 0) {
      onRestoreProgressEnd(
        sessionId,
        'prod',
        proContentRef,
        stepSummery.reCheckStats,
        setCloneProgress,
        setLogPath,
        logPath,
        true
      );
    }
    if (disableAirdState && disableAirdState.length > 0) {
      onRestoreProgressEnd(
        sessionId,
        'aird',
        airContentRef,
        stepSummery.reCheckStats,
        setCloneProgress,
        setLogPath,
        logPath,
        true
      );
    }
  }, [disableLvarState, disableProdState, disableAirdState]);

  const locVarPendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.lvar
  );
  const proDevicePendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.prodc
  );
  const airDevicePendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.airdc
  );
  const cloudDevicePendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.cintd
  );
  const smartListPendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.lv
  );
  const hiddenDevices = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.hidden
  );
  const configurableList = useSelector((state: RootState) => state.configurable_containers);

  const [activeTaskUid, setActiveTaskUid] = useState<string>(null);
  const [logPath, setLogPath] = useState({
    lvar: '',
    prod: '',
    aird: '',
    cintd: '',
    uv: '',
    lgc: '',
    lv: '',
    uvi: '',
  });

  const stepSummery = useStepSummery();
  const {fetchSession, onRestoreProgressEnd, determineTaskTypeToBeRunInLastStep} =
    useRestoreHelpers();

  useEffect(() => {
    dispatch(resetConfigCotnainers());
    dispatch(v2Reset());
    if (sessionId) {
      fetchTaskHistory();
      stepSummery.init(sessionId);
    }
  }, [sessionId]);

  const lastClonePreStep = async (hard = false) => {
    try {
      setCloneProgress(true);

      stepSummery.reCheckStats(sessionId, currentRunningStep as any);

      determineTaskTypeToBeRunInLastStep(
        sessionId,
        currentRunningStep,
        (type, rate) => startLocClone(type, rate),
        hard
      );
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
      setCloneProgress(false);
    }
  };

  const skipStep = async (type = 'lvar') => {
    try {
      setCurrentRunningStep(type);
      setCloneProgress(true);
      setSkipTriggered(null);
      let res;

      if (type === 'lvar') {
        res = toAPIPostFriendyLocVarInfoJson(locVarPendingComp, true);
      }
      if (type === 'prod') {
        res = toAPIPostFriendyProDeviceInfoJson(proDevicePendingComp, configurableList, true);
      }
      if (type === 'aird') {
        res = toAPIPostFriendyAirDeviceInfoJson(airDevicePendingComp, configurableList, true);
      }
      if (type === 'cintd') {
        res = toAPIPostFriendyCloudDeviceInfoJson(cloudDevicePendingComp, configurableList, true);
      }

      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/task/run`,
        {
          session_id: sessionId,
          type,
          info_json: res,
          info_state: JSON.stringify({
            hidden: hiddenDevices,
          }),
          disable_after_completed: 100,
          disable_when_error: 'on',
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      // && response.data.result._doc
      // eslint-disable-next-line no-underscore-dangle
      if (response.data && response.data.result) {
        // eslint-disable-next-line no-underscore-dangle
        setLogPath({...logPath, [type]: response.data.result.log_ref});
        setActiveTaskUid(response.data.result.task_uid);
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
      setCloneProgress(false);
    }
  };

  const startLocClone = async (type = 'lvar', cloneSuccessRate = 100) => {
    try {
      setCurrentRunningStep(type);
      setCloneProgress(true);
      setCloneTriggered(null);
      let res;

      if (type === 'lvar') {
        res = toAPIPostFriendyLocVarInfoJson(locVarPendingComp);
      }
      if (type === 'prod') {
        res = toAPIPostFriendyProDeviceInfoJson(proDevicePendingComp, configurableList);
      }
      if (type === 'aird') {
        res = toAPIPostFriendyAirDeviceInfoJson(airDevicePendingComp, configurableList);
        // eslint-disable-next-line no-debugger
        // debugger;
      }
      if (type === 'cintd') {
        res = toAPIPostFriendyCloudDeviceInfoJson(cloudDevicePendingComp, configurableList);
      }
      if (type === 'uv') {
        // const uvs = await fetchUserViews();
        const templateUvs = await fetchTemplateUV();
        res = toAPIFriendyUVInfoJson(
          templateUvs && templateUvs.pendingComps ? templateUvs.pendingComps : {}
        );
      }
      if (type === 'lgc') {
        if (!successRate.default_alexa_user) throw new Error('Default Alexa Account Not Found');
        if (
          !successRate.default_email ||
          !/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(successRate.default_email)
        ) {
          throw new Error('Invalid Logic Email');
        }
        if (Object.keys(successRate.dcMap).length == 0) {
          throw new Error('No device container map found');
        }
        res = {
          alexa_users: {
            default_alexa_user: successRate.default_alexa_user.id,
          },
          default_logic_email: successRate.default_email,
          dc_map: successRate.dcMap,
        };
      }
      if (type === 'lv') {
        res = toAPIPostFriendySmartListInfoJson(smartListPendingComp);
      }
      console.log(res);

      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/task/run`,
        {
          session_id: sessionId,
          type,
          info_json: res,
          info_state: JSON.stringify({
            hidden: hiddenDevices,
          }),
          disable_after_completed:
            cloneSuccessRate < 0 || cloneSuccessRate > 100 ? 100 : cloneSuccessRate,
          disable_when_error: 'on',
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      // && response.data.result._doc
      // eslint-disable-next-line no-underscore-dangle
      if (response.data && response.data.result) {
        // eslint-disable-next-line no-underscore-dangle
        setLogPath({...logPath, [type]: response.data.result.log_ref});
        setActiveTaskUid(response.data.result.task_uid);
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
      setCloneProgress(false);
    }
  };

  const fetchTaskHistory = async (type = 'lvar') => {
    try {
      const url = '/rest/backups/v2/restore/clone/task/result';
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          task_type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      if (response && response.data && response.data.result) {
        // eslint-disable-next-line camelcase
        const {info_state} = response.data.result;
        try {
          const parsed = JSON.parse(info_state);

          if (parsed && parsed.hidden) {
            dispatch(
              v2InitHideItems({
                hidden: parsed.hidden,
              })
            );
          }
        } catch (parserError: any) {
          console.log('info_state cannot be parsed');
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  // eslint-disable-next-line consistent-return
  const fetchTemplateUV = async (populated = true) => {
    const type = 'uv';
    const url = '/rest/backups/v2/restore/get-session-progress';
    try {
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          task_type: type,
          with_comp_map: populated,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      if (response.data && response.data.result) {
        // dispatch(
        //   v2InfoUpdate({
        //     value: response.data.result,
        //     key: 'airdc',
        //     mergeErrorComps: true,
        //   }),
        // );
        return response.data.result;
      }
    } catch (err: any) {
      // enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const locVarContentRef = useRef<any>();
  const proContentRef = useRef<any>();
  const airContentRef = useRef<any>();
  const cloudContentRef = useRef<any>();

  const [createContainerDialog, setCreateContainerDialog] = useState(false);

  return (
    <PageContentLayout className={styles.root}>
      <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button variant="outlined" onClick={() => setCreateContainerDialog(true)}>
          Create Containers
        </Button>
      </Box>
      <LogicStep>
        {({activeStep, setActive}) => {
          const index = 0;
          if (index !== activeStep) return <></>;
          return (
            <>
              <PageConentHeader>
                <Typography variant="h5" component="h2">
                  Location Variable Restoring
                </Typography>
              </PageConentHeader>
              <PageContent className={styles.content}>
                <Box>
                  <SessionRestoreLocVar ref={locVarContentRef} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.lvar}
                    onEnd={() => {
                      onRestoreProgressEnd(
                        sessionId,
                        'lvar',
                        locVarContentRef,
                        stepSummery.reCheckStats,
                        setCloneProgress,
                        setLogPath,
                        logPath,
                        true
                      );
                    }}
                    onError={err => {
                      console.log(err);
                      setCloneProgress(false);
                      setLogPath({...logPath, lvar: ''});
                    }}
                    sessionId={sessionId}
                  />
                </Box>
                <Box className={styles.bottom}>
                  {stepSummery.processing ? (
                    <Box style={{width: '100%'}}>
                      <Skeleton variant="rectangular" style={{width: '100%', height: 50}} />
                    </Box>
                  ) : (
                    <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                      <RestoreStepBottomSummery sessionId={sessionId} type="lvar" />
                      <RestoreStepBottomButtons
                        type="lvar"
                        setCloneTriggered={setCloneTriggered}
                        cloneProgress={cloneProgress}
                        setNext={() => setActive(1)}
                        onSkip={type => {
                          setSkipTriggered(type);
                        }}
                        setDeleteActionid={() => setDeleteActionid(1)}
                      />
                    </Box>
                  )}
                </Box>
              </PageContent>
            </>
          );
        }}
      </LogicStep>
      <LogicStep>
        {({activeStep, setActive}) => {
          const index = 1;
          if (index !== activeStep) return <></>;

          return (
            <>
              <PageConentHeader>
                <Typography variant="h5" component="h2">
                  Connect Device Restoring
                </Typography>
              </PageConentHeader>
              <PageContent className={styles.content}>
                <Box>
                  <SessionRestoreProDevices ref={proContentRef} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.prod}
                    onEnd={() => {
                      onRestoreProgressEnd(
                        sessionId,
                        'prod',
                        proContentRef,
                        stepSummery.reCheckStats,
                        setCloneProgress,
                        setLogPath,
                        logPath,
                        true
                      );
                    }}
                    onError={err => {
                      setLogPath({...logPath, prod: ''});
                    }}
                    sessionId={sessionId}
                  />
                </Box>
                <Box className={styles.bottom}>
                  {stepSummery.processing ? (
                    <Box style={{width: '100%'}}>
                      <Skeleton variant="rectangular" style={{width: '100%', height: 50}} />
                    </Box>
                  ) : (
                    <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                      <RestoreStepBottomSummery sessionId={sessionId} type="prod" />
                      <RestoreStepBottomButtons
                        type="prod"
                        setCloneTriggered={setCloneTriggered}
                        cloneProgress={cloneProgress}
                        onSkip={type => {
                          setSkipTriggered(type);
                        }}
                        setNext={() => setActive(2)}
                        setPrevious={() => setActive(0)}
                        setDeleteActionid={() => setDeleteActionid(1)}
                      />
                    </Box>
                  )}
                </Box>
              </PageContent>
            </>
          );
        }}
      </LogicStep>
      <LogicStep>
        {({activeStep, setActive}) => {
          const index = 2;
          if (index !== activeStep) return <></>;
          return (
            <>
              <PageConentHeader>
                <Typography variant="h5" component="h2">
                  Air Device Restoring
                </Typography>
              </PageConentHeader>
              <PageContent className={styles.content}>
                <Box>
                  <SessionRestoreAirDevices ref={airContentRef} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.aird}
                    onEnd={() => {
                      onRestoreProgressEnd(
                        sessionId,
                        'aird',
                        airContentRef,
                        stepSummery.reCheckStats,
                        setCloneProgress,
                        setLogPath,
                        logPath,
                        true
                      );
                    }}
                    onError={err => {
                      setLogPath({...logPath, aird: ''});
                    }}
                    sessionId={sessionId}
                  />
                </Box>

                <Box className={styles.bottom}>
                  {stepSummery.processing ? (
                    <Box style={{width: '100%'}}>
                      <Skeleton variant="rectangular" style={{width: '100%', height: 50}} />
                    </Box>
                  ) : (
                    <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                      <RestoreStepBottomSummery sessionId={sessionId} type="aird" />
                      <RestoreStepBottomButtons
                        type="aird"
                        setCloneTriggered={setCloneTriggered}
                        cloneProgress={cloneProgress}
                        onSkip={type => {
                          setSkipTriggered(type);
                        }}
                        setNext={() => setActive(3)}
                        setPrevious={() => setActive(1)}
                        setDeleteActionid={() => setDeleteActionid(1)}
                      />
                    </Box>
                  )}
                </Box>
              </PageContent>
            </>
          );
        }}
      </LogicStep>
      <LogicStep>
        {({activeStep, setActive}) => {
          const index = 3;
          if (index !== activeStep) return <></>;
          return (
            <>
              <PageConentHeader>
                <Typography variant="h5" component="h2">
                  Cloud Device Restoring
                </Typography>
              </PageConentHeader>
              <PageContent className={styles.content}>
                <Box>
                  <SessionRestoreCloudDevices ref={cloudContentRef} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.cintd}
                    onEnd={() => {
                      onRestoreProgressEnd(
                        sessionId,
                        'cintd',
                        cloudContentRef,
                        stepSummery.reCheckStats,
                        setCloneProgress,
                        setLogPath,
                        logPath,
                        true
                      );
                    }}
                    onError={err => {
                      setLogPath({...logPath, cintd: ''});
                    }}
                    sessionId={sessionId}
                  />
                </Box>

                <Box className={styles.bottom}>
                  {stepSummery.processing ? (
                    <Box style={{width: '100%'}}>
                      <Skeleton variant="rectangular" style={{width: '100%', height: 50}} />
                    </Box>
                  ) : (
                    <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                      <RestoreStepBottomSummery sessionId={sessionId} type="cintd" />
                      <RestoreStepBottomButtons
                        type="cintd"
                        setCloneTriggered={setCloneTriggered}
                        cloneProgress={cloneProgress}
                        onSkip={type => {
                          setSkipTriggered(type);
                        }}
                        setNext={() => setActive(4)}
                        setPrevious={() => setActive(2)}
                        setDeleteActionid={() => setDeleteActionid(1)}
                      />
                    </Box>
                  )}
                </Box>
              </PageContent>
            </>
          );
        }}
      </LogicStep>
      <LogicStep>
        {({activeStep, setActive}) => {
          const index = 4;
          if (index !== activeStep) return <></>;
          return (
            <>
              <PageConentHeader>
                <Typography variant="h5" component="h2">
                  Finish
                </Typography>
              </PageConentHeader>
              <PageContent className={styles.content}>
                <Box>
                  <SessionRestoreFinishUp sessionId={sessionId} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.uv}
                    onEnd={() => {
                      console.log('send logic clone');
                      // startLocClone('lgc');
                      lastClonePreStep();
                      setCloneProgress(false);
                    }}
                    onError={err => {
                      setCloneProgress(false);
                    }}
                    sessionId={sessionId}
                  />
                  <Box height={15} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.lgc}
                    onEnd={() => {
                      // startLocClone('uvi');
                      lastClonePreStep();
                      setCloneProgress(false);
                    }}
                    onError={err => {
                      setCloneProgress(false);
                    }}
                    sessionId={sessionId}
                  />
                  <Box height={15} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.lv}
                    onEnd={() => {
                      lastClonePreStep();
                      setCloneProgress(false);
                    }}
                    onError={err => {
                      setCloneProgress(false);
                    }}
                    sessionId={sessionId}
                  />
                  <Box height={15} />
                  <RestoreLogTerminal
                    taskId={activeTaskUid}
                    logPath={logPath.uvi}
                    onEnd={() => {
                      lastClonePreStep();
                      setCloneProgress(false);
                    }}
                    onError={err => {
                      setCloneProgress(false);
                      setLastStepCalled(false);
                    }}
                    sessionId={sessionId}
                  />
                </Box>

                <Box className={styles.bottom}>
                  <Box style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <RestoreStepBottomSummery sessionId={sessionId} type="uv" prefix="Userview" />
                    <RestoreStepBottomSummery sessionId={sessionId} type="lgc" prefix="Logic" />
                    <RestoreStepBottomSummery
                      sessionId={sessionId}
                      type="lv"
                      prefix="Smart Lists"
                    />
                    <RestoreStepBottomSummery
                      sessionId={sessionId}
                      type="uvi"
                      prefix="Userview Items"
                    />

                    <Box style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                      <FillButton
                        onClick={() => {
                          lastClonePreStep(true);
                          // setCloneTriggered('uv');
                        }}
                        disabled={cloneProgress}
                      >
                        {cloneProgress ? 'Running...' : 'Finish'}
                      </FillButton>
                    </Box>
                    <Box height={20} />
                    <NodeRed sessionId={sessionId} />
                    <Box height={20} />
                    <Box style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                      <FillButton
                        onClick={() => {
                          setActive(3);
                        }}
                        variant="secondary"
                        disabled={cloneProgress}
                      >
                        Previous Step
                      </FillButton>
                      <Box width={15} />
                      <FillButton
                        loading={false}
                        onClick={() => {
                          setDeleteActionid(1);
                        }}
                        variant="secondary"
                      >
                        Cancel
                      </FillButton>
                    </Box>
                  </Box>
                </Box>
              </PageContent>
            </>
          );
        }}
      </LogicStep>
      <CofirmDialog
        dialogOpen={!!deleteActionId}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteAction();
          } else {
            setDeleteActionid(null);
          }
        }}
        title="Are you sure to cancel ? "
        text="Cancelling will navigate to session list page"
      />
      <CofirmDialog
        dialogOpen={!!cloneTriggered}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            startLocClone(cloneTriggered);
          } else {
            setCloneTriggered(null);
          }
        }}
        title="Are you sure to start cloning ? "
        text={`You are about to send the clone command to currently configured items. 
        When the process is finished the interface will be reset and you will not able to undo past changes.`}
      />
      <CofirmDialog
        dialogOpen={!!skipTriggered}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            skipStep(skipTriggered);
          } else {
            setCloneTriggered(null);
          }
        }}
        title="Are you sure to skip this step ? "
        text="You are about to disable the pending devices of the current step. This process is cannot be undone."
      />
      <CreateContainerDialog open={createContainerDialog} setOpen={setCreateContainerDialog} />
    </PageContentLayout>
  );
};

export default SessionRestoreContent;
