/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Main card component use to list userviews
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define card for list userviews
 * @author Mayantha
 * @since -
 */
import {
  Menu, MenuItem, IconButton,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './IconTextCard.module.css';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { userviewDeleteGraphQL } from '../../../services/user-view/user-view.service';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import { UVConfirmDialog } from '../../UserViewComponents/UIItems/Dialogs/UVConfirmDialog/UVConfirmDialog';
import { appConsoleLog } from '../../../utility/appUtils';

type ComponentProps = {
  title: string;
  iconClass?: string;
  imgBgStyle?: any;
  itemColor?: string;
  uvId: string;
  editable?: boolean;
  onClick?: () => void;
};

/**
 * Card component uses in userview module
 * @callback onClickCard
 * @param {Object} props                component props
 * @param {string} props.title          main title of the card
 * @param {string} props.iconClass      custom icon class to display a given icon
 * @param {string} props.itemColor      card background color
 * @param {string} props.uvId           userview id
 * @param {boolean} props.editable      flag that use to show/hide top menu of the card
 * @param {onClickCard} props.onChange  callback fires when user dropped image
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const IconTextCardComponent: React.FunctionComponent<ComponentProps> = ({
  title,
  uvId,
  imgBgStyle,
  iconClass,
  itemColor = 'white',
  editable = true,
  onClick,
}) => {
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [deleted, setDeleted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (flag: string) => {
    setAnchorEl(null);
    if (flag === 'edit') {
      navigate(
        `/app/user-view/update?projectId=${selectedProject.selected_project.id}&uvId=${uvId}`,
      );
      return;
    }

    if (flag === 'delete') {
      // delete user view
      // deleteUVItem();
      setShowDeleteConfirm(true);
    }
  };

  const deleteUVItem = async () => {
    try {
      await userviewDeleteGraphQL(userProfile.apollo_client, uvId);
      enqueueSnackbar('User View Deleted', { variant: 'success' });
      setDeleted(true);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <div>
      <UVConfirmDialog
        dialogOpen={showDeleteConfirm}
        dialogCloseEvent={(e: any) => {
          setShowDeleteConfirm(false);
          if (e === true) {
            deleteUVItem();
          }
        }}
        title="Confirm to delete userview?"
        text="This will delete userview and it contains including items and nested folders"
      />
      <div
        className={`${styles.root} col-md-20`}
        style={{
          backgroundColor: itemColor,
          opacity: deleted ? 0.4 : 1,
          cursor: deleted ? 'not-allowed' : 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          {editable && (
            <>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                disabled={deleted}
                onClick={handleClick}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem> */}
                <MenuItem onClick={() => handleClose('delete')}>Delete</MenuItem>
              </Menu>
            </>
          )}
        </div>

        <div
          role="button"
          tabIndex={0}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!deleted) onClick();
          }}
          onKeyDown={(e) => {
            if (e.key === '13' && !deleted) {
              onClick();
            }
          }}
        >
          <span
            className={`calc ${iconClass} ${styles.iconCont}`}
            style={{
              fontFamily: 'calculus-icon-pack !important',
              fontSize: '2rem',
            }}
          />
          <div className={styles.data}>
            <div className={styles.title}>
              <p>{title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IconTextCardComponent.defaultProps = {
  iconClass: '',
  imgBgStyle: '',
  itemColor: 'white',
  editable: false,
  onClick: () => { appConsoleLog('default onclick fires'); },
};
