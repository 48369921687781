/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';

import styles from './UVControlButtonsSelector.module.css';
import { IUserViewItem } from '../../../types/types';
import { DeviceCollectionType, ICommDevice } from '../../UVDeviceFinder/types';
import { UVDeviceFinder } from '../../UVDeviceFinder/UVDeviceFinder';
import { UVCreateCommDeviceId } from '../../../utils';
import { UVListSort } from '../UVListSort/UVListSort';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import uvService from '../../../service/uv.service';
import { IControlButtonsSelector } from './types';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { UVDragSelector } from '../UVDragSelector/UVDragSelector';
import { IUVDragSelectorItem } from '../UVDragSelector/UVDragSelectItem';
import { IZone } from '../../../../../config/deviceConfig/types';
import useQueryString from '../../../../../utility/CustomHooks/useQueryString';

// arrow head alarm action button settings
const arrowHeadAlarmActionOptionsProps = [
  {
    label: 'ARM AWAY',
    value: 'arm_away',
  },
  {
    label: 'ARM HOME',
    value: 'arm_home',
  },
  {
    label: 'PANIC',
    value: 'panic',
  },
  {
    label: 'DISARM',
    value: 'disarm',
  },
  {
    label: 'STATUS',
    value: 'status',
  },
];

type ComponentProps = {
  fieldConfig: any;
  payloadKey: string;
  fieldKey: string;
  uvItem: IUserViewItem | null;
  label: string;
  zoneSelector?: boolean;
  alarmOutSelector?: boolean;
  onChange: (payload: any) => void;
  updateCustomFieldErrors: (error: any) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVControlButtonsSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const userProfile = useContext(ApolloAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const projectId = useQueryString('projectId') as string;

  const [selectedControlButton, setControlButton] = useState<ICommDevice>();
  const [selectedBtns, setSelectedBtns] = useState<ICommDevice[]>([]);
  const [rerestSelectedBtn, resetSelectedNow] = useState<number>();

  const [currentZoneList, setZoneList] = useState<IUVDragSelectorItem[]>([]);
  const [lastSelectedDevices, setLastSelectedDevices] = useState<string[]>([]);
  const [selectedZoneList, setSelectedZoneList] = useState<IUVDragSelectorItem[]>([]);

  const loadCurrentBtnsInfo = async () => {
    try {
      if (props.uvItem && props.uvItem.settings && props.uvItem.settings[props.fieldKey]) {
        const btnsConfig: IControlButtonsSelector = props.uvItem.settings[props.fieldKey];
        if (btnsConfig && btnsConfig.items) {
          const airIds = btnsConfig.items
            .filter((item) => item.dc_type === DeviceCollectionType.airDevice)
            .map((item) => item.id);

          const proIds = btnsConfig.items
            .filter((item) => item.dc_type === DeviceCollectionType.proDevice)
            .map((item) => item.id);

          const res = await uvService.searchProjectDevice(
            userProfile.apollo_client,
            projectId,
            [],
            [],
            '',
            airIds,
            proIds,
            [],
            100,
            0,
          );

          const responseAirDeviceMap: any = {};
          const responseProDeviceMap: any = {};
          if (res) {
            if (res.air_devices && res.air_devices.length > 0) {
              res.air_devices.forEach((v) => {
                responseAirDeviceMap[`${v.id}`] = v;
              });
            }
            if (res.pro_devices && res.pro_devices.length > 0) {
              res.pro_devices.forEach((v) => {
                responseProDeviceMap[`${v.id}`] = v;
              });
            }
          }
          const nMappedList = btnsConfig.items
            .map((btn) => {
              if (btn.dc_type === DeviceCollectionType.airDevice) {
                if (responseAirDeviceMap[btn.id]) {
                  return {
                    ...responseAirDeviceMap[btn.id],
                    dc_type: DeviceCollectionType.airDevice,
                  };
                }
              } else if (responseProDeviceMap[btn.id]) {
                return {
                  ...responseProDeviceMap[btn.id],
                  dc_type: DeviceCollectionType.proDevice,
                };
              }

              return null;
            })
            .filter((v) => v != null);
          if (props.uvItem.settings.zones) {
            const savedZones = props.uvItem.settings.zones;

            const slctedZoneItemList: IUVDragSelectorItem[] = [];
            savedZones.forEach((zoneC: any) => {
              const device = responseProDeviceMap[zoneC.device_id];
              const zoneM: any = {
                id: zoneC.area
                  ? `${device.id}_${zoneC.zone_value}_${zoneC.area.value}`
                  : `${device.id}_${zoneC.zone_value}`,
              };
              if (device) {
                if (device.settings) {
                  if (typeof device.settings === 'string') {
                    device.settings = JSON.parse(device.settings);
                  }
                  if (props.alarmOutSelector === true) {
                    if (device.settings.outputs) {
                      device.settings.outputs.forEach((outputItem: any) => {
                        if (outputItem.value === zoneC.zone_value) {
                          zoneM.title = outputItem.label;
                          zoneM.subTitle = device.name;
                          zoneM.payload = { device, zone: outputItem };
                        }
                      });
                      if (device.settings.areas) {
                        device.settings.areas.forEach((area: any) => {
                          arrowHeadAlarmActionOptionsProps.forEach((outputItem: any) => {
                            if (
                              outputItem.value === zoneC.zone_value
                              && zoneC.area.value === area.value
                            ) {
                              zoneM.title = outputItem.label;
                              zoneM.subTitle = `${device.name}(${area.label})`;
                              zoneM.payload = { device, zone: outputItem, area };
                            }
                          });
                        });
                      }
                    }
                  } else if (device.settings.zones) {
                    device.settings.zones.forEach((zoneItem: any) => {
                      if (zoneItem.value === zoneC.zone_value) {
                        zoneM.title = zoneItem.label;
                        zoneM.subTitle = device.name;
                        zoneM.payload = { device, zone: zoneItem };
                      }
                    });
                  }
                  slctedZoneItemList.push(zoneM);
                }
              }
            });
            console.log(slctedZoneItemList);
            setSelectedZoneList(slctedZoneItemList);
          }
          setSelectedBtns(nMappedList);
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    loadCurrentBtnsInfo();
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.uvItem]);

  useEffect(() => {
    if (props.fieldConfig == null) {
      return;
    }
    const data: any = {};
    data[props.fieldKey] = {
      items: selectedBtns.map((d) => ({ dc_type: d.dc_type, id: d.id })),
    };
    if (props.zoneSelector === true) {
      if (selectedZoneList.length === 0) {
        props.updateCustomFieldErrors({
          audio_list_zone_selector: {
            invalid: true,
            message: 'Please select  at least one zone',
          },
        });

        return;
      }
      props.updateCustomFieldErrors({
        audio_list_zone_selector: {},
      });
      data.zones = selectedZoneList.map((v) => ({
        device_id: v.payload.device.id,
        zone_value: v.payload.zone.value,
        zone_label: v.payload.zone.label,
      }));
    }
    if (props.alarmOutSelector === true) {
      props.updateCustomFieldErrors({
        audio_list_zone_selector: {},
      });
      data.zones = selectedZoneList.map((v) => ({
        device_id: v.payload.device.id,
        zone_value: v.payload.zone.value,
        zone_label: v.payload.zone.label,
        zone_type: v.payload.zone.type ? v.payload.zone.type : 'Label',
        zone: v.payload.zone,
        device: v.payload.device,
        area: v.payload.area ? v.payload.area : null,
      }));
    }

    props.onChange({
      action: 'data',
      form: props.payloadKey,
      data,
      errors: {},
    });
  }, [selectedBtns, selectedZoneList]);

  const addSelectedDevice = () => {
    if (selectedControlButton) {
      const selectedDeviceKey = UVCreateCommDeviceId(
        selectedControlButton.dc_type,
        selectedControlButton.id,
      );

      const itsIn = selectedBtns.findIndex((btn) => {
        const deviceKey = UVCreateCommDeviceId(btn.dc_type, btn.id);

        return selectedDeviceKey === deviceKey;
      });
      if (itsIn < 0) {
        const nlist = [...selectedBtns, { ...selectedControlButton }];
        setSelectedBtns(nlist);
      }

      resetSelectedNow(Date.now());
      setControlButton(undefined);
    }
  };
  const ITEMS_LIB: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;

  /**
   * Zone selector related functions
   */

  useEffect(() => {
    const zones: any[] = [];
    const outputs: any[] = [];
    const newSelectedDevices: string[] = [];
    selectedBtns.forEach((d) => {
      newSelectedDevices.push(d?.id || '');
      const device: ICommDevice = JSON.parse(JSON.stringify(d));
      if (device && device.settings) {
        if (typeof device.settings === 'string') {
          device.settings = JSON.parse(device.settings);
        }
        if (device.settings.zones) {
          zones.push(
            ...device.settings.zones.map((v: IZone) => ({
              id: `${device.id}_${v.value}`,
              title: v.label,
              subTitle: device.name,
              payload: { device, zone: v },
            })),
          );
        }
        if (device.settings.outputs) {
          outputs.push(
            ...device.settings.outputs.map((v: any) => ({
              id: `${device.id}_${v.value}`,
              title: v.label,
              subTitle: device.name,
              payload: { device, zone: v },
            })),
          );
          if (device.settings.areas) {
            device.settings.areas.forEach((area: any) => {
              arrowHeadAlarmActionOptionsProps.map((v: any) => outputs.push({
                id: `${device.id}_${v.value}_${area.value}`,
                title: v.label,
                subTitle: `${device.name}(${area.label})`,
                payload: { device, zone: v, area },
              }));
            });

            // console.log(outputs);
          }
        }
      }
    });
    setZoneList(zones);

    if (outputs.length > 0) {
      setZoneList(outputs);
    }

    const isSubset = lastSelectedDevices.every((val) => newSelectedDevices.includes(val));
    if (!isSubset) {
      setSelectedZoneList([]);
    }
    setLastSelectedDevices(newSelectedDevices);
  }, [selectedBtns]);

  return (
    <div className={`${styles.root} root-border`}>
      <UVDeviceFinder
        systemTags={props.fieldConfig.system_tags}
        defaultValue=""
        label={props.label}
        withCustomSearch={false}
        defaultDevice={null}
        withBorder={false}
        resetNow={rerestSelectedBtn}
        disabledList={selectedBtns}
        onDeviceSelected={(device, isManually) => {
          setControlButton(device);
        }}
      >
        <Button onClick={addSelectedDevice} color="primary">
          <Add fontSize="large" />
          Add
        </Button>
      </UVDeviceFinder>
      <div className="row">
        <div className="col-md-12">
          {
            <UVListSort
              onItemClick={(item_, index_) => {
                // onDeviceSelected(selectedBtns[index_], index_);
              }}
              selectedItems={selectedBtns.map((btn) => {
                const deviceKey = UVCreateCommDeviceId(btn.dc_type, btn.id);
                let title = '';

                if (ITEMS_LIB[btn.cat] && ITEMS_LIB[btn.cat].children[btn.sub_cat]) {
                  const conf = ITEMS_LIB[btn.cat].children[btn.sub_cat];
                  title = conf.title;
                }

                return {
                  id: deviceKey,
                  title: btn.name,
                  subTitle: title,
                  payload: btn,
                };
              })}
              itemListChangeHandler={(items) => {
                if (items) {
                  const devices = items.map((i) => i.payload);
                  setSelectedBtns(devices);
                }
              }}
            />
          }
        </div>
        {props.zoneSelector === true ? (
          <div className="col-md-12">
            <UVDragSelector
              inputList={currentZoneList}
              selectedList={selectedZoneList}
              onChange={(selectedList) => {
                setSelectedZoneList(selectedList);
              }}
              alarmOutSelector={false}
            />
          </div>
        ) : (
          <div className="col-md-12" />
        )}
        {props.alarmOutSelector === true ? (
          <div className="col-md-12">
            <UVDragSelector
              inputList={currentZoneList}
              selectedList={selectedZoneList}
              onChange={(selectedList) => {
                // console.log(selectedList);
                setSelectedZoneList(selectedList);
              }}
              alarmOutSelector
            />
          </div>
        ) : (
          <div className="col-md-12" />
        )}
      </div>
    </div>
  );
};

UVControlButtonsSelector.defaultProps = {
  zoneSelector: false,
  alarmOutSelector: false,
};
