import { APP_TEXT } from './AppTexts/DefaultAppText.utils';

/* eslint-disable no-param-reassign */
export interface IValidateField {
  minLen?: number;
  maxLen?: number;
  required?: boolean;
}
export interface IValidateFieldResult {
  error: string;
  isValid: boolean;
}
export const insertToArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];
export const cloneObject = (data: any) => JSON.parse(JSON.stringify(data));
export const SentenceCase = (data: string) => {
  if (!data || data.length < 2) {
    return data;
  }
  return data.charAt(0).toUpperCase() + data.slice(1);
};
export class AppUtils {
  public static getStorageBucketFileUrl(tag: string, filename: string) {
    let pathUrl = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/`;
    if (tag === 'photo') {
      pathUrl += `images/${filename}`;
    }
    return pathUrl;
  }

  public static validateFormField = (
    value: any,
    name: string,
    options: IValidateField,
  ): IValidateFieldResult => {
    let msg = '';
    if (value && value.length > 0) {
      if (options.minLen && options.minLen > 0 && value.length < options.minLen) {
        msg = `${name}must be at least ${options.minLen} characters`;
      }
      if (options.maxLen && options.maxLen > 0 && value.length > options.maxLen) {
        msg = `${name} must be less than  ${options.minLen} characters`;
      }
      msg = '';
    } else {
      msg = `${name} is Required`;
    }

    return {
      error: msg,
      isValid: msg.length === 0,
    };
  };

  static areWeTestingWithJest() {
    return process.env.JEST_WORKER_ID !== undefined;
  }

  static queryParamStringToObj = (paramData: string) => {
    paramData = paramData.replace('?', '');
    const paramKV = paramData.split('&');
    const dataJson: any = {};
    paramKV.forEach((kv) => {
      const kvSplit = kv.split('=');
      // eslint-disable-next-line prefer-destructuring
      dataJson[kvSplit[0]] = kvSplit[1];
    });
    return dataJson;
  };
}

export function convertDaysToLetters(days: number[]) {
  const dayLetters = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < days.length; i++) {
    switch (days[i]) {
      case 0:
        dayLetters.push('Sun');
        break;
      case 1:
        dayLetters.push('Mon');
        break;
      case 2:
        dayLetters.push('Tue');
        break;
      case 3:
        dayLetters.push('Wed');
        break;
      case 4:
        dayLetters.push('Thu');
        break;
      case 5:
        dayLetters.push('Fri');
        break;
      case 6:
        dayLetters.push('Sat');
        break;
      default:
        dayLetters.push('?');
    }
  }
  return dayLetters;
}

export const sortObjectsByKey = (arObj: any[], key: string) => {
  const keys: string[] = [];
  const keyMap: any = {};
  arObj.forEach((item) => {
    keys.push(item[key]);
    keyMap[item[key]] = item;
  });
  keys.sort();
  return keys.map((k) => keyMap[k]);
};
export const vDelay = async (timeout: number) => new Promise((res, rej) => {
  setTimeout(() => {
    res(1);
  }, timeout);
});
export const appConsoleLog = (message?: any, ...optionalParams: any[]) => {
  if (process.env.REACT_APP_CONSOLE_LOGS_ENABLED === 'true') {
    console.log(message, ...optionalParams);
  }
};
export const appConsoleError = (message?: any, ...optionalParams: any[]) => {
  if (process.env.REACT_APP_CONSOLE_ERRORS_ENABLED === 'true') {
    console.error(message, ...optionalParams);
  }
};
export const appApiErrorMessage = (message: any) => {
  message = `${message}`;
  if (message) {
    message = message.replace('Unexpected error value:', '');
    try {
      message = message.replace('Error: ', '');
      message = message.replace('error_code:', '"error_code":');
      message = message.replace('message:', '"message":');
      message = message.replace('trace:', '"trace":');
      message = message.replace('err:', '"err":');
      message = message.replace(': undefined', ': "undefined"');

      const json = JSON.parse(message.trim());
      message = json.message;
      if (message) {
        message = message.replaceAll('>', '');
      } else {
        message = 'Unexpected error';
      }
    // eslint-disable-next-line no-empty
    } catch (err) {}
  } else {
    message = 'Unexpected error';
  }
  return message;
};

export const getInitial = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

export const getPermissionString = (permissionType: number) => {
  let returnTxt = '';

  switch (permissionType) {
    case 1: {
      returnTxt = APP_TEXT.members.MEMBER_TYPE1;
      break;
    }
    case 2: {
      returnTxt = APP_TEXT.members.MEMBER_TYPE2;
      break;
    }
    case 3: {
      returnTxt = APP_TEXT.members.MEMBER_TYPE3;
      break;
    }
    case 4: {
      returnTxt = APP_TEXT.members.MEMBER_TYPE7;
      break;
    }
    case 5: {
      returnTxt = APP_TEXT.members.MEMBER_TYPE4;
      break;
    }
    default: {
      returnTxt = 'N/A';
      break;
    }
  }
  return returnTxt;
};
