import { COMPONENT_UNIT_TYPES } from 'src/plugins/shared/item.config';
import { Component } from '../../shared/uvitem.config';

const switchTags = {
  switch: {
    type: COMPONENT_UNIT_TYPES.Switch,
    tag: 'switch',
    description: 'Switch Channel',
    main_key: null,
    command_key: null,
    can_send_command: true,
    config: {
      mapping: {
        true: 'ON',
        false: 'OFF',
      },
      scale: 1,
    },
    command_v: "v1",
  },
};

const config: Record<string, Component> = {
  switch_map_1: {
    id: 'switch_map_1',
    componentName: 'Shelly Switch 1',
    created_at: '2023.04.10',
    item_type: 'shelly.relay',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: switchTags,
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
};

export default config;
