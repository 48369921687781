import { AxiosResponse } from 'axios';
import CloudIntegration, {
  CiDeviceContainerCreate,
  CiDeviceContainerCreateResponse,
} from '../shared/services/cloud-integration.service';
import ShellyIntegratorsService from './external/integrators.service';

interface GetShellyDeviceManagementUrlResponse {
  result: {
    url: string;
  };
  status: string;
}

interface ShellyDeviceContainerCreate extends CiDeviceContainerCreate {
  ext_username: string;
}

export interface iShellyIntegration extends CloudIntegration {
  externalShellyService: ShellyIntegratorsService;
  getShellyDeviceManagementUrl: (dcId: string) => Promise<GetShellyDeviceManagementUrlResponse>;
  syncShellyContainer: (dcId: string) => Promise<unknown>;
  createDemoDevices: (dcId: string, demoTemplateId: string) => Promise<AxiosResponse<any, any>>;
}

class ShellyIntegration extends CloudIntegration implements iShellyIntegration {
  externalShellyService = new ShellyIntegratorsService();

  public override async createDeviceCollection(
    payload: ShellyDeviceContainerCreate
  ): Promise<CiDeviceContainerCreateResponse> {
    const res = await this.axiosClient.post('/cloud-int/dc/create', payload);
    return res.data as CiDeviceContainerCreateResponse;
  }

  public async getShellyDeviceManagementUrl(dcId: string) {
    const res = await this.axiosClient.post('/cloud-int/shelly/user/v1/create-cb-url', {
      dc_id: dcId,
    });
    return res.data as GetShellyDeviceManagementUrlResponse;
  }

  public async syncShellyContainer(dcId: string) {
    const res = await this.axiosClient.post('/cloud-int/shelly/user/v1/sync-devices', {
      dc_id: dcId,
      sync_mode: 'sync_all_ops',
    });
    return res.data;
  }

  public async createDemoDevices(dcId: string, demoTemplateId: string) {
    return this.axiosClient.post('/cloud-int/device/demo/v1/shelly/create-demo-devices', {
      dc_id: dcId,
      template_id: demoTemplateId,
    });
  }
}

export default ShellyIntegration;
