/* eslint-disable camelcase */
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import dedent from 'dedent';
import { useDispatch, useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import firebase from 'firebase';
import { useRef, useState } from 'react';
import Editor from '@monaco-editor/react';
import { isArray } from 'lodash';
import { useFirebaseConnect } from 'react-redux-firebase';
import {
  Action,
  AirDevice,
  DeviceCollectionSearchOutItem,
  OPERATORS,
  ProDevice,
} from '@smartlife-redux-state/common';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbar } from 'notistack';
import { CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import { RootState } from '../../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../../LogicStepper';
import { DeviceSelector } from '../../shared/DeviceSelector';
import { ExpressionSelector, useFirebasePathBuilder } from '../../shared/ExpressionSelector';
import { update_action } from '../../../../../../../../store/redux/features/logic-engine/slice';
import { ExpressionVariableSelector } from '../../shared/expression-variable/ExpressionVariableSelector';

// eslint-disable-next-line import/prefer-default-export
export const AddVariable: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
  onAddNewVariable?: (payload: {
    updatedVariableList: any[];
    type: string;
    varName: string;
    firebasePath: string;
    staticVarType: string;
    staticVarValue: any;
    device: AirDevice | ProDevice | CiDevice
  }) => void;
  bypassValidation?: boolean;
}> = ({
  action, parentAction, isChild, bypassValidation, onAddNewVariable,
}) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function handleClose() {
    setOpen((old) => !old);
  }

  const dc = useSelector((state: RootState) => {
    if (isChild) {
      const found = parentAction.child_actions.find((child) => child.id === action.id);
      return found.dc;
    }
    const found = state.rule_engine.actions.find((act) => act.id === action.id);
    return found ? found.dc : null;
  });

  const [selectedDc, setSelectedDc] = useState<DeviceCollectionSearchOutItem>(null);
  const [selectedDevice, setSelectedDevice] = useState<AirDevice | ProDevice | CiDevice>(null);
  const [selectedVariable, setSelectedVariable] = useState(null);

  function onDcSelect(dcArg: DeviceCollectionSearchOutItem) {
    setSelectedDc(dcArg);
  }

  function onDeviceSelect(device: AirDevice | ProDevice) {
    if (!device) return;
    // console.log("action device", device, JSON.parse(device.settings));
    setSelectedDevice(device);
  }

  function onExpressionChange(op: OPERATORS, value: any, variable: {field: string; type: string}) {
    console.log('on expresion selected', op, value, variable);
    setSelectedVariable(variable.field);
  }

  const [type, setType] = useState('devices');
  const [validationErr, setShowValidationErr] = useState('');

  function isValidGoVariableName(input) {
    // List of Go keywords and predeclared identifiers
    const goReservedWords = [
      'break',
      'case',
      'chan',
      'const',
      'continue',
      'default',
      'defer',
      'else',
      'fallthrough',
      'for',
      'func',
      'go',
      'goto',
      'if',
      'import',
      'interface',
      'map',
      'package',
      'range',
      'return',
      'select',
      'struct',
      'switch',
      'type',
      'var',
    ];

    // Regular expression to check Go variable name validity
    const goVariableRegex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

    // Check if the input is a valid Go variable name and not a reserved keyword
    return goVariableRegex.test(input) && !goReservedWords.includes(input);
  }

  async function onBeforeAdd() {
    try {
      if (!var_name || var_name.length === 0) {
        setShowValidationErr('Please add a name for the variable.');
        return;
      }

      if (!isValidGoVariableName(var_name)) {
        setShowValidationErr(
          'The variable name is either reserved keyword or inavalid format. Please use something else.',
        );
        return;
      }

      if (!bypassValidation) {
        const rlDb = firebase.database();
        const ref = rlDb.ref(firebase_path);
        const snapshot = await ref.once('value');
        const value = snapshot.val();

        const parsed = parseFloat(value);
        if (value == null || value === undefined || Number.isNaN(parsed)) {
          throw new Error('not a number');
        }
      }
      onAdd();
    } catch (err) {
      setShowValidationErr(
        `Current value of the variable '${var_name}' is not a number. Please ensure the selected variable is a number in order to add it to the logic action.`,
      );
    }
  }

  function onAdd() {
    setShowValidationErr(undefined);
    let variables = [];
    try {
      const parsed = JSON.parse(action.variable);
      if (isArray(parsed)) {
        variables = parsed;
      } else {
        variables = [];
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}

    if (onAddNewVariable) {
      // derived device action
      onAddNewVariable({
        updatedVariableList: variables,
        type,
        varName: var_name,
        firebasePath: firebase_path,
        staticVarType,
        staticVarValue,
        device: selectedDevice,
      });
    } else {
      // compound variable action
      dispatch(
        update_action({
          action: {
            ...action,
            variable: JSON.stringify([
              ...variables,
              type === 'static'
                ? {
                  name: var_name,
                  static_value: staticVarType ? Number(staticVarValue) : staticVarValue,
                }
                : {
                  firebase_path,
                  name: var_name,
                },
            ]),
          },
          isChild,
          parentAction,
        }),
      );
    }
    setOpen(false);
  }

  const [firebase_path, setFirebasePath] = useFirebasePathBuilder(selectedDc, selectedDevice, {
    field: selectedVariable,
    type: '',
  });

  const [var_name, setVarName] = useState('');

  const [staticVarType, setStaticVarType] = useState('number');
  const [staticVarValue, setStaticVarValue] = useState<any>();
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ADD NEW VARIABLE</DialogTitle>
        <DialogContent>
          {validationErr && (
            <Box style={{ marginBottom: 10 }}>
              <Alert severity="warning">{validationErr}</Alert>
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl size="small">
                <Select
                  value={type}
                  onChange={(e: any) => setType(e.target.value)}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  <MenuItem value="devices">Device State</MenuItem>
                  <MenuItem value="variables">Location Variable</MenuItem>
                  {/* <MenuItem value="static">Static</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              {type === 'static' ? (
                <></>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <DeviceSelector
                      selectedDC={selectedDc}
                      selectedDevice={selectedDevice || null}
                      onDcSelect={onDcSelect}
                      onDeviceSelect={onDeviceSelect}
                      logic_step={LogicStepsTypes.ACTION}
                      behaveAs={type}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ExpressionSelector
                      selectedVariable={{
                        field: selectedVariable,
                        type: action.possible_variable_type,
                      }}
                      selecteValue={action.variable_value}
                      selectedOperator={OPERATORS.EQUAL}
                      onChange={onExpressionChange}
                      device={selectedDevice}
                      hideTypeSelector
                      dc={selectedDc}
                      behaveAs="justVariableSelector"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item md={12}>
              {type === 'static' ? (
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      placeholder="Set a name for the variable"
                      value={var_name}
                      onChange={(e) => setVarName(e.target.value)}
                      variant="outlined"
                      size="small"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      placeholder="value"
                      variant="outlined"
                      size="small"
                      value={staticVarValue}
                      onChange={(e) => setStaticVarValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Select
                      value={staticVarType}
                      onChange={(e: any) => setStaticVarType(e.target.value)}
                      variant="outlined"
                      size="small"
                      style={{
                        width: '100%',
                      }}
                    >
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="string">String</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              ) : (
                <TextField
                  placeholder="Set a name for the variable"
                  value={var_name}
                  onChange={(e) => setVarName(e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{ width: '100%' }}
                />
              )}
            </Grid>
            <Grid item md={12}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon />
                <FormHelperText>
                  A variable name should accurately identify your variable.
                </FormHelperText>
              </Box>
              <FormHelperText>
                <ul>
                  <li>Variable names cannot contain spaces.</li>
                  <li>
                    Variable names must begin with a letter, an underscore (_) or a dollar sign ($).
                  </li>
                  <li>
                    Variable names can only contain letters, numbers, underscores, or dollar signs.
                  </li>
                  <li>Variable names are case-sensitive.</li>
                  <li>
                    Certain words may not be used as variable names, because they have other
                    meanings within JavaScript. Check out this
                    <a
                      href="https://www.dummies.com/web-design-development/javascript/javascript-for-kids-for-dummies-cheat-sheet/"
                      target="_blank"
                      style={{ color: 'black' }}
                      rel="noreferrer"
                    >
                      {' '}
                      complete list
                    </a>
                    {' '}
                    of the reserved words.
                  </li>
                </ul>
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onBeforeAdd}>
            ADD
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Add a variable">
        <IconButton onClick={() => setOpen(true)} size="small">
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
