import {
  Box,
  Button,
  capitalize,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { isArray } from 'lodash';
import { Add } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import { ITuyaDevice, ITuyaFuntion } from '../types';
import { FieldCard } from '../../DynamicDeviceCreate/DeviceFeatures/FieldCard/FieldCard';
import ComponentTemplateEditor from './compTemplate/ComponentTemplate';
import DeviceProfileSelector from './DeviceProfileSelector';
import DeviceProfileCreator from './DeviceProfileCreator';

const AirDeviceChannels: React.FC<{
  airDeviceCustomFieldVisibility: boolean;
  isAirChannelsCustomForm: boolean;
  deviceFunctions: ITuyaFuntion[];
  currentFunctionCode: string;
  selectedFieldsMap: {
    [key: string]: ITuyaFuntion;
  };
  tuyaDevice: ITuyaDevice;
  customDefaults: {[key: string]: any};
  setSelectedFieldsMap: React.Dispatch<
    React.SetStateAction<{
      [key: string]: ITuyaFuntion;
    }>
  >;
  setCurrentFunctionCode: React.Dispatch<React.SetStateAction<string>>;
  addSelectedField: () => void;
  removeField: (code: string) => void;
  handleCustomDefaulsChanges: any;
  profileProps: {
    deviceType: string;
    deviceSubType: string;
    device: any;
    templateId: any;
    deviceFunctionsMap: {[key: string]: ITuyaFuntion};
  };
  onChangeProfile?: (comProfile: any) => void;
}> = ({
  airDeviceCustomFieldVisibility,
  isAirChannelsCustomForm,
  deviceFunctions,
  currentFunctionCode,
  selectedFieldsMap,
  tuyaDevice,
  customDefaults,
  setCurrentFunctionCode,
  addSelectedField,
  removeField,
  handleCustomDefaulsChanges,
  profileProps,
  onChangeProfile,
}) => {
  const {
    deviceSubType, deviceType, device, templateId,
  } = profileProps;

  const [openDialog, setOpenDialog] = useState(false);

  if (!airDeviceCustomFieldVisibility) return <></>;

  if (!isArray(deviceFunctions) || deviceFunctions.length === 0) return <></>;

  if (isAirChannelsCustomForm) {
    return (
      <div className="row pl-0 ml-0">
        <DeviceProfileCreator
          template={{ selectedFieldsMap, deviceType, deviceSubType }}
          comType="d"
          parentType="air"
          parentProfileId={null}
          parentCategory={
            // eslint-disable-next-line no-nested-ternary
            device && device.cat ? device.cat : tuyaDevice ? tuyaDevice.category : ''
          }
          parentSubCategory={
            // eslint-disable-next-line no-nested-ternary
            device && device.cat ? device.sub_cat : tuyaDevice ? tuyaDevice.product_name : ''
          }
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
        <div className="col-md-4 pl-8 ml-0">
          <div className="text-fields-flex">
            <FormControl
              size="small"
              variant="outlined"
              className="dynamic-ui-form-control-full-width"
            >
              <InputLabel id="field_selector-label1">Select field to add</InputLabel>
              <Select
                labelId="field_selector-label1"
                value={currentFunctionCode || ''}
                name="field_selector-air"
                onChange={(e: any) => {
                  setCurrentFunctionCode(e.target.value);
                }}
                label="Select field to add"
              >
                {deviceFunctions.map((item) => (
                  <MenuItem
                    key={item.code}
                    value={item.code}
                    disabled={selectedFieldsMap[item.code] !== undefined}
                  >
                    {capitalize(item.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              startIcon={<Add />}
              variant="outlined"
              className="ml-8"
              onClick={addSelectedField}
              color="primary"
            >
              Add
            </Button>
            {Object.values(selectedFieldsMap).length > 0 && (
              <Box style={{ marginLeft: 10 }}>
                <Tooltip title="Save as a profile">
                  <IconButton
                    onClick={() => setOpenDialog(true)}
                    disabled={!profileProps.deviceType || profileProps.deviceType.length === 0}
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </div>
        </div>
        <div className="row pro-device-dynamic-items" style={{ padding: '8px 30px !important' }}>
          {Object.values(selectedFieldsMap).map((item) => (
            <FieldCard
              key={item.code}
              name={item.name}
              code={item.code}
              tuyaDevice={tuyaDevice}
              onDelete={() => {
                removeField(item.code);
              }}
              customDefaultValue={customDefaults ? customDefaults[item.code] : {}}
              onCustomDefaultChange={(data) => {
                handleCustomDefaulsChanges(data);
              }}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <DeviceProfileSelector
        template={{ selectedFieldsMap, deviceType, deviceSubType }}
        comType="d"
        parentType="air"
        parentProfileId={null}
        parentCategory={
          // eslint-disable-next-line no-nested-ternary
          device && device.cat ? device.cat : tuyaDevice ? tuyaDevice.category : ''
        }
        parentSubCategory={
          // eslint-disable-next-line no-nested-ternary
          device && device.cat ? device.sub_cat : tuyaDevice ? tuyaDevice.product_name : ''
        }
        currentProfileId={templateId}
        deviceType={profileProps.deviceType}
        onChangeProfile={onChangeProfile}
      />
      {/* <ComponentTemplateEditor
        template={{ selectedFieldsMap, deviceType, deviceSubType }}
        comType="d"
        parentType="air"
        parentProfileId={null}
        parentCategory={
          // eslint-disable-next-line no-nested-ternary
          device && device.cat ? device.cat : tuyaDevice ? tuyaDevice.category : ''
        }
        parentSubCategory={
          // eslint-disable-next-line no-nested-ternary
          device && device.cat ? device.sub_cat : tuyaDevice ? tuyaDevice.product_name : ''
        }
        currentProfileId={templateId}
        onChangeProfile={onChangeProfile}
      /> */}
    </>
  );
};

export default AirDeviceChannels;
