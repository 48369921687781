import qs from 'query-string';

const getQueryStringValue = (key: string, queryString = window.location.search) => {
  try {
    const values = qs.parse(queryString);
    return Object.keys(values).length > 0 ? values[key] : null;
  } catch (error) {
    return null;
  }
};

function useQueryString(key: string) {
  const res = getQueryStringValue(key);
  return res;
}

export default useQueryString;
