/* eslint-disable camelcase */
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import {
  AirDevice,
  DeviceCollectionSearchOutItem,
  ProDevice,
  Trigger,
} from '@smartlife-redux-state/common';
import { RootState, useSelector } from '../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../LogicStepper';
import { TriggerSelector } from '../TriggerSelector';
import { DeviceSelector } from './DeviceSelector';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});
// eslint-disable-next-line import/prefer-default-export
export const LocationSelector: React.FC<{
  selectedVariable: AirDevice | ProDevice;
  onLocationContSelect: (dc: DeviceCollectionSearchOutItem) => void;
  onLocVaribaleSelect: (device: ProDevice | AirDevice) => void;
}> = ({ selectedVariable, onLocVaribaleSelect, onLocationContSelect }) => {
  const classes = useStyles();

  const locaction_container = useSelector(
    (state: RootState) => state.rule_engine.location_variable_container,
  );

  function onLocationVariableContainerSelect(dc: DeviceCollectionSearchOutItem) {
    onLocationContSelect(dc);
  }

  function onLocationVaribaleSelect(device: ProDevice | AirDevice) {
    onLocVaribaleSelect(device);
  }

  return (
    <DeviceSelector
      selectedDC={locaction_container}
      selectedDevice={selectedVariable}
      onDcSelect={onLocationVariableContainerSelect}
      onDeviceSelect={onLocationVaribaleSelect}
      behaveAs="variables"
      logic_step={LogicStepsTypes.WHEN}
    />
  );
};
