const findAndReplaceQueryString = (
  url: string,
  search: string,
  queryNameToBeReplaceOrAdd: string,
  queryValueToBeReplaceOrAdd,
) => {
  if (search.indexOf(queryNameToBeReplaceOrAdd) > -1) {
    const splited = search.split('&');

    const modified = splited.map((part) => {
      if (part.indexOf(queryNameToBeReplaceOrAdd) > -1) {
        return `${queryNameToBeReplaceOrAdd}=${queryValueToBeReplaceOrAdd}`;
      }
      return part;
    });

    const updatedSearch = modified.join('&');
    return url + updatedSearch;
  }

  return `${url + search}&${queryNameToBeReplaceOrAdd}=${queryValueToBeReplaceOrAdd}`;
};

export default findAndReplaceQueryString;
