import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import firebase from 'firebase';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'src/store/redux/store';
import axios from 'axios';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { v2InfoUpdate } from 'src/store/redux/features/backup-location-config';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#EBF1F2',
    borderRadius: '5px',
    margin: '2% 0',
    padding: '2% 5%',
  },
  rootInFullLog: {
    display: 'flex',
    alignItems: 'flex-start',
  },
});

export const RestoreLogTerminal: React.FC<{
  logPath: string;
  taskId: string;
  onError: (error: string) => void;
  onEnd: () => void;
  sessionId: string;
}> = ({
  logPath, onError, onEnd, sessionId, taskId,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const [isEnd, setIsEnd] = useState(true);
  const [viewFullLog, setViewFullLog] = useState(false);
  const [lastLine, setLastLine] = useState<{
    color: string;
    text: string;
    time: string;
  }>();
  const [fullLog, setFullLog] = useState<
    {
      color: string;
      text: string;
      time: string;
    }[]
  >([]);

  const scrollToBottomRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (lastLine) {
      setFullLog([...fullLog, lastLine]);
      // if (scrollToBottomRef?.current && scrollToBottomRef?.current.scrollIntoView) {
      //   scrollToBottomRef.current.scrollTop = scrollToBottomRef.current.scrollHeight;
      //   // scrollToBottomRef?.current.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  }, [lastLine]);

  useEffect(() => {
    // console.log('log path xx:', logPath, taskId);
    if (!logPath) return;

    if (!taskId) return;

    // console.log('log path:', logPath);
    const ref = firebase.database().ref(logPath);

    // ref.once('value', (snapshot) => {
    //   const val = snapshot.val();
    //   if (val) Object.keys(val).map((key) => console.log(val[key].message));
    // });

    let hasError = false;

    const listner = ref.on('child_added', (snapshot) => {
      const val = snapshot.val();
      // eslint-disable-next-line no-debugger
      // debugger;
      // console.log('val', val);
      if (val.message === taskId && val.type === 'task-end') {
        // eslint-disable-next-line no-debugger
        // debugger;
        if (!hasError) {
          const msg = {
            color: 'green',
            text: val.message,
            time: new Date().toString(),
          };
          setLastLine(msg);
          ref.off('child_added', listner);
          setTimeout(() => {
            onEnd();
          }, 1000);
        } else {
          const msg = {
            color: 'orange',
            text: `${val.message} (End with an error)`,
            time: new Date().toString(),
          };
          setLastLine(msg);
          if (onError) onEnd(); // onError(lastLine ? lastLine.text : 'unknown');
        }
        setIsEnd(true);
      } else if (val.type === 'error') {
        hasError = true;
        const msg = {
          color: 'red',
          text: val.message,
          time: new Date().toString(),
        };
        setLastLine(msg);
      } else {
        const msg = {
          color: 'blue',
          text: val.message,
          time: new Date().toString(),
        };
        setLastLine(msg);
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      // console.log('@terminal cleanup called', logPath, taskId);
      ref.off('child_added', listner);
    };
  }, [logPath, taskId]);

  if (viewFullLog) {
    return (
      <Box
        style={{
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column-reverse',
          height: '300px',
          marginTop: '2% 0',
        }}
      >
        <Box
          style={{
            // display: 'flex',
            width: '100%',
            // flexDirection: 'column',
            // margin: '2% 0',
            padding: '2% 5%',
            backgroundColor: '#EBF1F2',
            // overflow: 'scroll',
          }}
        >
          {fullLog.map((log, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box className={styles.rootInFullLog} key={index}>
              <Typography
                style={{
                  fontWeight: '400',
                  color: 'gray',
                  fontSize: '11px',
                  verticalAlign: 'middle',
                }}
              >
                {log.time}
              </Typography>
              <Typography
                style={{
                  color: log.color,
                  fontSize: '11px',
                }}
              >
                {log.text}
              </Typography>
            </Box>
          ))}
          <Button onClick={() => setViewFullLog(!viewFullLog)} ref={scrollToBottomRef}>
            {viewFullLog ? 'View Less' : 'View Full Log'}
          </Button>
        </Box>
      </Box>
    );
  }

  if (!lastLine) return <></>;

  return (
    <>
      <Box className={styles.root}>
        <Typography
          style={{
            fontWeight: '400',
            color: 'gray',
            fontSize: '11px',
            verticalAlign: 'middle',
          }}
        >
          {lastLine.time}
        </Typography>
        <Typography
          style={{
            color: lastLine.color,
            fontSize: '11px',
          }}
        >
          {lastLine.text}
        </Typography>
      </Box>
      <Box>
        {isEnd && (
          <Button onClick={() => setViewFullLog(!viewFullLog)}>
            {viewFullLog ? 'View Less' : 'View Full Log'}
          </Button>
        )}
      </Box>
    </>
  );
};

export default RestoreLogTerminal;
