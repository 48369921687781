/**
 * @deprecated
 */
/* eslint-disable max-len */
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';
import { AddBoxSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import useQueryString from 'src/utility/CustomHooks/useQueryString';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    marginBottom: '4%',
  },
  contentRootInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5%',
  },
  note: {
    backgroundColor: 'white',
    border: '10px',
    width: '80%',
    margin: '0 auto',
    marginTop: '5%',
  },
  root: {
    // height: "100%",
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  formRoot: {
    width: '100%',
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
// restore-sessions/restoring
const SessionRestorePre = () => {
  const navigate = useNavigate();
  const projectId = useQueryString('projectId');
  const sessionId = useQueryString('sessionId');

  const styles = useStyle();

  const navigateCustom = () => {
    navigate(`/app/backup-restore/restore-sessions/restoring?projectId=${projectId}&sessionId=${sessionId}`);
  };

  const navigateOneToOne = () => {
    navigate(`/app/backup-restore/restore-sessions/one-to-one?projectId=${projectId}&sessionId=${sessionId}`);
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>
            Select Restore Method
          </Typography>
        </Box>
      </PageHeader>
      <Box className={styles.contentRoot}>
        <Box className={styles.contentRootInner}>
          <Typography style={{
            fontSize: '1.5rem',
            fontWeight: '700',
          }}
          >
            Select One of the restore method to continue
          </Typography>
          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2%',
          }}
          >
            <Button color="primary" variant="contained" onClick={navigateOneToOne}>1:1 Device Mapping</Button>
            <Button style={{ marginTop: '5%' }} onClick={navigateCustom}>Custom Device Mapping</Button>
          </Box>
        </Box>
        <Box className={styles.note}>
          <Box style={{
            display: 'flex',
            alignItems: 'center',
            padding: '2%',
            borderBottom: '1px solid gray',
            fontFamily: 'monospace',
          }}
          >
            <InfoIcon />
            <Box width={10} />
            <Typography>INSTRUCTIONS</Typography>
          </Box>
          <Box style={{
            paddingBottom: '2%',
          }}
          >
            <ul style={{ fontFamily: 'monospace' }}>
              <li>Use 1:1 device mapping if you need to quickly map devices from template to the location.</li>
              <li>Use custom method to restore in a more flexible way</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default SessionRestorePre;
