import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Component, Tag } from 'src/plugins/shared/uvitem.config';
import { useSnackbar } from 'notistack';
import { isArray } from 'lodash';
import { CiDevice } from '../services/cloud-integration.service';

export const useCloudDeviceMapFormData = (
  itemMaps: {
    [componentName: string]: Component;
  },
  settings: Record<
    string,
    {
      k: string;
      t: string;
      command?: string;
    }
  >,
  selectedDevice: CiDevice & {dc_type: string},
  uvItem?: any,
  overrideSettingType?: string,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMapId, setSelectedMapId] = useState<string | null>(
    uvItem?.settings?.component_name || null,
  );
  const selectedMap: Component | null = useMemo(() => {
    if (!selectedMapId) return null;
    return itemMaps[selectedMapId];
  }, [selectedMapId, selectedDevice, itemMaps]);

  const [filledTagMap, setFilledTagMap] = useState(
    uvItem?.settings?.client_side_settings?.filledTagMap || {},
  ); // uvItem?.settings?.ui_data ||

  const [filledExtraTagMap, setFilledExtraTagMap] = useState<
    Record<string, {deviceId: string; channelKey: string}>
  >(uvItem?.settings?.client_side_settings?.filledExtraTagMap || {});

  const [notifications, setNotifications] = useState(
    uvItem?.settings?.notify_fields
      ? () => Object.keys(uvItem?.settings?.notify_fields).map((key) => ({
        value: uvItem?.settings?.notify_fields[key],
        channel: key,
        id: uuid(),
      }))
      : [
        {
          value: null,
          channel: null,
          id: uuid(),
        },
      ],
  );

  const resetOnMapChange = () => {
    setFilledTagMap({});
    setFilledExtraTagMap({});
  };

  const changeSelectedMapId = (id: string) => {
    setSelectedMapId(id);
    resetOnMapChange();
  };

  const prevSelectedMap = useRef<Component>();
  const initial = useRef(false);

  const finalSettings = useMemo(() => {
    if (!selectedMap) return;
    const existingSetting = uvItem && uvItem.settings && !initial.current
      ? uvItem.settings
      : {
        inline_data_map: 'inline_1',
        inner_data_map: 'inner_1',
        ui_data: {},
        extra_ui_data: {},
        notify_fields: {},
      };

    initial.current = true;
    if (uvItem?.settings?.client_side_settings?.used_as != overrideSettingType) {
      existingSetting.ui_data = {};
    }

    if (!prevSelectedMap.current) {
      prevSelectedMap.current = selectedMap;
    }

    const allSettings = {
      ...existingSetting,
      component_name: selectedMap.id,
      item_type: selectedMap.item_type,
      client_side_settings: {
        filledTagMap,
        filledExtraTagMap,
      },
    };

    // Reset mappins if selected map changed
    if (selectedMap.id !== prevSelectedMap.current.id) {
      allSettings.ui_data = {};
      allSettings.client_side_settings.filledTagMap = {};
      allSettings.client_side_settings.filledExtraTagMap = {};
      prevSelectedMap.current = selectedMap;
    }

    if (isArray(notifications)) {
      allSettings.notify_fields = {};
      notifications.map((notifi) => {
        if (notifi.channel && notifi.value) {
          allSettings.notify_fields[notifi.channel] = notifi.value;
        }
      });
    }

    Object.keys(filledTagMap).map((tag) => {
      const tagTemplatedata = selectedMap.ui_tags[tag];
      if (!tagTemplatedata) {
        enqueueSnackbar(`Unable to find tag from template. Tag: ${tag}`);
        return;
      }

      const deviceFns = settings[filledTagMap[tag]];

      const payload = { ...tagTemplatedata };
      payload.main_key = deviceFns.k;

      if (tagTemplatedata.can_send_command) {
        if (deviceFns.command) {
          payload.command_key = deviceFns.command;
        } else {
          enqueueSnackbar(
            'Template configuration mismatch with actual device. Hint: Check `can_send_command` and `command`',
          );
        }
      } else if (deviceFns.command && !tagTemplatedata.can_send_command) {
        enqueueSnackbar(
          'Template configuration mismatch with actual device. Hint: Check `can_send_command` and `command`',
        );
        return;
      }

      allSettings.ui_data[tag] = payload;
    });

    Object.keys(filledExtraTagMap).map((tag) => {
      const tagTemplatedata = selectedMap.extra_ui_tags[tag];
      if (!tagTemplatedata) {
        enqueueSnackbar(`Unable to find extra tag from template. Tag: ${tag}`);
        return;
      }

      const filledData = filledExtraTagMap[tag];

      const payload = { ...tagTemplatedata };
      payload.main_key = filledData.channelKey;

      if (tagTemplatedata.can_send_command) {
        payload.command_key = filledData.channelKey;
      }

      if (!allSettings.extra_ui_data) allSettings.extra_ui_data = {};

      allSettings.extra_ui_data[tag] = payload;
    });

    return allSettings;
  }, [filledTagMap, filledExtraTagMap, notifications, selectedDevice, settings, selectedMap, uvItem]);

  return {
    selectedMap,
    selectedMapId,
    finalSettings,
    filledTagMap,
    notifications,
    filledExtraTagMap,
    setFilledExtraTagMap,
    setNotifications,
    setFilledTagMap,
    setSelectedMapId: changeSelectedMapId,
  };
};
