import { gql } from '@apollo/client';

export const PRO_DEVICE_GET_QUERY = gql`
  query ProDeviceGet($item_id: String) {
    proDeviceGet(item_id: $item_id) {
      proDevice {
        id
        created_by
        project_id
        prodc_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        item_type
        device_status
        settings
        created_at
        updated_at
      }
    }
  }
`;

export const PRO_DEVICE_SEARCH_QUERY = gql`
  query ProDeviceSearch(
    $project_id: String
    $prodc_id: String
    $shadow_type: String
    $cat: String
    $sub_cat: String
    $device_type: String
    $device_status: Int
    $tags: [String]
    $system_tags: [String]
    $keyword: String
    $limit: Int
    $skip: Int
  ) {
    proDeviceSearch(
      project_id: $project_id
      prodc_id: $prodc_id
      shadow_type: $shadow_type
      cat: $cat
      sub_cat: $sub_cat
      device_type: $device_type
      device_status: $device_status
      tags: $tags
      system_tags: $system_tags
      keyword: $keyword
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        created_by
        project_id
        prodc_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        item_type
        device_status
        settings
        created_at
        updated_at
      }
    }
  }
`;

export const PRO_DEVICE_DELETE = gql`
  mutation ProDeviceDelete($item_id: String) {
    proDeviceDelete(item_id: $item_id) {
      id
      delete_status
    }
  }
`;
