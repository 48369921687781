import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { ClonePostPayload, CloneRes, CloneResult } from './types';

// eslint-disable-next-line import/prefer-default-export
export const cloneStep = createAsyncThunk<
  CloneRes,
  ClonePostPayload,
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/form-clone-loc-var', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/clone/task',
    filters,
  );
  return res.data as CloneRes;
});
