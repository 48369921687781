/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Base Layout/container for the content of the each modules.
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the layout for each modules.
 * @author Maduka Dilshan
 * @since -
 */
import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    background: '#EBF1F2',
    width: '100%',
    height: '100%',
    borderRadius: '13px',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
});

/**
 * Creating a Layout according to the defualt system theme.
 * This will not have any business logic. Use child components to define business logics
 * @param children React Children components
 * @returns
 */
const Layout: React.FC<{ children: any, style?: any }> = ({ children, style }) => {
  const styles = useStyles();
  return <Box className={styles.root} style={style}>{children}</Box>;
};

Layout.defaultProps = {
  style: {},
};

export default Layout;
