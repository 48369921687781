// eslint-disable-next-line import/prefer-default-export
export const UV_CALC_ICONS: any = {
  audio_icons: {
    icons: [
      {
        class_name: 'calc-hifi1',
        label: 'HiFi',
        query: 'audio-buffle-studio-hifi',
      },
      {
        class_name: 'calc-hifi2',
        label: 'HiFi',
        query: 'audio-buffle-studio-hifi',
      },
      {
        class_name: 'calc-hifi3',
        label: 'HiFi',
        query: 'audio-buffle-studio-hifi',
      },
      {
        class_name: 'calc-gate1',
        label: 'Gate',
        query: 'audio-gate-door',
      },
      {
        class_name: 'calc-bluray2',
        label: 'Blueray',
        query: 'audio-blueray-blueray2-disk-cd-dvd',
      },
      {
        class_name: 'calc-fan1',
        label: 'Fan',
        query: 'audio-fan-fan1-standfan-stand',
      },
      {
        class_name: 'calc-lamp1',
        label: 'Lamp',
        query: 'audio-lamp-lamp1-stand-light-tablelight',
      },
      {
        class_name: 'calc-light1',
        label: 'Light',
        query: 'audio-light-light1-bulb',
      },
      {
        class_name: 'calc-kitchen3',
        label: 'Kitchen',
        query: 'audio-kitchen-kitchen3',
      },
      {
        class_name: 'calc-temp2',
        label: 'Temp',
        query: 'audio-temp-temperature-temp2-thermometer',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'audio-itemfolder-folder-item',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'audio-itemfolder-folder-item',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'audio-itemfolder-folder-item',
      },
    ],
  },
  camera_icons: {
    icons: [
      {
        class_name: 'calc-camera2',
        label: 'Camera',
        query: 'camera-camera2-cctv-spycam-cam-wallcam',
      },
      {
        class_name: 'calc-camera3',
        label: 'Camera',
        query: 'camera-camera3-cctv-wallcam',
      },
      {
        class_name: 'calc-motionsensor1',
        label: 'MotionSensor',
        query: 'camera-motion-sensor-motionsensor-motionsensor1',
      },
      {
        class_name: 'calc-motionsensor2',
        label: 'MotionSensor',
        query: 'camera-motion-sensor-motionsensor-motionsensor2',
      },
      {
        class_name: 'calc-motionsensor3',
        label: 'MotionSensor',
        query: 'camera-motion-sensor-motionsensor-motionsensor3',
      },
      {
        class_name: 'calc-motionsensor4',
        label: 'MotionSensor',
        query: 'camera-motion-sensor-motionsensor-motionsensor4',
      },
      {
        class_name: 'calc-motionsensor5',
        label: 'MotionSensor',
        query: 'camera-motion-sensor-motionsensor-motionsensor5',
      },
      {
        class_name: 'calc-light1',
        label: 'Light',
        query: 'camera-light-light1-bulb',
      },
      {
        class_name: 'calc-kitchen3',
        label: 'Kitchen',
        query: 'camera-kitchen-kitchen3',
      },
      {
        class_name: 'calc-temp2',
        label: 'Temp',
        query: 'camera-temp-temperature-temp2-thermometer',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'camera-itemfolder-folder-item',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'camera-itemfolder-folder-item',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'camera-itemfolder-folder-item',
      },
    ],
  },
  default_icons: {
    icons: [
      {
        class_name: 'calc_output',
        label: 'Output',
        query: 'default-output-link',
      },
      {
        class_name: 'calc_search-bold',
        label: 'Search',
        query: 'default-search-magnify',
      },
      {
        class_name: 'calc_speaker',
        label: 'Speaker',
        query: 'default-speaker-sound',
      },
      {
        class_name: 'calc-ac1-active',
        label: 'AC',
        query: 'default-ac-ac1',
      },
      {
        class_name: 'calc-ac1',
        label: 'AC',
        query: 'default-ac-ac1',
      },
      {
        class_name: 'calc-ac2',
        label: 'AC',
        query: 'default-ac-ac2',
      },
      {
        class_name: 'calc-ac3-active',
        label: 'AC',
        query: 'default-ac-ac3',
      },
      {
        class_name: 'calc-ac3',
        label: 'AC',
        query: 'default-ac-ac3',
      },
      {
        class_name: 'calc-alarm1',
        label: 'Alarm',
        query: 'default-alarm-clock-alarm1-time-analog',
      },
      {
        class_name: 'calc-alarm2',
        label: 'Alarm',
        query: 'default-clock-digital-digitalclock',
      },
      {
        class_name: 'calc-alarm3',
        label: 'Alarm',
        query: 'default-alarm-alarm3-clock',
      },
      {
        class_name: 'calc-apple-tv1',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv1',
      },
      {
        class_name: 'calc-apple-tv2',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv2',
      },
      {
        class_name: 'calc-apple-tv3',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv3',
      },
      {
        class_name: 'calc-apple-tv4',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv4',
      },
      {
        class_name: 'calc-apple-tv5',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv5',
      },
      {
        class_name: 'calc-apple-tv6',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv6',
      },
      {
        class_name: 'calc-apple-tv7',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv7',
      },
      {
        class_name: 'calc-apple-tv8',
        label: 'AppleTv',
        query: 'default-apple-appletv-tv-tv8',
      },
      {
        class_name: 'calc-applelogo',
        label: 'AppleTvLogo',
        query: 'default-apple-appletv-tv-appletvlogo-logo',
      },
      {
        class_name: 'calc-back-166',
        label: 'Back',
        query: 'default-back-arrow',
      },
      {
        class_name: 'calc-back-187',
        label: 'Back',
        query: 'default-arrow-back-backarrow',
      },
      {
        class_name: 'calc-backward',
        label: 'Backward',
        query: 'default-back-backarrow-arrow-rewind',
      },
      {
        class_name: 'calc-batteries1',
        label: 'Battery',
        query: 'default-batteries-battery-batteries1-battery1-fullbattery',
      },
      {
        class_name: 'calc-batteries2',
        label: 'Battery',
        query: 'default-batteries-battery-batteries2-battery2-halfbattery',
      },
      {
        class_name: 'calc-batteries3',
        label: 'Battery',
        query: 'default-batteries-battery-batteries3-battery3-halfbattery',
      },
      {
        class_name: 'calc-batteries4',
        label: 'ChargingBattery',
        query: 'default-batteries-battery-batteries4-battery4-charging-chargingbattery',
      },
      {
        class_name: 'calc-batteries5',
        label: 'Barrey',
        query: 'default-batteries-battery-batteries5-battery5-charging',
      },
      {
        class_name: 'calc-bed1-active',
        label: 'Bed',
        query: 'default-bed-bed1',
      },
      {
        class_name: 'calc-bed2-active',
        label: 'Bed',
        query: 'default-bed-bed2',
      },
      {
        class_name: 'calc-bed3',
        label: 'Bed',
        query: 'default-bed-bed3',
      },
      {
        class_name: 'calc-bell',
        label: 'Bell',
        query: 'default-bell',
      },
      {
        class_name: 'calc-bell1-active',
        label: 'Bell',
        query: 'default-bell-bell',
      },
      {
        class_name: 'calc-bell2-active',
        label: 'Bell',
        query: 'default-bell-bell2',
      },
      {
        class_name: 'calc-bell3',
        label: 'Bell',
        query: 'default-bell-bell3',
      },
      {
        class_name: 'calc-bluetooth',
        label: 'Bluetooth',
        query: 'default-bluetooth-bt',
      },
      {
        class_name: 'calc-bluray2',
        label: 'Blueray',
        query: 'default-bluray-bluray2-br',
      },
      {
        class_name: 'calc-bluray3',
        label: 'Blueray',
        query: 'default--bluray-bluray3-br',
      },
      {
        class_name: 'calc-boardroom1',
        label: 'BoardRoom',
        query: 'default-boardroom-meeting-boardroom1',
      },
      {
        class_name: 'calc-boardroom2',
        label: 'BoardRoom',
        query: 'default-boardroom-meeting-boardroom2',
      },
      {
        class_name: 'calc-boardroom3',
        label: 'BoardRoom',
        query: 'default-boardroom-meeting-boardroom3',
      },
      {
        class_name: 'calc-boardroom4',
        label: 'BoardRoom',
        query: 'default-boardroom-meeting-boardroom4',
      },
      {
        class_name: 'calc-boardroom5',
        label: 'BoardRoom',
        query: 'default-boardroom-meeting-boardroom5',
      },
      {
        class_name: 'calc-camera2',
        label: 'Camera',
        query: 'default-camera-camera2-cctv',
      },
      {
        class_name: 'calc-camera3',
        label: 'Camera',
        query: 'default-camera-camera3-cctv',
      },
      {
        class_name: 'calc-change-password',
        label: 'ChangePassword',
        query: 'default-changepassowrd-password',
      },
      {
        class_name: 'calc-chat',
        label: 'Chat',
        query: 'default-chat-message',
      },
      {
        class_name: 'calc-chromecast',
        label: 'ChromeCast',
        query: 'default-chromecast-chrome',
      },
      {
        class_name: 'calc-cool',
        label: 'Cool',
        query: 'default-cool-spring',
      },
      {
        class_name: 'calc-door1',
        label: 'Door',
        query: 'default-door-door1',
      },
      {
        class_name: 'calc-door2',
        label: 'Door',
        query: 'default-door-door2',
      },
      {
        class_name: 'calc-door3',
        label: 'Door',
        query: 'default-door-door3',
      },
      {
        class_name: 'calc-door4',
        label: 'Door',
        query: 'default-door-door4',
      },
      {
        class_name: 'calc-door5',
        label: 'Door',
        query: 'default-door-door5',
      },
      {
        class_name: 'calc-doorlock1',
        label: 'DoorLock',
        query: 'default-doorlock-doorlock1-lock',
      },
      {
        class_name: 'calc-doorlock2',
        label: 'DoorLock',
        query: 'default-doorlock-doorlock2-lock',
      },
      {
        class_name: 'calc-doorlock3',
        label: 'DoorLock',
        query: 'default-doorlock-doorlock3-lock',
      },
      {
        class_name: 'calc-down',
        label: 'Down',
        query: 'default-down-arrow-downarrow',
      },
      {
        class_name: 'calc-edit',
        label: 'Edit',
        query: 'default-edit-prncil',
      },
      {
        class_name: 'calc-fan1',
        label: 'Fan',
        query: 'default-fan-fan1',
      },
      {
        class_name: 'calc-fan2',
        label: 'Fan',
        query: 'default-fan-fan2',
      },
      {
        class_name: 'calc-fan3',
        label: 'Fan',
        query: 'default-fan-fan3',
      },
      {
        class_name: 'calc-forward',
        label: 'Forward',
        query: 'default-forward-ff-arrow',
      },
      {
        class_name: 'calc-friends',
        label: 'Friends',
        query: 'default-friends',
      },
      {
        class_name: 'calc-gate1',
        label: 'Gate',
        query: 'default-gate-gate1',
      },
      {
        class_name: 'calc-gate2',
        label: 'Gate',
        query: 'default-gate-gate2',
      },
      {
        class_name: 'calc-gate3',
        label: 'Gate',
        query: 'default-gate-gate3',
      },
      {
        class_name: 'calc-gate4',
        label: 'Gate',
        query: 'default-gate-gate4',
      },
      {
        class_name: 'calc-hifi1',
        label: 'HiFi',
        query: 'default-hifi-hifi1',
      },
      {
        class_name: 'calc-hifi2',
        label: 'HiFi',
        query: 'default-hifi-hifi2',
      },
      {
        class_name: 'calc-hifi3',
        label: 'HiFi',
        query: 'default-hifi-hifi3',
      },
      {
        class_name: 'calc-info',
        label: 'Info',
        query: 'default-info',
      },
      {
        class_name: 'calc-item',
        label: 'Item',
        query: 'default-item',
      },
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'default-itemfolder-folder',
      },
      {
        class_name: 'calc-kitchen1',
        label: 'Kitchen',
        query: 'default-kitchen-kitchen1',
      },
      {
        class_name: 'calc-kitchen2',
        label: 'Kitchen',
        query: 'default-kitchen-kitchen2',
      },
      {
        class_name: 'calc-kitchen3',
        label: 'Kitchen',
        query: 'default-kitchen-kitchen3',
      },
      {
        class_name: 'calc-kitchen4',
        label: 'Kitchen',
        query: 'default-kitchen-kitchen4',
      },
      {
        class_name: 'calc-lamp1',
        label: 'Lamp',
        query: 'default-lamp1',
      },
      {
        class_name: 'calc-lamp2',
        label: 'Lamp',
        query: 'default-lamp2',
      },
      {
        class_name: 'calc-lamp3',
        label: 'Lamp',
        query: 'default-lamp3',
      },
      {
        class_name: 'calc-lg-tv1',
        label: 'LGTV',
        query: 'default-lg-tv-tv1',
      },
      {
        class_name: 'calc-lg-tv2',
        label: 'LGTV',
        query: 'default-lg-tv-tv2',
      },
      {
        class_name: 'calc-lg-tv3',
        label: 'LGTV',
        query: 'default-lg-tv-tv3',
      },
      {
        class_name: 'calc-lg-tv4',
        label: 'LGTV',
        query: 'default-lg-tv-tv4',
      },
      {
        class_name: 'calc-lg-tv5',
        label: 'LGTV',
        query: 'default-lg-tv-tv5',
      },
      {
        class_name: 'calc-light1',
        label: 'Light',
        query: 'default-light-light1',
      },
      {
        class_name: 'calc-light2',
        label: 'Light',
        query: 'default-light-light2',
      },
      {
        class_name: 'calc-light3',
        label: 'Light',
        query: 'default-light-light3',
      },
      {
        class_name: 'calc-light4',
        label: 'Light',
        query: 'default-light-light4',
      },
      {
        class_name: 'calc-livingroom1',
        label: 'LivingRoom',
        query: 'default-livingroom-livingroom1',
      },
      {
        class_name: 'calc-livingroom2',
        label: 'LivingRoom',
        query: 'default-livingroom-livingroom2',
      },
      {
        class_name: 'calc-livingroom3',
        label: 'LivingRoom',
        query: 'default-livingroom-livingroom3',
      },
      {
        class_name: 'calc-livingroom4',
        label: 'LivingRoom',
        query: 'default-livingroom-livingroom4',
      },
      {
        class_name: 'calc-livingroom5',
        label: 'LivingRoom',
        query: 'default-livingroom-livingroom5',
      },
      {
        class_name: 'calc-lock1',
        label: 'Lock',
        query: 'default-lock-lock1',
      },
      {
        class_name: 'calc-lock2',
        label: 'Lock',
        query: 'default-lock-lock2',
      },
      {
        class_name: 'calc-lock3',
        label: 'Lock',
        query: 'default-lock-lock3',
      },
      {
        class_name: 'calc-lock4',
        label: 'Lock',
        query: 'default-lock-lock4',
      },
      {
        class_name: 'calc-lock5',
        label: 'Lock',
        query: 'default-lock-lock5',
      },
      {
        class_name: 'calc-media',
        label: 'Media',
        query: 'default-media',
      },
      {
        class_name: 'calc-monitor1',
        label: 'Monitor',
        query: 'default-monitor-monitor1',
      },
      {
        class_name: 'calc-monitor2',
        label: 'Monitor',
        query: 'default-monitor-monitor2',
      },
      {
        class_name: 'calc-motionsensor1',
        label: 'MotionSensor',
        query: 'default-motionsensor-motionsensor1',
      },
      {
        class_name: 'calc-motionsensor2',
        label: 'MotionSensor',
        query: 'default-motionsensor-motionsensor2',
      },
      {
        class_name: 'calc-motionsensor3',
        label: 'MotionSensor',
        query: 'default-motionsensor-motionsensor3',
      },
      {
        class_name: 'calc-motionsensor4',
        label: 'MotionSensor',
        query: 'default-motionsensor-motionsensor4',
      },
      {
        class_name: 'calc-motionsensor5',
        label: 'MotionSensor',
        query: 'default-motionsensor-motionsensor5',
      },
      {
        class_name: 'calc-mute',
        label: 'Mute',
        query: 'default-mute-nosound',
      },
      {
        class_name: 'calc-next',
        label: 'Next',
        query: 'default-next-arrow',
      },
      {
        class_name: 'calc-notifications',
        label: 'Notification',
        query: 'default-notification-bell-notifications',
      },
      {
        class_name: 'calc-off',
        label: 'Off',
        query: 'default-off-power-on',
      },
      {
        class_name: 'calc-options',
        label: 'Options',
        query: 'default-options-dots',
      },
      {
        class_name: 'calc-pause',
        label: 'pause',
        query: 'default-pause',
      },
      {
        class_name: 'calc-play-apple',
        label: 'Play',
        query: 'default-play-apple',
      },
      {
        class_name: 'calc-play',
        label: 'play',
        query: 'default-play',
      },
      {
        class_name: 'calc-plus2',
        label: 'Plus',
        query: 'default-plus-cross',
      },
      {
        class_name: 'calc-privacy',
        label: 'Privay',
        query: 'default-privacy-sheild-protect-secure',
      },
      {
        class_name: 'calc-profile-edit',
        label: 'EditProfile',
        query: 'default-profile-edit',
      },
      {
        class_name: 'calc-profile',
        label: 'Profile',
        query: 'default-profile-user',
      },
      {
        class_name: 'calc-radio',
        label: 'Radio',
        query: 'default-radio',
      },
      {
        class_name: 'calc-refresh',
        label: 'Refresh',
        query: 'default-refresh-loading',
      },
      {
        class_name: 'calc-remote-apple',
        label: 'Apple',
        query: 'default-apple-remote-appletv-appletvlogo-applelogo',
      },
      {
        class_name: 'calc-remote-sky',
        label: 'Sky',
        query: 'default-remote-sky',
      },
      {
        class_name: 'calc-remote-tv',
        label: 'TVRemote',
        query: 'default-tv-remote',
      },
      {
        class_name: 'calc-remote-vodafone',
        label: 'VodafoneRemote',
        query: 'default-remote-vodafone',
      },
      {
        class_name: 'calc-return',
        label: 'Return',
        query: 'default-return',
      },
      {
        class_name: 'calc-rollergate1',
        label: 'RollerGate',
        query: 'default-rollergate-rollergate1-gate',
      },
      {
        class_name: 'calc-rollergate2',
        label: 'RollerGate',
        query: 'default-rollergate-rollergate2-gate',
      },
      {
        class_name: 'calc-search',
        label: 'Search',
        query: 'default-search-magnify',
      },
      {
        class_name: 'calc-send',
        label: 'Send',
        query: 'default-send',
      },
      {
        class_name: 'calc-signout',
        label: 'SignOut',
        query: 'default-signout',
      },
      {
        class_name: 'calc-sky-tv1',
        label: 'SkyTv',
        query: 'default-sky-tv1-skylogo-sky-logo',
      },
      {
        class_name: 'calc-sky-tv3-229',
        label: 'SkyTv',
        query: 'default-sky-tv3-skylogo-sky-logo',
      },
      {
        class_name: 'calc-sky-tv3-230',
        label: 'SkyTv',
        query: 'default-sky-tv3-skylogo',
      },
      {
        class_name: 'calc-sky-tv4',
        label: 'SkyTv',
        query: 'default-sky-tv4-skylogo',
      },
      {
        class_name: 'calc-skylogo',
        label: 'Skylogo',
        query: 'default-skylogo',
      },
      {
        class_name: 'calc-sound',
        label: 'Sound',
        query: 'default-sound',
      },
      {
        class_name: 'calc-support',
        label: 'Support',
        query: 'default-support',
      },
      {
        class_name: 'calc-switch1',
        label: 'Switch',
        query: 'default-switch-switch1',
      },
      {
        class_name: 'calc-switch2',
        label: 'Switch',
        query: 'default-switch-switch2',
      },
      {
        class_name: 'calc-switch3',
        label: 'Switch',
        query: 'default-switch-switch3',
      },
      {
        class_name: 'calc-switch4',
        label: 'Switch',
        query: 'default-switch-switch4',
      },
      {
        class_name: 'calc-switch5',
        label: 'Switch',
        query: 'default-switch-switch5',
      },
      {
        class_name: 'calc-switch6',
        label: 'Switch',
        query: 'default-switch-switch6',
      },
      {
        class_name: 'calc-switch7',
        label: 'Switch',
        query: 'default-switch-switch7',
      },
      {
        class_name: 'calc-switch8',
        label: 'Switch',
        query: 'default-switch-switch8',
      },
      {
        class_name: 'calc-tag',
        label: 'Tag',
        query: 'default-tag',
      },
      {
        class_name: 'calc-temp1',
        label: 'Temp',
        query: 'default-temp-temp1-temperature-thermometer',
      },
      {
        class_name: 'calc-temp2',
        label: 'Temp',
        query: 'default-temp-temp2-temperature-thermometer',
      },
      {
        class_name: 'calc-terms',
        label: 'Terms',
        query: 'default-terms',
      },
      {
        class_name: 'calc-toggle-off',
        label: 'ToggleOff',
        query: 'default-toggle-off',
      },
      {
        class_name: 'calc-tv',
        label: 'TV',
        query: 'default-tv',
      },
      {
        class_name: 'calc-tv0ff1',
        label: 'TVOff',
        query: 'default-tv0ff-tv0ff1',
      },
      {
        class_name: 'calc-tv0ff2',
        label: 'TVOff',
        query: 'default-tv0ff-tv0ff2',
      },
      {
        class_name: 'calc-tv0ff3',
        label: 'TVOff',
        query: 'default-tv0ff-tv0ff3',
      },
      {
        class_name: 'calc-tv1',
        label: 'TV',
        query: 'default-tv-tv1',
      },
      {
        class_name: 'calc-tv2',
        label: 'TV',
        query: 'default-tv-tv2',
      },
      {
        class_name: 'calc-tvroom1',
        label: 'TVRoom',
        query: 'default-tvroom-tvroom1',
      },
      {
        class_name: 'calc-tvroom2',
        label: 'TVRoom',
        query: 'default-tvroom-tvroom2',
      },
      {
        class_name: 'calc-tvroom3',
        label: 'TVRoom',
        query: 'default-tvroom-tvroom3',
      },
      {
        class_name: 'calc-tvroom4',
        label: 'TVRoom',
        query: 'default-tvroom-tvroom4',
      },
      {
        class_name: 'calc-tvroom5',
        label: 'TVRoom',
        query: 'default-tvroom-tvroom5',
      },
      {
        class_name: 'calc-user-placeholder',
        label: 'UserPlaceHolder',
        query: 'default-user-placeholder',
      },
      {
        class_name: 'calc-ventelation1',
        label: 'Ventilation',
        query: 'default-ventelation-ventelation1',
      },
      {
        class_name: 'calc-ventelation2',
        label: 'Ventilation',
        query: 'default-ventelation-ventelation2',
      },
      {
        class_name: 'calc-ventelation3',
        label: 'Ventilation',
        query: 'default-ventelation-ventelation3',
      },
      {
        class_name: 'calc-ventelation4',
        label: 'Ventilation',
        query: 'default-ventelation-ventelation4',
      },
      {
        class_name: 'calc-ventelation5',
        label: 'Ventilation',
        query: 'default-ventelation-ventelation5',
      },
      {
        class_name: 'calc-view',
        label: 'View',
        query: 'default-view-eye',
      },
      {
        class_name: 'calc-vodafonelogo',
        label: 'VodafoneLOGO',
        query: 'default-vodafone-logo-vodafonelogo',
      },
      {
        class_name: 'calc-vodaphone-tv2',
        label: 'VodaphoneTV',
        query: 'default-vodaphone-tv2',
      },
      {
        class_name: 'calc-vodaphone-tv4',
        label: 'VodaphoneTV',
        query: 'default-vodaphone-tv4',
      },
      {
        class_name: 'calc-vodaphone-tv5',
        label: 'VodaphoneTV',
        query: 'default-vodaphone-tv5',
      },
      {
        class_name: 'calc-warm',
        label: 'Warm',
        query: 'default-warm-sun',
      },
      {
        class_name: 'calc-wifi',
        label: 'WiFI',
        query: 'default-wifi',
      },
    ],
  },
  folder_icons: {
    icons: [
      {
        class_name: 'calc-itemfolder',
        label: 'ItemFolder',
        query: 'folder-itemfolder-folder-item',
      },
      {
        class_name: 'calc-bell2',
        label: 'Bell',
        query: 'folder-bell-bell2',
      },
      {
        class_name: 'calc-camera2',
        label: 'Camera',
        query: 'folder-camera-camera2-cctv',
      },
      {
        class_name: 'calc-terms',
        label: 'Terms',
        query: 'folder-terms',
      },
      {
        class_name: 'calc-bluray2',
        label: 'Blueray',
        query: 'folder-blueray-blueray2-br',
      },
      {
        class_name: 'calc-fan1',
        label: 'Fan',
        query: 'folder-fan-fan1',
      },
      {
        class_name: 'calc-lamp1',
        label: 'Lamp',
        query: 'folder-lamp-lamp1',
      },
      {
        class_name: 'calc-light1',
        label: 'Light',
        query: 'folder-light-light1',
      },
      {
        class_name: 'calc-kitchen3',
        label: 'Kitchen',
        query: 'folder-kitchen-kitchen3',
      },
      {
        class_name: 'calc-temp2',
        label: 'Temp',
        query: 'folder-temp-temp2',
      },
    ],
  },
  ir_icons: {
    icons: [
      {
        class_name: 'calc-tv-active',
        label: 'TVActive',
        query: 'ir-tv-active',
      },
      {
        class_name: 'calc-bluray2',
        label: 'Blueray',
        query: 'ir-blueray-blueray2-br',
      },
      {
        class_name: 'calc-bluray3',
        label: 'Blueray',
        query: 'ir-blueray-blueray3-br',
      },
      {
        class_name: 'calc-kitchen3',
        label: 'Kitchen',
        query: 'ir--kitchen-kitchen3',
      },
      {
        class_name: 'calc-vodafonelogo',
        label: 'VodafoneLogo',
        query: 'ir-vodafone-logo-vodafonelogo',
      },
      {
        class_name: 'calc-camera2',
        label: 'Camera',
        query: 'ir-camera-camera2',
      },
      {
        class_name: 'calc-lg-tv1',
        label: 'LGTV',
        query: 'ir-lg-tv1',
      },
      {
        class_name: 'calc-vodaphone-tv5',
        label: 'VodaphoneTV',
        query: 'ir-vodaphone-tv5',
      },
      {
        class_name: 'calc-sky-tv3-230',
        label: 'SKYTV',
        query: 'ir-sky-tv-tv3',
      },
      {
        class_name: 'calc-apple-tv2',
        label: 'AppleTV',
        query: 'ir-apple-tv-tv2',
      },
    ],
  },
  relay_icons: {
    icons: [
      {
        class_name: 'calc-switch8',
        label: 'Switch',
        query: 'relay-switch-switch8-toggle',
      },
      {
        class_name: 'calc-switch6',
        label: 'Switch',
        query: 'relay-switch-switch6',
      },
      {
        class_name: 'calc-toggle-off',
        label: 'ToggleOff',
        query: 'relay-toggle-off',
      },
      {
        class_name: 'calc-switch1',
        label: 'Switch',
        query: 'relay-switch-switch1',
      },
      {
        class_name: 'calc-switch2',
        label: 'Switch',
        query: 'relay-switch-switch2',
      },
      {
        class_name: 'calc-off',
        label: 'Off',
        query: 'relay-off-on-power',
      },
      {
        class_name: 'calc-switch3',
        label: 'Switch',
        query: 'relay-switch-switch3',
      },
      {
        class_name: 'calc-lock2',
        label: 'Lock',
        query: 'relay-lock-lock2',
      },
      {
        class_name: 'calc-switch5',
        label: 'Switch',
        query: 'relay-switch-switch5',
      },
      {
        class_name: 'calc-switch7',
        label: 'Switch',
        query: 'relay-switch-switch7',
      },
      {
        class_name: 'calc-switch8',
        label: 'Switch',
        query: 'relay-switch-switch8',
      },
    ],
  },
};
