/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import {
  FormControl, InputLabel, Select, MenuItem, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ITEM_DEFAULTS } from '../../../../config/deviceConfig/item_defaults';
import { ICategory, IDeviceCategory } from '../../../../config/deviceConfig/types';
import proDeviceUpdateUtils from '../utils/utils';

type ComponentProps = {
  category: string;
  subCategory: string;
  sub3Category: string;
  editable: boolean;
  isVariableType: boolean;
  onChange: (cat: IDeviceCategory) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const ProDeviceCategorySelector: React.FunctionComponent<ComponentProps> = (props) => {
  const [category, setCategory] = useState(props.category ? props.category : '');
  const [subCategory, setSubCategory] = useState(props.subCategory);
  const [subCat3, setSubCat3] = useState(props.sub3Category);

  useEffect(() => {
    setCategory(props.category);
    setSubCategory(props.subCategory);
  }, [props]);
  const handleCategoryChange = (event: any) => {
    // appConsoleLog(event.target.value);
    setCategory(event.target.value);
    setSubCategory('');
    setSubCat3('');

    props.onChange({
      category: event.target.value,
      subCategory: '',
      sub3Category: '',
      isItem: false,
      config: null,
    });
  };
  const handleSubCategoryChange = (event: any) => {
    setSubCategory(event.target.value);
    setSubCat3('');
    const itemConfigs = proDeviceUpdateUtils.getFieldsConfigFromCat(category, event.target.value);

    props.onChange({
      category,
      subCategory: event.target.value,
      sub3Category: '',
      config: itemConfigs.config,
      isItem: itemConfigs.isItem,
    });
  };
  const handleItemTypeChange = (event: any) => {
    setSubCat3(event.target.value);

    const obj: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
    const objCat2 = obj[category].children[subCategory];
    const objCat3 = objCat2.children[event.target.value];

    let isItem = false;
    if (Object.keys(objCat3.children).length === 0) {
      isItem = true;
    }

    props.onChange({
      category,
      subCategory,
      sub3Category: event.target.value,
      isItem,
      config: objCat3,
    });
  };

  // eslint-disable-next-line array-callback-return
  const catItems = Object.values(ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows).map((item: any) => {
    if (props.isVariableType && item.value === 'location_variables') {
      return (
        <MenuItem value={item.value} key={item.value}>
          {item.title}
        </MenuItem>
      );
    }
    if (!props.isVariableType && item.value !== 'location_variables') {
      return (
        <MenuItem value={item.value} key={item.value}>
          {item.title}
        </MenuItem>
      );
    }
  });
  let subCatItems: any[] = [];
  let subCat3Items: any[] = [];
  const obj: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
  if (category !== '' && obj[category]?.children !== undefined) {
    subCatItems = Object.values(obj[category].children).map((item: any) => (
      <MenuItem value={item.value} key={item.value}>
        {item.title}
      </MenuItem>
    ));

    if (subCategory !== '' && obj[category]?.children[subCategory]?.children !== undefined) {
      subCat3Items = Object.values(obj[category].children[subCategory].children).map(
        (item: any) => (
          <MenuItem value={item.value} key={item.value}>
            {item.title}
          </MenuItem>
        ),
      );
    }
  }

  // catItems.unshift(
  //   <MenuItem value="" key="0" disabled>
  //     <em>None</em>
  //   </MenuItem>,
  // );

  let subCatLabel = '';
  let subCat3Label = '';

  let subCatEnable = false;
  let subCat3Enable = false;
  if (subCat3Items.length > 0) {
    subCatLabel = 'Sub Category';
    subCat3Label = 'Item';
    subCatEnable = true;
    subCat3Enable = true;
  } else {
    subCatLabel = 'Item';
    subCatEnable = true;
    subCat3Enable = false;
  }

  subCatItems.unshift(
    <MenuItem value="" key="0" disabled>
      <em>None</em>
    </MenuItem>,
  );
  subCat3Items.unshift(
    <MenuItem value="" key="0" disabled>
      <em>None</em>
    </MenuItem>,
  );

  return (
    <div className="col-md-12 ml-0 pl-0">
      <FormControl
        size="small"
        variant="outlined"
        className="dynamic-ui-form-control "
        disabled={!props.editable}
      >
        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={category}
          onChange={handleCategoryChange}
          label="Category"
          placeholder="Select Category"
          displayEmpty
          // eslint-disable-next-line max-len
          // renderValue={(value) => (value?.length ? Array.isArray(value) ? value.join(', ') : value : 'placeholder')}
        >
          {catItems}
        </Select>
      </FormControl>
      {subCatEnable ? (
        <FormControl
          size="small"
          variant="outlined"
          className="dynamic-ui-form-control "
          disabled={!props.editable}
        >
          <InputLabel id="demo-simple-select-outlined-label">{subCatLabel}</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={subCategory}
            onChange={handleSubCategoryChange}
            label={subCatLabel}
          >
            {subCatItems}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
      {subCat3Enable ? (
        <FormControl
          size="small"
          variant="outlined"
          className="dynamic-ui-form-control "
          disabled={!props.editable}
        >
          <InputLabel id="demo-simple-select-outlined-label">{subCat3Label}</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={subCat3}
            onChange={handleItemTypeChange}
            label={subCat3Label}
          >
            {subCat3Items}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
    </div>
  );
};
