import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Box,
  ListItemText,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';

const debounceOptionsForDiffs = [
  {
    value: 4,
    label: '0.0001',
    description: 'Detect change when value change by 0.0001',
  },
  {
    value: 3,
    label: '0.001',
    description: 'Detect change when value change by 0.001',
  },
  {
    value: 2,
    label: '0.01',
    description: 'Detect change when value change by 0.01',
  },
  {
    value: 1,
    label: '0.1',
    description: 'Detect change when value change by 0.1',
  },
  {
    value: 0,
    label: '1',
    description: 'Detect change when value change by 1',
  },
  {
    value: -1,
    label: '10',
    description: 'Detect change when value change by 10',
  },
  {
    value: -2,
    label: '100',
    description: 'Detect change when value change by 100',
  },
  {
    value: -3,
    label: '1000',
    description: 'Detect change when value change by 1000',
  },
  {
    value: -4,
    label: '10000',
    description: 'Detect change when value change by 10000',
  },
];

const DeviceSettingConfiguration: React.FC<{
  settings: Record<
    string,
    {
      command?: string;
      command_v?: string;
      k: string;
      t: string;
      elog?: {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };
      events?: {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };
    }
  >;
  currentChannelValues?: Record<string, any>;
  onSave: (
    value: Record<
      string,
      {
        command?: string;
        command_v?: string;
        k: string;
        t: string;
        elog?: {
          debounce_cond: number;
          debounce_type: string;
          debounce: boolean;
          disabled: boolean;
        };
        events?: {
          debounce_cond: number;
          debounce_type: string;
          debounce: boolean;
          disabled: boolean;
        };
      }
    >
  ) => void;
  type: 'elog' | 'events';
}> = (props) => {
  const { settings, type, currentChannelValues = {} } = props;

  const [formValues, setFormValues] = useState(() => {
    const values = {};
    Object.keys(settings).map((key) => {
      const channel = settings[key];
      let config = channel[props.type] as {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };

      if (!config) {
        config = {
          debounce_cond: 1,
          debounce_type: 'time',
          debounce: false,
          disabled: true,
        };
      }

      Object.keys(config).map((configKey) => {
        values[`${key}_ui_to_channel_${configKey}`] = config[configKey];
      });
    });

    return values;
  });

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={(values) => {
        console.log(values);
        const modifiedSetting = {
          ...settings,
        };
        Object.keys(values).map((key) => {
          const parts = key.split('_ui_to_channel_');
          if (!modifiedSetting[parts[0]][type]) {
            modifiedSetting[parts[0]][type] = {
              debounce_cond: 1,
              debounce_type: 'time',
              debounce: false,
              disabled: true,
            };
          }
          modifiedSetting[parts[0]][type][parts[1]] = values[key];
        });

        props.onSave(modifiedSetting);
      }}
    >
      {({
        values, handleChange, setFieldValue, handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box style={{ display: 'flex', marginTop: 10, justifyContent: 'flex-start' }}>
            <Button type="submit" size="small" variant="outlined">
              Save Configuration
            </Button>
          </Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Channel</TableCell>
                  <TableCell>Enable</TableCell>
                  <TableCell>Enable Debounce</TableCell>
                  <TableCell>Debounce Type</TableCell>
                  <TableCell>Debounce Condition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(settings).map((key) => {
                  const channel = settings[key];
                  return (
                    <TableRow key={key}>
                <TableCell>
                        {key}
                        {' '}
                        -
                        {channel.k} 
                        {' '}
                        <span style={{color: '#2291A0', fontWeight: 500}}>{currentChannelValues[channel.k]}</span>
                      </TableCell>
                <TableCell>
                        <Switch
                          size="small"
                          name={`${key}_ui_to_channel_disabled`}
                          checked={!values[`${key}_ui_to_channel_disabled`]}
                          onChange={(e) => {
                            setFieldValue(`${key}_ui_to_channel_disabled`, !e.target.checked);
                            if (!e.target.checked) {
                              setFieldValue(`${key}_ui_to_channel_debounce`, e.target.checked);
                            }
                          }}
                        />
                      </TableCell>
                <TableCell>
                        <Switch
                          size="small"
                          name={`${key}_ui_to_channel_debounce`}
                          checked={values[`${key}_ui_to_channel_debounce`]}
                          disabled={values[`${key}_ui_to_channel_disabled`]}
                          onChange={(e) => {
                            setFieldValue(`${key}_ui_to_channel_debounce`, e.target.checked);
                          }}
                        />
                      </TableCell>
                <TableCell>
                        <FormControl style={{ width: '100%' }}>
                          <Select
                            variant="standard"
                            size="small"
                            value={values[`${key}_ui_to_channel_debounce_type`]}
                            name={`${key}_ui_to_channel_debounce_type`}
                            onChange={handleChange}
                            disabled={!values[`${key}_ui_to_channel_debounce`]}
                          >
                            <MenuItem value="time">Time(s)</MenuItem>
                            <MenuItem value="diff">Diff</MenuItem>
                            <MenuItem value="change">Change</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                <TableCell>
                        {values[`${key}_ui_to_channel_debounce_type`] === 'diff' ? (
                          <FormControl variant="standard" size="small" style={{ width: '100%' }}>
                            <Select
                              value={values[`${key}_ui_to_channel_debounce_cond`]}
                              name={`${key}_ui_to_channel_debounce_cond`}
                              onChange={handleChange}
                              renderValue={(value) => {
                                const find = debounceOptionsForDiffs.find(item => item.value === value);
                                if (find) {
                                  return <Typography>{find.label}</Typography>
                                }
                                return null;
                              }}
                            >
                              {debounceOptionsForDiffs.map((option) => (
                                <MenuItem value={option.value}>
                                  <ListItemText primary={option.label} secondary={option.description} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            style={{ width: '100%' }}
                            size="small"
                            type="number"
                            variant="standard"
                            value={values[`${key}_ui_to_channel_debounce_cond`]}
                            name={`${key}_ui_to_channel_debounce_cond`}
                            disabled={!values[`${key}_ui_to_channel_debounce`]}
                            onChange={handleChange}
                          />
                        )}
                      </TableCell>
              </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      )}
    </Formik>
  );
};

export default DeviceSettingConfiguration;
