import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FiEdit } from 'react-icons/fi';
import { WidgetHeaderLayout } from './WidgetHeaderLayout';

type ComponentProps = {
  selectedProject: any;
  onClick: any;
  imgBgStyle: any;
};

const useStyle = makeStyles({
  root: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(2px)',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    width: '100%',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    // lineeight: 25px;
  },
  content_root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
  },
  displayIcon2: {
    width: '36px',
    height: '36px',
    float: 'right',
    color: 'var(--primary-color)',
    padding: '4px 4px',
    border: '1px solid var(--primary-color)',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  projectImg: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
  },
  imgTab: {
    width: '50%',
    padding: '10px',
    borderRadius: '5px',
  },
  infoTab: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    background: '#FFFFFF',
    /* Utility/Mid Gray */
    border: '1px solid #D9DFE0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '18px',
    margin: '10px',
  },
  infoTitle: {},
  infoDesc: {
    flex: 1,
    background: 'white',
  },
});
// eslint-disable-next-line import/prefer-default-export
export const LocationWidget: React.FunctionComponent<ComponentProps> = ({
  selectedProject,
  onClick,
  imgBgStyle,
}) => {
  const styles = useStyle();
  console.log('xcxcxcxc', selectedProject);
  return (
    <div className={styles.root}>
      <WidgetHeaderLayout>
        <Box className={styles.header}>
          <Typography className={styles.title}>{selectedProject.name}</Typography>
          <FiEdit onClick={onClick} className={styles.displayIcon2} />
        </Box>
      </WidgetHeaderLayout>
      <div className={clsx(styles.content_root)}>
        <div className={styles.imgTab}>
          <div className={styles.projectImg} style={{ ...imgBgStyle }} />
        </div>
        <div className={styles.infoTab}>
          <div className={clsx(styles.info)}>{selectedProject.name}</div>
          <div className={clsx(styles.info, styles.infoDesc)}>{selectedProject.description}</div>
        </div>
      </div>
    </div>
  );
};
