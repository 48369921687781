/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import {Box, Grid, Skeleton, Typography} from '@mui/material';
import axios from 'axios';
import {cloneDeep, isArray} from 'lodash';
import {useSnackbar} from 'notistack';
import {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {DropResult, ResponderProvided} from 'react-beautiful-dnd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {useDispatch} from 'react-redux';
import {Board} from 'src/containers/app/LogicsEngine/components/board';
import {Card} from 'src/containers/app/LogicsEngine/components/board/Card';
import {Group} from 'src/containers/app/LogicsEngine/components/board/Group';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {
  v2InfoClientSideMetaUpdate,
  v2InfoUpdate,
  v2PullDroppedItem,
} from 'src/store/redux/features/backup-location-config';
import {PendingComponentData} from 'src/store/redux/features/backup-location-config/v2types';
import {
  DEVICE_POSITIONS,
  removeDevice,
  updateConfigurableContainer,
} from 'src/store/redux/features/configurable-containers';
import {RootState, useSelector} from 'src/store/redux/store';
import useQueryString from 'src/utility/CustomHooks/useQueryString';
import ConfigurableDeviceContainer from '../ConfigurableDeviceContainer';
import Device, {DEVICE_STATUS_IN_RESTORE_STEP} from '../shared/Device';
import DeviceContainer, {DEVICE_CONTAINER_LOC} from '../shared/DeviceContainer';
import useHideSelectedDevices from '../shared/hooks/useHideSelectedDevices';
import MoveDeviceDialog from '../shared/MoveDeviceDialog';
import useMoveDevices from '../shared/hooks/useMoveDevices';
import useLongPress from '../shared/hooks/useOnLongPress';
import {DeviceCollectionSearchOutItem} from '@smartlife-redux-state/common';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';
import {useRestoreType} from '../shared/hooks/useRestoreType';
import {RestoreDone} from '../shared/RestoreDoneScreen';

export const SessionRestoreLocVar = forwardRef((props, ref) => {
  const sessionId = useQueryStringFirst('sessionId');
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const {enqueueSnackbar} = useSnackbar();

  const {
    checkedDevicesState,
    allSelectedState,
    hiddenItems,
    showCheckBoxState,
    configurableContainerRef,
    configurableList,
    devicePendingComp,
    isStepCompleted,
  } = useRestoreType('lvar', sessionId);

  const {checkedDevices, setCheckDevices} = checkedDevicesState;
  const {allSelected, setAllSelected} = allSelectedState;

  const locVarPendingComp = devicePendingComp;

  const [_, groupCheckedDevicesByContId, showHiddens, setShowHiddens, forceHide] =
    useHideSelectedDevices(locVarPendingComp, checkedDevices, setCheckDevices);

  const {showCheckBox, setShowCheckBox} = showCheckBoxState;

  const {configInited, moveDevices} = useMoveDevices();
  const [openMoveDialog, setOpenMoveDialog] = useState<string>(null);

  useImperativeHandle(ref, () => ({
    getConfigurableContainerRef() {
      return configurableContainerRef;
    },
  }));

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    try {
      console.log(result);
      const {destination} = result;
      if (!destination) return;

      const {droppableId} = destination;

      // dropped onto a container. so it is a dropped as a new device
      if (droppableId.indexOf('configContainer') > -1) {
        const configurableContainerId = droppableId.split('configContainer');
        const container = configurableList[configurableContainerId[1]];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_loc_var': {
            const deviceRoot =
              locVarPendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = {...deviceRoot.data, id: droppedDedviceId};
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  toExisting: container.deviceMap.toExisting,
                  asNew: {
                    ...container.deviceMap.asNew,
                    [device.id]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            })
          );

          dispatch(
            v2InfoUpdate({
              value: {
                ...locVarPendingComp,
                pendingComps: {
                  ...locVarPendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...locVarPendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...locVarPendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: null,
                      },
                    },
                  },
                },
              },
              key: 'lvar',
              mergeErrorComps: false,
            })
          );
        }
      }
      // dropped on to the existng item. So update existing item list
      if (droppableId.indexOf('configExistingDevice') > -1) {
        const existingDeviceId = droppableId.split('configExistingDevice')[1];
        const configurableContainerId = existingDeviceId.split('_')[1];
        const container = configurableList[configurableContainerId];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_loc_var': {
            const deviceRoot =
              locVarPendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = {...deviceRoot.data, id: droppedDedviceId};
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  asNew: container.deviceMap.asNew,
                  toExisting: {
                    ...container.deviceMap.toExisting,
                    [existingDeviceId]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            })
          );

          dispatch(
            v2InfoUpdate({
              value: {
                ...locVarPendingComp,
                pendingComps: {
                  ...locVarPendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...locVarPendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...locVarPendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: existingDeviceId,
                      },
                    },
                  },
                },
              },
              key: 'lvar',
              mergeErrorComps: false,
            })
          );
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
      throw err;
    }
  }

  if (isStepCompleted) {
    return <RestoreDone />;
  }

  if (!locVarPendingComp) {
    return (
      <Grid container spacing={1}>
        <Grid item md={6}>
          <Skeleton sx={{bgcolor: '#D9DFE0'}} variant="rectangular" width="100%" height={200} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Board onDragEnd={onDragEnd}>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {locVarPendingComp && locVarPendingComp.pendingComps && (
            <>
              {locVarPendingComp.pendingComps &&
                Object.keys(locVarPendingComp.pendingComps).map(contId => {
                  const container = locVarPendingComp.pendingComps[contId];

                  return (
                    <DeviceContainer
                      title="Variable Container"
                      position={DEVICE_CONTAINER_LOC.IN_TEMPLATE}
                      dcId={contId}
                      key={contId}
                      checkedDevices={checkedDevices[contId] ? checkedDevices[contId] : {}}
                      allSelected={allSelected[contId]}
                      onToggleAllSelect={checked => {
                        setAllSelected(state => ({
                          [contId]: checked,
                        }));
                        setShowCheckBox(!showCheckBox);
                      }}
                      hideSelectedDevices={groupCheckedDevicesByContId}
                      hasHiddenItems={
                        hiddenItems &&
                        hiddenItems[contId] &&
                        Object.keys(hiddenItems[contId]).length > 0
                      }
                      showHiddenItems={showHiddens}
                      onToggleShowHiddenItems={setShowHiddens}
                      onMove={() => {
                        setOpenMoveDialog(contId);
                      }}
                      type="lvar"
                    >
                      {({searchText, devices}) => (
                        <Group
                          droppableId="template_loc_var_container"
                          droppableType="pro_var"
                          isDropDisabled
                        >
                          {() => (
                            <Grid container spacing={1}>
                              {Object.keys(devices).map(key => {
                                const locVar = devices[key];
                                if (searchText && searchText.length > 0) {
                                  if (!(locVar.data.name.toLowerCase().indexOf(searchText) > -1)) {
                                    return <></>;
                                  }
                                }
                                if (
                                  !showHiddens &&
                                  hiddenItems &&
                                  hiddenItems[contId] &&
                                  hiddenItems[contId][key]
                                ) {
                                  return <></>;
                                }

                                return (
                                  <Grid item md={3} sm={6} key={locVar.old_id}>
                                    <Card
                                      draggableId={`template_loc_var___${locVar.old_id}`}
                                      index={1}
                                      dragDisabled={
                                        !!(
                                          locVar.clientSideMeta &&
                                          locVar.clientSideMeta.dropped_contatainer_id
                                        )
                                      }
                                    >
                                      {({provided}) => (
                                        <Box>
                                          <Device
                                            deviceStatus={
                                              DEVICE_STATUS_IN_RESTORE_STEP.IN_TEMPLATE_DEVICE
                                            }
                                            isHidden={
                                              hiddenItems &&
                                              hiddenItems[contId] &&
                                              hiddenItems[contId][key]
                                            }
                                            device={locVar.data as any}
                                            dragHandlerProps={{...provided.dragHandleProps}}
                                            dragDisabled={
                                              !!(
                                                locVar.clientSideMeta &&
                                                locVar.clientSideMeta.dropped_contatainer_id
                                              )
                                            }
                                            bgColor={
                                              locVar.clientSideMeta &&
                                              locVar.clientSideMeta.dropped_contatainer_id
                                                ? '#d6d6d6'
                                                : null
                                            }
                                            showCheckBox={showCheckBox}
                                            onLongPress={() => setShowCheckBox(!showCheckBox)}
                                            checked={
                                              checkedDevices[contId] &&
                                              checkedDevices[contId][locVar.old_id]
                                            }
                                            onClickUnHide={() => {
                                              console.log(hiddenItems);
                                              if (
                                                hiddenItems &&
                                                Object.keys(hiddenItems).length > 0
                                              ) {
                                                const cpy = cloneDeep(hiddenItems);
                                                delete cpy[contId][locVar.old_id];
                                                console.log(cpy);
                                                forceHide(cpy);
                                              }
                                              // setCheckDevices({ ...cpy });
                                            }}
                                            handleCheckChange={(checked: boolean) => {
                                              // if manually clicked, reset allCheck,
                                              setAllSelected(null);
                                              if (!checked) {
                                                const cpy = cloneDeep(checkedDevices);
                                                delete cpy[contId][locVar.old_id];

                                                setCheckDevices({...cpy});
                                              } else {
                                                setCheckDevices({
                                                  ...checkedDevices,
                                                  [contId]: {
                                                    ...checkedDevices[contId],
                                                    [locVar.old_id]: checked,
                                                  },
                                                });
                                              }
                                            }}
                                            // eslint-disable-next-line consistent-return
                                            handleOnPullClick={() => {
                                              if (!locVar.clientSideMeta) return null;

                                              if (locVar.clientSideMeta.dropped_on) {
                                                dispatch(
                                                  removeDevice({
                                                    type: DEVICE_POSITIONS.TO_EXISTING,
                                                    configurableDcId:
                                                      locVar.clientSideMeta.dropped_contatainer_id,
                                                    key: locVar.clientSideMeta.dropped_on,
                                                  })
                                                );
                                              } else {
                                                dispatch(
                                                  removeDevice({
                                                    type: DEVICE_POSITIONS.AS_NEW,
                                                    configurableDcId:
                                                      locVar.clientSideMeta.dropped_contatainer_id,
                                                    key: locVar.old_id,
                                                  })
                                                );
                                              }

                                              dispatch(
                                                v2PullDroppedItem({
                                                  type: 'lvar',
                                                  dcId: contId,
                                                  deviceId: locVar.old_id,
                                                })
                                              );
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Card>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        </Group>
                      )}
                    </DeviceContainer>
                  );
                })}
            </>
          )}
        </Grid>
        <Grid item md={6}>
          <ConfigurableDeviceContainer type="locvar" dcCat={1} ref={configurableContainerRef} />
        </Grid>
      </Grid>
      <MoveDeviceDialog
        open={openMoveDialog}
        onClose={(value, templateId) => {
          if (!value || !templateId) return;
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const preConfig = {
            [templateId]: value.id,
          };
          moveDevices(
            JSON.stringify(preConfig),
            configurableList,
            locVarPendingComp,
            'lvar',
            checkedDevices[templateId]
          );
          setOpenMoveDialog(null);
          setCheckDevices({});
        }}
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line consistent-return
        containers={
          configurableList
            ? // eslint-disable-next-line consistent-return
              Object.keys(configurableList).map(key => {
                const dc = configurableList[key];

                if (
                  (dc.dc as DeviceCollectionSearchOutItem).dc_cat === 1 &&
                  (dc.dc as DeviceCollectionSearchOutItem).dc_type === 9
                ) {
                  return dc.dc;
                }
              })
            : []
        }
      />
    </Board>
  );
});

export default SessionRestoreLocVar;
