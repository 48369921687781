import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { appConsoleLog } from '../../utility/appUtils';
import { IAuthApolloResponse } from './types';
import FirebaseAuthFunctions from '../Auth/providers/FirebaseAuthFunctions';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

// eslint-disable-next-line import/prefer-default-export
export class ApolloFunctions {
  static createOpenApolloClient = () => new ApolloClient({
    uri: process.env.REACT_APP_APOLLO_SERVER_URL,
    cache: new InMemoryCache(),
    defaultOptions,
  });

  static createAuthApolloClient = (accessToken: string, uri?: string) => {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: accessToken || '',
      },
    }));
    const httpLink = createHttpLink({
      uri: uri || process.env.REACT_APP_APOLLO_SERVER_URL,
    });

    // const errorLink = onError(({ graphQLErrors, networkError }) => {
    //   if (graphQLErrors) {
    //     try {
    //       graphQLErrors.forEach(({
    //         message, locations, path, extensions,
    //       }) => {
    //         console.log(
    //           `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //         );
    //         if (extensions.code === 'FORBIDDEN') {
    //           // FirebaseAuthFunctions.LogoutCurrentUser();
    //           // window.location.href = "/app";

    //           throw new Error('forbidden'); // trick - break the loop
    //         }
    //       });
    //     } catch (e) {
    //       if (e !== 'forbidden') throw e;
    //     }
    //   }

    //   if (networkError) console.log(`[Network Error]: ${networkError.message}`);
    // });
    appConsoleLog(accessToken);
    const tempLlient = new ApolloClient({
      uri: process.env.REACT_APP_APOLLO_SERVER_URL,
      link: from([authLink, httpLink]), // authLink.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions,
    });

    return tempLlient;
  };

  static getLongLivedAccessToken = async (
    tempLlient: ApolloClient<NormalizedCacheObject>,
  ): Promise<any> => {
    try {
      const result = await tempLlient.query({
        query: gql`
          query {
            getLongLivedAccessToken {
              token
              created
              life
              profile {
                id
                fb_uid
                user_name
                profile_photo
                email
                invited_by
                user_status
                created_at
                updated_at
                user_country
                user_country_code
                user_city
                user_address
                user_contact_no1
                user_timezone
              }
            }
          }
        `,
      });
      const {
        token, created, life, profile,
      } = result.data.getLongLivedAccessToken;
      const res: IAuthApolloResponse = {
        token,
        created,
        life,
        profile,
      };
      return res;
    } catch (err) {
      return false;
    }
  };
}
