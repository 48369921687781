import React, {Suspense, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useSnackbar} from 'notistack';
import {cloneDeep} from 'lodash';
import GreenButton from '../../../components/shared/buttons/AuthButton/GreenButton';
import styles from './ProDeviceCreateEdit.module.css';
// common styles for all dynamic ui module
import './dynamic-ui.css';
import {ProDeviceCategorySelector} from '../DynamicDeviceCreate/ProDeviceCategorySelector/ProDeviceCategorySelector';
import {ProjectContext} from '../../../store/Project/ProjectContext';
import proDeviceUpdateUtils from '../DynamicDeviceCreate/utils/utils';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
  SuccessSnackbarActions,
  SuccessSnackbarOptions,
} from '../../../components/Alters/Snackbar/SnackbarOptions';

import proDeviceService from './services/pro-device.service';
import {ApolloAuthContext} from '../../../store/Apollo/ApolloContext';
import {FormResetAction, IFormResetRef} from './types';

import {DynamicDeviceCreateContainer} from '../DynamicDeviceCreate/DynamicDeviceCreate';
import ProDeviceNameCraeteComponent from '../DynamicDeviceCreate/DeviceFeatures/DeviceNameCreate/DeviceNameCreate';
import {IDeviceCategory} from '../../../config/deviceConfig/types';
import {LoadingComponent} from '../../../components/shared/Loading/Loading';
import {
  appApiErrorMessage,
  appConsoleError,
  appConsoleLog,
  vDelay,
} from '../../../utility/appUtils';
import {proDeviceGetGraphQL} from '../../../services/pro-device/pro-device.service';
import {DeviceCollectionType} from '../../../components/UserViewComponents/UIItems/UVDeviceFinder/types';

import {proDCGetStatus} from '../../../services/pro-container/pro-container.service';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import Layout from '../../../components/layout/Layout';
import {PageHeader} from '../../../components/layout/PageHeader';
import {ITEM_DEFAULTS} from '../../../config/deviceConfig/item_defaults';
import {KnxBulkDeviceCreation} from './knx';
import LabelValueMapping from 'src/components/UserViewComponents/UIItems/Dialogs/ItemCreateDialog/shared/LabelValueMapping';

const defaultMap = (): any => ({});
interface IState {
  dc?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    marginTop: '5px',
    padding: '2%',
    overflow: 'scroll',
  },
}));

export const ProDeviceCreateEdit: React.FunctionComponent<any> = () => {
  const snackbar = useSnackbar();
  const navgiate = useNavigate();

  const userProfile = useContext(ApolloAuthContext);
  const selectedProjectID = useQueryString('projectId') as string;
  const selectedDCID = useQueryString('dcId') as string;
  const selectedDeviceId = useQueryString('deviceId') as string;
  const dcName = useQueryString('dcName');
  const catType = useQueryString('dcType') as string;
  const isKnxGatewayExist = useQueryString('isKnxGatewayExist') === 'true';
  const isKnxGateway = useQueryString('knxGateway') === 'true';

  // alert(catType);

  const selectedProjectId = selectedProjectID || '';

  const navigateRoute = (path: string) => {
    navgiate(path);
  };

  const getDefaultDeviceInfo = () =>
    proDeviceUpdateUtils.defaultProDeviceInfo(
      selectedProjectId,
      selectedDCID,
      selectedDeviceId,
      [],
      {}
    );
  const [cat, setCat] = useState(
    proDeviceUpdateUtils.defaultCategory(
      dcName.indexOf('- variables') > -1
        ? ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows.location_variables.value
        : ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows.gpio_items.value
    )
  );
  const [formValidate, detectFormValidate] = useState(0);

  const [infoValidations, setInfoValidations] = useState(defaultMap());
  const [deviceInfo, setDeviceInfo] = useState(getDefaultDeviceInfo);
  const [valueMap, setValueMap] = useState<{
    [key: string]: {
      label: string;
      value: string;
    }[];
  }>();

  useEffect(() => {
    if (deviceInfo && deviceInfo.settings && deviceInfo.settings.label_value_map) {
      setValueMap(deviceInfo.settings.label_value_map);
    }
    console.log('--', deviceInfo);
    if (deviceInfo && deviceInfo.settings && deviceInfo.settings.hdc) {
      setEnergyMonitoring(deviceInfo.settings.hdc?.f1?.enabled || false);
      setScale(deviceInfo.settings.hdc?.f1?.scale || 1);
    }
  }, [deviceInfo]);

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [hubStatus, setHubStatus] = useState('');
  const projectCheck = () => {
    if (!selectedProjectID) {
      navigateRoute('/app');
    }
  };

  const hubAvaibility = async () => {
    try {
      const res = await proDCGetStatus(userProfile.apollo_client, selectedDCID);
      if (res.data && res.data.proDCGetStatus) {
        setHubStatus(res.data.proDCGetStatus.update_status);
      } else {
        throw new Error();
      }
    } catch (err) {
      setHubStatus('unkown');
      appConsoleLog(err);
    }
  };

  useEffect(() => {
    projectCheck();
    if (selectedDCID) {
      hubAvaibility();
    } else {
      navigateRoute('/app/device-containers');
    }
  }, [selectedDCID]);

  const [resetRef, resetFormsAgain] = useState<IFormResetRef>({
    ref: 0,
    action: '-',
    info: {},
  });

  const catChangeHandler = (event: IDeviceCategory) => {
    setCat(event);
    appConsoleLog('selected cat', event);
    resetForm();
  };

  const [payload, detectPayloadPush] = useState<any>(null);
  const initialInfoValidations = useRef(defaultMap);
  const initialDeviceInfo = useRef(getDefaultDeviceInfo);
  const [showNameComp, setShowNameComp] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDetectInitialPayload = (payload: any, flushToState?: boolean) => {
    if (!payload) {
      return;
    }
    if (payload.action === 'data') {
      const {data} = payload;

      initialInfoValidations.current[payload.form] = payload.errors;

      const oldInitialDeviceInfo = cloneDeep(initialDeviceInfo.current);
      initialDeviceInfo.current = proDeviceUpdateUtils.updateProDeviceInfoOnlyPresentData(
        oldInitialDeviceInfo,
        data
      );
    }

    if (flushToState) {
      setInfoValidations(initialInfoValidations.current);
      setDeviceInfo(initialDeviceInfo.current);
      setShowNameComp(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDetectPayloadByUserInteraction = (payload: any) => {
    if (!payload) {
      return;
    }
    if (payload.action === 'data') {
      const {data} = payload;

      setInfoValidations((oldInfoValidations: any) => {
        // eslint-disable-next-line no-param-reassign
        const copy = cloneDeep(oldInfoValidations);
        copy[payload.form] = payload.errors;
        return copy;
      });
      setDeviceInfo((oldDeviceInfo: any) => {
        const newInfo = proDeviceUpdateUtils.updateProDeviceInfoOnlyPresentData(
          cloneDeep(oldDeviceInfo),
          data
        );

        return newInfo;
      });
    }
  };

  useEffect(() => {
    if (!payload) {
      return;
    }
    appConsoleLog('ACTION', payload);
    let newInfo = deviceInfo;
    let newInfoValidations = infoValidations;
    if (payload.action === 'data') {
      const {data} = payload;

      setInfoValidations((oldInfoValidations: any) => {
        // eslint-disable-next-line no-param-reassign
        oldInfoValidations[payload.form] = payload.errors;
        newInfoValidations = oldInfoValidations;
        return oldInfoValidations;
      });
      setDeviceInfo((oldDeviceInfo: any) => {
        newInfo = proDeviceUpdateUtils.updateProDeviceInfoOnlyPresentData(oldDeviceInfo, data);

        return newInfo;
      });
    }

    // DON'T Delete
    // // appConsoleLog("last validation:", currentValidation);
    // appConsoleLog("CAT from change event", cat);
    // // appConsoleLog("last data:", newInfo);
    // // let FieldNames: string[] = [];
    // // if (cat.isItem && cat.config != null && cat.config.features) {
    // //   FieldNames = Object.keys(cat.config.features.fields);
    // //   FieldNames.push("name_update");
    // //   // appConsoleLog("CAT VALID", cat);
    // // } else {
    // //   // appConsoleLog("CAT INVALID", cat);
    // // }

    // // appConsoleLog(FieldNames);
    // // appConsoleLog(dataCollected);
  }, [payload]);

  const resetForm = async () => {
    // reset all fields to default
    setDeviceInfo(
      proDeviceUpdateUtils.defaultProDeviceInfo(
        selectedProjectId,
        selectedDCID,
        selectedDeviceId,
        cat.config?.features?.tags,
        {}
      )
    );
    setInfoValidations({});
    initialDeviceInfo.current = proDeviceUpdateUtils.defaultProDeviceInfo(
      selectedProjectId,
      selectedDCID,
      selectedDeviceId,
      cat.config?.features?.tags,
      {}
    );
    initialInfoValidations.current = defaultMap();
    setTimeout(() => {
      resetFormsAgain({
        ref: Date.now(),
        action: FormResetAction.toDefault,
        info: {},
      });
    }, 200);
  };

  // loading device for edit
  useEffect(() => {
    if (!selectedDeviceId || selectedDeviceId === '') {
      return;
    }
    const fetch = async () => {
      try {
        const deviceD = await proDeviceGetGraphQL(userProfile.apollo_client, selectedDeviceId);
        const device = JSON.parse(JSON.stringify(deviceD));
        device.settings = JSON.parse(device.settings);
        appConsoleLog('device', device);

        // reset all fields to default
        const info = proDeviceUpdateUtils.defaultProDeviceInfo(
          selectedProjectId,
          selectedDCID,
          selectedDeviceId,
          cat.config?.features?.tags,
          device
        );
        const itemCatInfo = proDeviceUpdateUtils.getFieldsConfigFromCat(info.cat, info.sub_cat);
        console.log('EDITTTT', info);
        setCat({
          category: info.cat,
          subCategory: info.sub_cat,
          sub3Category: '',
          config: itemCatInfo.config,
          isItem: itemCatInfo.isItem,
        });
        // setTimeout(() => {
        setDeviceInfo(info);
        resetFormsAgain({
          ref: Date.now(),
          action: FormResetAction.toCurrentInfo,
          info,
        });

        // }, 50);
      } catch (err: any) {
        appConsoleError(err);
        let errorMessage = 'Cannot load connect device info';
        if (typeof err === 'string') {
          errorMessage = err;
        } else if (err && err.message) {
          errorMessage = appApiErrorMessage(err.message);
        }
        snackbar.enqueueSnackbar(errorMessage, {
          key: 'pro-device-load-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      }
    };

    fetch();
  }, [selectedDeviceId]);

  const submitForm = async () => {
    setLoading(true);
    // vDelay(800);
    try {
      if (!(cat.isItem && cat.config != null && cat.config.features)) {
        throw new Error('Please select device from device browser');
      }
      if (
        !isKnxGateway &&
        isKnxGatewayExist &&
        cat.category === 'knx' &&
        cat.subCategory === 'knx_gateway'
      ) {
        throw new Error(
          'Knx gateway is already exist. You can only create one knx gateway per hub.'
        );
      }
      if (
        !isKnxGatewayExist &&
        cat.category === 'knx' &&
        (cat.subCategory === 'knx_dimmer' ||
          cat.subCategory === 'knx_switch' ||
          cat.subCategory === 'knx_light')
      ) {
        throw new Error('Please create a knx gateway first.');
      }
      let updatingDevice = JSON.parse(JSON.stringify(deviceInfo));
      updatingDevice.cat = cat.category;
      updatingDevice.sub_cat = cat.subCategory;
      updatingDevice.sub_cat = cat.subCategory;
      updatingDevice.shadow_type = cat.config?.features?.shadow_type;
      updatingDevice.device_type = cat.config?.value;

      if (cat.config?.features?.device_extra_fields) {
        const extraData = cat.config?.features?.device_extra_fields;
        updatingDevice = {
          ...updatingDevice,
          ...extraData,
        };
      }
      const validationKeys = Object.keys(infoValidations);
      let msg = '';
      let isValidForm = true;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < validationKeys.length; i++) {
        const key = validationKeys[i];
        const childKeys = Object.keys(infoValidations[key]);

        if (childKeys.length > 0) {
          msg = infoValidations[key][childKeys[0]];

          isValidForm = false;
          if (msg && msg.startsWith('PRINT')) {
            throw msg.replace('PRINT', '');
          }
          msg = `invalid data for:${key}`;

          break;
        }
      }

      // //Deleting removed fields
      const settingsKeys = Object.keys(updatingDevice.settings);
      settingsKeys.forEach(key => {
        if (updatingDevice.settings[key] === 'null' || updatingDevice.settings[key] == null) {
          delete updatingDevice.settings[key];
        }
      });
      appConsoleLog('updatingDevice', updatingDevice);
      // return;
      if (!isValidForm) {
        throw new Error('Please fill all required fields');
      }

      // Append value map to the settings
      if (valueMap) {
        updatingDevice.settings.label_value_map = {
          ...valueMap,
        };
      }

      // set energy monitoring setting
      updatingDevice.settings.hdc = {
        f1: {
          scale,
          k: 'state',
          enabled: energyMonitoring,
        },
      };

      if (selectedDeviceId && selectedDeviceId !== '') {
        const res = await proDeviceService.proDeviceUpdate(
          userProfile.apollo_client,
          updatingDevice
        );
        snackbar.enqueueSnackbar('Connect device updated', {
          key: 'pro-device-update-success',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        });
        resetForm();
        navigateRoute(
          `/app/device-container/pro?projectId=${selectedProjectID}&dcId=${selectedDCID}&name=${dcName}`
        );
      } else {
        appConsoleLog('creating', updatingDevice);
        const res = await proDeviceService.proDeviceCreate(
          userProfile.apollo_client,
          updatingDevice
        );
        snackbar.enqueueSnackbar('Connect device created', {
          key: 'pro-device-create-success',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        });
        resetForm();
        navigateRoute(
          `/app/device-container/pro?projectId=${selectedProjectID}&dcId=${selectedDCID}&name=${dcName}`
        );
      }
    } catch (err: any) {
      appConsoleError(err);
      let errorMessage = 'Unexpected error';
      if (typeof err === 'string') {
        errorMessage = err;
      } else if (err && err.message) {
        errorMessage = appApiErrorMessage(err.message);
      }
      snackbar.enqueueSnackbar(errorMessage, {
        key: 'pro-device-update-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const [showKnxDialog, setShowKnxDialog] = useState(false);

  const [energyMonitoring, setEnergyMonitoring] = useState(false);
  const [scale, setScale] = useState(1);

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            <span style={{marginRight: '5px'}}>
              Connect Device {selectedDeviceId ? 'Update' : 'Create'}
            </span>

            {hubStatus !== 'online' && (
              <span style={{fontSize: '13px', fontWeight: 400}}>
                The device collection is{' '}
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 600,
                    verticalAlign: 'top',
                    color: hubStatus === 'online' ? 'green' : 'gray',
                  }}
                >
                  {hubStatus}
                </span>{' '}
                you may not able to create new devices at the moment
              </span>
            )}
          </Typography>
          {isKnxGatewayExist && (
            <KnxBulkDeviceCreation
              key={showKnxDialog + ''}
              open={showKnxDialog}
              toggleOpen={value => setShowKnxDialog(value)}
              dcId={selectedDCID}
              projectId={selectedProjectID}
            />
          )}
          {((deviceInfo && deviceInfo.cat === 'location_variables') ||
            (cat && cat.category === 'location_variables')) && (
            <LabelValueMapping
              mode="device"
              deviceType="location_variables"
              device={deviceInfo}
              initialMap={valueMap}
              key={JSON.stringify(valueMap)}
              appendSettings={({data}) => {
                setValueMap(data?.label_value_map);
              }}
            />
          )}
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className={`${styles.cont} row width-full-fixed`}>
          <div className="dynamic-ui-form-features col-md-12">
            <div className="row">
              <ProDeviceCategorySelector
                key={cat.category}
                category={cat.category}
                subCategory={cat.subCategory}
                sub3Category={cat.sub3Category}
                editable
                onChange={catChangeHandler}
                isVariableType={catType === '9'}
              />
            </div>
            <div className="row">
              <div className="col-md-12 ml-0 pl-8 pt-16">Features: </div>
              <div className="col-md-12 row ml-8">
                <Suspense fallback={<h1>Something Went Wrong :</h1>}>
                  <DynamicDeviceCreateContainer
                    key={cat.category}
                    deviceCollectionType={DeviceCollectionType.proDevice}
                    keyPrefix={0}
                    cat={cat}
                    formValidate={formValidate}
                    deviceInfo={deviceInfo}
                    resetRef={resetRef}
                    detectPayloadPush={(fieldPayload, isInitializing, flushToState) => {
                      if (isInitializing && (!selectedDeviceId || selectedDeviceId === '')) {
                        onDetectInitialPayload(fieldPayload, flushToState);
                      } else {
                        onDetectPayloadByUserInteraction(fieldPayload);
                      }
                    }}
                  />
                </Suspense>
              </div>
            </div>

            <div className="row mt-16m">
              <ProDeviceNameCraeteComponent
                canEditName
                canEditIotId
                projectId={selectedProjectId}
                tags={deviceInfo.tags}
                name={deviceInfo.name}
                validateForm={formValidate}
                resetForm={resetRef}
                // eslint-disable-next-line @typescript-eslint/no-shadow
                onChange={(payload: any, isInitializing) => {
                  detectPayloadPush(payload);
                }}
              />
              <div>
                <Typography variant="subtitle2">Energy Monitoring</Typography>
                <div style={{display: 'flex', padding: 5}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="energyMonitoring"
                        checked={energyMonitoring}
                        onChange={e => setEnergyMonitoring(e.target.checked)}
                      />
                    }
                    label="Enable energy monitoring"
                  />
                  <TextField
                    size="small"
                    variant="standard"
                    label="Scale"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      disabled: !energyMonitoring,
                    }}
                    value={scale}
                    onChange={e => setScale(Number(e.target.value))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              variant="contained"
              onClick={() => {
                detectFormValidate(Date.now());
                setTimeout(() => {
                  submitForm();
                }, 800);
              }}
              disabled={loading}
            >
              {loading ? 'Processing...' : selectedDeviceId ? 'Update' : 'Create'}
            </Button>
            <Box width={10} />
            <Button
              color='warning'
              variant='outlined'
              onClick={() => {
                resetForm();
              }}
            >
              Reset
            </Button>
            <Box width={10} />
            <Button color="primary" onClick={() => navgiate(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Layout>
  );
};

export default ProDeviceCreateEdit;
