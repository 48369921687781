import moment from 'moment';
import { useMemo } from 'react';

export const usePopulatedGraphData = (
  rawData: {
    x: number;
    y: {
      [range: string]: {
        cost: number;
        value: number;
        values?: {
          // for graph api 6
          a: number;
          b: number;
        };
      };
    };
  }[],
  resolution: 'hour' | 'day' | 'week',
  fillYWith: any = {},
  startUnix: moment.Moment,
  endUnix: moment.Moment,
) => {
  const popuated = useMemo(() => {
    const populatedArr: {
      x: number;
      y: {
        [range: string]: {
          cost: number;
          value: number;
          values?: {
            // for graph api 6
            a: number;
            b: number;
          };
        };
      };
    }[] = [];

    if (!Array.isArray(rawData) || rawData.length === 0) return populatedArr;

    const startDate = startUnix;
    const endDate = endUnix;
    const currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate, resolution)) {
      const currentDateUnix = currentDate;
      const rawDataIndex = rawData.findIndex(data => moment.unix(data.x).isSame(currentDateUnix, resolution));
      if (rawDataIndex !== -1) {
        populatedArr.push(rawData[rawDataIndex]);
      } else {
        populatedArr.push({
          x: currentDateUnix.unix(),
          y: fillYWith,
        });
      }
      currentDate.add(1, resolution);
    }

    return populatedArr;
  }, [rawData, startUnix, endUnix]);

  return popuated;
};
