/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Context and Reusable Compund component for Logic Stepper
 *
 * @author Maduka Dilshan
 */

import React, {
  createContext, useContext, useMemo, useReducer,
} from 'react';
import { Box } from '@mui/material';

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

export enum LogicStepsTypes {
  WHEN = 'when',
  CHECK = 'check',
  ACTION = 'action',
}

type LogicStepperStateType = {
  activeStep: number;
  canSkipStep: boolean;
  setActive?: (arg1: number) => void;
};

enum LOGIC_REDUCER_TYPES {
  INIT = 'INIT',
  SWITCH_ACTION = 'SWITCH_ACTION',
}

const INITIAL_LOGIC_STEPPER_STATE: LogicStepperStateType = {
  activeStep: 0,
  canSkipStep: false,
};

const LogicStepperContext = createContext<LogicStepperStateType>({
  ...INITIAL_LOGIC_STEPPER_STATE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive: (switchTo: number) => {},
});

const useLogicStepper = () => useContext(LogicStepperContext);

const LogicStepperContextReducer = (
  state: LogicStepperStateType,
  action: {type: string; payload: any},
) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIC_REDUCER_TYPES.INIT:
      return { ...state, ...payload };
    case LOGIC_REDUCER_TYPES.SWITCH_ACTION:
      return {
        ...state,
        activeStep: payload,
      };
    default:
      return state;
  }
};

const LogicStepper: React.FC<{
  initialActiveStep: number;
  canSkipStep: boolean;
  children: any;
}> = ({
  initialActiveStep = 0, canSkipStep = false, children, ...rest
}) => {
  const [state, dispatch] = useReducer(LogicStepperContextReducer, INITIAL_LOGIC_STEPPER_STATE);

  const setActive = (switchTo: number) => {
    if (switchTo === state.activeStep) return;

    // if (!state.canSkipStep) {
    //   if (switchTo >= state.activeStep) {
    //     if (state.activeStep + 1 != switchTo) return;
    //   } else {
    //     if (state.activeStep - 1 != switchTo) return;
    //   }
    // }
    console.log(switchTo, 'sd');
    dispatch({
      type: LOGIC_REDUCER_TYPES.SWITCH_ACTION,
      payload: switchTo,
    });
  };

  const values = useMemo(
    () => ({
      ...state,
      setActive,
    }),
    [setActive, state],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LogicStepperContext.Provider value={values} {...rest}>
      {children}
    </LogicStepperContext.Provider>
  );
};

export const LogicStep: React.FC<{
  children: (props: LogicStepperStateType) => React.ReactChild;
}> = ({ children }) => {
  const logicState = useLogicStepper();

  const getLogicStepperStateAndHelpter = () => ({
    ...logicState,
  });

  const renderProp = unwrapArray(children);
  return renderProp(getLogicStepperStateAndHelpter());
};

export default LogicStepper;
