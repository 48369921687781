/* eslint-disable no-nested-ternary */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component use to list device containers aka hubs
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to list device containers aka hubs
 * @author Dinuka Bandara
 * @since -
 */
import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip } from '@mui/material';

import { pluginHook } from 'src/store/Plugins/PluginProvider';
import parseCustomError from 'src/utility/parseError';
import { useSnackbar } from 'notistack';
import { APP_TEXT } from '../../../utility/AppTexts/DefaultAppText.utils';
import styles from './DeviceContainerCard.module.css';
import { UVConfirmDialog } from '../../UserViewComponents/UIItems/Dialogs/UVConfirmDialog/UVConfirmDialog';
import { proDCDeleteGraphQL } from '../../../services/pro-container/pro-container.service';
import { airDCDeleteGraphQL } from '../../../services/air-device/air-device.service';

import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import { appConsoleError } from '../../../utility/appUtils';
import { ConfirmDelete } from '../CustomDeleteModal/ConfirmDelete';

type ComponentProps = {
  id: string;
  title: string;
  type: number;
  img: any;
  isDemo: boolean;
  isVariable?: boolean;
  isCloudIntegration?: boolean;
  refreshList: () => void;
  onClick: () => void;
};

/**
 * Device Container Card
 *
 * @callback onClickCard
 * @callback onRefrechCalled
 * @param {Object} props                      component props
 * @param {String} props.id                   Id of the device container
 * @param {Strint} props.title                Name/Title of the Device container
 * @param {number} props.type                 Type that use to determine if the
 * container holds air pro devices or if it is a demo container or if holds variables
 * @param {String} props.img                  Icon that shows to uniquely identify Air,
 * Pro and Variable containers
 * @param {onClickCard} props.onClick         Fires when user click the card button
 * @param {onRefrechCalled} props.refreshList Callback fires after user delete the device
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const DeviceContainerCard: React.FunctionComponent<ComponentProps> = ({
  id,
  title,
  type,
  img,
  isDemo,
  isVariable = false,
  isCloudIntegration = false,
  refreshList,
  onClick,
}) => {
  const userProfile = useContext(ApolloAuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [deleteState, setDeleteState] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const deleteDCConfirm = async () => {
    try {
      if (isCloudIntegration) {
        await pluginHook.commonIntegrationService.deleteCloudDeviceCollection(id);
      } else if (type === 1) {
        // pro hub
        await proDCDeleteGraphQL(userProfile.apollo_client, id);
      } else {
        // air hub
        await airDCDeleteGraphQL(userProfile.apollo_client, id);
      }
      refreshList();
    } catch (err: any) {
      console.log(err.response.data);
      const errMsg = parseCustomError(err.response && err.response.data);
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuAction?: string) => {
    setAnchorEl(null);
    if (menuAction === 'delete') {
      setDeleteState(true);
    }
  };
  return (
    <div className={styles.root}>
      <div className={styles.actionsMenu}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleClose('')}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem disabled={isVariable} key="delete" onClick={() => handleClose('delete')}>
            Delete
          </MenuItem>
        </Menu>
        {deleteState && (
          <ConfirmDelete
            itemId={id}
            open={deleteState}
            title="Confirm to delete?"
            item="device collection"
            itemTextBoxLabel="Device Collection Name"
            name={title}
            setOpen={setDeleteState}
            delete={deleteDCConfirm}
          />
        )}
      </div>

      {/* <div
        className={styles.imgBgStyle}
        ></div> */}
      <div
        onKeyDown={(e) => e.key === '13' && onClick()}
        role="button"
        tabIndex={0}
        className={`${styles.imgBgStyle} col-md-12`}
        // className={styles.imgBgStyle}
        onClick={onClick}
      >
        <img alt={title} src={img} height="60px" />
      </div>
      <div
        className={styles.data}
        onClick={onClick}
        onKeyDown={(e) => e.key === '13' && onClick()}
        role="button"
        tabIndex={0}
      >
        <div className={`${styles.title} ml-1`}>
          {title}
          {isDemo && <Chip label="Demo" size="small" style={{ marginLeft: '5px' }} />}
        </div>
        <div className={`${styles.type} ml-1`}>
          {isCloudIntegration
            ? pluginHook.commonIntegrationService.getCloudIntegrationNameByType(type)
            : isVariable
              ? APP_TEXT.device_container.SMARTLIFE_LOCATION_VARIABLES
              : type === 1
                ? APP_TEXT.device_container.SMARTLIFE_HUB_ACCOUNT
                : APP_TEXT.device_container.SMARTLIFE_AIR_ACCOUNT}
        </div>
      </div>
    </div>
  );
};

DeviceContainerCard.defaultProps = {
  isVariable: false,
  isCloudIntegration: false,
};
