import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import FirebaseAuthFunctions from '../../../../store/Auth/providers/FirebaseAuthFunctions';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import { SuccessSnackbarActions, SuccessSnackbarOptions } from '../../../Alters/Snackbar/SnackbarOptions';

const ResendLink = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const resendEmailVerificationCode = async () => {
    try {
      await FirebaseAuthFunctions.SendEmailVerification();
      enqueueSnackbar(APP_TEXT.header.VERIFY_EMAIL_ALERT_SENT, {
        key: 'key1',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(closeSnackbar),
      });
    } catch (err: any) {
      enqueueSnackbar(err.message, {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const refreshPage = async () => {
    window.location.reload();
  };

  return (
    <Alert variant="filled" severity="error">
      {APP_TEXT.header.VERIFY_EMAIL_ALERT}
      <span
        role="button"
        tabIndex={0}
        className="textLink ml10 mr10"
        onClick={resendEmailVerificationCode}
        onKeyDown={(e) => {
          if (e.key === '13') {
            resendEmailVerificationCode();
          }
        }}
      >
        {APP_TEXT.header.RESEND_LINK}
      </span>
      {APP_TEXT.header.OR}
      <span
        role="button"
        tabIndex={0}
        className="textLink ml10"
        onClick={refreshPage}
        onKeyDown={(e) => {
          if (e.key === '13') {
            refreshPage();
          }
        }}
      >
        {APP_TEXT.header.REFRESH_PAGE}
      </span>
    </Alert>
  );
};

export default ResendLink;
