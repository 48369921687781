/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import {
  Alert, Autocomplete, Box, CircularProgress, Grid, TextField,
} from '@mui/material';
import {
  getListView,
  getMySmartLists,
  ListEntryDto,
  SmartListDao,
  SmartListDto,
} from '@smartlife/shared-logic';
import axios from 'axios';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { ListViewUserviewItemLinkType } from 'src/components/UserViewComponents/types/types';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';

export const SmartListsOtherItem: React.FC<{
  selectedItemId?: string;
  selectedSmartlistId?: string;
  onChange?: (smartListId: string, itemId: string, listView: SmartListDto) => void;
}> = ({ selectedItemId, selectedSmartlistId, onChange }) => {
  const { access_token } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const project = useContext(ProjectContext);

  const [itemId, setItemId] = useState(selectedItemId);
  const [smartListId, setSmartListId] = useState(selectedSmartlistId);

  const [smartLists, setSmartLists] = useState<SmartListDto[]>([]);
  const [listItems, setListItems] = useState<ListEntryDto[]>([]);

  const [selectedSmartList, setSelectedSmartList] = useState<any>();
  const [selectedListItem, setSelectedListItem] = useState<any>();

  console.log('>>>>>>>>', selectedSmartList, selectedListItem);

  useEffect(() => {
    // setSelectedSmartList({
    //   _id: '62cb0325a359f062fe39dbd7',
    //   project_id: 'YEe63Sdx0ME1c',
    //   name: 'Smartlife Web Todo List',
    //   type: 'todo',
    //   list_status: 'active',
    //   items: ['62e00241dabf145711c0eb79', '62e6804a1d2ef8ef21236f25'],
    //   list_view_meta: '{"note":"B","show_completed":true}',
    //   updated_at: 1660487555,
    //   created_at: 1657471781,
    //   __v: 0,
    // } as any);
    setSelectedSmartList(
      smartListId && isArray(smartLists) ? smartLists.find((item) => item._id === smartListId) : null,
    );
    setSelectedListItem(
      itemId && isArray(listItems) ? listItems.find((item) => item._id === itemId) : null,
    );
  }, [smartLists, listItems, smartListId, itemId]);

  const [listLoading, setLoading] = useState(false);

  const axiosClient = useMemo(
    () => axios.create({
      baseURL: `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/rest`,
      headers: {
        Authorization: `Bearer ${access_token.token}`,
      },
    }),
    [access_token],
  );
  const mounted = useRef(0);
  useEffect(() => {
    if (mounted.current === 0) {
      mounted.current = 1;
      return;
    }
    const listView = smartLists.find((item) => item._id === smartListId);
    onChange(smartListId, itemId, listView);
  }, [smartListId, itemId]);

  useEffect(() => {
    const controller = new AbortController();
    const init = async () => {
      try {
        setLoading(true);
        const res = await getMySmartLists(
          axiosClient as any,
          project.selected_project.id,
          controller.signal,
        );
        setSmartLists(res);
      } catch (err: any) {
        if (err.message !== 'cancelled') enqueueSnackbar('Failed to load smartlists', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    init();

    return () => controller.abort();
  }, [selectedItemId, selectedSmartlistId]);

  useEffect(() => {
    const controller = new AbortController();
    if (!smartListId) return;
    const init = async () => {
      try {
        setLoading(true);
        const res = await getListView(axiosClient as any, smartListId, controller.signal);
        setListItems(isArray(res.items) ? res.items : []);
      } catch (err: any) {
        if (err.message !== 'cancelled') enqueueSnackbar('Failed to load smartlists', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    init();

    // eslint-disable-next-line consistent-return
    return () => controller.abort();
  }, [smartListId]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Alert severity="info">
          Keep the SmartList Entry field empty to link the entire smartlist
        </Alert>
        <Box height={10} />
      </Grid>
      <Grid item md={6} sm={12}>
        <Autocomplete
          key={JSON.stringify(selectedSmartList)}
          onChange={(e, value: any) => {
            if (value) {
              setSmartListId(value._id);
            } else {
              setSmartListId(null);
              setItemId(null);
            }
          }}
          size="small"
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option: any) => (option as SmartListDto).name}
          options={smartLists}
          // loading={listLoading}
          value={selectedSmartList}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              label="Search SmartLists"
              // onChange={(e) => console.log('xcxcxcx', e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {listLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <Autocomplete
          key={JSON.stringify(selectedListItem)}
          onChange={(e, value: any) => {
            if (value) {
              setItemId(value._id);
            } else {
              setItemId(null);
            }
          }}
          size="small"
          disabled={!smartListId}
          getOptionLabel={(option) => option.name}
          options={listItems}
          loading={listLoading}
          value={selectedListItem}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              label="Search SmartList Entry"
              // onChange={(e) => console.log('xcxcxcx', e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {listLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
