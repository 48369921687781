/* eslint-disable camelcase */
import React from 'react';
import { useNavigate } from 'react-router';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { ListRouts, ListsGrids } from '@smartlife/common-ui';
import ListLayout from 'src/layout/ListLayout';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
});

const ListGrideView = () => {
  const styles = useStyle();
  const projectId = useQueryStringFirst('projectId');
  const navigate = useNavigate();
  return (
    <ListLayout>
      <ListsGrids
        onCreateList={() => navigate(`/app/list/${ListRouts.LIST_CREATE_SELECT_TYPE}?projectId=${projectId}`)}
        onPressLatestEntry={(entry_id, list_view_id) => navigate(`/app/list/${ListRouts.LIST_ADD_ITEM}?__li_item_id=${entry_id}&__li_view_id=${list_view_id}&__editing=1`)}
        onPressSmartItem={(list_view_id) => navigate(`/app/list/${ListRouts.LIST_VIEW}?__list_view_id=${list_view_id}&projectId=${projectId}`)}
      />
    </ListLayout>
  );
};

export default ListGrideView;
