import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import {
  Box, InputAdornment, Typography,
} from '@mui/material';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './ListUserViews.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { IconTextCardComponent } from '../../../../components/shared/IconTextCard/IconTextCard';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import { userviewSearchGraphQL } from '../../../../services/user-view/user-view.service';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import { appConsoleLog } from '../../../../utility/appUtils';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '2%',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
}));

export const ListUserViewsComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const navgiate = useNavigate();

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [resultCount, setResultCount] = useState('');
  const [userViewList, setUserViewList] = useState([]);
  const [keyword, keywordSet] = useState('');
  const [projectId, projectIdSet] = useState(28);
  const [accessLevel, accessLevelSet] = useState(-1);
  const [permissionType, permissionTypeSet] = useState(-1);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    // if (
    //   !selectedProject ||
    //   !selectedProject.selected_project ||
    //   !selectedProject.selected_project.id ||
    //   selectedProject.selected_project.id === 0
    // ) {
    //   navigateRoute("/app");
    // }
    if (!selectedProject.selected_project.id) {
      return;
    }
    userviewSearchGraphQL(
      userProfile?.apollo_client,
      selectedProject.selected_project.id,
      1,
      keyword,
      100,
      0,
    )
      .then((res) => {
        setResultCount(res.userviewSearch.result ? res.userviewSearch.result.length : 0);
        setUserViewList(res.userviewSearch.result ? res.userviewSearch.result : 0);
      })
      .catch((err) => {
        // alert("Something went wrong!");
      });
  }, [
    // accessLevel,
    // userViewList,
    // navigateRoute,
    // permissionType,
    selectedProject.selected_project.id,
    // userProfile?.apollo_client,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSearch = async (value: string) => {
    keywordSet(value);
    await userviewSearchGraphQL(
      userProfile?.apollo_client,
      selectedProject.selected_project.id || '',
      1,
      value,
      100,
      0,
    )
      .then((res) => {
        setResultCount(res.userviewSearch.result ? res.userviewSearch.result.length : 0);
        setUserViewList(res.userviewSearch.result ? res.userviewSearch.result : 0);
      })
      .catch((err) => {
        // alert("Something went wrong!");
      });
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            {APP_TEXT.user_view.All_TITLE}
            {' '}
            {'  '}
            <span style={{ opacity: 0.4 }}>{resultCount}</span>
            <span style={{ opacity: 0.4, fontSize: '14px' }}>
              {' '}
              {Number(resultCount) > 1 ? ' views' : ' view'}
            </span>
          </Typography>
          <Box>
            <DefaultButton
              disabled={loading}
              onClick={() => navigateRoute(
                `/app/user-view/create?projectId=${selectedProject.selected_project.id}`,
              )}
            >
              <AddIcon />
              {APP_TEXT.user_view.ADD_USER_VIEW}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className="row">
          <SearchBox>
            <TextField
              id="input-with-icon-textfield"
              placeholder={APP_TEXT.user_view.USER_VIEW_SEARCH}
              onKeyUp={(event: any) => {
                onSearch(event.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchBox>
        </div>

        <div className="row" style={{ marginTop: '2%' }}>
          {(!userViewList || userViewList.length === 0) && (
            <Box>
              <h2 className="custom-link1">No User Views Found!</h2>
            </Box>
          )}
          {userViewList.map((userVIew: any) => {
            const profileBgStyle: React.CSSProperties = {
              backgroundImage:
                "url('https://www.pinclipart.com/picdir/big/131-1315465_white-home-icon-png-home-mobile-icon-png.png')",
            };
            return (
              <div className="col-md-3 mt-1" key={userVIew.id}>
                <IconTextCardComponent
                  uvId={userVIew.id}
                  title={userVIew.name ? userVIew.name : 'N/A'}
                  imgBgStyle={profileBgStyle}
                  iconClass={userVIew.icon_name}
                  itemColor={userVIew.item_color}
                  onClick={() => {
                    appConsoleLog({
                      pathname: `/app/user-view?userViewId=${userVIew.id}&projectId=${userVIew.project_id}`,
                      state: { user_view: userVIew },
                    });
                    navgiate(
                      `/app/user-view?userViewId=${userVIew.id}&projectId=${userVIew.project_id}`,
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </Box>
    </Layout>
  );
};

export default ListUserViewsComponent;
