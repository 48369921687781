/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Log table
 *
 * @author Maduka Dilshan
 */

import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryNumber } from '../../../utility/CustomHooks/useQueryNumber';
import useQueryString from '../../../utility/CustomHooks/useQueryString';

// TODO: Move <Layout> and <PageHeader /> into shared component directory
import Layout from '../../../components/layout/Layout';
import { PageHeader } from '../../../components/layout/PageHeader';

import LogsTable from './components/LogsTable';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const LogsContainer = () => {
  const styles = useStyle();

  const uv_name = useQueryString('uv_name');
  const uv_id = useQueryString('uv_id') as string;
  const project_id = useQueryString('projectId') as string;

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>
            {uv_name}
            {' '}
            Log Information
          </Typography>
        </Box>
      </PageHeader>
      <div style={{ overflowX: 'scroll' }}>
        <LogsTable initial_uv_id={uv_id} project_id={project_id} />
      </div>
    </Layout>
  );
};
