/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 * @author Mayantha Jayawardena
 */
import {
  Box, TextField, InputAdornment, Grid, Typography, AlertTitle, Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

import { FiSearch, FiTrash } from 'react-icons/fi';
import { proDCLocationAndMemberSearchGraphQL } from 'src/services/pro-container/pro-container.service';
import { useNavigate } from 'react-router-dom';
import { MemberCardComponent } from '../../../../components/shared/MemberCard/MemberCard';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { MatProjectCard } from '../../../../components/shared/ProjectCard/MatProjectCard';
import { AppUtils, getPermissionString } from '../../../../utility/appUtils';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import GreenButton from '../../../../components/shared/buttons/AuthButton/GreenButton';

const useStyles = makeStyles({
  root: {
    width: '94%',
    margin: '0 auto',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1rem',
  },
});

const HubFinderBody: React.FC = () => {
  const style = useStyles();
  const textInput = React.useRef(null);
  const [keyword, setKeyword] = useState('');
  const userProfile = useContext(ApolloAuthContext);
  const [projectInfo, setProjectInfo] = useState(undefined);
  const [members, setMembers] = useState([]);
  const selectedProject = useContext(ProjectContext);

  const navgiate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const search = () => {
    proDCLocationAndMemberSearchGraphQL(userProfile?.apollo_client, keyword.trim())
      .then((res) => {
        if (
          res
          && res.proDCLocationAndMemberSearch
          && res.proDCLocationAndMemberSearch.location_info
        ) {
          setProjectInfo(res.proDCLocationAndMemberSearch.location_info);
          setMembers(res.proDCLocationAndMemberSearch.member_info);
        } else setProjectInfo(null);
      })
      .catch((err) => {
        setProjectInfo(null);
      });
  };

  const clear = () => {
    setKeyword('');
    setProjectInfo(undefined);
    textInput.current.value = '';
    setMembers([]);
  };

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const projectSelector = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    id: string,
    title: string,
    description: string,
    image: string,
    project_status: string,
    project_type: string,
    access_level: string,
    demo_mode: boolean,
    restore_bsid: string,
  ) => {
    await selectedProject.dispatch({
      selected_project: {
        id,
        title,
        description,
        // project_country_code: project.project_country_code,
        // project_timezone: project.project_timezone,
        image,
        project_status,
        project_type,
        access_level,
        demo_mode,
        restore_bsid,
      },
    });
    navigateRoute(`/app/project/dashboard?projectId=${id}`);
  };

  return (
    <div className={style.root}>
      <Box mt={3}>
        <Box>
          <SearchBox>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  size="medium"
                  style={{ width: '100%' }}
                  id="input-with-icon-textfield"
                  placeholder="Hub name, Hub Serial"
                  inputRef={textInput}
                  onChange={(event: any) => {
                    setKeyword(event.target.value);
                  }}
                  onKeyUp={(event: any) => {
                    setKeyword(event.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <ClearLeftIconButton
                  text={APP_TEXT.settings.FIND}
                  onClick={search}
                  color="green"
                >
                  <FiSearch />
                </ClearLeftIconButton>
              </Grid>
              <Grid item md={3}>
                <ClearLeftIconButton
                  text={APP_TEXT.settings.CLEAR}
                  onClick={clear}
                  color="red"
                >
                  <FiTrash />
                </ClearLeftIconButton>
              </Grid>
            </Grid>
          </SearchBox>
        </Box>
        <Box height={20} />
        <Box>
          {projectInfo && projectInfo.id ? (
            <Box>
              <Typography className={style.headerTitle}>{APP_TEXT.settings.HF_LABEL2}</Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={4}
                  xs={12}
                  style={{ marginBottom: '2%' }}
                  onClick={() => {
                    projectSelector(
                      projectInfo.id,
                      projectInfo.name,
                      projectInfo.description,
                      projectInfo.image,
                      projectInfo.access_level,
                      projectInfo.project_type,
                      projectInfo.project_status,
                      projectInfo.demo_mode,
                      projectInfo.restore_bsid,
                    );
                  }}
                >
                  <MatProjectCard
                    title={projectInfo.name}
                    description={projectInfo.description}
                    img={AppUtils.getStorageBucketFileUrl('photo', projectInfo.image)}
                  />
                </Grid>
              </Grid>
              <Typography className={style.headerTitle}>{APP_TEXT.settings.HF_LABEL3}</Typography>
              <Grid container spacing={2}>
                {members.map((member: any) => {
                  if (member) {
                    let profileBgStyle: React.CSSProperties = {
                      backgroundImage:
                      "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
                    };

                    if (member.profile_photo) {
                      profileBgStyle = {
                        backgroundImage:
                        `url("${
                          AppUtils.getStorageBucketFileUrl('photo', member.profile_photo)
                        }")`,
                      };
                    }
                    return (
                      <Grid
                        item
                        md={4}
                        xs={12}
                        style={{ marginBottom: '2%' }}
                      >
                        <MemberCardComponent
                          title={member.user_name ? member.user_name : member.user_email}
                          permissionType={getPermissionString(member.access_level)}
                          desciption={member.user_title ? member.user_title : 'N/A'}
                          profileBgStyle={profileBgStyle}
                        />
                      </Grid>
                    );
                  }
                  return <></>;
                })}

              </Grid>
            </Box>
          ) : projectInfo === null ? (
            <Box>
              <Alert severity="error">
                <AlertTitle>{APP_TEXT.settings.HF_LABEL4}</AlertTitle>
              </Alert>
            </Box>
          ) : (
            <Box />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default HubFinderBody;
