/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component use to list device containers aka hubs in Dashboard
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to list device containers aka hubs in Dashboard
 * @author Dinuka Bandara
 * @since -
 */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import styles from './DeviceContainerDashboardListItem.module.css';

type ComponentProps = {
  id: any;
  title: string;
  date: any;
  img: any;
  type: any;
  projectId: any;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

/**
 * Device Container Card uses in Dashboard
 *
 * @param {Object} props                      component props
 * @param {String} props.id                   Id of the device container
 * @param {Strint} props.title                Name/Title of the Device container
 * @param {number} props.type                 Type that use to determine if the
 * container holds air pro devices or if it is a demo container or if holds variables
 * @param {String} props.img                  Icon that shows to uniquely identify Air,
 * Pro and Variable containers
 * @param {number} props.date                 Last updated time of the device container in unix
 * @param {String} props.projectId            Location id that the dc belongs to.
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const DeviceContainerDashboardListItem: React.FunctionComponent<ComponentProps> = ({
  title,
  date,
  img,
  type,
  projectId,
  id,
}) => {
  const navigate = useNavigate();
  const matStyle = useStyles();
  return (
    <div
      role="button"
      tabIndex={0}
      className={matStyle.root}
      onClick={() => {
        navigate(
          type === 2
            ? `/app/device-container/air?projectId=${projectId}&dcId=${id}&name=${
              title || 'N/A'
            }`
            : `/app/device-container/pro?projectId=${projectId}&dcId=${id}&name=${
              title || 'N/A'
            }`,
        );
      }}
      onKeyDown={(e) => {
        if (e.key === '13') {
          navigate(
            type === 2
              ? `/app/device-container/air?projectId=${projectId}&dcId=${id}&name=${
                title || 'N/A'
              }`
              : `/app/device-container/pro?projectId=${projectId}&dcId=${id}&name=${
                title || 'N/A'
              }`,
          );
        }
      }}
    >
      <div className="">
        <div className={styles.circleAvatar}>
          <img alt={title} className={styles.img} src={img} height="20px" />
        </div>
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.date}>{date}</div>
      </div>
    </div>
  );
};
