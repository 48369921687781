import { Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import hubIcon from '../../../assets/images/icons/hub.png';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';

const useStyles = makeStyles({
  root: {
    width: '50%',
    // margin: '0 auto',
    textAlign: 'center',
    backgroundColor: 'white',
    position: 'absolute',
    padding: '5% 0',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
  },
  icon: {
    backgroundImage: 'url("../../../assets/images/hub.png")',
    height: 58,
  },
  title: {
    color: '#06454D',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '120%',
  },
});

export const EnodeDeviceCallback = () => {
  const vendor = useQueryStringFirst('vendor');
  const styles = useStyles();

  const onComplete = () => {
    window.opener.postMessage('done', location.origin);
  }

  return (
    <Box className={styles.root}>
      <img src={hubIcon} style={{ height: 40 }} />
      <Typography className={styles.title}>Enode {vendor} is ready to sync with Smartlife</Typography>
      <br />
      <Button variant="contained" color="primary" onClick={onComplete}>
        Done
      </Button>
    </Box>
  );
};
