import { DeviceCollectionSearchOutItem, AirDevice, ProDevice } from '@smartlife-redux-state/common';
import { CiDevice, CiDeviceContainer } from 'src/plugins/shared/services/cloud-integration.service';

/* eslint-disable camelcase */
export type ConfigurableContainerState = {
  [containerId: string]: ConfigurableContainerItem;
};

export interface ConfigurableContainerItem {
  dc: DeviceCollectionSearchOutItem | CiDeviceContainer;
  existingDevices: {[key: string]: AirDevice | ProDevice | CiDevice};
  availableDevices?: {[tuyaId: string]: AirAvaibleDevice};
  deviceMap: {
    asNew: {
      // dropped device id
      [oldDeviceId: string]: {
        // device that dropped from restore container
        device: DeviceFromRestoreContainer;
        mappings?: {
          pin?: string;
          channel?: any;
        };
      };
    };
    toExisting: {
      // the id of the device in configurable container
      [existingId: string]: {
        // device that dropped from restore container
        device: DeviceFromRestoreContainer;
        mappings?: {
          pin?: string;
          channel?: any;
        };
      };
    };
    toAvailable?: {
      [availableId: string]: {
        device: DeviceFromRestoreContainer;
        mappings?: {
          channel?: any;
        };
      };
    };
  };
}

export type DeviceFromRestoreContainer =
  | AirDeviceFromRestoreContainer
  | CintDeviceFromRestoreContainer
  | ProDeviceFromRestoreContainer;

export type CintDeviceFromRestoreContainer = CiDevice;

export type AirDeviceFromRestoreContainer = {
  airdc_id: string;
  cat: string;
  config: any;
  device_type: string;
  id: string;
  name: string;
  settings: any;
  shadow_type: string;
  sub_cat: string;
  tags: string[];
  tuya_device_id: string;
};

export type ProDeviceFromRestoreContainer = {
  cat: string;
  device_type: string;
  id: string;
  name: string;
  prodc_id: string;
  settings: any;
  shadow_type: string;
  sub_cat: string;
  system_tags: string[];
  tags: any[];
};

export type AirDeviceFromRestoreContainerSettings = {
  debounce: number;
  selected_functions: {
    [channel_id: string]: {
      code: string;
      desc: string;
      name: string;
      type: string;
      value: string;
    };
  };
};

export enum DEVICE_POSITIONS {
  TO_EXISTING = 'to_existing',
  AS_NEW = 'as_new',
  TO_AVAILABLE = 'to_available',
}

export interface AirAvaibleDevice {
  active_time: number;
  biz_type: number;
  category: string;
  create_time: number;
  icon: string;
  id: string;
  ip: string;
  local_key: string;
  model: any;
  name: string;
  node_id: any;
  online: boolean;
  owner_id: string;
  product_id: string;
  product_name: string;
  status: string;
  sub: boolean;
  time_zone: string;
  uid: string;
  update_time: number;
  uuid: string;
  __typename: string;
}
