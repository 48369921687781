/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Option for selector component use in userview item create dialog
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a custom option for a selector component
 * @author Mayantha
 * @since -
 */
import React from 'react';
import styles from './InterfaceSelector.module.css';
import notSelectedIcon from '../../../assets/images/icons/checkmark-not-selected.png';
import selectedIcon from '../../../assets/images/icons/checkmark-selected.png';
import selectedDisabledIcon from '../../../assets/images/icons/checkmark-selected-disabled.png';

type ComponentProps = {
  title: string;
  description: string;
  selected: boolean;
  onClick: any;
  disabled: boolean;
  selectedDisabled?: boolean;
};

/**
 * Custom Option component
 *
 * @param {Object} props                  component props
 * @param {String} props.title            Text displays in the Option
 * @param {String} props.description      Additional text to display
 * @param {Boolean} props.selected        flag use to check if the option is selected or not
 * @param {Boolean} props.disabled        flag use to disable clickable events
 * @param {Boolean} props.selectedDisable flag use for determine the correct image to display
 * @param {onClickCb} props.onClick       fire when user click on the option
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const InterfaceSelectorComponenet: React.FunctionComponent<ComponentProps> = ({
  title,
  description,
  selected,
  onClick,
  disabled,
  selectedDisabled,
}) => (
  <div
    role="button"
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === '13' && !disabled) {
        onClick();
      }
    }}
    onClick={disabled ? null : onClick}
    className={
        // eslint-disable-next-line no-nested-ternary
        `${disabled
          ? styles.projectTyleSelectorDisabled
          : selected
            ? styles.projectTyleSelectorSelected
            : styles.projectTyleSelectorNone
        } noselect ${
          styles.root}`
      }
  >
    <div className={styles.selectCont}>
      <div className={styles.textCont}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
      </div>
      <div className="flexSpacer" />
      <div className={styles.iconMain}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {selectedDisabled ? (
          <img alt={title} src={selectedDisabledIcon} height="25px" />
        ) : selected ? (
          <img alt={title} src={selectedIcon} height="25px" />
        ) : (
          <img alt={title} src={notSelectedIcon} height="25px" />
        )}
      </div>
    </div>
  </div>
);

InterfaceSelectorComponenet.defaultProps = {
  selectedDisabled: false,
};
