import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {useContext, useRef, useState} from 'react';
import {ExcelFilePicker} from 'src/components/shared/DragDropFile/DragDropFile';
import proDeviceService from '../services/pro-device.service';
import {ApolloAuthContext} from 'src/store/Apollo/ApolloContext';
import {useSnackbar} from 'notistack';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';
import {proDeviceSearchGraphQL} from 'src/services/pro-device/pro-device.service';
import {isArray} from 'lodash';

export const KnxBulkDeviceCreation: React.FC<{
  projectId: string;
  dcId: string;
  open: boolean;
  toggleOpen: (value: boolean) => void;
}> = ({dcId, projectId, open, toggleOpen}) => {
  const userProfile = useContext(ApolloAuthContext);

  const [devices, setDevices] = useState<
    {
      name?: string;
      knx_group_address_switch?: string;
      knx_group_address_switch_state?: string;
      knx_group_address_brightness?: string;
      knx_group_address_brightness_state?: string;
    }[]
  >([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);

  const {enqueueSnackbar} = useSnackbar();

  const errors = useRef<{name: string; error: string}[]>([]);

  function sleep(ms) {
    return new Promise(res => setTimeout(res, ms));
  }

  async function upload() {
    try {
      const existingKnxDevices = await getExistingKnxDevices();
      setIsUploading(true);

      for (const device of devices) {
        const payload = processPayload(device, dcId);

        if (
          isArray(existingKnxDevices) &&
          existingKnxDevices.find(item => item.name === device.name)
        ) {
          const found = existingKnxDevices.find(item => item.name === device.name);
          await editDevice({
            ...found,
            settings: JSON.parse(payload.settings),
            name: payload.name,
          });
        } else {
          await createDevice(payload);
        }

        setCompletedCount(count => count + 1);
        await sleep(2600);
      }

      if (errors.current.length > 0) {
        setShowErrors(true);
      } else {
        toggleOpen(false);
        enqueueSnackbar('Bulk upload completed', {variant: 'success'});
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    } finally {
      setIsUploading(false);
    }
  }

  function groupColumns(data: string[][]) {
    const grouped: {
      name?: string;
      knx_group_address_switch?: string;
      knx_group_address_switch_state?: string;
      knx_group_address_brightness?: string;
      knx_group_address_brightness_state?: string;
    }[] = [];
    data.map(row => {
      const knxPayload: {
        name?: string;
        knx_group_address_switch?: string;
        knx_group_address_switch_state?: string;
        knx_group_address_brightness?: string;
        knx_group_address_brightness_state?: string;
      } = {};
      row.map((column, i) => {
        switch (i) {
          case 0:
            knxPayload['name'] = column;
            break;
          case 1:
            knxPayload['knx_group_address_switch'] = column;
            break;
          case 2:
            knxPayload['knx_group_address_switch_state'] = column;
            break;
          case 3:
            knxPayload['knx_group_address_brightness'] = column;
            break;
          case 4:
            knxPayload['knx_group_address_brightness_state'] = column;
            break;
          default:
            break;
        }
      });
      grouped.push(knxPayload);
    });
    console.log(grouped);
    setDevices(grouped);
  }

  function processPayload(
    item: {
      name?: string;
      knx_group_address_switch?: string;
      knx_group_address_switch_state?: string;
      knx_group_address_brightness?: string;
      knx_group_address_brightness_state?: string;
    },
    dcId: string
  ) {
    const payload = {
      prodc_id: dcId,
      name: null,
      tags: [],
      system_tags: ['KNX', 'KNXDevice'],
      shadow_type: 'knx',
      cat: 'knx',
      sub_cat: null,
      device_type: null,
      settings: null,
    };

    if (item && !item.name) {
      throw new Error('No Name Found');
    }

    payload.name = item.name;

    if (item && item.knx_group_address_brightness && item.knx_group_address_brightness_state) {
      payload.sub_cat = 'knx_dimmer';
      payload.device_type = 'knx_dimmer';

      payload.settings = JSON.stringify({
        knx_group_address_switch: item.knx_group_address_switch,
        knx_group_address_switch_state: item.knx_group_address_switch_state,
        knx_group_address_brightness: item.knx_group_address_brightness,
        knx_group_address_brightness_state: item.knx_group_address_brightness_state,
        debounce: 500,
        position: 'vertical',
        positioncustom_selector_custom_value: '',
        volume_max: 100,
        volume_min: 0,
        volume_step: 2,
        show_controls: true,
      });
    } else if (item && item.knx_group_address_switch && item.knx_group_address_switch_state) {
      payload.sub_cat = 'knx_switch';
      payload.device_type = 'knx_switch';

      payload.settings = JSON.stringify({
        knx_group_address: item.knx_group_address_switch,
        knx_group_address_state: item.knx_group_address_switch_state,
        debounce: 500,
        show_state: false,
      });
    }

    return payload;
  }

  async function getExistingKnxDevices() {
    try {
      const res = await proDeviceSearchGraphQL(
        userProfile?.apollo_client,
        projectId,
        dcId,
        '',
        'knx',
        '',
        '',
        -1,
        [],
        [],
        '',
        500,
        0
      );

      const result = res.proDeviceSearch;

      if (!result) return [];

      if (isArray(result.result)) return result.result.filter(item => item.cat === 'knx');
    } catch (err) {}
  }

  async function createDevice(payload: any) {
    try {
      await proDeviceService.proDeviceCreate(userProfile.apollo_client, payload);
    } catch (err: any) {
      errors.current = [...errors.current, {name: payload.name, error: err.message}];
    }
  }

  async function editDevice(payload: any) {
    try {
      await proDeviceService.proDeviceUpdate(userProfile.apollo_client, payload);
    } catch (err: any) {
      errors.current = [...errors.current, {name: payload.name, error: err.message}];
    }
  }

  return (
    <div>
      <Button onClick={() => toggleOpen(true)}>KNX Bulk Upload</Button>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{isUploading ? 'Uploading....' : 'KNX Bulk Upload'}</DialogTitle>
        <DialogContent>
          {showErrors ? (
            <Box>
              <Alert severity="warning">Completed with errors!</Alert>
              <Box height={10} />
              {errors.current.map((err, index) => (
                <Box mb={1}>
                  {index + 1}. Name: {err.name} / Reason:{' '}
                  <span style={{color: 'red'}}>{err.error}</span>
                </Box>
              ))}
            </Box>
          ) : (
            <>
              <ExcelFilePicker onChange={groupColumns} />
              <Box height={10} />
              {isUploading && (
                <Typography>
                  Completed {completedCount} / {devices.length}
                </Typography>
              )}
              {!isUploading && devices.length > 0 && (
                <Alert severity="info">
                  Total {devices.length} devices in the file. Would you like to auto create them ?
                </Alert>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={showErrors || devices.length < 1 || isUploading}
            onClick={() => upload()}
          >
            Create Devices
          </Button>
          <Button onClick={() => toggleOpen(false)}>{showErrors ? 'Finish' : 'Cancel'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
