import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';

import { Done, Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Slide,
} from '@mui/material';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@mui/material/transitions';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../Alters/Snackbar/SnackbarOptions';
import uvService from '../../../service/uv.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { getFolderIdFromPathRef } from '../../../utils';
import { UVContext, UVReducerTypes } from '../../../UVContext';
import styles from './FolderCreateDialog.module.css';
import { UVIconSelector } from '../../UVIconSelector/UVIconSelector';
import { CustomSwitch } from '../../../../shared/Switch/Swtich';
import {
  IUserViewItem,
  UserViewItemCategory,
  UserViewItemNotificationType,
  UserViewItemVisibility,
} from '../../../types/types';
import { LoadingComponent } from '../../../../shared/Loading/Loading';
import { appConsoleError, appConsoleLog } from '../../../../../utility/appUtils';

type ComponentProps = {
  dialogOpen: boolean;
  dialogCloseEvent: (flag: boolean) => void;
  folderId?: string;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title-folder" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}
const Transition = React.forwardRef((
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);
interface FormType {
  // eslint-disable-next-line camelcase
  folder_name: string;
  visibility: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const FolderCreateDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
  folderId,
}) => {
  const [formDefaults, setFormDefaults] = useState({
    folder_name: 'New folder 01',
    visibility: true,
  });

  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);
  const formRef = useRef<any>();
  const [selectedIcon, selectNewIcon] = useState('calc-door2');
  const [selectedColor, selectNewColor] = useState('#FFFFFF');
  const [isLoading, setLoading] = useState(false);

  const isUpdate = folderId !== undefined && folderId.length > 0;
  const [currentFolderItem, setCurrentFolderItem] = useState<IUserViewItem | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        if (folderId === undefined || folderId.length < 1) {
          return;
        }
        const { item } = await uvService.userviewItemGet(userProfile.apollo_client, folderId);
        setCurrentFolderItem(item);

        setFormDefaults({
          folder_name: item.display_name,
          visibility: item.item_visibility === UserViewItemVisibility.show,
        });

        selectNewColor(item.item_color);
        selectNewIcon(item.icon_name);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Unexpacted error', {
          key: 'uv-folder-update-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      }
      appConsoleLog('set loading', false);
      setLoading(false);
    };
    if (isUpdate) {
      setLoading(true);
      init();
    }
  }, [folderId]);
  const onSubmitForm = async (values: FormType) => {
    try {
      const parentFolder = getFolderIdFromPathRef(userViewContext.userViewPath);
      const { projectId, userViewId } = userViewContext;

      if (isUpdate) {
        const folderItem: any = {
          display_name: values.folder_name.trim(),
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          item_notification_type: currentFolderItem?.item_notification_type,
          rule_name: currentFolderItem?.rule_name,
          macro_name: currentFolderItem?.macro_name,
          icon_name: selectedIcon,
          item_color: selectedColor,
        };
        if (folderId === undefined) {
          throw new Error('Folder id not valid');
        }
        await uvService.userviewUpdateFolder(userProfile.apollo_client, folderId, folderItem);
      } else {
        const folderItem: any = {
          project_id: projectId,
          userview_id: userViewId,
          display_name: values.folder_name.trim(),
          parent_item_id: parentFolder === '' /* <1 */ ? null : parentFolder,
          pro_device_id: null,
          air_device_id: null,
          item_cat: UserViewItemCategory.folder,
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          item_notification_type:
            UserViewItemNotificationType.TextWithPushNotificationAndAlertSound,
          rule_name: '',
          macro_name: '',
          icon_name: selectedIcon,
          item_color: selectedColor,
          settings: null,
          config: null,
        };
        await uvService.userviewItemCreate(userProfile.apollo_client, folderItem);
      }

      snackbar.enqueueSnackbar(isUpdate ? 'Folder updated' : 'Folder created', {
        key: 'uv-folder-create-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });

      formRef.current.resetForm();
      dialogCloseEvent(true);
    } catch (err) {
      appConsoleError(err);

      snackbar.enqueueSnackbar(isUpdate ? 'Cannot update folder' : 'Cannot create folder', {
        key: 'uv-folder-create-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    } finally {
      setFormDefaults({
        folder_name: 'New folder 01',
        visibility: true,
      });
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      onClose={() => {
        dialogCloseEvent(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title-folder">
        {isUpdate ? 'Update Folder' : 'Add a Folder'}
      </DialogTitle>
      <DialogContent>
        {!isUpdate || (isUpdate && !isLoading) ? (
          <div className="row">
            <div className="col-md-12 pl-0 pb-0">
              <Formik
                initialValues={formDefaults}
                validationSchema={Yup.object().shape({
                  folder_name: Yup.string().trim()
                    .required('Please enter the name')
                    .max(24, 'Maximum folder name length 24'),
                })}
                validateOnChange
                onSubmit={onSubmitForm}
                innerRef={formRef}
              >
                {({
                  errors, handleBlur, handleChange, touched, values,
                }) => {
                  appConsoleLog(values);
                  return (
                    <>
                      <div className={styles.textInput} style={{ marginTop: '5px' }}>
                        <TextField
                          className="uv-ui-form-control-full-width"
                          error={Boolean(touched.folder_name && errors.folder_name)}
                          helperText={touched.folder_name && errors.folder_name}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.folder_name}
                          name="folder_name"
                          label="Name"
                          size="small"
                          variant="outlined"
                        />
                      </div>

                      <FormControlLabel
                        className="pl-8"
                        control={(
                          <CustomSwitch
                            name="visibility"
                            value={values.visibility}
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              console.log('xcx', e.target.checked);
                              handleChange(e);
                            }}
                            // onChange={(event: any) => {
                            //   appConsoleLog("switch", event.target.value);
                            // }}
                          />
                        )}
                        label="Visibility"
                      />
                    </>
                  );
                }}
              </Formik>
            </div>
            <div className="col-md-12 pl-0 pb-0">
              <div>
                <UVIconSelector
                  initialIcon={selectedIcon}
                  initialColor={selectedColor}
                  onChange={(icon, color) => {
                    appConsoleLog('selected Icon:', icon);
                    selectNewIcon(icon);
                    selectNewColor(color);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <LoadingComponent color="#06454d" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            formRef.current.submitForm();
          }}
          color="primary"
        >
          <Done fontSize="large" />
          {isUpdate ? 'Update' : 'Create'}
        </Button>
        <Button
          onClick={() => {
            appConsoleLog('isUpdate', folderId, isUpdate);
            dialogCloseEvent(false);
          }}
          color="primary"
        >
          <Close fontSize="large" />
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FolderCreateDialog.defaultProps = {
  folderId: null,
};
