/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component which use to list projects/locations
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to use list projects/locations
 * @author Maduka Dilshan
 * @since -
 */
import React from 'react';
import {
  Card, CardActionArea, CardContent, CardMedia, Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  img: {
    width: '100%',
    height: 'auto',
    padding: '2px',
    borderRadius: '4px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'var(--primary-dark-green)',
  },
  desciption: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '13px',
    marginTop: '5px',
    color: 'var(--utility-gray)',
  },
});

/**
 * Card component which use to list projects aka locations
 *
 * @param {Object} props                  component props
 * @param {String} props.title            Text displays in the Option
 * @param {String} props.desciption       Additional text to display
 * @param {String} props.image            location image url
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const MatProjectCard: React.FC<{
  title: string;
  description: string;
  img: string;
}> = ({ title, description, img }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia component="img" alt={title} className={classes.img} image={img} title={title} />
        <CardContent>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.desciption}>
            {description.length > 60 ? `${description.slice(0, 60)}....` : description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
