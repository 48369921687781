/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import styles from './UVAppBrowserItemSelector.module.css';
import { IUserViewItem } from '../../../types/types';
import { UVCreateCommDeviceId } from '../../../utils';
import { UVListSort } from '../UVListSort/UVListSort';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import uvService from '../../../service/uv.service';
import { useQueryNumber } from '../../../../../utility/CustomHooks/useQueryNumber';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { UVItemFinder } from '../../UVItemFinder/UVItemFinder';
import { IUVItem } from './types';
import useQueryString from '../../../../../utility/CustomHooks/useQueryString';

type ComponentProps = {
  fieldConfig: any;
  title: string;
  fieldKey: string;
  uvItem: IUserViewItem | null;
  onChange: (payload:any) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  updateCustomFieldErrors: (error: any) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVAppBrowserItemSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const userProfile = useContext(ApolloAuthContext);
  const userviewId = useQueryString('userViewId') as string;
  const { enqueueSnackbar } = useSnackbar();

  const [selectedCamera, setCamera] = useState<IUserViewItem>();
  const [selectedCameraList, setSelectedCameraList] = useState<IUserViewItem[]>([]);
  const [resetSelectedBtn, resetSelectedNow] = useState<number>();

  const loadCurrentInfo = async () => {
    try {
      if (props.uvItem && props.uvItem.settings && props.uvItem.settings[props.fieldKey]) {
        const cameraConfig: IUVItem = props.uvItem.settings[props.fieldKey];

        if (cameraConfig && cameraConfig.item_list) {
          const uvCameraIds = cameraConfig.item_list.map((item) => item.uv_item_id);
          console.log('props.fieldConfig', props.fieldConfig);
          console.log('cameraConfig', props.uvItem.settings, props.fieldKey, cameraConfig);

          const res = await uvService.searchUserviewItemsInUserview(
            userProfile.apollo_client,
            userviewId,
            props.fieldConfig.item_types,
            uvCameraIds,
            100,
            0,
          );

          console.log(res);
          const responseUVItemMap: any = {};
          if (res) {
            if (res.result && res.result.length > 0) {
              res.result.forEach((v) => {
                responseUVItemMap[`${v.id}`] = v;
              });
            }
          }
          const nMappedList = cameraConfig.item_list
            .map((cam) => {
              if (responseUVItemMap[cam.uv_item_id]) {
                return {
                  ...responseUVItemMap[cam.uv_item_id],
                };
              }
              return null;
            })
            .filter((v) => v != null);
          setSelectedCameraList(nMappedList);
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    loadCurrentInfo();
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.uvItem]);

  useEffect(() => {
    if (props.fieldConfig == null) {
      return;
    }
    const data: any = {};
    data[props.fieldKey] = {
      item_list: selectedCameraList.map((d) => ({ item_type: d.item_type, uv_item_id: d.id })),
    };

    props.onChange({
      action: 'data',
      form: props.fieldKey,
      data,
      errors: {},
    });
  }, [selectedCameraList]);

  const addSelectedItem = () => {
    if (selectedCamera) {
      const itsIn = selectedCameraList.findIndex((item) => selectedCamera.id === item.id);
      if (itsIn < 0) {
        const nlist = [...selectedCameraList, { ...selectedCamera }];
        setSelectedCameraList(nlist);
      }
      resetSelectedNow(Date.now());
      setCamera(undefined);
    }
  };

  return (
    <div className={`${styles.root} root-border`}>
      <UVItemFinder
        itemTypes={props.fieldConfig.item_types}
        defaultValue=""
        label={props.title}
        withCustomSearch={false}
        defaultItem={null}
        withBorder={false}
        resetNow={resetSelectedBtn}
        disabledList={selectedCameraList}
        onItemSelected={(device, isManually) => {
          setCamera(device);
        }}
      >
        <Button onClick={addSelectedItem} color="primary">
          <Add fontSize="large" />
          Add
        </Button>
      </UVItemFinder>
      <div className="row">
        <div className="col-md-12">
          <UVListSort
            selectedItems={selectedCameraList.map((item) => ({
              id: `${item.id}` || '',
              title: item.display_name,
              subTitle: item.display_path_ref,
              payload: item,
            }))}
            itemListChangeHandler={(items) => {
              setSelectedCameraList(items.map((i) => i.payload));
            }}
          />
        </div>
      </div>
    </div>
  );
};
