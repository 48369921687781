import { gql } from '@apollo/client';

export const PROJECT_INVITATION_LIST_QUERY = gql`
  query {
    projectInvitationsList {
      invitations {
        id
        name
        description
        image
        project_status
        project_type
        created_at
        updated_at
        perm_id
        last_updated
        access_level
        permission_status
      }
    }
  }
`;

export const PROJECT_INIVITATION_ACCEPT_MUTATION = gql`
  mutation ProjectInvitationAcceptReject(
    $permission_id: String
    $new_status: Int
  ) {
    projectInvitationAcceptReject(
      permission_id: $permission_id
      new_status: $new_status
    ) {
      status
    }
  }
`;
