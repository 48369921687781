import { useNavigate } from 'react-router-dom';
import { ArrowLeftCircle } from 'react-feather';
import { Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { KeyboardArrowDown } from '@mui/icons-material';

import styles from '../Header.module.css';
import { ISelectedProject } from '../../../../store/Project/types';

const useStyle = makeStyles((theme) => ({
  dropDown: {
    textTransform: 'capitalize',
  },
  avatar: {
    color: 'white',
    backgroundColor: '#673AB7',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LocationMenu
: React.FC<{
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement>>
    selectedProject: ISelectedProject
}> = ({ setAnchorEl, selectedProject }) => {
  const navigate = useNavigate();
  const matStyle = useStyle();

  const handleClickProjectSelect = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // setLocationList([]);
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={styles.headerLeft}>
      <div
        role="button"
        aria-label="Go Back"
        tabIndex={0}
        className={styles.headerIcon}
        onClick={() => {
          navigate(-1);
        }}
        onKeyDown={(e) => {
          if (e.key === '13') {
            navigate(-1);
          }
        }}
      >
        {' '}
        <ArrowLeftCircle height={60} width={30} color="#048696" />
        {' '}
      </div>
      <Tooltip title="Select a Location">
        <div
          role="button"
          aria-label="Go Home"
          tabIndex={0}
          className={styles.headerLocation}
          onClick={() => {
            // navgiate('/app');
            window.location.href = '/app';
          }}
          onKeyDown={(e) => {
            if (e.key === '13') {
              window.location.href = '/app';
            }
          }}
          style={{
            cursor: 'pointer',
          }}
        >
          {' '}
          Locations /
          {' '}
        </div>
      </Tooltip>
      <Button onClick={handleClickProjectSelect} className={matStyle.dropDown}>
        <div className={styles.itemLocation}>
          {selectedProject.selected_project.title ?? 'Select Location'}
        </div>
        <div className={styles.itemDropdown}>
          <Button
            color="primary"
            className={styles.userNameBtn}
            endIcon={<KeyboardArrowDown />}
            onClick={handleClickProjectSelect}
          >
            {/* {userProfile?.user_profile?.user_name} */}
          </Button>
        </div>
      </Button>
    </div>
  );
};
export default LocationMenu;
