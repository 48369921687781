import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { CLOUD_INTEGRATIONS } from 'src/plugins/shared/services/cloud-integration.service';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { useNavigate } from 'react-router';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import DCCreateSuccess from 'src/plugins/shared/react-components/DCCreateSuccess';
import { iEnodeIntegration } from '../enode.service';
import { VendorListConfiguration } from './VendorConfig';
import { DeviceContainerFormLayout } from 'src/plugins/shared/react-components/DeviceContainerFormLayout';

const useStyles = makeStyles({
  root: {
    paddingTop: 30,
  },
  formControl: {
    width: '100%',
  },
  textArea: {
    backgroundColor: '#F9F9F9',
    border: '1px solid #D9DFE0',
    borderRadius: 5,
    paddingLeft: 29,
    paddingRight: 29,
    paddingTop: 13,
    paddingBottom: 13,
  },
  btnContainer: {
    display: 'flex',
    paddingTop: 20,
  },
});

/* eslint-disable camelcase */
const CreateEnodeDeviceContainer: React.FC<{
  backup_view: boolean;
  onSuccessCreate?: (type: 'shelly', created: any) => void;
  integration: iEnodeIntegration;
}> = ({ integration }) => {
  const dcId = useQueryStringFirst('dcId');
  const showSync = Boolean(useQueryStringFirst('showSync'));
  const dcName = useQueryStringFirst('displayName');

  const styles = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const project = useContext(ProjectContext);

  const [displayName, setDisplayName] = useState(dcName || '');
  const [description, setDescription] = useState('');
  const [working, setWorking] = useState(false);
  const [disableInputs, setDisableInputs] = useState(false);
  const [dcCreatedSuccess, setDcCreatedSuccess] = useState(false);

  const createdDcId = useRef('');

  const cbPath = `/app/device-container/cloud-integration?projectId=${
    project.selected_project.id
  }&dcId=${createdDcId.current || dcId}&name=${displayName}&cint_type=3&tab=1`;

  const onContinue = async () => {
    try {
      setWorking(true);
      const res = await integration.createDeviceCollection({
        project_id: project.selected_project.id,
        name: displayName,
        description,
        cint_type: CLOUD_INTEGRATIONS.ENODE,
      });
      createdDcId.current = res.result.id;
      setDisableInputs(true);
      setDcCreatedSuccess(true);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setWorking(false);
    }
  };

  const onSuccessCallback = () => {
    navigate(cbPath);
  };

  if (dcCreatedSuccess) {
    return (
      <DeviceContainerFormLayout title={`Shelly Integration ${dcName ? `: ${dcName}` : ''}`}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90%',
          }}
        >
          <DCCreateSuccess onComplete={onSuccessCallback} />
        </div>
      </DeviceContainerFormLayout>
    );
  }

  return (
    <DeviceContainerFormLayout title={`Enode Integration ${dcName ? `: ${dcName}` : ''}`}>
      <Grid className={styles.root} container spacing={2}>
        <Grid item md={7} sm={12}>
          <Grid item md={12} sm={12}>
            <FormControl className={styles.formControl}>
              <TextField
                disabled={disableInputs}
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                label="Display Name"
                placeholder="Display Name"
                size="medium"
              />
            </FormControl>
          </Grid>
          <Box height={10} />
          <Grid item md={12} sm={12}>
            <FormControl className={styles.formControl}>
              <TextareaAutosize
                disabled={disableInputs}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={styles.textArea}
                aria-label="Description"
                placeholder="Description"
                minRows={5}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box className={styles.btnContainer}>
        <Button variant="contained" color="primary" disabled={working} onClick={onContinue}>
          Continue
        </Button>
        <Box width={15} />
        <Button
          style={{ color: '#7F8FA4', backgroundColor: '#F9F9F9' }}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </Button>
      </Box>
    </DeviceContainerFormLayout>
  );
};

export default CreateEnodeDeviceContainer;
