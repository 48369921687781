/* eslint-disable camelcase */
import { cloneDeep } from 'lodash';
import { ConfigurableContainerState, DEVICE_POSITIONS } from '../../../configurable-containers';
import { V2BStepackupInfoRoot } from '../../v2types';

/**
 * Example POST req / {
    "session_id": "61c55242b7b38277e69d29bf",
    "type": "cintd",
    "info_json": {
        "GsVziSrNV2o9f": {
             "1_GsVziSrNV2o9f_g0nwrSrVKDBug": {
                "new_id": "1_BLZhqSrwp24lf_Lqnp1SsUOP33g",
                "clone_type": "select_existing"
            },
             "1_GsVziSrNV2o9f_UuLcVSrwj0ohg": {
                "new_id": "",
                "clone_type": "disable"
            }
        }
    },
    "info_state": "redux info"
}
 */
function toAPIPostFriendyCloudDeviceInfoJson(
  info: V2BStepackupInfoRoot,
  config: ConfigurableContainerState,
  skipStep = false,
) {
  const apiFriendly = {};
  Object.keys(info.pendingComps).map((cloudContainerId) => {
    apiFriendly[cloudContainerId] = {};
    const templateContainer = info.pendingComps[cloudContainerId];

    // skipping whole step by disabling pending devices
    if (skipStep) {
      return Object.keys(templateContainer).map((templateDeviceId, index) => {
        apiFriendly[cloudContainerId][templateDeviceId] = {
          new_id: '',
          clone_type: 'disable',
        };
        return null;
      });
    }

    return Object.keys(templateContainer).map((templateDeviceId) => {
      const templateDevice = templateContainer[templateDeviceId];

      const clientMeta = templateDevice.clientSideMeta;

      if (clientMeta) {
        const currentDcId = clientMeta.dropped_contatainer_id;
        const { dropped_on } = clientMeta;

        // get channel mappings
        const customMappings = config[currentDcId].deviceMap;

        if (dropped_on) {
          // dropped on a existing device
          const deviceCloneInfo = {
            new_id: dropped_on,
            clone_type: 'select_existing_update',
          } as any;

          apiFriendly[cloudContainerId][templateDeviceId] = deviceCloneInfo;
        }
      }

      return null;
    });
  });

  return apiFriendly;
}

export default toAPIPostFriendyCloudDeviceInfoJson;
