/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/redux/store';

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

/**
 * A higher order component which implement with renderProps pattern.
 * The purpose of this function is to listen on the current firebase
 * value from the provided path and compair it to the current form value.
 *
 * @returns
 *
 * @author Dilshan
 */
// eslint-disable-next-line import/prefer-default-export
export const RealtimeValueCompartorComponent: React.FC<{
  path_to_check: string;
  current_value: any;
  children: (props: {isEqual: boolean}) => React.ReactChild;
}> = ({ path_to_check, current_value, children }) => {
  const firebase_value = useSelector((state: RootState) => {
    try {
      const patharray = path_to_check.split('/').filter((item) => item !== '' && item !== ' ');
      if (patharray.length === 5) {
        return state.firebase.data[patharray[0]][patharray[1]][patharray[2]][patharray[3]][
          patharray[4]
        ];
      }
      return null;
    } catch (err) {
      return null;
    }
  });

  useEffect(() => {
    console.log('@RealTIMEVAlueCom', current_value, firebase_value, path_to_check);
    if (current_value == null || firebase_value == null) {
      setIsEqual(false);
    } else if (current_value === firebase_value) {
      setIsEqual(true);
    } else if (isEqual === true) {
      setIsEqual(false);
    }
  }, [current_value, firebase_value]);

  const [isEqual, setIsEqual] = useState(false);
  const renderProp = unwrapArray(children);
  return renderProp({ isEqual });
};
