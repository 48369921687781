/* eslint-disable camelcase */
import { cloneDeep } from 'lodash';
import { ConfigurableContainerState, DEVICE_POSITIONS } from '../../../configurable-containers';
import { V2BStepackupInfoRoot } from '../../v2types';

/**
 * Example POST req / {
    "session_id": "61c55242b7b38277e69d29bf",
    "type": "lvar",
    "info_json": {
        "GsVziSrNV2o9f": {
            "1_GsVziSrNV2o9f_IJpmpSrNc5ung": {
                "new_id": "",
                "clone_type": "create_new",
                "data": {
                    "name":"test44444",
                    "settings":{
                        "state":12
                    }
                }
            },
             "1_GsVziSrNV2o9f_g0nwrSrVKDBug": {
                "new_id": "1_BLZhqSrwp24lf_Lqnp1SsUOP33g",
                "clone_type": "select_existing"
            },
             "1_GsVziSrNV2o9f_UuLcVSrwj0ohg": {
                "new_id": "",
                "clone_type": "disable"
            }
        }
    },
    "info_state": "redux info"
}
 */
function toAPIPostFriendyAirDeviceInfoJson(
  info: V2BStepackupInfoRoot,
  config: ConfigurableContainerState,
  skipStep = false,
) {
  const apiFriendly = {};
  Object.keys(info.pendingComps).map((airContainerId) => {
    apiFriendly[airContainerId] = {};
    const templateContainer = info.pendingComps[airContainerId];

    // skipping whole step by disabling pending devices
    if (skipStep) {
      return Object.keys(templateContainer).map((templateDeviceId, index) => {
        apiFriendly[airContainerId][templateDeviceId] = {
          new_id: '',
          clone_type: 'disable',
        };
        return null;
      });
    }

    return Object.keys(templateContainer).map((templateDeviceId) => {
      const templateDevice = templateContainer[templateDeviceId];

      const clientMeta = templateDevice.clientSideMeta;

      if (clientMeta) {
        const currentDcId = clientMeta.dropped_contatainer_id;
        const { dropped_on } = clientMeta;

        // get channel mappings
        const customMappings = config[currentDcId].deviceMap;

        if (!dropped_on) {
          // let editedSetting;
          // const channelMap = customMappings.asNew[templateDevice.old_id];
          // if (channelMap) {
          //   const { channel } = channelMap.mappings;
          //   const setting = cloneDeep(templateDevice.data.settings) as any;

          //   // if (pin) {
          //   //   setting.pin = pin;
          //   //   editedSetting = setting;
          //   // }
          // }
          // // dropped as a new
          // const deviceCloneInfo = {
          //   new_id: '',
          //   external_device_id: (templateDevice.data as any).tuya_device_id,
          //   new_dc_id: currentDcId,
          //   clone_type: 'create_new',
          // } as any;
          // if (editedSetting) {
          //   deviceCloneInfo.data = {};
          //   deviceCloneInfo.data.setting = editedSetting;
          // }

          // apiFriendly[airContainerId][templateDeviceId] = deviceCloneInfo;
        } else if (dropped_on && dropped_on.startsWith(DEVICE_POSITIONS.TO_AVAILABLE)) {
          // dropped on available device
          const deviceCloneInfo = {
            new_id: '',
            new_dc_id: currentDcId,
            external_device_id: dropped_on.split('to_available_')[1],
            clone_type: 'create_new',
            data: {
              settings: {
                ...templateDevice.data.settings,
              },
            },
          };

          apiFriendly[airContainerId][templateDeviceId] = deviceCloneInfo;
        } else {
          // dropped on a existing device
          const deviceCloneInfo = {
            new_id: dropped_on,
            clone_type: 'select_existing',
          } as any;

          apiFriendly[airContainerId][templateDeviceId] = deviceCloneInfo;
        }
      } else {
        // const deviceCloneInfo = {
        //   new_id: '',
        //   clone_type: 'disable',
        // };
        // apiFriendly[locVarContainerId][templateDeviceId] = deviceCloneInfo;
      }

      return null;
    });
  });

  return apiFriendly;
}

export default toAPIPostFriendyAirDeviceInfoJson;
