import React from 'react';
import { useNavigate } from 'react-router';
import { COLORS, ListRouts, ListView } from '@smartlife/common-ui';
import useQueryString from 'src/utility/CustomHooks/useQueryString';
import ListLayout from 'src/layout/ListLayout';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';

const ListSignleView = () => {
  const projectId = useQueryStringFirst('projectId');
  const listType = useQueryString('__ty');
  const uvItemId = useQueryString('__list_view_id') as unknown as string;

  const navigate = useNavigate();

  return (
    <ListLayout>
      <ListView
        uvId={uvItemId}
        onAddNewItem={() => navigate(`/app/list/${ListRouts.LIST_ADD_ITEM}?__li_view_id=${uvItemId}&__editing=0&projectId=${projectId}`)}
        onEditItem={(id) => navigate(
          `/app/list/${ListRouts.LIST_ADD_ITEM}?__li_item_id=${id}&__li_view_id=${uvItemId}&__editing=1&projectId=${projectId}`,
        )}
        onPressGearIcon={() => {
          navigate(`/app/list/${ListRouts.LIST_VIEW_SETTING}?__li_view_id=${uvItemId}&projectId=${projectId}`);
        }}
      />
    </ListLayout>
  );
};

export default ListSignleView;
