import { gql } from '@apollo/client';

export const DELETE_LOGGED_IN_USER = gql`
  mutation {
    userDelete {
      id
      delete_status
      message
    }
  }
`;

export const PROJECT_LIST_USERS_QUERY = gql`
  query ProjectListUsers(
    $project_id: String
    $access_level: Int
    $permission_status: Int
  ) {
    projectListUsers(
      project_id: $project_id
      access_level: $access_level
      permission_status: $permission_status
    ) {
      users {
        id
        fb_uid
        user_name
        profile_photo
        email
        invited_by
        user_status
        created_at
        updated_at
        perm_id
        last_updated
        user_email
        user_title
        access_level
        invite_to_userviews
        permission_status
      }
    }
  }
`;

export const PROJECT_USER_INFO = gql`
  query ProjectUserInfo($project_id: String, $user_email: String) {
    projectUserInfo(project_id: $project_id, user_email: $user_email) {
      user {
        id
        fb_uid
        user_name
        profile_photo
        email
        invited_by
        user_status
        created_at
        updated_at
      }
      project_permission {
        id
        created_by
        project_id
        user_id
        user_email
        user_title
        access_level
        permission_status
        invite_to_userviews
        permission_enabled
        last_updated
        created_at
        updated_at
      }
    }
  }
`;

export const PROJECT_INIVITE_MUTATION = gql`
  mutation ProjectInvite($input: InviteToProjectIn) {
    projectInvite(input: $input) {
      status
    }
  }
`;

export const PROJECT_USER_PERMISSION_UPDATE_MUTATION = gql`
  mutation ProjectUserPermissionUpdate($input: InviteToProjectIn) {
    projectUserPermissionUpdate(input: $input) {
      status
    }
  }
`;

export const PROJECT_REMOVE_USER_MUTATION = gql`
  mutation ProjectRemoveUser($project_id: String, $permission_id: String) {
    projectRemoveUser(project_id: $project_id, permission_id: $permission_id) {
      status
    }
  }
`;

export const CHECK_USER_IS_SUPER_ADMIN = gql`
  query CheckUserIsSystemAdmin{
    checkUserIsSystemAdmin {
      state
    }
  }
`;
