/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSnackbar } from 'notistack';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import clsx from 'clsx';
import uvService from '../../service/uv.service';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ErrorSnackbarOptions, ErrorSnackbarActions } from '../../../Alters/Snackbar/SnackbarOptions';
import { UVContext } from '../../UVContext';
import { IProDevice } from '../../../../containers/app/ProDeviceCreateEdit/types';

import styles from './UVDeviceFinder.module.css';
import { TagComponent } from '../../../../containers/app/ProDeviceCreateEdit/Tag/Tag';
import { DeviceCollectionType, ICommDevice } from './types';
import { LoadingLine } from '../../../shared/LoadingLine/LoadingLine';
import { UVCreateCommDeviceId, UVGetDeviceIdFromCommId } from '../../utils';
import { appConsoleError } from '../../../../utility/appUtils';
import { useQueryNumber } from '../../../../utility/CustomHooks/useQueryNumber';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';

type ComponentProps = {
  children?: any;
  systemTags: string[];
  defaultValue: string;
  label: string;
  withCustomSearch: boolean;
  freezed?: boolean;
  autoSelect?: boolean;
  defaultDevice?: ICommDevice | null;
  withBorder?: boolean;
  resetNow?: number;
  disabledList?: ICommDevice[];
  onDeviceSelected: (device: ICommDevice, manually: boolean) => void;
  selectedFromTree?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const UVDeviceFinder: React.FunctionComponent<ComponentProps> = ({
  disabledList,
  children,
  systemTags,
  defaultValue,
  label,
  withCustomSearch = false,
  freezed = false,
  defaultDevice = null,
  withBorder = true,
  resetNow = 0,
  autoSelect = false,
  onDeviceSelected,
  selectedFromTree,
}) => {
  const userProfile = useContext(ApolloAuthContext);
  const projectId = useQueryString('projectId') as string;

  const snackbar = useSnackbar();
  const [refresh, refreshNow] = useState(0);
  const [selectedTags, setTags] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selecteditem_id, setitem_id] = useState<string>(null); // pro_<id> or air_<id>
  const [selectedItem, setItem] = useState<ICommDevice>();
  const [lastRefreshRef, setLastRefreshRef] = useState<string>('');

  const idToDevice = () => {
    const itmIndex = items.findIndex(
      (item) => UVCreateCommDeviceId(item?.dc_type, item?.id) === selecteditem_id,
    );
    if (itmIndex > -1) {
      const item: any = { ...items[itmIndex] };
      setItem(item);
      onDeviceSelected(item, false);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setitem_id(defaultValue);
    }
    // else {
    //   setitem_id("");
    // }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultDevice) {
      setItem(defaultDevice);
    }
  }, [defaultDevice]);

  useEffect(() => {
    idToDevice();
  }, [selecteditem_id, items]);

  // useEffect(() => {
  //   if (autoSelect) {
  //     // debugger;
  //     if (items.length > 0) {
  //       const deviceKey = UVCreateCommDeviceId(items[0].dc_type, items[0].id);
  //       handleOnItemChange(deviceKey);
  //     }
  //   }
  // }, [items]);

  useEffect(() => {
    if (resetNow > 0) {
      setitem_id('');
      setItem(undefined);
    }
  }, [resetNow]);

  useEffect(() => {
    console.log('TREEEEEEEEEE', selectedFromTree);
    // setitem_id(selectedFromTree + "");
    if (selectedFromTree) handleOnItemChange(`${selectedFromTree}`);
  }, [selectedFromTree]);

  useEffect(() => {
    let timeout_fn_pointer;
    const init = async () => {
      const refreshRef = `${systemTags.join('')}${refresh}`;

      if (isLoading || lastRefreshRef === refreshRef) {
        return;
      }

      try {
        setLoading(true);

        const res = await uvService.searchProjectDevice(
          userProfile.apollo_client,
          projectId,
          systemTags,
          selectedTags,
          keyword,
          [],
          [],
          [],
          200,
          0,
        );
        const itms: any[] = [];

        res.pro_devices.forEach((element: any) => {
          element.dc_type = DeviceCollectionType.proDevice;
          itms.push(element);
        });
        res.air_devices.forEach((element: any) => {
          element.dc_type = DeviceCollectionType.airDevice;
          itms.push(element);
        });
        res.cint_devices.forEach((element: any) => {
          element.dc_type = DeviceCollectionType.cintDevice;
          itms.push(element);
        });
        await setItems(itms);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Cannot get device list', {
          key: 'uv-item-create-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
        appConsoleError(err);
      } finally {
        setLoading(false);
        setLastRefreshRef(`${systemTags.join('')}${refresh}`);
      }
    };
    init();
  }, [systemTags, selecteditem_id, refresh, selectedFromTree]);

  const handleOnItemChange = (deviceKey: string) => {
    const keyParts = UVGetDeviceIdFromCommId(deviceKey);
    const item = items.find(
      // eslint-disable-next-line max-len
      (itemPayload, idx) => itemPayload.id === keyParts.id && itemPayload.dc_type === keyParts.dc_type,
    );
    const commItem: any = { ...item };
    onDeviceSelected(commItem, true);
    setitem_id(deviceKey);
  };

  let itemPathDef = '';
  if (selectedItem !== undefined && selecteditem_id) {
    itemPathDef += `${selectedItem.dc_type}(${selectedItem.dc_name}) > `;
    if (selectedItem.cat) {
      itemPathDef += `${selectedItem.cat} > `;
    }
    if (selectedItem.sub_cat) {
      itemPathDef += `${selectedItem.sub_cat} > `;
    }
    if (selectedItem.device_type) {
      itemPathDef += `${selectedItem.device_type} > `;
    }
    if (selectedItem.name) {
      itemPathDef += selectedItem.name;
    }
  }

  const [open_dialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (items && items.length > 0 && autoSelect) {
      setOpenDialog(true);
    }
  }, [items]);

  return (
    <div className={clsx(styles.root, withBorder ? 'root-border' : '')}>
      <div className={styles.label}>
        {label}
        :
      </div>
      {!freezed && withCustomSearch && (
        <Grid container spacing={1}>
          <Grid item md={6}>
            <TagComponent
              label="Search by tags"
              projectId={projectId}
              tagsSelected={selectedTags}
              onChange={(newTags) => {
                setTags(newTags);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              className="dynamic-ui-form-control-full-width"
              size="small"
              id="outlined-basic"
              label="Keyword"
              variant="outlined"
              onChange={(event) => {
                setKeyword(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      )}
      {!freezed && (
        <>
          <div className={styles.searchButton}>
            <Button
              onClick={() => {
                refreshNow(Date.now());
              }}
              color="primary"
              variant="contained"
              size="small"
              startIcon={<Search fontSize="large" />}
              disabled={isLoading}
            >
              Search
            </Button>
            <LoadingLine isLoading={isLoading} />
          </div>
          <Divider />
        </>
      )}
      {!freezed ? (
        <div className={`col-md-12 pl-0 ${styles.selectBar}`}>
          <FormControl
            size="small"
            variant="outlined"
            className="dynamic-ui-form-control-full-width"
          >
            <FormHelperText>{label}</FormHelperText>
            <Select
              value={selecteditem_id}
              name="custom_selector"
              open={open_dialog}
              onOpen={() => setOpenDialog(true)}
              onClose={() => setOpenDialog(false)}
              onChange={(e: any) => {
                handleOnItemChange(e.target.value);
                setOpenDialog(false);
              }}
            >
              {items.map((item) => {
                const vkey = UVCreateCommDeviceId(item.dc_type, item.id);

                let disabled = false;
                if (disabledList) {
                  const itsIn = disabledList.findIndex((d) => {
                    const deviceKey = UVCreateCommDeviceId(d.dc_type, d.id);
                    return vkey === deviceKey;
                  });
                  if (itsIn > -1) {
                    disabled = true;
                  }
                }

                return (
                  <MenuItem key={vkey} value={vkey} disabled={disabled || item.device_status === 3}>
                    <ListItemText>
                      {item.device_status === 3 && '[DISABLED] ' }
                      {item.name}
                      {' '}
                      (
                      {item.dc_type}
                      -
                      {item.dc_name}
                      )
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {' '}
          {children}
        </div>
      ) : (
        <div />
      )}
      <div className="col-md-12 pl-0 ">
        {selectedItem ? <div className={styles.itemCatPath}>{itemPathDef}</div> : <div />}
      </div>
    </div>
  );
};
