/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Base Layout/container for the add Linked items to user view
 *
 * @author Maduka Dilshan
 */

// import UserViewService from '../../../../../service/uv.service';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { userviewSearchGraphQL } from '../../../../../../../services/user-view/user-view.service';
import { ApolloAuthContext } from '../../../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../../../store/Project/ProjectContext';
import FolderContent from './components/FolderContent';
import Folder from './components/Folder';
import Selectable, {
  SelectedPreview,
  useSelectedLinkedContext,
} from './components/SelectedLinkContext';
import { getFolderIdFromPathRef } from '../../../../../utils';
import { UVContext, UVReducerTypes } from '../../../../../UVContext';
import UVService from '../../../../../service/uv.service';
import useQueryString from '../../../../../../../utility/CustomHooks/useQueryString';
import { appConsoleLog } from '../../../../../../../utility/appUtils';

const UVLinkedItems: React.FC<{
  formRef: any;
  closeDialog: (result: boolean) => void;
}> = ({ formRef, closeDialog }) => {
  const selectedProject = useContext(ProjectContext);
  const userViewContext = useContext(UVContext);
  const userProfile = useContext(ApolloAuthContext);
  const destiationUvId = useQueryString('userViewId');
  const { enqueueSnackbar } = useSnackbar();
  const {
    setSelected, selected_item, selected_type, uv, folder_id,
  } = useSelectedLinkedContext();
  const [uvs, setUvs] = useState([]);

  const structureRef = useRef<any>();

  const onUVClicked = (
    type: number,
    // eslint-disable-next-line no-shadow
    uv: any,
    current_root: number,
    parent_id: number,
    child: any,
  ) => {
    setSelected({
      item: child, uv, folder_id: current_root, parent_id, type,
    });
  };

  const onSave = async () => {
    const parentFolder = getFolderIdFromPathRef(userViewContext.userViewPath);
    let settings: any;
    let data: any;

    const common: any = {
      project_id: selectedProject.selected_project.id,
      userview_id: destiationUvId, // uv.id,
      parent_item_id: parentFolder,
      pro_device_id: null,
      air_device_id: null,
      item_visibility: 1,
      item_notification_type: 1,
      macro_name: '',
      icon_name: 'calc_output',
      item_color: 'rgb(255, 255, 255)',
      config: JSON.stringify({}),
      rule_name: '',
    };

    switch (selected_type) {
      case 1:
        // selected item is an item
        settings = {
          uv_item_id: selected_item.id,
          uv_id: uv.id,
        };
        data = {
          display_name: `Shortcut Item ${selected_item.display_name}`,
          item_cat: 4,
          item_type: 'shortcut_item',
          settings: JSON.stringify(settings),
        };
        break;
      case 8:
        settings = {
          uv_item_id: selected_item.id,
          uv_id: uv.id,
        };
        data = {
          display_name: `Shortcut Other Item ${selected_item.display_name}`,
          item_cat: 4, // linked other items & items are same
          item_type: 'shortcut_item',
          settings: JSON.stringify(settings),
        };
        break;
      case 2:
        // selected item is a folder
        settings = {
          folder_id,
          uv_id: uv.id,
        };
        data = {
          display_name: `Shortcut Folder ${selected_item.display_name}`,
          item_cat: 3,
          item_type: 'shortcut_folder',
          settings: JSON.stringify(settings),
        };
        break;
      case 5:
        // selected item is a uv
        settings = {
          uv_id: uv.id,
        };

        data = {
          display_name: `Shortcut UV ${selected_item.display_name}`,
          item_cat: 5,
          item_type: 'shortcut_userview',
          settings: JSON.stringify(settings),
        };
        break;
      default:
        throw new Error('Unsupported Type');
      // unsupported type
    }

    try {
      await UVService.userviewItemCreate(userProfile.apollo_client, {
        ...common,
        ...data,
      });
      closeDialog(true);
      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });
      enqueueSnackbar('Shortcut created', { variant: 'success' });
    } catch (err:any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const res = await userviewSearchGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          1,
          '',
          100,
          0,
        );
        if (!res.userviewSearch) throw new Error('Failed to get user views');
        setUvs(res.userviewSearch.result);
      } catch (err) {
        appConsoleLog(err);
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={onSave}
        innerRef={formRef}
        style={{ display: 'none' }}
      />

      {uvs.length > 0 && (
        <div className="row" style={{ minWidth: '500px', overflow: 'hidden' }}>
          <div className="col-md-6" style={{ overflow: 'auto' }} ref={structureRef} id="struct_cont">
            {/* eslint-disable-next-line no-shadow */}
            {uvs.map((uv) => (
              <FolderContent
                uv={uv}
                current_root=""
                parent_root={null}
                child={{
                  display_name: uv.name,
                  icon_name: uv.icon_name,
                  item_color: uv.item_color,
                  item_cat: 5,
                }}
                key={uv.id}
                onClickItem={onUVClicked}
              >
                {/* eslint-disable-next-line max-len */}
                {({ uv, current_root, parent_root }: any) => <Folder current_root={current_root} uv={uv} parent_root={parent_root} />}
              </FolderContent>
            ))}
          </div>
          <div className="col-md-6">
            <SelectedPreview />
          </div>
        </div>
      )}
    </>
  );
};

const WithSelector: React.FC<any> = ({ ...rest }) => (
  <Selectable>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <UVLinkedItems {...rest} />
  </Selectable>
);

export default WithSelector;
