/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { appConsoleLog } from '../../utility/appUtils';

const userviewSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  userview_status: number,
  keyword: string,
  limit: number,
  skip: number,
): Promise<any> => {
  const res = await apolloClient.query({
    query: gql`
      query {
        userviewSearch(
          project_id: "${project_id}",
          userview_status: ${userview_status},
          keyword : "${keyword}",
          limit: ${limit},
          skip: ${skip},
        ){
            limit
            skip
            resultCount
            result{
              id
              created_by
              project_id
              name
              description
              userview_status
              lock_role3
              created_at
              updated_at
              icon_name
              item_color
            }
            permission{
              id
              created_by
              project_id
              user_id
              user_email
              user_title
              access_level
              permission_status
              invite_to_userviews
              last_updated
              created_at
              updated_at
            }
          }
        }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const listUsersInUserviewGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  userview_id: string,
  access_level: any,
  permission_status: any,
): Promise<any> => {
  const res = await apolloClient.query({
    query: gql`
      query {
        listUsersInUserview(
                  userview_id: "${userview_id}",
                  access_level: ${access_level},
                  permission_status: ${permission_status},
        ){
          users{
            id
            fb_uid
            user_name
            profile_photo
            email
            invited_by
            user_status
            created_at
            updated_at
            perm_id
            last_updated
            user_email
            user_title
            access_level
            permission_status
          }
        }
      }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const userviewGetGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  userview_id: string,
): Promise<any> => {
  const res = await apolloClient.query({
    query: gql`
      query {
        userviewGet(
          item_id: "${userview_id}",
        ){
          userview{
            id
            home_folder_id
            created_by
            project_id
            name
            description
            userview_status
            lock_role3
            created_at
            updated_at
            icon_name
            item_color
          }
          permission{
            id
            created_by
            project_id
            user_id
            user_email
            user_title
            access_level
            permission_status
            invite_to_userviews
            last_updated
            created_at
            updated_at
          }
        }
      }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const userviewCreateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  name: string,
  description: string,
  userview_status: number,
  lock_role3: any,
  icon_name: string,
  item_color: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
          userviewCreate(
                input:{
                  project_id: "${project_id}",
                  name:"${name}",
                  description: "${description}",
                  userview_status: ${userview_status},
                  lock_role3: ${lock_role3},
                  icon_name: "${icon_name}"
                  item_color: "${item_color}"
                }
          ){
            id
            created_by
            project_id
            name
            description
            userview_status
            lock_role3
            created_at
            updated_at
            icon_name
            item_color
          }
          }
        `,
  });
  appConsoleLog(res);
  return res;
};

const userviewUpdateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
  name: string,
  description: string,
  userview_status: number,
  lock_role3: any,
  icon_name: string,
  item_color: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
          userviewUpdate(
                input:{
                  item_id: "${item_id}",
                  item:{
                    name:"${name}",
                    description:"${description}",
                    userview_status: ${userview_status},
                    lock_role3: ${lock_role3},
                    icon_name: "${icon_name}"
                    item_color: "${item_color}"
                  }
                }
          ){
            id
            update_status
            updated_at
          }
        }
        `,
  });
  appConsoleLog(res);
  return res;
};

const userviewDeleteGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
      mutation {
        userviewDelete(
          item_id: "${item_id}",
        ){
          id
          delete_status
      }
    }
      `,
  });
  return res.data;
};

export {
  userviewSearchGraphQL,
  listUsersInUserviewGraphQL,
  userviewGetGraphQL,
  userviewCreateGraphQL,
  userviewUpdateGraphQL,
  userviewDeleteGraphQL,
};
