import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './CreateIntefaceTypeSelector.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { LoadingComponent } from '../../../../components/shared/Loading/Loading';
import GreenButton from '../../../../components/shared/buttons/AuthButton/GreenButton';
import { InterfaceSelectorComponenet } from '../../../../components/shared/InterfaceSelector/InterfaceSelector';

export const CreateIntefaceTypeSelectorComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [interfaceType, interfaceTypeSet] = useState(1);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const interfaceTypeSelect = (type: number) => {
    interfaceTypeSet(type);
  };

  const createIntefaceFormNav = () => {
    //   switch(projectType) {
    //     case 1: {
    //       navigateRoute("/app/create-custom-project");
    //       break;
    //     }
    //   //   case 2: {
    //   //     projectType2Select(true);
    //   //      break;
    //   //   }
    //   //   case 3: {
    //   //     projectType3Select(true);
    //   //     break;
    //   //  }
    //     default: {
    //       break;
    //     }
    // }
  };

  return (
    <div className={`${styles.root} main-content`}>
      <div className="pageHeader">
        <div className="flex-container">
          <div className="col-md-12 row nopadding">
            <div className="col-md-12 nopadding">
              <div className="col-md-3 nopadding">{APP_TEXT.location.LOCATION_TYPE}</div>
              <div className="col-md-6 nopadding" />
              <div className="col-md-3 nopadding text-right" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.innerPageContent + " pageContent"}> */}
      <div className="container">
        <div className="m-0 mt-4 col-md-12 row">
          <div className="col-md-12">
            <div className="custom-card2 col-md-12 pb-5">
              <div className="custom-card2-container">
                <div className="custom-card2-in ">
                  <div className="custom-card2-title">
                    <h2>{APP_TEXT.user_view.USER_VIEW_TYPE}</h2>
                    <hr />
                  </div>
                  <div className="custom-card2-body mt-2">
                    <div className="m-0 col-md-12 nopaddingOnly row">
                      <div className="col-md-12 nopaddingOnly mb-1">
                        <div className="col-md-4 nopaddingOnly">
                          <InterfaceSelectorComponenet
                            onClick={() => interfaceTypeSelect(1)}
                            title={APP_TEXT.user_view.INTERFACE_TYPE_1_TITLE}
                            description={APP_TEXT.user_view.INTERFACE_TYPE_1_DESCRIPTION}
                            selected={interfaceType === 1}
                            disabled={false}
                          />
                        </div>
                        <div className="col-md-4 nopaddingOnly">
                          <InterfaceSelectorComponenet
                            onClick={() => interfaceTypeSelect(2)}
                            title={APP_TEXT.user_view.INTERFACE_TYPE_2_TITLE}
                            description={APP_TEXT.user_view.INTERFACE_TYPE_2_DESCRIPTION}
                            selected={interfaceType === 2}
                            disabled={false}
                          />
                        </div>
                        <div className="col-md-4 nopaddingOnly">
                          <InterfaceSelectorComponenet
                            onClick={() => interfaceTypeSelect(3)}
                            title={APP_TEXT.user_view.INTERFACE_TYPE_3_TITLE}
                            description={APP_TEXT.user_view.INTERFACE_TYPE_3_DESCRIPTION}
                            selected={interfaceType === 3}
                            disabled={false}
                          />
                        </div>
                        <div className="col-md-4 nopaddingOnly">
                          <InterfaceSelectorComponenet
                            onClick={() => interfaceTypeSelect(4)}
                            title={APP_TEXT.user_view.INTERFACE_TYPE_4_TITLE}
                            description={APP_TEXT.user_view.INTERFACE_TYPE_4_DESCRIPTION}
                            selected={interfaceType === 4}
                            disabled={false}
                          />
                        </div>
                        <div className="col-md-4 nopaddingOnly">
                          <InterfaceSelectorComponenet
                            onClick={() => interfaceTypeSelect(5)}
                            title={APP_TEXT.user_view.INTERFACE_TYPE_5_TITLE}
                            description={APP_TEXT.user_view.INTERFACE_TYPE_5_DESCRIPTION}
                            selected={interfaceType === 5}
                            disabled={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 nopaddingOnly">
                        <div className="col-md-2 nopaddingOnly">
                          <GreenButton
                            text={APP_TEXT.user_view.CONTINUE}
                            onClick={createIntefaceFormNav}
                            disable={loading}
                            data-testid="submitButton"
                          >
                            {loading ? (
                              <LoadingComponent />
                            ) : (
                              <ArrowForward />
                            )}
                          </GreenButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default CreateIntefaceTypeSelectorComponent;
