import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ConfirmProcessMinimum } from 'src/components/shared/CustomDeleteModal/ConfirmDelete';
import { useNavigate } from 'react-router';
import parseCustomError from 'src/utility/parseError';
import CloudIntegration from '../services/cloud-integration.service';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    paddingTop: 19,
    paddingBottom: 19,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    margingTop: 10,
    flex: 1,
  },
  header: {
    borderBottom: '1px solid var(--green-30)',
  },
  title: {
    color: 'var(--primary-dark-green)',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '120%',
    paddingBottom: 20,
  },
  titlesm: {
    color: 'var(--primary-dark-green)',
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '120%',
    paddingBottom: 20,
  },
  contentRoot: {
    paddingTop: 30,
  },
  formControl: {
    width: '100%',
  },
  textArea: {
    backgroundColor: '#F9F9F9',
    border: '1px solid #D9DFE0',
    borderRadius: 5,
    paddingLeft: 29,
    paddingRight: 29,
    paddingTop: 13,
    paddingBottom: 13,
  },
  btnContainer: {
    display: 'flex',
    paddingTop: 10,
    width: '100%',
    justifyContent: 'space-between',
  },
});

const DCFormLayout: React.FC<{
  title: string;
  dcId: string;
  projectId: string;
  titleSize?: 'sm' | 'lg';
  children: (displayName: string, description: string, integratorEmail: string) => React.ReactNode;
  integration: CloudIntegration;
}> = ({
  title, dcId, projectId, children, titleSize = 'lg', integration,
}) => {
  const styles = useStyles();
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [integratorsEmail, setIntegratorsEmail] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        const res = await integration.getCloudDeviceCollection(dcId);
        if (res?.result?.ext_username) {
          setIntegratorsEmail(res.result.ext_username);
        }
        if (res?.result?.name) {
          setDisplayName(res.result.name);
        }
        if (res?.result?.description) {
          setDescription(res.result.description);
        }
      } catch (err) {
        enqueueSnackbar('Failed to get collection details', { variant: 'error' });
      }
    };
    init();
  }, [dcId]);

  async function updateCollection() {
    try {
      if (!displayName || displayName.length < 3) {
        enqueueSnackbar('Display name should have at least 3 characters', { variant: 'error' });
      }
      const res = await integration.updateDeviceCollection(dcId, {
        name: displayName,
        description,
        cint_status: 2, // this is hardcoded as mentioned here https://smartlifelabs.atlassian.net/wiki/spaces/SMAR/pages/1655373825/API+Guide#02.-Edit-device-collection
      });
      enqueueSnackbar('Device collection details updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Failed to update the device collection', { variant: 'error' });
    }
  }

  async function deleteContainer(id: string) {
    try {
      await integration.deleteCloudDeviceCollection(id);
      navigate(`/app/device-containers?projectId=${projectId}`);
    } catch (err: any) {
      const errMsg = parseCustomError(err.response && err.response.data);
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.header}>
        <Typography className={clsx(titleSize === 'lg' ? styles.title : styles.titlesm)}>
          {title}
        </Typography>
      </Box>
      <Grid className={styles.contentRoot} container spacing={2}>
        <Grid item md={8} sm={12}>
          {children(displayName, description, integratorsEmail)}
        </Grid>
        <Grid item md={4} sm={12}>
          <Grid container spacing={1}>
            {integratorsEmail && (
              <Grid item md={12}>
                <FormControl className={styles.formControl}>
                  <TextField
                    value={integratorsEmail}
                    disabled
                    label="Integrator Account Email"
                    placeholder="Integrator Account Email"
                    size="medium"
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item md={12}>
              <FormControl className={styles.formControl}>
                <TextField
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  label="Display Name"
                  placeholder="Display Name"
                  size="medium"
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl className={styles.formControl}>
                <TextareaAutosize
                  style={{
                    resize: 'none',
                  }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={styles.textArea}
                  aria-label="Description"
                  placeholder="Description"
                  minRows={5}
                />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Box className={styles.btnContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateCollection();
                  }}
                >
                  Save
                </Button>
                <IconButton color="error" onClick={() => setShowConfirmation(true)}>
                  <Tooltip title="Delete the container">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmProcessMinimum
        open={showConfirmation}
        setOpen={setShowConfirmation}
        title="Confirmation to delete."
        fullText="Are you sure you want to delete this device container ? Note that process cannot be undo."
        onConfirm={() => deleteContainer(dcId)}
      />
    </Box>
  );
};

export default DCFormLayout;
