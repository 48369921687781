/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
  FormHelperText,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Alert,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React, { useState } from 'react';
import {
  Action,
  ActionTypes,
  ActionTypesLabels,
  LOGIC_CARD_VISIBILITY,
  OPERATORS,
} from '@smartlife-redux-state/common';
import clsx from 'clsx';
import { DeviceAction } from './actions/DeviceAction';
import { FormContainer } from './FormContainer';
import {
  add_empty_child_action,
  delete_child_action,
  duplicate_child_action,
  update_action,
} from '../../../../../../store/redux/features/logic-engine/slice';
import { CofirmDialog } from '../../ui/Confirm';
import { NotificationAction } from './actions/NotificationAction';
import { FirebaseAction } from './actions/FirebaseAction';
import { DivButton } from '../../ui/DivButton';
import { LogicStatusAction } from './actions/LogicStatusAction';
import { Group } from '../../board/Group';
import { Card } from '../../board/Card';
import { AdvancedFirebaseAction } from './actions/AdvancedFirebaseAction';
import CardStatus, { CARD_TYPES } from './shared/CardStatus';
import { AlarmAction } from './actions/AlarmAction';
import { AlexaNotificationAction } from './actions/AlexaAction';
import { EmailAction } from './actions/EmailAction';

const useStyle = makeStyles({
  placeholderRoot: {
    backgroundColor: '#F5B37C',
  },
  errorRoot: {
    backgroundColor: '#EB88AD',
  },
  disabledRoot: {
    backgroundColor: '#7B7B7B',
  },
});

export const ActionForm: React.FC<{
  action: Action;
  parentAction: Action;
  isChild: boolean;
}> = ({ action, parentAction, isChild }) => {
  const classes = useStyle();

  const renderConcreateForm = (action: Action, parentAction: Action, isChild: boolean) => {
    switch (action.type) {
      case ActionTypes.DEVICE:
        return <DeviceAction action={action} isChild={isChild} parentAction={parentAction} />;
      case ActionTypes.ALARM:
        return <AlarmAction action={action} isChild={isChild} parentAction={parentAction} />;
      case ActionTypes.NOTIFICATION:
        return <NotificationAction action={action} isChild={isChild} parentAction={parentAction} />;
      case ActionTypes.EMAIL:
        return <EmailAction action={action} isChild={isChild} parentAction={parentAction} />;
      case ActionTypes.FIREBASE:
        return <FirebaseAction action={action} isChild={isChild} parentAction={parentAction} />;
      case ActionTypes.ADVANCED_FIREBASE:
        return (
          <AdvancedFirebaseAction action={action} isChild={isChild} parentAction={parentAction} />
        );
      case ActionTypes.LOGIC_RUN_STATUS:
        return (
          <LogicStatusAction
            action={action}
            isChild={isChild}
            parentAction={parentAction}
            flag={ActionTypes.LOGIC_RUN_STATUS}
          />
        );
      case ActionTypes.LOGIC_CANCEL_STATUS:
        return (
          <LogicStatusAction
            action={action}
            isChild={isChild}
            parentAction={parentAction}
            flag={ActionTypes.LOGIC_CANCEL_STATUS}
          />
        );
      case ActionTypes.ALEXA_VOICE_NOTIFICATION:
        return (
          <AlexaNotificationAction action={action} isChild={isChild} parentAction={parentAction} />
        );
      default:
        return <></>;
    }
  };
  // eslint-disable-next-line max-len
  return (
    <Grid container>
      <Grid item md={12}>
        {renderConcreateForm(action, parentAction, isChild)}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100%",
    //   border: "1px solid #7F8FA4",
    //   borderRadius: "5px",
    padding: '5px',
  },
  content: {
    // background: "#d5e3e5 !important",
    // padding: "4% 0",
    borderRadius: '5px',
  },
  formControl: {
    width: '100%',
    paddingRight: '5px',
  },
  line_root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  line: {
    alignSelf: 'center',
    background: '#E0E0E0',
    width: '80%',
    height: '0.5px',
  },
  child_root: {
    backgroundColor: '#d5e3e5 !important',
    margin: '0 !important',
  },
  child_head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  child_dropable_group: {
    background: '#F4FAFB !important',
    padding: '0 !important',
  },
  child_draggable_card: {
    background: '',
  },
  placeholderRoot: {
    backgroundColor: '#F5B37C',
  },
  errorRoot: {
    backgroundColor: '#EB88AD',
  },
  disabledRoot: {
    backgroundColor: '#7B7B7B',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const ActionSelector: React.FC<{
  action: Action;
  isChild?: boolean;
  parentAction?: Action;
  queueIndex?: number;
}> = ({
  action, isChild = false, parentAction, queueIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteActionId, setDeleteActionid] = useState(null);

  const handleChange = (
    action: Action,
    type: ActionTypes,
    parentAction: Action,
    isChild = false,
  ) => {
    dispatch(
      update_action({
        action: {
          ...action,
          dc: null,
          device: null,
          variable: null,
          type,
          possible_variable_type: 'String',
          variable_value: null,
          payload: null,
          remote_run: false,
          meta: { ...action.meta, card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE },
        },
        isChild,
        parentAction,
      }),
    );
  };

  const handleRemoteRunToggle = (action: Action) => {
    const validate_val = action.remote_run !== undefined && action.remote_run != null ? !action.remote_run : true;
    dispatch(
      update_action({
        action: {
          ...action,
          remote_run: validate_val,
        },
        isChild: false,
        parentAction: null,
      }),
    );
  };

  function onNewChildAction(parentActionId: string, nextTo = 0) {
    dispatch(add_empty_child_action({ parentActionId, nextTo }));
  }

  function onDuplicateAction(parentActionId: string, action: Action) {
    dispatch(duplicate_child_action({ parentActionId, action }));
  }

  function onDeleteAction(actionId: string) {
    setDeleteActionid(actionId);
  }

  function deleteAction() {
    dispatch(
      delete_child_action({
        actionId: deleteActionId,
        parentActionId: action.id,
      }),
    );
    setDeleteActionid(null);
  }

  function actionDelay(value) {
    // if(Number(value) == 0) {
    //   value = 1;
    // }
    dispatch(
      update_action({
        action: {
          ...action,
          child_action_delay: value ? Number(value) : null,
        },
        isChild: false,
      }),
    );
  }

  function childActionDelay(child_action: Action, parent_action: Action, value: string) {
    // if(Number(value) == 0) {
    //   value = "1";
    // }
    dispatch(
      update_action({
        action: {
          ...child_action,
          child_action_delay: value ? Number(value) : null,
        },
        isChild: true,
        parentAction: parent_action,
      }),
    );
  }

  const mainCardGroupId = `${`queueIndex=${queueIndex}__mainActionId=${action.id}`}__cardType=main-group`;
  const mainCardId = `${`queueIndex=${queueIndex}__mainActionId=${action.id}`}__cardType=main-card`;

  return (
    <Grid container className={classes.root}>
      {action.dc && action.dc.has_verification_error && (
        <Grid item md={12}>
          <Alert color="warning">
            There is an issue with Device Container, Make sure the device container is exists or
            select another device container from the dropdown or delete this card. Info -
            {' '}
            {action.dc.has_verification_error}
          </Alert>
          <Box height={15} />
        </Grid>
      )}

      {action.device && action.device.has_verification_error && (
        <Grid item md={12}>
          <Alert color="warning">
            There is an issue with the selected device/variable. If you deleted the device/variable
            make sure to select another item from the dropdown or delete this card. Info -
            {' '}
            {action.device.has_verification_error}
          </Alert>
          <Box height={15} />
        </Grid>
      )}
      <Grid item md={12} className={classes.content}>
        <Group
          droppableId={mainCardGroupId}
          key={`${action.id}main`}
          className={classes.child_dropable_group}
          droppableType={`queueIndex__${queueIndex}`}
        >
          {() => (
            <>
              <Card draggableId={mainCardId} index={0}>
                {({ provided, snapshot }) => (
                  <>
                    <Box mb={2} width="25%">
                      <FormControl size="small" variant="outlined" className={classes.formControl}>
                        <InputLabel>Type</InputLabel>
                        <Select
                          label="Type"
                          value={action.type}
                          onChange={(e) => handleChange(action, e.target.value as any, null, false)}
                        >
                          {Object.keys(ActionTypesLabels).map((key, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <MenuItem value={ActionTypesLabels[key].key} key={index}>
                              {ActionTypesLabels[key].label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {action.dc && action.dc.dc_type === 4 && action.dc.dc_cat === 1 && (
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={
                                action.remote_run !== undefined && action.remote_run != null
                                  ? action.remote_run
                                  : false
                              }
                              onChange={() => handleRemoteRunToggle(action)}
                              name="remoteRun"
                            />
                          )}
                          label="Remote Run (Beta)"
                        />
                      )}
                    </Box>

                    <CardStatus
                      cardType={CARD_TYPES.ACTION_CARD}
                      cardId={action.id}
                      meta={action && action.meta ? action.meta : null}
                    >
                      {({ classes: statusClasses }) => (
                        <FormContainer
                          cardId="2"
                          dragHandlerProps={provided.dragHandleProps}
                          className={clsx(statusClasses, classes.child_root)}
                          action={() => (
                            <>
                              <IconButton
                                onClick={() => onNewChildAction(action.id, -1)}
                                size="large"
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => onDuplicateAction(action.id, action)}
                                size="large"
                              >
                                <FileCopyIcon fontSize="small" />
                              </IconButton>
                              <IconButton disabled onClick={() => {}} size="large">
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        >
                          {' '}
                          <ActionForm
                            action={action}
                            isChild={isChild}
                            parentAction={parentAction}
                          />
                        </FormContainer>
                      )}
                    </CardStatus>

                    {action && action.child_actions && (
                      <Grid item sm={12} xs={12} style={{ marginTop: '10px' }}>
                        {action.child_actions.length !== 0 && (
                          <div className={classes.line_root}>
                            <hr className={classes.line} />
                            <TextField
                              label="Delay(sec)"
                              type="number"
                              variant="outlined"
                              error={action.child_action_delay < 0}
                              size="small"
                              value={action.child_action_delay}
                              onChange={(e) => actionDelay(e.target.value)}
                              // InputProps={{ inputProps: { min: 1 } }}
                            />
                            {/* <Button color="primary" disabled variant="contained">
                        <Typography>AND</Typography>
                      </Button> */}
                            <hr className={classes.line} />
                          </div>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </Card>
              {action.child_actions.map((childAction, index) => (
                <Card
                  draggableId={`${`queueIndex=${queueIndex}__mainActionId=${action.id}`}__cardType=childListGroup__childActionId=${
                    childAction.id
                  }`}
                  index={index + 1}
                  key={childAction.id}
                >
                  {({ provided, snapshot }) => (
                    <>
                      {/* {`${`queueIndex=${queueIndex}__mainActionId=${action.id}`}__cardType=childListGroup__childActionId=${
                        childAction.id
                      }`}
                      {index + 1} */}
                      {childAction.dc && childAction.dc.has_verification_error && (
                        <Grid item md={12}>
                          <Alert color="warning">
                            There is an issue with Device Container, Make sure the device container
                            is exists or select another device container from the dropdown or delete
                            this card. Info -
                            {' '}
                            {childAction.dc.has_verification_error}
                          </Alert>
                          <Box height={15} />
                        </Grid>
                      )}

                      {childAction.device && childAction.device.has_verification_error && (
                        <Grid item md={12}>
                          <Alert color="warning">
                            There is an issue with the selected device/variable. If you deleted the
                            device/variable make sure to select another item from the dropdown or
                            delete this card. Info -
                            {' '}
                            {childAction.device.has_verification_error}
                          </Alert>
                          <Box height={15} />
                        </Grid>
                      )}
                      <Box mb={2} width="25%">
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={clsx(classes.formControl)}
                        >
                          <InputLabel>Type</InputLabel>
                          <Select
                            label="Type"
                            value={childAction.type}
                            // eslint-disable-next-line max-len
                            onChange={(e) => handleChange(childAction, e.target.value as any, action, true)}
                          >
                            {Object.keys(ActionTypesLabels).map((key, index) => {
                              // eslint-disable-next-line max-len
                              // if (ActionTypesLabels[key].key === ActionTypes.NOTIFICATION) {
                              //   return <></>;
                              // }
                              return (
                                <MenuItem
                                  value={ActionTypesLabels[key].key}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                >
                                  {ActionTypesLabels[key].label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      <CardStatus
                        cardType={CARD_TYPES.ACTION_CHILD_CARD}
                        parentCardId={action.id}
                        cardId={childAction.id}
                        meta={childAction && childAction.meta ? childAction.meta : null}
                      >
                        {({ classes: statusClasses }) => (
                          <FormContainer
                            dragHandlerProps={provided.dragHandleProps}
                            className={clsx(classes.child_root, statusClasses)}
                            action={() => (
                              <>
                                <IconButton
                                  onClick={() => onNewChildAction(action.id, index)}
                                  size="large"
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() => onDuplicateAction(action.id, childAction)}
                                  size="large"
                                >
                                  <FileCopyIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() => onDeleteAction(childAction.id)}
                                  size="large"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          >
                            <ActionForm action={childAction} isChild parentAction={action} />
                          </FormContainer>
                        )}
                      </CardStatus>
                      <Box mt={1} mb={1}>
                        {action.child_actions.length !== index + 1 && (
                          <div className={classes.line_root}>
                            <hr className={classes.line} />
                            <TextField
                              label="Delay(sec)"
                              type="number"
                              variant="outlined"
                              size="small"
                              error={childAction.child_action_delay < 0}
                              value={childAction.child_action_delay}
                              required={!childAction.child_action_delay}
                              // eslint-disable-next-line max-len
                              onChange={(e) => childActionDelay(childAction, action, e.target.value)}
                            />
                            {/* <Button color="primary" disabled variant="contained">
                            <Typography>AND</Typography>
                          </Button> */}
                            <hr className={classes.line} />
                          </div>
                        )}
                      </Box>
                    </>
                  )}
                </Card>
              ))}
            </>
          )}
        </Group>
      </Grid>
      <CofirmDialog
        dialogOpen={!!deleteActionId}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteAction();
          } else {
            setDeleteActionid(null);
          }
        }}
        title={"Confirm to delete the 'Child Action' ?"}
        text="It will delete the child action and you will not able to redo it!"
      />
    </Grid>
  );
};

ActionSelector.defaultProps = {
  isChild: false,
  parentAction: null,
  queueIndex: null,
};

// {action && action.child_actions && (
//   <>
//     <Grid item md={12} sm={12} xs={12}>
//       <Box height={10} />
//       <Group
//         droppableId={`${`queueIndex=${queueIndex}__mainActionId=${action.id}`}__cardType=childListGroup`}
//         key={`${action.id}child_list`}
//         className={classes.child_dropable_group}
//       >
//         {() => (
//           <>
//             {action.child_actions.map((childAction, index) => (
//               <div key={childAction.id}>
//                 <Card
//                   draggableId={`${`queueIndex=${queueIndex}__mainActionId=${action.id}`}__cardType=childListGroup__childActionId=${
//                     childAction.id
//                   }`}
//                   index={index}
//                 >
//                   {({ provided, snapshot }) => (
//                     <>
//                       {childAction.dc && childAction.dc.has_verification_error && (
//                         <Grid item md={12}>
//                           <Alert color="warning">
//                             There is an issue with Device Container, Make sure the device
//                             container is exists or select another device container from the
//                             dropdown or delete this card. Info -
//                             {' '}
//                             {childAction.dc.has_verification_error}
//                           </Alert>
//                           <Box height={15} />
//                         </Grid>
//                       )}

//                       {childAction.device && childAction.device.has_verification_error && (
//                         <Grid item md={12}>
//                           <Alert color="warning">
//                             There is an issue with the selected device/variable. If you
//                             deleted the device/variable make sure to select another item from
//                             the dropdown or delete this card. Info -
//                             {' '}
//                             {childAction.device.has_verification_error}
//                           </Alert>
//                           <Box height={15} />
//                         </Grid>
//                       )}
//                       <Box mb={2} width="25%">
//                         <FormControl
//                           size="small"
//                           variant="outlined"
//                           className={clsx(classes.formControl)}
//                         >
//                           <InputLabel>Type</InputLabel>
//                           <Select
//                             label="Type"
//                             value={childAction.type}
//                             // eslint-disable-next-line max-len
//                             onChange={(e) => handleChange(childAction, e.target.value as any, action, true)}
//                           >
//                             {Object.keys(ActionTypesLabels).map((key, index) => {
//                               // eslint-disable-next-line max-len
//                               // if (ActionTypesLabels[key].key === ActionTypes.NOTIFICATION) {
//                               //   return <></>;
//                               // }
//                               return (
//                                 <MenuItem
//                                   value={ActionTypesLabels[key].key}
//                                   // eslint-disable-next-line react/no-array-index-key
//                                   key={index}
//                                 >
//                                   {ActionTypesLabels[key].label}
//                                 </MenuItem>
//                               );
//                             })}
//                           </Select>
//                         </FormControl>
//                       </Box>
//                       <CardStatus
//                         cardType={CARD_TYPES.ACTION_CHILD_CARD}
//                         parentCardId={action.id}
//                         cardId={childAction.id}
//                         meta={childAction && childAction.meta ? childAction.meta : null}
//                       >
//                         {({ classes: statusClasses }) => (
//                           <FormContainer
//                             dragHandlerProps={provided.dragHandleProps}
//                             className={clsx(classes.child_root, statusClasses)}
//                             action={() => (
//                               <>
//                                 <IconButton
//                                   onClick={() => onNewChildAction(action.id, index)}
//                                   size="large"
//                                 >
//                                   <AddIcon fontSize="small" />
//                                 </IconButton>
//                                 <IconButton
//                                   onClick={() => onDuplicateAction(action.id, childAction)}
//                                   size="large"
//                                 >
//                                   <FileCopyIcon fontSize="small" />
//                                 </IconButton>
//                                 <IconButton
//                                   onClick={() => onDeleteAction(childAction.id)}
//                                   size="large"
//                                 >
//                                   <DeleteIcon fontSize="small" />
//                                 </IconButton>
//                               </>
//                             )}
//                           >
//                             <ActionForm action={childAction} isChild parentAction={action} />
//                           </FormContainer>
//                         )}
//                       </CardStatus>
//                       <Box mt={1} mb={1}>
//                         {action.child_actions.length !== index + 1 && (
//                           <div className={classes.line_root}>
//                             <hr className={classes.line} />
//                             <TextField
//                               label="Delay(sec)"
//                               type="number"
//                               variant="outlined"
//                               size="small"
//                               error={
//                                 !childAction.child_action_delay
//                                 || childAction.child_action_delay < 0
//                               }
//                               value={childAction.child_action_delay}
//                               required={!childAction.child_action_delay}
//                               // eslint-disable-next-line max-len
//                               onChange={(e) => childActionDelay(childAction, action, e.target.value)}
//                             />
//                             {/* <Button color="primary" disabled variant="contained">
//                       <Typography>AND</Typography>
//                     </Button> */}
//                             <hr className={classes.line} />
//                           </div>
//                         )}
//                       </Box>
//                     </>
//                   )}
//                 </Card>
//               </div>
//             ))}
//           </>
//         )}
//       </Group>
//       <Box height={10} />
//       <DivButton
//         onClick={() => {
//           onNewChildAction(action.id, -99);
//         }}
//       />
//     </Grid>
//   </>
// )}
