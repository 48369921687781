import {
  TextField,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext } from 'src/store/Auth/AuthContext';

// eslint-disable-next-line max-len
const DeviceProfileCreator: React.FC<{open: boolean; handleClose: any; [key: string]: any}> = ({
  open,
  handleClose,
  ...props
}) => {
  const snackbar = useSnackbar();
  const authUser = useContext(AuthContext);

  const [val, setVal] = useState('');
  const [loading, setLoading] = useState(false);
  const handleConfirm = () => {
    createProfile(val);
  };

  const createProfile = async (name: string) => {
    setLoading(true);
    let errorStr = 'Cannot create Component profile';
    try {
      if (name.length < 4) {
        errorStr = 'Profile name not valid';
        throw new Error(errorStr);
      }
      const { token } = authUser.access_token;
      console.log('props', props);
      const compProfileInfo = {
        name,
        comp_type: props.comType,
        parent_type: props.parentType,
        template_data: props.template,
        parent_id: props.parentProfileId ? props.parentProfileId : null,
        parent_category: props.parentCategory,
        parent_sub_category: props.parentSubCategory,
      };

      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/com-templates/v1/create`,
        { item: compProfileInfo },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      snackbar.enqueueSnackbar('Component profile created', {
        variant: 'info',
      });
      setVal('');
      handleClose();
    } catch (err) {
      if (typeof err === 'string') {
        errorStr = err;
      }
      snackbar.enqueueSnackbar(errorStr, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Save As a Profile</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To save the settings as a profile please enter a name for the profile and click create.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Profile Name"
          fullWidth
          variant="standard"
          value={val}
          onChange={(e) => setVal(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceProfileCreator;
