/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React, { useEffect, useState } from 'react';
import { Check, Trigger } from '@smartlife-redux-state/common';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  child_head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function ValueSelector(
  row: any,
  state: any,
  areas: any,
  outputs: any,
  zones: any,
  index: any,
  setKeyValueHandler: any,
  setChanelHandler: any,
) {
  const capitalizedState = state && state.length > 0 && state.charAt(0).toUpperCase() + state.slice(1);
  const { state_value, chanel, type } = row;
  console.log(row);
  let selectorOptions: any = [];
  if (state === 'area' || state === 'area_state') {
    selectorOptions = areas;
  } else if (state === 'output') {
    selectorOptions = outputs;
  } else if (state === 'zone') {
    selectorOptions = zones;
  }
  const classes = useStyles();

  const arrowHeadAlarmActionOptionsProps = [
    {
      label: 'ARM AWAY',
      command: 'ARMED',
    },
    {
      label: 'ARMING AWAY',
      command: 'EXIT_DELAY',
    },
    {
      label: 'ARM HOME',
      command: 'STAY_ARMED',
    },
    {
      label: 'ARMING HOME',
      command: 'STAY_EXIT_DELAY',
    },
    {
      label: 'PANIC',
      command: 'PANIC',
    },
    {
      label: 'DISARM',
      command: 'DISARMED',
    },
  ];

  const arrowHeadAlarmOutputOptionsProps = [
    {
      label: 'TRUE',
      command: 'true',
    },
    {
      label: 'FALSE',
      command: 'false',
    },
  ];

  const arrowHeadAlarmStatusProps = [
    {
      label: 'COMMS_CONNECTED',
      command: 'COMMS_CONNECTED',
    },
    {
      label: 'COMMS_DISCONNECTED',
      command: 'COMMS_DISCONNECTED',
    },
    {
      label: 'COMMS_CONNECTING',
      command: 'COMMS_CONNECTING',
    },
    {
      label: 'FAILED_WITH_CONNECTION_TIMEOUT',
      command: 'FAILED_WITH_CONNECTION_TIMEOUT',
    },
    {
      label: 'CONNECTION_FAILED',
      command: 'CONNECTION_FAILED',
    },
    {
      label: 'NOT_CONNECTED_YET',
      command: 'NOT_CONNECTED_YET',
    },
    {
      label: 'COMMS_DISCONNECT_DEVICE_CONFIRMED',
      command: 'COMMS_DISCONNECT_DEVICE_CONFIRMED',
    },
  ];
  // const config = ac_key_maps[state];
  return (
    <>
      {state && (
        <>
          {state !== 'status' ? (
            <Grid item xs={12} md={3}>
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState}`}</InputLabel>
                <Select
                  label={`${capitalizedState}`}
                  value={chanel}
                  onChange={(e: any) => {
                    setChanelHandler(index, e.target.value);
                  }}
                >
                  {selectorOptions.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={3}>
            {state === 'area' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmActionOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state === 'area_state' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmOutputOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state === 'output' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmOutputOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state === 'zone' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmOutputOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state === 'status' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmStatusProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

const ChildRow = ({
  row,
  index,
  options,
  areas,
  outputs,
  zones,
  setKeyHandler,
  setDelayHandler,
  setKeyValueHandler,
  setTypeHandler,
  setChanelHandler,
  deleteChild,
  classes,
  isLast,
}: any) => (
  <Grid
    container
    spacing={2}
    key={row.index}
    style={{
      marginTop: '5px',
      padding: '5px',
    }}
  >
    <Grid item xs={12} md={3}>
      <Autocomplete
        size="small"
        onChange={(_, value) => {
          setTypeHandler(index, value || null);
        }}
        value={row.type}
        className={classes.formControl}
        id={`zone_${row.index}`}
        options={options}
        getOptionLabel={(option: any) => option}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="Chanel" variant="outlined" />}
      />
    </Grid>
    {ValueSelector(
      row,
      row.type,
      areas,
      outputs,
      zones,
      index,
      setKeyValueHandler,
      setChanelHandler,
    )}
  </Grid>
);

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const ExsAlarmChildChildActions: React.FC<{
  onChange: any;
  alarmType: string;
  action: Trigger | Check;
}> = ({ onChange, alarmType, action }) => {
  const classes = useStyles();

  const [options, setOptions] = useState<any[]>([]);

  const [settings, setSettings] = useState<any>();
  const [areas, setAreas] = useState<any[]>([]);
  const [outputs, setOutputs] = useState<any[]>([]);
  const [zones, setZones] = useState<any[]>([]);
  const [child, setChild] = useState<any[]>(
    action.variable_data && action.variable_data.field && isJson(action.variable_data.field)
      ? JSON.parse(action.variable_data.field)
      : action.variable && isJson(action.variable)
        ? JSON.parse(action.variable)
        : [
          {
            delay: 1,
            index: Date.now(),
          },
        ],
  );

  const AddNewChild = () => {
    const newArray = [
      ...child,
      {
        delay: 1,
        index: Date.now(),
      },
    ];
    setChild(newArray);
  };

  const deleteChild = (index) => {
    const newArray = [...child];
    newArray.splice(index, 1);
    setChild(newArray);
  };

  const setChanelHandler = (index: number, value: any) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      chanel: value,
    };
    setChild(newArray);
  };

  const setTypeHandler = (index: number, type: any) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      type,
    };
    setChild(newArray);
  };

  const setKeyHandler = (index: number, key: any) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state: key,
    };
    setChild(newArray);
  };

  const setKeyValueHandler = (index, key_value) => {
    console.log(index, key_value);
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state_value: key_value,
    };
    setChild(newArray);
  };

  const setDelayHandler = (index, val) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      delay: parseInt(val, 10) < 1 ? 0 : parseInt(val, 10),
    };
    setChild(newArray);
  };

  useEffect(() => {
    const { device } = action;
    if (!device) return;

    const EXS_STATES = {
      AREA_STATUS: 'area_state',
      AREA: 'area',
      POWER: 'output',
      Zone: 'zone',
      STATUS: 'status',
    };
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const keys = Object.keys(EXS_STATES).map((key) => EXS_STATES[key]);
    setOptions(keys || []);
    if (action && action.device && action.device.settings) {
      const actionSettings = JSON.parse(action.device.settings);
      console.log('dddddddd', actionSettings);
      setSettings(actionSettings);

      if (actionSettings.areas && actionSettings.areas.length > 0) {
        setAreas(actionSettings.areas);
      }
      if (actionSettings.outputs && actionSettings.outputs.length > 0) {
        setOutputs(actionSettings.outputs);
      }
      if (actionSettings.zones && actionSettings.zones.length > 0) {
        setZones(actionSettings.zones);
      }
    }
  }, [action]);

  // useEffect(() => {
  //   AddNewChild();
  // }, []);

  useEffect(() => {
    if (child) {
      let value;
      if (child.length === 0) {
        value = JSON.stringify([]);
      } else {
        value = JSON.stringify([...child]);
        onChange('ESX', '', {
          field: value,
          type: 'esx',
        });
      }
    }
  }, [child]);

  return (
    <Grid
      container
      style={{
        border: '1px solid #D9DFE0',
        padding: '10px',
        borderRadius: '10px',
        marginTop: '10px',
      }}
    >
      {child
        && child.map((row, index) => (
          <ChildRow
            row={row}
            index={index}
            options={options}
            areas={areas}
            outputs={outputs}
            zones={zones}
            setKeyHandler={setKeyHandler}
            setDelayHandler={setDelayHandler}
            setKeyValueHandler={setKeyValueHandler}
            setTypeHandler={setTypeHandler}
            setChanelHandler={setChanelHandler}
            deleteChild={deleteChild}
            classes={classes}
            isLast={child.length === index + 1}
          />
        ))}
    </Grid>
  );
};

export default ExsAlarmChildChildActions;
