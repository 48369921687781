/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import React, { useEffect, useState } from 'react';
import { keys } from 'lodash';
import { Action, ac_key_maps, AC_STATES } from '@smartlife-redux-state/common';
import { RootState, useSelector } from '../../../../../../../../store/redux/store';
import { ITEM_DEFAULTS } from '../../../../../../../../config/deviceConfig/item_defaults';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  child_head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

enum AUDIO_DISTRIBUTER_STATES {
  VOLUME = 'volume',
  MUTE = 'mute',
  POWER = 'pwr',
  SOURCE = 'src',
  TUNE = 'tune',
  FREQ = 'freq',
}

function AudioDistributerValueSelector(state: AC_STATES, row: any, setVariableState: any, index) {
  const { state_value } = row;
  const classes = useStyles();
  const config = ac_key_maps[state];
  return (
    <>
      {config && config.possible_state_type === 'number' ? (
        <TextField
          key={index}
          type="number"
          className={classes.formControl}
          label={`${state} state`}
          value={state_value}
          onChange={(e: any) => {
            setVariableState(index, e.target.value);
          }}
          variant="outlined"
          size="small"
        />
      ) : config && config.possible_state_type === 'select' ? (
        <FormControl size="small" key={index} className={classes.formControl} variant="outlined">
          <InputLabel>{`${state} state`}</InputLabel>
          <Select
            label={`${state} state`}
            value={state_value}
            onChange={(e) => setVariableState(index, e.target.value)}
          >
            {(config as any).possible_states.map((item, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          key={index}
          type="string"
          disabled
          className={classes.formControl}
          label="state"
          variant="outlined"
          size="small"
        />
      )}
    </>
  );
}

const ChildRow = ({
  row,
  index,
  options,
  sources,
  variables,
  setKeyHandler,
  setDelayHandler,
  setKeyValueHandler,
  setZoneHandler,
  deleteChild,
  classes,
  isLast,
}: any) => (
  <Grid
    container
    spacing={2}
    key={row.index}
    style={{
      marginTop: '5px',
      padding: '5px',
    }}
  >
    <Grid item xs={12} md={3}>
      <Autocomplete
        size="small"
        onChange={(_, value) => {
          setZoneHandler(index, value || null);
        }}
        value={row.zone}
        className={classes.formControl}
        id={`zone_${row.index}`}
        options={options}
        getOptionLabel={(option: any) => option}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="A/C Option" variant="outlined" />}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      {AudioDistributerValueSelector(row.zone, row, setKeyValueHandler, index)}
    </Grid>
    <Grid item xs={12} md={2}>
      {!isLast && (
        <TextField
          id={`delay_${row.index}`}
          type="number"
          error={row.delay !== 0 && !row.delay}
          className={classes.formControl}
          label="Delay(sec)"
          value={row.delay}
          onChange={(e: any) => {
            setDelayHandler(index, e.target.value);
          }}
          variant="outlined"
          size="small"
          // disabled={isLast}
        />
      )}
    </Grid>
    <Grid item xs={12} md={1}>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={() => deleteChild(index)} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
);

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const AcControllerChildActions: React.FC<{
  onChange: any;
  action: Action;
}> = ({ onChange, action }) => {
  const classes = useStyles();

  const [options, setOptions] = useState<any[]>([]);

  const [sources, setSources] = useState<any[]>([]);
  const [variables, setVaribles] = useState<any[]>([]);

  const [child, setChild] = useState<any[]>(
    action.variable && isJson(action.variable) ? JSON.parse(action.variable) : [],
  );
  // console.log(remoteKeyMap);

  const AddNewChild = () => {
    const newArray = [
      ...child,
      {
        delay: 1,
        index: Date.now(),
      },
    ];
    setChild(newArray);
  };

  const deleteChild = (index) => {
    const newArray = [...child];
    newArray.splice(index, 1);
    setChild(newArray);
  };

  const setZoneHandler = (index, zone) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      zone,
    };
    console.log('NEW ARRAY', newArray);
    setChild(newArray);
  };

  const setKeyHandler = (index, key) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state: key,
    };
    setChild(newArray);
  };

  const setKeyValueHandler = (index, key_value) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state_value: key_value,
    };
    setChild(newArray);
  };

  const setDelayHandler = (index, val) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      delay: parseInt(val, 10) < 1 ? 0 : parseInt(val, 10),
    };
    setChild(newArray);
  };

  useEffect(() => {
    const { device } = action;
    if (!device) return;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const keys = Object.keys(AC_STATES).map((key) => AC_STATES[key]);
    console.log('XXXXXXXXXXXX', keys);
    setOptions(keys || []);
  }, [action]);

  useEffect(() => {
    if (child) {
      let value;
      if (child.length === 0) {
        value = JSON.stringify([]);
      } else {
        value = JSON.stringify([...child]);
        console.log('AC_CONTROLLERS', '', { field: value, type: 'ac_controller' }, child);
        onChange('AC_3RD_PARTY_CONTROLLERS', '', {
          field: value,
          type: 'ac_controller',
        });
      }
    }
  }, [child]);

  return (
    <Grid
      container
      style={{
        border: '1px solid #D9DFE0',
        padding: '10px',
        borderRadius: '10px',
        marginTop: '10px',
      }}
    >
      <Box>
        <Typography style={{ color: 'red' }}>Under Development. May not work as expected</Typography>
      </Box>
      <Box className={classes.child_head}>
        <Typography>A/C Child Actions</Typography>
        <IconButton onClick={() => AddNewChild()} size="large">
          <AddIcon />
        </IconButton>
      </Box>

      {child
        && child.map((row, index) => (
          <ChildRow
            row={row}
            index={index}
            options={options}
            variables={variables}
            sources={sources}
            setKeyHandler={setKeyHandler}
            setDelayHandler={setDelayHandler}
            setKeyValueHandler={setKeyValueHandler}
            setZoneHandler={setZoneHandler}
            deleteChild={deleteChild}
            classes={classes}
            isLast={child.length === index + 1}
          />
        ))}
    </Grid>
  );
};

export default AcControllerChildActions;
