/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * A reusable drag drop card library with multiple groups. This library is an extended
 * version of react-beautiful-dnd library.
 *
 * @author Maduka Dilshan
 *
 */

import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';

const DragDropContextx: any = DragDropContext;

type BoardProps = {
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  children: any;
  [key: string]: any;
};

// eslint-disable-next-line import/prefer-default-export
export const Board: React.FC<BoardProps> = ({ onDragEnd, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...rest} style={{ width: '100%' }}>
    <DragDropContextx onDragEnd={onDragEnd}>{children}</DragDropContextx>
  </div>
);
