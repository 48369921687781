import { cloneDeep } from 'lodash';
import { V2BStepackupInfoRoot } from '../../v2types';

function toAPIPostFriendySmartListInfoJson(
  info: V2BStepackupInfoRoot,
) {
  const apiFriendly = {};
  Object.keys(info.pendingComps).map((smartListId) => {
    apiFriendly[smartListId] = {};
    const smartListData = cloneDeep(info.pendingComps[smartListId]);
    // eslint-disable-next-line array-callback-return
    return Object.keys(smartListData).map((key) => {
      apiFriendly[smartListId][key] = {
        clone_type: 'create_new',
      };
    });
  });

  return apiFriendly;
}

export default toAPIPostFriendySmartListInfoJson;
