import Delete from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import { appConsoleLog } from '../../../../../utility/appUtils';
import { DragSelectorCardItem, IUVDragSelectorItem } from '../UVDragSelector/UVDragSelectItem';
import styles from './UVListSort.module.css';

type ComponentProps = {
  selectedItems: IUVDragSelectorItem[];
  onItemClick?: (item: IUVDragSelectorItem, index: number) => void;
  itemListChangeHandler: (items: IUVDragSelectorItem[]) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVListSort: React.FunctionComponent<ComponentProps> = ({
  selectedItems,
  onItemClick,
  itemListChangeHandler,
}) => {
  const [dragItem, setDragItem] = useState<{
    item: IUVDragSelectorItem;
    type: string;
    index: number;
  } | null>();

  const dragStartHandler = (event: any) => {
    setDragItem(event);
  };

  const onDividerDropHandler = (divIndex: number) => {
    if (dragItem && dragItem.type === 'selected') {
      if (dragItem.index !== divIndex) {
        const item1 = selectedItems[dragItem.index];
        const item2 = selectedItems[divIndex];
        const selected = [...selectedItems];
        selected[divIndex] = item1;
        selected[dragItem.index] = item2;
        itemListChangeHandler(selected);
      }
      setDragItem(null);
    }
  };

  const removeItemHandler = (item: IUVDragSelectorItem) => {
    const selected = [...selectedItems].filter((v) => v.id !== item.id);
    itemListChangeHandler(selected);
  };
  const [selected, SetSelected] = useState<number>(-1);
  return (
    <div className="root-border ">
      <div className="row">
        <div className="col-md-12">
          <div className={styles.DragSelectorListCont}>
            <div className={styles.DragSelectorListInnerCont}>
              {selectedItems.map((item, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`i${index}`}
                  onDragOver={(event) => {
                    event.preventDefault();
                  }}
                  onDrop={(event) => {
                    event.preventDefault();
                    onDividerDropHandler(index);
                  }}
                  onClick={() => {
                    if (onItemClick) {
                      SetSelected(index);

                      onItemClick(item, index);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === '13' && onItemClick) {
                      SetSelected(index);
                      onItemClick(item, index);
                    }
                  }}
                >
                  <DragSelectorCardItem
                    item={item}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    itemIndex={index}
                    dragStartHandler={dragStartHandler}
                    type="selected"
                    removeItemHandler={removeItemHandler}
                    deleteIcon={<Delete fontSize="medium" />}
                    isSelected={selected === index}
                  />
                  <div className={styles.DragSelectorItemCardDivider} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UVListSort.defaultProps = {
  onItemClick: () => { appConsoleLog('default on Item Click'); },
};
