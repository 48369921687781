/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { appConsoleLog } from '../../utility/appUtils';
import {
  PRO_DC_CLEAR_MUTATION_V2,
  PRO_DC_CREATE_MUTATION,
  PRO_DC_DELETE_MUTATION,
  PRO_DC_ENABLE_DISABLE_ALL_DEVICES_V2,
  PRO_DC_GET_LOCATION_AND_MEMBERS,
  PRO_DC_GET_QUERY,
  PRO_DC_GET_STATUS,
  PRO_DC_SEARCH_QUERY,
  PRO_DC_UPDATE_MUTATION,
  PRO_DEVICE_SEARCH,
} from './query';

const proDCGetGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  prodc_id: string,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PRO_DC_GET_QUERY,
    variables: {
      prodc_id,
    },
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const proDCGetStatus = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  prodc_id: string,
) => {
  const res = await apolloClient.query({
    query: PRO_DC_GET_STATUS,
    variables: {
      prodc_id,
    },
  });
  return res;
};

const proDCSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  dc_type: number,
  dc_status: number,
  keyword: string,
  limit: number,
  skip: number,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PRO_DC_SEARCH_QUERY,
    variables: {
      project_id,
      dc_type,
      dc_status,
      keyword,
      limit,
      skip,
    },
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const proDCCreateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  serial_name: string,
  name: string,
  description: string,
  dc_type: number,
  dc_status: number,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_CREATE_MUTATION,
    variables: {
      input: {
        project_id,
        serial_name,
        name,
        description,
        dc_type,
        dc_status,
      },
    },
  });
  appConsoleLog(res);
  return res;
};

const proDCUpdateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
  name: string,
  description: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_UPDATE_MUTATION,
    variables: {
      input: {
        item_id,
        item: {
          name,
          description,
        },
      },
    },
  });
  return res;
};

const proDCDeleteGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_DELETE_MUTATION,
    variables: {
      item_id,
    },
  });
  return res.data;
};

const proDCClearHubGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
  status: number,
) => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_CLEAR_MUTATION_V2,
    variables: {
      status,
      proDcId: item_id,
    },
  });
  return res.data;
};

const proDCEnableDisableAllDevicesGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
  status: number,
) => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_ENABLE_DISABLE_ALL_DEVICES_V2,
    variables: {
      status,
      proDcId: item_id,
    },
  });
  return res.data;
};

const proDCLocationAndMemberSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  dc_name: string,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PRO_DC_GET_LOCATION_AND_MEMBERS,
    variables: {
      dc_name,
    },
    fetchPolicy: 'network-only',
  });
  return res.data;
};

export {
  proDCGetGraphQL,
  proDCSearchGraphQL,
  proDCCreateGraphQL,
  proDCUpdateGraphQL,
  proDCDeleteGraphQL,
  proDCClearHubGraphQL,
  proDCEnableDisableAllDevicesGraphQL,
  proDCGetStatus,
  proDCLocationAndMemberSearchGraphQL,
};
