export enum ComponentType {
  DC_CREATE = 'DC_CREATE',
  DC_EDIT = 'DC_EDIT',
  DEVICE_CREATE = 'DEVICE_CREATE',
  // more types
}

export enum EventType {
  DC_CREATE_FORM_ERROR = 'DC_CREATE_FORM_ERROR',
  DC_CREATE_FORM_SUBMIT = 'DC_CREATE_FORM_SUBMIT',
  DC_CREATE_FORM_CANCEL = 'DC_CREATE_FORM_CANCEL',
  DEVICE_CREATE_FORM_ERROR = 'DEVICE_CREATE_FORM_ERROR',
  ON_NEW_AUTH_TOKEN = 'ON_NEW_AUTH_TOKEN',
  // more event types
}

export enum EmitableEventType {
  RESET_FORM = 'RESET_FORM',
  // more emitable events
}
