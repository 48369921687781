/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { appConsoleLog } from '../../utility/appUtils';

export enum ImageUploadTypes {
  USER_PROFILE = 'USER_PROFILE',
  PROJECT_IMAGE = 'PROJECT_IMAGE',
}
export class SharedFunctions {
  public static async imageUpload(
    token: string,
    file: any,
    type: string,
    id: string,
  ): Promise<{
    data: {
      imageUpload: {
        imageId: string;
      };
    };
  }> {
    try {
      const op = {
        query: `
            mutation ($file: Upload!) {
              imageUpload(file: $file, input: {
                upload_for: "${type}",
                item_id: "${id}"
              }) {
                image_id
              }
            }
          `,
        variables: {
          file: null as any,
        },
      };

      const _map = {
        0: ['variables.file'],
      };

      const fd = new FormData();
      fd.append('operations', JSON.stringify(op));
      fd.append('map', JSON.stringify(_map));
      fd.append('0', file, file.name);

      const headers = {
        authorization: token,
      };

      const res = await axios.post(process.env.REACT_APP_APOLLO_SERVER_URL || '', fd, {
        headers,
      });
      return res.data;
    } catch (err) {
      appConsoleLog('createUserEntity 3 err', err);
      throw err;
    }
  }
}
