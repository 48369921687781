import React from 'react';
import styles from './ItemSortDialog.module.css';

export interface IItemSortCardItem {
  id: string;
  title: string;
  subTitle: string;
  iconName: string;
}
type ComponentProps = {
  item: IItemSortCardItem;
  itemIndex: number;
};

export const ItemSortCardItem: React.FunctionComponent<ComponentProps> = ({ item, itemIndex }) => (
  <div className={styles.DragSelectorItemCard}>
    <div>
      <p className={styles.itemCardTitle}>{item.title}</p>
      <span className={styles.itemCardSubTitle}>{item.subTitle}</span>
    </div>
    <div className={styles.spacer} />
    <span className={`calc ${item.iconName} ${styles.calcIconView}`} />
  </div>
);
