import {
  Alert, Box, Button, Tooltip, Typography,
} from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  ConfirmDelete,
  ConfirmProcessMinimum,
} from 'src/components/shared/CustomDeleteModal/ConfirmDelete';
import { useState } from 'react';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const ResetEnergy: React.FC<{deviceId: string}> = ({ deviceId }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onComfirm = async () => {
    try {
      const res = await pluginHook.commonIntegrationService.resetDeviceEnergyRecords(deviceId);
      enqueueSnackbar('Energy records removed successfully.', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Unable to remove energy records', { variant: 'error' });
    }
  };

  return (
    <>
      <Tooltip title="Delete the enegery records related to this device">
        <Button
          style={{ marginBottom: 10 }}
          variant="outlined"
          color="warning"
          onClick={() => setShowConfirmation(true)}
          startIcon={<RotateLeftIcon />}
        >
          Reset Energy Records
        </Button>
      </Tooltip>
      <ConfirmProcessMinimum
        open={showConfirmation}
        setOpen={setShowConfirmation}
        title="Confirmation to proceed."
        fullText="Are you sure you want to delete the recorded energy usage data of this device ? Note that process cannot be undo."
        onConfirm={onComfirm}
      />
    </>
  );
};

const ResetSettings: React.FC<{deviceId: string}> = ({ deviceId }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onComfirm = async () => {
    try {
      const res = await pluginHook.commonIntegrationService.resetDeviceSttingsToDefaults(deviceId);
      enqueueSnackbar('Device setting reset to defaults.', { variant: 'success' });
      setTimeout(() => {
        location.reload();
      }, 500);
    } catch (err) {
      enqueueSnackbar('Unable to reset device settings', { variant: 'error' });
    }
  };

  return (
    <>
      <Tooltip title="Reset all the enegry monitoring configurations, Event contigurations & device elog configurations to its default values">
        <Button
          style={{ marginBottom: 10 }}
          variant="contained"
          color="warning"
          onClick={() => setShowConfirmation(true)}
          startIcon={<RotateLeftIcon />}
        >
          Reset Device Settings To Default
        </Button>
      </Tooltip>
      <ConfirmProcessMinimum
        open={showConfirmation}
        setOpen={setShowConfirmation}
        title="Confirmation to proceed."
        fullText="Are you sure you want to reset the device configurations to its defaults values ? Note that this process cannot be undo and this will reset all the energy monitoring configurations and event, elog configurations."
        onConfirm={onComfirm}
      />
    </>
  );
};

const DeleteDevice: React.FC<{deviceId: string}> = ({ deviceId }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const onComfirm = async () => {
    try {
      const res = await pluginHook.commonIntegrationService.forceDeleteDeviceRecord(deviceId);
      enqueueSnackbar('Device Deleted', { variant: 'success' });
      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (err) {
      enqueueSnackbar('Unable to delete device', { variant: 'error' });
    }
  };

  return (
    <>
      <Tooltip title="Forcefully remove the device from SmartLife">
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteForeverIcon />}
          onClick={() => setShowConfirmation(true)}
        >
          Force Delete Device
        </Button>
      </Tooltip>
      <ConfirmProcessMinimum
        open={showConfirmation}
        setOpen={setShowConfirmation}
        title="Confirmation to delete."
        fullText="Are you sure you want to delete the device ? Note that this process will only remove the device from SmartLife side only."
        onConfirm={onComfirm}
      />
    </>
  );
};

export const DeviceSettingsDangerZone: React.FC<{deviceId: string}> = ({ deviceId }) => (
  <Box
    style={{
      padding: 10,
      border: '1px solid red',
      marginTop: 20,
      borderRadius: 5,
    }}
  >
    <Typography variant="subtitle2" style={{ color: 'red' }}>
      Device Danger Zone
    </Typography>
    <Box height={10} />
    <Alert color="warning">
      Following actions can not be undo. Make sure you know what you are doing.
    </Alert>
    <Box height={10} />
    <Box style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
      <ResetEnergy deviceId={deviceId} />
      <ResetSettings deviceId={deviceId} />
      <DeleteDevice deviceId={deviceId} />
    </Box>
  </Box>
);
