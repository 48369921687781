export enum COMPONENT_UNIT_TYPES {
  Switch = 'switch',
  Sensor = 'sensor',
  Slider = 'slider',
  Mode = 'mode',
}

export const ECloudIntDeviceStatus = {
  1: {
    label: 'Pending',
    color: '#898686',
  },
  2: {
    label: 'Active',
    color: '#12ea16',
  },
  3: {
    label: 'Disabled',
    color: '#a36c42',
  },
  4: {
    label: 'Access Removed',
    color: '#bc6bff',
  },
  5: {
    label: 'Not A Registered Device Model',
    color: '#b5e7ff',
  },
  6: {
    label: 'Demo Device Model',
    color: '#898686',
  },
};
