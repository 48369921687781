/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Icon } from '@mui/material';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';

import { ZoneSelectorItem } from './ZoneSelectorItem';
import {
  IDFField,
  IZoneAvailable,
  zonesUsedValuesMap,
} from '../../../../../config/deviceConfig/types';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';
import { appConsoleLog } from '../../../../../utility/appUtils';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.zones;
type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  deviceInfo: any;
  resetForm: IFormResetRef;
  onChange: (payload: any, isInitializing?: boolean) => void;
};

interface IformData {
  zones: any;
  // zones: { [key: string]: IZone[] };
}

// const arrayToMap = (data: any[]) => {
//   let dataMap: any = {};
//   for (let i = 0; i < data.length; i++) {
//     dataMap[i] = data[i];
//   }
//   return dataMap;
// };

// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldZones: React.FunctionComponent<ComponentProps> = (props) => {
  // console.log('------------------------------', props.deviceInfo.settings);
  const [deb, setDeb] = useState<BehaviorSubject<{values: IformData; errors: any} | null>>();
  const [zoneKeyList, setZoneKeyList] = useState<IZoneAvailable[]>([]);
  const [areaList, setAreaList] = useState<any[]>();
  useEffect(() => {
    const modal = props.deviceInfo.settings && props.deviceInfo.settings.modal
      ? props.deviceInfo.settings.modal
      : null;
    let modalValue;
    if (
      !modal
      || (props.fieldConfig.extra.includes('custom-values')
        && !props.fieldConfig.extra.includes('custom-area-selector'))
    ) {
      modalValue = 8;
      // return;
    } else {
      modalValue = +modal.split('x')[0];
    }

    const keyList: IZoneAvailable[] = [];
    if (
      props.fieldConfig.extra.includes('custom-selector-zones')
      || props.fieldConfig.extra.includes('custom-selector-outputs')
    ) {
      // eslint-disable-next-line max-len
      // const limit = props.fieldConfig.extra.includes('custom-selector-zones') ? Number(props.fieldConfig.extra.split('custom-selector-zones')) : Number(props.fieldConfig.extra.split('custom-selector-outputs'));
      const limit = 64;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < limit; i++) {
        keyList.push({
          value: i + 1,
          asigned_key: '',
        });
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < Number(modalValue); i++) {
        keyList.push({
          value: i + 1,
          asigned_key: '',
        });
      }
    }
    console.log('keyList', keyList);
    setZoneKeyList(keyList);
  }, [props.deviceInfo.settings.modal]);

  useEffect(() => {
    console.log('props.deviceInfo.settings.area', props.deviceInfo.settings);

    if (props.deviceInfo.settings && props.deviceInfo.settings.areas) {
      setAreaList(props.deviceInfo.settings.areas);
    }
  }, [JSON.stringify(props.deviceInfo)]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {
    const values = formData;
    const infoData: any = {
      settings: {},
    };
    infoData.settings[props.fieldConfig.field] = Object.values(values.zones);
    mounted.current = 1;
    props.onChange({
      action: 'data',
      form: FIELD_NAME,
      data: infoData,
      errors: {},
    });
  };

  // const sendDefaults = () => {
  //   if (!props.fieldConfig || !deb) {
  //     return;
  //   }
  //   deb.next({
  //     values: {
  //       zones: props.fieldConfig?.default,
  //     },
  //     errors: {},
  //   });
  // };

  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({ ref: 0, action: '', info: {} });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        const values = props.resetForm.info.settings[props.fieldConfig.field];
        formRef.current.setValues({
          zones: values,
        });
      } else {
        formRef.current.resetForm();
      }
    }
  }, [props.resetForm]);

  useEffect(() => {
    const b = new BehaviorSubject<{values: IformData; errors: any} | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }

        const { values, errors } = v;
        const infoData: any = {
          settings: {},
        };
        infoData.settings[props.fieldConfig.field] = Object.values(values.zones);

        if (mounted.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          mounted.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    // sendDefaults();
    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  const mounted = useRef(0);
  // useEffect(() => {
  //   if (mounted.current === 1) return;
  //   if (!props.fieldConfig || !props.fieldConfig?.default) return;
  //   const { values, errors } = {
  //     values: {
  //       zones: props.fieldConfig?.default,
  //     },
  //     errors: {},
  //   };
  //   const infoData: any = {
  //     settings: {},
  //   };
  //   infoData.settings[props.fieldConfig.field] = Object.values(values.zones);
  //   props.onChange({
  //     action: 'data',
  //     form: FIELD_NAME,
  //     data: infoData,
  //     errors,
  //   }, true);
  //   mounted.current = 1;
  // }, [props.fieldConfig]);

  const formRef = useRef<any>();
  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);

  let isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;
  if (isEditable) {
    if (props.deviceInfo.settings[`has_${props.fieldConfig.field}`] === false) {
      isEditable = false;
    }
  }

  return isEditable === true ? (
    <div className="dynamic-ui-form-features">
      <h4>
        {props.fieldConfig.labels}
        {/* :
        {props.fieldConfig && JSON.stringify(props.fieldConfig?.default)}
        :
        {props.deviceInfo.settings && JSON.stringify(props.deviceInfo.settings)} */}
      </h4>
      <Formik
        initialValues={{
          zones: props.fieldConfig?.default,
        }}
        enableReinitialize
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          zones: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().required('Value required'),
            }),
          ),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values,
        }) => {
          deb?.next({
            values,
            errors,
          });

          return (
            <FieldArray
              name="zones"
              render={(arrayHelpers) => (
                <div>
                  {values.zones && Object.keys(values.zones).length > 0 ? (
                    values.zones
                      .filter((v: any) => v != null && v !== undefined)
                      .map((zone: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={`${index}`}>
                          <ZoneSelectorItem
                            zoneModal={props.deviceInfo.settings.modal}
                            fieldConfig={props.fieldConfig}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            arrayHelpers={arrayHelpers}
                            label={props.fieldConfig.labels}
                            formDataZoneValues={values.zones}
                            zoneKeyList={zoneKeyList}
                            index={index}
                            zone={zone}
                            areas={areaList}
                          />
                        </div>
                      ))
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Icon>add</Icon>}
                      onClick={() => {
                        arrayHelpers.push({
                          label: '',
                          value: '',
                          key: Date.now(),
                        });
                        appConsoleLog(arrayHelpers);
                      }}
                    >
                      Add a
                      {' '}
                      {props.fieldConfig?.labels}
                    </Button>
                  )}
                </div>
              )}
            />
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
