/* eslint-disable camelcase */
import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Box,
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Button,
  Input,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Layout from '../../../components/layout/Layout';
import { PageHeader } from '../../../components/layout/PageHeader';
import { AuthContext } from '../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import PageContentLayout, { PageConentHeader } from '../LogicsEngine/components/PageContentLayout';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    marginBottom: '4%',
    overflow: 'scroll',
  },
  root: {
    // height: "100%",
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  formRoot: {
    width: '100%',
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const BackupCreateForm = () => {
  const classes = useStyle();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [initial_form, setInitialForm] = useState({
    project_id: '',
    backup_name: '',
    backup_desc: '',
    file: null,
  });

  const authUser: any = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);

  const backup_serial = useQueryString('backup_serial') as string;
  const backup_type = useQueryString('type') as string;
  const backup_title = useQueryString('t') as string;
  const backup_desc = useQueryString('desc') as string;

  useEffect(() => {
    setInitialForm({
      project_id: '',
      backup_name: backup_title,
      backup_desc,
      file: null,
    });
  }, [backup_serial, backup_type, backup_title, backup_desc]);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={initial_form}
      enableReinitialize
      validationSchema={Yup.object().shape({
        backup_name: Yup.string().required('Backup Name is required'),
      })}
      onSubmit={async (values) => {
        try {
          setIsSubmitting(true);
          const { token } = authUser.access_token;
          if (backup_serial) {
            const res = await axios.post(
              `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
              }/rest/backups/v1/project-backup/project/point/update`,
              {
                backup_serial,
                name: values.backup_name,
                description: values.backup_desc,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
          } else {
            if (values.file) {
              // uploading
              const formData = new FormData();
              formData.set('name', values.backup_name);
              formData.set('description', values.backup_desc);
              formData.set('project_id', selectedProject.selected_project.id);
              formData.set('backup_file', values.file);

              const res = await axios.post(
                `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
                }/rest/backups/v1/project-backup/point/upload`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
              return;
            }
            const res = await axios.post(
              `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
              }/rest/backups/v1/project-backup/project/point/create`,
              {
                project_id: selectedProject.selected_project.id,
                name: values.backup_name,
                description: values.backup_desc,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
          }
        } catch (err: any) {
          enqueueSnackbar(err.message, { variant: 'error' });
        } finally {
          enqueueSnackbar(backup_serial ? 'Backup Point Updated' : 'Backup Point Created', {
            variant: 'success',
          });
          setIsSubmitting(false);
          navigate(`/app/backup-restore?projectId=${selectedProject.selected_project.id}`);
        }
      }}
    >
      {({
        errors, values, handleChange, touched, handleSubmit, setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              name="backup_name"
              value={values.backup_name}
              onChange={handleChange}
              label="Backup Name"
              variant="outlined"
              className={classes.formRoot}
              error={Boolean(touched.backup_name && errors.backup_name)}
              helperText={touched.backup_name && errors.backup_name}
            />
            <Box height={15} />
            <FormControl
              variant="outlined"
              error={Boolean(touched.backup_name && errors.backup_name)}
              className={classes.formRoot}
            >
              <TextareaAutosize
                placeholder="Backup Description"
                name="backup_desc"
                onChange={handleChange}
                minRows={5}
                maxRows={6}
                value={values.backup_desc}
              />
            </FormControl>
            {!backup_serial && (
            <>
              <Box height={15} />
              <FormControl
                variant="outlined"
                error={Boolean(touched.backup_name && errors.backup_name)}
                className={classes.formRoot}
              >
                <Input
                  type="file"
                  name="file"
                  placeholder="Backup file"
                  onChange={(event) => {
                    setFieldValue('file', (event.currentTarget as any).files[0]);
                  }}
                />
                <FormHelperText>
                  If you want to create a backup point from an existing backup file then upload
                  it, if you keep this empty backup point will use the current state of this
                  location
                </FormHelperText>
              </FormControl>
            </>
            )}

            <Box height={15} />
            <Box className={classes.btns}>
              <Button
                color="primary"
                onClick={() => {
                  navigate(
                    `/app/backup-restore?projectId=${selectedProject.selected_project.id}`,
                  );
                }}
              >
                Cancel
              </Button>
              <Button disabled={isSubmitting} color="primary" variant="contained" type="submit">
                {backup_serial ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const BackupCreate = () => {
  const styles = useStyle();
  const authUser: any = useContext(AuthContext);
  useFirebaseConnect(`system_admins/${authUser.uid}`);

  const backup_serial = useQueryString('backup_serial') as string;

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>
            {backup_serial ? 'Edit Backup' : 'Create Backup'}
          </Typography>
        </Box>
      </PageHeader>
      <Box className={styles.contentRoot}>
        <PageContentLayout className={styles.root}>
          <PageConentHeader>
            <Typography variant="h5" component="h2">
              {backup_serial ? 'Edit Backup Point' : 'Create Backup Point'}
            </Typography>
          </PageConentHeader>
          <Box mt={3}>
            <BackupCreateForm />
          </Box>
        </PageContentLayout>
      </Box>
    </Layout>
  );
};

export default BackupCreate;
