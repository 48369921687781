import React, { useContext, useEffect, useReducer } from 'react';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';

export interface IItemDefaultsContext {
  data: any;
}
const getInitialItemDefaults = (): IItemDefaultsContext => ({ data: null });

export const ItemDefaultsContext = React.createContext<IItemDefaultsContext>(
  getInitialItemDefaults(),
);

export enum itemDefaultsReducerTypes {
  INITIALISE = 'INITIALISE',
  SET = 'SET',
}
const itemDefaultsReducer = (state: IItemDefaultsContext, action: {type: string; payload: any}) => {
  const { type, payload } = action;

  switch (type) {
    case 'INITIALISE': {
      const initState = getInitialItemDefaults();
      return {
        ...state,
        ...initState,
      };
    }
    case 'SET': {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export function ItemDefaultsComponent({ children, ...props }: any) {
  // get item creation json from app info graphql request
  const userProfile = useContext(ApolloAuthContext);

  const [state, dispatch] = useReducer(itemDefaultsReducer, getInitialItemDefaults());

  // useEffect(() => {
  //   try {
  //     const init = async () => {
  //       const appInfo = await AppInfoGet(userProfile.apollo_client);
  //       appConsoleLog(appInfo);

  //       const data: any = JSON.parse(appInfo.item_defaults);
  //       appConsoleLog(data);

  //       dispatch({
  //         type: itemDefaultsReducerTypes.SET,
  //         payload: data,
  //       });
  //     };
  //     init();
  //   } catch (err) {
  //     appConsoleError(err);
  //   }
  // }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ItemDefaultsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ItemDefaultsContext.Provider>
  );
}

export default ItemDefaultsComponent;
