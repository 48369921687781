/* eslint-disable default-case */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { number } from 'yup';
import { cloneDeep } from 'lodash';
import {
  CronIntervalVariables,
  CronReoccurentState,
  CronScheduleVariables,
  CronState,
  CronSunsetSunriseVariables,
  EXECUTION_LIMIT,
  INTERVAL_BASED_ON,
  REOCCURING_BASED_ON,
  SUNSET_SUNRISE,
  SUNSET_SUNRISE_FROM,
  SUNSET_SUNRISE_TIME_UNIT,
  TIME_FRAME,
} from '@smartlife-redux-state/common';
import { DatePickerUtils, TimePickerUtils } from '../DateAdapters';

import { DaysOfWeek } from '../CRON_TYPES';
import { TimeSelector } from '../components/TimeSelector';
import { CronRunCountSelector } from '../components/CronRunCountSelector';
import { CronRelativeToSun } from '../components/CronRelativeToSun';
import { CronScheduler } from './shared/CronScheduler';
import { CronSunsetSunrise } from './shared/CronSunsetSunrise';
import { CronInterval } from './shared/CronInterval';

const renderCronForm = (
  type: string,
  variables: CronScheduleVariables | CronIntervalVariables | CronSunsetSunriseVariables,
  onChange: (
    data: CronSunsetSunriseVariables | CronIntervalVariables | CronSunsetSunriseVariables
  ) => void,
) => {
  switch (type) {
    case 'time':
      return (
        <Grid item md={12}>
          <CronScheduler
            variables={variables as CronScheduleVariables}
            onChange={(data: CronScheduleVariables) => onChange(data as any)}
          />
        </Grid>
      );
    case 'relative_to_sun':
      return (
        <Grid item md={12}>
          <CronSunsetSunrise
            variables={variables as CronSunsetSunriseVariables}
            onChange={onChange}
          />
        </Grid>
      );
    case 'interval':
      return (
        <Grid item md={12}>
          <CronInterval variables={variables as CronIntervalVariables} onChange={onChange} />
        </Grid>
      );
    default:
      return <Grid item md={12} />;
  }
};

const useStyles = makeStyles({
  root: {},
  dayCard: {
    background: '#818181',
    borderRadius: '2px',
    margin: '2px',
    padding: '5px',
    minWidth: '50px',
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
  },
  dayCardActive: {
    background: '#048696',
  },
});

type ComponentProps = {
  typeKey: string;
  cronState: CronState;
  onChange: (
    data: CronReoccurentState,
    execution_limit?: {
      type: EXECUTION_LIMIT;
      value: number;
    }
  ) => void;
};
// eslint-disable-next-line import/prefer-default-export
export const RecurrentCronSelector
: React.FC<ComponentProps> = ({ typeKey, cronState, onChange }) => {
  const cronstate = cronState.data as CronReoccurentState;
  const classes = useStyles();

  const [recurrentType, setRecurrentType] = useState<string>(null);

  // useEffect(() => {
  //   if((cronState.data as CronReoccurentState).reoccuring_based_on) {
  //     setRecurrentType((cronState.data as CronReoccurentState).reoccuring_based_on);
  //   }
  // }, [])

  useEffect(() => {
    if (!recurrentType) return;
    if (cronstate.reoccuring_based_on === recurrentType) return;

    switch (recurrentType) {
      case 'time': {
        onChange({
          days: cronstate.days,
          reoccuring_based_on: REOCCURING_BASED_ON.SCHEDULE,
          variables: {
            time: '07:30',
            time_frame: TIME_FRAME.AM,
          },
        });
        break;
      }
      case 'interval': {
        onChange({
          days: cronstate.days,
          reoccuring_based_on: REOCCURING_BASED_ON.INTERVAL,
          variables: {
            based_on: INTERVAL_BASED_ON.EVERY_HOUR,
            value: 1,
            range: {
              from: {
                value: 12,
                meridiem: TIME_FRAME.AM,
              },
              to: {
                value: 11,
                meridiem: TIME_FRAME.PM,
              },
            },
          },
        });
        break;
      }
      case 'relative_to_sun': {
        onChange({
          days: cronstate.days,
          reoccuring_based_on: REOCCURING_BASED_ON.SUNSET_SUNRISE,
          variables: {
            when: SUNSET_SUNRISE_FROM.ON,
            based_on: SUNSET_SUNRISE.SUNRISE,
            time_unit: SUNSET_SUNRISE_TIME_UNIT.HOURS,
            number_of_execution: 1,
          },
        });
        break;
      }
    }
  }, [recurrentType]);

  const handleDayItemClick = (dKey: string) => {
    const sdlist = { ...cronstate.days };
    if (sdlist[dKey]) {
      if (hasAnotherSelectedDate(sdlist, dKey)) {
        delete sdlist[dKey];
      } else {
        return;
      }

      // setSelectedDays(sdlist);
    } else {
      sdlist[dKey] = true;
      // setSelectedDays(sdlist);
    }
    onChange({
      days: sdlist,
      reoccuring_based_on: cronstate.reoccuring_based_on,
      variables: cronstate.variables,
    });
  };

  function hasAnotherSelectedDate(days: {[day: string]: boolean}, trying_to_remove: string) {
    const copy = cloneDeep(days);

    delete copy[trying_to_remove];
    const found = Object.keys(copy).find((key) => copy[key] === true);

    if (found) {
      return true;
    }
    return false;
  }

  function onVariablesChange(
    data: CronSunsetSunriseVariables | CronIntervalVariables | CronSunsetSunriseVariables,
  ) {
    onChange({
      days: cronstate.days,
      reoccuring_based_on: cronstate.reoccuring_based_on,
      variables: data,
    });
  }

  function onExecutionLimitChange(limit: {type: EXECUTION_LIMIT; value: number}) {
    onChange({ ...(cronState.data as CronReoccurentState) }, limit);
  }

  return (
    <Grid container>
      <Grid container spacing={1}>
        <Grid item md={12}>
          Repeat On Every
        </Grid>
        <Grid item md={12}>
          <Grid container>
            {DaysOfWeek.map((d) => (
              <Box
                className={
                    `${classes.dayCard
                    } ${
                      cronstate && cronstate.days[d.value] ? classes.dayCardActive : ''}`
                  }
                onClick={() => handleDayItemClick(d.value)}
                key={d.value}
              >
                {d.label}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <FormControl style={{ margin: '2% 0' }}>
          <RadioGroup
            row
            name="position"
            defaultValue="unlimited"
            value={cronstate.reoccuring_based_on}
            onChange={(event) => {
              setRecurrentType(event.target.value);
            }}
          >
            <FormControlLabel value="time" control={<Radio color="primary" />} label="Schedule" />
            <FormControlLabel
              value="interval"
              control={<Radio color="primary" />}
              label="Interval base"
            />
            <FormControlLabel
              disabled
              value="relative_to_sun"
              control={<Radio color="primary" />}
              label="Sunset/Sunrise"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container>
        {cronstate
          && cronstate.reoccuring_based_on
          && renderCronForm(
            cronstate.reoccuring_based_on,
            (cronState.data as CronReoccurentState).variables,
            onVariablesChange,
          )}
      </Grid>

      <CronRunCountSelector
        typekey={typeKey}
        initialValue={cronState.execution_limit}
        onChange={(count) => {
          onExecutionLimitChange(count);
        }}
      />
    </Grid>
  );
};
