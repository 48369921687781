/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component use to list Air device
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to list Air device
 * @author Dinuka Bandara
 * @since -
 */
import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';

import { Box, Chip } from '@mui/material';
import { UVConfirmDialog } from '../../UserViewComponents/UIItems/Dialogs/UVConfirmDialog/UVConfirmDialog';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import styles from './DeviceCard.module.css';
import { ErrorSnackbarOptions, ErrorSnackbarActions } from '../../Alters/Snackbar/SnackbarOptions';
import { airDeviceDeleteGraphQL } from '../../../services/air-device/air-device.service';
import { appApiErrorMessage, appConsoleError } from '../../../utility/appUtils';
import { IAirDevice } from '../../../containers/app/AirDeviceCreateEdit/types';
import { ITEM_DEFAULTS } from '../../../config/deviceConfig/item_defaults';

type ComponentProps = {
  device: IAirDevice;
  refreshList: () => void;
  onClick: () => void;
};

const AIR_DEVICE_STATUS = {
  1: 'pending',
  2: 'active',
  3: 'inactive',
  4: 'deleted',
};

// 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
const CHIP_COLORS = {
  1: 'info',
  2: 'primary',
  3: 'warning',
  4: 'error',
};

/**
 * Pro Device Card
 *
 * @callback onClickCard
 * @callback onRefrechCalled
 * @param {Object} props                      component props
 * @param {Object} props.device               device payload IAirDevice
 * @param {onClickCard} props.onClick         Fires when user click the card button
 * @param {onRefrechCalled} props.refreshList Callback fires after user delete the device
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const DeviceCardAir: React.FunctionComponent<ComponentProps> = ({
  device,
  refreshList,
  onClick,
}) => {
  const snackbar = useSnackbar();
  const userProfile = useContext(ApolloAuthContext);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [deleteState, setDeleteState] = useState<boolean>(false);
  const [projectName] = useState(qs.parse(window.location.search).name);
  const navigateRoute = (path: string) => {
    navigate(path);
  };
  const deleteDeviceConfirm = async () => {
    try {
      if (!device.id) {
        throw new Error('Device id not valid');
      }
      await airDeviceDeleteGraphQL(userProfile.apollo_client, device.id);
      refreshList();
    } catch (err: any) {
      appConsoleError(err);
      let errorMessage = 'Unexpected error';
      if (typeof err === 'string') {
        errorMessage = err;
      } else if (err && err.message) {
        errorMessage = appApiErrorMessage(err.message);
      }
      snackbar.enqueueSnackbar(errorMessage, {
        key: 'dc-device-delete-update-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // console.log(device);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuAction?: string) => {
    setAnchorEl(null);
    if (menuAction === 'view') {
      navigateRoute(
        `/app/device-container/device?projectId=${device.project_id}&dcType=air&deviceId=${device.id}`,
      );
    }
    if (menuAction === 'delete') {
      setDeleteState(true);
    } else if (menuAction === 'edit') {
      navigateRoute(
        `/app/device-container/air/update/device?projectId=${device.project_id}&dcId=${device.airdc_id}&deviceId=${device.id}&dcName=${projectName}`,
      );
    }
  };
  const airDeviceTypeConfigs: any = ITEM_DEFAULTS.ITEM_CREATOR.AIR_DEVICES.configs;
  function capitalizeFirstLetter(string) {
    if (!string) return 'Unknown';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div className={styles.root}>
      <div className={styles.actionsMenu}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleClose('')}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem key="edit" onClick={() => handleClose('view')}>
            Device Links
          </MenuItem>
          <MenuItem key="edit" onClick={() => handleClose('edit')}>
            Edit
          </MenuItem>
          <MenuItem key="delete" onClick={() => handleClose('delete')}>
            Delete
          </MenuItem>
        </Menu>
        <div style={{ paddingRight: '10px' }}>
          <Chip label={capitalizeFirstLetter(AIR_DEVICE_STATUS[device.device_status])} color={CHIP_COLORS[device.device_status]} size="small" variant="outlined" />
        </div>
        <UVConfirmDialog
          dialogOpen={deleteState}
          dialogCloseEvent={(e: any) => {
            setDeleteState(false);
            if (e === true) {
              deleteDeviceConfirm();
            }
          }}
          title="Device Delete"
          text={`Are you sure you want to delete '${device.name},' device?`}
        />
      </div>
      <div className={styles.content}>
        <span className={`pl-8 calc calc-icon-uv-primary calc-light1 ${styles.imgBgStyle}`} />

        <div
          role="button"
          tabIndex={0}
          className={styles.dataAir}
          onClick={onClick}
          onKeyDown={(e) => e.key === '13' && onClick()}
        >
          <div className={`${styles.title} ml-1`}>{device.name}</div>

          <div className={`${styles.type} ml-1`}>
            {airDeviceTypeConfigs[device.device_type]?.title}
          </div>
          <div className={`${styles.type} ml-1`}>{` (${device.cat} - ${device.sub_cat}) `}</div>
          <div className={`${styles.type} ml-1`}>{device.tuya_device_id}</div>
        </div>
      </div>
    </div>
  );
};
