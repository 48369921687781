/* eslint-disable camelcase */
import {
  Box, Grid, MenuItem, Select, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  AirDevice,
  Check,
  DeviceCollectionSearchOutItem,
  OPERATORS,
  ProDevice,
} from '@smartlife-redux-state/common';
import { update_check } from 'src/store/redux/features/logic-engine';
import { cloneDeep, isArray } from 'lodash';
import { RootState } from '../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../LogicStepper';
import { DeviceSelector } from '../shared/DeviceSelector';
import { ExpressionSelector } from '../shared/ExpressionSelector';

// eslint-disable-next-line max-len
const OperatorSelector: React.FC<{op: any; onOpChange: (op: any) => void}> = ({ op, onOpChange }) => (
  <Box>
    <Select value={op} onChange={(e) => onOpChange(e.target.value)} size="small" style={{ minWidth: 150 }}>
      <MenuItem value="=">Equal To</MenuItem>
      <MenuItem value=">">Grater than To</MenuItem>
      <MenuItem value="<">Less than To</MenuItem>
      <MenuItem value=">=">Grater than or equal To</MenuItem>
      <MenuItem value="<=">Less than or equal To</MenuItem>
      <MenuItem value="!=">Not Equal To</MenuItem>
    </Select>
  </Box>
);

// eslint-disable-next-line import/prefer-default-export
export const DeviceStaeteComparisonCheck: React.FC<{check: Check}> = ({ check }) => {
  const dispatch = useDispatch();
  const dc = useSelector((state: RootState) => {
    if (!check) return null;
    const item = state.rule_engine.checks.content.find((checkItem) => checkItem.id === check.id);
    if (item) {
      return item.dc;
    }
    return null;
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function onDcSelect(dcArg: DeviceCollectionSearchOutItem, rhs = false) {
    if (!dcArg) return;
    const dcs = resolveField(dcArg, check.dc, rhs ? 2 : 1);

    dispatch(
      update_check({
        check: {
          ...check,
          dc: dcs,
        },
      }),
    );
    // if (rhs) {
    //   dispatch(
    //     update_check({
    //       check: {
    //         ...check,
    //         val: {
    //           dcId: dcArg.id,
    //         },
    //       },
    //     }),
    //   );
    //   return;
    // }
    // dispatch(
    //   update_check({
    //     check: {
    //       ...check,
    //       dc: dcArg,
    //     },
    //   }),
    // );
  }

  function onDeviceSelect(device: AirDevice | ProDevice, pos = 1) {
    if (!device) return;
    const devices = resolveField(device, check.device, pos);

    let resetVar = false;
    let resetVarType = false;
    if (isArray(devices) && isArray(check.device)) {
      if (devices[pos - 1] && check.device[pos - 1]) {
        resetVar = true;
        resetVarType = true;
      }
    }

    dispatch(
      update_check({
        check: {
          ...check,
          val: null,
          // variable: resetVar ? null : check.variable,
          // possible_variable_type: resetVarType ? null : check.possible_variable_type,
          device: devices,
        },
      }),
    );
  }

  function onExpressionChange(
    op: OPERATORS,
    value: any,
    variable: {field: string; type: string},
    pos = 1,
  ) {
    console.log('ON EXPRESSION CHANGED', op, value, variable);
    const variables = resolveField(variable.field, check.variable, pos);
    const type = resolveField(variable.type, check.possible_variable_type, pos);
    dispatch(
      update_check({
        check: {
          ...check,
          variable: variables,
          possible_variable_type: type,
        },
      }),
    );
  }

  function onOpChange(op) {
    dispatch(
      update_check({
        check: {
          ...check,
          op,
        },
      }),
    );
  }

  function resolveField(payload: any, current: any, pos = 1) {
    let pendingPayload: any = cloneDeep(current);
    if (pos === 1 && !isArray(pendingPayload)) {
      pendingPayload = [payload];
    } else if (pos === 1 && isArray(pendingPayload)) {
      pendingPayload[0] = payload;
    } else if (pos === 2 && !isArray(pendingPayload)) {
      pendingPayload = [{ ...pendingPayload }, payload];
    } else if (pos === 2 && isArray(pendingPayload)) {
      pendingPayload[1] = payload;
    }
    return pendingPayload;
  }

  function resolveCurrentValue(payload: any, pos = 1) {
    if (!isArray(payload) && pos === 1) return payload;

    if (!isArray(payload) && pos === 2) return null;

    return payload[pos - 1];
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Grid item xs={12} md={12}>
          <DeviceSelector
            selectedDC={resolveCurrentValue(dc, 1)}
            selectedDevice={check ? resolveCurrentValue(check.device) : null}
            onDcSelect={onDcSelect}
            onDeviceSelect={onDeviceSelect}
            logic_step={LogicStepsTypes.CHECK}
            disableDeviceClearable
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box height={10} />
          <ExpressionSelector
            selectedOperator={null}
            selectedVariable={{
              field: resolveCurrentValue(check.variable, 1),
              type: resolveCurrentValue(check.possible_variable_type, 1),
            }}
            selecteValue={null}
            onChange={onExpressionChange}
            device={resolveCurrentValue(check.device, 1)}
            dc={resolveCurrentValue(dc, 1)}
            behaveAs="justVariableSelector"
          />
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <OperatorSelector op={check.op} onOpChange={onOpChange} />
        </Box>
        <Box height={10} />
      </Grid>
      <Grid item md={12}>
        <Grid item xs={12} md={12}>
          <DeviceSelector
            disbaledDevice={check ? resolveCurrentValue(check.device, 1) : null}
            selectedDC={resolveCurrentValue(dc, 2)}
            selectedDevice={check ? resolveCurrentValue(check.device, 2) : null}
            onDcSelect={(dcVal) => onDcSelect(dcVal, true)}
            onDeviceSelect={(device) => onDeviceSelect(device, 2)}
            logic_step={LogicStepsTypes.CHECK}
            disableDeviceClearable
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box height={10} />
          <ExpressionSelector
            selectedOperator={null}
            selectedVariable={{
              field: resolveCurrentValue(check.variable, 2),
              type: resolveCurrentValue(check.possible_variable_type, 2),
            }}
            selecteValue={null}
            // eslint-disable-next-line max-len
            onChange={(op: OPERATORS, value: any, variable: {field: string; type: string}) => onExpressionChange(op, value, variable, 2)}
            device={resolveCurrentValue(check.device, 2)}
            dc={resolveCurrentValue(dc, 2)}
            behaveAs="justVariableSelector"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
