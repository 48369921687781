/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Dialog componet use for create/edit/delete link.
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the Re14Item create/Edit/delete Dialog and functionalities
 * @author Maduka Dilshan
 * @since 0.1.15
 */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Dialog, DialogTitle, Divider, TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  createProjectDiagram,
  updateProjectDiagram,
} from '../../../services/location/location.service';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { Link } from './Re14Item';

type propTypes = {
  open: boolean;
  isEditing?: boolean;
  editingLink?: Link;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSuccess?: (values: any) => void;
};

const useStyles = makeStyles({
  root: {
    minWidth: '1000px',
    padding: '2%',
    margin: '0 auto',
  },
  box: {
    padding: '5%',
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

/**
 * Dialog Component which use to create new RE14 links or edit existing Re14 links
 * @param {Object}    props                     component props
 * @param {Boolean}   props.open                flag that use to display or hide
 * the dialog component
 * @param {Boolean}   props.isEditting          flag that determine if the component
 * is beign use for create or edit link
 * @param {Object}    props.editingLink               link payload should provided if the isEdditing
 * flag is true
 * @param {String}    props.editingLink.id            id of the link
 * @param {String}    props.editingLink.name          display name of the link
 * @param {String}    props.editingLink.description   description of the link
 * @param {String}    props.editingLink.diagramUid    link to the diagram
 * @param {Number}    props.editingLink.createdAt     the time which link created
 * @param {Number}    props.editingLink.updatedAt     the time which link was edited
 * @param {Function}  props.onClose             Callback which fires when dialog close even fire
 * @param {Function}  props.onSuccess           Callback which fires when create/edit
 * API call success
 * @returns JSX.Element
 */
const RE14CreateEdit: React.FC<propTypes> = ({
  open,
  onClose,
  isEditing,
  editingLink,
  onSuccess,
}) => {
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const classes = useStyles();
  const [formVal, setFormVal] = useState({
    name: '',
    description: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEditing) {
      setFormVal({
        name: editingLink.name,
        description: editingLink.description,
      });
    } else {
      setFormVal({
        name: '',
        description: '',
      });
    }
  }, [editingLink, isEditing]);

  return (
    <Dialog onClose={onClose} open={open} className={classes.root}>
      <DialogTitle id="simple-dialog-title">
        {isEditing ? 'Edit RE14 Design Link' : 'Create RE14 Design Link'}
      </DialogTitle>
      <Divider />
      <Box className={classes.box}>
        <Formik
          initialValues={formVal}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
          })}
          onSubmit={async (values) => {
            try {
              setIsSubmitting(true);

              if (isEditing) {
                await updateProjectDiagram(
                  userProfile.apollo_client,
                  editingLink.id,
                  selectedProject.selected_project.id,
                  values.name,
                  values.description,
                );

                if (onSuccess) {
                  onSuccess({
                    ...editingLink,
                    name: values.name,
                    description: values.description,
                  });
                }
                enqueueSnackbar('RE14 Design Link updated', { variant: 'success' });
                onClose();
                return;
              }

              const res = await createProjectDiagram(
                userProfile.apollo_client,
                selectedProject.selected_project.id,
                values.name,
                values.description,
              );
              if (onSuccess) onSuccess(res.projectDiagramCreate);
              onClose();
              enqueueSnackbar('RE14 Design Link created', { variant: 'success' });
            } catch (err: any) {
              // onClose();
              enqueueSnackbar(err.message, { variant: 'error' });
            } finally {
              setIsSubmitting(false);
            }
          }}
        >
          {({
            errors, values, handleChange, touched, handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.innerBox}>
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Name"
                  size="small"
                  variant="outlined"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Box height={15} />
                <TextField
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  label="Description"
                  size="small"
                  variant="outlined"
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
                <Box height={15} />
                <Box className={classes.btns}>
                  <Button color="primary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {isEditing ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

RE14CreateEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  onSuccess: PropTypes.func,
  editingLink: PropTypes.instanceOf(Link),
};

RE14CreateEdit.defaultProps = {
  isEditing: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSuccess: () => {},
  editingLink: null,
};

export default RE14CreateEdit;
