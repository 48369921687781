/* eslint-disable camelcase */
import { Alert, Box, Skeleton } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useContext, useState, useEffect, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
} from '../../../../../components/Alters/Snackbar/SnackbarOptions';
import { dcSearchGraphQL } from '../../../../../services/device-container/device-container.service';
import { projectGetGraphQL } from '../../../../../services/location/location.service';
import { userviewSearchGraphQL } from '../../../../../services/user-view/user-view.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../store/Project/ProjectContext';
import { APP_TEXT } from '../../../../../utility/AppTexts/DefaultAppText.utils';
import { appConsoleLog, AppUtils } from '../../../../../utility/appUtils';
import useQueryString from '../../../../../utility/CustomHooks/useQueryString';
import { DeviceContainers } from './components/DeviceContainers';
import Layout from './components/Layout';
import { LocationWidget } from './components/Location';
import { LoggerComponent } from './components/Logger';
import { DeviceStatus } from './components/Status';
import { UserViewsWidegt } from './components/UserViews';

const CardSkelton = () => (
  <div>
    <Skeleton variant="text" />
    <Skeleton variant="rectangular" height={100} />
  </div>
);

// eslint-disable-next-line import/prefer-default-export
export const ProjectDashboardComponent: React.FunctionComponent<any> = () => {
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();

  const [imgBgStyle, imgBgStyleSet] = useState({
    backgroundImage:
      "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  });
  const [userViewList, setUserViewList] = useState<any[]>(null);
  const selectedProjectID = useQueryString('projectId') as string;
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [dcList, setDCList] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const innerRoot = useRef<any>();
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const getProjectByID = async (project_id: string) => {
    try {
      const res = await projectGetGraphQL(userProfile?.apollo_client, project_id);
      setSelectedProjectDetails(res.projectGet.project ? res.projectGet.project : {});

      if (res.projectGet.project && res.projectGet.project.image) {
        imgBgStyleSet({
          ...imgBgStyle,
          backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
            'photo',
            res.projectGet.project.image,
          )}")`,
        });
      }
    } catch (error) {
      snackbar.enqueueSnackbar(APP_TEXT.location.LOCATION_SELECT_ERR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const selectedProjectID = selectedProject.selected_project.id;
    if (!selectedProjectID) return;

    appConsoleLog(
      'selectedProject selectedProjectDetails',
      selectedProjectID,
      selectedProjectDetails,
    );

    async function getData() {
      try {
        await getProjectByID(selectedProjectID);
        const res = await userviewSearchGraphQL(
          userProfile?.apollo_client,
          selectedProjectID,
          -1,
          '',
          4,
          0,
        );

        setUserViewList(res.userviewSearch.result ? res.userviewSearch.result : 0);

        const res2 = await dcSearchGraphQL(
          userProfile?.apollo_client,
          selectedProjectID,
          -1,
          -1,
          '',
          4,
          0,
        );
        setDCList(res2.dcSearch.result ? res2.dcSearch.result : 0);
      } catch (err: any) {
        snackbar.enqueueSnackbar(err.message, { variant: 'error' });
      }
    }

    getData();
  }, [selectedProject.selected_project.id]);

  return (
    <Layout>
      <div className="row" style={{ minHeight: '46%' }}>
        <div className="col-md-8" style={{ marginBottom: '2%' }}>
          {selectedProjectDetails ? (
            <LocationWidget
              selectedProject={selectedProjectDetails}
              onClick={() => {
                navigateRoute(`/app/edit-custom-project?projectId=${selectedProjectID}`);
              }}
              imgBgStyle={imgBgStyle}
            />
          ) : (
            <CardSkelton />
          )}
        </div>
        <div className="col-md-4" style={{ marginBottom: '2%' }}>
          {userViewList ? <UserViewsWidegt userViewList={userViewList} /> : <CardSkelton />}
        </div>
      </div>
      <div className="row" style={{ flex: 1 }}>
        <div className="col-md-4">
          <DeviceStatus />
        </div>
        <div className="col-md-4">
          {dcList ? <DeviceContainers dcList={dcList} /> : <CardSkelton />}
        </div>
        <div className="col-md-4">
          {dcList ? <LoggerComponent dcList={dcList} /> : <CardSkelton />}
        </div>
      </div>
    </Layout>
  );
};
