/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Image Cropper component using cropper.js library
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a reusable Image Cropper component which mainly use in
 * location create/edit pages
 * @author Maduka Dilshan
 * @since 0.1.54
 */
import React, { useState, useEffect } from 'react';
import PropsTypes from 'prop-types';
import Cropper from 'react-cropper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { appConsoleLog } from '../../../utility/appUtils';

type CropperProps = {
  src: File;
  cropperRef?: any;
  onCropped?: (croppedData: any) => void;
};

/**
 * Image resizing component
 *
 * @callback onImageCroppedCB
 * @param {Object} props                       component props
 * @param {File} props.src                     Image file source to be cropped
 * @param {String} props.cropperRef            cropper.js ref
 * @param {Boolean} props.selected             Flag that use to determine if the radio is
 * selected or not
 * @param {onImageCroppedCB} props.onCropped   Fires when user cropped the image
 *
 * @returns JSX.Element
 */
const ImageCropper: React.FC<CropperProps> = ({ src, onCropped, cropperRef }) => {
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();

  useEffect(() => {
    if (!src) return;
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(src);
  }, [src]);

  const getCropData = () => {
    if (typeof cropper !== 'undefined' && onCropped) {
      onCropped(cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9));
    }
  };

  return (
    <div style={{ width: 'auto' }}>
      {image && (
        <Cropper
          style={{ width: 'auto' }}
          zoomTo={0}
          initialAspectRatio={375 / 178}
          aspectRatio={375 / 178}
          src={image}
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      )}
      <button
        type="button"
        aria-label="get crop data hidden"
        ref={(current) => {
          // eslint-disable-next-line no-param-reassign
          cropperRef.ref = current;
        }}
        style={{ display: 'none' }}
        onClick={getCropData}
      />
    </div>
  );
};

function FilePropTypeFactory(isRequired: boolean) {
  const builder = function propValidator(props, propName, componentName) {
    const prop = props[propName];
    if (prop == null && isRequired) {
      return new Error(`Prop ${propName} is required at ${componentName}`);
    }
    const retVal = typeof props[propName].name === 'string';
    if (!retVal) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}`);
    }
    return null;
  };

  builder.isRequired = null;

  return builder;
}
const filePropType = FilePropTypeFactory(false);
filePropType.isRequired = FilePropTypeFactory(true);

ImageCropper.propTypes = {
  src: filePropType.isRequired,
  cropperRef: PropsTypes.oneOfType([
    PropsTypes.func,
    PropsTypes.shape({ ref: PropsTypes.instanceOf(HTMLButtonElement) }),
  ]),
  onCropped: PropsTypes.func,
};

ImageCropper.defaultProps = {
  cropperRef: null,
  onCropped: (croppedData) => {
    appConsoleLog(croppedData);
  },
};

export default ImageCropper;
