import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { cloneDeep, isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import CreateEditDeviceContainerComponent from 'src/containers/app/DeviceContainers/CreateEditDeviceContainer/CreateEditDeviceContainer';
import {
  airDCGetGraphQL,
  airDCTuyaDeviceListGraphQL,
} from 'src/services/air-device/air-device.service';
import { proDCGetGraphQL } from 'src/services/pro-container/pro-container.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { pushNewContainer } from 'src/store/redux/features/configurable-containers';

const CreateContainerDialog: React.FC<{
  open: boolean;
  setOpen: any;
}> = ({ open, setOpen }) => {
  const user = useContext(ApolloAuthContext);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };

  const onSuccessCreate = async (type: 'air' | 'pro', created: any) => {
    console.log(type, created);
    try {
      if (!created) throw new Error('Something Went Wrong');
      const dcId = created.id;

      if (type === 'pro' && dcId) {
        const res = await proDCGetGraphQL(user.apollo_client, dcId);
        const dc = res.proDCGet.proDeviceCollection;
        const dcCpy = cloneDeep(dc);
        dcCpy.dc_cat = 1;
        initConfigugrableProContainer(dcCpy);
      }

      if (type === 'air' && dcId) {
        const res = await airDCGetGraphQL(user.apollo_client, dcId);
        const dc = res.airDCGet.airDeviceCollection;
        const dcCpy = cloneDeep(dc);
        dcCpy.dc_cat = 2;
        initConfigiurableAirContainer(dcCpy);
      }

      enqueueSnackbar('New Container Added', { variant: 'success' });
      handleClose();
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const initConfigiurableAirContainer = async (dc: any) => {
    const response = await airDCTuyaDeviceListGraphQL(user.apollo_client, dc.id);
    if (response && response.airDCTuyaDeviceList && isArray(response.airDCTuyaDeviceList.items)) {
      const availableDevices = {};

      response.airDCTuyaDeviceList.items.map((device) => {
        availableDevices[device.id] = device;
        return 0;
      });

      dispatch(
        pushNewContainer({
          dc,
          existingDevices: {},
          availableDevices: availableDevices || {},
          deviceMap: {
            asNew: {},
            toExisting: {},
            toAvailable: {},
          },
        }),
      );
    }
  };

  const initConfigugrableProContainer = async (dc: any) => {
    dispatch(
      pushNewContainer({
        dc,
        existingDevices: {},
        availableDevices: {},
        deviceMap: {
          asNew: {},
          toExisting: {},
          toAvailable: {},
        },
      }),
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Configure a new container</DialogTitle>
      <DialogContent>
        <CreateEditDeviceContainerComponent backup_view onSuccessCreate={onSuccessCreate} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateContainerDialog;
