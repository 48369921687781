import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Component, Tag } from "src/plugins/shared/uvitem.config";

const commonTagFields = {
    type: COMPONENT_UNIT_TYPES.Sensor,
    main_key: null,
    command_key: null,
    can_send_command: false,
}

const InverterTags: Record<string, Tag> = {
    productionState__productionRate: {
        tag: 'productionState__productionRate',
        description: 'Production Rate',
        command_v: "v1",
        icon: '',
        ...commonTagFields,
    },
    productionState__totalLifetimeProduction: {
        tag: 'productionState__totalLifetimeProduction',
        description: 'Total Lifetime Production',
        command_v: "v1",
        icon: '',
        ...commonTagFields,
    },
    productionState__isProducing: {
        tag: 'productionState__isProducing',
        description: 'Is Producing',
        command_v: "v1",
        icon: 'minimize-2',
        ...commonTagFields,
    },
}

const ExtraInverterTags: Record<string, Tag> = {
    toBattery: {
        tag: 'toBattery',
        description: 'Inverter to Battery',
        command_v: "v1",
        display_suffix: "Kw",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
    toLoad: {
        tag: 'toLoad',
        description: 'Inverter to Load',
        display_suffix: "Kw",
        command_v: "v1",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
    toGrid: {
        tag: 'toGrid',
        description: 'Inverter to Grid',
        display_suffix: "Kw",
        command_v: "v1",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
    toPv: {
        tag: 'toPv',
        description: 'Inverter to PV',
        display_suffix: "Kw",
        command_v: "v1",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
}

const ExtraInverterTags1: Record<string, Tag> = {
    toBattery: {
        tag: 'toBattery',
        description: 'Inverter to Battery',
        command_v: "v1",
        display_suffix: "Kw",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
    toLoad: {
        tag: 'toLoad',
        description: 'Inverter to Load',
        display_suffix: "Kw",
        command_v: "v1",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
    toPv: {
        tag: 'toPv',
        description: 'Inverter to PV',
        display_suffix: "Kw",
        command_v: "v1",
        config: {
            scale: 1,
            decimal_points: 1,
        },
        ...commonTagFields,
    },
}


const config: Record<string, Component> = {
    inverter_map_1: {
        id: 'inverter_map_1',
        componentName: "Inverter Map 1",
        created_at: '2023.04.27',
        default_inline_ui_data_map: null,
        default_inner_ui_data_map: null,
        ui_tags: InverterTags,
        item_type: 'enode.inverter',
        extra_ui_tags: ExtraInverterTags,
        ui_data_maps: {
            inline_maps: {}, // TODO: to be filled by @Mayantha
            inner_maps: {
                productionState__productionRate: {
                    ui_component: 'CloudValueDisplayComponent1'
                },
                productionState__totalLifetimeProduction: {
                    ui_component: 'CloudValueDisplayComponent1'
                },
                productionState__isProducing: {
                    ui_component: 'CloudValueDisplayComponent2'
                },
            }, // TODO: to be filled by @Mayantha
        },
    },
    inverter_map_2: {
        id: 'inverter_map_2',
        componentName: "Inverter Map ( Off Grid )",
        created_at: '2023.04.27',
        default_inline_ui_data_map: null,
        default_inner_ui_data_map: null,
        ui_tags: InverterTags,
        item_type: 'enode.inverter',
        extra_ui_tags: ExtraInverterTags1,
        ui_data_maps: {
            inline_maps: {}, // TODO: to be filled by @Mayantha
            inner_maps: {
                productionState__productionRate: {
                    ui_component: 'CloudValueDisplayComponent1'
                },
                productionState__totalLifetimeProduction: {
                    ui_component: 'CloudValueDisplayComponent1'
                },
                productionState__isProducing: {
                    ui_component: 'CloudValueDisplayComponent2'
                },
            }, // TODO: to be filled by @Mayantha
        },
    }
}

export default config;