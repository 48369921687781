/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useRef } from 'react';

import Grid from '@mui/material/Grid';
import {
  FormControl, Select, MenuItem, Box, InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { CronScheduleVariables, TIME_FRAME } from '@smartlife-redux-state/common';
import { HoursInDay, MinutesInHour } from '../CRON_TYPES';

export const TIME_SELECTOR_TIME_FORMAT = 'HH:mm';

type ComponentProps = {
  variables: CronScheduleVariables;
  onChange: (data: CronScheduleVariables) => void;
};

export const TimeSelector: React.FC<ComponentProps> = ({ variables, onChange }) => {
  const [h, setH] = useState('8');
  const [m, setM] = useState('0');
  const [am, setAM] = useState('AM');

  const handleHChange = (h: string) => {
    setH(h);
  };
  const handleMChange = (m: string) => {
    setM(m);
  };
  const handleAMChange = (am: string) => {
    setAM(am);
  };

  const mounted = useRef(0);

  useEffect(() => {
    if (mounted.current === 0 && variables && variables.time) {
      const momentTime = moment(variables.time, TIME_SELECTOR_TIME_FORMAT);
      if (momentTime.format() !== 'Invalid date') {
        const hh = +momentTime.format('h');
        const mm = +momentTime.format('mm');
        console.log('HHHH', hh, mm);
        setH(`${hh}`);
        setM(`${mm}`);
        setAM(momentTime.format('A'));
      }
      mounted.current = 1;
    }
  }, []);

  useEffect(() => {
    let t = '';
    if (+h < 10) {
      t += '0';
    }
    t += `${h}`;
    t += ':';
    if (+m < 10) {
      t += '0';
    }
    t += `${m}`;
    t += ` ${am}`;
    const outTime = moment(t, 'LT').format(TIME_SELECTOR_TIME_FORMAT);

    onChange({
      ...variables,
      time: outTime,
      time_frame: am === 'AM' ? TIME_FRAME.AM : TIME_FRAME.PM,
    });
  }, [h, m, am]);

  return (
    <Grid container>
      <FormControl size="small" variant="outlined">
        <InputLabel>Hour</InputLabel>
        <Select
          label="Hour"
          value={h}
          onChange={(event: any) => {
            handleHChange(event.target.value);
          }}
        >
          {HoursInDay.map((v) => (
            <MenuItem value={v} key={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box m={1} />
      <FormControl size="small" variant="outlined">
        <InputLabel>Minute</InputLabel>
        <Select
          label="Minute"
          value={m}
          onChange={(event: any) => {
            handleMChange(event.target.value);
          }}
        >
          {MinutesInHour.map((v) => (
            <MenuItem value={v} key={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box m={1} />
      <FormControl size="small" variant="outlined">
        <InputLabel>Meridiem</InputLabel>
        <Select
          label="Meridiem"
          value={am}
          onChange={(event: any) => {
            handleAMChange(event.target.value);
          }}
        >
          <MenuItem value="AM" key="am">
            AM
          </MenuItem>
          <MenuItem value="PM" key="pm">
            PM
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};
