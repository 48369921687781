/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * This is the place where configured redux store
 * is connect to the React app using react-redux pacakge.
 *
 * @author Maduka Dilshan
 */
import React, { ReactChild, useContext } from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import firebase from 'firebase';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { SnackbarProvider } from 'notistack';
import { ApolloAuthContext } from '../Apollo/ApolloContext';

import createStore from './store';
import { AuthContext } from '../Auth/AuthContext';
import Notifier from '../../components/app/Notifier/Notifier';
import 'firebase/database';

const Providerx: any = Provider;

// eslint-disable-next-line import/prefer-default-export
export const ReduxStoreProvider: React.FC<{
  children: ReactChild;
}> = (props) => {
  const userProfile = useContext(ApolloAuthContext);
  const authUser: any = useContext(AuthContext);

  const { token } = authUser.access_token;
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0],
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const store = createStore(userProfile.apollo_client, axiosInstance);

  const rrfConfig = {
    userProfile: 'users',
    // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  };
  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance // <- needed if using firestore
  };

  return (
    <Providerx store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <SnackbarProvider>
          <Notifier />
          {props.children}
        </SnackbarProvider>
      </ReactReduxFirebaseProvider>
    </Providerx>
  );
};
