import React, { useState, useContext } from 'react';
import {
  Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { RootState } from '../../../../store/redux/store';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { CofirmDialog } from '../../LogicsEngine/components/ui/Confirm';

const useStyle = makeStyles({
  header: {
    // paddingTop: "0",
    paddingBottom: '0',
  },
  content: {
    paddingTop: 0,
  },
  title: {
    color: '#1E575E',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  sub_title: {
    color: '#048696',
  },
  desc: {
    color: '#7F8FA4',
  },
});

const BackupCard: React.FC<{
  isSysAdmin: boolean;
  title: string;
  desc: string;
  date: number;
  serial: string;
  type: string;
  refetch: (type: string) => void;
}> = ({
  isSysAdmin, title, desc, date, serial, type, refetch,
}) => {
  const navgiate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteBackupId, setDeleteBackupId] = useState(null);
  const [startRestoring, setStartRestoring] = useState(false);

  const selectedProject = useContext(ProjectContext);
  const auth = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  async function createSession(serial: string) {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/session/create`,
        {
          backup_serial: serial,
          project_id: selectedProject.selected_project.id,
          note: `Backup ${title} | ${moment().format('MMMM do YYYY, h:mm:ss a')}`,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        },
      );
      setStartRestoring(false);
      navgiate(
        `/app/backup-restore/restore-sessions/list?projectId=${selectedProject.selected_project.id}`,
      );
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  async function deleteBackup(backupSerial: string) {
    setDeleteBackupId(null);
    try {
      enqueueSnackbar('Start Deleting ...', { variant: 'info' });
      let url = '';
      if (type === 'backups') {
        url = '/rest/backups/v1/project-backup/project/point/delete';
      }
      if (type === 'templates') {
        url = '/rest/backups/v1/project-backup/point/delete';
      }
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          backup_serial: backupSerial,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        },
      );
      refetch(type);
      enqueueSnackbar('Backup Deleted Successfully', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  async function downloadBackup() {
    try {
      enqueueSnackbar('Downloading Backup....', { variant: 'info' });
      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v1/project-backup/point/download`,
        {
          backup_serial: serial,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
          responseType: 'blob',
        },
      );
      const fileType = response.headers['content-type'];

      const filename = response.request
        .getResponseHeader('Content-Disposition')
        .split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      enqueueSnackbar('Download Success', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  const styles = useStyle();
  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        className={styles.header}
        action={(
          <>
            <IconButton aria-controls="backup-opt-menu" onClick={handleClick} size="large">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="backup-opt-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  if (type === 'backups') {
                    navgiate(
                      `/app/backup-restore/edit?projectId=${selectedProject.selected_project.id}&backup_serial=${serial}&type=${type}&t=${title}&desc=${desc}`,
                    );
                  }

                  if (type === 'templates') {
                    navgiate(
                      `/app/backup-restore/universal-templates/create?projectId=${
                        selectedProject.selected_project.id
                      }&universal_temp_update=${1}&backup_serial=${serial}&type=${type}&t=${title}&desc=${desc}`,
                    );
                  }
                }}
              >
                Edit
              </MenuItem>

              {isSysAdmin && type === 'backups' && (
                <MenuItem
                  onClick={() => {
                    if (type === 'backups') {
                      navgiate(
                        `/app/backup-restore/universal-templates/create?projectId=${
                          selectedProject.selected_project.id
                        }&universal_temp_update=${0}&backup_serial=${serial}&type=${type}&t=${title}&desc=${desc}`,
                      );
                    }
                  }}
                >
                  Make an Universal Template
                </MenuItem>
              )}

              {isSysAdmin && (
                <MenuItem
                  onClick={() => {
                    downloadBackup();
                  }}
                >
                  Save As a File
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  setStartRestoring(true);
                }}
              >
                Restore
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDeleteBackupId(serial);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
        title={<Typography className={styles.title}>{title}</Typography>}
        subheader={(
          <Typography className={styles.sub_title}>
            {moment.unix(date).format('Do MMM YYYY')}
          </Typography>
        )}
      />
      <CardContent className={styles.content}>
        {/* <Typography className={styles.title}>Backup Name</Typography>
        <Typography className={styles.sub_title}>2020.08.23</Typography> */}
        <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
        <Typography className={styles.desc}>
          {desc && desc.length > 35 ? `${desc.slice(0, 50)}...` : desc}
        </Typography>
      </CardContent>
      <CofirmDialog
        dialogOpen={!!deleteBackupId}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteBackup(serial);
          } else {
            setDeleteBackupId(null);
          }
        }}
        title={`Confirm to delete the  ${type === 'backup' ? 'Backup' : 'Template'}?`}
        text={`It will delete the backup ${title} and you will not able to redo it!`}
      />
      <CofirmDialog
        dialogOpen={startRestoring}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            createSession(serial);
          } else {
            setStartRestoring(false);
          }
        }}
        title="Confirm"
        text={`You are about to restore this current location using the backup ${title}. Do you need to continue ? `}
      />
    </Card>
  );
};

export default BackupCard;
