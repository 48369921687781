/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { LastTaskRes, TaskInfoRes, TaskListRes } from './types';

export const completeTask = createAsyncThunk<
  any,
  {project_id: string; status: string},
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/completeTask', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/clone-task-history/change-status',
    filters,
  );
  return res.data as LastTaskRes;
});

export const getLastTaskHistory = createAsyncThunk<
  LastTaskRes,
  {project_id: string},
  {
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/getLastTask', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/clone-task-history/last',
    filters,
  );
  return res.data as LastTaskRes;
});

export const getTaskHistory = createAsyncThunk<
  TaskListRes,
  {project_id: string},
  {
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/getTaskList', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/clone-task-history/list',
    filters,
  );
  return res.data as TaskListRes;
});

export const getTaskInfo = createAsyncThunk<
  TaskInfoRes,
  {id: string},
  {
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/getTaskInfo', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/clone-task-history/get',
    filters,
  );
  return res.data as TaskInfoRes;
});
