/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, { useEffect } from 'react';
import { LOGIC_CARD_PERMISSION, LOGIC_CARD_VISIBILITY } from '@smartlife-redux-state/common';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignItems: "center",
    // margin: '10px',
    borderRadius: '5px',
    backgroundColor: '#F4FAFB',
    padding: '4% 10px',
    // minHeight: "180px", // testing purposes
  },
  dragAction: {
    display: 'flex',
    alignItems: 'center',
    width: '50px',
    justifyContent: 'center',
  },
  contentRoot: {
    width: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  actions: {
    width: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const FormContainer: React.FC<{
  dragHandlerProps?: any;
  cardId?: string;
  action?: () => JSX.Element;
  children: any;
  className?: string;
}> = ({
  children, action, dragHandlerProps, className = '', cardId,
}) => {
  const classes = useStyle();
  useEffect(() => {
    if (cardId === '2') console.log('className__________', className);
  }, [cardId]);

  return (
    <Box className={clsx(className, classes.root)}>
      <Box className={classes.dragAction}>
        <IconButton {...dragHandlerProps} size="large">
          <DragIndicatorIcon />
        </IconButton>
      </Box>
      <Box className={classes.contentRoot}>
        <Box className={classes.content}>{children}</Box>
      </Box>
      <Box className={classes.actions}>{action && action()}</Box>
    </Box>
  );
};

FormContainer.defaultProps = {
  dragHandlerProps: null,
  action: null,
  cardId: null,
  className: '',
};
