import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {v2InfoUpdate} from 'src/store/redux/features/backup-location-config';
import {RootState} from 'src/store/redux/store';

function getInforType(type: 'cintd' | 'lvar' | 'prodc' | 'airdc') {
  switch (type) {
    case 'lvar':
      return 'lvar';
    case 'prodc':
      return 'prod';
    case 'airdc':
      return 'aird';
    case 'cintd':
      return 'cintd';
  }
}

export const useRestoreType = (type: 'cintd' | 'lvar' | 'prodc' | 'airdc', sessionId: string) => {
  const auth = useContext(AuthContext);
  const infoType = getInforType(type);
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const [checkedDevices, setCheckDevices] = useState({});
  const [allSelected, setAllSelected] = useState<{[dcId: string]: boolean}>({});

  const hiddenItems = useSelector(
    (state: RootState) => state.backup_location_config.info.v2.hidden
  );

  const configurableList = useSelector((state: RootState) => state.configurable_containers);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const configurableContainerRef = useRef();

  const devicePendingComp = useSelector(
    (state: RootState) => state.backup_location_config.info.v2[type]
  );

  const isStepCompleted =
    devicePendingComp &&
    devicePendingComp.pendingComps &&
    Object.keys(devicePendingComp.pendingComps).length === 0 &&
    devicePendingComp.errorComps &&
    Object.keys(devicePendingComp.errorComps).length === 0;

  useEffect(() => {
    if (allSelected === null) return;

    if (allSelected && Object.keys(allSelected).length > 0) {
      const checkedItems = {};

      // eslint-disable-next-line array-callback-return
      Object.keys(devicePendingComp.pendingComps).map(contId => {
        if (!allSelected[contId]) return;
        const container = devicePendingComp.pendingComps[contId];
        // eslint-disable-next-line array-callback-return
        Object.keys(container).map(key => {
          const locVar = container[key];

          if (hiddenItems && hiddenItems[contId] && hiddenItems[contId][key]) {
            // do not select hidden devices
            return;
          }

          if (!checkedItems[contId]) checkedItems[contId] = {};

          checkedItems[contId] = {
            ...checkedItems[contId],
            [locVar.old_id]: true,
          };
        });
      });

      setCheckDevices(checkedItems);
    } else {
      setCheckDevices({});
    }
  }, [allSelected]);

  useEffect(() => {
    if (!devicePendingComp) {
      fetchSession(infoType, true);
    }
  }, [devicePendingComp]);

  const fetchSession = async (infoType, populated = true) => {
    const url = '/rest/backups/v2/restore/get-session-progress';
    try {
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          task_type: infoType,
          with_comp_map: populated,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        }
      );
      if (response.data && response.data.result) {
        dispatch(
          v2InfoUpdate({
            value: response.data.result,
            key: type,
            mergeErrorComps: true,
          })
        );
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  return {
    checkedDevicesState: {checkedDevices, setCheckDevices},
    allSelectedState: {allSelected, setAllSelected},
    showCheckBoxState: {showCheckBox, setShowCheckBox},
    configurableList,
    configurableContainerRef,
    hiddenItems,
    devicePendingComp,
    isStepCompleted,
  };
};
