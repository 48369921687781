import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { convertDaysToLetters } from 'src/utility/appUtils';
import { Formik } from 'formik';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { useSnackbar } from 'notistack';
import { useEnergyProviders } from '../../hooks/useEnergyProviders';
import { EnergyMonitoringGroups } from './EnergyMonitoringGroups';

export const LocationEnergyMonitoringConfig = () => {
  const selectedProjectId = useQueryStringFirst('projectId');
  const { enqueueSnackbar } = useSnackbar();
  const energyProviders = useEnergyProviders();
  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const [working, setWorking] = useState(false);
  const [formVals, setFormVals] = useState<{
    daily_fixed_price: number;
    peak_price: number;
    off_peak_price?: number;
    night_price?: number;
  }>({
    daily_fixed_price: 0,
    peak_price: 0,
    off_peak_price: 0,
    night_price: 0,
  });

  useEffect(() => {
    async function init() {
      const res = await pluginHook.commonIntegrationService.getLocationEnergyProvider(
        selectedProjectId,
      );

      if (res && res.result && res.result.info) {
        setFormVals({
          daily_fixed_price: res.result.info.daily_fixed_price,
          peak_price: res.result.info.peak_price,
          off_peak_price: res.result.info.off_peak_price,
          night_price: res.result.info.night_price,
        });

        setSelectedProvider(res.result.info.supplier_code);
      }
    }
    if (energyProviders) {
      init();
    }
  }, [energyProviders]);

  const provider = useMemo(() => {
    if (!energyProviders) return null;
    const key = Object.keys(energyProviders).find((key) => {
      const provider = energyProviders[key];
      if (provider.code === selectedProvider) return true;
      return false;
    });
    return energyProviders[key];
  }, [selectedProvider]);

  const setEnergyProvider = async (formVals: {
    daily_fixed_price: number;
    peak_price: number;
    off_peak_price?: number;
    night_price?: number;
  }) => {
    try {
      setWorking(true);
      await pluginHook.commonIntegrationService.updateEnergyProvider({
        projectId: selectedProjectId,
        supplierCode: provider.code,
        dailyFixedPrice: formVals.daily_fixed_price,
        peekPrice: formVals.peak_price,
        offPeakPrice: formVals.off_peak_price,
        nightPrice: formVals.night_price,
      });
      enqueueSnackbar('Energy Details Saved!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Unable to set energy provider', { variant: 'error' });
    } finally {
      setWorking(false);
    }
  };

  if (!energyProviders) return <CircularProgress />;

  return (
    <Grid container spacing={2}>
      <Grid item md={8} sm={12}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <FormControl size="small" style={{ width: '100%' }}>
              <InputLabel>Energy Provider</InputLabel>
              <Select
                label="Energy Provider"
                value={selectedProvider}
                onChange={(e) => {
                  setSelectedProvider(e.target.value);
                }}
              >
                {Object.keys(energyProviders).map((key) => {
                  const provider = energyProviders[key];
                  return (
                    <MenuItem key={key} value={provider.code}>
                      {provider.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            {provider && (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableCell>Range</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Type</TableCell>
                  </TableHead>
                  <TableBody>
                    {Object.keys(provider.ranges).map((rangeKey, index) => {
                      const range = provider.ranges[rangeKey];

                      return (
                        <TableRow key={index}>
                          <TableCell>{convertDaysToLetters(range.days).join(', ')}</TableCell>
                          <TableCell>{range.s}</TableCell>
                          <TableCell>{range.e}</TableCell>
                          <TableCell>{range.type}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} sm={12}>
        {provider && (
          <Formik
            initialValues={formVals}
            validationSchema={Yup.object().shape({
              daily_fixed_price: Yup.number()
                .required('Daily fixed cost is required')
                .positive('Should be a positive number')
                .typeError('Daily Fixed Price must be a number'),
              peak_price: Yup.number()
                .required('Peak price is required')
                .positive('Should be a positive number')
                .typeError('Peak Price must be a number'),
              off_peak_price: Yup.number()
                .min(0, 'Should be a positive number')
                .nullable()
                .typeError('Off-Peak Price must be a number'),
              night_price: Yup.number()
                .min(0, 'Should be a positive number')
                .nullable()
                .typeError('Night Price must be a number'),
            })}
            enableReinitialize
            onSubmit={(val) => {
              setEnergyProvider(val);
            }}
          >
            {({
              values, handleChange, touched, errors, handleSubmit,
            }) => (
              <form>
                <Box style={{ marginBottom: 10 }}>
                  <Typography variant="h6">Energy Pricing Management</Typography>
                </Box>
                <Box style={{ marginBottom: 10 }}>
                  <TextField
                    type="number"
                    size="small"
                    label="Daily Fixed Cost"
                    error={!!(touched.daily_fixed_price && errors.daily_fixed_price)}
                    name="daily_fixed_price"
                    value={values.daily_fixed_price}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ marginBottom: 10 }}>
                  <TextField
                    type="number"
                    size="small"
                    label="Peak Cost Per Hour"
                    name="peak_price"
                    error={!!(touched.peak_price && errors.peak_price)}
                    value={values.peak_price}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ marginBottom: 10 }}>
                  <TextField
                    type="number"
                    size="small"
                    label="Off Peak Cost Per Hour"
                    name="off_peak_price"
                    error={!!(touched.off_peak_price && errors.off_peak_price)}
                    value={values.off_peak_price}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ marginBottom: 10 }}>
                  <TextField
                    type="number"
                    size="small"
                    label="Night Cost Per Hour"
                    name="night_price"
                    error={!!(touched.night_price && errors.night_price)}
                    value={values.night_price}
                    style={{ width: '100%' }}
                    onChange={handleChange}
                  />
                </Box>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => handleSubmit()}
                  disabled={working}
                >
                  {working ? 'Saving....' : 'Save Changes'}
                </Button>
              </form>
            )}
          </Formik>
        )}
      </Grid>
      <Grid item md={12}>
        <Typography variant='h6'>Monitoring Group Configuration</Typography>
        <Box height={10} />
        <EnergyMonitoringGroups />
      </Grid>
    </Grid>
  );
};
