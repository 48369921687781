import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { RootState } from 'src/store/redux/store';

export interface EnergySupplier {
  code: string;
  name: string;
  type: string;
  ranges: {
    [rangeId: string]: {
      days: number[];
      s: string;
      e: string;
      type: string;
    };
  };
}

export const useEnergyProviders = () => {
  const firebasePath = 'energy_configs/energy_suppliers';
  useFirebaseConnect(firebasePath);

  const data = useSelector((state: RootState) => state.firebase.data.energy_configs);

  if (data) return data.energy_suppliers as Record<string, EnergySupplier>;

  return null;
};
