/* eslint-disable no-debugger */
import {Box, Dialog, Grid, IconButton, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import {isArray} from 'lodash';
import {useSnackbar} from 'notistack';
import {useContext, useEffect, useState} from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Layout from 'src/components/layout/Layout';
import {PageHeader} from 'src/components/layout/PageHeader';
import LogicStepperContainer, {
  LogicStep,
} from 'src/containers/app/LogicsEngine/components/LogicStepper';
import {AuthContext} from 'src/store/Auth/AuthContext';
import useQueryString from 'src/utility/CustomHooks/useQueryString';
import {useDispatch} from 'src/store/redux/store';
import {resetConfigCotnainers} from 'src/store/redux/features/configurable-containers';
import {v2Reset} from 'src/store/redux/features/backup-location-config';
import SessionCard from './shared/SessionCard';
import SessionRestoreContent from './components/SessionRestoreContent';
import {DeviceConfigContextProvider} from './shared/context/deviceConfigContext';
import {CloneSuccessRateProvider} from './shared/context/cloneSuccessRateContext';
import {CloneStepSummaryProvider} from './shared/context/cloneStepSummery';

const useStyle = makeStyles({
  stepRoot: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
  },
  stepTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  stepTabTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.1rem',
    marginRight: '2%',
  },
  stepTabLine: {
    height: '100%',
    position: 'relative',
    flex: '1',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderBottom: '1px solid #D9DFE0',
      height: '100%',
      width: '100%',
      transform: 'translateY(-45%)',
    },
  },
  lastTabLine: {
    flex: 1,
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'center',
    backgroun: 'black',
    border: '8px solid var(--light-green-1)',
    borderRadius: '50%',
    marginRight: '2%',
    color: 'var(--light-green)',
  },

  circleIcon: {
    color: 'white',
  },

  contentRoot: {
    flex: 1,
    // marginBottom: '4%',
    overflow: 'scroll',
    padding: '2% 10%',
  },
});

const SessionRestore = () => {
  const styles = useStyle();
  const projectId = useQueryString('projectId');
  const sessionId = useQueryString('sessionId');

  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const {enqueueSnackbar} = useSnackbar();

  return (
    <DeviceConfigContextProvider>
      <CloneSuccessRateProvider>
        <CloneStepSummaryProvider>
          <Dialog open fullScreen fullWidth>
            <Layout>
              <LogicStepperContainer initialActiveStep={0} canSkipStep={false}>
                <PageHeader>
                  <Box className={styles.stepRoot}>
                    <LogicStep>
                      {({activeStep, setActive}) => {
                        const index = 0;
                        return (
                          <Box className={styles.stepTab}>
                            <Box className={styles.iconRoot}>
                              {activeStep === index ? (
                                <CheckCircleIcon />
                              ) : (
                                <FiberManualRecordIcon className={styles.circleIcon} />
                              )}
                            </Box>
                            <Typography className={styles.stepTabTitle}>Location Var</Typography>
                            <Box className={styles.stepTabLine} />
                          </Box>
                        );
                      }}
                    </LogicStep>
                    <LogicStep>
                      {({activeStep, setActive}) => {
                        const index = 1;
                        return (
                          <Box className={styles.stepTab}>
                            <Box className={styles.iconRoot}>
                              {activeStep === index ? (
                                <CheckCircleIcon />
                              ) : (
                                <FiberManualRecordIcon className={styles.circleIcon} />
                              )}
                            </Box>
                            <Typography className={styles.stepTabTitle}>Connect Devices</Typography>
                            <Box className={styles.stepTabLine} />
                          </Box>
                        );
                      }}
                    </LogicStep>
                    <LogicStep>
                      {({activeStep, setActive}) => {
                        const index = 2;
                        return (
                          <Box className={styles.stepTab}>
                            <Box className={styles.iconRoot}>
                              {activeStep === index ? (
                                <CheckCircleIcon />
                              ) : (
                                <FiberManualRecordIcon className={styles.circleIcon} />
                              )}
                            </Box>
                            <Typography className={styles.stepTabTitle}>Air Devices</Typography>
                            <Box className={styles.stepTabLine} />
                          </Box>
                        );
                      }}
                    </LogicStep>
                    <LogicStep>
                      {({activeStep, setActive}) => {
                        const index = 3;
                        return (
                          <Box className={styles.stepTab}>
                            <Box className={styles.iconRoot}>
                              {activeStep === index ? (
                                <CheckCircleIcon />
                              ) : (
                                <FiberManualRecordIcon className={styles.circleIcon} />
                              )}
                            </Box>
                            <Typography className={styles.stepTabTitle}>Cloud Devices</Typography>
                            <Box className={styles.stepTabLine} />
                          </Box>
                        );
                      }}
                    </LogicStep>
                    <LogicStep>
                      {({activeStep, setActive}) => {
                        const index = 4;
                        return (
                          <Box className={styles.stepTab}>
                            <Box className={styles.iconRoot}>
                              {activeStep === index ? (
                                <CheckCircleIcon />
                              ) : (
                                <FiberManualRecordIcon className={styles.circleIcon} />
                              )}
                            </Box>
                            <Typography className={styles.stepTabTitle}>Finish Up</Typography>
                            <Box className={styles.lastTabLine} />
                          </Box>
                        );
                      }}
                    </LogicStep>
                  </Box>
                </PageHeader>

                <Box className={styles.contentRoot}>
                  <SessionRestoreContent sessionId={sessionId as string} />
                </Box>
              </LogicStepperContainer>
            </Layout>
          </Dialog>
        </CloneStepSummaryProvider>
      </CloneSuccessRateProvider>
    </DeviceConfigContextProvider>
  );
};
export default SessionRestore;
