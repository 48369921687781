/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import {
  DCSEARCH,
  GETDEVICELINKEDITEMS,
  PRO_DC_TUNNELS,
  PRO_DC_TUNNEL_CREATE,
  PRO_DC_TUNNEL_DELETE,
} from './graphql';

const dcSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  dc_type: number,
  dc_status: number,
  keyword: string,
  limit: number,
  skip: number,
): Promise<any> => {
  const res = await apolloClient.query({
    query: DCSEARCH,
    variables: {
      project_id,
      dc_type,
      dc_status,
      keyword,
      limit,
      skip,
    },
    fetchPolicy: 'no-cache',
  });
  return res.data;
};

const getDeviceLinkedItems = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  device_id: string,
  collection_type: string,
) => {
  const res = await apolloClient.query({
    query: GETDEVICELINKEDITEMS,
    variables: {
      device_id,
      collection_type,
    },
    fetchPolicy: 'no-cache',
  });
  return res.data;
};

const getProDcTunnels = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  prodc_id: string,
) => {
  const res = await apolloClient.query({
    query: PRO_DC_TUNNELS,
    variables: {
      prodc_id,
    },
  });

  return res.data;
};

const proDcTunnelCreate = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  prodc_id: string,
  remark: string,
  type: string,
  ip: string,
  port: string,
) => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_TUNNEL_CREATE,
    variables: {
      prodc_id,
      remark,
      type,
      ip,
      port,
    },
  });
  return res.data;
};

const proDcTunnelDelete = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  prodc_id: string,
  type: string,
  id: string,
) => {
  const res = await apolloClient.mutate({
    mutation: PRO_DC_TUNNEL_DELETE,
    variables: {
      prodc_id,
      type,
      id,
    },
  });
  return res.data;
};

export {
  dcSearchGraphQL,
  getDeviceLinkedItems,
  getProDcTunnels,
  proDcTunnelCreate,
  proDcTunnelDelete,
};
