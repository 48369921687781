import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  CloudDeviceUVIntegration,
  Component,
} from 'src/plugins/shared/uvitem.config';
import { CLOUD_INTEGRATIONS, CiDevice } from '../services/cloud-integration.service';

export const useCloudDeviceMap = (
  selectedDevice: CiDevice & {dc_type: string},
  integration: CLOUD_INTEGRATIONS,
  config: CloudDeviceUVIntegration,
  configSelector: (
    allConfig: CloudDeviceUVIntegration,
    selectedDevice: CiDevice & {dc_type: string},
    overrideSettingType: string | null,
  ) => {
    [componentName: string]: Component;
  },
) => {
  const [overrideSettingType, setOverrideSettingType] = useState<string>(selectedDevice && selectedDevice.cat);
  const { enqueueSnackbar } = useSnackbar();

  const settings: Record<string, {k: string; t: string; command?: string}> = useMemo(() => {
    try {
      const parsed = JSON.parse(selectedDevice.settings);
      if (Object.keys(parsed.sf).length > 0) {
        return parsed.sf;
      }
      enqueueSnackbar(
        'Exception: Failed to parse device setting. Invalid Device Setting. Check Console for more details',
      );
      console.error({
        error: 'Device setting contain no function values',
        settings: parsed,
      });
      return {};
    } catch (err) {
      enqueueSnackbar(
        'Exception: Failed to parse device setting. Invalid Device Setting. Check Console for more details',
      );
      console.error({
        error: 'Device setting contain no function values',
        settings: selectedDevice.settings,
      });
      return {};
    }
  }, [selectedDevice]);

  const itemMaps = useMemo(() => {
    if (selectedDevice.cint_type != integration) {
      enqueueSnackbar(
        'Exception: Unsupported device for current integration. This is likely a Smartlife bug. Please contact support',
        { variant: 'error' },
      );
      return {};
    }
    const allConfig = config;
    const selectedMap = configSelector(allConfig, selectedDevice, overrideSettingType);
    if (selectedMap) {
      return selectedMap;
    }
    enqueueSnackbar(
      'Unable to find supported item maps in configuration. Please contact SmartLife',
    );
    return {};
  }, [selectedDevice, overrideSettingType]);

  return {
    settings,
    itemMaps,
    overrideSettingType,
    setOverrideSettingType,
  };
};
