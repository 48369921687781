import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { AxiosInstance } from 'axios';
import { ImageUploadTypes, SharedFunctions } from '../../../services/shared/SharedFunctions';
import { appConsoleLog } from '../../../utility/appUtils';
import { IUserProfile } from '../types';

// eslint-disable-next-line import/prefer-default-export
export class UserAuthFunctions {
  static async getUserProfile(
    apolloClient: ApolloClient<NormalizedCacheObject>,
  ): Promise<IUserProfile> {
    const res = await apolloClient.query({
      query: gql`
          query {
            me {
              id
              fb_uid
              user_name
              profile_photo
              email
              invited_by
              user_status
              created_at
              updated_at
            }
          }
        `,
    });
    return res.data.me;
  }

  static updateUserProfile = async (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    name: string,
    userContactNo1: string,
    userAddress: string,
    userCountry: string,
    userCountryCode: string,
    userCity: string,
  ): Promise<IUserProfile> => {
    try {
      const res = await apolloClient.mutate({
        mutation: gql`
          mutation {
            updateUserInfo(input: { 
              user_name : "${name}" ,
              user_contact_no1 : "${userContactNo1}" ,
              user_address : "${userAddress}" ,
              user_country : "${userCountry}" ,
              user_country_code : "${userCountryCode}" ,
              user_city : "${userCity}" ,
              device_type : "web",
              device_token : "none"
            }) {
              id
              fb_uid
              user_name
              profile_photo
              email
              invited_by
              user_status
              created_at
              updated_at
            }
          }
        `,
      });
      appConsoleLog('createUserEntity 2');
      return res.data;
    } catch (err) {
      appConsoleLog('createUserEntity 3 err', err);
      throw err;
    }
  };

  static updateUserProfilePhoto = async (accessToken: string, file: any): Promise<string> => {
    const res = await SharedFunctions.imageUpload(
      accessToken,
      file,
      ImageUploadTypes.USER_PROFILE,
      '',
    );
    return res.data.imageUpload.imageId;
  };

  static updateUserProfilePhotoRest = async (
    axioInstance: AxiosInstance,
    file: any,
    path: string,
  ) => {
    const data = new FormData();
    data.set('image_file', file);
    data.set('upload_for', 'USER_PROFILE');

    const res = await axioInstance.post(path, data);
    return res.data.result.image_id;
  };
}
