/* eslint-disable max-len */
/* eslint-disable camelcase */
import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from 'src/components/Alters/Snackbar/SnackbarOptions';
import CustomOptionSelector from 'src/components/shared/CustomOptionSelector/CustomOptionSelector';
import AIR_COUNTRY_LIST from 'src/config/airDcCountryList';
import AirDeviceCreateMethod from 'src/containers/app/DeviceContainers/CreateEditDeviceContainer/types';
import {
  airDCCreateGraphQL,
  airDCSendVerificationGraphQL,
} from 'src/services/air-device/air-device.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { APP_TEXT } from 'src/utility/AppTexts/DefaultAppText.utils';
import AirDcCreateNewAccount from './AirDcCreateNew';

type IAirDCFormValue = {
  air_email: string;
  air_otp: string;
  air_password: string;
  air_name: string;
  air_type: number;
  country_code: string;
};
type IAirOtpFormValue = {
  air_email: string;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  sec1: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  fieldSpace: {
    margin: '8px 0 8px 0 !important',
    width: '100%',
  },
}));

const CreateAirDeviceContainer: React.FC<{
  backup_view: boolean;
  onSuccessCreate?: (type: 'air', created: any) => void;
}> = ({ backup_view, onSuccessCreate }) => {
  const matStyles = useStyles();
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [airDeviceMailOTPRequested, airMailOTPRequested] = useState(false);
  const [airEmail, airEmailSet] = useState('');
  const [airDCCreateMethod, setAirDCCreateMethod] = useState(AirDeviceCreateMethod.menually);

  const onSubmitAirOtpRequestForm = async (
    values: IAirOtpFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    setLoading(true);
    try {
      if (
        !selectedProject.selected_project.id
        // selectedProject.selected_project.id === 0
      ) {
        return;
      }
      await airDCSendVerificationGraphQL(userProfile?.apollo_client, values.air_email.trim());
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR_OTP_REQUEST_SUCCESS, {
        key: 'key1',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
      airMailOTPRequested(true);
      airEmailSet(values.air_email.trim());
      resetForm();
    } catch (err) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR_OTP_REQUEST_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const onSubmitAirCreateForm = async (
    values: IAirDCFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    setLoading(true);
    try {
      if (!selectedProject.selected_project.id) {
        throw new Error('Please select project');
      }
      if (!airEmail || airEmail === '') {
        return;
      }

      await airDCCreateGraphQL(
        userProfile?.apollo_client,
        airEmail,
        values.air_otp.trim(),
        selectedProject.selected_project.id,
        values.air_password.trim(),
        values.country_code,
        values.air_name.trim(),
        '',
        values.air_type,
        1,
      )
        .then(async (res) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR__CREATE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          airMailOTPRequested(true);
          airEmailSet(values.air_email.trim());
          resetForm();

          if (backup_view && res.data.airDCCreate) {
            onSuccessCreate('air', res.data.airDCCreate);
          }

          if (!backup_view) {
            if (res.data.airDCCreate) {
              navgiate(
                `/app/device-container/air?projectId=${selectedProject.selected_project.id}&dcId=${res.data.airDCCreate.id}&name=${res.data.airDCCreate.name}`,
              );
              return;
            }
            navgiate(`/app/device-containers?projectId=${selectedProject.selected_project.id}`);
          }
        })
        .catch((err) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR__CREATE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (err) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR__CREATE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  return (
    <div>
      {!airDeviceMailOTPRequested ? (
        <div>
          {/* Smartlife AIR Email validation */}
          <div className="custom-card2 col-md-12 pb-5 mt-3 mb-5">
            <div className="custom-card2-container">
              <div className="custom-card2-in ">
                <div className="custom-card2-title">
                  <h2>{APP_TEXT.device_container.SMARTLIFE_AIR_ACCOUNT_DETAILS}</h2>
                  <hr />
                </div>
                <div className="custom-card2-body mt-2 cursor-pointer">
                  <Box className={matStyles.sec1}>
                    <Box>
                      <CustomOptionSelector
                        title="Use existing account"
                        selected={airDCCreateMethod === AirDeviceCreateMethod.menually}
                        onClick={() => setAirDCCreateMethod(AirDeviceCreateMethod.menually)}
                      />
                    </Box>
                    <Box>
                      <CustomOptionSelector
                        title="Create new account"
                        selected={airDCCreateMethod === AirDeviceCreateMethod.automatedAccount}
                        onClick={() => {
                          setAirDCCreateMethod(AirDeviceCreateMethod.automatedAccount);
                        }}
                      />
                    </Box>
                  </Box>
                </div>
                {airDCCreateMethod === AirDeviceCreateMethod.menually ? (
                  <div>
                    <Formik
                      onSubmit={onSubmitAirOtpRequestForm}
                      initialValues={{
                        air_email: '',
                      }}
                      validationSchema={Yup.object().shape({
                        air_email: Yup.string()
                          .trim()
                          .email('Please enter a valid email address')
                          .required('Please enter the email address'),
                      })}
                    >
                      {({
                        errors, handleBlur, handleChange, touched, values, submitForm,
                      }) => (
                        <form>
                          <div className="custom-card2-body  mt-2">
                            <div className="m-0 col-md-12 nopaddingOnly row">
                              <div className="col-md-12 nopaddingOnly">
                                <div className="col-md-6 nopaddingOnly">
                                  <div>
                                    <TextField
                                      className={matStyles.fieldSpace}
                                      error={Boolean(touched.air_email && errors.air_email)}
                                      helperText={touched.air_email && errors.air_email}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.air_email}
                                      name="air_email"
                                      label={APP_TEXT.device_container.FORM_FIELD_USER_NAME}
                                      size="medium"
                                      type="text"
                                      variant="outlined"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton edge="end" size="large" />
                                          </InputAdornment>
                                        ),
                                      }}
                                      data-testid=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6  " />
                              </div>
                            </div>
                          </div>

                          <div className="custom-card2-body">
                            <div className="col-md-12 nopaddingOnly">
                              <Button
                                endIcon={<ArrowForward />}
                                onClick={submitForm}
                                disabled={loading}
                                color="primary"
                                variant="contained"
                              >
                                {APP_TEXT.device_container.REQUEST_OTP_BTN}
                              </Button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                ) : (
                  <AirDcCreateNewAccount matStyles={matStyles} isBackup={backup_view} />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {/* Smartlife AIR Create */}
          <div className="custom-card2 col-md-12 pb-5 mt-3 mb-5">
            <div className="custom-card2-container">
              <div className="custom-card2-in ">
                <div className="custom-card2-title">
                  <h2>{APP_TEXT.device_container.SMARTLIFE_AIR_ACCOUNT_DETAILS}</h2>
                  <hr />
                </div>

                <Formik
                  onSubmit={(values, formikHelpers) => {
                    onSubmitAirCreateForm(values, formikHelpers);
                  }}
                  initialValues={{
                    air_email: airEmail,
                    air_otp: '',
                    air_password: '',
                    air_name: '',
                    air_type: 1,
                    country_code:
                      selectedProject.selected_project
                      && selectedProject.selected_project.country_code_num
                        ? selectedProject.selected_project.country_code_num
                        : '64',
                  }}
                  validationSchema={Yup.object().shape({
                    // air_email: Yup.string().email().required("Required"),
                    air_otp: Yup.string().trim().required('Required'),
                    air_name: Yup.string().trim().required('Required'),
                    air_password: Yup.string().required('Required'),
                    air_type: Yup.string().required('Required'),
                    country_code: Yup.string().required('Required'),
                  })}
                >
                  {({
                    errors, handleBlur, handleChange, touched, values, submitForm,
                  }) => (
                    <form>
                      <div className="custom-card2-body mt-2">
                        <div className="m-0 col-md-12 nopaddingOnly row">
                          <div className="col-md-12 nopaddingOnly">
                            <div className="col-md-6 nopaddingOnly">
                              <div>
                                <TextField
                                  className={matStyles.fieldSpace}
                                  disabled
                                  error={Boolean(touched.air_email && errors.air_email)}
                                  helperText={touched.air_email && errors.air_email}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={airEmail}
                                  name="air_email"
                                  label={APP_TEXT.device_container.FORM_FIELD_USER_NAME}
                                  size="small"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                              </div>
                              <div>
                                <TextField
                                  className={matStyles.fieldSpace}
                                  error={Boolean(touched.air_otp && errors.air_otp)}
                                  helperText={touched.air_otp && errors.air_otp}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.air_otp}
                                  name="air_otp"
                                  label={APP_TEXT.device_container.FORM_FIELD_OTP}
                                  size="small"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                              </div>
                              <div>
                                <TextField
                                  className={matStyles.fieldSpace}
                                  error={Boolean(touched.air_name && errors.air_name)}
                                  helperText={touched.air_name && errors.air_name}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.air_name}
                                  name="air_name"
                                  label={APP_TEXT.device_container.FORM_FIELD_NAME}
                                  size="small"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                              </div>
                              <div>
                                <TextField
                                  className={matStyles.fieldSpace}
                                  error={Boolean(touched.air_password && errors.air_password)}
                                  helperText={touched.air_password && errors.air_password}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.air_password}
                                  name="air_password"
                                  label={APP_TEXT.device_container.FORM_FIELD_PASSWORD}
                                  size="small"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                              </div>
                              <FormControl
                                // variant="outlined"
                                className={matStyles.formControl}
                                size="small"
                              >
                                <InputLabel id="demo-simple-select-label">Device Type</InputLabel>
                                <Select
                                  label="Device Type"
                                  variant="outlined"
                                  onChange={handleChange}
                                  name="air_type"
                                  value={values.air_type}
                                >
                                  <MenuItem value={1}>Real</MenuItem>
                                  <MenuItem value={8}>Demo</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl className={`${matStyles.formControl}`} size="small">
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                  variant="outlined"
                                  label="Country"
                                  onChange={handleChange}
                                  name="country_code"
                                  value={values.country_code}
                                >
                                  {Object.keys(AIR_COUNTRY_LIST).map((groupCode) => AIR_COUNTRY_LIST[groupCode].map((country) => (
                                    <MenuItem value={country.code} key={country.code}>
                                      {country.english}
                                    </MenuItem>
                                  )))}
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-md-6  " />
                          </div>
                        </div>
                      </div>

                      <div className="custom-card2-body">
                        <div className="col-md-12 nopaddingOnly">
                          <div className="col-md-3 nopaddingOnly">
                            <Button
                              endIcon={<ArrowForward />}
                              onClick={submitForm}
                              disabled={loading}
                              color="primary"
                              variant="contained"
                            >
                              {APP_TEXT.device_container.ADD_AIR_DEVICE_CONTAINER_BTN}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAirDeviceContainer;
