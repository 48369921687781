import Delete from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { appConsoleLog } from '../../../../../utility/appUtils';
import { DragSelectorCardItem, IUVDragSelectorItem } from './UVDragSelectItem';
import styles from './UVDragSelector.module.css';

type ComponentProps = {
  inputList: IUVDragSelectorItem[];
  selectedList: IUVDragSelectorItem[];
  onChange: (selectedList: IUVDragSelectorItem[]) => void;
  alarmOutSelector: boolean;
};
// eslint-disable-next-line import/prefer-default-export
export const UVDragSelector: React.FunctionComponent<ComponentProps> = ({
  inputList,
  selectedList,
  onChange,
  alarmOutSelector = false,
}) => {
  // console.log(inputList);
  // console.log(selectedList);
  const [sourceItems, setSourceItems] = useState<IUVDragSelectorItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<IUVDragSelectorItem[]>([]);
  const [dragItem, setDragItem] = useState<{
    item: IUVDragSelectorItem;
    type: string;
    index: number;
  } | null>();

  useEffect(() => {
    if (JSON.stringify(inputList) !== JSON.stringify(sourceItems)) {
      const smap: any = {};
      selectedList.forEach((item) => {
        smap[item.id] = true;
      });

      const slist = inputList.filter((item) => !smap[item.id]);
      setSourceItems(slist);
    }
  }, [inputList]);
  useEffect(() => {
    if (JSON.stringify(selectedList) !== JSON.stringify(selectedItems)) {
      setSelectedItems(selectedList);
    }
  }, [selectedList]);

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems]);

  const updateDragAndDropState = (event: any) => {
    setTimeout(() => {
      setDragItem((prevState) => {
        appConsoleLog('updateDragAndDropState', prevState);
        if (!prevState) {
          return;
        }
        if (prevState.item && prevState.type === 'source') {
          setSelectedItems((oldSelected) => {
            const newItems = [...oldSelected];
            const item = { ...prevState.item, type: 'selected' };
            newItems.push(item);

            const souces = [...sourceItems].filter((v) => v.id !== prevState.item.id);
            setSourceItems(souces);

            return newItems;
          });
        }
        // eslint-disable-next-line consistent-return
        return prevState;
      });
    }, 200);
  };

  const receiveDraggedElements = (event: any) => {
    // appConsoleLog("receiveDraggedElements", event);
    event.preventDefault();
  };
  const dragStartHandler = (event: any) => {
    // appConsoleLog("dragStartHandler", event);
    setDragItem(event);
  };

  const onDividerDropHandler = (divIndex: number) => {
    appConsoleLog('onDividerDropHandler', dragItem);
    if (dragItem && dragItem.type === 'selected') {
      if (dragItem.index !== divIndex) {
        const item1 = selectedItems[dragItem.index];
        const item2 = selectedItems[divIndex];
        const selected = [...selectedItems];
        selected[divIndex] = item1;
        selected[dragItem.index] = item2;
        setSelectedItems(selected);
      }
      setDragItem(null);
    } else if (dragItem && dragItem.type === 'source') {
      const item1 = dragItem.item;

      const selected = [...selectedItems];

      const sec1 = selected.slice(0, divIndex);
      let sec2: any[] = [];

      sec2 = selected.slice(divIndex, selected.length);

      setSelectedItems([...sec1, item1, ...sec2]);
      setDragItem(null);

      const souces = [...sourceItems].filter((v) => v.id !== dragItem.item.id);
      setSourceItems(souces);
    }
  };

  const removeItemHandler = (item: IUVDragSelectorItem) => {
    const selected = [...selectedItems].filter((v) => v.id !== item.id);
    setSelectedItems(selected);

    const itemPayload = { ...item };
    const sources = [...sourceItems];
    sources.push(itemPayload);
    setSourceItems(sources);
  };
  return (
    <div>
      <div className="row mt-2">
        <div className="col-md-6 ">
          <div className={styles.DragSelectorListCont}>
            <p className={styles.contSubTitle}>
              {' '}
              {alarmOutSelector ? 'Selected Controls/Outputs' : 'Selected Zones'}
            </p>
            <div
              className={styles.DragSelectorListInnerCont}
              onDragOver={
                dragItem && dragItem.type === 'source' ? receiveDraggedElements : undefined
              }
              onDrop={dragItem && dragItem.type === 'source' ? updateDragAndDropState : undefined}
            >
              {selectedItems.map((item, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`d${index}`}
                  onDragOver={(event) => {
                    event.preventDefault();
                  }}
                  onDrop={(event) => {
                    event.preventDefault();
                    onDividerDropHandler(index);
                  }}
                >
                  <DragSelectorCardItem
                    item={item}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    itemIndex={index}
                    dragStartHandler={dragStartHandler}
                    type="selected"
                    removeItemHandler={removeItemHandler}
                    deleteIcon={<Delete fontSize="medium" />}
                  />
                  <div className={styles.DragSelectorItemCardDivider} />
                </div>
              ))}
              <p className={styles.dragHere}>Drag here</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.DragSelectorListCont}>
            <p className={styles.contSubTitle}>
              {alarmOutSelector ? 'Controls/Outputs' : 'Device Zones'}
            </p>
            <div className={styles.DragSelectorListInnerCont}>
              {sourceItems.map((item, index) => (
                <DragSelectorCardItem
                  item={item}
                  key={item.id}
                  itemIndex={index}
                  dragStartHandler={dragStartHandler}
                  removeItemHandler={removeItemHandler}
                  type="source"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
