/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Extended group component using renderProps pattern.
 *
 * @author Maduka Dilshan
 */

import React, { useState } from 'react';

import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';

const Droppablex: any = Droppable;

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

const grid = 8;
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#D9DFE0',
  padding: '2%',
  width: '100%',
  borderRadius: '10px',
});

type GroupRenderPropArgs = {
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  setExtraChild: React.Dispatch<(prevState: any) => any>;
};

type GroupProps = {
  droppableId: string;
  style?: (isDraggingOver: boolean) => any;
  children: (args: GroupRenderPropArgs) => React.ReactChild;
  droppableType?: string;
  isDropDisabled?: boolean;
  [key: string]: any;
};
export const Group: React.FC<GroupProps> = ({
  droppableId,
  children,
  style = getListStyle,
  droppableType,
  isDropDisabled,
  ...rest
}) => {
  const stylefn = style;
  const renderProp = unwrapArray(children);
  return (
    <Droppablex droppableId={droppableId} type={droppableType} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={stylefn(snapshot.isDraggingOver)}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.droppableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        >
          {renderProp({
            provided,
            snapshot,
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppablex>
  );
};

Group.defaultProps = {
  style: getListStyle,
  droppableType: 'DEFAULT',
  isDropDisabled: false,
};

export const GroupExtra = ({ children }) => <>{children}</>;
