import { Box, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { FillButton } from 'src/containers/app/LogicsEngine/components/ui/FillButton';
import { CofirmDialog } from 'src/containers/app/LogicsEngine/components/ui/Confirm';
import { useState } from 'react';
import useStepSummery from './hooks/useStepSummery';

const RestoreStepBottomButtons = ({
  type,
  setCloneTriggered,
  cloneProgress,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSkip = null,
  setNext,
  setPrevious,
  setDeleteActionid,
}) => {
  const stepSummery = useStepSummery();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <Button
        startIcon={<ArrowLeftIcon />}
        onClick={() => {
          setPrevious();
        }}
        color="secondary"
        variant="outlined"
        disabled={!setPrevious || cloneProgress}
      >
        Previous
      </Button>
      <Box>
        <Button
          style={{ marginRight: '10px' }}
          onClick={() => {
            setCloneTriggered(type);
            // startLocClone();
          }}
          variant="contained"
          disabled={
            (stepSummery && stepSummery[type] && stepSummery[type].donePercentage === 100)
            || cloneProgress
          }
        >
          {cloneProgress ? 'Running...' : 'Clone'}
        </Button>
        {setNext && (
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              onSkip(type);
            }}
            variant="outlined"
            color="warning"
            disabled={
              (stepSummery && stepSummery[type] && stepSummery[type].donePercentage === 100)
              || cloneProgress
            }
          >
            Skip All
          </Button>
        )}
        <Button
          onClick={() => {
            setDeleteActionid();
          }}
          variant="outlined"
          disabled={cloneProgress}
        >
          Cancel
        </Button>
      </Box>
      {setNext && (
        <Button
          endIcon={<ArrowRightIcon />}
          onClick={() => {
            setNext();
          }}
          color="secondary"
          variant="outlined"
          disabled={cloneProgress}
        >
          Next
        </Button>
      )}
    </Box>
  );
};

RestoreStepBottomButtons.defaultProps = {
  setNext: null,
  setPrevious: null,
};

export default RestoreStepBottomButtons;
