/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const MIGRATE_TO_V2 = gql`
  mutation ProjectMigrateToNewPermissionMode($projectId: String!) {
    ProjectMigrateToNewPermissionMode(project_id: $projectId) {
      status
      ref_id
    }
  }
`;

export const GENERATE_LOGIC_REPORT = gql`
  mutation GenLocationReport($projectId: String!) {
    GenLocationReport(project_id: $projectId) {
      ref_id
      status
    }
  }
`;

export const GET_LOCATION_REPORT_INFO = gql`
  mutation GetLocationReportInfo($projectId: String!) {
    GetLocationReportInfo(project_id: $projectId) {
      project_id
      report_link
      report_type
      status_note
      updated_at
    }
  }
`;
