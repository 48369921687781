/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Formik} from 'formik';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import {Chip, CircularProgress, Typography} from '@mui/material';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import {CLOUD_INTEGRATIONS, CiDevice} from 'src/plugins/shared/services/cloud-integration.service';
import {ITEM_DEFAULTS} from '../../../../../config/deviceConfig/item_defaults';
import {USERVIEW_NOTIFICATION_TYPES} from '../../../../../config/deviceConfig/Values';
import {ProjectContext} from '../../../../../store/Project/ProjectContext';
import {searchLogicList} from '../../../../../store/redux/features/logics';
import {RootState, useSelector} from '../../../../../store/redux/store';
import {appConsoleLog} from '../../../../../utility/appUtils';
import {CustomSwitch} from '../../../../shared/Switch/Swtich';
import {
  IUserViewItem,
  ListViewUserviewItemLinkType,
  UserViewItemCategory,
} from '../../../types/types';
import {UVCreateCommDeviceId} from '../../../utils';
import {DeviceCollectionType, ICommDevice} from '../../UVDeviceFinder/types';
import {UVDeviceFinder} from '../../UVDeviceFinder/UVDeviceFinder';
import {UVIconSelector} from '../../UVIconSelector/UVIconSelector';
import styles from './ItemCreateDialog.module.css';
import ItemCreatorExtraFieldGen from './ItemCreatorExtraFields';
import {UVCreatorDefaultFieldMap, UVCreatorFieldsMap} from './uv_creator_fields_map';
import {UVItemAdvanceOptions} from './seconds_steps/UVLinksCreator/components/UVItemAdvanceOptions';
import {SmartListsOtherItem} from './shared/SmartListsOtherItem';
import LabelValueMapping from './shared/LabelValueMapping';

const InternalShellyComponent = pluginHook.shellyPlugin.UserviewExtraFieldComponents;
const InternalEnodeComponent = pluginHook.enodePlugin.uiComponents.getUserViewCreateExtraFields;

const ExtraFields: React.FC<{
  key: string;
  selectedDevice: ICommDevice | (CiDevice & {dc_type: string});
  uvItem: IUserViewItem;
  fieldConfigs: object;
  submitRefresh: any;
  appendSettings: (payload: any) => void;
  updateCustomFieldErrors: any;
}> = props => {
  const {selectedDevice, uvItem} = props;
  if (
    selectedDevice &&
    selectedDevice.dc_type === DeviceCollectionType.cintDevice &&
    // eslint-disable-next-line eqeqeq
    (selectedDevice as CiDevice).cint_type == CLOUD_INTEGRATIONS.SHELLY
  ) {
    return (
      <InternalShellyComponent
        appendSettings={(payload: any) => {
          props.appendSettings(payload);
        }}
        updateCustomFieldErrors={props.updateCustomFieldErrors}
        selectedDevice={selectedDevice as CiDevice & {dc_type: string}}
        uvItem={uvItem}
      />
    );
  }

  if (
    selectedDevice &&
    selectedDevice.dc_type === DeviceCollectionType.cintDevice &&
    // eslint-disable-next-line eqeqeq
    (selectedDevice as CiDevice).cint_type == CLOUD_INTEGRATIONS.ENODE
  ) {
    return (
      <InternalEnodeComponent
        appendSettings={(payload: any) => {
          props.appendSettings(payload);
        }}
        selectedDevice={selectedDevice as CiDevice & {dc_type: string}}
        uvItem={uvItem}
      />
    );
  }
  return (
    <ItemCreatorExtraFieldGen {...props} selectedDevice={props.selectedDevice as ICommDevice} />
  );
};

type ComponentProps = {
  itemCategoryType: UserViewItemCategory;
  uvItem: IUserViewItem | null;
  uvSettings: any;
  selectedDevice: ICommDevice | (CiDevice & {dc_type: string});
  selectedIcon: string;
  selectedColor: string;
  isUpdate: boolean;
  isLoading: boolean;
  formDefaults: any;
  formRef: any;
  selectedOtherItemCat: string;
  submitRefresh: number;
  setDevice: (device: ICommDevice) => void;
  selectNewIcon: (icon: string) => void;
  selectNewColor: (color: string) => void;
  onSubmitForm: (values: any, formikHelpers?: any) => void;
  setFormDefaults: (values: any) => void;
  appendSettings: (payload: any) => void;
  updateCustomFieldErrors: (error: any, hardReset?: boolean) => void;
  setOtherItemCat: (error: any) => void;
  selectedFromTree?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const UVItemCreatorDynamic: React.FunctionComponent<ComponentProps> = ({
  itemCategoryType,
  uvSettings,
  selectedDevice,
  selectedIcon,
  formDefaults,
  setFormDefaults,
  selectedColor,
  formRef,
  selectedOtherItemCat,
  setDevice,
  selectNewIcon,
  selectNewColor,
  onSubmitForm,
  appendSettings,
  submitRefresh,
  updateCustomFieldErrors,
  isUpdate,
  isLoading,
  uvItem,
  setOtherItemCat,
  selectedFromTree,
}) => {
  const selectedProject = useContext(ProjectContext);
  const isOtherItem = itemCategoryType === UserViewItemCategory.otherItem;
  const [isMultiMacro, setIsMultiMacro] = useState(true);
  const [openMacroSelector, setOpenMacroSelector] = useState(false);

  const {result: logics} = useSelector((state: RootState) => state.logics.result);
  const {loading: logics_loading, error: logics_fetch_error} = useSelector(
    (state: RootState) => state.logics.meta
  );
  const [selected_logics, setSelectedLogics] = useState([]);
  const [single_selected_logic, setSingleSelectedLogic] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMultiMacro) {
      if (formDefaults.macro_name && formDefaults.macro_name.length > 0) {
        const logic_ids = formDefaults.macro_name.split(',');
        const selected = logics.filter(item => logic_ids.indexOf(item.logic_id) > -1);
        setSelectedLogics(selected);
      }
    } else if (formDefaults.macro_name && formDefaults.macro_name.length > 0) {
      const selected = logics.find(item => item.logic_id === formDefaults.macro_name);
      setSingleSelectedLogic(selected);
    }
  }, [logics, formDefaults.macro_name, isMultiMacro]);

  useEffect(() => {
    // if (currentItems && resultCount && resultCount == currentItems) return;
    dispatch(
      searchLogicList({
        project_id: selectedProject.selected_project.id,
        limit: 100,
        skip: 0,
        keyword: '',
        logic_status: '',
        logic_version: '',
      })
    );
  }, []);

  useEffect(() => {
    if (selectedOtherItemCat) {
      const found = otherItems.find(item => item.item_type === selectedOtherItemCat);
      if (found && (found.features.uv_settings as any)?.macro_selector_single) {
        setIsMultiMacro(false);
      }
    }
  }, [selectedOtherItemCat]);

  let otherItems = useMemo(() => {
    const {UV_OTHER_ITEMS} = ITEM_DEFAULTS.ITEM_CREATOR;
    const items = Object.values(UV_OTHER_ITEMS.items);

    return items;
  }, []);
  const setDefaultValidators = () => ({
    display_name: Yup.string().required('Required').max(25, 'Maximum item name length 15'),
  });
  const [validators, setValidators] = useState(setDefaultValidators());

  const [fieldConfigs, setFieldConfigs] = useState(UVCreatorDefaultFieldMap);
  useEffect(() => {
    updateCustomFieldErrors({}, true);
    if (uvSettings != null) {
      let currentFieldConfigs = UVCreatorDefaultFieldMap();
      let currentFieldValidator: any = setDefaultValidators();
      let currentFormDefaults: any = {...formDefaults};
      const {fields} = uvSettings;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        const res = UVCreatorFieldsMap(
          field,
          currentFieldConfigs,
          currentFormDefaults,
          currentFieldValidator,
          updateCustomFieldErrors,
          selectNewIcon
        );
        currentFieldConfigs = res.currentFieldConfigsCpy;
        currentFieldValidator = res.currentFieldValidatorCpy;
        currentFormDefaults = res.currentFormDefaultsCpy;
      }

      currentFormDefaults.display_name = selectedDevice?.name;
      setFieldConfigs(currentFieldConfigs);
      setValidators(currentFieldValidator);
      setFormDefaults(currentFormDefaults);
      appConsoleLog('currentFieldConfigs', currentFieldConfigs);
      appConsoleLog('currentFieldValidator', currentFieldValidator);
      appConsoleLog('currentFormDefaults', currentFormDefaults);
    }
  }, [uvSettings]);

  const [initlaFormValue, setInitalFormValue] = useState(formDefaults);
  useEffect(() => {
    if (formRef.current) {
      // eslint-disable-next-line no-debugger
      // debugger;
      formRef.current.setValues(formDefaults);
    }
  }, [formDefaults]);

  let isMainObjSelected = false;

  if (!isOtherItem && selectedDevice) {
    isMainObjSelected = true;
  } else if (isOtherItem && selectedOtherItemCat !== '') {
    isMainObjSelected = true;
  }

  return (
    <div className={styles.creatorRoot}>
      <div className="row">
        <div className="col-md-12 pl-0 pb-0">
          {isOtherItem ? (
            <FormControl
              size="small"
              variant="outlined"
              className="dynamic-ui-form-control-full-width"
            >
              <InputLabel id="other-item-selector-label1">Item type</InputLabel>
              <Select
                labelId="other-item-selector-label1"
                value={selectedOtherItemCat}
                name="other-item-selector"
                onChange={(e: any) => {
                  setOtherItemCat(e.target.value);
                }}
                label="Item type"
              >
                {otherItems.map(item => (
                  <MenuItem key={item.item_type} value={item.item_type}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <UVDeviceFinder
              systemTags={[]}
              label="Select Device"
              autoSelect={!isUpdate}
              defaultValue={
                selectedDevice == null
                  ? ''
                  : UVCreateCommDeviceId(selectedDevice.dc_type, selectedDevice?.id || '')
              }
              defaultDevice={selectedDevice as any}
              freezed={isUpdate}
              withCustomSearch
              onDeviceSelected={async (device, isManually) => {
                await setInitalFormValue({
                  ...initlaFormValue,
                  display_name: device.name,
                });
                setDevice(device);
              }}
              selectedFromTree={selectedFromTree}
            />
          )}
        </div>
        {!isOtherItem && isMainObjSelected && (
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <LabelValueMapping
              device={selectedDevice}
              deviceType={
                selectedDevice.cat === 'location_variables'
                  ? 'location_variables'
                  : (selectedDevice as CiDevice).cint_coll
                  ? 'cint'
                  : null
              }
              initialMap={uvItem?.settings?.label_value_map ?? {}}
              appendSettings={appendSettings}
            />
          </div>
        )}
        {isOtherItem && !isMainObjSelected ? (
          <p className="text-error">* Please select item type</p>
        ) : (
          <div />
        )}
        <div className="col-md-12 pl-0 pb-0" />
        <div className="col-md-12 pl-0 pb-0" />
        {isMainObjSelected != null ? (
          <div className="col-md-12 pl-0 pb-0">
            <Formik
              initialValues={initlaFormValue}
              enableReinitialize
              // validationSchema={Yup.object().shape(validators)}
              // validateOnChange={true}
              onSubmit={onSubmitForm}
              innerRef={formRef}
            >
              {({errors, handleBlur, handleChange, touched, values, submitForm, setFieldValue}) => (
                <form>
                  <div className={styles.textInput}>
                    <TextField
                      className="uv-ui-form-control-full-width"
                      error={Boolean(touched.display_name && errors.display_name)}
                      helperText={
                        errors.display_name && (
                          <Typography>
                            {errors.display_name && errors.display_name.toString()}
                          </Typography>
                        )
                      }
                      onBlur={handleBlur}
                      onChange={e => {
                        handleChange(e);
                      }}
                      value={values.display_name}
                      name="display_name"
                      label="Item Name"
                      size="small"
                      variant="outlined"
                    />
                  </div>
                  <FormControlLabel
                    className="pl-8"
                    control={
                      <CustomSwitch
                        name="visibility"
                        value={values.visibility}
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label="Visibility"
                  />
                  {/* {JSON.stringify(
                    Object.keys(uvItem && uvItem.settings && uvItem.settings.list_settings),
                  )} */}
                  {fieldConfigs.smartlists.enable && (
                    <SmartListsOtherItem
                      selectedItemId={
                        uvItem && uvItem.settings && uvItem.settings.list_settings
                          ? uvItem.settings.list_settings.list_item_id
                          : undefined
                      }
                      selectedSmartlistId={
                        uvItem && uvItem.settings && uvItem.settings.list_settings
                          ? uvItem.settings.list_settings.list_id
                          : undefined
                      }
                      onChange={(arg1, arg2, listInfo) => {
                        if (arg1 && arg2) {
                          appendSettings({
                            data: {
                              list_settings: {
                                link_type: ListViewUserviewItemLinkType.toListItem,
                                type: listInfo.type,
                                list_item_id: arg2,
                                list_id: arg1,
                              },
                            },
                          });
                        } else {
                          appendSettings({
                            data: {
                              list_settings: {
                                link_type: ListViewUserviewItemLinkType.toList,
                                type: listInfo.type,
                                list_id: arg1,
                              },
                            },
                          });
                        }
                      }}
                    />
                  )}
                  {fieldConfigs.rule_name.enable ? (
                    <div className={styles.textInput}>
                      <TextField
                        className="uv-ui-form-control-full-width"
                        error={Boolean(touched.rule_name && errors.rule_name)}
                        helperText={
                          errors.rule_name && <Typography>{errors.rule_name.toString()}</Typography>
                        }
                        onBlur={handleBlur}
                        onChange={e => {
                          handleChange(e);
                        }}
                        value={values.rule_name}
                        name="rule_name"
                        label="Send A Command to Hub"
                        size="small"
                        variant="outlined"
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                  {fieldConfigs.macro_name.enable ? (
                    <div className={styles.textInput}>
                      <Autocomplete
                        onChange={(e, value) => {
                          if (isMultiMacro) {
                            const logic_ids = [];
                            value.map(item => logic_ids.push(item.logic_id));
                            setFieldValue('macro_name', logic_ids.toString()); //+
                            setSelectedLogics([...value]);
                          } else {
                            setFieldValue(
                              'macro_name',
                              value && value.logic_id ? value.logic_id : ''
                            );
                            setFieldValue('display_name', value && value.name ? value.name : '');
                            setSingleSelectedLogic(value);
                          }
                        }}
                        size="small"
                        open={openMacroSelector}
                        onOpen={() => {
                          setOpenMacroSelector(true);
                        }}
                        onClose={() => {
                          setOpenMacroSelector(false);
                        }}
                        // getOptionSelected={(option, value) =>
                        //   option.id === value.id
                        // }
                        getOptionLabel={option => option.name}
                        options={logics}
                        loading={logics_loading}
                        value={isMultiMacro ? selected_logics : single_selected_logic}
                        renderInput={params => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="outlined"
                            label={isMultiMacro ? 'Search Logics' : 'Search logic'}
                            // onChange={(e) => console.log('xcxcxcx', e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {logics_loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Chip label={option.name} {...getTagProps({index})} />
                          ))
                        }
                        multiple={isMultiMacro}
                      />
                      {/* <TextField
                          className={"uv-ui-form-control-full-width"}
                          error={Boolean(
                            touched.macro_name && errors.macro_name
                          )}
                          helperText={touched.macro_name && errors.macro_name}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.macro_name}
                          name="macro_name"
                          label={"Logics"}
                          size="small"
                          variant="outlined"
                        /> */}
                    </div>
                  ) : (
                    <div />
                  )}
                  {fieldConfigs.item_notification_type.enable ? (
                    <div className={styles.textInput}>
                      <FormControl
                        size="small"
                        variant="outlined"
                        className="dynamic-ui-form-control-full-width"
                      >
                        <InputLabel id="1custom_selector-label1">Notification Type</InputLabel>
                        <Select
                          labelId="1custom_selector-label1"
                          value={values.item_notification_type}
                          name="item_notification_type"
                          onChange={(e: any) => {
                            handleChange(e);
                          }}
                          label="Notification Type"
                        >
                          {USERVIEW_NOTIFICATION_TYPES.map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <div />
                  )}
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div />
        )}
      </div>

      <div>
        {isMainObjSelected != null ? (
          <ExtraFields
            key={`${selectedOtherItemCat}`}
            selectedDevice={selectedDevice}
            uvItem={uvItem}
            fieldConfigs={fieldConfigs}
            submitRefresh={submitRefresh}
            appendSettings={appendSettings}
            updateCustomFieldErrors={updateCustomFieldErrors}
          />
        ) : (
          // <ItemCreatorExtraFieldGen
          //   key={`${selectedOtherItemCat}`}
          //   selectedDevice={selectedDevice}
          //   uvItem={uvItem}
          //   fieldConfigs={fieldConfigs}
          //   submitRefresh={submitRefresh}
          //   appendSettings={appendSettings}
          //   updateCustomFieldErrors={updateCustomFieldErrors}
          // />
          <div />
        )}

        {isMainObjSelected != null ? (
          <div>
            <div className="col-md-12 pl-0 pb-0">
              <div>
                <UVIconSelector
                  initialIcon={selectedIcon}
                  initialColor={selectedColor}
                  onChange={(icon, color) => {
                    selectNewIcon(icon);
                    selectNewColor(color);
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 pl-0 pb-0">
              <UVItemAdvanceOptions
                selectedDevice={selectedDevice as any}
                uvItem={uvItem}
                onChange={data => {
                  appendSettings({data: {advance_options: data}});
                }}
              />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

UVItemCreatorDynamic.defaultProps = {
  selectedFromTree: null,
};
