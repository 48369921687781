/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import {
  FormControl,
  Select,
  Grid,
  MenuItem,
  Box,
  InputLabel,
  TextField,
  FormHelperText,
  Typography,
  Popover,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { number } from 'yup';
import {
  Trigger,
  CronCustomState,
  CronDateSelectState,
  CronState,
  CRON_OBJECT_TYPES,
  EXECUTION_LIMIT,
  REOCCURING_BASED_ON,
  TIME_FRAME,
  CronParser,
  SunSetSunRiseState,
} from '@smartlife-redux-state/common';
import { update_trigger } from '../../../../../../../../../store/redux/features/logic-engine';
import { RootState, useDispatch } from '../../../../../../../../../store/redux/store';

import { CronTypeDateSelector } from './cronTypes/CronTypeDateSelector';
import { CustomCronSelector } from './cronTypes/CustomCronSelector';
import { RecurrentCronSelector } from './cronTypes/RecurrentCronSelector';
import { CRON_TYPES } from './CRON_TYPES';
import SunsetSunrise from './cronTypes/SunsetSunrise';
import { CronRunCountSelector } from './components/CronRunCountSelector';

type ComponentProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  initialValue?: any;
  trigger: Trigger;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  formControl: {
    width: '100%',
  },
  between_root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popover: {},
  paper: {
    padding: theme.spacing(1),
  },
  execution_show: {
    padding: '2px',
    textAlign: 'center',
    background: '#ebd234',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    fontSize: '12px',
    color: '#fe0000',
  },
}));

const initial_reocurrent_state = {
  type: CRON_OBJECT_TYPES.CRON_REOCCURENT,
  data: {
    days: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      0: true,
    },
    reoccuring_based_on: REOCCURING_BASED_ON.SCHEDULE,
    variables: {
      time: '07.25',
      time_frame: TIME_FRAME.AM,
    } as any,
  },
  execution_limit: {
    type: EXECUTION_LIMIT.UNLIMITED,
    value: 0,
  },
};

const initial_custom_cron_state = {
  type: CRON_OBJECT_TYPES.CRON_CUSTOM,
  data: {
    cron: '0 * * * *',
  },
  execution_limit: {
    type: EXECUTION_LIMIT.ONCE,
    value: 1,
  },
};

const initial_date_state = {
  type: CRON_OBJECT_TYPES.CRON_DATE_SELECT,
  data: {
    date: null,
    time: null,
  },
  execution_limit: {
    type: EXECUTION_LIMIT.ONCE,
    value: 1,
  },
};

const initial_sunset_sunrise_state = {
  sun_type: 'sun_set',
  reoccurrence: { type: 'everyday', value: '' },
  time_diff: { type: 'during', value: 0 },
  days_in_week: '0,1,6',
  times_can_trigger: 11,
  extra: {
    execution_limit: {
      type: EXECUTION_LIMIT.ONCE,
      value: 1,
    },
    days: {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      0: true,
    },
    weekdays: 'only_selected_weekdays',
  },
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// eslint-disable-next-line import/prefer-default-export
export const CronSelector: React.FC<ComponentProps> = (props) => {
  const { trigger } = props;
  const classes = useStyles();
  // const [cronType, setCronType] = useState(CRON_OBJECT_TYPES.CRON_REOCCURENT);

  // const [cron, setCron] = useState<CronState>(null);

  const cron = JSON.parse(trigger.variable) as CronState;
  // const cronType1 =

  // useEffect(() => {
  //   if (cron == null) {
  //   }
  //   console.log("CRON STATE", cron);
  //   console.log("CRON STATE", JSON.stringify(cron));
  //   //Check cron parser

  // }, [cron]);

  const [cronStr, setCronStr] = useState('');
  const [nextExecutions, setNextExecutions] = useState<string[]>([]);

  useEffect(() => {
    let parsed: CronState | SunSetSunRiseState = null;
    console.log('CRON GOT', props.trigger.variable, props.trigger.type);
    try {
      if (
        props.trigger.type === 'scheduler_time'
        && props.trigger.variable
        && isJson(props.trigger.variable)
      ) {
        parsed = JSON.parse(props.trigger.variable);
        const v = CronParser(parsed) as any;
        if (v) {
          setCronStr(v.cron);
          setNextExecutions(v.next_invocations);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [trigger.variable, trigger.type]);

  const dispatch = useDispatch();

  const handleValueChange = (
    payload: any,
    execution_limit?: {
      type: EXECUTION_LIMIT;
      value: number;
    },
    type?: string,
  ) => {
    console.log('PAYLOAD', payload);
    // setCron({ ...cron, data: { ...payload } });
    if (type && type === 'type5') {
      dispatch(
        update_trigger({
          trigger: {
            ...props.trigger,
            variable: JSON.stringify({
              ...cron,
              ...payload,
            }),
          },
        }),
      );
      return;
    }
    dispatch(
      update_trigger({
        trigger: {
          ...props.trigger,
          variable: JSON.stringify({
            ...cron,
            data: { ...payload },
            execution_limit: execution_limit ? { ...execution_limit } : cron.execution_limit,
          }),
        },
      }),
    );
  };

  function onTypeSelect(type: string) {
    switch (type) {
      case 'type1':
        return dispatch(
          update_trigger({
            trigger: {
              ...props.trigger,
              variable: JSON.stringify({
                ...initial_date_state,
                type: CRON_OBJECT_TYPES.CRON_DATE_SELECT,
              }),
            },
          }),
        );
      case 'type2':
        return dispatch(
          update_trigger({
            trigger: {
              ...props.trigger,
              variable: JSON.stringify({
                ...initial_reocurrent_state,
                type: CRON_OBJECT_TYPES.CRON_REOCCURENT,
              }),
            },
          }),
        );
      case 'type3':
        return dispatch(
          update_trigger({
            trigger: {
              ...props.trigger,
              variable: JSON.stringify({
                ...initial_reocurrent_state,
                execution_limit: {
                  type: EXECUTION_LIMIT.ONCE,
                  value: 1,
                },
                type: CRON_OBJECT_TYPES.CRON_REOCCURENT_ONCE,
              }),
            },
          }),
        );
      case 'type4':
        return dispatch(
          update_trigger({
            trigger: {
              ...props.trigger,
              variable: JSON.stringify({
                type: CRON_OBJECT_TYPES.CRON_CUSTOM,
                ...initial_custom_cron_state,
              }),
            },
          }),
        );
      case 'type5':
        return dispatch(
          update_trigger({
            trigger: {
              ...props.trigger,
              variable: JSON.stringify({
                type: 'type5',
                ...initial_sunset_sunrise_state,
              }),
            },
          }),
        );
      default:
        return update_trigger({
          trigger: {
            ...props.trigger,
            variable: JSON.stringify({
              ...initial_reocurrent_state,
              type: CRON_OBJECT_TYPES.CRON_REOCCURENT,
            }),
          },
        });
    }
  }
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, check = false) => {
    if (check) {
      if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <FormControl size="small" variant="outlined" className={classes.formControl}>
              <InputLabel>Scheduling Type</InputLabel>
              <Select
                label="Scheduling Type"
                value={cron && cron.type}
                onChange={(event: any) => {
                  onTypeSelect(event.target.value);
                }}
              >
                {CRON_TYPES.map((cronType) => (
                  <MenuItem value={cronType.type} key={cronType.type}>
                    <Box key={cronType.type}>
                      {`${cronType.title} `}
                      {' '}
                      <FormHelperText>
                        {' '}
                        Ex.
                        {cronType.placeholder}
                      </FormHelperText>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {cron && (
          <Box paddingTop={1}>
            {cron.type === 'type1' ? (
              <CronTypeDateSelector
                typeKey={`${cron.type}_0`}
                date={cron.data as CronDateSelectState}
                onChange={handleValueChange}
              />
            ) : (
              <div />
            )}
            {cron.type === 'type2' || (cron.type === 'type3' && (cron.data as any).days) ? (
              <RecurrentCronSelector
                typeKey={`${cron.type}_0`}
                cronState={cron}
                onChange={handleValueChange}
              />
            ) : (
              <div />
            )}
            {cron.type === 'type4' && (
              <>
                <TextField
                  variant="outlined"
                  label="Custom cron value"
                  size="small"
                  error={
                    cron.data && (cron.data as CronCustomState).cron
                      ? (cron.data as CronCustomState).cron.split(' ').length > 5
                      : false
                  }
                  value={(cron.data as CronCustomState).cron}
                  onChange={(e: any) => handleValueChange({
                    cron: e.target.value,
                  })}
                />
                <CronRunCountSelector
                  typekey="type4"
                  initialValue={cron.execution_limit && cron.execution_limit}
                  onChange={(count) => {
                    // onExecutionLimitChange(count);
                    handleValueChange(cron.data, count);
                  }}
                />
              </>
            )}
            {(cron.type as any) === 'type5' && (
              <SunsetSunrise
                state={cron}
                onChange={(payload: any) => handleValueChange(payload, null, 'type5')}
              />
            )}
          </Box>
        )}
      </Box>
      <Box paddingTop={1}>
        {nextExecutions && nextExecutions.length > 0 && (
          <>
            <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onClick={(event) => handlePopoverOpen(event, true)}
              className={classes.execution_show}
            >
              ?
            </Typography>

            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <FormHelperText>Next Executions</FormHelperText>
              {nextExecutions.map((v, i) => (
                <Typography key={i}>{v}</Typography>
              ))}
            </Popover>
          </>
        )}
      </Box>
    </div>
  );
};

CronSelector.defaultProps = {
  initialValue: null,
};
