import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Tag } from "src/plugins/shared/uvitem.config";

const tags: Record<'tags1', Record<string, Tag>> = {
    tags1: {
        motion: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'motion',
            description: 'Motion',
            display_suffix: '', 
            config: {
              mapping: {
                true: 'ACTIVE',
                false: 'NORMAL',
              },
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
}

export default tags;