import React, { useEffect } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { TextField } from '@mui/material';
import { CronDateSelectState, TIME_FRAME } from '@smartlife-redux-state/common';
import { DatePickerUtils, TimePickerUtils } from '../DateAdapters';
import { TimeSelector } from '../components/TimeSelector';

type ComponentProps = {
  typeKey: string;
  date: CronDateSelectState;
  // initialValue: { type_key: string; payload: any };
  onChange: (data: any) => void;
};
// eslint-disable-next-line import/prefer-default-export
export const CronTypeDateSelector: React.FC<ComponentProps> = ({
  typeKey,
  date,
  // nitialValue,
  onChange,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<any>({
    date: moment(),
    date_value: moment().format('YYYY-MM-DD'),
  });

  const [selectedTime, setSelectedTime] = React.useState<string>('08:25');

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleDateChange = (date: any) => {
    console.log(date);
    onChange({
      date,
      time: date.split('T')[1],
    });
  };

  useEffect(() => {
    if (date) {
      setSelectedDate({
        date: moment(date.date, 'YYYY-MM-DD'),
        date_value: date.date,
      });

      setSelectedTime(date.time);
    }
  }, []);

  const isError = date && date.date && moment(`${date.date.split('T')[0]}-${date.date.split('T')[1]}`, 'YYYY-MM-DD-HH:mm').diff(moment()) < 0;

  return (
    <Grid container>
      <TextField
        id="datetime-local"
        size="small"
        variant="outlined"
        label="Run on"
        type="datetime-local"
        defaultValue={date.date}
        onChange={(e) => handleDateChange(e.target.value)}
        // className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        error={isError}
        helperText={isError && 'Please select a future date'}
        InputProps={{
          inputProps: {
            min: (new Date()).toLocaleDateString(),
          },
        }}
      />
    </Grid>
  );
};
