import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@mui/material';
import { APP_TEXT } from '../../../utility/AppTexts/DefaultAppText.utils';

import styles from './LoadingFull.module.css';

export const LoadingFullPageComponent: FunctionComponent = () => (
  <div>
    <div className={styles.bg1} />
    <div className={styles.bg2}>
      <div className={styles.content}>
        <div className={styles.logoWithName} />
        <div className={styles.loadingContainer}>
          <CircularProgress className={styles.circularLoader} variant="indeterminate" />
        </div>
        <div className={styles.titleDarkText}>{APP_TEXT.loading.SMART_HOMES_LIFESTYLES}</div>
      </div>
    </div>
  </div>
);

export default LoadingFullPageComponent;
