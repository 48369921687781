/* eslint-disable consistent-return */
/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase';
import build from '@date-io/moment';
import { cloneDeep } from 'lodash';
import { BACKUP_RESTORE_STEP_TYPES } from '../backup-restore';
import { cloneStep } from './async-thunks/form/form';
import { BackupLocationConfigState, BackupUIRightPanel, LEFT_PANEL_TABS } from './types';
import { getInfo, initInfo } from './async-thunks/info/info';
import { completeTask, getLastTaskHistory } from './async-thunks/history/history';
import { PendingComponentClientSideMeta, V2BStepackupInfoRoot } from './v2types';

const initial_state: BackupLocationConfigState = {
  ui: {
    initializing_error: null,
    is_init: false,
    is_done: false,
    right_panel: {
      configurable_steps_in_order: [],
      next_step_to_configure: BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP,
      expanded_step: BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP,
      steps: {
        [BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP,
          label: 'Restore Location Variables',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP,
          label: 'Restore SmartLife Connect Containers',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.AIR_DC_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.AIR_DC_SETUP,
          label: 'Restore SmartLife Air Containers',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.AIR_DC_DEVICE_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.AIR_DC_DEVICE_SETUP,
          label: 'Restore SmartLife Air Devices',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.UV_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.UV_SETUP,
          label: 'Restore SmartLife User Views',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.UV_ITEM_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.UV_ITEM_SETUP,
          label: 'Restore SmartLife User Views Items',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.LOGIC_SETUP]: {
          is_done: false,
          is_running: false,
          is_configurable: false,
          ui_type: BACKUP_RESTORE_STEP_TYPES.LOGIC_SETUP,
          label: 'Restore SmartLife Logics',
          errors: [],
        },
        [BACKUP_RESTORE_STEP_TYPES.NODE_RED]: {
          is_done: false,
          is_running: false,
          is_configurable: true,
          ui_type: BACKUP_RESTORE_STEP_TYPES.NODE_RED,
          label: 'Upload Node Red Config',
          errors: [],
        },
      },
    },
    left_panel: {
      active_tab: 1,
      log_path: null,
      tabs: {
        [LEFT_PANEL_TABS.CONTAINER_CREATE]: {
          label: 'Create Device Container',
          key: 0,
          type: LEFT_PANEL_TABS.CONTAINER_CREATE,
        },
        [LEFT_PANEL_TABS.TERMINAL]: {
          label: 'Terminal',
          key: 1,
          type: LEFT_PANEL_TABS.TERMINAL,
        },
      },
    },
  },
  info: {
    v2: {
      hidden: null,
      lvar: null,
      prodc: null,
      airdc: null,
      cintd: null,
    },
    docs: [],
    backup_point: null,
    loc_var_map: {},
    pro_dc_map: {},
    air_dc_map: {},
    air_device_map: {},
    loc_var_dc_map: null,
    pro_device_map: null,
    project_info: null,
    created_at: null,
    userviews_map: {},
    userview_items_count: {},
    pro_device_count: {},
    air_device_count: {},
  },
  history: {
    past_tasks_list: {},
    last_past_task: null,
  },
  form_state: {
    clone_step_location_var: null,
    clone_step_pro_dc: {
      is_done_fill: false,
      values: {},
    },
    clone_step_air_dc: {
      is_done_fill: false,
      values: {},
    },
  },
};

/**
 * 1 get Info
 * 2. get history
 * 3. reset from input
 */

const backupLocationSlice = createSlice({
  name: 'backup-location-config',
  initialState: initial_state,
  reducers: {
    ui_update_right_panel_next_step_to_configure: (
      state,
      action: {payload: {type: BACKUP_RESTORE_STEP_TYPES}},
    ) => {
      state.ui.right_panel.next_step_to_configure = action.payload.type;
    },
    ui_update_right_panel_steps: (
      state,
      action: {
        payload: {data: BackupUIRightPanel; type: BACKUP_RESTORE_STEP_TYPES};
      },
    ) => {
      state.ui.right_panel.steps[action.payload.type] = action.payload.data;
    },
    ui_update_left_active_tab: (state, action: {payload: {tab: number}}) => {
      state.ui.left_panel.active_tab = action.payload.tab;
    },
    ui_update_left_log_path: (state, action: {payload: {path: string}}) => {
      state.ui.left_panel.log_path = action.payload.path;
    },
    clone_step_pro_dc_add_dc_map: (
      state,
      action: {
        payload: {old_id: string; new_id: string};
      },
    ) => {
      state.form_state.clone_step_pro_dc.values[action.payload.old_id] = {
        value: action.payload.new_id,
        isDirty: true,
      };
    },
    clone_step_pro_dc_confirm: (state, action: {payload: {value: boolean; old_dc_id: string}}) => {
      state.form_state.clone_step_pro_dc.values[action.payload.old_dc_id].isDirty = action.payload.value;
    },
    clone_step_air_dc_add_dc_map: (state, action: {payload: {old_id: string; new_id: string}}) => {
      state.form_state.clone_step_air_dc.values[action.payload.old_id] = {
        value: action.payload.new_id,
        isDirty: true,
        devices: {},
      };
    },
    clone_step_air_dc_add_device_map: (
      state,
      action: {payload: {dc_id: string; device_id: string; tuya_id: string}},
    ) => {
      state.form_state.clone_step_air_dc.values[action.payload.dc_id].devices[
        action.payload.device_id
      ] = action.payload.tuya_id;
    },
    clone_step_air_dc_confirm: (state, action: {payload: {value: boolean; old_dc_id: string}}) => {
      state.form_state.clone_step_air_dc.values[action.payload.old_dc_id].isDirty = action.payload.value;
    },
    v2InfoUpdate: (state, action: {payload: {value: V2BStepackupInfoRoot; key: string, mergeErrorComps: boolean}}) => {
      const cpy = action.payload.value;
      if (action.payload.mergeErrorComps) {
        if (Object.keys(cpy.errorComps).length > 0) {
          Object.keys(cpy.errorComps).map((dcId) => {
            const dc = cpy.errorComps[dcId];
            cpy.pendingComps[dcId] = {
              ...cpy.pendingComps[dcId],
              ...dc,
            };
            return 0;
          });
        }
      }
      state.info.v2[action.payload.key] = cpy; // action.payload.value;
    },
    v2Reset: (state) => {
      state.info.v2 = {
        hidden: null,
        lvar: null,
        prodc: null,
        airdc: null,
        cintd: null,
      };
    },
    v2HideItems: (state, action: { payload: {devices: {[deviceId: string]: boolean}, containerId: string, forceReset?: boolean} }) => {
      if (!state.info.v2.hidden) state.info.v2.hidden = {};

      const updateTo = action.payload.forceReset ? { ...action.payload.devices } : { ...state.info.v2.hidden[action.payload.containerId], ...action.payload.devices };

      state.info.v2.hidden[action.payload.containerId] = updateTo;
    },
    v2InitHideItems: (state, action: { payload: { hidden: any } }) => {
      state.info.v2.hidden = action.payload.hidden;
    },
    v2InfoClientSideMetaUpdate: (
      state,
      action: {
        payload: {
          value: PendingComponentClientSideMeta;
          key: 'lvar' | 'prodc' | 'airdc';
          componentPositionKey: 'pendingComps' | 'errorComps';
          containerId: string;
          deviceId: string;
        };
      },
    ) => {
      debugger;
      const step = state.info.v2[action.payload.key];

      if (step) {
        const component = step[action.payload.componentPositionKey];

        if (!component) return null;

        const container = component[action.payload.containerId];
        if (container) {
          const device = container[action.payload.deviceId];
          if (!device) return null;

          const deviceCopy = cloneDeep(device);

          deviceCopy.clientSideMeta = action.payload.value;

          state.info.v2[action.payload.key][action.payload.componentPositionKey][
            action.payload.containerId
          ][action.payload.deviceId] = deviceCopy;
        }
      }
    },
    v2PullDroppedItem: (state, action: { payload: { type: 'lvar' | 'prodc' | 'airdc' | 'cintd', dcId: string, deviceId: string } }) => {
      if (state.info.v2[action.payload.type].pendingComps) {
        const dc = state.info.v2[action.payload.type].pendingComps[action.payload.dcId];

        if (dc && dc[action.payload.deviceId]) {
          delete state.info.v2[action.payload.type].pendingComps[action.payload.dcId][action.payload.deviceId].clientSideMeta;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfo.fulfilled, (state, res) => {
      const paylaod = res.payload;
      state.info[paylaod.type] = paylaod.data.result[paylaod.type];
    });
    builder.addCase(initInfo.fulfilled, (state, res) => {
      const { payload } = res;
      const { loc_var_map } = payload.data.result;
      const { pro_dc_map } = payload.data.result;
      const { pro_device_count } = payload.data.result;
      const { air_dc_map } = payload.data.result;
      const { air_device_count } = payload.data.result;
      const uv_map = payload.data.result.userviews_map;
      const uv_item_count = payload.data.result.userview_items_count;

      const location_configurable_steps: BACKUP_RESTORE_STEP_TYPES[] = [];
      if (loc_var_map && Object.keys(loc_var_map).length > 0) {
        state.ui.right_panel.steps[
          BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP
        ].is_configurable = true;
        state.info.loc_var_map = payload.data.result.loc_var_map;

        location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP);
      }
      if (pro_dc_map && Object.keys(pro_dc_map).length > 0) {
        state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP].is_configurable = true;
        state.info.pro_dc_map = payload.data.result.pro_dc_map;
        state.info.pro_device_count = pro_device_count;
        location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP);
      }
      // if(pro_device_count && Object.keys(pro_device_count).length > 0) {
      //   state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP].is_configurable = true;
      //   state.info.pro_dc_map = payload.data.result.pro_dc_map;

      //   location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP);
      // }
      if (air_dc_map && Object.keys(air_dc_map).length > 0) {
        state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.AIR_DC_SETUP].is_configurable = true;
        state.info.air_dc_map = payload.data.result.air_dc_map;
        state.info.air_device_count = air_device_count;
        location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.AIR_DC_SETUP);
      }
      if (uv_map && Object.keys(uv_map).length > 0) {
        state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.UV_SETUP].is_configurable = true;
        state.info.userviews_map = payload.data.result.userviews_map;

        location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.UV_SETUP);
      }

      state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.LOGIC_SETUP].is_configurable = true;

      location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.LOGIC_SETUP);

      if (uv_item_count && Object.keys(uv_item_count).length > 0) {
        state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.UV_ITEM_SETUP].is_configurable = true;
        state.info.userviews_map = payload.data.result.userviews_map;

        location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.UV_ITEM_SETUP);
      }
      if (pro_dc_map && Object.keys(pro_dc_map).length > 0) {
        location_configurable_steps.push(BACKUP_RESTORE_STEP_TYPES.NODE_RED);
      }

      state.ui.right_panel.configurable_steps_in_order = location_configurable_steps;
      state.ui.is_init = true;
    });
    builder.addCase(initInfo.rejected, (state, res) => {
      console.log(res);
      state.ui.initializing_error = res.error;
      state.ui.is_init = true;
    });
    builder.addCase(cloneStep.pending, (state, res) => {
      state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP].is_running = true;
    });
    builder.addCase(cloneStep.fulfilled, (state, res) => {
      const type = TaskTypeToBackupRestoreTypeMap[res.payload.result.type];
      state.ui.right_panel.steps[type] = {
        ...state.ui.right_panel.steps[type],
        is_done: true,
        is_running: false,
      };
      console.log(res.payload.result.log_ref);
      state.ui.left_panel.log_path = res.payload.result.log_ref;
    });
    builder.addCase(cloneStep.rejected, (state, res) => {
      state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP] = {
        ...state.ui.right_panel.steps[BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP],
        is_done: false,
        is_running: false,
        errors: [res.error],
      };
      console.log(res.error);
      console.log('Exited(0) Failed to clone location variable.');
    });
    builder.addCase(getLastTaskHistory.fulfilled, (state, res) => {
      state.history.last_past_task = res.payload.result;
      if (!res.payload.result) {
        // eslint-disable-next-line prefer-destructuring
        state.ui.right_panel.next_step_to_configure = state.ui.right_panel.configurable_steps_in_order[0];
        return;
      }

      const last_task_type = res.payload.result.type;
      const coresponding_backup_step_type_for_last_task = TaskTypeToBackupRestoreTypeMap[last_task_type];

      if (res.payload.result.status === 'e') {
        console.log('LAST CREE', coresponding_backup_step_type_for_last_task);
        state.ui.right_panel.next_step_to_configure = coresponding_backup_step_type_for_last_task;
        return;
        // if(res.payload.result.type == "1lv" ) {
        //   state.ui.right_panel.next_step_to_configure = BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP;
        //   return;
        // }
        // if(res.payload.result.type == "3pd") {
        //   state.ui.right_panel.next_step_to_configure = BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP;
        //   return;
        // }

        // TODO - add other types
      }

      const current_type_index = state.ui.right_panel.configurable_steps_in_order.indexOf(
        coresponding_backup_step_type_for_last_task,
      );
      const next_backup_step_type = current_type_index > -1
        ? state.ui.right_panel.configurable_steps_in_order[current_type_index + 1]
        : coresponding_backup_step_type_for_last_task;

      state.ui.right_panel.next_step_to_configure = next_backup_step_type;

      // state.ui.right_panel.next_step_to_configure = BACKUP_RESTORE_STEP_TYPES.NODE_RED;

      // if(res.payload.result.type == "1lv" && res.payload.result.status != "e") {
      //   state.ui.right_panel.next_step_to_configure = BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP;
      // }
      // if(res.payload.result.type == "3pd" && res.payload.result.status != "e") {
      //   state.ui.right_panel.next_step_to_configure = BACKUP_RESTORE_STEP_TYPES.AIR_DC_SETUP;
      // }
    });
    builder.addCase(completeTask.fulfilled, (state, res) => {
      state.ui.is_done = true;
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    builder.addCase(completeTask.rejected, (state, res) => {});
  },
});

// locationVariables = "1lv",
// ProDevice = "3pd",
// AirDevice = "5ad",
// userViews = "6uv",
// userviewItems = "7uvi",
// Logics = "8l",
// NodeRedJsonIdUpdate = "9n",

const TaskTypeToBackupRestoreTypeMap = {
  lvar: BACKUP_RESTORE_STEP_TYPES.LOCATION_VARIABLES_SETUP,
  prod: BACKUP_RESTORE_STEP_TYPES.PRO_DC_SETUP,
  aird: BACKUP_RESTORE_STEP_TYPES.AIR_DC_SETUP,
  uv: BACKUP_RESTORE_STEP_TYPES.UV_SETUP,
  lgc: BACKUP_RESTORE_STEP_TYPES.LOGIC_SETUP,
  uvi: BACKUP_RESTORE_STEP_TYPES.UV_ITEM_SETUP,
};

export const { reducer } = backupLocationSlice;

export const {
  ui_update_right_panel_next_step_to_configure,
  ui_update_right_panel_steps,
  ui_update_left_active_tab,
  ui_update_left_log_path,
  clone_step_pro_dc_add_dc_map,
  clone_step_pro_dc_confirm,
  clone_step_air_dc_add_dc_map,
  clone_step_air_dc_add_device_map,
  clone_step_air_dc_confirm,
  v2InfoUpdate,
  v2InfoClientSideMetaUpdate,
  v2Reset,
  v2PullDroppedItem,
  v2HideItems,
  v2InitHideItems,
} = backupLocationSlice.actions;
