import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
});

const ListLayout: React.FC<{children: any}> = ({ children }) => {
  const styles = useStyle();
  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>Manage Lists</Typography>
        </Box>
      </PageHeader>
      <Box style={{ padding: '2% 3% 0 3%', overflowY: 'scroll', marginBottom: 10 }}>{children}</Box>
    </Layout>
  );
};

export default ListLayout;
