/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, {useContext, useEffect, useRef, useState} from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import {useSnackbar} from 'notistack';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import {ArrowForward, TextFields, DeleteForever} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  IconButton,
  InputAdornment,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Typography,
  Grid,
  FormHelperText,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Autocomplete,
  Switch,
  Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import {useSelector} from 'react-redux';
import TimezoneSelect, {ITimezone, allTimezones} from 'react-timezone-select';

import {enqueueSnackbar} from 'src/store/redux/features/common';
import {TextInput} from '@smartlife/common-ui';
import {ApolloFunctions} from 'src/store/Apollo/ApolloFunctions';
import parseCustomApiError from 'src/utility/parseError';
import moment from 'moment';
import {APP_TEXT} from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './CreateEditCustomProject.module.css';
import {AuthContext} from '../../../../store/Auth/AuthContext';
import {ApolloAuthContext} from '../../../../store/Apollo/ApolloContext';
import {ImgDragDrop} from '../../../../components/shared/DragDropFile/DragDropFile';
import {
  createProjectGraphQL,
  deleteProjectGraphQL,
  generateLogicReportV2,
  getLocationReportV2,
  migrateToV2,
  projectGetGraphQL,
  updateProjectGraphQL,
  uploadProjectImageGraphQL,
  uploadProjectImageRest,
} from '../../../../services/location/location.service';
import {LoadingComponent} from '../../../../components/shared/Loading/Loading';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import {ProjectContext} from '../../../../store/Project/ProjectContext';
import {appConsoleLog, AppUtils} from '../../../../utility/appUtils';
import selectedIcon from '../../../../assets/images/icons/checkmark-selected.png';
import notSelectedIcon from '../../../../assets/images/icons/checkmark-not-selected.png';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import Re14Component from '../../../../components/app/Re14';
import Layout from '../../../../components/layout/Layout';
import {PageHeader} from '../../../../components/layout/PageHeader';
import PageContentLayout, {
  PageConentHeader,
  PageContent,
} from '../../LogicsEngine/components/PageContentLayout';
import {FillButton} from '../../LogicsEngine/components/ui/FillButton';
import {RootState, useDispatch} from '../../../../store/redux/store';
import {set_backup_serial, set_project_info} from '../../../../store/redux/features/backup-restore';
import {ConfirmLocDelete} from './components/ConfirmLocDelete';
import {CountryCodes} from '../../../../config/countryCodes';
import {CustomDelete} from './components/CustomDelete';
import {LogicRefresh} from './components/LogicRefresh';

type ILoginFormValue = {
  location_name: string;
  location_description: string;
  demo: boolean;
  street?: string;
  suburb?: string;
  postal_code?: string;
  city?: string;
};
const formValues: ILoginFormValue = {
  location_name: '',
  location_description: '',
  demo: false,
  street: '',
  suburb: '',
  postal_code: '',
  city: '',
};

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
  },
  stepRoot: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    padding: '2% 0',
  },
  stepTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  stepTabTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
    marginRight: '2%',
  },
  stepTabLine: {
    height: '100%',
    position: 'relative',
    flex: '1',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderBottom: '1px solid #D9DFE0',
      height: '100%',
      width: '100%',
      transform: 'translateY(-45%)',
    },
  },
  lastTabLine: {
    flex: 1,
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'center',
    backgroun: 'black',
    border: '8px solid #D9DFE0',
    borderRadius: '50%',
    marginRight: '2%',
    color: 'var(--light-green)',
  },
  circleIcon: {
    color: 'white',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    overflow: 'hidden',
  },
  root: {
    // height: "100%",
    display: 'flex',
    flexDirection: 'column',
  },
  layoutRoot: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '2%',
    width: '100%',
    background: 'transparent',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    margin: '3% 1%',
    justifyContent: 'flex-end',
  },
});

export const CreateEditCustomProjectComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);
  const dispatch = useDispatch();
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [projectImg, projectImgSet] = useState<any>();
  const [projectImgFile, setProjectImgFile] = useState<any>();
  const [projectStatus] = useState(1);
  const [projectType, setProjectType] = useState(1);

  const [locationID, locationIDSet] = useState('');
  const [isUpdating, updateingStatusSet] = useState(false);
  const [imgBgStyle, imgBgStyleSet] = useState({
    backgroundImage:
      "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
  });

  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const template_seriel = useQueryString('loc_temp_serial') as string;

  const classes = useStyle();

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    if (!isUpdating) return;
    if (
      selectedProject &&
      selectedProject.selected_project &&
      selectedProject.selected_project.title &&
      selectedProject.selected_project.id
    ) {
      getProjectByID(selectedProject.selected_project.id);
    }
  }, [isUpdating]);

  useEffect(() => {
    if (
      selectedProject &&
      selectedProject.selected_project &&
      selectedProject.selected_project.title &&
      selectedProject.selected_project.id
    ) {
      // appConsoleLog("selectedProject",selectedProject)
      formValues.location_description = selectedProject.selected_project.description;
      formValues.location_name = selectedProject.selected_project.title;

      console.log('XXXXXXXXXXXXXXXXXXXXX', selectedProject);

      formValues.demo = selectedProject.selected_project.demo_mode
        ? selectedProject.selected_project.demo_mode
        : false;

      locationIDSet(selectedProject.selected_project.id);
      if (selectedProject.selected_project.image) {
        imgBgStyleSet({
          backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
            'photo',
            selectedProject.selected_project.image
          )}")`,
        });
      }
      updateingStatusSet(true);
    } else {
      // projectImgSet();
      formValues.location_description = '';
      formValues.location_name = '';
      locationIDSet('');
      imgBgStyleSet({
        backgroundImage:
          "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
      });
      updateingStatusSet(false);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (template_seriel) dispatch(set_backup_serial({serial: template_seriel}));
  }, [template_seriel]);

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
  function urlToFile(url, filename, mimeType) {
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], filename, {type: mimeType}));
  }
  const onFileChange = async (base64img, file) => {
    console.log(file);
    // setUploading(true);
    try {
      // const blob: any = await fetch(base64img).then((res) => res.blob());
      // blob.name = file.name;
      // blob.lastModified = file.lastModified;
      // console.log(blob);

      // const convertedFile = dataURLtoFile(base64img, file.name);
      const convertedFile = await urlToFile(base64img, file.name, file.type);
      if (convertedFile.size > 3.8 * 1024 * 1024) {
        throw new Error(
          `Our system cannot process the selected image. Please try a different one. (File too large ${Math.round(
            convertedFile.size / (1024 * 1024)
          )} MB)`
        );
      }
      //  console.log('file size', convertedFile.size, convertedFile.size / (1024 * 1024));
      // setProjectImgFile(convertedFile);
      projectImgSet(convertedFile);
      // const file = event.target.files[0];
      // if (!file) {
      //   throw "Please select valid image file";
      // }
      // projectImgSet(file);
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {
        key: 'profile-file-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };

  const deleteLocation = async () => {
    setLoading(true);
    try {
      if (locationID) {
        appConsoleLog(locationID);
        await deleteProjectGraphQL(userProfile?.apollo_client, locationID)
          .then(async res => {
            appConsoleLog('syc', res);
            snackbar.enqueueSnackbar(APP_TEXT.location.LOCATION_DELETE_SUCCESS, {
              key: 'login-error',
              ...SuccessSnackbarOptions,
              action: SuccessSnackbarActions(snackbar.closeSnackbar),
            });
            // navigateRoute("/");
            window.location.href = '/';
          })
          .catch(() => {
            snackbar.enqueueSnackbar(APP_TEXT.location.LOCATION_DELETE_ERROR, {
              key: 'login-error',
              ...ErrorSnackbarOptions,
              action: ErrorSnackbarActions(snackbar.closeSnackbar),
            });
          });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(APP_TEXT.location.LOCATION_DELETE_ERROR, {
        key: 'delete-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const backup_payload = useSelector((state: RootState) => state.backup);

  const onSubmitForm = async (values: ILoginFormValue, data: any) => {
    const extra = {
      project_contact_no1: phone_no,
      // eslint-disable-next-line dot-notation
      project_timezone: time_zone && time_zone['value'],
      project_address: `${values.street}, ${values.suburb}, ${values.city}, ${values.postal_code}`,
      project_country: country_code_iso && country_code_iso.name,
      project_country_code: country_code_iso && country_code_iso.code,
      project_city: values.city,
      project_tz_info: JSON.stringify({
        time_zone,
        address: {
          street: values.street,
          suburb: values.suburb,
          postal_code: values.postal_code,
        },
        country_code_iso,
      }),
    };
    setLoading(true);
    try {
      if (isUpdating) {
        let imgUploadRes = '';
        if (projectImg) {
          const axiosInstace = axios.create({
            baseURL: process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0],
            headers: {
              Authorization: `Bearer ${user.access_token.token}`,
            },
          });
          const imgUpRes = await uploadProjectImageRest(
            axiosInstace,
            projectImg,
            locationID,
            '/rest/media/v1/image/upload'
          );
          if (imgUpRes) {
            imgUploadRes = imgUpRes;
            appConsoleLog(imgUpRes);
          }
        }

        const res = await updateProjectGraphQL(
          userProfile?.apollo_client,
          locationID,
          values.location_name.trim(),
          values.location_description.trim(),
          projectStatus,
          projectType,
          values.demo,
          extra
        );
        if (res && res.projectUpdate && res.projectUpdate.id) {
          await selectedProject.dispatch({
            selected_project: {
              id: res.projectUpdate.id,
              title: values.location_name.trim(),
              description: values.location_description.trim(),
              image: imgUploadRes.includes('.jpg')
                ? imgUploadRes
                : selectedProject.selected_project.image,
              project_status: selectedProject.selected_project.image,
              project_type: selectedProject.selected_project.image,
              access_level: selectedProject.selected_project.image,
              demo_mode: values.demo,
              restore_bsid: selectedProject.selected_project.restore_bsid,
            },
          });

          snackbar.enqueueSnackbar(APP_TEXT.location.LOCATION_UPDATE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          navigateRoute(`/app/project/dashboard?projectId=${locationID}`);
        }
      } else {
        if (!projectImg || projectImg === '') {
          snackbar.enqueueSnackbar('Project Image is Required', {
            variant: 'error',
          });
          setLoading(false);
          return;
        }
        const res = await createProjectGraphQL(
          userProfile?.apollo_client,
          values.location_name.trim(),
          values.location_description.trim(),
          projectStatus,
          projectType,
          values.demo,
          template_seriel,
          extra
        );
        if (res && res.projectCreate && res.projectCreate.id) {
          if (projectImg) {
            const axiosInstace = axios.create({
              baseURL: process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0],
              headers: {
                Authorization: `Bearer ${user.access_token.token}`,
              },
            });
            const imgUpRes = await uploadProjectImageRest(
              axiosInstace,
              projectImg,
              res.projectCreate.id,
              '/rest/media/v1/image/upload'
            );
            // const imgUpRes = await uploadProjectImageGraphQL(
            //   user.access_token.token,
            //   projectImg,
            //   res.projectCreate.id,
            // );
            if (imgUpRes) {
              appConsoleLog(imgUpRes);
            }
          }

          if (template_seriel && template_seriel.length > 0 && res && res.projectCreate) {
            await createSession(template_seriel, res.projectCreate.id);
          }

          snackbar.enqueueSnackbar(APP_TEXT.location.LOCATION_CREATE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
        }
        navigateRoute('/app/all-projects');
      }
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {
        key: 'login-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  async function createSession(serial: string, projectId: string) {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/session/create`,
        {
          backup_serial: serial,
          project_id: projectId,
          note: 'The restore session created when you initilized this project',
        },
        {
          headers: {
            Authorization: `Bearer ${user.access_token.token}`,
          },
        }
      );
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {variant: 'error'});
    }
  }

  const [open_delete_confirm, setOpenDeleteConfirm] = useState(false);

  const [time_zone, setTimeZone] = useState({});
  useEffect(() => {
    console.log(time_zone);
  }, [time_zone]);
  const [country_code_iso, setCountryCodeIso] = useState<{code: string; name: string}>(null);
  const [phone_no, setPhonNumber] = useState(null);

  const getProjectByID = (project_id: string) => {
    projectGetGraphQL(userProfile?.apollo_client, project_id)
      .then(async response => {
        const res = response.projectGet.project;
        if (!res) return;
        // set extrafields
        const parsed_info =
          res.project_tz_info && typeof res.project_tz_info === 'string'
            ? JSON.parse(res.project_tz_info)
            : {};
        if (parsed_info) {
          setTimeZone(parsed_info.time_zone);
          setCountryCodeIso(parsed_info.country_code_iso);
          setPhonNumber(res.project_contact_no1);

          formValues.street = parsed_info.address.street;
          formValues.suburb = parsed_info.address.suburb;
          formValues.postal_code = parsed_info.address.postal_code;
        }

        formValues.city = res.project_city;

        await selectedProject.dispatch({
          selected_project: {
            id: res.id,
            title: res.name,
            description: res.description,
            image: res.image,
            project_status: res.project_status,
            project_type: res.project_type,
            access_level: res.access_level,
            demo_mode: res.demo_mode,
            restore_bsid: res.restore_bsid,
            project_contact_no1: res.project_contact_no1,
            project_timezone: res.project_timezone,
            project_tz_info: res.project_tz_info,
            project_address: res.project_address,
            project_country: res.project_country,
            project_country_code: res.project_country_code,
            project_city: res.project_city,
          },
        });
      })
      .catch(err => {
        appConsoleLog(err);
      });
  };

  const [enableMigrationBtn, setEnableMigrationBtn] = useState(false);
  const handleMigration = async () => {
    try {
      setEnableMigrationBtn(false);
      const res = migrateToV2(user.access_token.token, locationID);
      snackbar.enqueueSnackbar('Migration Request Sent. Processing in background...', {
        variant: 'success',
      });
    } catch (err) {
      snackbar.enqueueSnackbar('Migration Request Failed', {variant: 'error'});
    }
  };

  const logicReport = async () => {
    try {
      const client = ApolloFunctions.createAuthApolloClient(
        user.access_token.token,
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/graphqlv2`
      );
      const projectId = selectedProject.selected_project.id;
      await generateLogicReportV2(client, projectId);
      snackbar.enqueueSnackbar(
        'Report requested. Please check after few minutes to see the requested report',
        {variant: 'success'}
      );
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data);
      snackbar.enqueueSnackbar(msg, {variant: 'error'});
    }
  };

  const [lastReport, setLastReport] = useState<{
    project_id: string | null;
    report_link: string | null;
    report_type: string | null;
    status_note: string | null;
    updated_at: number | null;
  }>();

  useEffect(() => {
    async function init() {
      try {
        const client = ApolloFunctions.createAuthApolloClient(
          user.access_token.token,
          `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/graphqlv2`
        );
        const projectId = selectedProject.selected_project.id;
        const res = await getLocationReportV2(client, projectId);
        console.log('xcxcx', res);
        if (res.GetLocationReportInfo) {
          setLastReport(res.GetLocationReportInfo);
        }
      } catch (err: any) {
        console.log(err);
        const msg = parseCustomApiError(err.response && err.response.data);
        snackbar.enqueueSnackbar(msg, {variant: 'error'});
      }
    }
    // eslint-disable-next-line max-len
    if (
      selectedProject &&
      selectedProject.selected_project &&
      selectedProject.selected_project.id
    ) {
      init();
    }
  }, [selectedProject]);

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.stepRoot}>
          <Box className={classes.stepTab}>
            <Box className={classes.iconRoot}>
              {window.location.href.indexOf('/create-project') > -1 ? (
                <CheckCircleIcon />
              ) : (
                <FiberManualRecordIcon className={classes.circleIcon} />
              )}
            </Box>
            <Typography className={classes.stepTabTitle}>
              {APP_TEXT.location.LOCATION_TYPE}
            </Typography>
            <Box className={classes.stepTabLine} />
          </Box>
          <Box className={classes.stepTab}>
            <Box className={classes.iconRoot}>
              {window.location.href.indexOf('/create-custom-project') > -1 ? (
                <CheckCircleIcon />
              ) : (
                <FiberManualRecordIcon className={classes.circleIcon} />
              )}
            </Box>
            <Typography className={classes.stepTabTitle}>
              {APP_TEXT.location.LOCATION_DETAILS}
            </Typography>
            <Box className={classes.lastTabLine} />
          </Box>
        </Box>
      </PageHeader>
      <PageContentLayout className={classes.layoutRoot}>
        <Box
          style={{
            width: '90%',
            margin: '2% auto',
            background: 'white',
            padding: '2%',
            borderRadius: '10px',
          }}
        >
          <PageConentHeader>
            <Typography variant="h5" component="h2">
              {isUpdating
                ? APP_TEXT.location.UPDATE_LOCATION_DETAILS
                : APP_TEXT.location.ENTER_LOCATION_DETAILS}
            </Typography>
          </PageConentHeader>
          <PageContent className={classes.content}>
            <Formik
              onSubmit={onSubmitForm}
              initialValues={formValues}
              validationSchema={Yup.object().shape({
                location_name: Yup.string()
                  .required('Required')
                  .matches(/^[a-zA-Z0-9\s]+$/, 'Name cannot contains symbols'),
                location_description: Yup.string()
                  .required('Required')
                  .max(199, 'Must be less than 199 characters'),
              })}
              // onSubmit={onSubmitForm}
            >
              {({errors, handleBlur, handleChange, touched, values, submitForm}) => (
                <form>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      border: '1px solid #EBF1F2',
                      borderRadius: '5px',
                      padding: '2%',
                    }}
                  >
                    <Grid item md={12}>
                      <Typography variant="h6">Basic Information</Typography>
                    </Grid>

                    <Grid item md={6} sm={12}>
                      {formValues.location_name && formValues.location_name !== '' ? (
                        <div className="projectPhotom" style={{...imgBgStyle}} />
                      ) : (
                        <div />
                      )}

                      <ImgDragDrop onChange={onFileChange}>
                        <div className={styles.dropzone} />
                      </ImgDragDrop>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <div>
                        <TextField
                          className={styles.fieldSpace}
                          error={Boolean(touched.location_name && errors.location_name)}
                          helperText={touched.location_name && errors.location_name}
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            dispatch(
                              set_project_info({
                                project_name: e.target.value,
                              })
                            );
                          }}
                          value={values.location_name}
                          name="location_name"
                          label={APP_TEXT.location.FORM_FIELD_NAME}
                          size="medium"
                          type="text"
                          variant="outlined"
                          data-testid="email"
                        />
                      </div>
                      <div>
                        <TextField
                          rows={10}
                          maxRows={10}
                          multiline
                          className={styles.fieldSpace}
                          error={Boolean(
                            touched.location_description && errors.location_description
                          )}
                          helperText={touched.location_description && errors.location_description}
                          onBlur={handleBlur}
                          onChange={e => {
                            handleChange(e);
                            dispatch(
                              set_project_info({
                                project_description: e.target.value,
                              })
                            );
                          }}
                          value={values.location_description}
                          name="location_description"
                          label={APP_TEXT.location.FORM_FIELD_DESCRIPTION}
                          size="medium"
                          type="text"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" size="large">
                                  {/* {showPassword ? <Visibility /> : <VisibilityOff />} */}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          data-testid="password"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.demo}
                              onChange={e => {
                                dispatch(
                                  set_project_info({
                                    demo: !values.demo,
                                  })
                                );
                                handleChange(e);
                              }}
                              name="demo"
                              color="primary"
                            />
                          }
                          // eslint-disable-next-line no-useless-concat
                          label={values.demo ? 'Disable ' + 'Demo Mode' : 'Enable ' + 'Demo Mode'}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Box height={30} />
                  <Grid
                    container
                    spacing={2}
                    style={{
                      border: '1px solid #EBF1F2',
                      borderRadius: '5px',
                      padding: '2%',
                    }}
                  >
                    <Typography variant="h6">Additional Information</Typography>
                    <Grid item md={12}>
                      <Typography variant="subtitle1">Location Time Zone</Typography>
                      <TimezoneSelect
                        value={time_zone ? (time_zone as any) : {}}
                        onChange={e => {
                          console.log(e);
                          setTimeZone(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography variant="subtitle1">Location Contact Information</Typography>
                      <FormControl>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phone_no}
                          onChange={val => {
                            setPhonNumber(val);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={8}>
                      <FormControl style={{width: '100%'}}>
                        <FormHelperText>Street</FormHelperText>
                        <TextField
                          name="street"
                          variant="outlined"
                          placeholder="Street"
                          size="small"
                          onChange={e => handleChange(e)}
                          style={{width: '100%'}}
                          value={values.street}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl style={{width: '100%'}}>
                        <FormHelperText>Suburb</FormHelperText>
                        <TextField
                          name="suburb"
                          variant="outlined"
                          placeholder="Suburb"
                          size="small"
                          onChange={e => handleChange(e)}
                          style={{width: '100%'}}
                          value={values.suburb}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormHelperText>City</FormHelperText>
                      <TextField
                        name="city"
                        variant="outlined"
                        size="small"
                        onChange={e => handleChange(e)}
                        style={{width: '100%'}}
                        value={values.city}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormHelperText>Postal Code</FormHelperText>
                      <TextField
                        name="postal_code"
                        variant="outlined"
                        size="small"
                        onChange={e => handleChange(e)}
                        style={{width: '100%'}}
                        value={values.postal_code}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FormHelperText>Country</FormHelperText>
                      <Autocomplete
                        size="small"
                        onChange={(_, value) => {
                          // setZoneHandler(index, value ? value : null);
                          setCountryCodeIso(value as any);
                        }}
                        value={country_code_iso}
                        // className={classes.formControl}
                        options={CountryCodes}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={params => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="outlined"
                            type="text"
                            autoComplete="off"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box className={classes.bottom}>
                    <FillButton onClick={submitForm}>
                      {loading ? (
                        <LoadingComponent />
                      ) : isUpdating ? (
                        APP_TEXT.location.UPDATE
                      ) : (
                        APP_TEXT.location.CREATE
                      )}
                    </FillButton>
                    {/* {isUpdating && (
                      <>
                        <Box width={20}></Box>
                        <Button
                          onClick={deleteLocation}
                          style={{ color: "white", backgroundColor: "red" }}
                        >
                          {APP_TEXT.location.DELETE}
                        </Button>
                      </>
                    )} */}
                  </Box>
                </form>
              )}
            </Formik>
            <Grid
              container
              spacing={2}
              style={{
                border: '1px solid #EBF1F2',
                borderRadius: '5px',
                padding: '2%',
              }}
            >
              <Grid item md={12}>
                {isUpdating && <Re14Component projectId={selectedProject.selected_project.id} />}
              </Grid>
            </Grid>
            <Box height={30} />
            {isUpdating && (
              <fieldset
                style={{
                  padding: '2%',
                  borderRadius: '10px',
                  border: '1px solid #EBF1F2',
                  marginBottom: '5%',
                }}
              >
                <legend>
                  <Typography variant="h6">Reports</Typography>
                </legend>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle2">Location Report</Typography>
                      {!lastReport || !lastReport.updated_at ? (
                        <div>No Report Found</div>
                      ) : (
                        <div>
                          <Typography>Last Report Info</Typography>
                          <div>
                            <ul>
                              <li>
                                Generated Time:{' '}
                                {moment.unix(lastReport.updated_at).format('MM-DD-YYYY HH:mm:ss')}
                              </li>
                              <li>
                                Status -{' '}
                                <Chip
                                  label={lastReport.status_note}
                                  size="small"
                                  color={lastReport.status_note === 'DONE' ? 'success' : 'warning'}
                                />
                              </li>
                              <li>
                                <a
                                  style={{color: 'blue'}}
                                  href={lastReport.report_link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View Report
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <Button variant="outlined" onClick={logicReport}>
                        Request New Report
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </fieldset>
            )}
            {isUpdating && (
              <fieldset
                style={{
                  padding: '2%',
                  borderRadius: '10px',
                  border: '1px solid red',
                  marginBottom: '5%',
                }}
              >
                <legend>
                  <Typography variant="h6">Danger Zone</Typography>
                </legend>
                <Grid container spacing={2}>
                  {/* <Grid
                    item
                    md={12}
                    style={{ borderBottom: "1px solid #EBF1F2" }}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={11}>
                        <Typography variant="subtitle2">
                          Manual Delete
                        </Typography>
                        <Typography variant="subtitle1">
                          Delete this location after you manually delete all the
                          userviews, containers and logics
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={deleteLocation}
                          style={{ color: "white", backgroundColor: "red" }}
                        >
                          {APP_TEXT.location.DELETE}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item md={12} style={{marginBottom: '90px'}}>
                    <CustomDelete />
                  </Grid>

                  <Grid item md={12} style={{borderTop: '1px solid #EBF1F2', marginBottom: '50px'}}>
                    <Grid container spacing={2}>
                      <Grid item md={9}>
                        <Typography variant="subtitle2">Wipe Everything</Typography>
                        <Typography variant="subtitle1">
                          Delete the project and its all contents permenently
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          onClick={() => setOpenDeleteConfirm(true)}
                          style={{color: 'white', backgroundColor: 'red'}}
                        >
                          {APP_TEXT.location.WIPE}
                        </Button>
                      </Grid>
                    </Grid>
                    <ConfirmLocDelete
                      open={open_delete_confirm}
                      setOpen={setOpenDeleteConfirm}
                      loc_name={selectedProject.selected_project.title}
                      loc_id={selectedProject.selected_project.id}
                    />
                  </Grid>
                </Grid>
                <hr />
                <Box>
                  <Typography variant="subtitle2">Migration From v1 to v2</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enableMigrationBtn}
                        onChange={e => setEnableMigrationBtn(e.target.checked)}
                      />
                    }
                    label={enableMigrationBtn ? 'Lock Button' : 'Unlock Button'}
                  />
                  <Box>
                    <Alert severity="warning">
                      Migration process will start as soon as the button is clicked.
                    </Alert>
                    <Box height={10} />
                    <Button
                      disabled={!enableMigrationBtn}
                      variant="contained"
                      onClick={handleMigration}
                    >
                      Start Migration
                    </Button>
                  </Box>
                </Box>
              </fieldset>
            )}
            {isUpdating && <LogicRefresh />}
            <Box height={30} />
          </PageContent>
        </Box>
      </PageContentLayout>
    </Layout>
  );
};

export default CreateEditCustomProjectComponent;
