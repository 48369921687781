import React, { FunctionComponent } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './Loading.module.css';

type ComponentProps = {
  size?: number;
  color?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const LoadingComponent: FunctionComponent<ComponentProps> = ({
  size = 30,
  color = '#ffffff',
}) => (
  <div className={styles.root}>
    <CircularProgress
      variant="indeterminate"
      style={{
        color,
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  </div>
);

LoadingComponent.defaultProps = {
  size: 50,
  color: 'white',
};
