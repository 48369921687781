/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ClearIcon from '@mui/icons-material/Clear';
import {AirDevice, ProDevice} from '@smartlife-redux-state/common';
import {Group} from 'src/containers/app/LogicsEngine/components/board/Group';
import {RootState, useSelector} from 'src/store/redux/store';
import {useContext, useEffect, useState} from 'react';
import {
  AirAvaibleDevice,
  DeviceFromRestoreContainer,
  ProDeviceFromRestoreContainer,
  updateConfigurableContainer,
} from 'src/store/redux/features/configurable-containers';
import {useDispatch} from 'react-redux';
import {DeviceConfigContext} from './context/deviceConfigContext';
import useFigureChannelSet from './hooks/useFigureChannelSet';
import {CiDevice} from 'src/plugins/shared/services/cloud-integration.service';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5%',
    width: '100%',
    border: '1px solid #D9DFE0',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  top: {
    marginBottom: '5px',
    minHeight: '50px',
  },
  topLeft: {
    // border: '0.5px solid gray',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  topMiddle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    color: '#048696',
    fontWeight: 500,
  },
  subtitle: {
    fontSize: '12px',
  },
  icon: {
    fontSize: '10px',
    '&::before': {
      fontSize: '20px',
      color: '#048696',
    },
  },
  proConfigCont: {
    display: 'flex',
    flexDirection: 'column',
  },
  proConfigInnerCont: {
    display: 'flex',
    marginTop: '5%',
  },
});

const AirDeviceConfig: React.FC<{
  device: AirDevice;
  configurableContainerId: string;
  devicePos: 'toExisting' | 'toAvailable';
  // isExistingDevice: boolean;
}> = ({device, configurableContainerId, devicePos}) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const [showCustom, setShowCustom] = useState(false);
  const [settings, setSettings] = useState<any>(null);
  const [noExistingChannel, setNonExisting] = useState(false);

  const configurableContainer = useSelector(
    (state: RootState) => state.configurable_containers[configurableContainerId]
  );
  const droppedDevice = configurableContainer.deviceMap[devicePos]
    ? configurableContainer.deviceMap[devicePos][device.id]
    : null;
  const selectedChannels = configurableContainer.deviceMap[devicePos]
    ? configurableContainer.deviceMap[devicePos][device.id]?.mappings.channel
    : {};

  const resx = useFigureChannelSet(
    device,
    droppedDevice?.device,
    devicePos,
    configurableContainerId
  );

  useEffect(() => {
    console.log('update trigged');
    setNonExisting(false);
    setShowCustom(false);
    if (device.airdc_id) {
      const parsedSettings = parseSettings(device.settings);
      setSettings(parsedSettings);
      console.log('XXXXXXXXXXXXXX', parsedSettings);
      if (selectedChannels) {
        setNonExisting(false);
      } else {
        setNonExisting(true);
      }
    }
  }, [device]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function parseSettings(settings: any) {
    if (typeof settings === 'string' && settings.length > 0) {
      const res = JSON.parse(settings);
      if (typeof res === 'string') {
        return parseSettings(res);
      }
      return res;
    }
    return settings;
  }

  function onPinSelect(value: any, oldChannelKey: string) {
    console.log(value);
    let payload = null;

    if (devicePos === 'toExisting') {
      payload = {
        deviceMap: {
          toExisting: {
            ...configurableContainer.deviceMap.toExisting,
            [device.id]: {
              device: droppedDevice.device,
              mappings: {
                pin: null,
                channel: {
                  ...droppedDevice.mappings.channel,
                  [oldChannelKey]: value,
                },
              },
            },
          },
          asNew: configurableContainer.deviceMap.asNew,
        },
      };
    } else {
      payload = {
        deviceMap: {
          toExisting: configurableContainer.deviceMap.toExisting,
          asNew: {
            ...configurableContainer.deviceMap.asNew,
            [device.id]: {
              device,
              mappings: {
                pin: null,
                channel: {
                  ...droppedDevice.mappings.channel,
                  [oldChannelKey]: value,
                },
              },
            },
          },
        },
      };
    }
    dispatch(
      updateConfigurableContainer({
        item: {...payload},
        key: configurableContainerId,
        flag: 'UPDATE',
      })
    );

    setNonExisting(false);
  }

  return (
    <Box className={styles.proConfigCont}>
      {((devicePos === 'toExisting' && droppedDevice) ||
        (devicePos === 'toAvailable' && device)) && (
        <>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={showCustom} onChange={e => setShowCustom(!showCustom)} />}
              label="Show Config"
            />
          </Box>
          {noExistingChannel && <Alert severity="error">Invalid Channels. Please Choose.</Alert>}
          {showCustom && (
            <Box className={styles.proConfigInnerCont}>
              {devicePos === 'toExisting' && settings.selected_functions && (
                <Box>
                  <Typography>Channel Values</Typography>
                  <Box height={10} />
                  <Grid container spacing={2}>
                    {Object.keys(settings.selected_functions).map(channelKey => (
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={6}>
                            <TextField
                              value={channelKey}
                              variant="outlined"
                              size="small"
                              disabled
                            />
                          </Grid>
                          <Grid item md={6}>
                            {droppedDevice && device && settings && (
                              <FormControl style={{width: '100%'}}>
                                <Select
                                  value={selectedChannels ? selectedChannels[channelKey] : ''}
                                  size="small"
                                  onChange={e => onPinSelect(e.target.value, channelKey)}
                                >
                                  {Object.keys(
                                    droppedDevice.device.settings.selected_functions
                                  ).map(key => (
                                    <MenuItem value={key}>{key}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText style={{color: 'red'}}>
                                  {selectedChannels &&
                                    !selectedChannels[channelKey] &&
                                    'invalid channel'}
                                </FormHelperText>
                              </FormControl>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const ProDeviceConfig: React.FC<{
  device: DeviceFromRestoreContainer;
  relayPins: any;
  configurableContainerId: string;
  isExistingDevice: boolean;
}> = ({device, relayPins, configurableContainerId, isExistingDevice}) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const [showCustom, setShowCustom] = useState(false);
  const [settings, setSettings] = useState<any>(null);
  const [noExistingPin, setNonExistingPin] = useState(false);

  const configurableContainer = useSelector(
    (state: RootState) => state.configurable_containers[configurableContainerId]
  );
  const droppedDevice = configurableContainer.deviceMap.toExisting[device.id];
  const selectedPin =
    configurableContainer.deviceMap[isExistingDevice ? 'toExisting' : 'asNew'][device.id]?.mappings
      .pin;

  useEffect(() => {
    setNonExistingPin(false);
    setShowCustom(false);
    if (device.cat === 'gpio_items') {
      const parsedSettings = parseSettings(device.settings);
      setSettings(parsedSettings);

      if (selectedPin) {
        setNonExistingPin(false);
      } else {
        const found = relayPins.find(relayPin => relayPin.pin === parsedSettings.pin);
        if (!found) {
          setNonExistingPin(true);
          setShowCustom(true);
        }
      }
    }
  }, [device]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function parseSettings(settings: any) {
    if (typeof settings === 'string' && settings.length > 0) {
      const res = JSON.parse(settings);
      return res;
    }
    return settings;
  }

  function onPinSelect(value: any) {
    console.log(value);
    let payload = null;

    if (isExistingDevice) {
      payload = {
        deviceMap: {
          toExisting: {
            ...configurableContainer.deviceMap.toExisting,
            [device.id]: {
              device: droppedDevice.device,
              mappings: {
                pin: value,
                channel: null,
              },
            },
          },
          asNew: configurableContainer.deviceMap.asNew,
        },
      };
    } else {
      payload = {
        deviceMap: {
          toExisting: configurableContainer.deviceMap.toExisting,
          asNew: {
            ...configurableContainer.deviceMap.asNew,
            [device.id]: {
              device,
              mappings: {
                pin: value,
                channel: null,
              },
            },
          },
        },
      };
    }
    dispatch(
      updateConfigurableContainer({
        item: {...payload},
        key: configurableContainerId,
        flag: 'UPDATE',
      })
    );

    setNonExistingPin(false);
  }

  return (
    <Box className={styles.proConfigCont}>
      {((isExistingDevice && droppedDevice) || (!isExistingDevice && device)) && (
        <>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={showCustom} onChange={e => setShowCustom(!showCustom)} />}
              label="Show Advance Configuration"
            />
          </Box>
          {noExistingPin && <Alert severity="error">Invalid Pin. Please Select a One</Alert>}
          {showCustom && (
            <Box className={styles.proConfigInnerCont}>
              {device.cat === 'gpio_items' ? (
                <>
                  <Typography>Pin Value</Typography>
                  <FormControl style={{width: '100%'}}>
                    <Select
                      value={selectedPin}
                      size="small"
                      onChange={e => onPinSelect(e.target.value)}
                    >
                      {relayPins.map(relayPin => (
                        <MenuItem value={relayPin.pin} disabled={relayPin.item_id}>
                          {relayPin.display_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <Box>No Configurations</Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const DeviceInfo: React.FC<{
  device: DeviceFromRestoreContainer;
  deviceConfig?: {icon: string; title: string};
  configurableDeviceContainerId: string;
  isExistingDevice?: boolean;
  isavailableDevice?: boolean;
  relayPins: any;
  onPullBack: (
    device: DeviceFromRestoreContainer,
    isExistingDevice?: boolean,
    isavailableDevice?: boolean
  ) => void;
}> = ({
  device,
  deviceConfig,
  configurableDeviceContainerId,
  isExistingDevice,
  relayPins,
  isavailableDevice,
  onPullBack,
}) => {
  const classes = useStyle();
  const deviceInfo = useContext(DeviceConfigContext);
  const {
    deviceMap: {toExisting, toAvailable},
  } = useSelector(
    (state: RootState) => state.configurable_containers[configurableDeviceContainerId]
  );

  const closeTab = () => {
    deviceInfo.dispatch({
      type: 'UPDATE_PANEL',
      payload: {
        selectedDevice: null,
        showPanel: false,
      },
    });
  };

  return (
    <Box className={classes.root}>
      <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
        <IconButton onClick={closeTab}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box height={5} />
      {(isExistingDevice || isavailableDevice) && (
        <Box className={classes.top}>
          <Grid container spacing={1}>
            <Grid item md={5} className={classes.topLeft}>
              <Paper
                style={{
                  padding: 10,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <span
                    style={{fontSize: '10px !important'}}
                    className={deviceConfig ? `calc ${deviceConfig.icon} ${classes.icon}` : ''}
                  />
                </div>
                <div className={classes.name}>{device ? device.name : ''}</div>
              </Paper>
            </Grid>
            <Grid item md={2} className={classes.topMiddle}>
              <SwapHorizIcon />
            </Grid>
            <Grid item md={5} className={classes.topRight}>
              <Group
                droppableId={
                  isavailableDevice
                    ? `configAvailableDevice${device.id}_${(device as any).configContainerId}`
                    : `configExistingDevice${device.id}`
                }
                droppableType={
                  (device as CiDevice).cint_device_id
                    ? 'cintd'
                    : device.cat === 'location_variables'
                    ? 'pro_var'
                    : (device as AirDevice).airdc_id || isavailableDevice
                    ? 'air'
                    : 'pro'
                }
                isDropDisabled={isavailableDevice ? false : !!toExisting[device.id]}
                style={isDraggingOver => ({
                  background: isDraggingOver ? 'lightblue' : '#D9DFE0',
                  padding: '2%',
                  width: '100%',
                  height: '90px',
                  borderRadius: '3px',
                  border: '1px dotted gray',
                  minHeight: '100%',
                  display: 'flex',
                })}
              >
                {() => (
                  <>
                    {(isavailableDevice
                      ? toAvailable && toAvailable[device.id]
                      : toExisting[device.id]) && (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          textAlign: 'center',
                        }}
                      >
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <span
                            style={{fontSize: '10px !important'}}
                            className={
                              deviceConfig ? `calc ${deviceConfig.icon} ${classes.icon}` : ''
                            }
                          />
                        </div>
                        <div className={classes.name}>
                          <Tooltip title="Send back to the template container">
                            <IconButton
                              onClick={() =>
                                onPullBack(device, isExistingDevice, isavailableDevice)
                              }
                            >
                              <MoveUpIcon />
                            </IconButton>
                          </Tooltip>
                          {isavailableDevice
                            ? toAvailable[device.id].device.name
                            : toExisting[device.id]
                            ? toExisting[device.id].device.name
                            : ''}
                        </div>
                      </Box>
                    )}
                  </>
                )}
              </Group>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box>
        <Typography className={classes.name}>{device.name}</Typography>
        <Typography className={classes.subtitle}>
          {device.cat} | {device.sub_cat}
        </Typography>
      </Box>
      <Box style={{borderTop: '1px solid gray'}}>
        {device && (device as ProDevice).prodc_id && (
          <ProDeviceConfig
            device={device}
            relayPins={relayPins}
            configurableContainerId={configurableDeviceContainerId}
            isExistingDevice={isExistingDevice}
          />
        )}
        {device && (device as AirDevice).airdc_id && (
          <AirDeviceConfig
            device={device}
            configurableContainerId={configurableDeviceContainerId}
            devicePos={isExistingDevice ? 'toExisting' : 'toAvailable'}
            // isExistingDevice={isExistingDevice}
          />
        )}
        {device && (device as unknown as AirAvaibleDevice).product_id && (
          <AirDeviceConfig
            device={device}
            configurableContainerId={configurableDeviceContainerId}
            devicePos="toAvailable"
            // isExistingDevice={isExistingDevice}
          />
        )}
      </Box>
    </Box>
  );
};

export default DeviceInfo;
