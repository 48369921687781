/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { Suspense, lazy, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { Navigate } from 'react-router';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { RootState } from 'src/store/redux/store';

const HubStatsTables = lazy(() => import('./Stats'));

export const HubStats = () => {
  const user = useContext(AuthContext);
  useFirebaseConnect(`system_admins/${user.uid}`);

  const isSystemAdmin = useSelector(
    (store: RootState) =>
      !!(store.firebase.data.system_admins && store.firebase.data.system_admins[user.uid]),
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isSystemAdmin ? <HubStatsTables /> : <Navigate to="/app" />}
    </Suspense>
  );
};
