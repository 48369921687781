/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Reusable option component styled according to the project theme
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a reusable option component that can use to select things
 * @author Dinuka Bandara
 * @since -
 */
import React from 'react';
import PropsTypes from 'prop-types';
import styles from './CustomOptionSelector.module.css';
import notSelectedIcon from '../../../assets/images/icons/checkmark-not-selected.png';
import selectedIcon from '../../../assets/images/icons/checkmark-selected.png';
import selectedDisabledIcon from '../../../assets/images/icons/checkmark-selected-disabled.png';
import { appConsoleLog } from '../../../utility/appUtils';

type ComponentProps = {
  title: string;
  selected: boolean;
  onClick: any;
  disabled?: boolean;
  selectedDisabled?: boolean;
};

/**
 * Custom Option Selector
 *
 * @callback onRadioToggleCB
 * @param {Object} props                    component props
 * @param {String} props.title              Text to display inside the option component
 * @param {Boolean} props.selected          Flag that use to determine if the option is
 * selected or not
 * @param {onRadioToggleCB} props.onClick   Fires when user click the option button
 * @param {Boolean} props.disabled  Flag that determine if the button is clickable or not
 *
 * @returns JSX.Element
 */
const CustomOptionSelector: React.FunctionComponent<ComponentProps> = ({
  title,
  selected,
  onClick,
  disabled,
  selectedDisabled,
}) => {
  appConsoleLog(title, selected, disabled, selectedDisabled);
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === '13') {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
      onClick={disabled ? null : onClick}
      className={
        // eslint-disable-next-line no-nested-ternary
        disabled
          ? styles.projectTyleSelectorDisabled
          : selected
            ? styles.projectTyleSelectorSelected
            : styles.projectTyleSelectorNone
      }
    >
      <div className={styles.root}>
        <div className={styles.text}>
          <h4 className="nopadding">{title}</h4>
        </div>
        <div className={styles.image}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedDisabled ? (
            <img alt={title} src={selectedDisabledIcon} height="25px" />
          ) : selected ? (
            <img alt={title} src={selectedIcon} height="25px" />
          ) : (
            <img alt={title} src={notSelectedIcon} height="25px" />
          )}
        </div>
      </div>
    </div>
  );
};

CustomOptionSelector.propTypes = {
  title: PropsTypes.string.isRequired,
  selected: PropsTypes.bool.isRequired,
  onClick: PropsTypes.func.isRequired,
  disabled: PropsTypes.bool,
  selectedDisabled: PropsTypes.bool,
};

CustomOptionSelector.defaultProps = {
  disabled: false,
  selectedDisabled: false,
};

export default CustomOptionSelector;
