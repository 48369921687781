/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
import {
  Box, Grid, Skeleton, Typography,
} from '@mui/material';
import axios from 'axios';
import { cloneDeep, isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Board } from 'src/containers/app/LogicsEngine/components/board';
import { Card } from 'src/containers/app/LogicsEngine/components/board/Card';
import { Group } from 'src/containers/app/LogicsEngine/components/board/Group';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { v2InfoUpdate, v2PullDroppedItem } from 'src/store/redux/features/backup-location-config';
import { Comps, PendingComponentData } from 'src/store/redux/features/backup-location-config/v2types';
import {
  DEVICE_POSITIONS,
  removeDevice,
  updateConfigurableContainer,
} from 'src/store/redux/features/configurable-containers';
import { RootState, useSelector } from 'src/store/redux/store';
import useQueryString from 'src/utility/CustomHooks/useQueryString';
import ConfigurableDeviceContainer from '../ConfigurableDeviceContainer';
import Device, { DEVICE_STATUS_IN_RESTORE_STEP } from '../shared/Device';
import DeviceContainer, { DEVICE_CONTAINER_LOC } from '../shared/DeviceContainer';
import useHideSelectedDevices from '../shared/hooks/useHideSelectedDevices';
import useMoveDevices from '../shared/hooks/useMoveDevices';
import MoveDeviceDialog from '../shared/MoveDeviceDialog';
import { DeviceCollectionSearchOutItem } from '@smartlife-redux-state/common';
import { useRestoreType } from '../shared/hooks/useRestoreType';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { RestoreDone } from '../shared/RestoreDoneScreen';

export const SessionRestoreProDevices = forwardRef((props, ref) => {
  const sessionId = useQueryStringFirst('sessionId');

  const {
    checkedDevicesState,
    allSelectedState,
    hiddenItems,
    showCheckBoxState,
    configurableContainerRef,
    configurableList,
    devicePendingComp,
    isStepCompleted,
  } = useRestoreType('prodc', sessionId);
  const proDevicePendingComp = devicePendingComp;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  
  const {checkedDevices, setCheckDevices} = checkedDevicesState
  const {allSelected, setAllSelected} = allSelectedState;

  const [_, groupCheckedDevicesByContId, showHiddens, setShowHiddens, forceHide] = useHideSelectedDevices(proDevicePendingComp, checkedDevices, setCheckDevices);

  const { configInited, moveDevices } = useMoveDevices();
  const [openMoveDialog, setOpenMoveDialog] = useState<string>(null);

  const {showCheckBox, setShowCheckBox} = showCheckBoxState;

  useImperativeHandle(ref, () => ({
    getConfigurableContainerRef() {
      return configurableContainerRef;
    },
  }));

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    try {
      console.log(result);
      const { destination } = result;
      if (!destination) return;

      const { droppableId } = destination;

      // dropped onto a container. so it is a dropped as a new device
      if (droppableId.indexOf('configContainer') > -1) {
        const configurableContainerId = droppableId.split('configContainer');
        const container = configurableList[configurableContainerId[1]];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_pro_device': {
            const deviceRoot = proDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = { ...deviceRoot.data, id: droppedDedviceId };
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  toExisting: container.deviceMap.toExisting,
                  asNew: {
                    ...container.deviceMap.asNew,
                    [droppedDedviceId]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            }),
          );

          dispatch(
            v2InfoUpdate({
              value: {
                ...proDevicePendingComp,
                pendingComps: {
                  ...proDevicePendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...proDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...proDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: null,
                      },
                    },
                  },
                },
              },
              key: 'prodc',
              mergeErrorComps: false,
            }),
          );
        }
      }
      // dropped on to the existng item. So update existing item list
      if (droppableId.indexOf('configExistingDevice') > -1) {
        const existingDeviceId = droppableId.split('configExistingDevice')[1];
        const configurableContainerId = existingDeviceId.split('_')[1];
        const container = configurableList[configurableContainerId];

        const [flag, droppedDedviceId] = result.draggableId.split('___');

        let device = null;
        switch (flag) {
          case 'template_pro_device': {
            const deviceRoot = proDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][droppedDedviceId];
            if (deviceRoot) device = { ...deviceRoot.data, id: droppedDedviceId };
            break;
          }
          default:
            return;
        }

        if (device) {
          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  asNew: container.deviceMap.asNew,
                  toExisting: {
                    ...container.deviceMap.toExisting,
                    [existingDeviceId]: {
                      device,
                      mappings: {
                        pin: null,
                        channel: null,
                      },
                    },
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            }),
          );
          console.log({
            dropped_contatainer_id: container.dc.id,
            dropped_on: existingDeviceId,
          });
          dispatch(
            v2InfoUpdate({
              value: {
                ...proDevicePendingComp,
                pendingComps: {
                  ...proDevicePendingComp.pendingComps,
                  [droppedDedviceId.split('_')[1]]: {
                    ...proDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]],
                    [droppedDedviceId]: {
                      ...proDevicePendingComp.pendingComps[droppedDedviceId.split('_')[1]][
                        droppedDedviceId
                      ],
                      clientSideMeta: {
                        dropped_contatainer_id: container.dc.id,
                        dropped_on: existingDeviceId,
                      },
                    },
                  },
                },
              },
              key: 'prodc',
              mergeErrorComps: false,
            }),
          );
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  }

  const catogorizeDevices = (pendincDevices: {[deviceId: string]: PendingComponentData}) => {
    const categories: {
      [cat: string]: {
        [deviceId: string]: PendingComponentData;
      };
    } = {};
    Object.keys(pendincDevices).map((deviceId) => {
      const device = pendincDevices[deviceId];
      if (!categories[device.data.cat]) {
        categories[device.data.cat] = {
          [device.old_id]: device,
        };
      } else {
        categories[device.data.cat] = {
          ...categories[device.data.cat],
          [device.old_id]: device,
        };
      }
      return categories;
    });

    return categories;
  };

  if (isStepCompleted) {
    return <RestoreDone />;
  }
  
  if (!proDevicePendingComp) {
    return (
      <Grid container spacing={1}>
        <Grid item md={6}>
          <Skeleton sx={{ bgcolor: '#D9DFE0' }} variant="rectangular" width="100%" height={200} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Board onDragEnd={onDragEnd}>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {proDevicePendingComp && proDevicePendingComp.pendingComps && (
            <>
              {proDevicePendingComp.pendingComps
                // eslint-disable-next-line max-len
                && Object.keys(proDevicePendingComp.pendingComps).map((contId) => {
                  const container = proDevicePendingComp.pendingComps[contId];
                  const containerInfo = proDevicePendingComp.collections[contId];

                  // const categorized = catogorizeDevices(container);
                  return (
                    <>
                      <DeviceContainer
                        title={containerInfo ? containerInfo.name : 'Template Connect Container'}
                        position={DEVICE_CONTAINER_LOC.IN_TEMPLATE}
                        checkedDevices={checkedDevices[contId] ? checkedDevices[contId] : {}}
                        key={contId}
                        dcId={contId}
                        allSelected={allSelected && allSelected[contId]}
                        onToggleAllSelect={(checked) => setAllSelected((state) => ({
                          // ...state,
                          [contId]: checked,
                        }))}
                        hideSelectedDevices={groupCheckedDevicesByContId}
                        hasHiddenItems={
                          hiddenItems
                          && hiddenItems[contId]
                          && Object.keys(hiddenItems[contId]).length > 0
                        }
                        showHiddenItems={showHiddens}
                        onToggleShowHiddenItems={setShowHiddens}
                        onMove={() => {
                          setOpenMoveDialog(contId);
                        }}
                        type="prodc"
                      >
                        {({ searchText, devices }) => (
                          <Group
                            droppableId={`template_pro_dc_container_${contId}`}
                            droppableType="pro"
                            isDropDisabled
                          >
                            {() => (
                              <Grid container spacing={1}>
                                {Object.keys(devices).map((key) => {
                                  const proDevice = devices[key];
                                  
                                  if (searchText && searchText.length > 0) {
                                    if (!(proDevice.data.name.toLowerCase().indexOf(searchText) > -1)) {
                                      return <></>;
                                    }
                                  }

                                  if (
                                    !showHiddens
                                      && hiddenItems
                                      && hiddenItems[contId]
                                      && hiddenItems[contId][key]
                                  ) {
                                    return <></>;
                                  }

                                  return (
                                    <Grid item md={3} key={proDevice.old_id}>
                                      <Card
                                        draggableId={`template_pro_device___${proDevice.old_id}`}
                                        index={1}
                                        dragDisabled={
                                            !!(
                                              proDevice.clientSideMeta
                                              && proDevice.clientSideMeta.dropped_contatainer_id
                                            )
                                          }
                                      >
                                        {({ provided }) => (
                                          <Box>
                                            <Device
                                              deviceStatus={
                                                  DEVICE_STATUS_IN_RESTORE_STEP.IN_TEMPLATE_DEVICE
                                                }
                                              isHidden={
                                                  hiddenItems
                                                  && hiddenItems[contId]
                                                  && hiddenItems[contId][key]
                                                }
                                              device={proDevice.data as any}
                                              dragHandlerProps={{ ...provided.dragHandleProps }}
                                              hasError={proDevice.clone_status === 'e'}
                                              dragDisabled={
                                                  !!(
                                                    proDevice.clientSideMeta
                                                    && proDevice.clientSideMeta.dropped_contatainer_id
                                                  )
                                                }
                                              bgColor={
                                                  proDevice.clientSideMeta
                                                  && proDevice.clientSideMeta.dropped_contatainer_id
                                                    ? '#d6d6d6'
                                                    : null
                                                }
                                              showCheckBox
                                              checked={
                                                  checkedDevices[contId]
                                                  && checkedDevices[contId][proDevice.old_id]
                                                }
                                              onLongPress={() => setShowCheckBox(!showCheckBox)}
                                              onClickUnHide={() => {
                                                console.log(hiddenItems);
                                                if (
                                                  hiddenItems
                                                    && Object.keys(hiddenItems).length > 0
                                                ) {
                                                  const cpy = cloneDeep(hiddenItems);
                                                  delete cpy[contId][proDevice.old_id];
                                                  forceHide(cpy);
                                                }
                                                // setCheckDevices({ ...cpy });
                                              }}
                                              handleCheckChange={(checked: boolean) => {
                                                // if manually clicked, reset allCheck,
                                                setAllSelected(null);
                                                if (!checked) {
                                                  const cpy = cloneDeep(checkedDevices);
                                                  delete cpy[contId][proDevice.old_id];

                                                  setCheckDevices({ ...cpy });
                                                } else {
                                                  setCheckDevices({
                                                    ...checkedDevices,
                                                    [contId]: {
                                                      ...checkedDevices[contId],
                                                      [proDevice.old_id]: checked,
                                                    },
                                                  });
                                                }
                                              }}
                                                // eslint-disable-next-line consistent-return
                                              handleOnPullClick={() => {
                                                if (!proDevice.clientSideMeta) return null;

                                                if (proDevice.clientSideMeta.dropped_on) {
                                                  dispatch(
                                                    removeDevice({
                                                      type: DEVICE_POSITIONS.TO_EXISTING,
                                                      configurableDcId:
                                                          proDevice.clientSideMeta
                                                            .dropped_contatainer_id,
                                                      key: proDevice.clientSideMeta.dropped_on,
                                                    }),
                                                  );
                                                } else {
                                                  dispatch(
                                                    removeDevice({
                                                      type: DEVICE_POSITIONS.AS_NEW,
                                                      configurableDcId:
                                                          proDevice.clientSideMeta
                                                            .dropped_contatainer_id,
                                                      key: proDevice.old_id,
                                                    }),
                                                  );
                                                }

                                                dispatch(
                                                  v2PullDroppedItem({
                                                    type: 'prodc',
                                                    dcId: contId,
                                                    deviceId: proDevice.old_id,
                                                  }),
                                                );
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Card>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                          </Group>
                        )}
                      </DeviceContainer>
                      <Box style={{ height: 10 }} />
                    </>
                  );
                })}
            </>
          )}
        </Grid>
        <Grid item md={6}>
          <ConfigurableDeviceContainer type="prodc" dcCat={1} ref={configurableContainerRef} />
        </Grid>
      </Grid>
      <MoveDeviceDialog
        open={openMoveDialog}
        onClose={(value, templateId) => {
          if (!value || !templateId) return;
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const preConfig = {
            [templateId]: value.id,
          };
          moveDevices(
            JSON.stringify(preConfig),
            configurableList,
            proDevicePendingComp,
            'prodc',
            checkedDevices[templateId],
          );
          setOpenMoveDialog(null);
          setCheckDevices({});
        }}
        // eslint-disable-next-line array-callback-return
        containers={
          configurableList
            // eslint-disable-next-line array-callback-return
            ? Object.keys(configurableList).map((key) => {
              const dc = configurableList[key];

              if ((dc.dc as DeviceCollectionSearchOutItem).dc_cat === 1 && (dc.dc as DeviceCollectionSearchOutItem).dc_type !== 9) {
                return dc.dc;
              }
            })
            : []
        }
      />
    </Board>
  );
});

export default SessionRestoreProDevices;
