import { useState } from 'react';
import DeviceProfileSelector from 'src/containers/app/AirDeviceCreateEdit/components/DeviceProfileSelector';

const UVAirGangSelectorProfile: React.FC<{
  deviceSettings?:any;
  selectedType: any;
  template: any;
  parentProfileId: string;
  parentCategory: string;
  parentSubCategory: string;
  currentProfileId: string;
  onChangeProfile?: (comProfile: any) => void;
}> = ({
  deviceSettings,
  selectedType,
  template,
  parentProfileId,
  parentCategory,
  parentSubCategory,
  currentProfileId,
  onChangeProfile,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <DeviceProfileSelector
        deviceSettings={deviceSettings}
        deviceType={selectedType}
        template={template}
        comType="uvi"
        parentType="air"
        parentProfileId={parentProfileId}
        parentCategory={parentCategory}
        parentSubCategory={parentSubCategory}
        currentProfileId={currentProfileId}
        onChangeProfile={onChangeProfile}
      />
    </>
  );
};

export default UVAirGangSelectorProfile;
