/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  Box, Checkbox, FormControlLabel, Grid,
} from '@mui/material';
import {
  Action,
  AirAction,
  AirDevice,
  DeviceCollectionSearchOutItem,
  LOGIC_CARD_VISIBILITY,
  OPERATORS,
  ProDevice,
} from '@smartlife-redux-state/common';
import { upperCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Condition from 'yup/lib/Condition';
import { ITEM_DEFAULTS } from '../../../../../../../config/deviceConfig/item_defaults';
import { update_action } from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState } from '../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../LogicStepper';
import { DeviceSelector } from '../shared/DeviceSelector';
import { ExpressionSelector } from '../shared/ExpressionSelector';
import AcControllerChildActions from './shared/AcControllerAction';
import AudioDistributerActions from './shared/AudioControllerActions';
import ExsAlarmChildChildActions from './shared/EsxAlarmControllerAction';
import RemoteActions from './shared/RemoteActions';

// eslint-disable-next-line import/prefer-default-export
export const DeviceAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild = false, parentAction }) => {
  const dispatch = useDispatch();
  const [device, setDevice] = useState<ProDevice | AirDevice>(null);
  const [remoteKeyMap, setRemoteKeyMap] = useState<any | null>(null);

  const dc = useSelector((state: RootState) => {
    if (isChild) {
      const found = parentAction.child_actions.find((child) => child.id === action.id);
      return found.dc;
    }
    const found = state.rule_engine.actions.find((act) => act.id === action.id);
    return found ? found.dc : null;
  });

  function onDcSelect(dcArg: DeviceCollectionSearchOutItem) {
    console.log('on dc select called');

    dispatch(
      update_action({
        action: {
          ...action,
          remote_run: false,
          dc: dcArg,
        },
        isChild,
        parentAction,
      }),
    );
  }

  function onDeviceSelect(deviceArg: AirDevice | ProDevice) {
    // console.log("action device", device, JSON.parse(device.settings));
    setDevice(deviceArg);
    setRemoteKeyMap(null);

    dispatch(
      update_action({
        action: {
          ...action,
          device: deviceArg,
          variable: null,
          payload: null,
          // when "Number" ? set to null the input field get unresponsive and hold old value. so fort 0 as it is the minimum value
          // can set to the value input field. otherwise set null
          variable_value: action.possible_variable_type === 'Number' ? 0 : null,
          meta: {
            ...action.meta,
            is_enabled: true,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
          },
        },
        isChild,
        parentAction,
      }),
    );
  }

  const ITEMS_LIB: any = ITEM_DEFAULTS.IR_DEFAULTS;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { device } = action;
    if (device && device.settings) {
      const settings = JSON.parse(device.settings);
      if (device.cat === 'ir' && settings.device) {
        const key_map = ITEMS_LIB[device.sub_cat][settings.device.toUpperCase()].versions[
          settings.key_map_version
        ];
        const formatted = Object.keys(key_map.key_map).map((key) => ({
          key,
          label: key_map.key_map[key].replace('_', ' '),
        }));
        setRemoteKeyMap(formatted);
      }
    }
  }, [action.device]);

  function onExpressionChange(op: OPERATORS, value: any, variable: {field: string; type: string}) {
    console.log('on expresion selected 22', op, value, variable);
    dispatch(
      update_action({
        action: {
          ...action,
          variable_value: value,
          variable: variable.field,
          possible_variable_type: variable.type,
        },
        isChild,
        parentAction,
      }),
    );
  }

  function onCommandValidationChange() {
    const action_cpy = action as AirAction;
    // eslint-disable-next-line max-len
    const validate_val = action_cpy.validate_command !== undefined && action_cpy.validate_command != null
      ? !action_cpy.validate_command
      : true;
    dispatch(
      update_action({
        action: {
          ...action,
          validate_command: validate_val,
        } as any,
        isChild,
        parentAction,
      }),
    );
  }

  function onDerivedStateChange() {
    dispatch(
      update_action({
        action: {
          ...action,
          payload: null,
          variable_value: null,
        },
        isChild,
        parentAction,
      }),
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box height={10} />
        <DeviceSelector
          selectedDC={dc}
          selectedDevice={action ? action.device : null}
          onDcSelect={onDcSelect}
          onDeviceSelect={onDeviceSelect}
          logic_step={LogicStepsTypes.ACTION}
        />
      </Grid>
      {remoteKeyMap ? (
        <Grid item xs={12} md={12}>
          <RemoteActions
            remoteKeyMap={remoteKeyMap}
            onChange={onExpressionChange}
            action={action}
            isChild={isChild}
            parentAction={parentAction}
          />
        </Grid>
      ) : action.device && action.device.cat === 'ac' ? (
        <Grid item xs={12} md={12}>
          <AudioDistributerActions
            action={action}
            onChange={onExpressionChange}
            isChild={isChild}
            parentAction={parentAction}
          />
        </Grid>
      ) : action.device && action.device.sub_cat === 'thirdprty_ac_controller' ? (
        <Grid item xs={12} md={12}>
          <AcControllerChildActions action={action} onChange={onExpressionChange} />
        </Grid>
      ) : action.device && (action.device.sub_cat === 'aapesx1a' || action.device.sub_cat === 'paradox_sp') ? (
        <Grid item xs={12} md={12}>
          <ExsAlarmChildChildActions action={action} alarmType={action.device.sub_cat} onChange={onExpressionChange} />
        </Grid>
      ) : (
        <Grid item xs={12} md={12}>
          <ExpressionSelector
            selectedVariable={{
              field: action.variable,
              type: action.possible_variable_type,
            }}
            selecteValue={action.variable_value}
            selectedOperator={OPERATORS.EQUAL}
            onChange={onExpressionChange}
            device={action.device}
            dc={dc}
            behaveAs="valueAssigner"
            extraFields={{
              action,
              isChild,
              parentAction,
            }}
            onToggleDerivedState={onDerivedStateChange}
          />
        </Grid>
      )}
      {action && action.device && action.device.shadow_type === 'airDevice' && (
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={
                  (action as AirAction).validate_command !== undefined
                  && (action as AirAction).validate_command != null
                    ? (action as AirAction).validate_command
                    : false
                }
                onChange={onCommandValidationChange}
                name="gilad"
              />
            )}
            label="Command Validation"
          />
        </Grid>
      )}
    </Grid>
  );
};
