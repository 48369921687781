/* eslint-disable camelcase */
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { insert_op_connection } from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState } from '../../../../../../../store/redux/store';

export enum LOGICAL_OP {
  AND = 'AND',
  OR = 'OR',
}

const useStyle = makeStyles({
  line_root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '2% 0',
  },
  line: {
    alignSelf: 'center',
    background: '#E0E0E0',
    width: '40%',
    height: '0.5px',
  },
  formControl: {
    width: '100px',
  },
});

export const OperatorSelector: React.FC<{
  preId: string;
  nextId: string;
  initial_op?: LOGICAL_OP;
}> = ({ preId, nextId, initial_op = LOGICAL_OP.OR }) => {
  const state = useSelector((reduxState: RootState) => reduxState
    .rule_engine.checks.operator_connectivity);
  const dispatch = useDispatch();

  const classes = useStyle();

  useEffect(() => {
    if (state[`${preId}-${nextId}`]) return;
    dispatch(insert_op_connection({ key: `${preId}-${nextId}`, value: initial_op }));
  }, []);

  function onOpChange(e) {
    console.log('onchanged');
    dispatch(insert_op_connection({ key: `${preId}-${nextId}`, value: e.target.value }));
  }

  return (
    <div className={classes.line_root}>
      <hr className={classes.line} />
      <FormControl size="small" variant="outlined" className={classes.formControl}>
        <InputLabel>Oparator</InputLabel>
        <Select
          label="Operator"
          value={state[`${preId}-${nextId}`] || initial_op}
          onChange={onOpChange}
        >
          {Object.keys(LOGICAL_OP).map((key, index) => (
            <MenuItem value={LOGICAL_OP[key]}>{key}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <hr className={classes.line} />
    </div>
  );
};

OperatorSelector.defaultProps = {
  initial_op: null,
};
