/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Image file picker with drag drop feature
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a file picker
 * @author Mayantha
 * @since -
 */
import React, {useEffect, useState} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import ImageCropper from '../Cropper';
// import Dropzone from 'react-dropzone'

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

export const ExcelFilePicker: React.FC<{onChange: (data: string[][]) => void}> = ({ onChange }) => {
  const [file, setFile] = useState<any>();
  const [filedata, setFileData] = useState(null);
  const [open, setOpen] = useState(false);

  let uploadRef: HTMLInputElement | null;

  useEffect(
    () => () => {
      if (file) URL.revokeObjectURL(file.preview);
    },
    [file]
  );

  function readExcelData(file) {
    const reader = new FileReader();
    reader.onload = evt => {
      /* Parse data */
      const csvData = evt.target.result as string;
      const allColumns: string[][] = [];

      const lines = csvData.split('\n');
      lines.forEach((line) => {
        const columns = line.split(',');
        const processedColumn = [];
        columns.map((item) => {
          processedColumn.push(item.trim().replace(/^"(.*)"\s*$/, '$1'));
        })

        if (processedColumn.length > 1) {
          allColumns.push(processedColumn);
        }
      });

      onChange(allColumns);
    };

    reader.readAsText(file);
  }

  return (
    <section className="">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input
        type="file"
        style={{border: '1px solid gray', padding: 10}}
        onChange={event => {
          if (event.target.files.length > 0) {
            setFile(event.target.files[0]);
            readExcelData(event.target.files[0]);
            setOpen(true);
          }
        }}
        ref={ref => {
          uploadRef = ref;
        }}
        accept=".csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      {filedata && filedata != null ? (
        <aside
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 16,
            height: 100,
          }}
        >
          <div
            style={{
              display: 'inline-flex',
              borderRadius: 2,
              border: '1px solid #eaeaea',
              marginBottom: 8,
              marginRight: 8,
              // width: 100,
              height: 100,
              padding: 4,
              boxSizing: 'border-box',
            }}
          >
            <div style={thumbInner}>
              <img style={img} src={filedata} alt="Thumbnail" />
            </div>
          </div>
        </aside>
      ) : (
        <div />
      )}
    </section>
  );
};

/**
 * File picker with drag drop feature
 * @callback onDropImage
 * @param {Object} props                      component props
 * @param {onDropImage} props.onChange        callback fires when user dropped image
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const ImgDragDrop: React.FC<any> = props => {
  const [files, setFiles] = useState([]);
  const [filedata, setFileData] = useState(null);
  const [open, setOpen] = useState(false);

  let uploadRef: HTMLInputElement | null;
  const cropRef = {
    ref: null as HTMLButtonElement,
  };

  const {getRootProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setOpen(true);
    },
  });

  const selectNewPhoto = () => {
    if (uploadRef) {
      uploadRef.click();
    }
  };

  const onImageCropper = (cropped: any) => {
    setFileData(cropped);
    // eslint-disable-next-line react/destructuring-assignment
    props.onChange(cropped, files[0]);
  };

  useEffect(
    () => () => {
      files.forEach(file => {
        if (file) URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );

  return (
    <section className="">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div
        {...getRootProps({className: 'dropzone'})}
        onClick={selectNewPhoto}
        onKeyDown={e => e.key === '13' && selectNewPhoto()}
        role="button"
        tabIndex={0}
      >
        <input
          type="file"
          hidden
          onChange={event => {
            if (event.target.files.length > 0) {
              setFiles([event.target.files[0]]);
              setOpen(true);
            }
          }}
          ref={ref => {
            uploadRef = ref;
          }}
          accept="image/x-png,image/gif,image/jpeg"
        />
        <p>Cick to select image</p>
        {filedata && filedata != null ? (
          <aside
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: 16,
              height: 100,
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                borderRadius: 2,
                border: '1px solid #eaeaea',
                marginBottom: 8,
                marginRight: 8,
                // width: 100,
                height: 100,
                padding: 4,
                boxSizing: 'border-box',
              }}
            >
              <div style={thumbInner}>
                <img style={img} src={filedata} alt="Thumbnail" />
              </div>
            </div>
          </aside>
        ) : (
          <div />
        )}
      </div>
      <Dialog open={open}>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          <ImageCropper src={files[0]} onCropped={onImageCropper} cropperRef={cropRef} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (cropRef.ref) {
                cropRef.ref.click();
                setOpen(false);
              }
            }}
            color="primary"
            variant="contained"
          >
            Crop
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};
