/* eslint-disable no-useless-concat */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { createContext, useContext, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { appConsoleLog } from '../../../utility/appUtils';

const initialTreeState: {treeData: any[]; selectedDeviceId: number} = {
  treeData: [],
  selectedDeviceId: null,
};

const getDeviceUrl = (deviceObj: any, projectId?: string, dcName?: string) => {
  const isPro = !!deviceObj.device.prodc_id;
  const isAir = !!deviceObj.device.airdc_id;
  const isCint = !!deviceObj.device.cint_coll;

  if (isCint) {
    return `/app/device-container/cloud-int/update/device?projectId=${projectId}&dcId=${deviceObj.device.cint_coll}&deviceId=${deviceObj.device.id}&dcName=${dcName}&cint_type=${deviceObj.device.cint_type}`;
  }

  if (isPro) {
    return `/app/device-container/device?projectId=${projectId}&dcType=${
      isPro ? 'pro' : 'air'
    }&deviceId=${deviceObj.device.id}`;
  }
  if (isAir) {
    return `/app/device-container/air/update/device?projectId=${projectId}&dcId=${deviceObj.device.airdc_id}&deviceId=${deviceObj.device.id}&dcName=${dcName}`;
  }
  return null;
};

const generateRows = (
  root: any,
  source: Map<string, any>,
  userViews: any,
  projectId: any,
  onSelect: (selectedId: string) => void,
) => {
  const treeData = [];

  Object.keys(source).map((key) => {
    const value = source[key];

    const uuid1 = uuid(); // tree id for container level item
    const item: {uv: any; id: any; dbId: any; parentId: any; title: any} = {
      id: uuid1,
      dbId: value.info.id,
      parentId: root.id,
      title: (
        <Tooltip title="Open In New" placement="bottom-start">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/app/device-container/pro?projectId=${projectId}&dcId=${value.info.id}&name=${value.info.name}`}
          >
            <p style={{ color: '#222222' }}>{value.info.name}</p>
          </Link>
        </Tooltip>
      ),
      uv: null,
    };
    treeData.push(item);

    const { devices } = value;
    devices.map((deviceObj: any) => {
      const isPro = !!deviceObj.device.prodc_id;
      const isCint = !!deviceObj.device.cint_coll;
      const uniqueId = uuid(); // tree id for device level item
      const Ditem = {
        id: uniqueId,
        dbId: deviceObj.device.id,
        parentId: uuid1, // deviceObj.device.prodc_id || deviceObj.device.airdc_id,
        title:
          deviceObj.uv_items.length > 0 ? (
            <div style={{ display: 'inline' }}>
              <Tooltip title={deviceObj.device.name} placement="bottom-start">
                <div
                  style={{
                    color: '#06454D !important',
                    cursor: 'pointer',
                    fontWeight: 500,
                  }}
                >
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'inherit',
                      cursor: 'pointer',
                      fontWeight: 500,
                    }}
                    to={getDeviceUrl(deviceObj, projectId, value.info.name)}
                    // to={`/app/device-container/device?projectId=${projectId}&dcType=${
                    //   isPro ? 'pro' : 'air'
                    // }&deviceId=${deviceObj.device.id}`}
                  >
                    {`${
                      deviceObj.device.name.length > 14
                        ? `${deviceObj.device.name.substr(0, 14)}..`
                        : deviceObj.device.name
                    } ` + `(${deviceObj.uv_items.length})`}
                  </Link>

                  <IconButton
                    aria-label="Select This"
                    onClick={() => onSelect(
                      isCint
                        ? `cint_${deviceObj.device.id}`
                        : isPro
                          ? `pro_${deviceObj.device.id}`
                          : `air_${deviceObj.device.id}`,
                    )}
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          ) : (
            <div style={{ color: 'gray', cursor: 'pointer' }}>
              <Tooltip title={deviceObj.device.name} placement="bottom-start">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'inherit',
                    cursor: 'pointer',
                    fontWeight: 500,
                  }}
                  to={getDeviceUrl(deviceObj, projectId, value.info.name)}
                  // to={`/app/device-container/device?projectId=${projectId}&dcType=${
                  //   isPro ? 'pro' : 'air'
                  // }&deviceId=${deviceObj.device.id}`}
                >
                  {deviceObj.device.name.length > 14
                    ? `${deviceObj.device.name.substr(0, 14)}..`
                    : deviceObj.device.name}
                </Link>
              </Tooltip>

              <IconButton
                aria-label="Select This"
                onClick={() => onSelect(
                  isCint
                    ? `cint_${deviceObj.device.id}`
                    : isPro
                      ? `pro_${deviceObj.device.id}`
                      : `air_${deviceObj.device.id}`,
                )}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </div>
          ),

        // uv:
        //   deviceObj.uv_items.length == 0 ? (
        //     <Button>
        //       <PlaylistAddIcon />
        //     </Button>
        //   ) : null,
      };
      treeData.push(Ditem);

      deviceObj.uv_items.map((uv: any) => {
        // console.log("xcxcxc", uv, userViews);
        const uvItem = {
          id: uuid(),
          dbId: uv.id,
          parentId: uniqueId, // item.id,
          title: (
            <Tooltip
              title={
                userViews[uv.userview_id]
                  ? `${userViews[uv.userview_id].name}/${uv.display_name}`
                  : ''
              }
              placement="bottom-start"
            >
              <p style={{ cursor: 'pointer' }}>
                {userViews[uv.userview_id]
                  ? `${userViews[uv.userview_id].name}/${uv.display_name}`
                  : ''}
              </p>
            </Tooltip>
          ), // uv.display_name,
          //   uv: <Button>{uv.display_name}</Button>,
        };
        return treeData.push(uvItem);
      });
      return 0;
    });

    return 0;
  });
  return treeData;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET': {
      const { json_map, project_id, onSelect } = action.payload;
      // eslint-disable-next-line no-debugger
      // debugger;
      const rawData = JSON.parse(json_map);
      const userViews = rawData.userviews;

      const treeData = [];

      const { air } = rawData;
      const { pro } = rawData;
      const { cint } = rawData;
      const items = { ...air, ...pro, ...cint };
      const root: {uv: any; id: any; parentId: any; title: any} = {
        id: 0,
        parentId: null,
        title: 'Device - Container Map',
        uv: null,
      };
      treeData.push(root);
      const childrens = generateRows(root, items, userViews, project_id, onSelect);
      treeData.push(...childrens);

      return {
        ...state,
        treeData,
      };
    }
    case 'RESET': {
      return {
        ...state,
        treeData: [],
      };
    }
    case 'SELECT': {
      return {
        ...state,
        selectedDeviceId: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const TreeContext = createContext({
  ...initialTreeState,
  dispatch: (obj: any) => {
    appConsoleLog(obj);
  },
});

export const TreeViewProvider: React.FC<{children: any}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialTreeState);
  return (
    <TreeContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </TreeContext.Provider>
  );
};

export const useTree = () => useContext(TreeContext);
