import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    height: '60px',
    width: '100%',
    background: 'white',
    BorderBottom: '1px solid black',
    verticalAlign: 'middle',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    color: 'var(--primary-dark-green)',
    boxShadow: '2px 5px 8px rgba(4, 134, 150, 0.05)',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const WidgetHeaderLayout: React.FC<{
  children: any;
  className?: any;
}> = ({ children, className }) => {
  const styles = useStyles();
  return <Box className={clsx(styles.root, className)}>{children}</Box>;
};
