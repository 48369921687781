/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import {
  DeleteForever, Folder, Sort, ArrowForward,
} from '@mui/icons-material';
import { Formik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import {
  IconButton,
  InputAdornment,
  TextField,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Button,
} from '@mui/material';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './SingleUserView.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { LoadingComponent } from '../../../../components/shared/Loading/Loading';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { appConsoleLog, AppUtils } from '../../../../utility/appUtils';
import { CustomSwitch } from '../../../../components/shared/Switch/Swtich';

import GreenButton from '../../../../components/shared/buttons/AuthButton/GreenButton';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';

import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import {
  userviewDeleteGraphQL,
  userviewGetGraphQL,
  userviewUpdateGraphQL,
} from '../../../../services/user-view/user-view.service';
import { UserViewRoot } from '../../../../components/UserViewComponents/UserViewRoot';
import { IUserView } from '../../../../components/UserViewComponents/types/types';
import {
  IUVCurrentDirectoryRef,
  UVCurrentDirectoryRefActions,
} from '../../../../components/UserViewComponents/UserViewcurrentDirectory/UserViewcurrentDirectory';
import { projectDeviceUvMapGraphQL } from '../../../../services/location/location.service';
import { UVIconSelector } from '../../../../components/UserViewComponents/UIItems/UVIconSelector/UVIconSelector';
import { useQueryNumber } from '../../../../utility/CustomHooks/useQueryNumber';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';
import { CofirmDialog } from '../../LogicsEngine/components/ui/Confirm';

const MemoizedUserViewRoot: React.FC<{
  parent_folder_id: string;
  userViewSet: (value: React.SetStateAction<IUserView>) => void;
  itemCreateRef: React.MutableRefObject<IUVCurrentDirectoryRef>;
}> = React.memo(({ parent_folder_id, userViewSet, itemCreateRef }) => {
  console.log('PPPP_FFFF_IID', parent_folder_id);
  return (
    <UserViewRoot
      itemCreateRef={itemCreateRef}
      parent_folder_id={parent_folder_id}
      userviewFetchHandler={(userView: IUserView) => {
        userViewSet(userView);
      }}
    />
  );
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: <></>,
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1ca086',
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#818181',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#1ca086',
      opacity: 1,
    },
    '&$selected': {
      color: '#1ca086',
      // fontWeight: 'bold',
    },
    '&:focus': {
      color: '#1ca086',
    },
  },
  selected: {},
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<any>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '2%',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  topBreadcump: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
interface IState {
  user_view?: any;
}
type IUserVIewFormValue = {
  user_view_name: string;
};

export const SingleUserViewComponent: React.FunctionComponent<any> = () => {
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject: any = useContext(ProjectContext);
  const uvId = useQueryString('userViewId') as string;
  const navgiate = useNavigate();
  const location = useLocation();

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [lockState, setLocakState] = React.useState(true);

  const [selectedIcon, setSelectedIcon] = useState('calc-door2');
  const [selectedColor, setSelectedColor] = useState('#FFFFFF');
  const [formval, setFormVal] = useState({
    user_view_name: '',
  });

  const [userView, userViewSet] = useState<IUserView | null>(null);
  const [uvRootFolderId, setUVRootFolderID] = useState<string>(null);

  const [shouldDeleteUv, setShouldDeleteUv] = useState(false);

  const itemCreateRef = useRef<IUVCurrentDirectoryRef>();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [deleteState, setDeleteState] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    // if (
    //   !selectedProject ||
    //   !selectedProject.selected_project ||
    //   !selectedProject.selected_project.id ||
    //   selectedProject.selected_project.id === 0
    // ) {
    //   navigateRoute("/app");
    // }

    if (uvId && uvId !== '') {
      init(uvId);
    }
  }, [uvId]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const init = async (uvId: string) => {
    const res = await userviewGetGraphQL(userProfile.apollo_client, uvId);
    if (res.userviewGet) {
      const {
        name, icon_name, item_color, lock_role3, home_folder_id,
      } = res.userviewGet.userview;
      setFormVal({
        user_view_name: name,
      });
      setSelectedIcon(icon_name);
      setSelectedColor(item_color);
      setLocakState(lock_role3 !== 2);
      setUVRootFolderID(home_folder_id);
    }
  };

  // useEffect(() => {
  //   console.log("OOOOOOOO", location.state as IState);
  //   if ((location.state as IState) && (location.state as IState).user_view) {
  //     setLocakState(userView?.lock_role3 === 2 ? false : true);

  //     if (
  //       !userView ||
  //       !userView.id ||
  //       (location.state as IState).user_view.id !== userView.id
  //     ) {
  //       userViewSet((location.state as IState).user_view);
  //       setLocakState(
  //         (location.state as IState).user_view?.lock_role3 === 2 ? false : true
  //       );
  //       setSelectedColor((location.state as IState).user_view.item_color);
  //       setSelectedIcon((location.state as IState).user_view.icon_name);
  //     }
  //   }
  // });

  const handleChangeOfSwtich = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocakState(event.target.checked);
    try {
      if (userView == null || !userView.id) {
        return;
      }
      userviewUpdateGraphQL(
        userProfile?.apollo_client,
        userView?.id,
        userView?.name,
        '',
        1,
        lockState ? 2 : 1,
        selectedIcon,
        selectedColor,
      )
        .then(async (res) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_UPDATE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          userViewSet({ ...userView, lock_role3: lockState ? 2 : 1 });
        })
        .catch((err) => {
          setLoading(false);
          appConsoleLog(err);
          snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_UPDATE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (err) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_UPDATE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };

  const onSubmitForm = async (
    values: IUserVIewFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    setLoading(true);
    try {
      if (userView == null || !userView.id) {
        return;
      }
      if (!selectedProject.selected_project.id) {
        return;
      }
      await userviewUpdateGraphQL(
        userProfile?.apollo_client,
        userView.id,
        values.user_view_name.trim(),
        '',
        1,
        userView?.lock_role3,
        selectedIcon,
        selectedColor,
      )
        .then(async (res) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_UPDATE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          userViewSet({ ...userView, name: values.user_view_name.trim() });
        })
        .catch((err) => {
          setLoading(false);
          appConsoleLog(err);
          snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_UPDATE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (err) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_UPDATE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const deleteUserView = async (userview_id: string) => {
    try {
      setLoading(true);
      setShouldDeleteUv(false);
      await userviewDeleteGraphQL(userProfile?.apollo_client, userview_id)
        .then(async (res) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_DELETE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          navigateRoute(`/app/user-views?projectId=${selectedProject.selected_project.id}`);
        })
        .catch((err) => {
          setLoading(false);
          appConsoleLog(err);
          snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_DELETE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (error) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_DELETE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            <span
              className={classes.topBreadcump}
              onClick={() => navgiate(`/app/user-views?projectId=${selectedProject.selected_project.id}`)}
            >
              User Views
            </span>
            <span>{' / '}</span>
            <span
              className={classes.topBreadcump}
              onClick={() => navgiate(
                `/app/user-view?userViewId=${uvId}&projectId=${selectedProject.selected_project.id}`,
              )}
            >
              {userView?.name || ''}
            </span>
          </Typography>
          <div className={styles.btnBar}>
            <div className={styles.flexSpacer} />
            <div className={styles.addInterface}>
              <DefaultButton
                disabled={value !== 0}
                onClick={() => {
                  itemCreateRef.current?.action(UVCurrentDirectoryRefActions.folderCraete);
                }}
              >
                <Folder style={{ marginRight: 5 }} />
                {APP_TEXT.user_view.ADD_FOLDER}
              </DefaultButton>
            </div>
            <div className="pl-8" />

            <div className={styles.addInterface}>
              <DefaultButton
                disabled={value !== 0}
                onClick={() => {
                  itemCreateRef.current?.action(UVCurrentDirectoryRefActions.itemCreate);
                }}
              >
                <AddIcon style={{ marginRight: 5 }} />
                {APP_TEXT.user_view.ADD_INTERFACE}
              </DefaultButton>
            </div>
          </div>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className={`${classes.demo1} width-full-fixed`}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            className={styles.tabsTop}
            variant="scrollable"
          >
            <AntTab label={APP_TEXT.user_view.USER_VIEW_TAB1} />
            <AntTab label={APP_TEXT.user_view.USER_VIEW_TAB2} />
            <div className={styles.flexSpacer} />
            <div className={styles.sortBtn}>
              <Button
                disabled={value !== 0}
                onClick={() => {
                  itemCreateRef.current?.action(UVCurrentDirectoryRefActions.sortFolderItems);
                }}
                color="primary"
              >
                <Sort fontSize="large" />
                <span className={styles.SortText}>Sort</span>
              </Button>
            </div>
          </AntTabs>
          <TabPanel value={value} index={0}>
            <div>
              {uvRootFolderId ? (
                <MemoizedUserViewRoot
                  parent_folder_id={uvRootFolderId}
                  itemCreateRef={itemCreateRef}
                  userViewSet={userViewSet}
                />
              ) : (
                'loading...'
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="row">
              <div className="col-md-12">
                <div className="custom-card2 col-md-12 pb-5">
                  <div className="custom-card2-container">
                    <div className="custom-card2-in ">
                      <div className="custom-card2-title">
                        <h2>{APP_TEXT.user_view.USER_VIEW_DETAILS}</h2>
                        <hr />
                      </div>
                      <Formik
                        onSubmit={onSubmitForm}
                        initialValues={formval}
                        enableReinitialize
                        validationSchema={Yup.object().shape({
                          user_view_name: Yup.string().required('Required'),
                        })}
                      >
                        {({
                          errors,
                          handleBlur,
                          // eslint-disable-next-line @typescript-eslint/no-shadow
                          handleChange,
                          touched,
                          values,
                          submitForm,
                        }) => (
                          <form>
                            <div className="custom-card2-body mt-3">
                              <div className="m-0 col-md-12 nopaddingOnly row">
                                <div className="col-md-12 nopaddingOnly">
                                  <div className="col-md-7 nopaddingOnly mb-2">
                                    <div>
                                      <TextField
                                        className={`${styles.fieldSpace} width-full-fixed`}
                                        error={Boolean(
                                          touched.user_view_name && errors.user_view_name,
                                        )}
                                        helperText={touched.user_view_name && errors.user_view_name}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.user_view_name}
                                        name="user_view_name"
                                        label={APP_TEXT.user_view.FORM_FIELD_USER_VIEW_NAME}
                                        size="medium"
                                        type="text"
                                        variant="outlined"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton edge="end" size="large" />
                                            </InputAdornment>
                                          ),
                                        }}
                                        data-testid=""
                                      />
                                    </div>
                                    <UVIconSelector
                                      initialIcon={selectedIcon}
                                      initialColor={selectedColor}
                                      onChange={(icon, color) => {
                                        setSelectedIcon(icon);
                                        setSelectedColor(color);
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-6  " />
                                </div>
                              </div>
                            </div>

                            <div className="custom-card-body">
                              <div className="col-md-12 nopaddingOnly">
                                <div className="col-md-4 nopaddingOnly">
                                  <GreenButton
                                    text={APP_TEXT.user_view.UPDATE_USER_VIEW_BTN}
                                    onClick={submitForm}
                                    disable={loading}
                                    data-testid="submitButton"
                                  >
                                    {loading ? <LoadingComponent /> : <ArrowForward />}
                                  </GreenButton>
                                </div>
                                <div className="col-md-2 nopaddingOnly" />
                                <div className="col-md-2 nopaddingOnly">
                                  <FormControlLabel
                                    control={(
                                      <CustomSwitch
                                        checked={lockState}
                                        onChange={handleChangeOfSwtich}
                                        name="checkedB"
                                      />
                                    )}
                                    label="Lock User View"
                                  />
                                </div>
                                <div className="col-md-3 nopaddingOnly">
                                  {userView && userView.id ? (
                                    <ClearLeftIconButton
                                      text={APP_TEXT.user_view.DELETE_USER_VIEW_BTN}
                                      onClick={() => {
                                        if (userView == null || !userView.id) {
                                          return;
                                        }
                                        setShouldDeleteUv(true);
                                      }}
                                      disable={loading}
                                      color="red"
                                    >
                                      {loading ? <LoadingComponent /> : <DeleteForever />}
                                    </ClearLeftIconButton>
                                  ) : (
                                    <div />
                                  )}
                                  {/* </div> */}
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <Typography component="span" className={classes.padding} />
          <CofirmDialog
            dialogOpen={shouldDeleteUv}
            dialogCloseEvent={(e: any) => {
              if (e === true) {
                deleteUserView(userView?.id);
              } else {
                setShouldDeleteUv(false);
              }
            }}
            title="Confirm to delete the user view ?"
            text="It will delete the userview and you will not able to redo it!"
          />
        </div>
      </Box>
    </Layout>
  );
};

export default SingleUserViewComponent;
