/* eslint-disable camelcase */
import React, {useContext, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Skeleton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import {APP_TEXT} from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './SingleDevice.module.css';
import {AuthContext} from '../../../../store/Auth/AuthContext';
import {ApolloAuthContext} from '../../../../store/Apollo/ApolloContext';
import {LinkedDeviceCardComponent} from '../../../../components/shared/LinkedDeviceCard/LinkedDeviceCard';
import {ProjectContext} from '../../../../store/Project/ProjectContext';
import {ITEM_DEFAULTS} from '../../../../config/deviceConfig/item_defaults';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import {getDeviceLinkedItems} from '../../../../services/device-container/device-container.service';
import {proDeviceGetGraphQL} from '../../../../services/pro-device/pro-device.service';
import {airDeviceGet} from '../../AirDeviceCreateEdit/services/air-device.service';
import Layout from '../../../../components/layout/Layout';
import {PageHeader} from '../../../../components/layout/PageHeader';
import moment from 'moment';

type IProDCFormValue = {
  hub_type: number;
  device_name: string;
  device_serial_number: string;
  device_description: string;
};
type IAirDCFormValue = {
  air_email: string;
  air_otp: string;
  air_password: string;
  air_name: string;
};
type IAirOtpFormValue = {
  air_email: string;
};

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    minHeight: 100,
    width: '100%',
  },
  contentRoot: {
    flex: 1,
    marginBottom: '4%',
    overflow: 'auto',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    padding: '2% 1%',
  },
  links: {
    padding: '2% 1%',
  },
  linkTitle: {},
});

export const SingleDeviceComponent: React.FunctionComponent<any> = () => {
  const matStyles = useStyle();
  const user = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deviceConfig, setDeviceConfig] = useState<{
    icon: string;
    title: string;
  }>({icon: '', title: ''});

  const dcType = useQueryStringFirst('dcType');
  const deviceId = useQueryStringFirst('deviceId');
  const deviceName = useQueryStringFirst('deviceName');
  const deviceType = useQueryStringFirst('deviceType');
  const deviceCat = useQueryStringFirst('cat');
  const deviceSubCat = useQueryStringFirst('sub_cat');

  const [device, setDevice] = useState(null);
  const [linkedItems, setLinkedItems] = useState([]);
  const [isInit, setIsInit] = useState(false);

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    if (selectedProject.selected_project.id) {
      // eslint-disable-next-line use-isnan
      if (!(dcType === 'pro' || dcType === 'air' || dcType === 'cint')) {
        // if (history.length > 0) {
        //   navgiate(-1);
        //   return;
        // }
        // navigateRoute('/app');
        navgiate(-1);
      }

      init(deviceId, dcType);
      initLogicReferences(deviceId);
    }
  }, [selectedProject.selected_project.id, deviceId, dcType]);

  useEffect(() => {
    if (device) {
      const ITEMS_LIB: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
      if (ITEMS_LIB[device.cat] && ITEMS_LIB[device.cat].children[device.sub_cat]) {
        const conf = ITEMS_LIB[device.cat].children[device.sub_cat];
        setDeviceConfig(conf);
      }
    }
  }, [device]);

  const init = async (device_id: string, collection_type: string) => {
    try {
      if (collection_type === 'air') {
        const res = await airDeviceGet(userProfile.apollo_client, device_id);
        await setDevice(res);
      }

      if (collection_type === 'pro') {
        const res = await proDeviceGetGraphQL(userProfile.apollo_client, device_id);
        await setDevice(res);
      }

      if (collection_type === 'cint') {
        const res = await pluginHook.commonIntegrationService.getCloudDevice(device_id);
        if (res && res.result && res.result.device) {
          setDevice(res.result.device);
        }
      }
      await initLinkedItems(deviceId, dcType);
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {variant: 'error'});
    } finally {
      setIsInit(true);
    }
  };

  const [logics, setLogics] = useState<
    {
      created_at: number;
      logic_id: string;
      name: string;
      status: string;
      updated_at: number;
    }[]
  >([]);
  const initLogicReferences = async (device_id: string) => {
    try {
      const res = await pluginHook.commonIntegrationService.axiosClient.post(
        '/logic-engine/v1/logic-item/by-devices-id',
        {
          device_id,
        }
      );
      const {data} = res;
      const logics = data.result;
      if (Array.isArray(logics) && logics.length > 0) setLogics(logics);
    } catch (err) {}
  };

  const initLinkedItems = async (device_id: string, collection_type: string) => {
    try {
      const res = await getDeviceLinkedItems(userProfile.apollo_client, device_id, collection_type);
      if (!res.deviceGetLinkedItems) throw new Error('Failed to get linked items');

      setLinkedItems([...res.deviceGetLinkedItems.items]);
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  const handleClickLink = async (item: any) => {
    navgiate(
      `/app/user-view?userViewId=${item.userview_id}&projectId=${selectedProject.selected_project.id}&userViewPath=${item.path_ref}&userViewPathName=HOME&userViewName=`
    );
  };

  if (!isInit) {
    return (
      <Layout>
        <PageHeader>
          <Box className={matStyles.headerRoot}>
            <Typography>
              <span className="container-title-dark-txt">
                {selectedProject.selected_project.title}/
              </span>
              <span className="container-title-dark-green-txt">&nbsp; Loading...</span>
            </Typography>
          </Box>
        </PageHeader>
        <Box className={matStyles.contentRoot}>
          <Box className={matStyles.info}>
            <Skeleton variant="rectangular" style={{width: '100%', height: '100px'}} />
          </Box>
          <Box className={matStyles.links}>
            <Grid container>
              <Grid item lg={12}>
                <span className={styles.contentHeader}>Available Device Links</span>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Skeleton variant="rectangular" style={{width: '100%', height: '100px'}} />
              </Grid>
              <Grid item md={3}>
                <Skeleton variant="rectangular" style={{width: '100%', height: '100px'}} />
              </Grid>
              <Grid item md={3}>
                <Skeleton variant="rectangular" style={{width: '100%', height: '100px'}} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader>
        <Box className={matStyles.headerRoot}>
          <Typography variant="h5">
            {selectedProject.selected_project.title} / {device.name}
          </Typography>
        </Box>
      </PageHeader>
      <Box className={matStyles.contentRoot}>
        <Box className={matStyles.info}>
          <div style={{padding: 0}}>
            {deviceConfig.icon.length > 0 ? (
              <span
                className={`pl-8 calc calc-icon-uv-primary ${styles.imgBgStyle} ${deviceConfig.icon}`}
                style={{padding: '0 !important'}}
              />
            ) : (
              <img src="https://img.icons8.com/carbon-copy/50/000000/bullet-camera.png" alt="" />
            )}
          </div>
          <div>
            <div className="col-md-12">
              <span className={styles.deviceName}>{device.name}</span>
            </div>
            <div className="col-md-12">
              <span className={styles.devicePath}>Device Path</span>
            </div>
          </div>
        </Box>
        <Box className={matStyles.links}>
          <Grid container>
            <Grid item lg={12}>
              <span className={styles.contentHeader}>Available Device Links</span>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            {linkedItems.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item md={4} lg={3}>
                <LinkedDeviceCardComponent
                  linkedItemName={item.display_name}
                  UserViewName={item.item_type}
                  icon={item.icon_name}
                  goTo={() => handleClickLink(item)}
                />
              </Grid>
            ))}
          </Grid>
          <Box height={20} />
          <Grid container>
            <Grid item lg={12}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableCell>Logic Name</TableCell>
                    <TableCell>Logic status</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Last Modified</TableCell>
                  </TableHead>
                  <TableBody>
                    {logics.map(logic => (
                      <TableRow key={logic.logic_id}>
                        <TableCell>
                          <a
                            target="_blank"
                            style={{color: 'blue'}}
                            href={`/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${logic.logic_id}`}
                          >
                            {logic.name}
                          </a>
                        </TableCell>
                        <TableCell>{logic.status}</TableCell>
                        <TableCell>
                          {moment.unix(logic.created_at).format('YYYY-MM-DD HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {moment.unix(logic.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default SingleDeviceComponent;
