/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Snackbar notification component based on redux state.
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the centralized notification function based on redux state
 * @author Maduka Dilshan
 * @since 1.0.0
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { RootState } from '../../../store/redux/store';
import { removeSnackbar } from '../../../store/redux/features/common';

let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store: RootState) => store.common.notification || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ message, options, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(options.key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(options.key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: () => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar({ key: options.key }));
          removeDisplayed(options.key);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(options.key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default Notifier;
