/* eslint-disable camelcase */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AIR_DC_CREATE_NEW_ACCOUNT,
  AIR_DC_PASSWORD_GET,
  AIR_DC_PASSWORD_RESET,
  AIR_DC_SYNC_NOW_V2,
} from './graphql';

const airDCPasswordGet = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  airdc_id: string,
) => {
  const res = await apolloClient.query({
    query: AIR_DC_PASSWORD_GET,
    variables: {
      airdc_id,
    },
  });

  return res.data.airDCPasswordGet;
};

const airDCPasswordReset = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  airdc_id: string,
) => {
  await apolloClient.query({
    query: AIR_DC_PASSWORD_RESET,
    variables: {
      airdc_id,
    },
  });
};

const MutateAirDcCreateNewAccount = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  input: any,
) => {
  const res = await apolloClient.mutate({
    mutation: AIR_DC_CREATE_NEW_ACCOUNT,
    variables: {
      input,
    },
  });
  return res.data.airDCAccountCreate;
};

const airDCSync = async (apolloClient: ApolloClient<NormalizedCacheObject>, dcId: string) => {
  const res = await apolloClient.mutate({
    mutation: AIR_DC_SYNC_NOW_V2,
    variables: {
      syncType: 'rename,deleted_disable',
      airDcId: dcId,
    },
  });
  return res;
};

export {
  airDCPasswordGet, airDCPasswordReset, MutateAirDcCreateNewAccount, airDCSync,
};
