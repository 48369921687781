/* eslint-disable camelcase */
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Action,
  NotificationActionPayload,
  NotificationAction as NotificationActionTypes,
  AlexaNotificationAction as AlexaNotificationActionType,
} from '@smartlife-redux-state/common';
import { getProjectMembersGraphQL } from 'src/services/member/member.service';
import { isArray } from 'lodash';
import { USERVIEW_NOTIFICATION_TYPES } from '../../../../../../../config/deviceConfig/Values';
import { userviewSearchGraphQL } from '../../../../../../../services/user-view/user-view.service';
import { ApolloAuthContext } from '../../../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../../../store/Project/ProjectContext';
import { update_action } from '../../../../../../../store/redux/features/logic-engine/slice';

const useStyle = makeStyles({
  form_root: {
    width: '100%',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const AlexaNotificationAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const actionCpy: AlexaNotificationActionType = action as any;
  const classes = useStyle();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [allUsers, setAllUsers] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [userIds, setUserIds] = useState([]);
  const [title, setTitle] = useState('');

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);

  useEffect(() => {
    dispatch(
      update_action({
        action: {
          ...action,
          payload: {
            ...action.payload,
            routine_trigger_name: title,
            users: userIds,
          } as any,
        },
        isChild,
        parentAction,
      }),
    );
  }, [userIds, title]);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await getProjectMembersGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          -1,
          -1,
        );
        if (!res.projectListUsers) throw new Error('Failed to get users');
        const filtered = res.projectListUsers.users.filter((user) => user.id != null);
        setAllUsers(filtered);
        if (actionCpy.payload) {
          if (actionCpy.payload.routine_trigger_name) {
            setTitle(actionCpy.payload.routine_trigger_name);
          } else if (actionCpy.payload.routing_trigger_name) {
            setTitle(actionCpy.payload.routing_trigger_name);
          }
          setUserIds(isArray(actionCpy.payload.users) ? actionCpy.payload.users : []);
        }
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  const handleUVChange = (event) => {
    setUserIds(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box style={{ marginTop: 20 }} />
      </Grid>

      <Grid item md={6}>
        <FormControl className={classes.form_root} variant="outlined" size="small">
          <InputLabel>Project Users (Alexa Account)</InputLabel>
          <Select
            multiple
            value={userIds}
            label="Project Users (Alexa Account)"
            onChange={handleUVChange}
            // input={<Input />}
            variant="outlined"
            disableUnderline
            renderValue={(selected: any) => {
              const arr = [];
              selected.map((selectedone) => {
                const uv = allUsers.find((item) => item.id === selectedone);
                if (uv) {
                  arr.push(uv.user_email);
                }
                return null;
              });
              // const uv: any = allUsers.find((item) => item.id == selected);
              return arr.join(', ');
            }}
            // MenuProps={MenuProps}
          >
            {isLoading ? (
              <Skeleton variant="rectangular" height={50} />
            ) : (
              allUsers.map((uv) => (
                <MenuItem key={uv.id} value={uv.id}>
                  <Checkbox checked={userIds.indexOf(uv.id) > -1} />
                  <ListItemText secondary={uv.user_name} primary={uv.user_email} />
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl className={classes.form_root}>
          <TextField
            label="Routine Trigger Name"
            variant="outlined"
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
