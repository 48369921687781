import React, { useContext, useEffect, useState } from 'react';

import firebase from 'firebase';
import { useNavigate } from 'react-router-dom';
import { IUser } from './types';
import FirebaseAuthFunctions from './providers/FirebaseAuthFunctions';
import { ApolloFunctions } from '../Apollo/ApolloFunctions';
import { LoadingFullPageComponent } from '../../components/shared/LoadingFull/LoadingFull';
import { appConsoleLog } from '../../utility/appUtils';

const initialUser: IUser = {
  name: '',
  email: '',
  email_verified: false,
  uid: '',
  access_token: {
    token: '',
    created: 0,
    life: 0,
  },
  id_token: '',
  profile: {},
};
export const AuthContext = React.createContext<IUser>(initialUser);
export function AuthProviderComponent({ children, ...props }: any) {
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>(initialUser);
  const [isAppLoading, setAppLoading] = useState<boolean>(true);
  useEffect(() => {
    async function init() {
      await FirebaseAuthFunctions.initFirebaseApp();
      console.log('Initialized the app');
      // eslint-disable-next-line @typescript-eslint/no-shadow
      firebase.auth().onAuthStateChanged(async (user) => {
        console.log('Initialized the app INSIDE Listner');
        let appUser: IUser;

        if (user && user.email && user.email.length > 1) {
          const idToken = await FirebaseAuthFunctions.getIdToken();
          const tempClinet = ApolloFunctions.createAuthApolloClient(idToken);
          const authApolloResponse = await ApolloFunctions.getLongLivedAccessToken(tempClinet);
          console.log('APOLLO RES', authApolloResponse);
          // appConsoleLog(authApolloResponse);
          if (!authApolloResponse) {
            if (window.location.href.includes('/app')) {
              navigate('/login');
            }
          }
          appUser = {
            email: user.email,
            access_token: {
              token: authApolloResponse.token,
              created: authApolloResponse.created,
              life: authApolloResponse.life,
            },
            uid: user.uid,
            email_verified: user?.emailVerified,
            profile: authApolloResponse.profile,
          };

          appConsoleLog(appUser);
          setUser(appUser);
          setAppLoading(false);
        } else {
          appConsoleLog('init app firebase No user is signed in. ', null);
          if (window.location.href.includes('/app')) {
            navigate('/login');
          }
          // FirebaseAuthFunctions.LogoutCurrentUser();
          setAppLoading(false);
        }

        //---------------
        //---------------
        //---------------
        //---------------
        // setAuth(appUser);
        // appConsoleLog(appUser.idToken);
        // appConsoleLog("init app  2", appUser.uid, appUser.email);
        // initAuthApolloClient(appUser.idToken);
        // history.push("/dashboard");
        // setAuth(null as any);
        // history.push("/login");
        // initOpenApolloClient();
      });
    }
    init();
  }, []);
  return (
    <AuthContext.Provider value={user}>
      {isAppLoading === true ? <LoadingFullPageComponent /> : children}
    </AuthContext.Provider>
  );
}
