/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Form submit button used accrossed the app
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the main form submit Button
 * @author Dinuka Bandara
 * @since 1.0.0
 */
import React from 'react';
import PropsTypes from 'prop-types';
import Button from '@mui/material/Button';

import styles from './FormSubmitButton.module.css';

type ComponentProps = {
  text?: string;
  children?: any;
  onClick?: () => void;
  disable?: boolean;
};

/**
 * Main Green color button component of the app
 * @callback onClickCB
 * @param {Object} props            component props
 * @param {String} props.text       Text to display inside the button
 * @param {String} props.children   Custom JSX.Element to render as child
 * @param {onClickCB} props.onClick Fires when user click the button
 * @param {Boolean} props.disabled  Flag that determine if the button is clickable or not
 *
 * @returns JSX.Element
 */
const FormSubmitButton: React.FunctionComponent<ComponentProps> = ({
  text,
  children,
  onClick,
  disable = false,
}) => (
  <Button
    disabled={disable}
    className={styles.btnBg}
    size="small"
    onClick={() => {
      if (!disable && onClick) {
        onClick();
      }
    }}
  >
    <div className={styles.btnText}>
      {' '}
      {text}
    </div>
    {children != null ? (
      <div className={styles.iconText}>{children}</div>
    ) : (
      <div />
    )}
  </Button>
);

FormSubmitButton.propTypes = {
  text: PropsTypes.string,
  children: PropsTypes.oneOfType([
    PropsTypes.arrayOf(PropsTypes.node),
    PropsTypes.node,
  ]),
  onClick: PropsTypes.func,
  disable: PropsTypes.bool,
};

FormSubmitButton.defaultProps = {
  text: '',
  children: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  disable: false,
};

export default FormSubmitButton;
