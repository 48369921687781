/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import React, { useEffect, useState } from 'react';
import { Action } from '@smartlife-redux-state/common';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  child_head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

function ValueSelector(
  row: any,
  state: any,
  areas: any,
  outputs: any,
  index,
  setKeyValueHandler: any,
  setChanelHandler: any,
) {
  const capitalizedState = state && state.length > 0 && state.charAt(0).toUpperCase() + state.slice(1);
  let selectorOptions: any = [];
  if (state === 'area' || state === 'area_state') {
    selectorOptions = areas;
  } else if (state === 'output') {
    selectorOptions = outputs;
  }
  const { state_value, chanel, type } = row;
  const classes = useStyles();

  const arrowHeadAlarmActionOptionsProps = [
    {
      label: 'ARM AWAY',
      command: 'arm_away',
    },
    {
      label: 'ARM HOME',
      command: 'arm_home',
    },
    {
      label: 'PANIC',
      command: 'panic',
    },
    {
      label: 'DISARM',
      command: 'disarm',
    },
  ];

  const arrowHeadAlarmOutputOptionsProps = [
    {
      label: 'TRUE',
      command: 'true',
    },
    {
      label: 'FALSE',
      command: 'false',
    },
  ];
  // const config = ac_key_maps[state];
  return (
    <>
      {state ? (
        <>
          <Grid item xs={12} md={3}>
            <FormControl
              size="small"
              key={index}
              className={classes.formControl}
              variant="outlined"
            >
              <InputLabel>{`${capitalizedState}`}</InputLabel>
              <Select
                label={`${capitalizedState}`}
                value={chanel}
                onChange={(e: any) => {
                  setChanelHandler(index, e.target.value);
                }}
              >
                {selectorOptions.map((item, key) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={key} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            {state === 'area' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmActionOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state === 'area_state' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmOutputOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state === 'output' ? (
              <FormControl
                size="small"
                key={index}
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>{`${capitalizedState} State`}</InputLabel>
                <Select
                  label={`${capitalizedState} State`}
                  value={state_value}
                  onChange={(e: any) => {
                    setKeyValueHandler(index, e.target.value);
                  }}
                >
                  {arrowHeadAlarmOutputOptionsProps.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.command}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const ChildRow = ({
  row,
  index,
  options,
  areas,
  outputs,
  setKeyHandler,
  setDelayHandler,
  setKeyValueHandler,
  setTypeHandler,
  setChanelHandler,
  deleteChild,
  classes,
  isLast,
}: any) => (
  <Grid
    container
    spacing={2}
    key={row.index}
    style={{
      marginTop: '5px',
      padding: '5px',
    }}
  >
    <Grid item xs={12} md={3}>
      <Autocomplete
        size="small"
        onChange={(_, value) => {
          setTypeHandler(index, value || null);
        }}
        value={row.type}
        className={classes.formControl}
        id={`zone_${row.index}`}
        options={options}
        getOptionLabel={(option: any) => option}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="Chanel" variant="outlined" />}
      />
    </Grid>
    {ValueSelector(row, row.type, areas, outputs, index, setKeyValueHandler, setChanelHandler)}
    <Grid item xs={12} md={2}>
      {!isLast && (
        <TextField
          id={`delay_${row.index}`}
          type="number"
          error={row.delay !== 0 && !row.delay}
          className={classes.formControl}
          label="Delay(sec)"
          value={row.delay}
          onChange={(e: any) => {
            setDelayHandler(index, e.target.value);
          }}
          variant="outlined"
          size="small"
          // disabled={isLast}
        />
      )}
    </Grid>
    <Grid item xs={12} md={1}>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={() => deleteChild(index)} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
);

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const ExsAlarmChildChildActions: React.FC<{
  onChange: any;
  alarmType: string;
  action: Action;
}> = ({ onChange, alarmType, action }) => {
  const classes = useStyles();

  const [options, setOptions] = useState<any[]>([]);

  const [settings, setSettings] = useState<any>();
  const [areas, setAreas] = useState<any[]>([]);
  const [outputs, setOutputs] = useState<any[]>([]);

  const [child, setChild] = useState<any[]>(
    action.variable && isJson(action.variable) ? JSON.parse(action.variable) : [],
  );
  // console.log(remoteKeyMap);

  const AddNewChild = () => {
    const newArray = [
      ...child,
      {
        delay: 1,
        index: Date.now(),
      },
    ];
    setChild(newArray);
  };

  const deleteChild = (index) => {
    const newArray = [...child];
    newArray.splice(index, 1);
    setChild(newArray);
  };

  const setChanelHandler = (index: number, value: any) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      chanel: value,
    };
    setChild(newArray);
  };

  const setTypeHandler = (index: number, type: any) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      type,
    };
    setChild(newArray);
  };

  const setKeyHandler = (index, key) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state: key,
    };
    setChild(newArray);
  };

  const setKeyValueHandler = (index, key_value) => {
    console.log(index, key_value);
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state_value: key_value,
    };
    setChild(newArray);
  };

  const setDelayHandler = (index, val) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      delay: parseInt(val, 10) < 1 ? 0 : parseInt(val, 10),
    };
    setChild(newArray);
  };

  useEffect(() => {
    const { device } = action;
    if (!device) return;

    const EXS_STATES = {
      AREA: 'area',
      POWER: 'output',
    };
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const keys = Object.keys(EXS_STATES).map((key) => EXS_STATES[key]);

    setOptions(keys || []);
    if (action && action.device && action.device.settings) {
      const actionSettings = JSON.parse(action.device.settings);
      setSettings(actionSettings);

      if (actionSettings.areas && actionSettings.areas.length > 0) {
        setAreas(actionSettings.areas);
      }

      if (actionSettings.outputs && actionSettings.outputs.length > 0) {
        setOutputs(actionSettings.outputs);
      }
    }
  }, [action]);

  useEffect(() => {
    if (child) {
      let value;
      if (child.length === 0) {
        value = JSON.stringify([]);
      } else {
        value = JSON.stringify([...child]);
        onChange('ESX', '', {
          field: value,
          type: 'esx',
        });
      }
    }
  }, [child]);

  return (
    <Grid
      container
      style={{
        border: '1px solid #D9DFE0',
        padding: '10px',
        borderRadius: '10px',
        marginTop: '10px',
      }}
    >
      <Box className={classes.child_head}>
        <Typography>ESX Alarm Child Actions</Typography>
        <IconButton onClick={() => AddNewChild()} size="large">
          <AddIcon />
        </IconButton>
      </Box>

      {child
        && child.map((row, index) => (
          <ChildRow
            row={row}
            index={index}
            options={options}
            areas={areas}
            outputs={outputs}
            setKeyHandler={setKeyHandler}
            setDelayHandler={setDelayHandler}
            setKeyValueHandler={setKeyValueHandler}
            setTypeHandler={setTypeHandler}
            setChanelHandler={setChanelHandler}
            deleteChild={deleteChild}
            classes={classes}
            isLast={child.length === index + 1}
          />
        ))}
    </Grid>
  );
};

export default ExsAlarmChildChildActions;
