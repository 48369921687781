/* eslint-disable max-len */
import firebase from 'firebase';
// import { initializeApp, getApps } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
import { LocalStorageKeys } from '../../../config/config';
import { LoginWithEmailLinkActionCodeSettings } from '../../../config/constants';
import { appConsoleError, appConsoleLog, AppUtils } from '../../../utility/appUtils';
import { ApolloFunctions } from '../../Apollo/ApolloFunctions';
import { UserAuthFunctions } from './UserAuthFunctions';

class FirebaseAuthFunctions {
  // const { createUserEntity } = useUserApolloFunctions();

  static initFirebaseApp = () => {
    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      REACT_APP_FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    // if (getApps().length === 0) {
    //   initializeApp(config);
    // }
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }
  };

  /**
   * @returns Promise
   */
  static getIdToken = async (): Promise<string> => {
    try {
      // const auth = getAuth();
      // const user = auth.currentUser;
      // return user.getIdToken();
      const token = (await firebase?.auth()?.currentUser?.getIdToken()) || '';
      return token;
    } catch (err) {
      throw new Error('User not signed in');
    }
  };

  /**
   * @param  {string} name
   * @param  {string} email
   * @param  {string} pw
   */
  static CraeteFirebaseUser = async (
    name: string,
    email: string,
    pw: string,
    userContactNo1: string,
    userAddress: string,
    userCountry: string,
    userCountryCode: string,
    userCity: string,
  ) => {
    await firebase.auth().createUserWithEmailAndPassword(email, pw);
    FirebaseAuthFunctions.SendEmailVerification();
    const idToken = await FirebaseAuthFunctions.getIdToken();
    const tempClinet = ApolloFunctions.createAuthApolloClient(idToken);
    await UserAuthFunctions.updateUserProfile(tempClinet, name, `${userContactNo1}`, userAddress, userCountry, userCountryCode, userCity);
    if (!AppUtils.areWeTestingWithJest()) {
      window.location.href = '/app';
    }
  };

  // eslint-disable-next-line max-len
  static SendPasswordChangeLink = async (email: string) => firebase.auth().sendPasswordResetEmail(email);

  static SendEmailVerification = async () => {
    const v = firebase.auth().currentUser?.emailVerified;
    if (!v) {
      return firebase.auth().currentUser?.sendEmailVerification();
    }
    return 'Already Verified';
  };

  /**
   * @param  {string} email
   * @param  {string} pw
   */
  static LoginUser = async (email: string, pw: string) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, pw);
      if (!AppUtils.areWeTestingWithJest()) {
        window.location.href = '/app';
      }
    } catch (err) {
      appConsoleLog(err);
      throw err;
    }
  };

  static LoginWithEmailSendLink = async (email: string) => {
    try {
      await firebase.auth().sendSignInLinkToEmail(email, LoginWithEmailLinkActionCodeSettings);
      window.localStorage.setItem(LocalStorageKeys.LoginWithEmailLink, email);
    } catch (err) {
      appConsoleLog(err);
      throw err;
    }
  };

  static CompleteLoginWithEmailSendLink = async (url: string, email: string) => {
    try {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        await firebase.auth().signInWithEmailLink(email, window.location.href);
        window.localStorage.removeItem(LocalStorageKeys.LoginWithEmailLink);
        if (!AppUtils.areWeTestingWithJest()) {
          window.location.href = '/app';
        }
      } else {
        throw new Error('Link not valid');
      }
    } catch (err) {
      appConsoleLog(err);
      throw err;
    }
  };

  static LogoutCurrentUser = async () => {
    try {
      await firebase.auth().signOut();
    } catch (err) {
      appConsoleError(err);
    }
  };

  static getProHubFirebaseInfo = async (hubSerial: string) => {
    console.log('SENDING PRO HUB INFO REQ');
    try {
      const ref = `hubs/${hubSerial}/info`;
      const res = await firebase.database().ref(ref).once('value');
      return {
        firmware_version: res.val().firmware_version,
        online_datetime: res.val().online_datetime.seconds,
      };
    } catch (err) {
      return {
        firmware_version: '-1',
        online_datetime: 0,
      };
    }
  };

  static getProHubFirebaseData = async (hubSerial: string) => {
    try {
      const ref = `hubs/${hubSerial}/info`;
      const res = await firebase.database().ref(ref).once('value');
      return res.val();
    } catch (err) {
      return null;
    }
  };

  static updateProHubFirebaseMonitoring = async (hubSerial: string, value:boolean) => {
    try {
      const ref = `hubs/${hubSerial}/info/offline_alert_notify`;
      const res = await firebase.database().ref(ref).set(value);
      return res;
    } catch (err) {
      return null;
    }
  };
}

export default FirebaseAuthFunctions;
