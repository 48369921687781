import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box, FormControlLabel, IconButton, Switch, Tooltip,
} from '@mui/material';
import DeviceProfileCreator from 'src/containers/app/AirDeviceCreateEdit/components/DeviceProfileCreator';
import { IUserViewItem } from '../../../types/types';
import { DeviceCollectionType, ICommDevice } from '../../UVDeviceFinder/types';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { AirChannelSelector } from './AirChannelSelector';
import { SentenceCase } from '../../../../../utility/appUtils';
import uvService from '../../../service/uv.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { SensorAirChannel } from './SensorAirChannel';
import UVAirGangSelectorProfile from './UVAirGangSelectorProfile';

type ComponentProps = {
  fieldConfig: any;
  device: ICommDevice | null;
  uvItem: IUserViewItem | null;
  submitRefresh: number;
  profileProps: {
    currentSettings: any;
    deviceSettings: any;
    selectedDevice: any;
    templateId: string;
  };
  onChange: (payload: any) => void;
  onChangeProfile: (payload: any) => void;
  updateCustomFieldErrors: (error: any) => void;
  onDeviceTypeChange: (payload: any) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVAirGangSelector: React.FunctionComponent<ComponentProps> = ({
  fieldConfig,
  uvItem,
  device,
  profileProps,
  onChange,
  onChangeProfile,
  submitRefresh,
  updateCustomFieldErrors,
  onDeviceTypeChange,
}) => {
  const {
    currentSettings, deviceSettings, selectedDevice, templateId,
  } = profileProps;
  const userProfile = useContext(ApolloAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const types = Object.keys(ITEM_DEFAULTS.ITEM_CREATOR.AIR_UV_DEVICE_MAP.uv_types);
  const [selectedType, setType] = useState<string>('');

  const [allFunctions, setAllFunctions] = useState<string[]>([]);

  const [curentTypeChannels, setChannels] = useState<string[]>([]);
  const [channelFieldMap, setChannelFieldMap] = useState<any>({});
  const [extraConfigs, setExtraConfigs] = useState<any>({});

  const [deviceState, setDeviceState] = useState<any>({ id: '', state: {} });

  const getDeviceState = async (dcType: string, id: string) => {
    try {
      if (dcType === DeviceCollectionType.proDevice || deviceState.id === id) {
        return;
      }
      const res = await uvService.getDeviceState(userProfile.apollo_client, dcType, id);
      setDeviceState({ id, state: JSON.parse(res.state) });
    } catch (err: any) {
      enqueueSnackbar(err.message);
    }
  };

  useEffect(() => {
    if (device == null) {
      return;
    }
    getDeviceState(device.dc_type, device.id || '');
  }, [device]);

  useEffect(() => {
    if (Object.keys(profileProps.currentSettings).length > 0) {
      setChannelFieldMap(profileProps.currentSettings.data.fields);
    }
  }, [profileProps.currentSettings]);

  useEffect(() => {
    if (device == null) {
      return;
    }

    const devicePayload: ICommDevice = JSON.parse(JSON.stringify(device));
    if (devicePayload && devicePayload.settings) {
      if (typeof devicePayload.settings === 'string') {
        devicePayload.settings = JSON.parse(devicePayload.settings);
      }
    }

    if (devicePayload.settings.selected_functions) {
      const funcs = devicePayload.settings.selected_functions;
      setAllFunctions(Object.keys(funcs));
    }

    if (uvItem != null) {
      if (uvItem.settings.air_device_type) {
        setType(uvItem.settings.air_device_type);
        onDeviceTypeChange(uvItem.settings.air_device_type);
        setTimeout(() => {
          setChannelFieldMap(uvItem.settings.fields);
          setTimeout(() => {
            setExtraConfigs(uvItem.settings.config);
          }, 300);
        }, 300);
      }
    } else if (devicePayload && devicePayload.dc_type === DeviceCollectionType.airDevice) {
      setType(devicePayload.device_type);
      onDeviceTypeChange(devicePayload.device_type);
    }
  }, [uvItem, device]);

  useEffect(() => {
    if (!selectedType) {
      return;
    }
    const mainConfig: any = ITEM_DEFAULTS.ITEM_CREATOR.AIR_UV_DEVICE_MAP.uv_types;
    const typeConfig = mainConfig[selectedType];
    if (typeConfig.channels) {
      const channelsPayload = Object.keys(typeConfig.channels);
      setChannels(channelsPayload);

      const cfmap: any = {};
      channelsPayload.forEach((channel) => {
        cfmap[channel] = '';
      });
      setChannelFieldMap(cfmap);
      setExtraConfigs({});
    } else {
      setChannels([]);
      setChannelFieldMap({});
      setExtraConfigs({});
    }
  }, [selectedType]);

  const setChannelFunction = (channel: string, field: string) => {
    setChannelFieldMap((oldValue: any) => {
      const ov = { ...oldValue };
      ov[channel] = field;
      return ov;
    });
  };

  const setChannelProperties = (channel: string, data: any, clean = false) => {
    setExtraConfigs((oldValue: any) => {
      const ov = { ...oldValue };

      if (clean) {
        ov[channel] = { ...data };
      } else {
        ov[channel] = { ...ov[channel], ...data };
      }
      return ov;
    });
  };
  const setExtraProperties = (code: string, value: any) => {
    setExtraConfigs((oldValue: any) => {
      const ov = { ...oldValue };
      ov[code] = value;
      return ov;
    });
  };

  const uvItemFields: any[] = [];
  if (curentTypeChannels && curentTypeChannels.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < curentTypeChannels.length; i++) {
      const channel = curentTypeChannels[i];

      const mainConfig: any = ITEM_DEFAULTS.ITEM_CREATOR.AIR_UV_DEVICE_MAP.uv_types;
      const typeConfig = mainConfig[selectedType];

      const key = `${`${channel}_${i}`}`;
      if (channel === 'wind') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="wind Status Channel(wind)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'power') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Power Status Channel(power)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'control') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Control Channel(control)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'percent_control') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Slider Channel(percent_control)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'percent_state') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Slider Value Channel(percent_state)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'fan') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Fan Channel(fan)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'temp') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Temperature Channel(temp)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'mode') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Select Mode Channel(mode)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'switch') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Switch Channel(switch)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'bright_value') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Bright Value Channel(bright_value)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'color') {
        uvItemFields.push(
          <div className="col-md-6 pl-0" key={key}>
            <AirChannelSelector
              label="Color Channel(color)"
              deviceState={deviceState}
              channels={allFunctions}
              selectedChannel={channelFieldMap[channel]}
              onChange={(item) => {
                setChannelFunction(channel, item);
              }}
            />
          </div>,
        );
      } else if (channel === 'sensor_value') {
        const items = SensorAirChannel({
          setChannelFunction,
          setExtraProperties,
          updateCustomFieldErrors,
          setChannelProperties,
          channelKey: channel,
          elmntKey: key,
          deviceState,
          allFunctions,
          channelFieldMap,
          extraConfigs,
          typeConfig,
        });
        if (items.length > 0) {
          uvItemFields.push(...items);
        }
      }
    }
  }

  useEffect(() => {
    // return;
    if (isAirChannelsCustomForm) {
      if (selectedType === '') {
        updateCustomFieldErrors({
          air: { invalid: true, message: 'device type required' },
        });
        return;
      }
      let channelFill = '';
      curentTypeChannels.forEach((channel) => {
        const v = channelFieldMap[channel];
        if (!v || v === '' || v.length < 1) {
          channelFill = channel;
        }
      });
      if (channelFill !== '') {
        if (!(curentTypeChannels.includes('color') && channelFill === 'bright_value')) {
          updateCustomFieldErrors({
            air: {
              invalid: true,
              message: `${SentenceCase(channelFill.split('_').join(' '))} channel required`,
            },
          });
          return;
        }
      }
      if (selectedType === 'sensor') {
        if (!extraConfigs.sensor_value || !extraConfigs.sensor_value.type) {
          updateCustomFieldErrors({
            air: {
              invalid: true,
              message: 'Sensor value type required',
            },
          });
          return;
        }
        if (extraConfigs.sensor_value.type === 'numeric') {
          if (!extraConfigs.sensor_value || !extraConfigs.sensor_value.value_scale) {
            updateCustomFieldErrors({
              air: {
                invalid: true,
                message: 'Sensor value scale required',
              },
            });
            return;
          }
        } else if (extraConfigs.sensor_value.type === 'boolean') {
          if (!extraConfigs.sensor_value.true_label) {
            updateCustomFieldErrors({
              air: {
                invalid: true,
                message: 'Sensor True Label required',
              },
            });
            return;
          }
          if (!extraConfigs.sensor_value.false_label) {
            updateCustomFieldErrors({
              air: {
                invalid: true,
                message: 'Sensor False Label required',
              },
            });
            return;
          }
        }
      }
      updateCustomFieldErrors({
        air: { invalid: false },
      });
    }
    onChange({
      data: {
        air_device_type: selectedType,
        fields: channelFieldMap,
        config: extraConfigs,
      },
    });
  }, [curentTypeChannels, channelFieldMap, extraConfigs]);

  const [isAirChannelsCustomForm, setIsAirChannelsCustomForm] = useState(
    true, //! deviceSettings.device_template_id,
  );
  const [open, setOpen] = useState(false);

  return (
    <div className="col-md-12 pl-0 pb-0" style={{ margin: '10px 0', padding: '0 10px' }}>
      <div className="row">
        <div className="col-md-6" style={{ marginLeft: '5px' }}>
          <AirChannelSelector
            label="Device Type"
            channels={types}
            selectedChannel={selectedType}
            onChange={(item) => {
              onDeviceTypeChange(item);
              setType(item);
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
          <FormControlLabel
            control={(
              <Switch
                disabled
                checked={isAirChannelsCustomForm}
                onChange={() => setIsAirChannelsCustomForm((state) => !state)}
                // disabled={!deviceSettings.device_template_id}
              />
            )}
            label="Advanced Settings"
          />
          <Box style={{ marginLeft: 10 }}>
            <Tooltip title="Save as a profile">
              <IconButton onClick={() => setOpen(true)} disabled={!isAirChannelsCustomForm}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </div>

        {isAirChannelsCustomForm ? (
          <>
            <DeviceProfileCreator
              template={{ data: { ...currentSettings.data, config: { ...extraConfigs } } }}
              comType="uvi"
              parentType="air"
              parentProfileId={deviceSettings.device_template_id}
              parentCategory={selectedDevice.cat}
              parentSubCategory={selectedDevice.sub_cat}
              open={open}
              handleClose={() => setOpen(false)}
            />
            <div>{uvItemFields}</div>
          </>
        ) : (
          <UVAirGangSelectorProfile
            deviceSettings={deviceSettings}
            selectedType={selectedType}
            template={{ data: currentSettings.data }}
            parentProfileId={deviceSettings.device_template_id}
            parentCategory={selectedDevice.cat}
            parentSubCategory={selectedDevice.sub_cat}
            currentProfileId={templateId}
            onChangeProfile={(comProf) => {
              console.log(comProf);
              if (
                comProf
                && comProf.template_data
                && comProf.template_data.data
                && comProf.template_data.data.config
              ) {
                // eslint-disable-next-line no-debugger
                // debugger;
                // setChannelFieldMap(comProf.template_data.fields);
                setExtraConfigs(comProf.template_data.data.config);
              }
              onChangeProfile(comProf);
            }}
          />
        )}
      </div>
    </div>
  );
};
