/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef, useState } from 'react';

import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IDFField } from '../../../../../config/deviceConfig/types';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';
import { appConsoleLog, cloneObject } from '../../../../../utility/appUtils';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.field_selector;

type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  resetForm: IFormResetRef;
  onChange: (payload: any, isInitializing?: boolean) => void;
};
interface IformData {
  selected_field: string;
}
const convertToFields = (defaultMap: any, selectedField: string) => {
  const m = JSON.parse(JSON.stringify(defaultMap));
  m[`has_${selectedField}`] = true;
  appConsoleLog(m);
  return m;
};
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldFieldSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const [deb, setDeb] = useState<BehaviorSubject<{values: IformData; errors: any} | null>>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};

  const sendDefaults = (deb_: any) => {
    if (!deb_) {
      return;
    }
    const v = {
      values: {
        selected_field: props.fieldConfig.default,
      },
      errors: {},
    };
    deb_.next(v);
  };
  const [radioItems, setRadioItems] = useState<any[]>([]);

  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (props.resetForm.ref > 0 && props.resetForm.ref > refFormReset.current.ref) {
      refFormReset.current = props.resetForm;
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        const selectFieldNameKey = props.fieldConfig.field.split(':')[0];
        const values = props.resetForm.info.settings[selectFieldNameKey];

        setValue(values);
      } else {
        setValue(props.fieldConfig.default);
      }
    }
  }, [props.resetForm]);

  const [selectdValue, setValue] = useState(props.fieldConfig.default);
  const initilized = useRef(0);
  useEffect(() => {
    let radioValuesMap: any = {};
    const b = new BehaviorSubject<{values: IformData; errors: any} | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }

        const { values, errors } = v;
        const selectedMap = convertToFields(radioValuesMap, values.selected_field);
        const infoData: any = {
          settings: { ...selectedMap },
        };
        /**
         * save extra field for use for build this field selector later
         */
        const selectFieldNameKey = props.fieldConfig.field.split(':')[0];
        infoData.settings[selectFieldNameKey] = values.selected_field;

        const fields = cloneObject(props.fieldConfig.field.split(':'));

        fields.shift();
        fields.forEach((element) => {
          console.log(element, values.selected_field);
          if (element !== values.selected_field) {
            infoData.settings[values.selected_field] = null;
          }
        });

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    if (props.fieldConfig != null) {
      const values = props.fieldConfig.field.split(':');

      values.shift();

      const labels = props.fieldConfig.labels.split('|').map((a: string) => a.trim());
      if (labels.length === values.length) {
        const defaultMap: any = {};
        const items = values.map((v: any, i: number) => {
          defaultMap[`has_${v}`] = false;

          return (
            <FormControlLabel
              value={v}
              // eslint-disable-next-line react/no-array-index-key
              key={v + i}
              control={<Radio color="primary" />}
              label={labels[i]}
              labelPlacement="start"
            />
          );
        });
        radioValuesMap = defaultMap;
        setRadioItems(items);
        // send once default data

        sendDefaults(b);
      }
    }

    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  const isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;

  return isEditable === true ? (
    <div>
      <div className="text-fields-flex">
        <div>
          <FormControl
            component="fieldset"
            onChange={(event: any) => {
              setValue(event.target.value);
              deb?.next({
                values: { selected_field: event.target.value },
                errors: {},
              });
            }}
          >
            {/* <FormLabel component="legend">labelPlacement</FormLabel> */}

            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue={props.fieldConfig?.default}
              value={selectdValue}
            >
              {radioItems}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};
