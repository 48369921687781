import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

type ComponentProps = {
  dialogOpen: boolean;
  dialogCloseEvent: (flag:any) => void;
  title: string;
  text: string;
};

const Transition = React.forwardRef((
  props: TransitionProps & {children: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

// eslint-disable-next-line import/prefer-default-export
export const UVConfirmDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
  title,
  text,
}) => (
  <Dialog
    open={dialogOpen}
    TransitionComponent={Transition}
    keepMounted
    onClose={() => {
      dialogCloseEvent(false);
    }}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          dialogCloseEvent(true);
        }}
        color="primary"
      >
        <DoneIcon fontSize="large" />
      </Button>
      <Button
        onClick={() => {
          dialogCloseEvent(false);
        }}
        color="primary"
      >
        <CloseIcon fontSize="large" />
      </Button>
    </DialogActions>
  </Dialog>
);
