/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useContext } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CofirmDialog } from 'src/containers/app/LogicsEngine/components/ui/Confirm';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { FillButton } from 'src/containers/app/LogicsEngine/components/ui/FillButton';

const useStyle = makeStyles({
  header: {
    // paddingTop: "0",
    paddingBottom: '0',
  },
  content: {
    paddingTop: 0,
  },
  title: {
    color: '#1E575E',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  sub_title: {
    color: '#048696',
  },
  desc: {
    color: '#7F8FA4',
  },
});

export enum RestoreSessionStatus {
  created = 'i',
  started = 's',
  closed = 'c',
  done = 'd',
  error = 'e',
}

const RestoreSessionStatusChip = ({ status }) => {
  if (status === RestoreSessionStatus.created) {
    return <Chip variant="outlined" color="primary" label="DRAFT" size="small" />;
  }

  if (status === RestoreSessionStatus.error) {
    return <Chip variant="outlined" color="error" label="ERROR" size="small" />;
  }

  if (status === RestoreSessionStatus.done) {
    return <Chip variant="outlined" color="success" label="COMPLETED" size="small" />;
  }

  if (status === RestoreSessionStatus.started) {
    return <Chip variant="outlined" color="success" label="IN PROGRESS..." size="small" />;
  }

  return <Chip variant="outlined" color="success" label="UNKNOWN..." size="small" />;
};

const SessionCard: React.FC<{
  title: string;
  date: number;
  status: RestoreSessionStatus;
  sessionId: string;
  refetch: () => void;
}> = ({
  title, date, sessionId, refetch, status,
}) => {
  const navgiate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [shouldDeleteSession, setShouldDeleteSession] = useState(false);
  const [startRestoring, setStartRestoring] = useState(false);

  const selectedProject = useContext(ProjectContext);
  const auth = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function deleteSession(sessionId: string) {
    setShouldDeleteSession(false);
    try {
      enqueueSnackbar('Start Deleting ...', { variant: 'info' });
      const url = '/rest/backups/v2/restore/clone/session/delete';
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          session_id: sessionId,
          project_id: selectedProject.selected_project.id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        },
      );
      refetch();
      enqueueSnackbar('Session Deleted Successfully', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  const styles = useStyle();
  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        className={styles.header}
        action={(
          <>
            <IconButton aria-controls="backup-opt-menu" onClick={handleClick} size="large">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="session-opt-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setStartRestoring(true);
                  // TODO: navigate to restore ui
                }}
              >
                Continue Restoring
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setShouldDeleteSession(true);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}
        // title={<Typography className={styles.title}>{sessionId}</Typography>}
        subheader={(
          <Typography className={styles.sub_title}>
            {moment.unix(date).format('Do MMM YYYY')}
          </Typography>
        )}
      />
      <CardContent className={styles.content}>
        {/* <Typography className={styles.title}>Backup Name</Typography>
        <Typography className={styles.sub_title}>2020.08.23</Typography> */}
        <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
        <RestoreSessionStatusChip status={status} />
        <Typography className={styles.desc}>{title}</Typography>
        {
          status !== RestoreSessionStatus.done && (
          <FillButton
            onClick={() => {
              setStartRestoring(true);
            }}
            variant="secondary"
          >
            Go to session
          </FillButton>
          )
      }
      </CardContent>
      <CofirmDialog
        dialogOpen={shouldDeleteSession}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteSession(sessionId);
          } else {
            setShouldDeleteSession(false);
          }
        }}
        title="Confirm to delete the session ?"
        text="It will delete the session and its progress. you will not able to redo it!"
      />
      <CofirmDialog
        dialogOpen={startRestoring}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            navgiate(
              `/app/backup-restore/restore-sessions/restoring?projectId=${selectedProject.selected_project.id}&sessionId=${sessionId}`,
            );

            // eslint-disable-next-line max-len
            // `/app/backup-restore/restore-sessions/pre-screen?projectId=${selectedProject.selected_project.id}&sessionId=${sessionId}`,
          } else {
            setStartRestoring(false);
          }
        }}
        title="Confirm"
        text="Are you sure to continue restoring ? "
      />
    </Card>
  );
};

export default SessionCard;
