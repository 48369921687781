/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ArrowForward } from '@mui/icons-material';
import styles from './AuthPage.module.css';

import { APP_TEXT } from '../../../utility/AppTexts/DefaultAppText.utils';
import { appConsoleLog } from '../../../utility/appUtils';

type ComponentProps = {
  children: any;
};
// eslint-disable-next-line import/prefer-default-export
export const AuthPage: FunctionComponent<ComponentProps> = (props) => {
  const location = useLocation();
  appConsoleLog(location.pathname);
  const isSignup = location.pathname.includes('signup');
  return (
    <div>
      <div className={styles.bg}>
        {/* Web */}
        <div className={styles.bg2}>
          <div className={`${styles.bg1} container`}>
            <div className={`${styles.authContainer} row`}>
              <div className={styles.mainBg}>
                <div className="col-lg-12 ">
                  <div className="col-lg-4">
                    <div className="col-lg-12">
                      <div className={styles.logoWithName} />
                    </div>
                    <div className="col-lg-12 pt-2">
                      <div className={styles.titleDarkText}>{APP_TEXT.auth.SMARTLIFE_PRO}</div>
                    </div>
                    <div className="col-lg-12">
                      <div className={styles.titleLightText}>
                        {APP_TEXT.auth.WELCOME_TO_SMARTLIFE_PRO}
                        {' '}
                        <br />
                        {APP_TEXT.auth.ADMIN_PANEL}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={styles.createAccount}>
                      <div className={styles.accountCreate}>
                        <div className={styles.accountText}>
                          {isSignup ? (
                            <>
                              <div className={styles.notAccount}>
                                {APP_TEXT.auth.ALREADY_HAVE_AN_ACCOUNT}
                              </div>
                              <div className={styles.account}>{APP_TEXT.auth.LOGIN_NOW}</div>
                            </>
                          ) : (
                            <>
                              <div className={styles.notAccount}>Don’t have an account?</div>
                              <div className={styles.account}>Create an Account</div>
                            </>
                          )}
                        </div>
                        <div>
                          <Link to={isSignup ? '/login' : '/signup'}>
                            <Fab color="primary" className={styles.circleBtn}>
                              <ArrowBack />
                            </Fab>
                          </Link>
                        </div>
                      </div>
                      <div className={`${styles.rightSection} col-lg-7`}>{props.children}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile */}
        <div className={styles.bg3}>
          <div className={`${styles.mobileCon} col-lg-12`}>
            {props.children}
            <hr />
            {isSignup ? (
              <div className={styles.createAccContMobi}>
                <p>{APP_TEXT.auth.ALREADY_HAVE_AN_ACCOUNT}</p>
                <span>{APP_TEXT.auth.LOGIN_NOW}</span>
                <br />
                <Link to="/login">
                  <Fab color="primary" className={styles.circleBtn}>
                    <ArrowForward />
                  </Fab>
                </Link>
              </div>
            ) : (
              <div className={styles.createAccContMobi}>
                <p>{APP_TEXT.auth.DONT_HAVE_AN_ACCOUNT}</p>
                <span>{APP_TEXT.auth.CREATE_AN_ACCOUNT}</span>
                <br />
                <Link to="/signup">
                  <Fab color="primary" className={styles.circleBtn}>
                    <ArrowBack className={styles.backArrow} />
                  </Fab>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
