import UiComponents from './ui';
import { Component, EventManager, iComponent } from '../shared/Plugin';
import { iEnodeIntegration } from './enode.service';
import { CiDevice } from '../shared/services/cloud-integration.service';

export class ReactComponents extends Component implements iComponent {
  private integration: iEnodeIntegration;

  private uiComponents: UiComponents;

  constructor(arg: EventManager, integration: iEnodeIntegration) {
    super();
    this.eventManager = arg;
    this.integration = integration;
    this.uiComponents = new UiComponents();
  }

  getDeviceCardUi = (rnProps: {
    category: any;
    id?: string;
    cintId?: string;
    name: string;
    bgColor?: string;
    cursor?: string;
    key?: any;
  }) => {
    const ReactComponent = this.uiComponents.GetDeviceCardUiComponent(rnProps);
    return ReactComponent;
  };

  getDcCreateUi = (rnProps: {
    backup_view: boolean;
    onSuccessCreate?: (type: 'shelly', created: any) => void;
  }) => {
    return this.uiComponents.GetDcCreateComponent(this.integration, rnProps);
  }

  getDcSettingUi = (rnProps: {
    dcName: string;
    dcId: string;
    projectId: string;
  }) => {
   return this.uiComponents.GetDeviceContainerSettingsForm(this.integration, rnProps); 
  }

  getUserViewCreateExtraFields = (rnProps: {
    selectedDevice: (CiDevice & {dc_type: string});
    uvItem: object;
    appendSettings: (payload: any) => void;
  }) => {
    const ReactComponent =  this.uiComponents.GetUserViewCreateExtraFields(this.integration, rnProps);
    return ReactComponent;
  }
}
