import React, {
  useEffect, useRef, useState,
} from 'react';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ICustomValueLabel } from './types';

type ComponentProps = {
  initialValueMap: ICustomValueLabel[];
  onChange: (payload:any) => void;
  updateCustomFieldErrors: (error: any) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const AirValueCustomMap: React.FunctionComponent<ComponentProps> = ({
  initialValueMap,
  onChange,
  updateCustomFieldErrors,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [valueLabels, SetValueLabels] = useState<ICustomValueLabel[]>([]);

  const mountedInitVal = useRef(0);
  const checkInvalidMapValue = (listData: ICustomValueLabel[]) => {
    let invalid = false;
    if (listData && listData.length > 0) {
      const invalidKV = listData.findIndex((v) => (
        !v || !v.label || v.label.length < 1 || !v.value || v.value.length < 1
      ));
      if (invalidKV !== -1) {
        invalid = true;
      }
    } else {
      invalid = true;
    }

    if (invalid) {
      updateCustomFieldErrors({
        air_values_map: {
          invalid: true,
          message: 'Values map required',
        },
      });
    } else {
      updateCustomFieldErrors({ air_values_map: { invalid: false } });
    }
    return invalid;
  };
  useEffect(() => {
    if (initialValueMap && JSON.stringify(initialValueMap) !== JSON.stringify(valueLabels)) {
      SetValueLabels(initialValueMap);

      if (initialValueMap.length === 0) {
        updateCustomFieldErrors({
          air_values_map: {
            invalid: true,
            message: 'Values map required',
          },
        });
      }
    }
    mountedInitVal.current = 1;
  }, [initialValueMap]);

  useEffect(() => {
    // if(mountedInitVal.current == 0) return;
    if (valueLabels.length > 0) {
      const foundInvalid = valueLabels.find((item) => {
        if (!item.label || item.label.length === 0 || !item.value || item.value.length === 0) {
          return true;
        }
        return false;
      });
      if (foundInvalid) {
        updateCustomFieldErrors({
          air_values_map: {
            invalid: true,
            message: 'Values map required',
          },
        });
      } else {
        onChange(valueLabels);
        updateCustomFieldErrors({ air_values_map: { invalid: false } });
      }
    } else {
      updateCustomFieldErrors({
        air_values_map: {
          invalid: true,
          message: 'Values map required',
        },
      });
    }
  }, [valueLabels]);

  const addRow = () => {
    const cList = [...valueLabels];
    cList.push({
      value: '',
      label: '',
      type: 'equal',
      id: `avcm${Date.now()}`,
    });
    SetValueLabels(cList);
  };

  const deleteByIndex = (index: number) => {
    const cList = [...valueLabels];
    cList.splice(index, 1);
    SetValueLabels(cList);
  };
  const updateItemByIndex = (index: number, code: string, text: string) => {
    const cList = [...valueLabels];
    const item: any = cList[index];
    if (item) {
      item[`${code}`] = text;
      cList[index] = item;
      SetValueLabels(cList);
    }
  };

  const [mapForm, setMapForm] = useState({ label: '', value: '' });

  const onAddNewMapItem = () => {
    if (
      mapForm.label
      && mapForm.label.length > 0
      && mapForm.value
      && mapForm.value.length > 0
    ) {
      SetValueLabels((oldList) => [
        {
          value: mapForm.value,
          label: mapForm.label,
          type: 'equal',
          id: `avcm${Date.now()}`,
        },
        ...oldList,
      ]);

      setMapForm({ label: '', value: '' });
    } else {
      updateCustomFieldErrors({
        air_values_map: {
          invalid: true,
          message: 'Values map required',
        },
      });
      enqueueSnackbar('Cannot add with empty fields', { variant: 'error' });
    }
  };

  return (
    <div>
      {/* <div className={"row"}>
        <Button onClick={addRow} color="primary">
          <AddIcon /> Add Value
        </Button>
      </div> */}
      <div className="row">
        <div className="col-md-5">
          <TextField
            className="dynamic-ui-form-control-full-width"
            label="Value"
            size="small"
            value={mapForm.value}
            onChange={(e) => {
              setMapForm((old) => ({ ...old, value: e.target.value }));
            }}
          />
        </div>
        <div className="col-md-5">
          <TextField
            className="dynamic-ui-form-control-full-width"
            label="Label"
            size="small"
            value={mapForm.label}
            onChange={(e) => {
              setMapForm((old) => ({ ...old, label: e.target.value }));
            }}
          />
        </div>
        <div className="col-md-2" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button onClick={onAddNewMapItem} color="primary">
            <AddIcon />
            {' '}
            Add
          </Button>
        </div>
      </div>
      <Box height={10} />
      {valueLabels.map((item, index) => (
        <div className="row" key={item.id}>
          <div className="col-md-5">
            <TextField
              className="dynamic-ui-form-control-full-width"
              label="Value"
              size="small"
              variant="outlined"
              defaultValue={item.value}
              onChange={(e) => {
                updateItemByIndex(index, 'value', e.target.value);
              }}
            />
          </div>
          <div className="col-md-5">
            <TextField
              className="dynamic-ui-form-control-full-width"
              label="Label"
              size="small"
              variant="outlined"
              defaultValue={item.label}
              onChange={(e) => {
                updateItemByIndex(index, 'label', e.target.value);
              }}
            />
          </div>
          <div className="col-md-2">
            <IconButton
              color="secondary"
              aria-label="delete"
              onClick={() => {
                deleteByIndex(index);
              }}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};
