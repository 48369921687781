// eslint-disable-next-line import/prefer-default-export
export const airCountries = [
  {
    abbr: 'CN',
    chinese: '中国',
    chinese_tw: '中國',
    code: '86',
    english: 'China',
  },
  {
    abbr: 'KZ',
    chinese: '哈萨克斯坦',
    chinese_tw: '哈薩克斯坦',
    code: '7',
    english: 'Kazakstan',
  },
  {
    abbr: 'RU',
    chinese: '俄罗斯',
    chinese_tw: '俄羅斯',
    code: '7',
    english: 'Russia',
  },
  {
    abbr: 'EG',
    chinese: '埃及',
    chinese_tw: '埃及',
    code: '20',
    english: 'Egypt',
  },
  {
    abbr: 'ZA',
    chinese: '南非',
    chinese_tw: '南非',
    code: '27',
    english: 'South Africa',
  },
  {
    abbr: 'GR',
    chinese: '希腊',
    chinese_tw: '希臘',
    code: '30',
    english: 'Greece',
  },
  {
    abbr: 'NL',
    chinese: '荷兰',
    chinese_tw: '荷蘭',
    code: '31',
    english: 'Netherlands',
  },
  {
    abbr: 'BE',
    chinese: '比利时',
    chinese_tw: '比利時',
    code: '32',
    english: 'Belgium',
  },
  {
    abbr: 'FR',
    chinese: '法国',
    chinese_tw: '法國',
    code: '33',
    english: 'France',
  },
  {
    abbr: 'ES',
    chinese: '西班牙',
    chinese_tw: '西班牙',
    code: '34',
    english: 'Spain',
  },
  {
    abbr: 'HU',
    chinese: '匈牙利',
    chinese_tw: '匈牙利',
    code: '36',
    english: 'Hungary',
  },
  {
    abbr: 'IT',
    chinese: '意大利',
    chinese_tw: '意大利',
    code: '39',
    english: 'Italy',
  },
  {
    abbr: 'RO',
    chinese: '罗马尼亚',
    chinese_tw: '羅馬尼亞',
    code: '40',
    english: 'Romania',
  },
  {
    abbr: 'CH',
    chinese: '瑞士',
    chinese_tw: '瑞士',
    code: '41',
    english: 'Switzerland',
  },
  {
    abbr: 'AT',
    chinese: '奥地利',
    chinese_tw: '奧地利',
    code: '43',
    english: 'Austria',
  },
  {
    abbr: 'IM',
    chinese: '马恩岛',
    chinese_tw: '馬恩島',
    code: '44',
    english: 'Isle of Man',
  },
  {
    abbr: 'GB',
    chinese: '英国',
    chinese_tw: '英國',
    code: '44',
    english: 'United Kingdom',
  },
  {
    abbr: 'DK',
    chinese: '丹麦',
    chinese_tw: '丹麥',
    code: '45',
    english: 'Denmark',
  },
  {
    abbr: 'SE',
    chinese: '瑞典',
    chinese_tw: '瑞典',
    code: '46',
    english: 'Sweden',
  },
  {
    abbr: 'NO',
    chinese: '挪威',
    chinese_tw: '挪威',
    code: '47',
    english: 'Norway',
  },
  {
    abbr: 'PL',
    chinese: '波兰',
    chinese_tw: '波蘭',
    code: '48',
    english: 'Poland',
  },
  {
    abbr: 'DE',
    chinese: '德国',
    chinese_tw: '德國',
    code: '49',
    english: 'Germany',
  },
  {
    abbr: 'AU',
    chinese: '澳大利亚',
    chinese_tw: '澳大利亞',
    code: '61',
    english: 'Australia',
  },
  {
    abbr: 'SG',
    chinese: '新加坡',
    chinese_tw: '新加坡',
    code: '65',
    english: 'Singapore',
  },
  {
    abbr: 'TR',
    chinese: '土耳其',
    chinese_tw: '土耳其',
    code: '90',
    english: 'Turkey',
  },
  {
    abbr: 'PK',
    chinese: '巴基斯坦',
    chinese_tw: '巴基斯坦',
    code: '92',
    english: 'Pakistan',
  },
  {
    abbr: 'AF',
    chinese: '阿富汗',
    chinese_tw: '阿富汗',
    code: '93',
    english: 'Afghanistan',
  },
  {
    abbr: 'LK',
    chinese: '斯里兰卡',
    chinese_tw: '斯裏蘭卡',
    code: '94',
    english: 'Sri Lanka',
  },
  {
    abbr: 'IR',
    chinese: '伊朗',
    chinese_tw: '伊朗',
    code: '98',
    english: 'Iran',
  },
  {
    abbr: 'MA',
    chinese: '摩洛哥',
    chinese_tw: '摩洛哥',
    code: '212',
    english: 'Morocco',
  },
  {
    abbr: 'DZ',
    chinese: '阿尔及利亚',
    chinese_tw: '阿爾及利亞',
    code: '213',
    english: 'Algeria',
  },
  {
    abbr: 'TN',
    chinese: '突尼斯',
    chinese_tw: '突尼斯',
    code: '216',
    english: 'Tunisia',
  },
  {
    abbr: 'LY',
    chinese: '利比亚',
    chinese_tw: '利比亞',
    code: '218',
    english: 'Libya',
  },
  {
    abbr: 'GM',
    chinese: '冈比亚',
    chinese_tw: '岡比亞',
    code: '220',
    english: 'Gambia',
  },
  {
    abbr: 'SN',
    chinese: '塞内加尔',
    chinese_tw: '塞內加爾',
    code: '221',
    english: 'Senegal',
  },
  {
    abbr: 'MR',
    chinese: '毛利塔尼亚',
    chinese_tw: '毛利塔尼亞',
    code: '222',
    english: 'Mauritania',
  },
  {
    abbr: 'ML',
    chinese: '马里',
    chinese_tw: '馬裏',
    code: '223',
    english: 'Mali',
  },
  {
    abbr: 'GN',
    chinese: '几内亚',
    chinese_tw: '幾內亞',
    code: '224',
    english: 'Guinea',
  },
  {
    abbr: 'CI',
    chinese: '科特迪瓦',
    chinese_tw: '科特迪瓦',
    code: '225',
    english: 'Ivory Coast',
  },
  {
    abbr: 'BF',
    chinese: '布基纳法索',
    chinese_tw: '布基納法索',
    code: '226',
    english: 'Burkina-faso',
  },
  {
    abbr: 'NE',
    chinese: '尼日尔',
    chinese_tw: '尼日爾',
    code: '227',
    english: 'Niger',
  },
  {
    abbr: 'TG',
    chinese: '多哥',
    chinese_tw: '多哥',
    code: '228',
    english: 'Togo',
  },
  {
    abbr: 'BJ',
    chinese: '贝宁',
    chinese_tw: '貝甯',
    code: '229',
    english: 'Benin',
  },
  {
    abbr: 'MU',
    chinese: '毛里求斯',
    chinese_tw: '毛裏求斯',
    code: '230',
    english: 'Mauritius',
  },
  {
    abbr: 'LR',
    chinese: '利比里亚',
    chinese_tw: '利比裏亞',
    code: '231',
    english: 'Liberia',
  },
  {
    abbr: 'SL',
    chinese: '塞拉利昂',
    chinese_tw: '塞拉利昂',
    code: '232',
    english: 'Sierra Leone',
  },
  {
    abbr: 'GH',
    chinese: '加纳',
    chinese_tw: '加納',
    code: '233',
    english: 'Ghana',
  },
  {
    abbr: 'NG',
    chinese: '尼日利亚',
    chinese_tw: '尼日利亞',
    code: '234',
    english: 'Nigeria',
  },
  {
    abbr: 'TD',
    chinese: '乍得',
    chinese_tw: '乍得',
    code: '235',
    english: 'Chad',
  },
  {
    abbr: 'CF',
    chinese: '中非共和国',
    chinese_tw: '中非共和國',
    code: '236',
    english: 'Central African Republic',
  },
  {
    abbr: 'CM',
    chinese: '喀麦隆',
    chinese_tw: '喀麥隆',
    code: '237',
    english: 'Cameroon',
  },
  {
    abbr: 'CV',
    chinese: '佛得角',
    chinese_tw: '佛得角',
    code: '238',
    english: 'Cape Verde',
  },
  {
    abbr: 'GQ',
    chinese: '赤道几内亚',
    chinese_tw: '赤道幾內亞',
    code: '240',
    english: 'Equatorial Guinea',
  },
  {
    abbr: 'GA',
    chinese: '加蓬',
    chinese_tw: '加蓬',
    code: '241',
    english: 'Gabon',
  },
  {
    abbr: 'CG',
    chinese: '刚果(布)',
    chinese_tw: '剛果(布)',
    code: '242',
    english: 'Congo - Brazzaville',
  },
  {
    abbr: 'CD',
    chinese: '刚果(金)',
    chinese_tw: '剛果(金)',
    code: '243',
    english: 'Congo - Kinshasa',
  },
  {
    abbr: 'AO',
    chinese: '安哥拉',
    chinese_tw: '安哥拉',
    code: '244',
    english: 'Angola',
  },
  {
    abbr: 'SD',
    chinese: '苏丹',
    chinese_tw: '蘇丹',
    code: '249',
    english: 'Sudan',
  },
  {
    abbr: 'RW',
    chinese: '卢旺达',
    chinese_tw: '盧旺達',
    code: '250',
    english: 'Rwanda',
  },
  {
    abbr: 'ET',
    chinese: '埃塞俄比亚',
    chinese_tw: '埃塞俄比亞',
    code: '251',
    english: 'Ethiopia',
  },
  {
    abbr: 'SO',
    chinese: '索马里',
    chinese_tw: '索馬裏',
    code: '252',
    english: 'Somalia',
  },
  {
    abbr: 'DJ',
    chinese: '吉布提',
    chinese_tw: '吉布提',
    code: '253',
    english: 'Djibouti',
  },
  {
    abbr: 'KE',
    chinese: '肯尼亚',
    chinese_tw: '肯尼亞',
    code: '254',
    english: 'Kenya',
  },
  {
    abbr: 'TZ',
    chinese: '坦桑尼亚',
    chinese_tw: '坦桑尼亞',
    code: '255',
    english: 'Tanzania',
  },
  {
    abbr: 'UG',
    chinese: '乌干达',
    chinese_tw: '烏幹達',
    code: '256',
    english: 'Uganda',
  },
  {
    abbr: 'BI',
    chinese: '布隆迪',
    chinese_tw: '布隆迪',
    code: '257',
    english: 'Burundi',
  },
  {
    abbr: 'MZ',
    chinese: '莫桑比克',
    chinese_tw: '莫桑比克',
    code: '258',
    english: 'Mozambique',
  },
  {
    abbr: 'ZM',
    chinese: '赞比亚',
    chinese_tw: '贊比亞',
    code: '260',
    english: 'Zambia',
  },
  {
    abbr: 'MG',
    chinese: '马达加斯加',
    chinese_tw: '馬達加斯加',
    code: '261',
    english: 'Madagascar',
  },
  {
    abbr: 'RE',
    chinese: '留尼旺',
    chinese_tw: '留尼旺',
    code: '262',
    english: 'Reunion',
  },
  {
    abbr: 'ZW',
    chinese: '津巴布韦',
    chinese_tw: '津巴布韋',
    code: '263',
    english: 'Zimbabwe',
  },
  {
    abbr: 'NA',
    chinese: '纳米比亚',
    chinese_tw: '納米比亞',
    code: '264',
    english: 'Namibia',
  },
  {
    abbr: 'MW',
    chinese: '马拉维',
    chinese_tw: '馬拉維',
    code: '265',
    english: 'Malawi',
  },
  {
    abbr: 'LS',
    chinese: '莱索托',
    chinese_tw: '萊索托',
    code: '266',
    english: 'Lesotho',
  },
  {
    abbr: 'BW',
    chinese: '博茨瓦纳',
    chinese_tw: '博茨瓦納',
    code: '267',
    english: 'Botswana',
  },
  {
    abbr: 'SZ',
    chinese: '斯威士兰',
    chinese_tw: '斯威士蘭',
    code: '268',
    english: 'Swaziland',
  },
  {
    abbr: 'KM',
    chinese: '科摩罗',
    chinese_tw: '科摩羅',
    code: '269',
    english: 'Comoros',
  },
  {
    abbr: 'ER',
    chinese: '厄立特里亚',
    chinese_tw: '厄立特裏亞',
    code: '291',
    english: 'Eritrea',
  },
  {
    abbr: 'GL',
    chinese: '格陵兰',
    chinese_tw: '格陵蘭',
    code: '299',
    english: 'Greenland',
  },
  {
    abbr: 'PT',
    chinese: '葡萄牙',
    chinese_tw: '葡萄牙',
    code: '351',
    english: 'Portugal',
  },
  {
    abbr: 'LU',
    chinese: '卢森堡',
    chinese_tw: '盧森堡',
    code: '352',
    english: 'Luxembourg',
  },
  {
    abbr: 'IE',
    chinese: '爱尔兰',
    chinese_tw: '愛爾蘭',
    code: '353',
    english: 'Ireland',
  },
  {
    abbr: 'IS',
    chinese: '冰岛',
    chinese_tw: '冰島',
    code: '354',
    english: 'Iceland',
  },
  {
    abbr: 'AL',
    chinese: '阿尔巴尼亚',
    chinese_tw: '阿爾巴尼亞',
    code: '355',
    english: 'Albania',
  },
  {
    abbr: 'MT',
    chinese: '马耳他',
    chinese_tw: '馬耳他',
    code: '356',
    english: 'Malta',
  },
  {
    abbr: 'CY',
    chinese: '塞浦路斯',
    chinese_tw: '塞浦路斯',
    code: '357',
    english: 'Cyprus',
  },
  {
    abbr: 'FI',
    chinese: '芬兰',
    chinese_tw: '芬蘭',
    code: '358',
    english: 'Finland',
  },
  {
    abbr: 'BG',
    chinese: '保加利亚',
    chinese_tw: '保加利亞',
    code: '359',
    english: 'Bulgaria',
  },
  {
    abbr: 'LT',
    chinese: '立陶宛',
    chinese_tw: '立陶宛',
    code: '370',
    english: 'Lithuania',
  },
  {
    abbr: 'LV',
    chinese: '拉脱维亚',
    chinese_tw: '拉脫維亞',
    code: '371',
    english: 'Latvia',
  },
  {
    abbr: 'EE',
    chinese: '爱沙尼亚',
    chinese_tw: '愛沙尼亞',
    code: '372',
    english: 'Estonia',
  },
  {
    abbr: 'MD',
    chinese: '摩尔多瓦',
    chinese_tw: '摩爾多瓦',
    code: '373',
    english: 'Moldova',
  },
  {
    abbr: 'AM',
    chinese: '亚美尼亚',
    chinese_tw: '亞美尼亞',
    code: '374',
    english: 'Armenia',
  },
  {
    abbr: 'BY',
    chinese: '白俄罗斯',
    chinese_tw: '白俄羅斯',
    code: '375',
    english: 'Belarus',
  },
  {
    abbr: 'MC',
    chinese: '摩纳哥',
    chinese_tw: '摩納哥',
    code: '377',
    english: 'Monaco',
  },
  {
    abbr: 'SM',
    chinese: '圣马力诺',
    chinese_tw: '聖馬力諾',
    code: '378',
    english: 'San Marino',
  },
  {
    abbr: 'VA',
    chinese: '梵蒂冈城',
    chinese_tw: '梵蒂岡城',
    code: '379',
    english: 'Vatican City',
  },
  {
    abbr: 'UA',
    chinese: '乌克兰',
    chinese_tw: '烏克蘭',
    code: '380',
    english: 'Ukraine',
  },
  {
    abbr: 'RS',
    chinese: '塞尔维亚',
    chinese_tw: '塞爾維亞',
    code: '381',
    english: 'Serbia',
  },
  {
    abbr: 'ME',
    chinese: '黑山共和国',
    chinese_tw: '黑山共和國',
    code: '382',
    english: 'Montenegro',
  },
  {
    abbr: 'HR',
    chinese: '克罗地亚',
    chinese_tw: '克羅地亞',
    code: '385',
    english: 'Croatia',
  },
  {
    abbr: 'SI',
    chinese: '斯洛文尼亚',
    chinese_tw: '斯洛文尼亞',
    code: '386',
    english: 'Slovenia',
  },
  {
    abbr: 'BA',
    chinese: '波斯尼亚和黑塞哥维那',
    chinese_tw: '波斯尼亞和黑塞哥維那',
    code: '387',
    english: 'Bosnia and Herzegovina',
  },
  {
    abbr: 'MK',
    chinese: '马其顿',
    chinese_tw: '馬其頓',
    code: '389',
    english: 'Macedonia',
  },
  {
    abbr: 'CZ',
    chinese: '捷克共和国',
    chinese_tw: '捷克共和國',
    code: '420',
    english: 'Czech Republic',
  },
  {
    abbr: 'SK',
    chinese: '斯洛伐克',
    chinese_tw: '斯洛伐克',
    code: '421',
    english: 'Slovakia',
  },
  {
    abbr: 'BZ',
    chinese: '伯利兹',
    chinese_tw: '伯利茲',
    code: '501',
    english: 'Belize',
  },
  {
    abbr: 'SV',
    chinese: '萨尔瓦多',
    chinese_tw: '薩爾瓦多',
    code: '503',
    english: 'EI Salvador',
  },
  {
    abbr: 'HN',
    chinese: '洪都拉斯',
    chinese_tw: '洪都拉斯',
    code: '504',
    english: 'Honduras',
  },
  {
    abbr: 'NI',
    chinese: '尼加拉瓜',
    chinese_tw: '尼加拉瓜',
    code: '505',
    english: 'Nicaragua',
  },
  {
    abbr: 'CR',
    chinese: '哥斯达黎加',
    chinese_tw: '哥斯達黎加',
    code: '506',
    english: 'Costa Rica',
  },
  {
    abbr: 'PA',
    chinese: '巴拿马',
    chinese_tw: '巴拿馬',
    code: '507',
    english: 'Panama',
  },
  {
    abbr: 'HT',
    chinese: '海地',
    chinese_tw: '海地',
    code: '509',
    english: 'Haiti',
  },
  {
    abbr: 'GY',
    chinese: '圭亚那',
    chinese_tw: '圭亞那',
    code: '592',
    english: 'Guyana',
  },
  {
    abbr: 'BN',
    chinese: '文莱',
    chinese_tw: '文萊',
    code: '673',
    english: 'Brunei',
  },
  {
    abbr: 'TO',
    chinese: '汤加',
    chinese_tw: '湯加',
    code: '676',
    english: 'Tonga',
  },
  {
    abbr: 'FJ',
    chinese: '斐济',
    chinese_tw: '斐濟',
    code: '679',
    english: 'Fiji',
  },
  {
    abbr: 'PF',
    chinese: '法属玻利尼西亚',
    chinese_tw: '法屬玻利尼西亞',
    code: '689',
    english: 'French Polynesia',
  },
  {
    abbr: 'MH',
    chinese: '马绍尔群岛共和国',
    chinese_tw: '馬紹爾群島共和國',
    code: '692',
    english: 'The Republic Of The Marshall Islands',
  },
  {
    abbr: 'KP',
    chinese: '朝鲜',
    chinese_tw: '朝鮮',
    code: '850',
    english: 'North Korea',
  },
  {
    abbr: 'KH',
    chinese: '柬埔寨',
    chinese_tw: '柬埔寨',
    code: '855',
    english: 'Cambodia',
  },
  {
    abbr: 'LA',
    chinese: '老挝',
    chinese_tw: '老撾',
    code: '856',
    english: 'Laos',
  },
  {
    abbr: 'BD',
    chinese: '孟加拉国',
    chinese_tw: '孟加拉國',
    code: '880',
    english: 'Bangladesh',
  },
  {
    abbr: 'MV',
    chinese: '马尔代夫',
    chinese_tw: '馬爾代夫',
    code: '960',
    english: 'Maldives',
  },
  {
    abbr: 'LB',
    chinese: '黎巴嫩',
    chinese_tw: '黎巴嫩',
    code: '961',
    english: 'Lebanon',
  },
  {
    abbr: 'JO',
    chinese: '约旦',
    chinese_tw: '約旦',
    code: '962',
    english: 'Jordan',
  },
  {
    abbr: 'SY',
    chinese: '叙利亚',
    chinese_tw: '敘利亞',
    code: '963',
    english: 'Syria',
  },
  {
    abbr: 'IQ',
    chinese: '伊拉克',
    chinese_tw: '伊拉克',
    code: '964',
    english: 'Iraq',
  },
  {
    abbr: 'KW',
    chinese: '科威特',
    chinese_tw: '科威特',
    code: '965',
    english: 'Kuwait',
  },
  {
    abbr: 'SA',
    chinese: '沙特阿拉伯',
    chinese_tw: '沙特阿拉伯',
    code: '966',
    english: 'Saudi Arabia',
  },
  {
    abbr: 'YE',
    chinese: '也门',
    chinese_tw: '也門',
    code: '967',
    english: 'Yemen',
  },
  {
    abbr: 'OM',
    chinese: '阿曼',
    chinese_tw: '阿曼',
    code: '968',
    english: 'Oman',
  },
  {
    abbr: 'AE',
    chinese: '阿拉伯联合酋长国',
    chinese_tw: '阿拉伯聯合酋長國',
    code: '971',
    english: 'United Arab Emirates',
  },
  {
    abbr: 'IL',
    chinese: '以色列',
    chinese_tw: '以色列',
    code: '972',
    english: 'Israel',
  },
  {
    abbr: 'BH',
    chinese: '巴林',
    chinese_tw: '巴林',
    code: '973',
    english: 'Bahrain',
  },
  {
    abbr: 'QA',
    chinese: '卡塔尔',
    chinese_tw: '卡塔爾',
    code: '974',
    english: 'Qatar',
  },
  {
    abbr: 'BT',
    chinese: '不丹',
    chinese_tw: '不丹',
    code: '975',
    english: 'Bhutan',
  },
  {
    abbr: 'MN',
    chinese: '蒙古',
    chinese_tw: '蒙古',
    code: '976',
    english: 'Mongolia',
  },
  {
    abbr: 'NP',
    chinese: '尼泊尔',
    chinese_tw: '尼泊爾',
    code: '977',
    english: 'Nepal',
  },
  {
    abbr: 'TJ',
    chinese: '塔吉克斯坦',
    chinese_tw: '塔吉克斯坦',
    code: '992',
    english: 'Tajikstan',
  },
  {
    abbr: 'TM',
    chinese: '土库曼斯坦',
    chinese_tw: '土庫曼斯坦',
    code: '993',
    english: 'Turkmenistan',
  },
  {
    abbr: 'AZ',
    chinese: '阿塞拜疆',
    chinese_tw: '阿塞拜疆',
    code: '994',
    english: 'Azerbaijan',
  },
  {
    abbr: 'GE',
    chinese: '格鲁吉亚',
    chinese_tw: '格魯吉亞',
    code: '995',
    english: 'Georgia',
  },
  {
    abbr: 'KG',
    chinese: '吉尔吉斯斯坦',
    chinese_tw: '吉爾吉斯斯坦',
    code: '996',
    english: 'Kyrgyzstan',
  },
  {
    abbr: 'UZ',
    chinese: '乌兹别克斯坦',
    chinese_tw: '烏茲別克斯坦',
    code: '998',
    english: 'Uzbekistan',
  },
  {
    abbr: 'BB',
    chinese: '巴巴多斯',
    chinese_tw: '巴巴多斯',
    code: '1246',
    english: 'Barbados',
  },
  {
    abbr: 'AG',
    chinese: '安提瓜和巴布达',
    chinese_tw: '安提瓜和巴布達',
    code: '1268',
    english: 'AG Antigua And Barbuda',
  },
  {
    abbr: 'VG',
    chinese: '英属维京群岛',
    chinese_tw: '英屬維京群島',
    code: '1284',
    english: 'British Virgin Islands',
  },
  {
    abbr: 'VI',
    chinese: '美属维尔京群岛',
    chinese_tw: '美屬維爾京群島',
    code: '1340',
    english: 'U.S. Virgin Islands',
  },
  {
    abbr: 'KY',
    chinese: '开曼群岛',
    chinese_tw: '開曼群島',
    code: '1345',
    english: 'Cayman Islands',
  },
  {
    abbr: 'DO',
    chinese: '多米尼加共和国',
    chinese_tw: '多米尼加共和國',
    code: '1809',
    english: 'Dominican Republic',
  },
  {
    abbr: 'DO',
    chinese: '多米尼加共和国',
    chinese_tw: '多米尼加共和國',
    code: '1829',
    english: 'Dominican Republic',
  },
  {
    abbr: 'DO',
    chinese: '多米尼加共和国',
    chinese_tw: '多米尼加共和國',
    code: '1849',
    english: 'Dominican Republic',
  },
  {
    abbr: 'TT',
    chinese: '特立尼达和多巴哥',
    chinese_tw: '特立尼達和多巴哥',
    code: '1868',
    english: 'Trinidad and Tobago',
  },
  {
    abbr: 'JM',
    chinese: '牙买加',
    chinese_tw: '牙買加',
    code: '1876',
    english: 'Jamaica',
  },
  {
    abbr: 'IN',
    chinese: '印度',
    chinese_tw: '印度',
    code: '91',
    english: 'India',
  },
  {
    abbr: 'CA',
    chinese: '加拿大',
    chinese_tw: '加拿大',
    code: '1',
    english: 'Canada',
  },
  {
    abbr: 'US',
    chinese: '美国',
    chinese_tw: '美國',
    code: '1',
    english: 'The United States',
  },
  {
    abbr: 'PE',
    chinese: '秘鲁',
    chinese_tw: '秘魯',
    code: '51',
    english: 'Peru',
  },
  {
    abbr: 'MX',
    chinese: '墨西哥',
    chinese_tw: '墨西哥',
    code: '52',
    english: 'Mexico',
  },
  {
    abbr: 'AR',
    chinese: '阿根廷',
    chinese_tw: '阿根廷',
    code: '54',
    english: 'Argentina',
  },
  {
    abbr: 'BR',
    chinese: '巴西',
    chinese_tw: '巴西',
    code: '55',
    english: 'Brazil',
  },
  {
    abbr: 'CL',
    chinese: '智利',
    chinese_tw: '智利',
    code: '56',
    english: 'Chile',
  },
  {
    abbr: 'CO',
    chinese: '哥伦比亚',
    chinese_tw: '哥倫比亞',
    code: '57',
    english: 'Colombia',
  },
  {
    abbr: 'VE',
    chinese: '委内瑞拉',
    chinese_tw: '委內瑞拉',
    code: '58',
    english: 'Venezuela',
  },
  {
    abbr: 'MY',
    chinese: '马来西亚',
    chinese_tw: '馬來西亞',
    code: '60',
    english: 'Malaysia',
  },
  {
    abbr: 'ID',
    chinese: '印度尼西亚',
    chinese_tw: '印度尼西亞',
    code: '62',
    english: 'Indonesia',
  },
  {
    abbr: 'PH',
    chinese: '菲律宾',
    chinese_tw: '菲律賓',
    code: '63',
    english: 'Philippines',
  },
  {
    abbr: 'NZ',
    chinese: '新西兰',
    chinese_tw: '新西蘭',
    code: '64',
    english: 'New Zealand',
  },
  {
    abbr: 'TH',
    chinese: '泰国',
    chinese_tw: '泰國',
    code: '66',
    english: 'Thailand',
  },
  {
    abbr: 'JP',
    chinese: '日本',
    chinese_tw: '日本',
    code: '81',
    english: 'Japan',
  },
  {
    abbr: 'KR',
    chinese: '韩国',
    chinese_tw: '韓國',
    code: '82',
    english: 'Korea',
  },
  {
    abbr: 'VN',
    chinese: '越南',
    chinese_tw: '越南',
    code: '84',
    english: 'Vietnam',
  },
  {
    abbr: 'MM',
    chinese: '缅甸',
    chinese_tw: '緬甸',
    code: '95',
    english: 'Myanmar(Burma)',
  },
  {
    abbr: 'GT',
    chinese: '危地马拉',
    chinese_tw: '危地馬拉',
    code: '502',
    english: 'Guatemala',
  },
  {
    abbr: 'BO',
    chinese: '玻利维亚',
    chinese_tw: '玻利維亞',
    code: '591',
    english: 'Bolivia',
  },
  {
    abbr: 'EC',
    chinese: '厄瓜多尔',
    chinese_tw: '厄瓜多爾',
    code: '593',
    english: 'Ecuador',
  },
  {
    abbr: 'PY',
    chinese: '巴拉圭',
    chinese_tw: '巴拉圭',
    code: '595',
    english: 'Paraguay',
  },
  {
    abbr: 'SR',
    chinese: '苏里南',
    chinese_tw: '蘇裏南',
    code: '597',
    english: 'Suriname',
  },
  {
    abbr: 'UY',
    chinese: '乌拉圭',
    chinese_tw: '烏拉圭',
    code: '598',
    english: 'Uruguay',
  },
  {
    abbr: 'HK',
    chinese: '中国香港特别行政区',
    chinese_tw: '中國香港特別行政區',
    code: '852',
    english: 'Hongkong SAR China',
  },
  {
    abbr: 'MO',
    chinese: '中国澳门特别行政区',
    chinese_tw: '中國澳門特別行政區',
    code: '853',
    english: 'Macao SAR China',
  },
  {
    abbr: 'TW',
    chinese: '中国台湾',
    chinese_tw: '台灣地區',
    code: '886',
    english: 'Taiwan China',
  },
  {
    abbr: 'PR',
    chinese: '波多黎各',
    chinese_tw: '波多黎各',
    code: '1787',
    english: 'Puerto Rico',
  },
  {
    abbr: 'CW',
    chinese: '库拉索',
    chinese_tw: '库拉索',
    code: '5999',
    english: 'Curacao',
  },
];
