/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Listing All available rules/logics created and filters for
 * filtering them, button for create new logics
 *
 * @author Maduka Dilshan
 */
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useContext, useEffect } from 'react';
import { Box, Typography, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { DefaultButton } from './components/ui/DefaultButton';
import Layout from '../../../components/layout/Layout';
import { PageHeader } from '../../../components/layout/PageHeader';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import { AuthContext } from '../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import RuleListTable from './components/RulesListTable';
import { CronSelector } from './components/board-usage/components/triggers/shared/CronSelector/CronSelector';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
});
const RuleList = () => {
  const styles = useStyle();
  const navgiate = useNavigate();
  const projectId = useQueryString('projectId') as string;

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>Manage Logics</Typography>
          <DefaultButton
            onClick={() => {
              navgiate(`/app/logics/create?projectId=${projectId}`);
            }}
          >
            <AddIcon />
            Create A Logic
          </DefaultButton>
        </Box>
      </PageHeader>
      <RuleListTable />
    </Layout>
  );
};

export default RuleList;
