import CloudIntegration from '../shared/services/cloud-integration.service';

interface EnodeUrlResponse {
  result: {
    url: string;
  };
  status: string;
}

export interface iEnodeIntegration extends CloudIntegration {
  getEnodeUrl: (dcId: string, vender: string, redirectUrl: string) => Promise<EnodeUrlResponse>;
  syncDevices: (dcId: string) => Promise<any>;
}

class EnodeIntegration extends CloudIntegration implements iEnodeIntegration {
  public async getEnodeUrl(dcId: string, vender: string, redirectUrl: string) {
    const res = await this.axiosClient.post('/cloud-int/enode/user/v1/create-redirect-url', {
      dc_id: dcId,
      vendor_type: vender,
      redirect_url: redirectUrl,
    });

    return res.data as EnodeUrlResponse;
  }

  public async syncDevices(dcId: string) {
    const res = await this.axiosClient.post('/cloud-int/enode/user/v1/sync-devices', {
      dc_id: dcId,
      sync_mode: "sync_all_ops"
    });
  }
}

export default EnodeIntegration;
