/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl, FormControlLabel, Switch, TextField,
} from '@mui/material';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IDFField } from '../../../../../config/deviceConfig/types';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';
import { appConsoleLog } from '../../../../../utility/appUtils';
import utils from '../../utils/utils';
import { DeviceCollectionType } from '../../../../../components/UserViewComponents/UIItems/UVDeviceFinder/types';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.text_input;

type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  deviceInfo: any;
  resetForm: IFormResetRef;
  onChange: (payload: any, isInitializing?: boolean) => void;
};
interface IformData {
  text: number;
}

// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldTextInput: React.FunctionComponent<ComponentProps> = (props) => {
  const [deb, setDeb] = useState<BehaviorSubject<{values: IformData; errors: any} | null>>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};

  const sendDefaults = () => {
    if (!props.fieldConfig || !deb) {
      return;
    }
    deb.next({
      values: {
        text: props.fieldConfig.default,
      },
      errors: {},
    });
  };
  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        const values = props.resetForm.info.settings[props.fieldConfig.field];
        formRef.current.setValues({
          text: values,
        });
      } else {
        formRef.current.resetForm();
      }
    }
  }, [props.resetForm]);

  const initilized = useRef(0);
  useEffect(() => {
    const b = new BehaviorSubject<{values: IformData; errors: any} | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }

        const { values, errors } = v;
        const infoData: any = {
          settings: {},
        };
        infoData.settings[props.fieldConfig.field] = values.text;

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    sendDefaults();
    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  const formRef = useRef<any>();
  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);
  let isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;
  if (isEditable) {
    if (props.deviceInfo.settings[`has_${props.fieldConfig.field}`] === false) {
      isEditable = false;
    }
  }
  let maxLen = 10;
  let uiLen = 2;
  let isRequired = true;
  const extraValues = props.fieldConfig.extra.split(',').map((v) => +v);
  if (extraValues.length === 1) {
    maxLen = extraValues[0];
  } else if (extraValues.length === 3) {
    maxLen = extraValues[0];
    isRequired = extraValues[1] === 1;
    uiLen = extraValues[2];
  }
  let validator: any = {};
  if (isRequired) {
    validator = {
      text: Yup.string().required('Required').max(maxLen),
    };
  } else {
    validator = {
      text: Yup.string().max(maxLen),
    };
  }
  return isEditable === true ? (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={{
          text: props.fieldConfig.default,
        }}
        validationSchema={Yup.object().shape(validator)}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values, submitForm,
        }) => {
          deb?.next({
            values,
            errors,
          });
          return (
            <form>
              <div className="row">
                <div className={`col-md-${uiLen}`}>
                  <TextField
                    className="dynamic-ui-form-control-full-width mt-8"
                    error={Boolean(touched.text && errors.text)}
                    helperText={touched.text && errors.text}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.text}
                    name="text"
                    label={props.fieldConfig.labels}
                    size="small"
                    variant="outlined"
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
