import {createContext, useMemo, useReducer} from 'react';
import useRestoreHelpers from '../hooks/useRestoreHelpers';

const initialState = {
  processing: false,
  lvar: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  prod: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  aird: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  cintd: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  uv: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  lgc: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  lv: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
  uvi: {
    donePercentage: 0,
    totalItems: 0,
    totalRemItems: 0,
  },
};

export const CloneStepSummaryContext = createContext({
  ...initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: (payload: any) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: (sessionId: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reCheckStats: (
    sessionId: string,
    type: 'lvar' | 'prod' | 'aird' | 'cintd' | 'uv' | 'lgc' | 'lv' | 'uvi'
  ) => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const CloneStepSummaryProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {fetchSession, fethSummary} = useRestoreHelpers();
  const init = async (sessionId: string) => {
    try {
      dispatch({
        type: 'UPDATE',
        payload: {
          processing: true,
        },
      });

      const totalSummary: {
        [key: string]: {
          donePercentage: number;
          totalItems: number;
          totalRemItems: number;
        };
      } = {};

      const sum = await fethSummary(sessionId);

      if (
        sum &&
        sum.result &&
        sum.result.result_counts &&
        Object.keys(sum.result.result_counts).length > 0
      ) {
        Object.keys(sum.result.result_counts).map(typeKey => {
          const result = sum.result.result_counts[typeKey];
          totalSummary[typeKey] = {
            donePercentage:
              result.counts_total == 0
                ? 0
                : Math.round((result.counts_done * 100) / result.counts_total),
            totalItems: result.counts_total,
            totalRemItems: result.counts_pending,
          };
        });
      } else {
        // Fallback
        const types = ['lvar', 'prod', 'aird', 'cintd', 'uv', 'lgc', 'lv', 'uvi'];
        for (let i = 0; i < types.length; i++) {
          const type = types[i];
          // eslint-disable-next-line no-await-in-loop
          const summary = await fetchSession(sessionId, type, false, true);

          if (summary) {
            totalSummary[type] = {
              ...summary,
            };
          }
        }
      }

      if (Object.keys(totalSummary).length > 0) {
        console.log('______________', totalSummary);
        dispatch({
          type: 'UPDATE',
          payload: totalSummary,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({
        type: 'UPDATE',
        payload: {
          processing: false,
        },
      });
    }
  };

  const reCheckStats = async (
    sessionId: string,
    step: 'lvar' | 'prod' | 'aird' | 'cintd' | 'uv' | 'lgc' | 'uvi'
  ) => {
    try {
      if (!step || !sessionId) return;
      dispatch({
        type: 'UPDATE',
        payload: {
          processing: true,
        },
      });
      const summary = await fetchSession(sessionId, step, false, true);

      if (!summary) return;
      console.log('_________________________', {
        ...state,
        [step]: {
          ...summary,
        },
      });
      dispatch({
        type: 'UPDATE',
        payload: {
          ...state,
          [step]: {
            ...summary,
          },
        },
      });
    } catch (err) {
      console.log('error', err);
    } finally {
      dispatch({
        type: 'UPDATE',
        payload: {
          processing: false,
        },
      });
    }
  };

  const values = useMemo(
    () => ({
      ...state,
      dispatch,
      init,
      reCheckStats,
    }),
    [state]
  );

  return (
    <CloneStepSummaryContext.Provider value={values as any}>
      {props.children}
    </CloneStepSummaryContext.Provider>
  );
};
