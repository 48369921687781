/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { appConsoleLog } from '../../utility/appUtils';
import { PRO_DEVICE_DELETE, PRO_DEVICE_GET_QUERY, PRO_DEVICE_SEARCH_QUERY } from './graphql';

const proDeviceGetGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PRO_DEVICE_GET_QUERY,
    variables: {
      item_id,
    },
    fetchPolicy: 'network-only',
  });
  return res.data.proDeviceGet.proDevice;
};

const proDeviceSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  prodc_id: string,
  shadow_type: string = '',
  cat: string = '',
  sub_cat: string = '',
  device_type: string = '',
  device_status: number = -1,
  tags: any = [],
  system_tags: any = [],
  keyword: string = '',
  limit: number = 100,
  skip: number = 0,
): Promise<any> => {
  appConsoleLog(
    project_id,
    prodc_id,
    shadow_type,
    cat,
    sub_cat,
    device_type,
    device_status,
    tags,
    system_tags,
    keyword,
    limit,
    skip,
  );
  const res = await apolloClient.query({
    query: PRO_DEVICE_SEARCH_QUERY,
    variables: {
      project_id,
      prodc_id,
      shadow_type,
      cat,
      sub_cat,
      device_type,
      device_status: -1,
      tags: [],
      system_tags: [],
      keyword,
      limit,
      skip,
    },
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const proDeviceDeleteGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: PRO_DEVICE_DELETE,
    variables: {
      item_id,
    },
  });
  return res.data;
};

export { proDeviceGetGraphQL, proDeviceSearchGraphQL, proDeviceDeleteGraphQL };
