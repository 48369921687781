/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
  Box, Grid, InputAdornment, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { CodeSharp } from '@mui/icons-material';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './ListDeviceContainers.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { DeviceContainerCard } from '../../../../components/shared/DeviceContainerCard/DeviceContainerCard';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import airIcon from '../../../../assets/images/icons/dc-air.png';
import hubIcon from '../../../../assets/images/icons/dc-hub.png';
import variableIcon from '../../../../assets/images/icons/dc-variable.png';
import { dcSearchGraphQL } from '../../../../services/device-container/device-container.service';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import { appConsoleLog } from '../../../../utility/appUtils';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  contentRootGrid: {
    flex: 1,
  },
});

export const ListDeviceContainersComponent: React.FunctionComponent<any> = () => {
  const matStyle = useStyle();

  const userProfile = useContext(ApolloAuthContext);
  const selectedProjectID = useQueryString('projectId') as string;

  const navgiate = useNavigate();

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [dcResultCount, dcResultCountSet] = useState(0);
  const [dcList, setDCList] = useState([]);
  const [dcKeyword, dcSet] = useState('');

  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const projectCheck = () => {
    if (!selectedProjectID) {
      navigateRoute('/app');
    }
  };

  useEffect(() => {
    projectCheck();
    dcSearchGraphQL(userProfile?.apollo_client, selectedProjectID, -1, -1, dcKeyword, 100, 0)
      .then((res) => {
        // dcResultCountSet(res.dcSearch.result ? res.dcSearch.result.length : 0);
        // setDCList(res.dcSearch.result ? res.dcSearch.result : 0);

        const resCount = res.dcSearch.result ? res.dcSearch.result.length : 0;
        const dcListRes = res.dcSearch.result ? res.dcSearch.result : [];

        pluginHook.commonIntegrationService.getCloudDeviceCollections({
          project_id: selectedProjectID,
          skip: 0,
          limit: 100,
        }).then((data) => {
          dcResultCountSet(resCount + data.result.resultCount || 0);
          setDCList([...dcListRes, ...data.result.result]);
        }).catch((err: any) => {
          setDCList([...dcListRes]);
        });
      })
      .catch((err) => {
        appConsoleLog(err);
        // alert("Something went wrong!")
      });
  }, []);

  const onDcSearch = (keyword: string) => {
    projectCheck();
    dcSet(keyword);
    dcSearchGraphQL(userProfile?.apollo_client, selectedProjectID, -1, -1, keyword, 100, 0)
      .then((res) => {
        const resCount = res.dcSearch.result ? res.dcSearch.result.length : 0;
        const dcListRes = res.dcSearch.result ? res.dcSearch.result : [];

        pluginHook.commonIntegrationService.getCloudDeviceCollections({
          project_id: selectedProjectID,
          skip: 0,
          limit: 100,
        }).then((data) => {
          dcResultCountSet(resCount + data.result.resultCount || 0);
          setDCList([...dcListRes, ...data.result.result]);
        });
      })
      .catch((err) => {
        appConsoleLog(err);
        // alert("Something went wrong!")
      });
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={matStyle.headerRoot}>
          <Typography className={matStyle.headerTitle}>
            {APP_TEXT.device_container.All_TITLE}
            {' '}
            {'  '}
            {' '}
            <span style={{ opacity: 0.4 }}>{dcResultCount}</span>
            <span style={{ opacity: 0.4, fontSize: '14px' }}>
              {dcResultCount > 1 ? ' containers' : ' container'}
            </span>
          </Typography>
          <Box>
            <DefaultButton
              disabled={loading}
              onClick={() => navigateRoute(`/app/device-container/create?projectId=${selectedProjectID}`)}
            >
              <AddIcon />
              {APP_TEXT.device_container.ADD_DEVICE_CONTAINER}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={matStyle.contentRoot}>
        <Box>
          <SearchBox>
            <TextField
              id="input-with-icon-textfield"
              size="small"
              placeholder={APP_TEXT.device_container.SEARCH_DC}
              onKeyUp={(event: any) => {
                onDcSearch(event.target.value);
              }}
              style={{
                width: '100%',
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchBox>
        </Box>
        <Box height={10} />
        <Box className={matStyle.contentRootGrid}>
          <Grid container spacing={2}>
            {dcResultCount > 0 ? (
              <>
                {dcList.map((dc: any) => (
                  <Grid item md={3} sm={6} xs={12} key={dc.id}>
                    <DeviceContainerCard
                      id={dc.id}
                      title={dc.name ? dc.name : 'N/A'}
                      type={dc.dc_cat || dc.cint_type}
                      isCloudIntegration={dc.cint_type || dc.cint_status}
                      // eslint-disable-next-line no-nested-ternary
                      img={dc.dc_cat === 2 ? airIcon : dc.dc_type === 9 ? variableIcon : hubIcon}
                      isDemo={dc.dc_type === 8} // this will ignored when isCloudIntegration true
                      isVariable={dc.dc_type === 9} // this will ignored when isCloudIntegration true
                      refreshList={() => {
                        onDcSearch('');
                      }}
                      onClick={() => {
                        let url = '';
                        if (dc.cint_type) {
                          url = encodeURI(`/app/device-container/cloud-integration?projectId=${dc.project_id}&dcId=${
                            dc.id
                          }&name=${dc.name ? dc.name : 'N/A'}&cint_type=${dc.cint_type}`);
                        } else if (dc.dc_cat === 2) {
                          url = encodeURI(`/app/device-container/air?projectId=${dc.project_id}&dcId=${
                            dc.id
                          }&name=${dc.name ? dc.name : 'N/A'}`);
                        } else {
                          url = encodeURI(`/app/device-container/pro?projectId=${dc.project_id}&dcId=${
                            dc.id
                          }&name=${dc.name ? dc.name : 'N/A'}`);
                        }
                        navigateRoute(url);
                      }}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <Grid item md={12}>
                <h2 className="custom-link1">{APP_TEXT.device_container.DC_NOT_AVAILABLE}</h2>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default ListDeviceContainersComponent;
