import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export function ConfirmDelete(props: any) {
  const [text, setText] = React.useState('');

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setText('');
    props.setOpen(false);
  };

  useEffect(() => {
    props.setOpen(props.open);
  }, [props.open]);

  const deleteAction = (itemId: any) => {
    if (text !== props.name) {
      return false;
    }
    setLoading(true);
    props.delete(itemId);
    setTimeout(() => {
      handleClose();
    }, 1000);

    return true;
  };

  return (
    <Dialog open={props.open}>
      {loading ? <DialogTitle>Deleting...</DialogTitle> : <DialogTitle>{props.title}</DialogTitle>}

      <DialogContent>
        {loading && (
          <>
            <LinearProgress color="secondary" />
          </>
        )}
        <DialogContentText>
          {props.fullText ? (
            <>{props.fullText}</>
          ) : (
            <>
              {`You are about to delete the ${props.item} named`}
              ,
              {' '}
              <span style={{ fontWeight: 600 }}>{props.name}</span>
              {`. If you wish to proceed please
          type the name of the ${props.item} and click delete.`}
            </>
          )}
        </DialogContentText>

        <TextField
          onChange={(e) => setText(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label={props.itemTextBoxLabel}
          fullWidth
          variant="standard"
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => deleteAction(props.itemId)}
          disabled={text !== props.name || loading}
          variant="contained"
          color="primary"
        >
          {props.actionText ? props.actionText : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// eslint-disable-next-line import/prefer-default-export
export function ConfirmProcessMinimum(props: any) {
  const [text, setText] = React.useState('');

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setText('');
    props.setOpen(false);
  };

  useEffect(() => {
    props.setOpen(props.open);
  }, [props.open]);

  const deleteAction = () => {
    setLoading(true);
    props.onConfirm();
    setTimeout(() => {
      handleClose();
    }, 1000);

    return true;
  };

  return (
    <Dialog open={props.open}>
      {loading ? (
        <DialogTitle>Processing...</DialogTitle>
      ) : (
        <DialogTitle>{props.title}</DialogTitle>
      )}

      <DialogContent>
        {loading && (
          <>
            <LinearProgress color="secondary" />
          </>
        )}
        <DialogContentText>
          {props.fullText}
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={() => deleteAction()}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
