/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { ProjectUserViewState } from './types';

const initial_state: ProjectUserViewState = {
  permissions: null,
  views: null,
  viewsIds: [],
  meta: {
    initialized: false,
    error: null,
  },
};

export const projectUserViewsSlice = createSlice({
  name: 'projects-user-views',
  initialState: initial_state,
  reducers: {
    // TODO: add reducers
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    init: () => {},
  },
  extraReducers: {
    // TODO: add extra reducers
  },
});

export const { init } = projectUserViewsSlice.actions;
export const { reducer } = projectUserViewsSlice;
