import {
  Dialog, DialogTitle, List, ListItemText, ListItem,
} from '@mui/material';
import { DeviceCollectionSearchOutItem } from '@smartlife-redux-state/common';

const MoveDeviceDialog: React.FC<{
    open: string;
    onClose: (value?:any, templateId?:string) => void;
    containers: DeviceCollectionSearchOutItem[]
}> = ({ open, onClose, containers = [] }) => {
  const handleListItemClick = (value) => {
    onClose(value, open);
  };

  return (
    <Dialog onClose={onClose} open={open && open.length > 0}>
      <DialogTitle>Select In Location Container to Move Selected Devices</DialogTitle>
      <List>
        {containers.map((container) => {
          if (!container) return <></>;
          return (
            <ListItem button onClick={() => handleListItemClick(container)} key={container.id}>
              <ListItemText primary={container.name} />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

export default MoveDeviceDialog;
