import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Total Energy Usage',
    },
  },
};

export const LocationTotalEnergyGraph: React.FC<{
  rawData: {
    [key: string]: {
      consumption: number;
      cost: number;
    };
  };
}> = ({ rawData }) => {
  const data = useMemo(() => {
    if (!rawData || Object.keys(rawData).length == 0) return null;

    const labels = ['OffPeak (kWh)', 'Peak (kWh)'];
    const datasets = [
      {
        label: 'Total Energy Consumption',
        data: [rawData.OffPeak?.consumption, rawData.Peak?.consumption],
        backgroundColor: ['#6BCDCD', '#FCCE62'],
        borderColor: ['#6BCDCD', '#FCCE62'],
      },
    ];

    return {
      labels,
      datasets,
    };
  }, [rawData]);

  if (!data) return null;

  return <Pie options={options} data={data} />;
};
