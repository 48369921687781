/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useNavigate } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
import { ListRouts, COLORS, ListSettings } from '@smartlife/common-ui';
import ListLayout from 'src/layout/ListLayout';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';

const ListSetting = () => {
  const navigate = useNavigate();
  const projectId = useQueryStringFirst('projectId');

  const handleSuccess = (res: any, isNew: boolean) => {
    if (isNew && res && res.listview_info && res.listview_info._id) {
      navigate(`/app/list?projectId=${projectId}`);
      return;
    }
    navigate(-1);
  };

  return (
    <ListLayout>
      <ListSettings
        onCancel={(resource_removed) => {
          if (resource_removed) {
            navigate(`/app/list?projectId=${projectId}`);
          } else {
            navigate(-1);
          }
        }}
        onSuccessSubmit={handleSuccess}
      />
    </ListLayout>
  );
};

export default ListSetting;
