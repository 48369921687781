/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ListItem } from './ListItem';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { proDcTunnelCreate } from '../../../../../services/device-container/device-container.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import CreateCustomTunnel from './Dialog';

type HUB_API = {
  name: string;
  ip: string;
  port: string;
  remark: string;
};

const RemoteConnect: React.FC<{
  prodc_id: string;
  sshTunnels: any;
  hostTunnels: any;
  refetch: () => Promise<any>;
  onGenrateSuccess: () => void;
}> = ({
  prodc_id, sshTunnels, hostTunnels, onGenrateSuccess, refetch,
}) => {
  const isDefaultHubApisAvaible = sshTunnels
    ? sshTunnels.filter((item: any) => item.remark === 'ssh').length !== 0
    : false;
  const [openDialog, setOpenDialog] = useState(false);
  // const [isInit, setInit] = useState(false);

  const [hubApis, setHubApis] = useState([]);
  const [customHubApis, setCustomHubApis] = useState([]);

  const userProfile = useContext(ApolloAuthContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isDefaultHubApisAvaible) {
      // default hub apis not activated. Remove custom apis if avaible
      localStorage.removeItem(`CUSTOM_HUB_APIS_${prodc_id}`);
    }
    const hubStr = localStorage.getItem(`CUSTOM_HUB_APIS_${prodc_id}`);
    let custom_secure_hub_apis: HUB_API[] = [];

    if (hubStr) {
      custom_secure_hub_apis = JSON.parse(hubStr) as HUB_API[];
    }

    const default_secure_hub_apis = ITEM_DEFAULTS.ITEM_CREATOR.HUB_APIS;
    setHubApis(default_secure_hub_apis);
    setCustomHubApis(custom_secure_hub_apis);
    // setInit(true);
  }, [prodc_id, isDefaultHubApisAvaible]);

  const onClickGenerate = async () => {
    try {
      enqueueSnackbar('Opening ssh connections....', { variant: 'info' });
      const res = await proDcTunnelCreate(
        userProfile.apollo_client,
        prodc_id,
        'ssh',
        'tcp',
        '127.0.0.1',
        '22',
      );

      onGenrateSuccess();
      enqueueSnackbar('ssh connections generated', { variant: 'success' });
    } catch (err:any) {
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSuccess = async (values: {
    name: string;
    local_ip: string;
    port: string;
    type: string;
  }) => {
    try {
      if (values.type === 'secure') {
        if (!isDefaultHubApisAvaible) await onClickGenerate();
        // Save to localstorage
        localStorage.setItem(
          `CUSTOM_HUB_APIS_${prodc_id}`,
          JSON.stringify([...customHubApis, values]),
        );
        // update the state
        console.log('updated');
        setCustomHubApis([...customHubApis, values]);
      } else {
        enqueueSnackbar('Creating New Tunnel...', { variant: 'info' });
        const res = await proDcTunnelCreate(
          userProfile.apollo_client,
          prodc_id,
          '', // KEEP this as empty!!!
          'tcp',
          values.local_ip,
          values.port,
        );
        enqueueSnackbar('Custom tunnel created', { variant: 'success' });
        enqueueSnackbar('Loading New Tunnels...', { variant: 'info' });
        await refetch();
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    // setCustom([tunnel, ...custom]);
  };

  return (
    <div>
      <div className="row" style={{ border: '1px solid #7F8FA4', borderRadius: '5px' }}>
        <div className="col-md-12">
          <h4>TUNNELS</h4>
          <div style={{ color: '#7F8FA4' }}>
            Please copy one of the follwing command and run on your local terminal. Once the
            terminal connected to the device, click on the luanch button while terminal is alive
          </div>
          {ITEM_DEFAULTS.ITEM_CREATOR.HUB_APIS.map((item: any, i) => {
            if (item.remark !== 'custom1') {
              // eslint-disable-next-line react/no-array-index-key
              return <ListItem key={i} tunnel={sshTunnels && sshTunnels[0]} config_obj={item} />;
            }
          })}
        </div>
        <div className="col-md-12">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button
                color="primary"
                variant="contained"
                disabled={isDefaultHubApisAvaible}
                onClick={onClickGenerate}
              >
                Generate
              </Button>
            </div>
            <div>
              <Button color="primary" onClick={() => setOpenDialog(true)}>
                Create Custom Tunnel
              </Button>
            </div>
            {/* <div className="col-sm-12 col-md-2">button3</div> */}
          </div>
          <Box height={10} />
        </div>
        <CreateCustomTunnel open={openDialog} onClose={onCloseDialog} onSuccess={onSuccess} />
        {customHubApis.length > 0 && (
          <div className="col-md-12">
            <Box height={10} />
            <h4>SECURE CUSTOM TUNNELS</h4>
            {customHubApis.map((hubApi: any, index: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} tunnel={sshTunnels && sshTunnels[0]} config_obj={hubApi} />
            ))}
          </div>
        )}

        {sshTunnels && sshTunnels.filter((tunnel: any) => tunnel.remark === '').length > 0 && (
          <div className="col-md-12">
            <Box height={10} />
            <h4>CUSTOM NON-SECURE TUNNELS</h4>
            {sshTunnels
              && sshTunnels
                .filter((tunnel: any) => tunnel.remark === '')
                .map((custom_tunnel: any, index: number) => {
                  const config = hubApis.filter((item) => item.remark === 'custom1');
                  return (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      tunnel={custom_tunnel}
                      config_obj={config[0]}
                      isNonSecureTunnel
                      deletable
                      refetch={refetch}
                    />
                  );
                })}
          </div>
        )}
      </div>
      <br />
      <div className="row" style={{ border: '1px solid #7F8FA4', borderRadius: '5px' }}>
        <div className="col-md-12">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>HOSTS</h4>
            {/* <Button color="primary" onClick={() => setOpenDialog(true)}>
              Create Custom Host
            </Button> */}
          </div>

          <div style={{ color: '#7F8FA4' }}>
            Please open one of this on a new browser tab and enter login credentials to continue
          </div>
          {hostTunnels
            // eslint-disable-next-line react/no-array-index-key
            && hostTunnels.map((item: any, i: any) => <ListItem key={i} tunnel={item} isHost />)}
        </div>
      </div>
    </div>
  );
};

export default RemoteConnect;
