/* eslint-disable camelcase */
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState, useEffect } from 'react';

import { ProDevice } from '@smartlife-redux-state/common';

export enum SINGLE_VARIABLE_DEVICES {
  TOGGLE = 'toggle',
  CONTACT_CLOSURE = 'contact_closure',
  PRESS_AND_HOLD = 'press_and_hold',
  THIRDPARTY_SLIDER = 'thirdprty_slider',
  THIRDPARTY_TOGGLE = 'thirdprty_toggle',
  THIRDPARTY_CONTACT_CLOSURE = 'thirdprty_contact_closure',
  THIRDPARTY_AC_CONTROLLER = 'thirdprty_ac_controller',
}

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  between_root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const ProVariable: React.FC<{
  pro_device: ProDevice;
  selectedVariable: {field: string; type: string};
  hideTypeSelector?: boolean;
  onChange: (selectedFiled: string, type: string) => void;
}> = ({
  pro_device, selectedVariable, hideTypeSelector, onChange,
}) => {
  const classes = useStyles();
  const [isSingleVar, setIsSingleVar] = useState<boolean>(null);

  useEffect(() => {
    // determine the device type
    const { sub_cat } = pro_device;
    let found = false;
    // eslint-disable-next-line array-callback-return
    Object.keys(SINGLE_VARIABLE_DEVICES).map((key) => {
      if (SINGLE_VARIABLE_DEVICES[key] === sub_cat) {
        found = true;
      }
    });
    setIsSingleVar(found);
  }, [pro_device]);

  function renderVariableFields(isSingle: boolean) {
    const fields = [];

    if (pro_device && pro_device.cat === 'knx') {
      if (pro_device.sub_cat === 'knx_dimmer') {
        fields.push({
          label: 'Brightness',
          value: 'state/brightness',
        });
        fields.push({
          label: 'Device State',
          value: 'state/state',
        });
      } else {
        fields.push({
          label: 'Device State',
          value: 'state',
        });
      }
    } else {
      fields.push({
        label: 'Device State',
        value: 'state',
      });
    }
    console.log(pro_device);
    // if (isSingle) {
    //   fields.push({
    //     label: "Device State",
    //     value: "state",
    //   });
    // } else {
    //   //TODO find varible list and push to fields array
    //   //const settings = JSON.parse(air_device.settings);

    //   // hack
    //   fields = [];
    // }

    // deafult field set
    // if (fields.length > 0) {
    //   setSelectedField(fields[0].value);
    // }

    return (
      <FormControl size="small" variant="outlined" className={classes.formControl}>
        <InputLabel>Variable</InputLabel>
        <Select
          label="Variable"
          value={selectedVariable.field}
          onChange={(e) => {
            onChange(
              e.target.value as string,
              selectedVariable.type ? selectedVariable.type : 'String',
            );
            // setSelectedField(e.target.value as string);
          }}
          startAdornment={<InputAdornment position="start">{'State '}</InputAdornment>}
        >
          {fields.map((field, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={field.value} key={index}>
              {field.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Grid container spacing={2}>
      {isSingleVar != null && (
        <Grid item xs={12} md={8}>
          {isSingleVar ? renderVariableFields(true) : renderVariableFields(false)}
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          {/* <InputLabel>Possible Var Type</InputLabel> */}
          <Select
            disabled={hideTypeSelector}
            // label="Possible Var Type"
            value={selectedVariable.type}
            onChange={(e) => {
              onChange(
                selectedVariable.field ? selectedVariable.field : 'state',
                e.target.value as string,
              );
              // setType(e.target.value as any)
            }}
          >
            <MenuItem value="Number">Number</MenuItem>
            <MenuItem value="String">String</MenuItem>
            <MenuItem value="Boolean">Boolean</MenuItem>
          </Select>
          <FormHelperText>* Possible Variable Type</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
