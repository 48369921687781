/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ArrowForward, InfoOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { isArray } from 'lodash';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './CreateProjectProjectTypeSelector.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import CreateLocationSelector from '../../../../components/shared/CreateLocationSelector/CreateLocationSelector';
import { appConsoleLog } from '../../../../utility/appUtils';
import selectedIcon from '../../../../assets/images/icons/checkmark-selected.png';
import notSelectedIcon from '../../../../assets/images/icons/checkmark-not-selected.png';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import PageContentLayout, {
  PageConentHeader,
  PageContent,
} from '../../LogicsEngine/components/PageContentLayout';
import { FillButton } from '../../LogicsEngine/components/ui/FillButton';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  stepRoot: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    padding: '2% 0',
  },
  stepTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  stepTabTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
    marginRight: '2%',
  },
  stepTabLine: {
    height: '100%',
    position: 'relative',
    flex: '1',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderBottom: '1px solid #D9DFE0',
      height: '100%',
      width: '100%',
      transform: 'translateY(-45%)',
    },
  },
  lastTabLine: {
    flex: 1,
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'center',
    backgroun: 'black',
    border: '8px solid #D9DFE0',
    borderRadius: '50%',
    marginRight: '2%',
    color: 'var(--light-green)',
  },
  circleIcon: {
    color: 'white',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  root: {
    // height: "100%",
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    margin: 0,
    padding: '2%',
    width: '100%',
    background: 'transparent',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    margin: '3% 1%',
  },
});

export const CreateProjectProjectTypeSelectorComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  appConsoleLog('userProfile', user);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [projectType, projectTypeSet] = useState(1);
  const [projectType1Selected, projectType1Select] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = useStyle();

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    async function init() {
      const { token } = user.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v1/project-backup/point/universal-template/list`,
        {
          query: {
            limit: 20,
            skip: 0,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      console.log(res);
      setTemplates(
        res.data && res.data.result && isArray(res.data.result.result) ? res.data.result.result : [],
      );
    }
    if (user && user.access_token && user.access_token.token) init();
  }, [user]);

  const createProjectFormNav = () => {
    if (projectType1Selected) {
      navgiate('/app/create-custom-project');
      return;
    }
    if (templateSelected) {
      navgiate(`/app/create-custom-project?loc_temp_serial=${templateSelected}`);
    }
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.stepRoot}>
          <Box className={classes.stepTab}>
            <Box className={classes.iconRoot}>
              {window.location.href.indexOf('/create-project') > -1 ? (
                <CheckCircleIcon />
              ) : (
                <FiberManualRecordIcon className={classes.circleIcon} />
              )}
            </Box>
            <Typography className={classes.stepTabTitle}>
              {APP_TEXT.location.LOCATION_TYPE}
            </Typography>
            <Box className={classes.stepTabLine} />
          </Box>
          <Box className={classes.stepTab}>
            <Box className={classes.iconRoot}>
              {window.location.href.indexOf('/create-custom-project') > -1 ? (
                <CheckCircleIcon />
              ) : (
                <FiberManualRecordIcon className={classes.circleIcon} />
              )}
            </Box>
            <Typography className={classes.stepTabTitle}>
              {APP_TEXT.location.LOCATION_DETAILS}
            </Typography>
            <Box className={classes.lastTabLine} />
          </Box>
        </Box>
      </PageHeader>
      <PageContentLayout className={classes.root}>
        <Box
          style={{
            width: '90%',
            margin: '2% auto',
            background: 'white',
            padding: '2%',
            borderRadius: '10px',
          }}
        >
          <PageConentHeader>
            <Typography variant="h5" component="h2">
              {APP_TEXT.location.CHOOSE_LOCATION}
            </Typography>
          </PageConentHeader>
          <PageContent className={classes.content}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12}>
                <CreateLocationSelector
                  onClick={() => {
                    setTemplateSelected(null);
                    projectType1Select(true);
                  }}
                  title={APP_TEXT.location.TYPE1_TITLE}
                  description={APP_TEXT.location.TYPE1_DESC}
                  selected={projectType1Selected}
                  disabled={false}
                  selectedDisabled={false}
                />
              </Grid>
              {templates.map((item, index) => (
                <Grid item md={4} sm={12}>
                  <CreateLocationSelector
                    onClick={() => {
                      setTemplateSelected(item.backup_serial);
                      projectType1Select(false);
                    }}
                    title={item.name}
                    description={item.description}
                    selected={templateSelected === item.backup_serial}
                    disabled={false}
                    selectedDisabled={false}
                  />
                </Grid>
              ))}
              <Box className={classes.bottom}>
                <FillButton onClick={createProjectFormNav}>{APP_TEXT.location.CONTINUE}</FillButton>
                <Box width={20} />
                <FillButton
                  onClick={() => {
                    navgiate('/app');
                  }}
                  variant="secondary"
                >
                  Cancel
                </FillButton>
              </Box>
            </Grid>
          </PageContent>
        </Box>
      </PageContentLayout>
    </Layout>
  );
};

export default CreateProjectProjectTypeSelectorComponent;
