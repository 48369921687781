/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { DraggableLocation, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { isTypeNode } from 'graphql';
import { clone, cloneDeep } from 'lodash';
import { string } from 'yup/lib/locale';
import {
  RuleEngineState,
  ActionTypes,
  DeviceCollectionSearchOutItem,
  ProDevice,
  AirDevice,
  gpioSettings,
  thirdPartySettings,
  TriggerTypes,
  OPERATORS,
  Trigger,
  CheckTypes,
  Check,
  Action,
  LOGIC_CARD_PERMISSION,
  LOGIC_CARD_VISIBILITY,
} from '@smartlife-redux-state/common';
import { insertToArray } from 'src/utility/appUtils';
import { init, projectsSlice } from '../project/slice';
import { searchDc, searchDevices } from './async-thunk';
import actionDragDropIdResolver from './helpers/actionDragDropIdResolver';

const insertArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const reorder = (list: any[], oldIndex: number, newIndex: number) => {
  const shallow_cpy = [...list];
  const newSorted = arrayMove(shallow_cpy, oldIndex, newIndex);
  return newSorted;
};

const move = (
  source: any[],
  destination: any[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const check_initial_uuid = uuid();
const initial_state: RuleEngineState = {
  // av_item_creator: {
  //   audio_device: null,
  //   video_device: null,
  // },
  device_containers: {
    result: [],
  },
  location_variable_container: null,
  devices: null,
  device_form_resovler: {},
  triggers: [],
  checks: {
    list: [],
    content: [],
    operator_connectivity: {},
    expression: null,
  },
  actions: [
    // {
    //   key: null,
    //   id: uuid(),
    //   integration: null,
    //   payload: null,
    //   child_action_delay: 0,
    //   child_actions: [],
    //   device: null,
    //   dc: null,
    //   variable: null,
    //   possible_variable_type: null,
    // },
  ],
  meta: {
    loadin_device: false,
    is_editing: false,
  },
};

export const ruleEngineSlice = createSlice({
  name: 'rule-engine',
  initialState: initial_state,
  reducers: {
    reset_logic_creation: (state) => {
      state.triggers = initial_state.triggers;
      state.actions = initial_state.actions;
      state.checks = initial_state.checks;
      state.device_containers = initial_state.device_containers;
      state.device_form_resovler = initial_state.device_form_resovler;
      state.devices = initial_state.devices;
      state.location_variable_container = initial_state.location_variable_container;
      state.meta = initial_state.meta;
    },
    hydrate_logic_creation: (state, action: {payload: RuleEngineState}) => {
      // patch
      // Below map used to dynamically add the type
      // to the older version of logic action items
      // that were created before notification action type.
      const actionsCpy = action.payload.actions;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updated = actionsCpy.map((action) => {
        if (!action.type) {
          action.type = ActionTypes.DEVICE;
        }

        const updated_childs = action.child_actions.map((child_action) => {
          if (!child_action.type) {
            child_action.type = ActionTypes.DEVICE;
          }
          return child_action;
        });
        action.child_actions = updated_childs;
        return action;
      });
      console.log('payload', action.payload);
      state.triggers = action.payload.triggers;
      state.actions = updated; // action.payload.actions;
      state.checks = action.payload.checks;
      state.device_containers = { ...action.payload.device_containers };
      state.device_form_resovler = action.payload.device_form_resovler;
      state.devices = { ...action.payload.devices, result: [] };
      state.location_variable_container = action.payload.location_variable_container;
      state.meta = action.payload.meta;

      // state = action.payload;
    },
    set_meta_loading: (state, action: {type: string; payload: {loadin_device: boolean}}) => {
      state.meta.loadin_device = action.payload.loadin_device;
    },
    select_dc: (
      state,
      action: {
        type: string;
        payload: {dc: DeviceCollectionSearchOutItem; trigger_id?: string};
      },
    ) => {
      // eslint-disable-next-line max-len
      state.triggers.find((trigger) => trigger.id === action.payload.trigger_id).dc = action.payload.dc;
    },
    select_device: (state, action: {type: string; payload: {device: ProDevice | AirDevice}}) => {
      if (!action.payload.device) {
        return;
      }
      let settings: gpioSettings | thirdPartySettings = null;
      if (action.payload.device && action.payload.device.settings) {
        settings = JSON.parse(action.payload.device.settings);
      }

      // state.device_form_resovler[action.payload.device.id] = settings;
    },
    add_empty_trigger: (state, action: {type: string; payload: {last_trigger_id: string}}) => {
      if (!action.payload.last_trigger_id) {
        state.triggers = [
          {
            id: uuid(),
            type: TriggerTypes.DEVICE,
            key: '',
            op: OPERATORS.EQUAL,
            condition: null,
            device: null,
            dc: null,
            variable: null,
            possible_variable_type: null,
            meta: {
              is_enabled: true,
              card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
              card_permission: LOGIC_CARD_PERMISSION.LEVEL1,
            },
          },
        ];
        return;
      }
      const lastIndex = state.triggers.map((tri) => tri.id).indexOf(action.payload.last_trigger_id);
      if (lastIndex > -1) {
        const updated = insertArray(state.triggers, lastIndex + 1, {
          id: uuid(),
          type: TriggerTypes.DEVICE,
          key: '',
          op: OPERATORS.EQUAL,
          condition: null,
          device: null,
          dc: null,
          variable: null,
          possible_variable_type: null,
        });
        state.triggers = updated;
      }
    },
    update_trigger: (state, action: {type: string; payload: {trigger: Trigger}}) => {
      let found_index = -1;
      const found = [...state.triggers].find((trigger, index) => {
        if (trigger.id === action.payload.trigger.id) {
          found_index = index;
          return true;
        }
        return false;
      });
      console.log('FOUND', found_index);
      let cpy = { ...found };
      cpy = action.payload.trigger;
      state.triggers[found_index] = cpy;
    },
    trigger_list_reorder: (
      state,
      action: {
        type: string;
        payload: {result: DropResult; provided: ResponderProvided};
      },
    ) => {
      const { result, provided } = action.payload;
      const boardItems = [];

      state.triggers.map((trigger) => {
        boardItems.push(cloneDeep(trigger));
      });

      const { source, destination } = result;
      if (!destination) return;

      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      if (sInd === dInd) {
        // moved within same group
        console.log(sInd, dInd, source.index, destination.index);
        // eslint-disable-next-line no-debugger
        // debugger;
        const items = reorder(boardItems, source.index, destination.index);
        // console.log(items);
        // const stateCpy = [...boardItems];
        // stateCpy = items;

        state.triggers = items; // stateCpy.filter((group) => group.length);
        // setBoardItems(stateCpy.filter((group) => group.length));
      }
      // else {
      //   // moved among groups
      //   // eslint-disable-next-line @typescript-eslint/no-shadow
      //   const result = move(boardItems[sInd], boardItems[dInd], source, destination);
      //   const newState = [...boardItems];
      //   newState[sInd] = result[sInd];
      //   newState[dInd] = result[dInd];

      //   state.checks.list = newState.filter((group) => group.length);
      //   // setBoardItems(newState.filter((group) => group.length));
      // }
    },
    delete_triiger: (state, action: {type: string; payload: {trigger_id: string}}) => {
      const filtered = state.triggers.filter((trigger) => trigger.id !== action.payload.trigger_id);
      state.triggers = filtered;
    },
    duplicate_trigger: (state, action: {type: string; payload: {trigger: Trigger}}) => {
      const trigger_cpy = cloneDeep(action.payload.trigger);
      trigger_cpy.id = uuid();

      const lastIndex = state.triggers.map((tri) => tri.id).indexOf(action.payload.trigger.id);
      if (lastIndex > -1) {
        const updated = insertArray(state.triggers, lastIndex + 1, trigger_cpy);
        state.triggers = updated;
      }

      // state.triggers.push(trigger_cpy);
    },
    insert_op_connection: (
      state,
      action: {type: string; payload: {key: string; value: string}},
    ) => {
      const obj = state.checks.operator_connectivity;
      obj[action.payload.key] = action.payload.value;
      state.checks.operator_connectivity = obj;
    },
    check_list_reorder: (
      state,
      action: {
        type: string;
        payload: {result: DropResult; provided: ResponderProvided};
      },
    ) => {
      const { result, provided } = action.payload;
      const boardItems = state.checks.list;

      const { source, destination } = result;
      if (!destination) return;

      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      if (sInd === dInd) {
        // moved within same group
        console.log(sInd, dInd, source.index, destination.index);
        const items = reorder(boardItems[sInd], source.index, destination.index);
        console.log(items);
        const stateCpy = [...boardItems];
        stateCpy[sInd] = items;

        state.checks.list = stateCpy.filter((group) => group.length);
        // setBoardItems(stateCpy.filter((group) => group.length));
      } else {
        // moved among groups
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const result = move(boardItems[sInd], boardItems[dInd], source, destination);
        const newState = [...boardItems];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];

        state.checks.list = newState.filter((group) => group.length);
        // setBoardItems(newState.filter((group) => group.length));
      }
    },
    add_check_empty_group: (state) => {
      const empyt_cond = {
        id: uuid(),
        type: CheckTypes.DEVICE,
        op: OPERATORS.EQUAL,
        key: '',
        val: '',
        msg: '',
        device: null,
        dc: null,
        variable: null,
        possible_variable_type: null,
      };
      state.checks.list = [
        ...state.checks.list,
        [
          {
            id: empyt_cond.id,
          },
        ],
      ];
      state.checks.content.push(empyt_cond);
    },
    add_empty_check: (state, action: {payload: {groupIndex: number; lastIndex: number}}) => {
      const group = state.checks.list[action.payload.groupIndex];
      if (group) {
        const uId = uuid();
        const updated = insertArray(group, action.payload.lastIndex + 1, {
          id: uId,
        });
        state.checks.list[action.payload.groupIndex] = updated;
        state.checks.content.push({
          id: uId,
          type: CheckTypes.DEVICE,
          op: OPERATORS.EQUAL,
          key: '',
          val: null,
          msg: '',
          device: null,
          dc: null,
          variable: null,
          possible_variable_type: null,
          meta: {
            is_enabled: true,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
            card_permission: LOGIC_CARD_PERMISSION.LEVEL1,
          },
        });
      }
    },
    duplicate_check: (
      state,
      action: {payload: {check: Check; groupIndex: number; lastIndex: number}},
    ) => {
      const group = state.checks.list[action.payload.groupIndex];
      if (group) {
        const uId = uuid();
        const updated = insertArray(group, action.payload.lastIndex + 1, {
          id: uId,
        });
        state.checks.list[action.payload.groupIndex] = updated;
        state.checks.content.push({
          ...cloneDeep(action.payload.check),
          id: uId,
        });
      }
    },
    update_check: (state, action: {type: string; payload: {check: Check}}) => {
      let foundIndex = -1;
      state.checks.content.find((item, index) => {
        if (item.id === action.payload.check.id) {
          foundIndex = index;
          return true;
        }
        return false;
      });
      console.log('update_check', action.payload.check);
      const cpy = action.payload.check;

      if (foundIndex > -1) state.checks.content[foundIndex] = cpy;
    },
    delete_check: (
      state,
      action: {
        type: string;
        payload: {groupIndex: number; checkIndex: number};
      },
    ) => {
      const id = state.checks.list[action.payload.groupIndex][action.payload.checkIndex]?.id;
      if (id) {
        const filteredContent = state.checks.content.filter((content) => content.id !== id);
        const listCpy = [...state.checks.list][action.payload.groupIndex];
        const removedItem = listCpy.splice(action.payload.checkIndex, 1);

        const cpy = { ...state.checks };
        // console.log("UPDATED LIST ***********" , listCpy, cpy.list[action.payload.groupIndex]);
        if (filteredContent.length === 0) {
          cpy.list = [];
        } else if (listCpy.length === 0) {
          const list = [...cpy.list];
          if (action.payload.groupIndex > -1) {
            list.splice(action.payload.groupIndex, 1);
          }
          cpy.list = list;
        } else {
          cpy.list[action.payload.groupIndex] = listCpy;
        }
        cpy.content = filteredContent;

        // state.checks.list[action.payload.groupIndex] = updatedList;
        // state.checks.content = filteredContent;
        state.checks = cpy;
      }
    },
    define_check_expression: (state) => {
      // hint
      // g0-g1 => connection between group 1 and 2
      // g0-0-g0-1 => connection between item 1 and 2 in group 1
      // g0-0-g0-1
      let expression = '';
      state.checks.list.map((group, groupIndex) => {
        let inner_expression = '';
        group.map((checkItem, checkItemIndex) => {
          // const checkContent = state.checks.content.find((check) => check.id === checkItem.id);
          // if (!checkContent.meta.is_enabled) return;
          if (group.length === 1) {
            const expression_part = `${checkItem.id}`;
            inner_expression += expression_part;
            return;
          }
          if (group.length === checkItemIndex + 1) return;
          const connectivity = state.checks.operator_connectivity[
            `g${groupIndex}-${checkItemIndex}-g${groupIndex}-${checkItemIndex + 1}`
          ];

          console.log(
            `g${groupIndex}-${checkItemIndex}-g${groupIndex}-${checkItemIndex + 1}`,
            connectivity,
          );

          let expression_part = '';
          if (checkItemIndex === 0) {
            expression_part += `${checkItem.id} ${connectivity} ${group[checkItemIndex + 1].id} `;
          } else {
            expression_part += ` ${connectivity} ${group[checkItemIndex + 1].id}`;
          }

          inner_expression += expression_part;
        });

        const group_connectivity = state.checks.operator_connectivity[`g${groupIndex}-g${groupIndex + 1}`];
        if (inner_expression.trim().length === 0) return;
        if (state.checks.list.length !== groupIndex + 1) {
          expression += `(${inner_expression}) ${group_connectivity} `;
        } else {
          expression += `(${inner_expression}) `;
        }
      });

      state.checks.expression = expression;
    },
    add_empty_action: (state) => {
      state.actions.push({
        key: null,
        id: uuid(),
        type: ActionTypes.DEVICE,
        integration: null,
        payload: null,
        child_action_delay: 1,
        child_actions: [],
        device: null,
        dc: null,
        variable: null,
        possible_variable_type: null,
        meta: {
          is_enabled: true,
          card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
          card_permission: LOGIC_CARD_PERMISSION.LEVEL1,
        },
      });
    },
    duplicate_action_queue: (state, action: {type: string; payload: {action_queue: Action}}) => {
      const actionCpy: Action = cloneDeep(action.payload.action_queue);

      const lastIndex = state.actions.map((act) => act.id).indexOf(actionCpy.id);
      if (lastIndex > -1) {
        const updated = insertArray(state.actions, lastIndex + 1, {
          ...actionCpy,
          id: uuid(),
        });
        state.actions = updated;
      }
      // state.actions.push({
      //   ...cloneDeep(action.payload.action_queue),
      //   id: uuid(),
      // });
    },
    add_empty_child_action: (
      state,
      action: {type: string; payload: {parentActionId: string; nextTo: number}},
    ) => {
      let parentIndex = -1;
      state.actions.find((item, index) => {
        if (item.id === action.payload.parentActionId) {
          parentIndex = index;
          return true;
        }
      });
      if (parentIndex > -1) {
        const payload = {
          key: null,
          id: uuid(),
          type: ActionTypes.DEVICE,
          integration: null,
          payload: null,
          device: null,
          dc: null,
          variable: null,
          possible_variable_type: null,
          child_action_delay: 1,
          meta: {
            is_enabled: true,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
            card_permission: LOGIC_CARD_PERMISSION.LEVEL1,
          },
        };
        if (action.payload.nextTo !== null || action.payload.nextTo !== undefined) {
          const actionCpy = cloneDeep(state.actions[parentIndex].child_actions);
          const res = insertToArray(actionCpy, action.payload.nextTo + 1, payload);
          state.actions[parentIndex].child_actions = res;
        } else {
          state.actions[parentIndex].child_actions.push(payload);
        }
      }
    },
    duplicate_child_action: (
      state,
      action: {type: string; payload: {parentActionId: string; action: Action}},
    ) => {
      let parentIndex = -1;
      state.actions.find((item, index) => {
        if (item.id === action.payload.parentActionId) {
          parentIndex = index;
          return true;
        }
      });
      if (parentIndex > -1) {
        // state.actions[parentIndex].child_actions.push({
        //   ...cloneDeep(action.payload.action),
        //   id: uuid(),
        // });

        // eslint-disable-next-line max-len
        const lastIndex = state.actions[parentIndex].child_actions.map((act) => act.id).indexOf(action.payload.action.id);
        if (lastIndex > -1) {
          const updated = insertArray(state.actions[parentIndex].child_actions, lastIndex + 1, {
            ...cloneDeep(action.payload.action),
            id: uuid(),
          });
          state.actions[parentIndex].child_actions = updated;
        } else {
          const updated = insertArray(state.actions[parentIndex].child_actions, 0, {
            ...cloneDeep(action.payload.action),
            id: uuid(),
          });
          state.actions[parentIndex].child_actions = updated;
        }
      }
    },
    update_action: (
      state,
      action: {
        type: string;
        payload: {action: Action; isChild: boolean; parentAction?: Action};
      },
    ) => {
      if (action.payload.isChild) {
        console.log('CHILD CONFIRM');
        let parentIndex = -1;
        state.actions.find((item, index) => {
          if (item.id === action.payload.parentAction.id) {
            parentIndex = index;
            return true;
          }
        });

        let childIndex = -1;
        state.actions[parentIndex].child_actions.find((child_action, index) => {
          if (child_action.id === action.payload.action.id) {
            childIndex = index;
            return true;
          }
        });

        if (parentIndex > -1 && childIndex > -1) {
          console.log(parentIndex, childIndex, action.payload.action);
          state.actions[parentIndex].child_actions[childIndex] = {
            ...state.actions[parentIndex].child_actions[childIndex],
            ...action.payload.action,
          };
        }
        return;
      }

      let foundIndex = -1;
      state.actions.find((item, index) => {
        if (item.id === action.payload.action.id) {
          foundIndex = index;
          return true;
        }
      });
      console.log('update_action', action.payload.action);
      const cpy = action.payload.action;

      if (foundIndex > -1) state.actions[foundIndex] = cpy;
    },
    validate_main_action: (state) => {
      const filtered = state.actions.filter(
        (act) => act.type !== 'send_notification'
          && act.type !== 'alarm_monitoring'
          && act.type !== 'logics_run'
          && act.type !== 'logic_cancel'
          && act.type !== 'alexa_voice_notification'
          && act.type !== 'advanced_firebase'
          && act.type !== 'email_alert'
          && act.device == null,
      );
      if (filtered.length > 0) {
        const valid = state.actions.filter(
          (act) => act.device != null || act.type === 'send_notification',
        );
        state.actions = valid;
      }
    },
    action_reorder: (
      state,
      action: {payload: {result: DropResult; provided: ResponderProvided}},
    ) => {
      // TODOS
      console.log('XXXXXXXXX', action.payload);
      // return;
      // 1. Drop on same child list
      // 2. Drop child action on main action
      // 3. Drop main action to child action
      // 4. Drop on main action from another queue
      // 5. Drop on child list from another queue

      const data = action.payload.result;
      // drop on same child list
      if (data.destination.index > 0 && data.source.index > 0) {
        // eslint-disable-next-line no-debugger
        // debugger;
        const sourceIndex = data.source.index - 1;
        const destinationIndex = data.destination.index - 1;

        const draggedCardInfo = actionDragDropIdResolver<'dragable'>(data.draggableId);
        const droppedGroupInfo = actionDragDropIdResolver<'dropable'>(data.destination.droppableId);

        const actionQueue = cloneDeep(state.actions[Number(draggedCardInfo.queueIndex)]);

        const childList = actionQueue.child_actions;
        // eslint-disable-next-line max-len
        [childList[sourceIndex], childList[destinationIndex]] = [
          childList[destinationIndex],
          childList[sourceIndex],
        ];

        state.actions[Number(draggedCardInfo.queueIndex)].child_actions = childList;
      }

      // drop child action on main action
      if (data.destination.index === 0 && data.source.index > 0) {
        const childListIndex = data.source.index - 1;
        const draggedCardInfo = actionDragDropIdResolver<'dragable'>(data.draggableId);

        const actionQueue = cloneDeep(state.actions[Number(draggedCardInfo.queueIndex)]);
        const childList = cloneDeep(actionQueue.child_actions);
        const childAction = cloneDeep(actionQueue.child_actions[childListIndex]);

        const mainAction = cloneDeep(actionQueue);
        // mainAction.child_action_delay = 1;
        delete mainAction.child_actions;
        delete (mainAction as any).flag;

        childList[childListIndex] = mainAction;
        // mainAction.child_actions = childList;
        const newMainAction = childAction;
        (newMainAction as any).flag = 'parent';
        newMainAction.child_actions = childList;
        console.log('xx', mainAction);

        state.actions[Number(draggedCardInfo.queueIndex)] = newMainAction; // mainAction;
        return;
      }
      // drop main action to child action
      if (data.source.index === 0 && data.destination.index > 0) {
        const childListIndex = data.destination.index - 1;
        const draggedCardInfo = actionDragDropIdResolver<'dragable'>(data.draggableId);
        const droppedGroupInfo = actionDragDropIdResolver<'dropable'>(data.destination.droppableId);

        const actionQueue = cloneDeep(state.actions[Number(draggedCardInfo.queueIndex)]);
        const childList = cloneDeep(actionQueue.child_actions);
        const mainAction = cloneDeep(actionQueue);
        // mainAction.child_action_delay = 1;
        delete mainAction.child_actions;
        delete (mainAction as any).flag;

        const childAction = cloneDeep(actionQueue.child_actions[childListIndex]);

        childList[childListIndex] = mainAction;

        const newMainAction = childAction;
        (newMainAction as any).flag = 'parent';
        newMainAction.child_actions = childList;

        state.actions[Number(draggedCardInfo.queueIndex)] = newMainAction;
      }

      // // 1 Drop on same child list
      // if (data.destination.droppableId === data.source.droppableId) {
      //   const sourceIndex = data.source.index;
      //   const destinationIndex = data.destination.index;

      //   const draggedCardInfo = actionDragDropIdResolver<'dragable'>(data.draggableId);
      //   const droppedGroupInfo = actionDragDropIdResolver<'dropable'>(data.destination.droppableId);

      //   const actionQueue = cloneDeep(state.actions[Number(draggedCardInfo.queueIndex)]);

      //   const childList = actionQueue.child_actions;
      //   // eslint-disable-next-line max-len
      //   [childList[sourceIndex - 1], childList[destinationIndex - 1]] = [
      //     childList[destinationIndex - 1],
      //     childList[sourceIndex - 1],
      //   ];

      //   state.actions[Number(draggedCardInfo.queueIndex)].child_actions = childList;
      //   // return;
      // }
      // // eslint-disable-next-line no-debugger
      // // debugger;
      // // 2. Drop child action on main action
      // if (data.destination.droppableId.indexOf('cardType=main-group') > -1) {
      //   console.log('reached');
      //   const draggedCardInfo = actionDragDropIdResolver<'dragable'>(data.draggableId);
      //   const droppedGroupInfo = actionDragDropIdResolver<'dropable'>(data.destination.droppableId);

      //   const actionQueue = cloneDeep(state.actions[Number(draggedCardInfo.queueIndex)]);
      //   const childList = cloneDeep(actionQueue.child_actions);
      //   const childAction = cloneDeep(actionQueue.child_actions[data.source.index]);

      //   const mainAction = cloneDeep(actionQueue);
      //   // mainAction.child_action_delay = 1;
      //   delete mainAction.child_actions;

      //   childList[data.source.index] = mainAction;
      //   // mainAction.child_actions = childList;
      //   const newMainAction = childAction;
      //   newMainAction.child_actions = childList;
      //   console.log('xx', mainAction);

      //   state.actions[Number(draggedCardInfo.queueIndex)] = newMainAction; // mainAction;
      // }

      // // 3. Drop main action to child action
      // if (data.draggableId.indexOf('cardType=main-card') > -1) {
      //   const draggedCardInfo = actionDragDropIdResolver<'dragable'>(data.draggableId);
      //   const droppedGroupInfo = actionDragDropIdResolver<'dropable'>(data.destination.droppableId);

      //   const actionQueue = cloneDeep(state.actions[Number(draggedCardInfo.queueIndex)]);
      //   const childList = cloneDeep(actionQueue.child_actions);
      //   const mainAction = cloneDeep(actionQueue);
      //   // mainAction.child_action_delay = 1;
      //   delete mainAction.child_actions;

      //   const childAction = cloneDeep(actionQueue.child_actions[data.destination.index]);

      //   childList[data.destination.index] = mainAction;

      //   const newMainAction = childAction;
      //   newMainAction.child_actions = childList;

      //   state.actions[Number(draggedCardInfo.queueIndex)] = newMainAction;
      // }
    },
    delete_action: (state, action: {payload: {actionId: string}}) => {
      const filtered = state.actions.filter((act) => act.id !== action.payload.actionId);
      state.actions = [...filtered];
    },
    delete_child_action: (state, action: {payload: {parentActionId: string; actionId: string}}) => {
      let parentIndex = -1;
      state.actions.find((item, index) => {
        if (item.id === action.payload.parentActionId) {
          parentIndex = index;
          return true;
        }
      });

      if (parentIndex > -1) {
        const filtered = state.actions[parentIndex].child_actions.filter(
          (child) => child.id !== action.payload.actionId,
        );
        state.actions[parentIndex].child_actions = [...filtered];
      }
    },
    // update_av_item_logic: (state, action) => {
    //   const payload = action.payload;
    //   state.av_item_creator = {...state.av_item_creator, ...payload}
    // }
  },

  extraReducers: (builder) => {
    builder.addCase(searchDc.fulfilled, (state, action) => {
      const data = cloneDeep(action.payload);
      let location_variable_container = null;
      if (data.result && data.result.length > 0) {
        const filtered = [];
        data.result.reverse().map((dc, index) => {
          if (dc.dc_type === 9) {
            location_variable_container = dc;
            return;
          }
          filtered.push(dc);
        });
        data.result = filtered;
      }

      if (data.result) {
        state.device_containers = data;
      }

      if (location_variable_container) {
        state.location_variable_container = location_variable_container;
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    builder.addCase(searchDc.rejected, (state, action) => {
      console.log(state, action);
    });
    builder.addCase(searchDevices.pending, (state, action) => {
      state.meta.loadin_device = true;
    });
    builder.addCase(searchDevices.fulfilled, (state, action) => {
      const data = action.payload;
      state.devices = data;
      // if(data) {
      //   state.devices = {
      //     air_devices :  data.air_devices,
      //     pro_devices: data.pro_devices
      //   }
      // }

      state.meta.loadin_device = false;
    });
    builder.addCase(searchDevices.rejected, (state, action) => {
      state.meta.loadin_device = false;
    });
  },
});

export const {
  reset_logic_creation,
  hydrate_logic_creation,
  set_meta_loading,
  select_dc,
  select_device,
  add_empty_trigger,
  update_trigger,
  trigger_list_reorder,
  delete_triiger,
  insert_op_connection,
  check_list_reorder,
  add_check_empty_group,
  add_empty_check,
  update_check,
  delete_check,
  define_check_expression,
  add_empty_action,
  add_empty_child_action,
  update_action,
  validate_main_action,
  action_reorder,
  delete_action,
  delete_child_action,
  duplicate_trigger,
  duplicate_check,
  duplicate_action_queue,
  duplicate_child_action,
  // update_av_item_logic
} = ruleEngineSlice.actions;
export const { reducer } = ruleEngineSlice;
