/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Example Usage of the extend dragDrop library
 *
 * @author Maduka Dilshan
 *
 */
import { v4 as uuid } from 'uuid';
import {
  Box, Button, IconButton, Typography, Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';

import { Trigger } from '@smartlife-redux-state/common';
import clsx from 'clsx';
import {
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { FormContainer } from './components/FormContainer';
import { TriggerSelector } from './components/TriggerSelector';
import { RootState, useSelector } from '../../../../../store/redux/store';
import {
  add_empty_trigger,
  delete_triiger,
  duplicate_trigger,
  trigger_list_reorder,
} from '../../../../../store/redux/features/logic-engine/slice';
import { CofirmDialog } from '../ui/Confirm';
import { DivButton } from '../ui/DivButton';
import { RealtimeValueCompartorComponent } from './components/shared/RealtimeValueCompartor';
import { useFirebasePathBuilder } from './components/shared/ExpressionSelector';
import CardStatus, { CARD_TYPES } from './components/shared/CardStatus';
import { Board } from '../board';
import { Group } from '../board/Group';
import { Card } from '../board/Card';

const insertArray = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const useStyle = makeStyles({
  boardRoot: {
    width: '100%',
  },
  groupRoot: {
    minHeight: '100px',
    background: 'white !important',
  },
  line_root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  line: {
    alignSelf: 'center',
    background: '#E0E0E0',
    width: '40%',
    height: '0.5px',
  },
  equal_values: {
    background: '#dbffe1',
  },
});

export const BoardWhenCard: React.FC<{
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  trigger: Trigger;
  trigger_count: number;
  index: number;
  onDeleteTrigger: (id: string) => void;
  onNewTrigger: (id: string) => void;
  onDuplicateTrigger: (trigger: Trigger) => void;
}> = ({
  provided,
  snapshot,
  trigger,
  trigger_count,
  index,
  onDeleteTrigger,
  onNewTrigger,
  onDuplicateTrigger,
}) => {
  const classes = useStyle();

  const [firebase_path] = useFirebasePathBuilder(trigger.dc, trigger.device, {
    field: trigger.variable,
    type: trigger.possible_variable_type,
  });
  console.log('FIREBASE PATH', firebase_path);
  return (
    <RealtimeValueCompartorComponent
      path_to_check={firebase_path}
      current_value={trigger.condition}
    >
      {({ isEqual }) => (
        <>
          <CardStatus
            cardType={CARD_TYPES.TRIGGER_CARD}
            cardId={trigger.id}
            meta={trigger.meta ? trigger.meta : null}
          >
            {({ classes: cardStatusClasses }) => (
              <FormContainer
                dragHandlerProps={provided.dragHandleProps}
                cardId={trigger.id}
                key={trigger.id}
                className={clsx(isEqual ? classes.equal_values : '', cardStatusClasses)}
                action={() => (
                  <>
                    <IconButton onClick={() => onNewTrigger(trigger.id)} size="large">
                      <AddIcon />
                    </IconButton>
                    <IconButton onClick={() => onDuplicateTrigger(trigger)} size="large">
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteTrigger(trigger.id)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              >
                <TriggerSelector trigger={trigger} />
              </FormContainer>
            )}
          </CardStatus>
          {trigger_count !== index + 1 && (
            <div className={classes.line_root}>
              <hr className={classes.line} />
              <Button color="primary" disabled variant="contained">
                <Typography>OR</Typography>
              </Button>
              <hr className={classes.line} />
            </div>
          )}
        </>
      )}
    </RealtimeValueCompartorComponent>
  );
};

export const BoardWhen = () => {
  // const [triggers, setTriggers] = useState([uuid()]);
  const classes = useStyle();

  const [deleteId, setDeleteId] = useState(null);

  const triggers = useSelector((state: RootState) => state.rule_engine.triggers);
  const dispatch = useDispatch();

  const onNewTrigger = (lastTriggerId: string) => {
    dispatch(add_empty_trigger({ last_trigger_id: lastTriggerId }));
  };

  const onDuplicateTrigger = (trigger: Trigger) => {
    dispatch(duplicate_trigger({ trigger }));
  };

  const onDeleteTrigger = (triggerId: string) => {
    setDeleteId(triggerId);
  };

  function deleteTrigger() {
    dispatch(
      delete_triiger({
        trigger_id: deleteId,
      }),
    );
    setDeleteId(null);
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    dispatch(trigger_list_reorder({ result, provided }));
  }

  return (
    <Board
      onDragEnd={onDragEnd}
      className={classes.boardRoot}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Group droppableId="0" className={classes.groupRoot}>
        {() => (
          <>
            {triggers.map((trigger, index) => (
              <Card draggableId={`drop0drag${index}`} index={index}>
                {({ provided, snapshot }) => (
                  <BoardWhenCard
                    provided={provided}
                    snapshot={snapshot}
                    trigger={trigger}
                    key={trigger.id}
                    index={index}
                    trigger_count={triggers.length}
                    onDeleteTrigger={onDeleteTrigger}
                    onNewTrigger={onNewTrigger}
                    onDuplicateTrigger={onDuplicateTrigger}
                  />
                )}
              </Card>
            ))}
          </>
        )}
      </Group>
      <Box>
        <DivButton
          onClick={() => {
            onNewTrigger(triggers[triggers.length - 1] ? triggers[triggers.length - 1].id : null);
          }}
        />
        <CofirmDialog
          dialogOpen={!!deleteId}
          dialogCloseEvent={(e: any) => {
            if (e === true) {
              deleteTrigger();
            } else {
              setDeleteId(null);
            }
          }}
          title={"Confirm to delete the 'Trigger' ?"}
          text="It will delete the trigger and you will not able to redo it!"
        />
      </Box>
    </Board>
  );
};
