import React, { useContext, useEffect, useState } from 'react';
import {
  Route, useLocation, useNavigate, Routes, Outlet,
} from 'react-router-dom';
import { Alert, AlertTitle, Button } from '@mui/material';

import HeaderComponent from '../components/app/Header/Header';
import SideBarComponent from '../components/app/Sidebar/Sidebar';
import useQueryString from '../utility/CustomHooks/useQueryString';
import { appConsoleLog } from '../utility/appUtils';
import { useDispatch } from '../store/redux/store';
import { getAvaibleProjects } from '../store/redux/features/project';
import { ProjectContext } from '../store/Project/ProjectContext';

import styles from './AppPage.module.css';
import DashboardLayout from './Dashboard';

export const AppLayout: React.FunctionComponent<any> = (props) => {
  // const { path, url } = useRouteMatch();
  const loc = useLocation();
  const path = loc.pathname;

  const [mobileNavShow, mobileNavShowSet] = useState<any>(false);
  const selectedProjectID = useQueryString('projectId');
  const selectedProject = useContext(ProjectContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // use links as
  // <Link to={`${url}/bar`}>Bar</Link>
  const navOnClick = () => {
    appConsoleLog('navOnClick', mobileNavShow);
    mobileNavShowSet(!mobileNavShow);
  };

  console.log('**** APP LAYOUT');

  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    // TODO: intiialze project state

    // get project
    async function initProjects() {
      const projectResultAction = await dispatch(
        getAvaibleProjects({
          project_status: 1,
          project_type: -1,
          keyword: '',
          limit: 5,
          skip: 0,
        }),
      );
    }
    initProjects();
    // get project views
    console.log('XXXXXXXXXXXXXXXXXXX');
    // get project device containers
  }, []);

  return <DashboardLayout>{props.children}</DashboardLayout>;
};

export default AppLayout;
