import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {dcSearchGraphQL} from 'src/services/device-container/device-container.service';
import {ApolloAuthContext} from 'src/store/Apollo/ApolloContext';
import {AuthContext} from 'src/store/Auth/AuthContext';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';

export const ContainerMapper: React.FC<{
  sessionId: string;
  onDcMap: (payload: Record<string, string>) => void;
}> = ({sessionId, onDcMap}) => {
  const [open, setOpen] = useState(false);
  const auth = useContext(AuthContext);
  const userProfile = useContext(ApolloAuthContext);
  const selectedProjectID = useQueryStringFirst('projectId');

  const {enqueueSnackbar} = useSnackbar();

  const [currentDcs, setCurrentDcs] = useState<Record<string, Record<string, any>>>({});
  const [locationDcs, setLocationDcs] = useState<Record<string, Record<string, any>>>({});

  const [dcMap, setDcMap] = useState<Record<string, string>>({});

  useEffect(() => {
    const init = async () => {
      try {
        const res = await dcSearchGraphQL(
          userProfile?.apollo_client,
          selectedProjectID,
          -1,
          -1,
          '',
          100,
          0
        );
        const data = res?.dcSearch?.result || [];

        const grouped = {
          air: {},
          pro: {},
          lv: {},
          cint: {},
        };

        data.map(dc => {
          let groupKey;
          if (dc.dc_type === 1) groupKey = 'air';
          if (dc.dc_type === 4) groupKey = 'pro';
          if (dc.dc_type === 9) groupKey = 'lv';

          grouped[groupKey] = {
            ...grouped[groupKey],
            [dc.id]: dc,
          };
        });

        const res1 = await pluginHook.commonIntegrationService.getCloudDeviceCollections({
          project_id: selectedProjectID,
          skip: 0,
          limit: 100,
        });

        const cDcs = res1?.result?.result || [];
        cDcs.map(dc => {
          grouped.cint = {
            ...grouped.cint,
            [dc.id]: dc,
          };
        });
        setLocationDcs(grouped);
      } catch (err) {}
    };

    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
          }/rest/backups/v2/restore/get-session-backup-dc-map`,
          {
            session_id: sessionId,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.access_token.token}`,
            },
          }
        );
        const dcs = response?.data?.result?.dc_map || {};
        const groupedDcs = {
          air: {},
          pro: {},
          lv: {},
          cint: {},
        };
        Object.keys(dcs).map(dcKey => {
          const dc = dcs[dcKey];
          groupedDcs[dc.dc_type] = {
            ...groupedDcs[dc.dc_type],
            [dcKey]: dcs[dcKey],
          };
        });

        setCurrentDcs(groupedDcs);
      } catch (err) {
        enqueueSnackbar('Unable to get device containers in restore point', {variant: 'error'});
      }
    };

    init();
  }, []);

  function dcTypeToLabel(type) {
    if (type == 'air') return 'Air Container';
    if (type == 'pro') return 'Pro Container';
    if (type == 'lv') return 'Location Variable Container';
    if (type == 'cint') return 'Cloud Containers';
  }

  return (
    <Grid>
      <Button onClick={() => setOpen(true)}>Device Container Map</Button>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Device Container Map</DialogTitle>
        <DialogContent>
          {Object.keys(currentDcs).map(dcType => {
            const dcs = currentDcs[dcType];
            return (
              <Box style={{backgroundColor: '#ebf1f2', marginBottom: 10, padding: 10}}>
                <Typography style={{fontWeight: 600}}>{dcTypeToLabel(dcType)}</Typography>
                {Object.keys(dcs).map(dcKey => {
                  return (
                    <Box style={{marginBottom: 5}}>
                      <Grid container spacing={1}>
                        <Grid item md={6}>
                          {dcs[dcKey]?.name}
                        </Grid>
                        <Grid item md={6}>
                          <FormControl style={{width: '100%'}}>
                            <Select
                              size="small"
                              value={dcMap[dcKey]}
                              onChange={e => {
                                setDcMap(current => {
                                  return {
                                    ...current,
                                    [dcKey]: e.target.value,
                                  } as any;
                                });
                              }}
                            >
                              {locationDcs[dcType] &&
                                Object.keys(locationDcs[dcType]).map(dcKey => (
                                  <MenuItem value={locationDcs[dcType][dcKey].id}>
                                    {locationDcs[dcType][dcKey].name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => {onDcMap(dcMap); setOpen(false);}}>Process</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
