/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Audio zone and source selector component
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define zone source selector for audio devices on uv item create dialog
 * @author Maduka Dilshan
 * @since 0.0.1
 */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import styles from './AudioSelector.module.css';
import { UVDeviceFinder } from '../UVDeviceFinder/UVDeviceFinder';
import { ICommDevice } from '../UVDeviceFinder/types';
import { appConsoleLog } from '../../../../utility/appUtils';
import { IZone } from '../../../../config/deviceConfig/types';
import { IUserViewItem } from '../../types/types';

type ComponentProps = {
  key: string;
  fieldConfig: any;
  onChangePayloadRootKey: string;
  uvItem: IUserViewItem | null;
  onChange: (arg:any) => void;
  device?: ICommDevice;
  hideFinder?: boolean;
  label?: string;
  updateCustomFieldErrors: (error: any) => void;
};

/**
 * Zone Source selector for Audio devices on uv item create form
 * @param {Object} props component props
 * @param {String} props.key unique key
 * @param {Object} props.fieldConfig config object which enable/disable of nasted fields
 * @param {Object} props.uvItem Main userview item selected on uv item create form
 * @param {Object} props.device Selected audio device
 * @param {Boolean} props.hideFinder flag that use to show/hide the device search box
 * withing AudioSelector component
 * @param {String} props.label display label
 * @param {updateCustomFieldErrorfn} props.updateCustomFieldErrors set fields errors
 * @param {onChangefn} props.onChange on zone source change
 * @param {String} props.onChangePayloadRootKey device setting object key name
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const UVAudioSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const {
    device, uvItem, hideFinder, fieldConfig, label,
  } = props;
  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useState<string>('');
  const [selectedAudioDevice, setAudioDevice] = useState<ICommDevice | null>(null);
  const [selectedZone, setZone] = useState<string>('');
  const [selectedSource, setSource] = useState<string>('');

  const mounted = useRef(0);

  useEffect(() => {
    if (props.device) {
      setAudioDevice(props.device);
      setNewAudioDevice(props.device, true, false);
    }
  }, [device]);

  useEffect(() => {
    if (
      props.uvItem
      && props.uvItem.settings
      && props.uvItem.settings[props.onChangePayloadRootKey]
    ) {
      const audioDevice = props.uvItem.settings[props.onChangePayloadRootKey];
      // debugger;
      if (audioDevice.device_id) {
        setSelectedAudioDeviceId(audioDevice.device_id);
        // &&
        // selectedAudioDeviceId != audioDevice.device_id
        if (audioDevice.zone) {
          setZone(audioDevice.zone);
        }
        if (audioDevice.source) {
          setSource(audioDevice.source);
        }
      }
    }
  }, [uvItem]);

  useEffect(() => {
    if (props.fieldConfig == null) {
      return;
    }

    const data: any = {};
    const errors: any = {};
    if (selectedAudioDevice) {
      // debugger;
      data[props.onChangePayloadRootKey] = {
        device_id: selectedAudioDevice.id,
        source: selectedSource,
        zone: selectedZone,
        type: selectedAudioDevice.sub_cat,
      };
    } else {
      data[props.onChangePayloadRootKey] = {};
    }
    props.onChange({
      action: 'data',
      form: props.key,
      data,
      errors,
    });
  }, [selectedSource, selectedZone]);

  useEffect(() => {
    if (props.fieldConfig == null) {
      return;
    }

    const data: any = {};
    const errors: any = {};
    if (selectedAudioDevice) {
      // debugger;
      data[props.onChangePayloadRootKey] = {
        device_id: selectedAudioDevice.id,
        source: selectedSource,
        zone: selectedZone,
        type: selectedAudioDevice.sub_cat,
      };
    } else {
      data[props.onChangePayloadRootKey] = {};
    }
    props.onChange({
      action: 'data',
      form: props.key,
      data,
      errors,
      clean_logic: true,
    });
  }, [selectedAudioDevice]);

  const setNewAudioDevice = (
    deviceArg: ICommDevice,
    isManually: boolean,
    cleanSrcZrc = true,
  ) => {
    if (cleanSrcZrc) {
      setZone('');
      setSource('');
    }

    if (deviceArg == null || !deviceArg.id || !deviceArg.settings) {
      return;
    }
    if (typeof deviceArg.settings === 'string') {
      // eslint-disable-next-line no-param-reassign
      deviceArg.settings = JSON.parse(deviceArg.settings);
    }

    const { sources } = deviceArg.settings;
    const { zones } = deviceArg.settings;
    if (isManually) {
      if (sources && sources.length > 0) {
        setSource(sources[0].value.toString());
      }
      if (zones && zones.length > 0) {
        setZone(zones[0].value.toString());
      }
    }

    setSelectedAudioDeviceId(deviceArg.id);
    setAudioDevice(deviceArg);
    props.updateCustomFieldErrors({
      audio_selector: {
        invalid: false,
      },
    });
  };
  const removeDevice = () => {
    setSelectedAudioDeviceId('');
    setAudioDevice(null);
  };

  return (
    <div className={clsx(styles.root, hideFinder ? '' : 'root-border')}>
      {!hideFinder && (
        <UVDeviceFinder
          systemTags={fieldConfig.system_tags}
          defaultValue={selectedAudioDeviceId.length > 1 ? `pro_${selectedAudioDeviceId}` : ''}
          label={label || 'Audio device'}
          withCustomSearch={false}
          defaultDevice={selectedAudioDevice}
          withBorder={false}
          onDeviceSelected={(devicePayload, isManually) => {
            if (mounted.current === 0) {
              setNewAudioDevice(devicePayload, isManually, false);
              mounted.current = 1;
            } else {
              setNewAudioDevice(devicePayload, isManually, true);
            }
            appConsoleLog('devicePayload', devicePayload);
          }}
        />
      )}

      {selectedAudioDevice != null ? (
        <div>
          {selectedAudioDevice.settings.zones ? (
            <div className="row">
              <div className="col-md-3 pl-12">
                <FormControl
                  size="small"
                  variant="outlined"
                  className="dynamic-ui-form-control-full-width"
                >
                  <InputLabel id="custom_selector-label1">Select Zone</InputLabel>
                  <Select
                    labelId="custom_selector-label1"
                    value={selectedZone}
                    error={
                      selectedAudioDevice && (selectedZone == null || selectedZone.length === 0)
                    }
                    name="custom_selector"
                    onChange={(e: any) => {
                      setZone(e.target.value);
                    }}
                    label="Select Zone"
                  >
                    {selectedAudioDevice.settings.zones.map((item: IZone) => (
                      <MenuItem
                        key={item.key + item.value + item.type + item.label}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 pl-12">
                <FormControl
                  size="small"
                  variant="outlined"
                  className="dynamic-ui-form-control-full-width"
                >
                  <InputLabel id="custom_selector-label1">Select source</InputLabel>
                  <Select
                    labelId="custom_selector-label1"
                    value={selectedSource}
                    error={
                      selectedAudioDevice && (selectedSource == null || selectedSource.length === 0)
                    }
                    name="custom_selector"
                    onChange={(e: any) => {
                      setSource(e.target.value);
                    }}
                    label="Select source"
                  >
                    {selectedAudioDevice.settings.sources.map((item: IZone) => (
                      <MenuItem
                        key={item.label + item.key + item.type + item.value}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className="row">
            <div className="col-md-12 pl-12">
              <Button onClick={removeDevice} color="primary">
                Remove Device
                <Delete fontSize="large" />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

UVAudioSelector.defaultProps = {
  device: null,
  hideFinder: false,
  label: 'Audio Device Selector',
};
