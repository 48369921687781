import React from 'react';
import { APP_TEXT } from '../../../../../utility/AppTexts/DefaultAppText.utils';
import { InterfaceSelectorComponenet } from '../../../../shared/InterfaceSelector/InterfaceSelector';
import { UserViewItemCategory } from '../../../types/types';

type ComponentProps = {
  initialCat: number;
  onCatChange: (category: number) => void;
};
const itemTypes = [
  {
    name: 'Item',
    text: 'Item Description',
    value: UserViewItemCategory.item,
  },
  {
    name: 'Other Item',
    text: 'Other Item Description',
    value: UserViewItemCategory.otherItem,
  },
  // {
  //   name: "Link to Folder",
  //   text: "Link to Folder Description",
  //   value: UserViewItemCategory.linkedFolder,
  // },
  {
    name: 'Link to Item',
    text: 'Link to Item Description',
    value: UserViewItemCategory.linkedItem,
  },
];
// eslint-disable-next-line import/prefer-default-export
export const ItemCategorySelectorStep: React.FunctionComponent<ComponentProps> = ({
  initialCat,
  onCatChange,
}) => {
  const catChange = (cat: number) => {
    onCatChange(cat);
  };
  return (
    <div>
      <div className="m-0 mt-4 col-md-12 row">
        <div className="custom-card2-in ">
          <div className="custom-card2-title">
            <h2>{APP_TEXT.user_view.USER_VIEW_TYPE}</h2>
            <hr />
          </div>
          <div className="custom-card2-body mt-2">
            <div className="col-md-12 nopaddingOnly">
              {itemTypes.map((item) => (
                <div key={`itm-type-key${item.value}`}>
                  <InterfaceSelectorComponenet
                    onClick={() => catChange(item.value)}
                    title={item.name}
                    description={item.text}
                    selected={item.value === initialCat}
                    disabled={false}
                    selectedDisabled={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
