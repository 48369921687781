/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeviceCollectionSearchOut, DeviceSearchOut } from '@smartlife-redux-state/common';
import _, { filter, isArray } from 'lodash';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { searchProjectDevice } from '../../../../components/UserViewComponents/service/uv.service';
import { airDeviceSearch } from '../../../../services/air-device/air-device.service';
import { dcSearchGraphQL } from '../../../../services/device-container/device-container.service';
import { proDeviceSearchGraphQL } from '../../../../services/pro-device/pro-device.service';

export const searchDc = createAsyncThunk<
  DeviceCollectionSearchOut,
  {keyword: string; project_id: string},
  {
    rejectValue: Object;
    extra: {
      apolloClient: ApolloClient<NormalizedCacheObject>;
    };
  }
>('rule-engine/getDcs', async (filters, thunkAPI) => {
  console.log('THUNK CALLED');
  const res = await dcSearchGraphQL(
    thunkAPI.extra.apolloClient,
    filters.project_id,
    null,
    null,
    filters.keyword,
    20,
    0,
  );

  const cInts = await pluginHook.commonIntegrationService.getCloudDeviceCollections({
    project_id: filters.project_id,
    skip: 0,
    limit: 100,
  });

  const convertedResult = pluginHook.commonIntegrationService.LogicMapper.convertCloudDeviceContainersToReduxFriendly(
    cInts,
  );

  const fromGql = res.dcSearch as DeviceCollectionSearchOut;
  const fromRest = convertedResult;

  const gqlRes = fromGql && isArray(fromGql.result) ? [...fromGql.result] : [];
  const restRes = fromRest && isArray(fromRest.result) ? [...fromRest.result] : [];

  return {
    __typename: 'DeviceCollectionSearchOut',
    limit: 100,
    skip: 0,
    resultCount: gqlRes.length + restRes.length,
    result: [...gqlRes, ...restRes],
  };
});

export const searchDevices = createAsyncThunk<
  DeviceSearchOut,
  {
    air_dc_id: string[];
    pro_dc_id: string[];
    cint_dc_id: string[];
    project_id: string;
    keyword: string;
  },
  {
    rejectValue: Object;
    extra: {
      apolloClient: ApolloClient<NormalizedCacheObject>;
    };
  }
>('rule-engine/devices', async (filters, thunkAPI) => {
  console.log(filters);
  if (filters.air_dc_id.length > 0) {
    const res = (await airDeviceSearch(
      thunkAPI.extra.apolloClient,
      filters.project_id,
      filters.air_dc_id[0],
      [],
      '',
      500,
      0,
    )) as any;
    // res.flag = "air";
    return res;
  }
  if (filters.pro_dc_id.length > 0) {
    console.log('TRIGGERD');
    const res = await proDeviceSearchGraphQL(
      thunkAPI.extra.apolloClient,
      filters.project_id,
      filters.pro_dc_id[0],
      '',
      '',
      '',
      '',
      -1,
      [],
      [],
      filters.keyword,
      500,
      0,
    );
    // res.flag = "pro";
    console.log(res);
    return res.proDeviceSearch;
  }

  if (filters.cint_dc_id.length > 0) {
    const res = await pluginHook.commonIntegrationService.getCloudDeviceCollectionDevices(filters.cint_dc_id[0]);
    const convertedResult = pluginHook.commonIntegrationService.LogicMapper.convertCloudIntContainerDevicesToReduxFriendly(res);
    return convertedResult;
  }

  throw new Error('DC not selected');

  // const res = (await searchProjectDevice(
  //   thunkAPI.extra.apolloClient,
  //   filters.project_id,
  //   null,
  //   null,
  //   filters.keyword,
  //   filters.air_dc_id,
  //   filters.pro_dc_id,
  //   100,
  //   0
  // )) as unknown;
  // return res as ProjectDeviceSearchOut;
});
