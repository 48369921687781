/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import axios from 'axios';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  createContext, useContext, useEffect, useMemo, useReducer,
} from 'react';
import { AuthContext } from 'src/store/Auth/AuthContext';

export type NodeRedList = {
  loading: boolean;
  notFound: boolean;
  hasError: boolean;
  list: {
    id: string;
    name: string;
    serial_name: string;
    have_nodered_flows: boolean;
  }[];
  isDirty: boolean;
};

const initialState: NodeRedList = {
  loading: false,
  notFound: false,
  hasError: false,
  isDirty: false,
  list: [],
};

// {
//     id: 'HRzocT13TSzhf',
//     name: 'SmartLifeConnect-1337',
//     serial_name: 'SmartLifeConnect-1337',
//     have_nodered_flows: true,
//   },
//   {
//     id: 'HRzoc34jk3TSzhf',
//     name: 'SmartLifeConnect-1339',
//     serial_name: 'SmartLifeConnect-1339',
//     have_nodered_flows: true,
//   },

const isFoundNodeFlows = (list: any = []) => {
  const modified = list.filter((item) => item.have_nodered_flows === true);

  return modified.length > 0;
};

export const TemplateNodeRedListContext = createContext({
  ...initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: (payload: any) => {},
  fetchNodeRedList: (serialId: string) => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET': {
      return {
        ...state,
        list: action.payload,
        notFound: !(isArray(action.payload) && isFoundNodeFlows(action.payload)),
        loading: false,
        hasError: false,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'SET_ERROR': {
      return {
        ...state,
        hasError: true,
      };
    }
    case 'SET_DIRTY': {
      return {
        ...state,
        isDirty: true,
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line max-len
export const TemplateNodeRedListProvider: React.FC<{sessionId: string; children: any}> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const auth = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const fetchNodeRedList = async (sessionId: string) => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/nodered/flows/backup/list`,
        {
          session_id: sessionId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        },
      );
      if (!response.data.result) throw new Error('Something Went Wrong');
      const data = response.data.result;

      dispatch({
        type: 'SET',
        payload: data,
      });
    } catch (err: any) {
      dispatch({ type: 'SET_ERROR' });
      dispatch({ type: 'SET_LOADING', payload: false });
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      // dispatch({ type: 'SET_LOADING' });
    }
  };

  const values = useMemo(() => ({ ...state, dispatch, fetchNodeRedList }), [state]);

  useEffect(() => {
    fetchNodeRedList(props.sessionId);
  }, []);

  return (
    <TemplateNodeRedListContext.Provider value={values}>
      {props.children}
    </TemplateNodeRedListContext.Provider>
  );
};
