import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { cloneDeep, isArray } from 'lodash';

const HdcLine: React.FC<{
  hdc: {
    k: string;
    enabled: true;
    scale: number;
    display_name: string;
  };
  onScaleChange: (val: number) => void;
  onDisplayNameChange: (val: string) => void;
  onRemove: () => void;
}> = ({
  hdc, onScaleChange, onDisplayNameChange, onRemove,
}) => (
  <TableRow>
    <TableCell>{hdc.k}</TableCell>
    <TableCell>
      <TextField
        variant="standard"
        size="small"
        value={hdc.display_name}
        onChange={(e) => {
          onDisplayNameChange(e.target.value);
        }}
      />
    </TableCell>
    <TableCell>
      <TextField
        variant="standard"
        size="small"
        type="number"
        value={hdc.scale}
        onChange={(e) => {
          try {
            onScaleChange(Number(e.target.value));
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </TableCell>
    <TableCell>
      <IconButton size="small" color="error" onClick={() => onRemove()}>
        <CloseIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const NewHdcLine: React.FC<{
  hdcConfig: Record<
    string,
    {
      k: string;
      enabled: true;
      scale: number;
      display_name: string;
    }
  >;
  channels: Record<
    string,
    {
      command?: string;
      command_v?: string;
      channel_type?: string;
      k: string;
      t: string;
      elog?: {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };
      events?: {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };
    }
  >;
  onAdd: (payload: {
    channelKey: string;
    enabled: true;
    scale: number;
    display_name: string;
  }) => void;
}> = ({ hdcConfig, channels, onAdd }) => {
  const [channel, setChannel] = useState('');
  const [scale, setScale] = useState<number>();
  const [name, setName] = useState('');
  return (
    <TableRow style={{ backgroundColor: '#f2f2f2' }}>
      <TableCell>
        <FormControl size="small" style={{ width: '50%' }}>
          <Select
            value={channel}
            variant="standard"
            onChange={(e) => {
              setChannel(e.target.value);
              setScale(1);
            }}
          >
            {Object.keys(channels).map((key) => {
              const channel = channels[key];
              return (
                <MenuItem
                  key={key}
                  disabled={
                    !!hdcConfig[key] || !channel.channel_type || channel.channel_type != 'elec_total'
                  }
                  value={key}
                >
                  {channel.k}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <TextField
          variant="standard"
          size="small"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="standard"
          size="small"
          type="number"
          value={scale}
          onChange={(e) => {
            try {
              setScale(Number(e.target.value));
            } catch (e) {
              console.error(e);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <IconButton
          size="small"
          disabled={!channel || channel.length < 1}
          onClick={() => {
            onAdd({
              channelKey: channel,
              enabled: true,
              scale,
              display_name: name,
            });

            setChannel('');
            setScale(undefined);
          }}
          color="primary"
        >
          <CheckIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export const EnergyMonitoringConfig: React.FC<{
  channels: Record<
    string,
    {
      command?: string;
      command_v?: string;
      channel_type?: string;
      k: string;
      t: string;
      elog?: {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };
      events?: {
        debounce_cond: number;
        debounce_type: string;
        debounce: boolean;
        disabled: boolean;
      };
    }
  >;
  hdc: Record<
    string,
    {
      k: string;
      enabled: true;
      scale: number;
      display_name: string;
    }
  >;
  onSave: (hdcs: any) => void;
}> = ({ channels, hdc, onSave }) => {
  const [hdcConfig, setHdcConfig] = useState(correct);

  useEffect(() => {
    correct();
  }, [hdc, channels]);

  function correct() {
    const correctedHdc = {};
    // Default HDC initialized by the server has wrong channel key. recorrect them here so that the select box works as expected
    if (hdc && isArray(Object.keys(hdc))) {
      Object.keys(hdc).map((key) => {
        const config = hdc[key];
        const correctKey = Object.keys(channels).find((cKey) => {
          const channel = channels[cKey];
          if (channel.k == config.k) {
            return true;
          }
          return false;
        });

        if (correctKey) {
          correctedHdc[correctKey] = config;
        } else {
          correctedHdc[key] = config;
        }
      });
    }

    return correctedHdc;
  }

  return (
    <Box>
      <Box>
        <Alert style={{ fontSize: '12px', margin: '10px 0', padding: '0 6px' }} severity="info">
          Remember to press 'Save Configuration' after edit the table!!
        </Alert>
        <Button type="submit" size="small" variant="outlined" onClick={() => onSave(hdcConfig)}>
          Save Configuration
        </Button>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableCell>Monitoring Channel</TableCell>
            <TableCell>Display Name</TableCell>
            <TableCell style={{ width: 200 }}>Scale (multiply)</TableCell>
            <TableCell style={{ width: 50 }}>Action</TableCell>
          </TableHead>
          <TableBody>
            {Object.keys(hdcConfig).map((key) => {
              const config = hdcConfig[key];
              return (
                <HdcLine
                  hdc={config}
                  key={config.k}
                  onRemove={() => {
                    const hdcCpy = cloneDeep(hdcConfig);
                    delete hdcCpy[key];

                    setHdcConfig(hdcCpy);
                  }}
                  onScaleChange={(val) => {
                    setHdcConfig((config) => ({
                      ...config,
                      [key]: {
                        ...config[key],
                        scale: val,
                      },
                    }));
                  }}
                  onDisplayNameChange={(val) => {
                    setHdcConfig((config) => ({
                      ...config,
                      [key]: {
                        ...config[key],
                        display_name: val,
                      },
                    }));
                  }}
                />
              );
            })}
            <NewHdcLine
              hdcConfig={hdcConfig}
              channels={channels}
              onAdd={(payload) => {
                setHdcConfig((config) => ({
                  ...config,
                  [payload.channelKey]: {
                    k: channels[payload.channelKey].k,
                    enabled: payload.enabled,
                    scale: payload.scale,
                    display_name: payload.display_name,
                  },
                }));
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EnergyMonitoringConfig;
