import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Theme,
  InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import moment from 'moment';
import { CronSunsetSunriseVariables } from '@smartlife-redux-state/common';
import { HoursInDay, MinutesInHour } from '../CRON_TYPES';

export const TIME_SELECTOR_TIME_FORMAT = 'HH:mm';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formRoot: {
    width: '100%',
  },
}));

type ComponentProps = {
  variables: CronSunsetSunriseVariables;
  onChange?: (data: CronSunsetSunriseVariables) => void;
};
export const CronRelativeToSun: React.FC<ComponentProps> = ({ variables, onChange }) => {
  const classes = useStyles();

  return (
    <Box pt={2}>
      <Grid container spacing={2}>
        <Grid item md={3} sm={6} xs={12}>
          <FormControl size="small" variant="outlined" className={classes.formRoot}>
            <InputLabel>When</InputLabel>
            <Select
              label="When"
              value={variables.when}
              onChange={(event: any) => {
                onChange({
                  ...variables,
                  when: event.target.value,
                });
              }}
            >
              <MenuItem value="before" key="before">
                Before
              </MenuItem>
              <MenuItem value="after" key="after">
                After
              </MenuItem>
              <MenuItem value="on" key="on">
                On
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormControl size="small" variant="outlined" className={classes.formRoot}>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              value={variables.based_on}
              onChange={(event: any) => {
                onChange({
                  ...variables,
                  based_on: event.target.value,
                });
              }}
            >
              <MenuItem value="sunrise">Sunrise</MenuItem>
              <MenuItem value="sunset">Sunset</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormControl size="small" variant="outlined" className={classes.formRoot}>
            <InputLabel>Unit</InputLabel>
            <Select
              label="Type"
              value={variables.time_unit}
              onChange={(event: any) => {
                onChange({
                  ...variables,
                  time_unit: event.target.value,
                });
              }}
            >
              <MenuItem value="h">Hours</MenuItem>
              <MenuItem value="m">Minutes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            label="Number of executions"
            type="number"
            variant="outlined"
            size="small"
            value={variables.number_of_execution}
            onChange={(e) => onChange({
              ...variables,
              number_of_execution: Number(e.target.value),
            })}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

CronRelativeToSun.defaultProps = {
  onChange: null,
};
