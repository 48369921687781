import { useEffect, useMemo, useState } from 'react';
import { cloneDeep, isArray } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import CloudIntegration, {
  CiDevice,
  CloudDeviceState,
} from '../../services/cloud-integration.service';

const AddChannel: React.FC<{
  unUsedChannels: string[];
  allChannels: {
    [channelKey: string]: string | number | boolean;
  };
  onAddNewChannel: (
    cKey: string,
    cType: string,
    cMode: string,
    cUnit: string,
    cDescription: string,
    cDisplayTimestamp: boolean
  ) => void;
}> = ({ unUsedChannels, allChannels, onAddNewChannel }) => {
  const [newChannel, setNewChannel] = useState<string>('');
  const [unitType, setUnitType] = useState('');
  const [description, setDescription] = useState('');
  const [newChannelType, setNewChannelType] = useState<string>('');
  const [newChannelMode, setNewChannelMode] = useState('');
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);
  const [displayTimestamp, setDisplayTimestamp] = useState(false);

  return (
    <>
      <Dialog open={showAddConfirmation}>
        <DialogTitle>Confirmation to proceed ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to add channel
            {' '}
            {newChannel}
            {' '}
            to the device state in SmartLife ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddConfirmation(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onAddNewChannel(newChannel, newChannelType, newChannelMode, unitType, description,displayTimestamp);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <TableCell width="250">
        <FormControl style={{ width: '100%' }} variant="standard">
          <InputLabel>Add a Channel</InputLabel>
          <Select
            value={newChannel}
            onChange={(e) => setNewChannel(e.target.value)}
            size="small"
            label="Add a Channel"
          >
            {isArray(unUsedChannels)
              && unUsedChannels.map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                  {' '}
                  {allChannels[key] ? (
                    <span style={{ color: 'gray' }}>{`-${allChannels[key]}`}</span>
                  ) : (
                    ''
                  )}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell width="100">
        <FormControl style={{ width: '100%' }} variant="standard">
          <TextField
            value={unitType}
            onChange={(e) => setUnitType(e.target.value)}
            label="Unit"
            size="small"
            variant="standard"
          />
        </FormControl>
      </TableCell>
      <TableCell width="140">
        <FormControl style={{ width: '100%' }} variant="standard">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            size="small"
            variant="standard"
          />
        </FormControl>
      </TableCell>
      <TableCell width="140">
        <FormControl style={{ width: '100%' }} variant="standard">
          <InputLabel>Data Type</InputLabel>
          <Select
            value={newChannelType}
            onChange={(e) => setNewChannelType(e.target.value)}
            size="small"
            label="Data Type"
          >
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="string">String</MenuItem>
            <MenuItem value="boolean">Boolean</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell width="100">
        <FormControl style={{ width: '100%' }} variant="standard">
          <InputLabel>Type</InputLabel>
          <Select
            value={newChannelMode}
            onChange={(e) => setNewChannelMode(e.target.value)}
            size="small"
            label="Type"
          >
            <MenuItem value="">-</MenuItem>
            <MenuItem value="elec_total">elec_total</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell width="200">
        <FormControl style={{ width: '100%' }} variant="standard">
          <InputLabel>Timestamp Show</InputLabel>
          <Checkbox
          size="small"
          checked={displayTimestamp} onChange={() => setDisplayTimestamp(!displayTimestamp)} />
        </FormControl>
      </TableCell>
      <TableCell>
        <IconButton
          disabled={!newChannel || !newChannelType}
          onClick={() => setShowAddConfirmation(true)}
          size="small"
          color="primary"
        >
          <CheckIcon style={{ fontSize: '15px' }} />
        </IconButton>
      </TableCell>
    </>
  );
};

const EditChannel: React.FC<{
  channel: any;
  onEditChannel: (cUnit: string, cDescription: string, cDisplayTimestamp: boolean) => void;
  onCloseForm: () => void;
}> = ({ channel, onEditChannel, onCloseForm }) => {
  const [unitType, setUnitType] = useState(channel.units_label);
  const [description, setDescription] = useState(channel.description);
  const [showAddConfirmation, setShowAddConfirmation] = useState(false);

  const [displayTimestamp, setDisplayTimestamp] = useState(channel.timestamp_display == undefined || channel.timestamp_display ? true : false);

  return (
    <>
      <Dialog open={showAddConfirmation}>
        <DialogTitle>Confirmation to edit ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to edit the channel
            {' '}
            {channel.k}
            {' '}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddConfirmation(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onEditChannel(unitType, description, displayTimestamp);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <TableCell>{channel.k}</TableCell>
      <TableCell>
        <FormControl style={{ width: '100%' }} variant="standard">
          <TextField
            value={unitType}
            onChange={(e) => setUnitType(e.target.value)}
            label="Unit"
            size="small"
            variant="standard"
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl style={{ width: '100%' }} variant="standard">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            size="small"
            variant="standard"
          />
        </FormControl>
      </TableCell>
      <TableCell>{channel.t}</TableCell>
      <TableCell>{channel.channel_type ?? '-'}</TableCell>
      <TableCell>   <Checkbox
          size="small"
          checked={displayTimestamp} onChange={() => setDisplayTimestamp(!displayTimestamp)} /></TableCell>
      <TableCell>
        <Tooltip title="Save Changes">
          <IconButton onClick={() => setShowAddConfirmation(true)} size="small" color="primary">
            <CheckIcon style={{ fontSize: '15px' }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="close">
          <IconButton
            onClick={() => {
              onCloseForm();
            }}
            size="small"
            color="default"
          >
            <CloseIcon style={{ fontSize: '13px' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );
};

const ChannelRow: React.FC<{
  channel: any;
  onRemove: (channel: any) => void;
  onEditChannel: (channel: any) => Promise<void>;
}> = ({ channel, onRemove, onEditChannel }) => {
  const [showEdit, setShowEdit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  console.log(channel)

  if (showEdit) {
    return (
      <EditChannel
        channel={channel}
        onEditChannel={async (cUnit, cDescription, cDisplayTimestamp) => {
          try {
            await onEditChannel({
              ...channel,
              units_label: cUnit,
              description: cDescription,
              timestamp_display: cDisplayTimestamp,
            });
            setShowEdit(false);
            enqueueSnackbar('Channel Edited', { variant: 'success' })
          } catch (err: any) {
            enqueueSnackbar('Unable to edit the channel', { variant: 'error' });
          }
        }}
        onCloseForm={() => setShowEdit(false)}
      />
    );
  }

  return (
    <>
      <TableCell>{channel.k}</TableCell>
      <TableCell>{channel.units_label ?? '-'}</TableCell>
      <TableCell>{channel.description ?? '-'}</TableCell>
      <TableCell>{channel.t}</TableCell>
      <TableCell>{channel.channel_type ?? '-'}</TableCell>
      <TableCell>{channel.timestamp_display == undefined || channel.timestamp_display ? 'true' : 'false'}</TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            setShowEdit(true);
          }}
          size="small"
          color="info"
        >
          <EditIcon style={{ fontSize: '13px' }} />
        </IconButton>
        <IconButton
          onClick={() => {
            onRemove(channel);
          }}
          size="small"
          color="error"
        >
          <CloseIcon style={{ fontSize: '13px' }} />
        </IconButton>
      </TableCell>
    </>
  );
};

export const AdvancedDeviceConfig: React.FC<{
  device: CiDevice;
  integration: CloudIntegration;
  onAddNewChannel: (newChannel: any) => void;
  onSfsChanged: (updatedSfs: any) => void;
}> = ({
  device, integration, onAddNewChannel, onSfsChanged,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dState, setdState] = useState<CloudDeviceState>();

  const usedChannels = useMemo(() => {
    if (device?.settings?.sf) {
      const c = {};
      Object.keys(device.settings.sf).map((fKey) => {
        c[device.settings.sf[fKey].k] = {
          ...device.settings.sf[fKey],
          fKey,
        };
      });
      return c;
    }
    return {};
  }, [device]);

  const allChannels = useMemo(() => dState?.result?.state?.mapped ?? {}, [dState]);

  const unUsedChannels = useMemo(
    () => Object.keys(allChannels).filter((cKey) => !usedChannels[cKey]),
    [allChannels],
  );

  useEffect(() => {
    if (!device) return;
    async function init() {
      try {
        const res = await integration.getCloudDeviceState(device.id);
        setdState(res);
      } catch (err) {}
    }
    init();
  }, [device]);

  const [toBeRemoveChannel, setToBeRemoveChannel] = useState('');

  const [addCompKey, setAddCompKey] = useState(0);
  async function addNewChannel(
    cKey: string,
    cType: string,
    cMode: string,
    cUnit: string,
    cDescription: string,
    cDisplayTimestamp: boolean,
  ) {
    try {
      const newChannel: any = {
        t: cType,
        k: cKey,
        units_label: cUnit,
        description: cDescription,
        timestamp_display: cDisplayTimestamp,
        events: {
          debounce: true,
          debounce_cond: 0,
          debounce_type: 'change',
          disabled: false,
        },
      };

      if (cMode) {
        newChannel.channel_type = cMode;
      }

      const res = await integration.editCloudDevice(device.id, {
        ...device.settings,
        sf: {
          ...device.settings.sf,
          [`f${Object.keys(device.settings.sf).length + 1}`]: newChannel,
        },
      });
      onAddNewChannel(newChannel);
      enqueueSnackbar('Channel Added.', { variant: 'success' });
      setAddCompKey((current) => current + 1);
    } catch (err) {
      enqueueSnackbar('Unabled to add the channel', { variant: 'error' });
    }
  }

  async function removeChannel(cKey: string) {
    try {
      const channel = usedChannels[cKey];
      const hdc = device?.settings?.hdc && device?.settings?.hdc[channel.fKey];

      if (hdc) {
        enqueueSnackbar(
          'This channel is used in energy monitoring. In order to remove the channel, remove it first from the energy monitoring',
          { variant: 'error' },
        );
        return;
      }

      const sfs = cloneDeep(device.settings.sf);
      delete sfs[channel.fKey];

      const res = await integration.editCloudDevice(device.id, {
        ...device.settings,
        sf: sfs,
      });
      setToBeRemoveChannel('');
      onSfsChanged(sfs);
      enqueueSnackbar('Channel Removed.', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Unable to remove the channel', { variant: 'error' });
      console.error(err);
    }
  }

  async function editChannel(channel: any) {
    const sfs = cloneDeep(device.settings.sf);
    sfs[channel.fKey] = {
      ...channel,
    };
    delete sfs[channel.fKey].fkey;

    const res = await integration.editCloudDevice(device.id, {
      ...device.settings,
      sf: sfs,
    });

    onSfsChanged(sfs);
  }

  return (
    <Box style={{ overflow: 'auto' }}>
      <Dialog open={toBeRemoveChannel.length > 0}>
        <DialogTitle>Confirmation to delete ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the channel
            {' '}
            {toBeRemoveChannel}
            {' '}
            from SmartLife ? This
            process cannot be undo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setToBeRemoveChannel('')}>Cancel</Button>
          <Button
            onClick={() => {
              removeChannel(toBeRemoveChannel);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Channel</TableCell>
            <TableCell width="100">Unit</TableCell>
            <TableCell>Description</TableCell>
            <TableCell width="140">Data Type</TableCell>
            <TableCell width="100">Type</TableCell>
            <TableCell>Timestamp Show</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(usedChannels).map((channelKey) => (
            <TableRow key={channelKey}>
              <ChannelRow
                channel={usedChannels[channelKey]}
                onRemove={(channel) => {
                  setToBeRemoveChannel(channel.k);
                }}
                onEditChannel={editChannel}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table>
        <TableRow>
          <AddChannel
            key={addCompKey}
            allChannels={allChannels}
            unUsedChannels={unUsedChannels}
            onAddNewChannel={addNewChannel}
          />
        </TableRow>
      </Table>
    </Box>
  );
};
