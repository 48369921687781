/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Extended draggable card component using renderProps pattern.
 *
 * @author Maduka Dilshan
 */
import React from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

const Draggablex: any = Draggable;

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

const grid = 8;
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  // padding: grid * 2,
  margin: `10px 0 ${grid}px 0`,
  background: 'none',
  filter: isDragging ? 'blur(2px)' : '',
  ...draggableStyle,
});

type CardRenderPropArgs = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
};

type CardProps = {
  draggableId: string;
  dragDisabled?: boolean;
  index: number;
  style?: (isDragging: boolean, draggableStyle: any) => any;
  children: (args: CardRenderPropArgs) => React.ReactChild;
};

// eslint-disable-next-line import/prefer-default-export
export const Card: React.FC<CardProps> = React.memo(
  ({
    draggableId, children, index, dragDisabled, style = getItemStyle,
  }) => {
    const renderProp = unwrapArray(children);

    return (
      <Draggablex draggableId={draggableId} index={index} isDragDisabled={dragDisabled}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
                // {...provided.dragHandleProps}
            style={
                  style
                    ? style(snapshot.isDragging, provided.draggableProps.style)
                    : { ...provided.draggableProps.style }
                }
          >
            {renderProp({
              provided,
              snapshot,
            })}
          </div>
        )}
      </Draggablex>
    );
  },
);
