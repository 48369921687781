import { useContext } from 'react';
import { CloneStepSummaryContext } from '../context/cloneStepSummery';

const useStepSummery = () => {
  const summery = useContext(CloneStepSummaryContext);

  return summery;
};

export default useStepSummery;
