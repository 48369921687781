/* eslint-disable no-empty */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  FormControl, FormHelperText, Grid, TextField, Autocomplete,
} from '@mui/material';
import ReactQuill from 'react-quill';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useState, useDeferredValue, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Action, EmailAction as EmailActionType } from '@smartlife-redux-state/common';
import { getProjectMembersGraphQL } from 'src/services/member/member.service';
import { cloneDeep, isArray } from 'lodash';
import { ApolloAuthContext } from '../../../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../../../store/Project/ProjectContext';
import { update_action } from '../../../../../../../store/redux/features/logic-engine/slice';
import 'react-quill/dist/quill.snow.css';
import { AddVariable } from './shared/AddVariable';
import { VarItem } from './shared/VarItem';

const useStyle = makeStyles({
  form_root: {
    width: '100%',
  },
});

const EmailSelector: React.FC<{emails: string[]; suggestions: string[]; onValueChange: any}> = ({
  emails,
  suggestions,
  onValueChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Autocomplete
      freeSolo
      multiple
      options={suggestions}
      getOptionLabel={(option) => option}
      value={emails}
      // eslint-disable-next-line consistent-return
      onChange={(arg1, arg2) => {
        if (isArray(arg2)) {
          if (arg2.length === 0) return onValueChange(arg1, arg2);
          const lastItem = arg2[arg2.length - 1];
          if (/^\S+@\S+\.\S+$/.test(lastItem)) {
            onValueChange(arg1, arg2);
          } else {
            enqueueSnackbar(`"${lastItem}" is not an valid email`, { variant: 'error' });
          }
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label="Emails to send message" variant="outlined" size="small" />
      )}
    />
  );
};

// eslint-disable-next-line import/prefer-default-export
export const EmailAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const actionCpy: EmailActionType = action as any;
  const classes = useStyle();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [emailSuggestions, setEmailsSuggestions] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState('');
  const [desc, setDesc] = useState('');

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);

  const differedDesc = useDeferredValue(desc);
  const differedEmails = useDeferredValue(emails);
  const differedSubjects = useDeferredValue(subject);

  const items = useMemo<
    Record<
      string,
      {
        path: string;
        deviceId: string;
      }
    >
  >(() => action.variable_value ?? {}, [action]);

  useEffect(() => {
    try {
      dispatch(
        update_action({
          action: {
            ...action,
            payload: {
              ...action.payload,
              emails_list: differedEmails,
              email_subject: differedSubjects,
              email_body: btoa(differedDesc),
            } as any,
          },
          isChild,
          parentAction,
        }),
      );
    } catch (err) {}
  }, [differedDesc, differedEmails, differedSubjects]);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await getProjectMembersGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          -1,
          -1,
        );
        if (res.projectListUsers && isArray(res.projectListUsers.users)) {
          const filtered = [];
          // eslint-disable-next-line array-callback-return
          res.projectListUsers.users.map((item) => {
            if (item.id && item.email) {
              filtered.push(item.email);
            }
          });
          setEmailsSuggestions(filtered);
        }
        if (actionCpy.payload) {
          setEmails(actionCpy.payload.emails_list);
          setSubject(actionCpy.payload.email_subject);
          setDesc(atob(actionCpy.payload.email_body));
        }
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  function handleRemoveVariable(key) {
    const filtered = cloneDeep(action.variable_value);
    delete filtered[key];
    dispatch(
      update_action({
        action: {
          ...action,
          variable_value: filtered,
        },
        isChild,
        parentAction,
      }),
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} />
      <Grid item md={12}>
        <EmailSelector
          emails={emails}
          suggestions={emailSuggestions}
          onValueChange={(e, val) => setEmails(val)}
        />
        <FormHelperText style={{ color: '#1976D2' }}>
          Type & hit enter to add emails that are not available in suggestion list.
        </FormHelperText>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={1}>
          {Object.keys(items).map((varKey) => (
            <Grid
              item
              md={3}
              sm={4}
              xs={6}
              // eslint-disable-next-line react/no-array-index-key
              key={varKey}
            >
              <VarItem
                item={items[varKey]}
                key={varKey}
                disableEditor
                onRemove={() => {
                  handleRemoveVariable(varKey);
                }}
              />
            </Grid>
          ))}
          <Grid item md={2}>
            <AddVariable
              bypassValidation
              action={action}
              isChild={isChild}
              parentAction={parentAction}
              onAddNewVariable={({ varName, firebasePath, device }) => {
                dispatch(
                  update_action({
                    action: {
                      ...action,
                      variable_value: {
                        ...action.variable_value,
                        [varName]: {
                          firebasePath,
                          name: varName,
                          deviceId: device.id,
                        },
                      },
                    },
                    isChild,
                    parentAction,
                  }),
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <FormControl className={classes.form_root}>
          <TextField
            label="Subject"
            variant="outlined"
            size="small"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item md={12}>
        <FormControl className={classes.form_root}>
          <ReactQuill theme="snow" value={desc} onChange={setDesc} />
        </FormControl>
      </Grid>
    </Grid>
  );
};
