/* eslint-disable camelcase */
export enum DeviceCollectionType {
  proDevice = 'pro',
  airDevice = 'air',
  cintDevice = 'cint',
}
export interface ICommDevice {
  id?: string;
  created_by?: number;

  project_id: number;
  dc_id: number;

  prodc_id: number;
  airdc_id: number;

  name: string;

  tags: string[];
  system_tags: string[];

  shadow_type: string;

  cat: string;
  sub_cat: string;
  device_type: string;

  device_status: number;

  settings: string | any;

  created_at?: number | string;
  updated_at?: number | string;

  tuya_device_id?: string;

  dc_type: string; // use in web app only
  dc_name?: string;
}
