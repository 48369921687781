/* eslint-disable max-len */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * A button use in logic module to render form buttons
 *
 * @author Maduka Dilshan
 */

import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';

const useStyle = makeStyles({
  root: {
    background: 'linear-gradient(239.95deg, #048696 7.54%, #06454D 92.1%)',
    borderRadius: '5px',
    color: 'white',
    padding: '1% 5%',
  },
  secondaryRoot: {
    background: '#F9F9F9',
    borderRadius: '5px',
    border: '1px solid #06454D',
    color: '#06454D',
    padding: '1% 5%',
    boxSizing: 'border-box',
  },
});
// eslint-disable-next-line import/prefer-default-export
export const FillButton: React.FC<{
  children: React.ReactChild;
  onClick: () => void;
  className?: string;
  variant?: string;
  disabled?: boolean;
  loading?: boolean
}> = ({
  children, onClick, className = '', variant = 'primary', disabled, loading,
}) => {
  const styles = useStyle();
  if (variant === 'secondary') {
    return (
      <LoadingButton loading={loading} loadingIndicator="Processing..." onClick={onClick} className={clsx(styles.secondaryRoot, className)} disabled={disabled}>
        {children}
      </LoadingButton>
    );
  }
  return (
    <LoadingButton loading={loading} loadingIndicator="Processing..." onClick={onClick} className={clsx(styles.root, className)} disabled={disabled}>
      {children}
    </LoadingButton>
  );
};

FillButton.defaultProps = {
  className: '',
  variant: 'primary',
  disabled: false,
  loading: false,
};
