/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  ReactChild, Suspense, useContext, useEffect,
} from 'react';

import './theme/colors.css';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { Provider } from 'react-redux';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import styles from './App.module.css';
import { AuthProviderComponent } from './store/Auth/AuthContext';
import { ApolloAuthContext, ApolloProviderComponent } from './store/Apollo/ApolloContext';
import { MainRouterComponent } from './containers/main-router/main-router';
import { ThemeContextComponent } from './theme/ThemContext';
import { ProjectProviderComponent } from './store/Project/ProjectContext';
import createStore from './store/redux/store';
import { ReduxStoreProvider } from './store/redux';
import AppRouter from './Router';
import ListProvider from './containers/app/Lists/provider';
import PluginProvider from './store/Plugins/PluginProvider';

// eslint-disable-next-line max-len
const composeProviders = (...providers: any[]) => ({ children }: {children: React.ReactChild}) => providers.reduceRight((child, Provider) => <Provider>{child}</Provider>, children);

const CustomProviders = composeProviders(
  ThemeContextComponent,
  AuthProviderComponent,
  PluginProvider,
  ApolloProviderComponent,
  ProjectProviderComponent,
  ReduxStoreProvider,
);

function App() {
  return (
    <CustomProviders>
      <SnackbarProvider maxSnack={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ListProvider>
            <div className={styles.root}>
              <Suspense fallback={<div>loading...</div>}>
                <AppRouter />
                {/* <MainRouterComponent /> */}
              </Suspense>
            </div>
          </ListProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </CustomProviders>
  );
}

export default App;
