/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FiCopy } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { WidgetHeaderLayout } from './WidgetHeaderLayout';

const useStyle = makeStyles({
  root: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(2px)',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    // lineeight: 25px;
  },
  content_root: {
    width: '100%',
    padding: '10px',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    width: '100%',
  },
  card_root: {
    marginBottom: '10px',
    // minHeight: "50%",
    maxHeight: '50%',
  },
  card: {
    background: 'white',
    borderRadius: '5px',
    padding: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const UserViewsWidegt: React.FC<{userViewList: any[]}> = ({ userViewList }) => {
  const styles = useStyle();
  const navgiate = useNavigate();
  return (
    <div className={styles.root}>
      <WidgetHeaderLayout>
        <Box className={styles.header}>
          <Typography className={styles.title}>
            <FiCopy />
            {' '}
            User Views
          </Typography>
        </Box>
      </WidgetHeaderLayout>
      <div className={clsx(styles.content_root, 'row')}>
        {userViewList.map((userView: any) => (
          <div
            className={clsx('col-sm-12 col-md-6', styles.card_root)}
            key={userView.id}
            onClick={() => {
              navgiate(
                `/app/user-view?userViewId=${userView.id}&projectId=${userView.project_id}`,
              );
            }}
          >
            <div className={styles.card}>
              <span
                className={clsx('calc', userView.icon_name)}
                style={{
                  marginRight: '5px',
                  fontSize: '1.2rem',
                  color: 'white',
                  background: 'rgba(60, 109, 115, 0.7)',
                  padding: '5px',
                  borderRadius: '5px',
                }}
              />
              <div>
                <Typography>{userView.name}</Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
