/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-promise-executor-return */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import {
  buildActionPayload,
  buildEmptyLogicPayload,
  Action,
  toNormalize,
} from '@smartlife-redux-state/common';
import { insertInOrder } from '@smartlife-redux-state/orm';
import { CLOUD_INTEGRATIONS, CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { ITEM_DEFAULTS } from '../../../../../../config/deviceConfig/item_defaults';
import { appConsoleError, appConsoleLog } from '../../../../../../utility/appUtils';
import {
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
} from '../../../../../Alters/Snackbar/SnackbarOptions';
import uvService from '../../../../service/uv.service';
import {
  IUserViewItem,
  UserViewItemVisibility,
  IUVCustomError,
  UserViewItemCategory,
} from '../../../../types/types';
import { getFolderIdFromPathRef } from '../../../../utils';
import { UVContext, UVReducerTypes } from '../../../../UVContext';
import { DeviceCollectionType } from '../../../UVDeviceFinder/types';
import { UVItemCreatorDynamic } from '../UVItemCreatorDynamic';
import { ICommDevice } from '../../../UVItemFinder/types';

import { ApolloAuthContext } from '../../../../../../store/Apollo/ApolloContext';
import { UVCreatorStep } from '../ItemCreateDialog';
import { useTree } from '../../../../../shared/TreeView';
import { RootState, useDispatch, useSelector } from '../../../../../../store/redux/store';
import { AuthContext } from '../../../../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../../../../store/Project/ProjectContext';
import { userviewGetGraphQL } from '../../../../../../services/user-view/user-view.service';

interface FormType {
  display_name: string;
  visibility: boolean;
  rule_name: string;
  macro_name: string;
  item_notification_type: number;
}
type ComponentProps = {
  itemCategoryType: UserViewItemCategory;
  isUpdate: boolean;
  itemId?: string;
  selectedDevice: ICommDevice | (CiDevice & {dc_type: string}) | null;
  formRef: any;
  setCreatorStep: (step: any) => void;
  closeDialog: (result: boolean) => void;
  setDevice: React.Dispatch<
    React.SetStateAction<
      | ICommDevice
      | (CiDevice & {
          dc_type: string;
        })
    >
  >;
};
// eslint-disable-next-line import/prefer-default-export
export const UVItemCreator: React.FunctionComponent<ComponentProps> = ({
  itemCategoryType,
  isUpdate,
  itemId,
  selectedDevice,
  formRef,
  setCreatorStep,
  closeDialog,
  setDevice,
}) => {
  const snackbar = useSnackbar();
  const { selectedDeviceId } = useTree();
  const [openLogicNavigationDialog, setOpenLogicNavigationDialog] = React.useState(false);
  const navigate = useNavigate();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);
  const selectedProject = useContext(ProjectContext);

  const [selectedIcon, selectNewIcon] = useState('calc-door2');
  const [selectedColor, selectNewColor] = useState('#FFFFFF');
  const [isLoading, setLoading] = useState(false);

  const [showOverlay, setOverlay] = useState(false);

  const [uvSettings, setUVSettings] = useState<any>(null);
  const [userviewDeviceSettings, setUserviewDeviceSettings] = useState<any>({});
  const [customFieldsErrors, updateCustomFieldErrors] = useState<{
    [key: string]: IUVCustomError;
  }>({});

  const [currentFolderItem, setCurrentFolderItem] = useState<IUserViewItem | null>(null);
  const [formDefaults, setFormDefaults] = useState({
    display_name: '',
    visibility: true,
    rule_name: '',
    macro_name: '',
    item_notification_type: 1,
  });

  // set current item properties when update/edit
  useEffect(() => {
    const init = async () => {
      try {
        if (itemId === undefined || itemId.length < 1) {
          return;
        }
        const res = await uvService.userviewItemGet(userProfile.apollo_client, itemId);

        const uvItem = res.item;
        uvItem.settings = JSON.parse(uvItem.settings);
        setCurrentFolderItem(uvItem);
        if (res.proDevice != null) {
          setDevice({
            ...res.proDevice,
            dc_type: DeviceCollectionType.proDevice,
          });
        } else if (res.airDevice != null) {
          setDevice({
            ...res.airDevice,
            dc_type: DeviceCollectionType.airDevice,
          });
        } else if (res.cintDevice != null) {
          setDevice({
            ...res.cintDevice,
            dc_type: DeviceCollectionType.cintDevice,
          });
        } else {
          snackbar.enqueueSnackbar('Item device not found', { variant: 'error' });
          return;
        }
        setCreatorStep(UVCreatorStep.secondStep);

        setTimeout(() => {
          setFormDefaults({
            display_name: res.item.display_name,
            visibility: res.item.item_visibility === UserViewItemVisibility.show,
            rule_name: res.item.rule_name,
            macro_name: res.item.macro_name,
            item_notification_type: res.item.item_notification_type,
          });

          selectNewColor(res.item.item_color);
          selectNewIcon(res.item.icon_name);
        }, 500);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Unexpacted error', {
          key: 'uv-item-update-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
        throw err;
      }
      setLoading(false);
    };
    if (isUpdate) {
      setLoading(true);
      init();
    }
  }, [itemId]);

  const appendSettings = (payload: any) => {
    // debugger;
    const { data } = payload;
    setUserviewDeviceSettings((oldData: any) => ({ ...oldData, ...data }));
  };
  useEffect(() => {
    if (selectedDevice != null) {
      let currentUvSettings: any = null;
      if (selectedDevice.dc_type === DeviceCollectionType.proDevice) {
        const itemConfigs: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
        const config = itemConfigs[selectedDevice.cat].children[selectedDevice.sub_cat];
        if (!config) {
          return;
        }
        currentUvSettings = config.features.uv_settings;
        if (!currentUvSettings) {
          appConsoleError('Item Userview config not exist');
          snackbar.enqueueSnackbar('Device userview settings not updated', {
            key: 'uv-item-config-error',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
          return;
        }
      } else if (selectedDevice.dc_type === DeviceCollectionType.airDevice) {
        const itemConfigs: any = ITEM_DEFAULTS.ITEM_CREATOR.AIR_UV_DEVICE_MAP.uv_types;

        let config = itemConfigs[selectedDevice.device_type];

        if (!config) {
          config = itemConfigs[ITEM_DEFAULTS.ITEM_CREATOR.AIR_UV_DEVICE_MAP.default_uv_type];
          return;
        }
        currentUvSettings = config.uv_settings;
        if (!currentUvSettings) {
          appConsoleError('Item Userview config not exist');
          snackbar.enqueueSnackbar('Device userview settings not updated', {
            key: 'uv-item-config-error',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
          return;
        }
      } else if (selectedDevice.dc_type === DeviceCollectionType.cintDevice) {
        const config = pluginHook.commonIntegrationService.getDeviceUvFieldSettings(
          selectedDevice.device_type,
        );

        currentUvSettings = config;
      }

      if (currentUvSettings == null) {
        return;
      }
      setUVSettings(currentUvSettings);
    }
  }, [selectedDevice]);

  const authUser = useContext(AuthContext);
  const logic = useSelector((state: RootState) => state.rule_engine);
  const orm = useSelector((state: RootState) => state.orm);
  const dispatch = useDispatch();

  /**
   * If the actions.device is empty that mean the user did not selected any device in extra
   * fields ( Audio, video devices ). So do not create a logic
   * @param actions
   * @returns
   */
  function shouldLogicCreat(actions: Action[]) {
    const filtered = actions.filter((action) => {
      let flag = true;

      if (action.device == null) {
        flag = false;
      }
      if (action.dc && action.dc.dc_type === 8) {
        flag = false;
      }
      // if (action.variable == null || action.variable.length == 0) {
      //   flag = false;
      // }

      return flag;
    });
    // debugger;
    if (filtered.length > 0) {
      return true;
    }
    return false;
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [shouldCallOnSubmit, setShoulCallOnSubmit] = useState(false);
  const [submitData, setSubmitData] = useState({
    values: null,
    formikHelpers: null,
    flag: null,
  });
  const prepareNormalizedLogic = async () => {
    if (logic) {
      const normalized = toNormalize(logic);
      insertInOrder(normalized, dispatch);
    }
    setShoulCallOnSubmit(true);
  };

  useEffect(() => {
    if (!shouldCallOnSubmit) return;
    if (!submitData.values) return;
    if (Object.keys(orm.LogicActionCard.itemsById).length > 0) {
      const { values, formikHelpers, flag } = submitData;
      onSubmitForm1(values, formikHelpers, flag);
    }
  }, [shouldCallOnSubmit, orm, submitData]);

  const onSubmitForm = async (values: FormType, formikHelpers: any, flag: string) => {
    if (shouldLogicCreat(logic.actions) && !isUpdate) {
      setSubmitData({
        values,
        formikHelpers,
        flag,
      });
      prepareNormalizedLogic();
    } else {
      onSubmitForm1(values, formikHelpers, flag);
    }
  };

  const onSubmitForm1 = async (values: FormType, formikHelpers: any, flag: string) => {
    let skipLogicCreation = false;
    if (flag === 'cancel') {
      return;
    }
    if (!values.display_name || values.display_name.length === 0) {
      formikHelpers.setFieldError('display_name', 'Item Name is Required');
      snackbar.enqueueSnackbar('Display Name is Required', {
        variant: 'error',
      });
      return;
    }
    try {
      setOverlay(true);
      // console.log("form values", values);
      const parentFolder = getFolderIdFromPathRef(userViewContext.userViewPath);
      const { projectId, userViewId } = userViewContext;

      const cfErrors: IUVCustomError[] = Object.values(customFieldsErrors).filter(
        (item) => item.invalid,
      );
      if (cfErrors.length > 0) {
        throw cfErrors[0].message;
      }
      let genaratedLogicId = null;

      // debugger;
      // Creating the logic
      // Logics will create when it is first time "CREATE" uv
      // or if user force to recreate when updating a uv item
      if (
        (!isUpdate || flag === 'recreate' || flag === 'recreate-and-go')
        && shouldLogicCreat(cloneDeep(logic.actions))
      ) {
        try {
          // "IR" devices at the moment
          // selectedDevice.cat == "ac"
          if (selectedDevice.cat === 'ir') {
            // dispatch(validate_main_action())
            const parsedChecks = buildEmptyLogicPayload();
            const parsedActions = buildActionPayload(cloneDeep(logic.actions));

            const uvFolderIds = userViewContext.userViewPath.split('-');
            let currentFolderId = '';
            if (uvFolderIds.length > 0) {
              currentFolderId = uvFolderIds[uvFolderIds.length - 1];
            }

            let folderId = getFolderIdFromPathRef(currentFolderId);

            if (!folderId || folderId === 'root') {
              const res = await userviewGetGraphQL(
                userProfile.apollo_client,
                userViewContext.userViewId,
              );
              if (res.userviewGet) {
                // eslint-disable-next-line camelcase
                const { home_folder_id } = res.userviewGet.userview;
                // eslint-disable-next-line camelcase
                folderId = home_folder_id;
              }
            }

            const folder = await uvService.userviewListFolderItems(
              userProfile.apollo_client,
              userViewContext.userViewId,
              folderId,
              1,
              0,
            );
            if (parsedActions.length === 0) throw new Error('Empty Actions');

            const { token } = authUser.access_token;

            const payload = {
              project_id: projectId,
              status: 'active',
              name:
                flag === 'recreate' || flag === 'recreate-and-go'
                  ? `Macro - ${userViewContext.userViewName}[ ${folder.folder.display_path_ref} ]`
                    + `(${values.display_name.trim()} - recreated at ${new Date().toLocaleString()})`
                  : `Macro -${userViewContext.userViewName}[ ${folder.folder.display_path_ref} ]`
                    + `(${values.display_name.trim()})`,
              alexa_friendly_name: '',
              input_json: JSON.stringify(orm),
              triggers: [],
              rules: parsedChecks.rules,
              logic: parsedChecks.logic,
              actions: parsedActions,
            };

            const res = await axios.post(
              `${
                process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
              }/rest/logic-engine/v1/logic-item/create`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            genaratedLogicId = res.data.result.logic_id;

            // setTimeout(async (ormArg) => {
            //   const { token } = authUser.access_token;

            //   const payload = {
            //     project_id: projectId,
            //     status: 'active',
            //     name:
            //       flag === 'recreate' || flag === 'recreate-and-go'
            //         ? `Macro - ${
            //           userViewContext.userViewName
            //         }[ ${
            //           folder.folder.display_path_ref
            //         } ]`
            //           + `(${
            //             values.display_name.trim()
            //           } - recreated at ${
            //             new Date().toLocaleString()
            //           })`
            //         : `Macro -${
            //           userViewContext.userViewName
            //         }[ ${
            //           folder.folder.display_path_ref
            //         } ]`
            //           + `(${
            //             values.display_name.trim()
            //           })`,
            //     alexa_friendly_name: '',
            //     input_json: JSON.stringify(ormArg),
            //     triggers: [],
            //     rules: parsedChecks.rules,
            //     logic: parsedChecks.logic,
            //     actions: parsedActions,
            //   };

            //   const res = await axios.post(
            //     `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
            //     }/rest/logic-engine/v1/logic-item/create`,
            //     payload,
            //     {
            //       headers: {
            //         Authorization: `Bearer ${token}`,
            //       },
            //     },
            //   );
            //   genaratedLogicId = res.data.result.logic_id;
            // }, 2000, orm);
          }
        } catch (err) {
          skipLogicCreation = true;
          snackbar.enqueueSnackbar('Skipping Logic creation due to insufficient data', {
            variant: 'info',
          });
          throw err;
        }
      }

      if (isUpdate) {
        const modifiedDeviceSetting = { ...userviewDeviceSettings };
        console.log('zzz', modifiedDeviceSetting);
        // console.log("xcxcxcxcxcxcxcxcxcxc", currentFolderItem);

        if (currentFolderItem.settings) {
          const settings = typeof currentFolderItem.settings === 'string'
            ? JSON.parse(currentFolderItem.settings)
            : currentFolderItem.settings;

          modifiedDeviceSetting.generated_logic_id = settings.generated_logic_id;

          // if user force recreating when update the uvitem then force to
          // get the logic id from newly created logic but not from the device setting.
          // selectedDevice.cat == "ac" ||
          if (
            !skipLogicCreation
            && shouldLogicCreat(logic.actions)
            && (flag === 'recreate' || flag === 'recreate-and-go')
            && selectedDevice.cat === 'ir'
          ) {
            const macors = values.macro_name.split(',');
            // console.log(
            //   "vbbnbnbnbnbnbnbn",
            //   modifiedDeviceSetting.generated_logic_id,
            //   currentFolderItem.settings,
            //   genaratedLogicId,
            //   values.macro_name
            // );
            const oldGenerateMacroIndex = macors.findIndex(
              (item) => item === modifiedDeviceSetting.generated_logic_id,
            );

            if (oldGenerateMacroIndex > -1) {
              macors[0] = genaratedLogicId;
              values.macro_name = macors.toString();
            } else {
              values.macro_name = genaratedLogicId;
            }

            const oldGeneratedRuleId = modifiedDeviceSetting.generated_logic_id;
            const { token } = authUser.access_token;

            if (oldGeneratedRuleId) {
              try {
                await axios.post(
                  `${
                    process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
                  }/rest/logic-engine/v1/logic-item/delete`,
                  {
                    logic_id: oldGeneratedRuleId,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  },
                );
              } catch (err) {
                console.log('Deleting Old Logic Failed');
              }
            }

            modifiedDeviceSetting.generated_logic_id = genaratedLogicId;
          }
          // console.log("cxcxcxcxcxc", modifiedDeviceSetting, settings);
        }
        const deviceItem: any = {
          display_name: values.display_name.trim(),
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          item_notification_type: values.item_notification_type,
          rule_name: values.rule_name,
          macro_name: values.macro_name,
          icon_name: selectedIcon,
          item_color: selectedColor,
          settings: modifiedDeviceSetting,
        };

        if (itemId === undefined) {
          throw new Error('Item id not valid');
        }
        appConsoleLog('uv update item', deviceItem);

        if (deviceItem.settings) {
          deviceItem.settings = JSON.stringify(deviceItem.settings);
        }
        await uvService.userviewUpdateItem(userProfile.apollo_client, itemId, deviceItem);
      } else {
        if (selectedDevice == null) {
          throw new Error('Please select device');
        }

        let modifiedDeviceSetting = userviewDeviceSettings;
        if (genaratedLogicId) {
          modifiedDeviceSetting = typeof userviewDeviceSettings === 'string'
            ? JSON.parse(userviewDeviceSettings)
            : userviewDeviceSettings;
          modifiedDeviceSetting.generated_logic_id = genaratedLogicId;
        }

        const deviceItem: any = {
          project_id: projectId,
          userview_id: userViewId,
          display_name: values.display_name.trim(),
          parent_item_id: parentFolder === '' /* < 1 */ ? null : parentFolder,
          pro_device_id: null,
          air_device_id: null,
          cint_device_id: null,
          dc_id: null,
          item_type: null,
          item_cat: UserViewItemCategory.item,
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          item_notification_type: values.item_notification_type,
          rule_name: values.rule_name,
          // eslint-disable-next-line no-nested-ternary
          macro_name: genaratedLogicId
            ? values.macro_name.length > 0
              ? `${genaratedLogicId},${values.macro_name}`
              : genaratedLogicId
            : values.macro_name,
          icon_name: selectedIcon,
          item_color: selectedColor,
          settings: modifiedDeviceSetting, // userviewDeviceSettings,
          config: null,
        };

        if (selectedDevice?.dc_type === DeviceCollectionType.proDevice) {
          deviceItem.pro_device_id = selectedDevice.id;
          deviceItem.dc_id = (selectedDevice as ICommDevice).prodc_id;
          deviceItem.item_type = selectedDevice.sub_cat;
        } else if (selectedDevice?.dc_type === DeviceCollectionType.airDevice) {
          deviceItem.air_device_id = selectedDevice.id;
          deviceItem.dc_id = (selectedDevice as ICommDevice).airdc_id;
          if (userviewDeviceSettings && userviewDeviceSettings.air_device_type) {
            deviceItem.item_type = userviewDeviceSettings.air_device_type;
          }
        } else if (selectedDevice?.dc_type === DeviceCollectionType.cintDevice) {
          deviceItem.cint_device_id = (selectedDevice as any).id;
          deviceItem.dc_id = (selectedDevice as unknown as CiDevice).cint_coll;
          deviceItem.item_type = userviewDeviceSettings && userviewDeviceSettings.item_type;
        }

        appConsoleLog('uv item', deviceItem);

        if (deviceItem.settings) {
          deviceItem.settings = JSON.stringify(deviceItem.settings);
        }

        await uvService.userviewItemCreate(userProfile.apollo_client, deviceItem);
      }
      // return;
      snackbar.enqueueSnackbar(isUpdate ? 'Item updated' : 'Item created', {
        key: 'uv-folder-create-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });

      formRef.current.resetForm();
      closeDialog(true);

      // Navigation based on user input form dialog confirmation
      if (flag === 'go') {
        snackbar.enqueueSnackbar('Starting navigation, please wait....', {
          variant: 'info',
        });

        if (!isUpdate) {
          navigate(
            `/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${genaratedLogicId}`,
          );
        } else {
          const settings = typeof currentFolderItem.settings === 'string'
            ? JSON.parse(currentFolderItem.settings)
            : currentFolderItem.settings;
          const logicId = settings.generated_logic_id;
          navigate(
            `/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${logicId}`,
          );
        }
      } else if (flag === 'recreate' && !skipLogicCreation) {
        snackbar.enqueueSnackbar('Logic recreated!', { variant: 'success' });
      } else if (flag === 'recreate-and-go' && !skipLogicCreation) {
        snackbar.enqueueSnackbar('Logic recreated!', { variant: 'success' });
        navigate(
          `/app/logics/edit?projectId=${selectedProject.selected_project.id}&logicId=${genaratedLogicId}`,
        );
      } else {
        // do nothing
      }
    } catch (err) {
      // throw err;
      appConsoleError(err);
      let errMsg = isUpdate ? 'Cannot update item' : 'Cannot create item';

      if (typeof err === 'string') {
        errMsg = err;
      }
      snackbar.enqueueSnackbar(errMsg, {
        key: 'uv-item-create-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    } finally {
      // dispatch(reset_logic_creation());
      setOverlay(false);
      setShoulCallOnSubmit(false);
    }
  };

  const [formVals, setFormVals] = useState<{values: FormType; formikHelpers: any}>(null);

  async function handleLogicNavigationButtonClose(flag: string) {
    try {
      await onSubmitForm(formVals.values, formVals.formikHelpers, flag);
    } catch (err: any) {
      snackbar.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setOpenLogicNavigationDialog(false);
    }
  }

  function onConfirmLogicCreation(values: FormType, formikHelpers: any) {
    // selectedDevice.cat == "ac" ||
    if (selectedDevice.cat === 'ir' && isUpdate) {
      setOpenLogicNavigationDialog(true);
      setFormVals({
        values,
        formikHelpers,
      });
    } else if (selectedDevice.cat === 'ir') {
      onSubmitForm(values, formikHelpers, 'no');
    } else {
      onSubmitForm1(values, formikHelpers, 'no');
    }
  }

  return (
    <>
      <Dialog open={showOverlay} onClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Processing Request...</DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openLogicNavigationDialog}
        // onClose={handleLogicNavigationButtonClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: 'red' }}>
          Alert!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!isUpdate
              ? `You are about to create a user view item that contains a logic(macro). Do you need to navigate to the
            generated logic once this user view item created ?`
              : 'You are about to update a userview that may have a generated logic attached to it. If you do any changes by updating the devices, or changing zones, source or hdmi ports, thats used for generate the the logic, you may need to manually update the logic. Do you prefer that? or do you want to recreate a new logic item ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Cancel the update process">
            <Button onClick={() => handleLogicNavigationButtonClose('cancel')} color="primary">
              <>Cancel</>
            </Button>
          </Tooltip>
          <Tooltip title="This will only update the userview. It won't do any update to the generated logic content">
            <Button onClick={() => handleLogicNavigationButtonClose('no')} color="primary">
              <>Just update the userview</>
            </Button>
          </Tooltip>
          <Tooltip title="If you don't wish to recreate a logic but to modified the current logic click this. This will navigate to the logic content after userview updated">
            <Button
              onClick={() => handleLogicNavigationButtonClose('go')}
              color="primary"
              variant={isUpdate ? 'text' : 'contained'}
              autoFocus
            >
              Yes Go there
            </Button>
          </Tooltip>
          {isUpdate && (
            <>
              <Tooltip title="This will recreate a new logic and remove the old generated logic and replace it and will navigate to the logic content after this userview updated">
                <Button
                  onClick={() => handleLogicNavigationButtonClose('recreate-and-go')}
                  color="primary"
                  autoFocus
                >
                  Recreate and Go There
                </Button>
              </Tooltip>
              <Tooltip title="This will recreate a new logic and remove the old generated logic and replace it">
                <Button
                  onClick={() => handleLogicNavigationButtonClose('recreate')}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Recreate the logic
                </Button>
              </Tooltip>
            </>
          )}
        </DialogActions>
      </Dialog>
      <UVItemCreatorDynamic
        itemCategoryType={itemCategoryType}
        selectedColor={selectedColor}
        selectedIcon={selectedIcon}
        uvSettings={uvSettings}
        selectedDevice={selectedDevice}
        isUpdate={isUpdate}
        isLoading={isLoading}
        setDevice={(device) => setDevice(device)}
        selectNewColor={(color) => selectNewColor(color)}
        selectNewIcon={(icon) => selectNewIcon(icon)}
        formDefaults={formDefaults}
        setFormDefaults={(defaults) => setFormDefaults(defaults)}
        // eslint-disable-next-line react/jsx-no-bind
        onSubmitForm={onConfirmLogicCreation}
        appendSettings={appendSettings}
        uvItem={currentFolderItem}
        formRef={formRef}
        submitRefresh={0}
        selectedOtherItemCat=""
        setOtherItemCat={() => {
          appConsoleLog('setOtherItemCat called');
        }}
        updateCustomFieldErrors={(updatedErrors) => {
          updateCustomFieldErrors((errors: any) => ({
            ...errors,
            ...updatedErrors,
          }));
        }}
        selectedFromTree={selectedDeviceId}
      />
    </>
  );
};

UVItemCreator.defaultProps = {
  itemId: null,
};
