/* eslint-disable max-classes-per-file */

import { AxiosInstance } from 'axios';
import { CloudIntegrationPlugin, EventManager } from '../shared/Plugin';
import AirIntegration from './air.service';
import { ReactComponents } from './components';

class AirPlugin extends CloudIntegrationPlugin {
  uiComponents: ReactComponents;

  constructor(axiosClient: AxiosInstance, eventManager: EventManager) {
    const integration = new AirIntegration(axiosClient, eventManager);
    super(eventManager, integration);
    this.uiComponents = new ReactComponents(this.eventManager, integration);
  }
}

export default AirPlugin;
