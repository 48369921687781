/* eslint-disable no-debugger */
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';
import { AuthContext } from 'src/store/Auth/AuthContext';
import useQueryString from 'src/utility/CustomHooks/useQueryString';
import SessionCard from './shared/SessionCard';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  root: {
    width: '94%',
    margin: '0 auto',
  },
});

const SessionList = () => {
  const styles = useStyle();
  const projectId = useQueryString('projectId');
  const auth = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    fetchSession();
  }, []);

  const fetchSession = async () => {
    const url = '/rest/backups/v2/restore/clone/session/list';
    try {
      const response = await axios.post(
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0] + url,
        {
          project_id: projectId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        },
      );
      if (response.data && isArray(response.data.result)) {
        setSessions(response.data.result);
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>Restore Sessions</Typography>
        </Box>
      </PageHeader>
      <Box className={styles.root}>
        <Box height={20} />
        <Grid container spacing={2}>
          {sessions.map((session) => (
            <Grid item md={4} sm={12}>
              <SessionCard
                title={session.note}
                date={session.updated_at}
                // eslint-disable-next-line no-underscore-dangle
                sessionId={session._id}
                refetch={fetchSession}
                status={session.status}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};
export default SessionList;
