/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';

import { Done, Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Slide,
} from '@mui/material';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { userviewGetGraphQL } from 'src/services/user-view/user-view.service';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../Alters/Snackbar/SnackbarOptions';
import uvService from '../../../service/uv.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { getFolderIdFromPathRef } from '../../../utils';
import { UVContext, UVReducerTypes } from '../../../UVContext';
import styles from './ShortcutEdit.module.css';
import { UVIconSelector } from '../../UVIconSelector/UVIconSelector';
import { CustomSwitch } from '../../../../shared/Switch/Swtich';
import {
  IUserViewItem,
  UserViewItemCategory,
  UserViewItemNotificationType,
  UserViewItemVisibility,
} from '../../../types/types';
import { LoadingComponent } from '../../../../shared/Loading/Loading';
import { appConsoleError, appConsoleLog } from '../../../../../utility/appUtils';

type ComponentProps = {
  dialogOpen: boolean;
  dialogCloseEvent: (flag: boolean) => void;
  shortcutId?: string;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title-folder" cancel={'[class*="MuiDialogContent-root"]'}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Paper {...props} />
    </Draggable>
  );
}
const Transition = React.forwardRef(
  (
    props: TransitionProps & {children: React.ReactElement<any, any>},
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);
interface FormType {
  // eslint-disable-next-line camelcase
  shortcut_name: string;
  visibility: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const ShortcutEditDialog: React.FunctionComponent<ComponentProps> = ({
  dialogOpen,
  dialogCloseEvent,
  shortcutId,
}) => {
  const [formDefaults, setFormDefaults] = useState({
    shortcut_name: 'test',
    visibility: true,
  });

  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const project = useContext(ProjectContext);
  const userViewContext = useContext(UVContext);
  const formRef = useRef<any>();
  const [selectedIcon, selectNewIcon] = useState('calc-door2');
  const [selectedColor, selectNewColor] = useState('#FFFFFF');
  const [isLoading, setLoading] = useState(false);

  const isUpdate = shortcutId !== undefined && shortcutId.length > 0;
  const [currentFolderItem, setCurrentFolderItem] = useState<IUserViewItem | null>(null);
  const [parentItem, setParentItem] = useState<IUserViewItem | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        if (shortcutId === undefined || shortcutId.length < 1) {
          return;
        }
        const { item } = await uvService.userviewItemGet(userProfile.apollo_client, shortcutId);
        const { settings } = item;
        try {
          const parsed = JSON.parse(settings);

          shortCutPath.current = null;
          shortCutPathName.current = null;
          shortCutUvId.current = null;

          if (parsed.uv_item_id && parsed.uv_id) {
            shortCutUvId.current = parsed.uv_id;
            setIsBuildingPath(true);
            buildShortcutPath(parsed.uv_item_id, parsed.uv_id);
          } else if (parsed.folder_id && parsed.uv_id) {
            shortCutUvId.current = parsed.uv_id;
            setIsBuildingPath(true);
            buildShortcutPath(parsed.folder_id, parsed.uv_id);
          } else if (parsed.uv_id) {
            shortCutUvId.current = parsed.uv_id;
            setIsBuildingPath(true);
            buildShortcutPath(null, parsed.uv_id);
          }
        } catch (err) {
          snackbar.enqueueSnackbar('Unable to parse settings', { variant: 'error' });
        }
        const tesmp = await uvService.userviewItemGet(
          userProfile.apollo_client,
          `${item?.parent_item_id}`,
        );
        setParentItem(tesmp?.item);
        setCurrentFolderItem(item);

        setFormDefaults({
          shortcut_name: item.display_name,
          visibility: item.item_visibility === UserViewItemVisibility.show,
        });

        selectNewColor(item.item_color);
        selectNewIcon(item.icon_name);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Unexpacted error', {
          key: 'uv-folder-update-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      }
      appConsoleLog('set loading', false);
      setLoading(false);
    };
    if (isUpdate) {
      setLoading(true);
      init();
    }
  }, [shortcutId]);

  const [isBuildingPath, setIsBuildingPath] = useState(false);
  const [shortCutHatch, setShortcutHatch] = useState<string>();
  const [shortcutHatchPathName, setShortcutHatchPathName] = useState<string>();
  const shortCutPath = useRef<string>();
  const shortCutPathName = useRef<string>();
  const shortCutUvId = useRef<string>();

  async function buildShortcutPath(uvItemIdOftheShortcut: string, uvItem: string) {
    try {
      // its a link to a userview but not to a userview item
      if (!uvItemIdOftheShortcut) {
        const res = await userviewGetGraphQL(
          userProfile.apollo_client,
          userViewContext.userViewId,
        );
        if (res.userviewGet) {
          shortCutPath.current = null;
          shortCutPathName.current = res.userviewGet.userview.name;
          setShortcutHatchPathName(shortCutPathName.current);
        }
        setIsBuildingPath(false);
        return;
      }
      // its a link to a userview item
      const item = await uvService.userviewItemGet(
        userProfile.apollo_client,
        uvItemIdOftheShortcut,
      );

      shortCutPath.current = shortCutPath.current
        ? `-${uvItemIdOftheShortcut + shortCutPath.current}`
        : `-${uvItemIdOftheShortcut}`;

      shortCutPathName.current = shortCutPathName.current
        ? `${item.item.display_name}/${shortCutPathName.current}`
        : `${item.item.display_name}`;

      if (item.item.parent_item_id && item.item.parent_item_id.length > 0) {
        buildShortcutPath(item.item.parent_item_id, uvItem);
      } else {
        const res = await userviewGetGraphQL(
          userProfile.apollo_client,
          shortCutUvId.current,
        );
        if (res.userviewGet) {
          shortCutPathName.current = shortCutPathName.current.toLowerCase().replace('home', res.userviewGet.userview.name);
        }

        setShortcutHatch(shortCutPath.current);
        setShortcutHatchPathName(shortCutPathName.current);
        setIsBuildingPath(false);
      }
    } catch (err) {
      snackbar.enqueueSnackbar('Unable to build shortcut path', { variant: 'error' });
      setIsBuildingPath(false);
    }
  }

  const onSubmitForm = async (values: FormType) => {
    try {
      const parentFolder = getFolderIdFromPathRef(userViewContext.userViewPath);
      const { projectId, userViewId } = userViewContext;

      // console.log(values.shortcut_name.trim(), values.visibility, selectedIcon, selectedColor)

      // return;

      if (isUpdate) {
        const folderItem: any = {
          display_name: values.shortcut_name.trim(),
          item_visibility: values.visibility
            ? UserViewItemVisibility.show
            : UserViewItemVisibility.hide,
          icon_name: selectedIcon,
          item_color: selectedColor,
        };
        if (shortcutId === undefined) {
          throw new Error('Shortcut id not valid');
        }
        await uvService.userviewUpdateShortcut(userProfile.apollo_client, shortcutId, folderItem);
      }

      snackbar.enqueueSnackbar(isUpdate ? 'Shortcut updated' : '', {
        key: 'uv-folder-create-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });

      formRef.current.resetForm();
      dialogCloseEvent(true);
    } catch (err) {
      appConsoleError(err);

      snackbar.enqueueSnackbar(isUpdate ? 'Cannot update shortcut' : 'Cannot create shortcut', {
        key: 'uv-folder-create-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    } finally {
      setFormDefaults({
        shortcut_name: 'New folder 01',
        visibility: true,
      });
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      onClose={() => {
        dialogCloseEvent(false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title-folder">
        {isUpdate ? 'Update Shortcut' : ''}
      </DialogTitle>
      <DialogContent>
        {!isUpdate || (isUpdate && !isLoading) ? (
          <div className="row">
            <div className="col-md-12 pl-0 pb-0">
              <Formik
                initialValues={formDefaults}
                validationSchema={Yup.object().shape({
                  shortcut_name: Yup.string()
                    .required('Required')
                    .max(25, 'Maximum shortcut name length 25'),
                })}
                validateOnChange
                onSubmit={onSubmitForm}
                innerRef={formRef}
              >
                {({
                  errors, handleBlur, handleChange, touched, values,
                }) => {
                  appConsoleLog(values);
                  return (
                    <form>
                      <div className={styles.textInput} style={{ marginTop: '5px' }}>
                        <TextField
                          className="uv-ui-form-control-full-width"
                          error={Boolean(touched.shortcut_name && errors.shortcut_name)}
                          helperText={touched.shortcut_name && errors.shortcut_name}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          value={values.shortcut_name}
                          name="shortcut_name"
                          label="Name"
                          size="small"
                          variant="outlined"
                        />
                      </div>

                      <div>
                        {shortcutHatchPathName && shortcutHatchPathName.length > 0 && (
                          <Button
                            disabled={isBuildingPath}
                            onClick={() => {
                              let url = `/app/user-view?userViewId=${shortCutUvId.current}&projectId=${project.selected_project.id}`;
                              if (shortCutPath.current && shortCutPath.current.length > 0) {
                                const path = shortCutPath.current.split('-');
                                path.pop();
                                url += `&userViewPath=${path.join('-')}`;
                              }
                              window.open(url, '_blank');
                            }}
                          >
                            {isBuildingPath ? 'loading..' : `Go to -> ${shortcutHatchPathName}`}
                          </Button>
                        )}
                      </div>

                      <FormControlLabel
                        className="pl-8"
                        control={(
                          <CustomSwitch
                            name="visibility"
                            value={values.visibility}
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              console.log('xcx', e);
                              handleChange(e);
                            }}
                            // onChange={(event: any) => {
                            //   appConsoleLog("switch", event.target.value);
                            // }}
                          />
                        )}
                        label="Visibility"
                      />
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="col-md-12 pl-0 pb-0">
              <div>
                <UVIconSelector
                  initialIcon={selectedIcon}
                  initialColor={selectedColor}
                  onChange={(icon, color) => {
                    appConsoleLog('selected Icon:', icon);
                    selectNewIcon(icon);
                    selectNewColor(color);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <LoadingComponent color="#06454d" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            formRef.current.submitForm();
          }}
          color="primary"
        >
          <Done fontSize="large" />
          {isUpdate ? 'Update' : 'Create'}
        </Button>
        <Button
          onClick={() => {
            appConsoleLog('isUpdate', shortcutId, isUpdate);
            dialogCloseEvent(false);
          }}
          color="primary"
        >
          <Close fontSize="large" />
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShortcutEditDialog.defaultProps = {
  shortcutId: null,
};
