/* eslint-disable max-len */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { LogicSearchRes } from './types';

// eslint-disable-next-line import/prefer-default-export
export const searchLogicList = createAsyncThunk<
  LogicSearchRes,
  {skip: number; limit: number; project_id: string; keyword: string, logic_status: string, logic_version: string},
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('logics/getLogics', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post('/rest/logic-engine/v1/logic-item/list', filters);
  return res.data as LogicSearchRes;
});
