/* eslint-disable camelcase */
import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client';
import { appConsoleLog } from '../../../../utility/appUtils';
import { IProDevice } from '../types';
import {
  gqlAppInfo,
  gqlProDCGetPins,
  gqlProDeviceCreate,
  gqlProDeviceUpdate,
  gqlTagSearch,
} from './graphql';

export interface IPin {
  display_name: string;
  pin: number;
  available: boolean;
  item_id: number;
}

const appInfoGet = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<{item_defaults: any}> => {
  const res = await apolloClient.query({
    query: gqlAppInfo,
  });
  return res.data.appInfo;
};

export const proDCGetPins = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  prodcId: string,
  pinType: string,
): Promise<{pins: IPin[]}> => {
  appConsoleLog(prodcId, pinType);
  const res = await apolloClient.query({
    query: gqlProDCGetPins,
    variables: {
      prodcId,
      pinType,
    },
  });
  return res.data.proDCGetPins;
};

const proDeviceCreate = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  deviceInfo: IProDevice,
): Promise<IProDevice> => {
  const settingsJson = JSON.stringify(deviceInfo.settings);
  const res = await apolloClient.mutate({
    mutation: gqlProDeviceCreate,
    variables: {
      payload: {
        prodc_id: deviceInfo.prodc_id,
        name: deviceInfo.name,
        tags: deviceInfo.tags,
        system_tags: deviceInfo.system_tags,
        shadow_type: deviceInfo.shadow_type,
        cat: deviceInfo.cat,
        sub_cat: deviceInfo.sub_cat,
        device_type: deviceInfo.device_type,
        settings: settingsJson,
      },
    },
  });
  return res.data.proDeviceCreate;
};

const proDeviceUpdate = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  deviceInfo: IProDevice,
): Promise<IProDevice> => {
  const settingsJson = JSON.stringify(deviceInfo.settings);
  const res = await apolloClient.mutate({
    mutation: gqlProDeviceUpdate,
    variables: {
      payload: {
        item_id: deviceInfo.id,
        item: {
          name: deviceInfo.name,
          tags: deviceInfo.tags,
          shadow_type: deviceInfo.shadow_type,
          cat: deviceInfo.cat,
          sub_cat: deviceInfo.sub_cat,
          device_type: deviceInfo.device_type,
          settings: settingsJson,
          device_status: deviceInfo.device_status,
        },
      },
    },
  });
  return res.data.proDeviceCreate;
};

const tagSearch = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
  keyword: string,
): Promise<string[]> => {
  const res = await apolloClient.query({
    query: gqlTagSearch,
    variables: {
      projectId,
      keyword,
      limit: 10,
      skip: 0,
    },
  });
  const items: {name: string}[] = res.data.tagSearch.result;

  return items.map((item) => item.name);
};
export default {
  proDCGetPins,
  appInfoGet,
  proDeviceCreate,
  proDeviceUpdate,
  tagSearch,
};
