import type { CloudDeviceUVIntegration } from '../../shared/uvitem.config';
import RelayConfig from './relay.config';
import DimmerConfig from './dimmer.config';
import SensorConfig from './sensor.config';

export enum DEVICE_HIGH_LEVEL_CATOGORIES {
  RELAY = 'relay',
  DIMMER = 'dimmer',
  SENSOR = 'sensor',
}

export const itemConfig: CloudDeviceUVIntegration = {
  [DEVICE_HIGH_LEVEL_CATOGORIES.RELAY]: RelayConfig,
  [DEVICE_HIGH_LEVEL_CATOGORIES.DIMMER]: DimmerConfig,
  [DEVICE_HIGH_LEVEL_CATOGORIES.SENSOR]: SensorConfig,
};


// Currently using the default field map from shared service of plugin system.
// TODO: use this later for more customisable usage of uv create form

export const uvSettings = {
  [DEVICE_HIGH_LEVEL_CATOGORIES.RELAY]: {
    fields: [
      { type: 'rule_name', default: '' },
      { type: 'macro_name', default: '' },
      { type: 'icon_name', default: 'calc-light1' },
    ],
  },
  [DEVICE_HIGH_LEVEL_CATOGORIES.DIMMER]: {
    fields: [
      { type: 'rule_name', default: '' },
      { type: 'macro_name', default: '' },
      { type: 'icon_name', default: 'calc-light1' },
    ],
  },
  [DEVICE_HIGH_LEVEL_CATOGORIES.SENSOR]: {
    fields: [
      { type: 'rule_name', default: '' },
      { type: 'macro_name', default: '' },
      { type: 'icon_name', default: 'calc-light1' },
    ],
  }
}

export const uvSettingsBasedOnDeviceType = {
  'relay': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.RELAY],
  'emeter': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.SENSOR],
  'inputs_reader': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.SENSOR],
  'light': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.DIMMER],
}