/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import uvService from '../../service/uv.service';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ErrorSnackbarOptions, ErrorSnackbarActions } from '../../../Alters/Snackbar/SnackbarOptions';
import { UVContext } from '../../UVContext';

import styles from './UVItemFinder.module.css';
import { TagComponent } from '../../../../containers/app/ProDeviceCreateEdit/Tag/Tag';
import { LoadingLine } from '../../../shared/LoadingLine/LoadingLine';
import { UVCreateCommDeviceId, UVGetDeviceIdFromCommId } from '../../utils';
import { appConsoleError } from '../../../../utility/appUtils';
import { useQueryNumber } from '../../../../utility/CustomHooks/useQueryNumber';
import { IUserViewItem } from '../../types/types';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';

type ComponentProps = {
  children?: any;
  itemTypes: string[];
  defaultValue: string;
  label: string;
  withCustomSearch: boolean;
  freezed?: boolean;
  defaultItem?: IUserViewItem | null;
  withBorder?: boolean;
  resetNow?: number;
  disabledList?: IUserViewItem[];
  onItemSelected: (item: IUserViewItem, manually: boolean) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVItemFinder: React.FunctionComponent<ComponentProps> = ({
  disabledList,
  children,
  itemTypes,
  defaultValue,
  label,
  withCustomSearch = false,
  freezed = false,
  defaultItem = null,
  withBorder = true,
  resetNow = 0,
  onItemSelected,
}) => {
  const userProfile = useContext(ApolloAuthContext);
  const userViewId = useQueryString('userViewId') as string;

  const snackbar = useSnackbar();
  const [refresh, refreshNow] = useState(0);
  const [items, setItems] = useState<IUserViewItem[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selecteditem_id, setitem_id] = useState<string>(defaultValue); // pro_<id> or air_<id>
  const [selectedItem, setItem] = useState<IUserViewItem>();
  const [lastRefreshRef, setLastRefreshRef] = useState<string>('');

  useEffect(() => {
    if (defaultValue) {
      setitem_id(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultItem) {
      setItem(defaultItem);
    }
  }, [defaultItem]);

  useEffect(() => {
    if (resetNow > 0) {
      setitem_id('');
      setItem(undefined);
    }
  }, [resetNow]);

  useEffect(() => {
    const init = async () => {
      const refreshRef = `${itemTypes.join('')}${refresh}`;

      if (isLoading || lastRefreshRef === refreshRef) {
        return;
      }

      try {
        setLoading(true);

        const res = await uvService.searchUserviewItemsInUserview(
          userProfile.apollo_client,
          userViewId,
          itemTypes,
          [],
          100,
          0,
        );
        console.log('SETTTTTTTTTTTTT CALLED');
        setItems(res.result);
      } catch (err) {
        appConsoleError(err);

        snackbar.enqueueSnackbar('Cannot get userview item list', {
          key: 'uv-item-list-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
        appConsoleError(err);
      }
      setLoading(false);
      setLastRefreshRef(`${itemTypes.join('')}${refresh}`);
    };
    init();
  }, [itemTypes, selecteditem_id, refresh]);

  const handleOnItemChange = (item_id: string) => {
    const item = items.find((itemPayload, idx) => itemPayload.id === item_id);
    const commItem: any = { ...item };
    onItemSelected(commItem, true);
    setitem_id(item_id);
  };

  return (
    <div className={`${styles.root} ${withBorder ? 'root-border' : ''}`}>
      <div className={styles.label}>
        {label}
        :
      </div>
      {!freezed ? (
        <div className={styles.searchButton}>
          <Button
            onClick={() => {
              refreshNow(Date.now());
            }}
            color="primary"
            disabled={isLoading}
          >
            <Search fontSize="large" />
            {' '}
            Refresh
          </Button>
          <LoadingLine isLoading={isLoading} />
        </div>
      ) : (
        <div />
      )}
      {!freezed ? (
        <div className={`col-md-6 pl-0 ${styles.selectBar}`}>
          <FormControl
            size="small"
            variant="outlined"
            className="dynamic-ui-form-control-full-width"
          >
            <InputLabel id="custom_selector-label1">{label}</InputLabel>
            <Select
              labelId="custom_selector-label1"
              value={selecteditem_id == null || selecteditem_id === '' ? '' : selecteditem_id}
              name="custom_selector"
              onChange={(e: any) => {
                handleOnItemChange(e.target.value);
              }}
              label={label}
            >
              {items.map((item) => {
                let disabled = false;
                if (disabledList) {
                  const itsIn = disabledList.findIndex((d) => item.id === d.id);
                  if (itsIn > -1) {
                    disabled = true;
                  }
                }

                return (
                  <MenuItem key={item.id} value={item.id} disabled={disabled}>
                    {item.display_name}
                    {' '}
                    (
                    {item.display_path_ref}
                    )
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {' '}
          {children}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
