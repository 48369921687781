import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from 'react';
import Layout from 'src/components/layout/Layout';
import {PageHeader} from 'src/components/layout/PageHeader';
import PageContentLayout, {
  PageConentHeader,
} from 'src/containers/app/LogicsEngine/components/PageContentLayout';
import {useQueryStringFirst} from 'src/utility/CustomHooks/useQueryNumber';
import {useSnackbar} from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudIntegration, {CiDevice} from '../services/cloud-integration.service';
import DeviceSettingConfiguration from './atoms/DeviceSettingConfig';
import EnergyMonitoringConfig from './atoms/DeviceEnergyMonitoringConfig';
import {DeviceSettingsDangerZone} from './atoms/DeviceSettingDangerZone';
import {AdvancedDeviceConfig} from './atoms/AdvancedDeviceConfig';
import {useFirebaseConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {RootState} from 'src/store/redux/store';
import LabelValueMapping from 'src/components/UserViewComponents/UIItems/Dialogs/ItemCreateDialog/shared/LabelValueMapping';
import {set} from 'lodash';
import { de } from 'date-fns/locale';

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    marginTop: '5px',
    padding: '2%',
    overflow: 'scroll',
  },
  root: {
    // height: "100%",
    display: 'flex',
    // width: '50%',
    flexDirection: 'column',
  },
}));

// disable event or log
// disabled:true

// enable event or log
// disabled: false , debounce: true,

enum DebounceTypes {
  TIME = 'time',
  DIFF = 'diff',
}

// debounce_type: time(sec), diff
type EventsConfig = {
  disabled: boolean;
  debounce?: boolean;
  debounce_type?: DebounceTypes;
  debounce_cond?: number;
};

const CloudDeviceEdit: React.FC<{integration: CloudIntegration}> = ({integration}) => {
  const classNames = useStyles();

  const selectedProjectID = useQueryStringFirst('projectId');
  const selectedDCID = useQueryStringFirst('dcId');
  const selectedDCName = useQueryStringFirst('dcName');
  const selectedDeviceId = useQueryStringFirst('deviceId');
  const cintType = useQueryStringFirst('cint_type');

  const cluterId = selectedDeviceId.split('_')[0];

  useFirebaseConnect(`/shadow_in/${selectedDCID}/${cluterId}/${selectedDeviceId}`);
  const currentDeviceValues = useSelector((state: RootState) => {
    if (
      state.firebase.data.shadow_in &&
      state.firebase.data.shadow_in[selectedDCID] &&
      state.firebase.data.shadow_in[selectedDCID][cluterId] &&
      state.firebase.data.shadow_in[selectedDCID][cluterId][selectedDeviceId]
    ) {
      return state.firebase.data.shadow_in[selectedDCID][cluterId][selectedDeviceId];
    } else {
      return {};
    }
  });

  const [device, setDevice] = useState<CiDevice>(null);
  const [valueMap, setValueMap] = useState<{
    [key: string]: {
      label: string;
      value: string;
    }[];
  }>();

  const [newDeviceName, setNewDeviceName] = useState(device?.name);

  useEffect(() => {
    if (device && device.settings && device.settings.label_value_map) {
      setValueMap(device.settings.label_value_map);
    }
    if (device) {
      setNewDeviceName(device.name);
    }
  }, [device]);
  const {enqueueSnackbar} = useSnackbar();

  const [tab, setTab] = useState(0);

  useEffect(() => {
    async function init() {
      try {
        const res = await integration.getCloudDevice(selectedDeviceId);
        if (res && res.result && res.result.device) {
          setDevice(res.result.device);
        }
      } catch (err) {}
    }

    init();
  }, [selectedDeviceId]);

  async function saveConfig(sks: any) {
    try {
      const res = await integration.editCloudDevice(selectedDeviceId, {
        ...device.settings,
        sf: sks,
      });
      enqueueSnackbar('Device Configuration Saved', {variant: 'success'});
    } catch (err) {
      enqueueSnackbar('Unable to save configuration', {variant: 'error'});
    }
  }

  async function saveHdcs(hdcs: any) {
    try {
      const res = await integration.editCloudDevice(selectedDeviceId, {
        ...device.settings,
        hdc: hdcs,
      });
      setDevice(device => ({
        ...device,
        settings: {
          ...device.settings,
          hdc: hdcs,
        },
      }));
      enqueueSnackbar('Device Energy Monitoring Configurations Saved', {variant: 'success'});
    } catch (err) {
      enqueueSnackbar('Unable to save configuration', {variant: 'error'});
    }
  }

  async function saveValueMap(map: {
    [key: string]: {
      label: string;
      value: string;
    }[];
  }) {
    try {
      const res = await integration.editCloudDevice(selectedDeviceId, {
        ...device.settings,
        label_value_map: map,
      });
      setDevice(device => ({
        ...device,
        settings: {
          ...device.settings,
          label_value_map: map,
        },
      }));
      enqueueSnackbar('Mapping updated', {variant: 'success'});
    } catch (err) {
      enqueueSnackbar('Unable to save mapping', {variant: 'error'});
    }
  }

  function onNewChannel(newChannel: any) {
    setDevice(device => ({
      ...device,
      settings: {
        ...device.settings,
        sf: {
          ...device.settings.sf,
          [`f${Object.keys(device.settings.sf).length + 1}`]: newChannel,
        },
      },
    }));
  }

  function onSfsChanged(sfs) {
    setDevice(device => ({
      ...device,
      settings: {
        ...device.settings,
        sf: sfs,
      },
    }));
  }

  async function onAdditionalConfigChanged(config) {
    let oldDeviceState;
    try {
      setDevice(device => {
        oldDeviceState = device;
        return {
          ...device,
          settings: {
            ...device.settings,
            ...config,
          },
        };
      });
      const res = await integration.editCloudDevice(selectedDeviceId, {
        ...device.settings,
        ...config,
      });
      enqueueSnackbar('Mapping updated', {variant: 'success'});
    } catch (err) {
      enqueueSnackbar('Unable to save mapping', {variant: 'error'});
      setDevice(oldDeviceState);
    }
  }

  async function onNameUpdate(name: string) {
    try {
      const res = await integration.editCloudDeviceName(selectedDeviceId, name, device.settings);
      enqueueSnackbar('Device name updated', {variant: 'success'});
      setDevice(device => ({
        ...device,
        name,
      }));
    } catch (err) {
      enqueueSnackbar('Unable to update device name', {variant: 'error'});
    }
  }

  async function toggleInverseState(inverted: boolean) {
    try {
      setDevice(device => ({
        ...device,
        settings: {
          ...device.settings,
          command_inverted: inverted,
        },
      }));
      const res = await integration.editCloudDevice(selectedDeviceId, {
        ...device.settings,
        command_inverted: inverted,
      });
      enqueueSnackbar('Device inverted', {variant: 'success'});
    } catch (err) {
      setDevice(device => ({
        ...device,
        settings: {
          ...device.settings,
          command_inverted: !inverted,
        },
      }));
      enqueueSnackbar('Unable to invert the device', {variant: 'error'});
    }
  }

  return (
    <Layout>
      <PageHeader>
        <Box className={classNames.headerRoot}>
          <Box>
            <Box style={{display: 'flex', alignItems: 'center'}}>
              <Typography className={classNames.headerTitle}>
                {device ? device.name : 'Loading...'}
              </Typography>
            </Box>
            {device && <Typography variant="subtitle1">{selectedDCName}</Typography>}
          </Box>
          {device && (
            <LabelValueMapping
              mode="device"
              deviceType="cint"
              device={device as any}
              initialMap={valueMap}
              key={JSON.stringify(valueMap)}
              appendSettings={({data}) => {
                setValueMap(data?.label_value_map);
                saveValueMap(data?.label_value_map);
              }}
            />
          )}
        </Box>
      </PageHeader>
      <Box className={classNames.contentRoot}>
        <PageContentLayout className={classNames.root}>
          <Box>
            <Tabs value={tab} onChange={(_, val) => setTab(val)}>
              <Tab label="Settings" value={0} />
              <Tab label="Channels Management" value={1} />
            </Tabs>
            {tab === 1 && (
              <AdvancedDeviceConfig
                device={device}
                integration={integration}
                onAddNewChannel={onNewChannel}
                onSfsChanged={onSfsChanged}
              />
            )}
            {tab === 0 && (
              <>
                {
                  <Box
                    style={{
                      margin: '10px 0',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="subtitle2">Basic Settings</Typography>
                    {device && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                            marginTop: '10px',
                          }}
                        >
                          <TextField
                            style={{flex: 1, marginRight: '10px'}}
                            variant="outlined"
                            size="small"
                            label="Device Name"
                            value={newDeviceName}
                            onChange={event => {
                              setNewDeviceName(event.target.value);
                            }}
                          />
                          <Button
                            onClick={() => {
                              onNameUpdate(newDeviceName);
                            }}
                            variant="outlined"
                          >
                            Update Device Name
                          </Button>
                        </div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={device.settings.hideTimestamp}
                              onChange={event => {
                                console.log(event.target.checked);
                                onAdditionalConfigChanged({
                                  hideTimestamp: event.target.checked,
                                });
                              }}
                              color="primary"
                            />
                          }
                          label="Hide Timestamp in app"
                        />
                        {device && device.settings && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={device?.settings?.command_inverted}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  toggleInverseState(event.target.checked);
                                }}
                              />
                            }
                            label="Inverted Device"
                          />
                        )}
                      </>
                    )}
                  </Box>
                }
                {device && device.settings && device.settings.sf && (
                  <>
                    <Accordion expanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Device Energy Montoring Configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EnergyMonitoringConfig
                          channels={device.settings.sf}
                          hdc={device.settings.hdc}
                          onSave={saveHdcs}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Device Event Configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <DeviceSettingConfiguration
                          settings={device.settings.sf}
                          type="events"
                          onSave={saveConfig}
                          currentChannelValues={currentDeviceValues}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Device Elog Configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <DeviceSettingConfiguration
                          settings={device.settings.sf}
                          type="elog"
                          onSave={saveConfig}
                          currentChannelValues={currentDeviceValues}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
                {device && <DeviceSettingsDangerZone deviceId={device.id} />}
              </>
            )}
          </Box>
        </PageContentLayout>
      </Box>
    </Layout>
  );
};

export default CloudDeviceEdit;
