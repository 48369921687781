/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * The root component which list device belongs to the give device container
 *
 * @file This file define the root component for list devices
 * @author Maduka Dilshan
 * @since 0.1.243
 */

import {
  Typography,
  Box,
  IconButton,
  Chip,
  Divider,
  Tooltip,
  Checkbox,
  Skeleton,
  Grid,
  FormControl,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { cloneDeep, debounce, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/redux/store';
import { ApolloAuthContext } from '../../../../../../store/Apollo/ApolloContext';
import { proDCGetPins } from '../../../../ProDeviceCreateEdit/services/pro-device.service';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #CDEEFA',
    borderRadius: '5px',
  },
  titleRoot: {
    borderBottom: '1px solid #CDEEFA',
    padding: '10px',
    color: '#048696',
    fontWeight: 600,
    background: '#CDEEFA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentRoot: {
    display: 'flex',
    padding: 10,
    overflow: 'auto',
    backgroundColor: '#D9DFE0',
    maxHeight: 500,
  },
});

export enum DEVICE_CONTAINER_LOC {
  IN_TEMPLATE = 'in_template',
  IN_CONFIGURABLE_CONTAINER = 'in_configurable_container',
}

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

const DeviceContainer: React.FC<{
  title: string;
  dc?: any;
  dcId?: string;
  children: (arg: {searchText, devices}) => React.ReactChildren | React.ReactChild;
  className?: string;
  position: DEVICE_CONTAINER_LOC;
  checkedDevices?: {[key: string]: boolean};
  allSelected?: boolean;
  hasHiddenItems?: boolean;
  showHiddenItems?: boolean;
  isContentLoading?: boolean;
  type?: 'lvar' | 'prodc' | 'airdc' | 'cintd';
  onToggleShowHiddenItems?: (flag: boolean) => void;
  onToggleAllSelect?: (checked: boolean) => void;
  onMove?: () => void;
  hideSelectedDevices?: () => void;
}> = ({
  children,
  title,
  className,
  dc,
  dcId,
  position,
  checkedDevices,
  allSelected,
  hasHiddenItems,
  isContentLoading,
  showHiddenItems,
  type,
  onToggleShowHiddenItems,
  onToggleAllSelect,
  onMove,
  hideSelectedDevices,
}) => {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const userProfile = useContext(ApolloAuthContext);

  // const lvar = useSelector((state: RootState) => state.backup_location_config.info.v2.lvar);
  // const prod = useSelector((state: RootState) => state.backup_location_config.info.v2.prodc);
  // const aird = useSelector((state: RootState) => state.backup_location_config.info.v2.airdc);
  // const cintd = useSelector((state: RootState) => state.backup_location_config.info.v2.cintd);

  const backupInfo = useSelector((state: RootState) => state.backup_location_config.info.v2[type]);

  const [searchVal, setSearchVal] = useState('');
  const [finalSearchVal, setFinalSearchVal] = useState('');
  const [collapse, setCollapse] = useState(false);
  const [sort, setSort] = useState('');
  const [sortedPendingComp, setSortedPendingComp] = useState({});

  const renderProp = unwrapArray(children);
  // useMemo(() => unwrapArray(children), [finalSearchVal]);

  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    if (!dcId) return;
    // eslint-disable-next-line no-nested-ternary
    const pendingComponents = backupInfo.pendingComps[dcId];
    if (sort === '' || !backupInfo) {
      setSortedPendingComp(pendingComponents);
      return;
    }

    const container = backupInfo.pendingComps[dcId];
    console.log('container', container);
    if (container) {
      const arrWithkeys = Object.keys(container).map((key) => {
        const locVar = container[key];

        return {
          key,
          ...locVar,
        };
      });

      const sortedComps = orderBy(arrWithkeys, [(comp) => comp.data.name.toLowerCase()]);
      console.log('SORTED', sortedComps);
      const map = {};

      // eslint-disable-next-line array-callback-return
      sortedComps.map((item) => {
        const { key } = item;

        const cpy = cloneDeep(item);
        delete cpy.key;
        map[key] = cpy;
      });

      setSortedPendingComp(map);
    }
  // eslint-disable-next-line no-nested-ternary
  }, [sort, backupInfo]);

  useEffect(() => {
    debouncedSearchText(searchVal);
  }, [searchVal]);

  const debouncedSearchText = debounce((val) => {
    setFinalSearchVal(val);
  }, 700);

  return (
    <Box className={clsx(classes.root, className)}>
      <Box
        className={classes.titleRoot}
        style={{
          background: position === DEVICE_CONTAINER_LOC.IN_TEMPLATE ? '#CEE2FD' : '#CDEEFA',
          color: position === DEVICE_CONTAINER_LOC.IN_TEMPLATE ? '#222222' : '#048696',
        }}
      >
        <Typography style={{ textTransform: 'capitalize' }}>
          <Chip
            label={position === DEVICE_CONTAINER_LOC.IN_TEMPLATE ? 'In Template' : 'In Location'}
            size="small"
          />
          {' '}
          {title}
        </Typography>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            {checkedDevices && Object.keys(checkedDevices).length > 0 && (
              <Box
                style={{
                  backgroundColor: '#CDEEFA',
                  borderRadius: '10px',
                }}
              >
                <IconButton size="small" onClick={() => onMove()}>
                  <CopyAllIcon />
                </IconButton>
                <IconButton onClick={() => hideSelectedDevices()} size="small">
                  <VisibilityOffIcon />
                </IconButton>
                {/* <IconButton size="small">
                <RemoveCircleOutlineIcon />
              </IconButton> */}
              </Box>
            )}
          </Box>
          {position === DEVICE_CONTAINER_LOC.IN_TEMPLATE && (
            <>
              <Tooltip title="Select all items">
                <Checkbox
                  size="small"
                  checked={allSelected}
                  onChange={(e) => onToggleAllSelect && onToggleAllSelect(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Tooltip>
              {hasHiddenItems && (
                <IconButton onClick={() => onToggleShowHiddenItems(!showHiddenItems)}>
                  {showHiddenItems ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              )}
              {/* <IconButton>
                <DeleteOutlineIcon />
              </IconButton> */}
            </>
          )}
          {position !== DEVICE_CONTAINER_LOC.IN_CONFIGURABLE_CONTAINER && (
            <IconButton onClick={() => setSort((sortState) => (sortState === 'az' ? '' : 'az'))}>
              {sort === 'az' ? <SortIcon /> : <SortByAlphaIcon />}
            </IconButton>
          )}
          <IconButton onClick={() => setCollapse(!collapse)}>
            {collapse ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
          </IconButton>
        </Box>
      </Box>
      {isContentLoading ? (
        <Box className={classes.contentRoot}>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <Skeleton variant="rectangular" width="100%" height={100} />
            </Grid>
            <Grid item md={3}>
              <Skeleton variant="rectangular" width="100%" height={100} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        !collapse && (
          <Box>
            <Box
              style={{
                backgroundColor: '#D9DFE0',
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                style={{ width: '100%', padding: '4% 4% 0 4%' }}
                placeholder="Search Devices"
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </Box>
            <Box className={classes.contentRoot}>
              {renderProp({
                searchText: finalSearchVal.toLowerCase(),
                devices: sortedPendingComp,
              })}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default DeviceContainer;
