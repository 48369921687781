/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { ILocationCreatPayloadFromTemplate } from './types';

const initial_state: ILocationCreatPayloadFromTemplate = {
  backup_serial: null,
  project_info: {
    project_name: null,
    project_description: null,
    demo: false,
  },
  pro_dc_map: {},
  air_dc_map: {},
};

const backupSlice = createSlice({
  name: 'backup',
  initialState: initial_state,
  reducers: {
    set_project_info: (
      state,
      action: {payload: {project_name?: string; project_description?: string; demo?: boolean}},
    ) => {
      state.project_info = {
        ...state.project_info,
        ...action.payload,
        // project_name: action.payload.project_name,
        // project_description: action.payload.project_description,
        // demo: action.payload.demo
      };
    },
    set_backup_serial: (state, action: {payload: {serial: string}}) => {
      state.backup_serial = action.payload.serial;
    },
    update_backup_state: (state, action: {payload: ILocationCreatPayloadFromTemplate}) => {
      state = {
        ...state,
        ...action.payload,
      };
    },
    add_pro_container: (
      state,
      action: {
        payload: {
          config: {
            serial_name?: string;
            display_name?: string;
            description?: string;
            type?: number;
            notDirty?: boolean;
          };
          container_id: string;
        };
      },
    ) => {
      console.log('AAA', action.payload.config);
      state.pro_dc_map[action.payload.container_id] = {
        ...state.pro_dc_map[action.payload.container_id],
        ...action.payload.config,
      };
    },
    add_air_container: (
      state,
      action: {
        payload: {
          config: {
            email?: string;
            display_name?: string;
            description?: string;
            type?: number;
            password?: string;
            notDirty?: boolean;
          };
          container_id: string;
        };
      },
    ) => {
      state.air_dc_map[action.payload.container_id] = {
        ...state.air_dc_map[action.payload.container_id],
        ...action.payload.config,
        devices: {},
      };
    },
    add_air_device: (
      state,
      action: {
        payload: {
          device_id: string;
          new_device_id: string;
          container_id: string;
        };
      },
    ) => {
      state.air_dc_map[action.payload.container_id].devices[action.payload.device_id] = action.payload.new_device_id;
    },
    set_dirty: (state, action: {payload: {type: string; notDirty: boolean; dc_id: string}}) => {
      if (action.payload.type === 'air') {
        state.air_dc_map[action.payload.dc_id].notDirty = action.payload.notDirty;
      }

      if (action.payload.type === 'pro') {
        state.pro_dc_map[action.payload.dc_id].notDirty = action.payload.notDirty;
      }
    },
  },
});

export const { reducer } = backupSlice;
export const {
  set_backup_serial,
  set_project_info,
  update_backup_state,
  add_air_container,
  add_pro_container,
  add_air_device,
  set_dirty,
} = backupSlice.actions;
