/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import axios from 'axios';
import { ComponentType, EmitableEventType, EventType } from './PluginTypes';
import CloudIntegration from './services/cloud-integration.service';

export class CloudIntegrationPlugin {
  eventManager: EventManager;
  integration: CloudIntegration;

  constructor(eventManager: EventManager, integration: CloudIntegration) {
    this.eventManager = eventManager;
    this.integration = integration;
  }
}

export interface iComponent {
  // getUI(args?: any): JSX.Element
}

export abstract class PlugingComponent {
  public abstract GetUiComponent(
    eventManager: EventManager,
    internalEventManager: InternalEventManager,
    integration: CloudIntegration,
    rnProps: Record<any, any>
  ): JSX.Element;
}

export class Component {
  eventManager: EventManager;
  protected internalEvents: InternalEventManager = new InternalEventManager();
}

export class Observable {
  protected observers: Record<string, any> = [];
  public subscribe(eventType: any, cb: any) {
    this.observers[eventType].push(cb);
  }
  public notify(eventType: any, payload: unknown) {
    this.observers[eventType].map((ob) => ob(payload));
  }
}

export class EventManager extends Observable {
  observers: Record<EventType, any[]> = {
    [EventType.DC_CREATE_FORM_CANCEL]: [],
    [EventType.DC_CREATE_FORM_ERROR]: [],
    [EventType.DC_CREATE_FORM_SUBMIT]: [],
    [EventType.DEVICE_CREATE_FORM_ERROR]: [],
    [EventType.ON_NEW_AUTH_TOKEN]: [],
  };

  subscribe(
    eventType: EventType.DC_CREATE_FORM_SUBMIT,
    cb: (arg: {x: string; y: string}) => void
  ): () => void
  subscribe(eventType: EventType.ON_NEW_AUTH_TOKEN, cb: (token: string) => void): () => void
  subscribe(eventType: EventType, cb: any) {
    this.observers[eventType].push(cb);

    return () => {
      const filtered = this.observers[eventType].filter((observer) => observer != cb);
      this.observers[eventType] = filtered;
    }
  }

  notify(eventType: EventType.DC_CREATE_FORM_SUBMIT, payload: {x: string; y: string}): void;
  notify(eventType: EventType.ON_NEW_AUTH_TOKEN, token: string): void;
  notify(eventType: EventType, payload: unknown) {
    this.observers[eventType].map((ob) => ob(payload));
  }
}

export class InternalEventManager extends Observable {
  protected override observers: Record<string, any> = {
    [EmitableEventType.RESET_FORM]: [],
  };

  public override subscribe(eventType: EmitableEventType.RESET_FORM, cb: () => void): void;

  public override subscribe(eventType: EmitableEventType, cb: any): void {
    this.observers[eventType].push(cb);
  }

  notify(eventType: EmitableEventType.RESET_FORM, payload: undefined): void;

  notify(eventType: EmitableEventType, payload: unknown) {
    this.observers[eventType].map((ob) => ob(payload));
  }
}
