/* eslint-disable camelcase */
import {
  Button, Checkbox, FormControlLabel, Grid, Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ProjectContext } from '../../../../../store/Project/ProjectContext';
import { APP_TEXT } from '../../../../../utility/AppTexts/DefaultAppText.utils';
import { ConfirmLocDelete } from './ConfirmLocDelete';

export interface CustomDeleteOptions {
  air_dcs: boolean;
  air_devices_in_dc: boolean;
  pro_dcs: boolean;
  pro_devices_in_dc: boolean;
  userviews: boolean;
  userview_items_in_uv: boolean;
  location_vars: boolean;
  logics: boolean;
  location: boolean;
}

export const CustomDelete = () => {
  const [options, setOptions] = useState({
    air_dcs: false,
    air_devices_in_dc: false,
    pro_dcs: false,
    pro_devices_in_dc: false,
    userviews: false,
    userview_items_in_uv: false,
    location_vars: false,
    logics: false,
    location: false,
  });

  function handleChange(flag) {
    setOptions((oldOpt) => ({ ...oldOpt, [flag]: !oldOpt[flag] }));
  }
  const [open_delete_confirm, setOpenDeleteConfirm] = useState(false);
  const selectedProject = useContext(ProjectContext);

  return (
    <Grid container spacing={2}>
      <Grid item md={9}>
        <Typography variant="subtitle2">Customized Deletion</Typography>
        <Typography variant="subtitle1">Delete only selected parts</Typography>
      </Grid>
      <Grid
        item
        md={3}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => {
            setOpenDeleteConfirm(true);
          }}
          style={{ color: 'white', backgroundColor: 'orange' }}
        >
          {APP_TEXT.location.WIPE_CUSTOM}
        </Button>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={<Checkbox checked={options.air_dcs} onChange={() => handleChange('air_dcs')} />}
          label="Remove all Air Containers"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={options.air_devices_in_dc}
              onChange={() => handleChange('air_devices_in_dc')}
            />
          )}
          label="Remove all Air Devices but not Air Containers"
        />
        <FormControlLabel
          control={<Checkbox checked={options.pro_dcs} onChange={() => handleChange('pro_dcs')} />}
          label="Remove all Connect Containers"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={options.pro_devices_in_dc}
              onChange={() => handleChange('pro_devices_in_dc')}
            />
          )}
          label="Remove all Connect Devices but not Connect Containers"
        />
        <FormControlLabel
          control={
            <Checkbox checked={options.userviews} onChange={() => handleChange('userviews')} />
          }
          label="Remove all User Views"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={options.userview_items_in_uv}
              onChange={() => handleChange('userview_items_in_uv')}
            />
          )}
          label="Remove all items in User Views not the User Views"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={options.location_vars}
              onChange={() => handleChange('location_vars')}
            />
          )}
          label="Remove all location variables"
        />
        <FormControlLabel
          control={<Checkbox checked={options.logics} onChange={() => handleChange('logics')} />}
          label="Remove all logics"
        />
        {/* Following checkbox is dangerous as it can completely wipe out a location, so we remove it /*}
        {/* <FormControlLabel
          control={
            <Checkbox checked={options.location} onChange={() => handleChange('location')} />
          }
          label="Remove everything in the location"
        /> */}
      </Grid>
      <ConfirmLocDelete
        open={open_delete_confirm}
        setOpen={setOpenDeleteConfirm}
        options={options}
        setOptions={setOptions}
        isCustom
        loc_name={selectedProject.selected_project.title}
        loc_id={selectedProject.selected_project.id}
      />
    </Grid>
  );
};
