/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * A Higher order component which uses render prop pattern
 * to logically show/hide and emit click events on sub folders
 *
 * @author Maduka Dilshan
 */

import React, { useState } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

function unwrapArray(arg: any) {
  return Array.isArray(arg) ? arg[0] : arg;
}

const useStyle = makeStyles({
  folder_root: {
    width: '100%',
    justifyContent: 'flex-start',
  },
});

/**
 *
 * @param uv - userView object from the API
 * @param current_root - The folde_id, this can be -1 for root, or id of items/ sub folders
 * @param parent_root - The parent_id of the current_root
 * @param child - The Folder object from the API.
 * @returns
 */
const FolderContent: React.FC<any> = ({
  uv,
  current_root,
  parent_root,
  child,
  children,
  onClickItem,
}) => {
  const styles = useStyle();
  const [expanded, setExpanded] = useState(false);

  const onClickExpand = async () => {
    if (onClickItem) onClickItem(child.item_cat, uv, current_root, parent_root, child);
    setExpanded(!expanded);
  };

  const renderProp = unwrapArray(children);

  return (
    <div style={{ marginLeft: '10px' }}>
      <Button
        onClick={onClickExpand}
        color="primary"
        className={styles.folder_root}
        startIcon={!parent_root ? <ViewModuleIcon /> : <FolderOpenIcon />}
      >
        <span
          className={`calc ${child.icon_name}`}
          style={{
            marginRight: '5px',
            fontSize: '1.2rem',
            color: 'white',
            background: 'var(--light-green)',
            padding: '2%',
            borderRadius: '5px',
          }}
        />
        {child.display_name}
      </Button>
      {expanded && renderProp({ uv, current_root, parent_root })}
    </div>
  );
};

export default FolderContent;
