import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ProjectCardProps {
  title: string;
  value: string | number;
  unit: string;
  icon: React.ReactNode;
}

const useStyles = makeStyles({
  card: {
    //minWidth: 300,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    height: '100%',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EBF1F2',
    color: "#1ca086",
    width: 60,
    height: 60,
    borderRadius: '50%',
    marginRight: 16,
  },
  icon: {
    color: '#ffffff',
    fontSize: 36,
  },
});

export const StatCard: React.FC<ProjectCardProps> = ({
  title, value, unit, icon,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.iconContainer}>{icon}</div>
        <div>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h5">{value}</Typography>
          <Typography variant="subtitle1">{unit}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};
