/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IDFField } from '../../../../../config/deviceConfig/types';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';
import { appConsoleLog } from '../../../../../utility/appUtils';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.display_labels;
type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  resetForm: IFormResetRef;
  onChange: (payload:any, isInitializing?: boolean) => void;
};
interface IformData {
  display_label_1: string;
  display_label_2: string;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldDisplayLabels: React.FunctionComponent<ComponentProps> = (props) => {
  const [deb, setDeb] = useState<BehaviorSubject<{values: IformData; errors: any} | null>>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};

  const sendDefaults = () => {
    if (!props.fieldConfig || !deb) {
      return;
    }
    deb.next({
      values: {
        display_label_1: props.fieldConfig.default[0],
        display_label_2: props.fieldConfig.default[1],
      },
      errors: {},
    });
  };

  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      appConsoleLog(props.resetForm);
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        appConsoleLog({ name: props.resetForm.info.name });
        const values = props.resetForm.info.settings[props.fieldConfig.field];
        if (values && values.length === 2) {
          formRef.current.setValues({
            display_label_1: values[0],
            display_label_2: values[1],
          });
        }
      } else {
        formRef.current.resetForm();
      }
    }
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.resetForm]);
  const initilized = useRef(0);
  useEffect(() => {
    const b = new BehaviorSubject<{values: IformData; errors: any} | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }
        const { values, errors } = v;
        const infoData: any = {
          settings: {},
        };
        infoData.settings[props.fieldConfig.field] = [
          values.display_label_1,
          values.display_label_2,
        ];
        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    sendDefaults();
    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  const formRef = useRef<any>();
  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);

  const isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;

  return isEditable === true ? (
    <div className="pl-8">
      <Formik
        initialValues={{
          display_label_1: props.fieldConfig?.default[0],
          display_label_2: props.fieldConfig?.default[1],
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          display_label_1: Yup.string()
            .min(2, 'Display label not valid')
            .max(10, 'Display label must have less than 10 characters')
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Display label not valid'),
          display_label_2: Yup.string()
            .min(2, 'Display label not valid')
            .max(10, 'Display label must have less than 10 characters')
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Display label not valid'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values,
        }) => {
          deb?.next({
            values,
            errors,
          });
          return (
            <form>
              <div className="text-fields-flex">
                <div>
                  <TextField
                    className="dynamic-ui-form-control-full-width "
                    error={Boolean(touched.display_label_1 && errors.display_label_1)}
                    helperText={touched.display_label_1 && errors.display_label_1}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.display_label_1}
                    name="display_label_1"
                    label="Display Label On"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div>
                  <TextField
                    className="dynamic-ui-form-control-full-width ml-8"
                    error={Boolean(touched.display_label_2 && errors.display_label_2)}
                    helperText={touched.display_label_2 && errors.display_label_2}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.display_label_2}
                    name="display_label_2"
                    label="Display Label Close"
                    size="small"
                    variant="outlined"
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
