/* eslint-disable no-underscore-dangle */
import { Action, ActionTypes, NormalizedState } from '@smartlife-redux-state/common';
import { isArray } from 'lodash';

const extractDepMap = (state: NormalizedState) => {
  const actions = state.LogicActionCard;

  const userviewIdsMap = {};
  const logicIdsMap = {};

  if (actions.itemsById && isArray(Object.keys(actions.itemsById))) {
    // eslint-disable-next-line array-callback-return
    Object.keys(actions.itemsById).map((actionId) => {
      const action = actions.itemsById[actionId];

      if (action.type === ActionTypes.NOTIFICATION) {
        const config: any = action.payload;

        if (!config) return;

        if (
          config
          && config.userview_ids
          && isArray(config.userview_ids)
          && config.userview_ids.length > 0
        ) {
          config.userview_ids.map((uvId) => {
            if (!userviewIdsMap[uvId]) userviewIdsMap[uvId] = {};

            userviewIdsMap[uvId] = {
              ...userviewIdsMap[uvId],
              [action.id]: true,
            };

            return 0;
          });
        }
      }

      if (
        action.type === ActionTypes.LOGIC_RUN_STATUS
        || action.type === ActionTypes.LOGIC_CANCEL_STATUS
      ) {
        const variables = action.variable;

        if (variables && isArray(variables) && variables.length > 0) {
          variables.map((logic) => {
            if (!logicIdsMap[logic.logic_id]) logicIdsMap[logic.logic_id] = {};

            logicIdsMap[logic.logic_id] = {
              ...logicIdsMap[logic.logic_id],
              [action.id]: true,
            };
            return 0;
          });
        }
      }
    });
  }

  return { userviewIdsMap, logicIdsMap };
};

export default extractDepMap;
