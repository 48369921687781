/* eslint-disable max-classes-per-file */

import axios, { AxiosInstance } from 'axios';
import { CloudIntegrationPlugin, EventManager } from '../shared/Plugin';
import ShellyIntegration from './shelly.service';
import { ReactComponents } from './components';
import { EventType } from '../shared/PluginTypes';

class ShellyPlugin extends CloudIntegrationPlugin {
  private uiComponents: ReactComponents;

  constructor(axiosClient: AxiosInstance, eventManager: EventManager) {
    const integration = new ShellyIntegration(axiosClient, eventManager)
    super(eventManager, integration);
    this.uiComponents = new ReactComponents(this.eventManager, integration as ShellyIntegration);
  }

  public get DcCreateComponent() {
    return this.uiComponents.getDeviceContainerCreateUi;
  }

  public get DeviceCardComponent() {
    return this.uiComponents.getDeviceCardUi;
  }

  public get DcSettingsComponents() {
    return this.uiComponents.getDeviceContainerSettings;
  }

  public get UserviewExtraFieldComponents() {
    return this.uiComponents.getUserViewCreateExtraFields;
  }
}

export default ShellyPlugin;
