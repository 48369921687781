import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    paddingTop: 19,
    paddingBottom: 19,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 10,
    margingTop: 10,
    flex: 1,
  },
  header: {
    borderBottom: '1px solid var(--green-30)',
  },
  title: {
    color: 'var(--primary-dark-green)',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '120%',
    paddingBottom: 20,
  },
  titlesm: {
    color: 'var(--primary-dark-green)',
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '120%',
    paddingBottom: 20,
  },
});

export const DeviceContainerFormLayout: React.FC<{
  title: string;
  titleSize?: 'sm' | 'lg';
  children: any;
}> = ({ title, titleSize, children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.root}>
      <Box className={styles.header}>
        <Typography className={clsx(titleSize === 'lg' ? styles.title : styles.titlesm)}>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
