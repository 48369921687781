import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import {
  Box,
  ButtonBase,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  root: {
    padding: '2%',
    margin: '0 auto',
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  box: {
    padding: '5%',
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const INITIAL_FORM = {
  name: '',
  local_ip: '0.0.0.0',
  port: '8080',
  type: 'secure',
};

const SimpleDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onSuccess: (values: typeof INITIAL_FORM) => void;
}> = (props) => {
  const classes = useStyles();
  const [initialFormVal] = useState(INITIAL_FORM);
  const { onClose, onSuccess, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <DialogTitle id="simple-dialog-title">Custom Tunnel</DialogTitle>
      <Divider />
      <Box className={classes.box}>
        <Formik
          initialValues={initialFormVal}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            local_ip: Yup.string().required('Ip is required'),
            port: Yup.string().required('Port is required'),
            type: Yup.string().required('Type is required'),
          })}
          validateOnChange
          onSubmit={(values, {
            setErrors, setStatus, setSubmitting, resetForm,
          }) => {
            // TODO: create tunnel
            console.log('sdsd', values);
            onSuccess(values);
            handleClose();
          }}
        >
          {({
            errors, values, handleChange, touched, handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className={classes.innerBox}>
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Name"
                  size="small"
                  variant="outlined"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Box height={15} />
                <TextField
                  name="local_ip"
                  value={values.local_ip}
                  onChange={handleChange}
                  label="Local IP"
                  size="small"
                  variant="outlined"
                  error={Boolean(touched.local_ip && errors.local_ip)}
                  helperText={touched.local_ip && errors.local_ip}
                />
                <Box height={15} />
                <TextField
                  name="port"
                  value={values.port}
                  onChange={handleChange}
                  label="PORT"
                  size="small"
                  variant="outlined"
                  error={Boolean(touched.port && errors.port)}
                  helperText={touched.port && errors.port}
                />
                <Box height={15} />
                <FormControl>
                  <RadioGroup name="type" value={values.type} onChange={handleChange}>
                    <FormControlLabel value="secure" control={<Radio />} label="Secure" />
                    <FormControlLabel
                      value="not-secure"
                      control={<Radio />}
                      label="Not Secure ( Warning: This will exposes this device to internet. make sure to have application level security )"
                    />
                  </RadioGroup>
                </FormControl>
                <Box height={15} />
                <Box className={classes.btns}>
                  <Button color="primary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Create
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default SimpleDialog;
