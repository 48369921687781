/* eslint-disable camelcase */
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Skeleton,
  ListSubheader,
  FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { Action, AlarmAction as AlarmActionType } from '@smartlife-redux-state/common';
import { USERVIEW_NOTIFICATION_TYPES } from '../../../../../../../config/deviceConfig/Values';
import { userviewSearchGraphQL } from '../../../../../../../services/user-view/user-view.service';
import { ApolloAuthContext } from '../../../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../../../store/Project/ProjectContext';
import { update_action } from '../../../../../../../store/redux/features/logic-engine/slice';

const EventGroups = {
  100: 'Medical Alarms',
  110: 'Fire Alarms',
  120: 'Panic Alarms',
  130: 'Bulglar Alarms',
};

const EventCodes = {
  100: {
    // Medical Alarm
    101: 'Oebdabt Transmitter',
    102: 'Fail to report in',
  },
  110: {
    // Fire Alarm
    111: 'Smoke',
    112: 'Combustion',
    113: 'Water Flow',
    114: 'Heat',
    115: 'Pull Station',
    116: 'Duct',
    117: 'Flame',
    118: 'Near Alarm',
  },
  120: {
    // Panics Alarms
    121: 'Duress',
    122: 'Silent',
    123: 'Audible',
  },
  130: {
    // Bulglar Alarm
    131: 'Perimeter',
    132: 'Interior',
    133: '24 Hour',
    134: 'Entry/Exit',
    135: 'Day/Night',
    136: 'Outdoor',
    137: 'Temper',
    138: 'Near Alarm',
  },
};

const useStyle = makeStyles({
  form_root: {
    width: '100%',
    marginBottom: 10,
  },
});

const AlarmCodeGenerator: React.FC<{code: string; onCodeChange: (code: string) => void}> = ({
  code,
  onCodeChange,
}) => {
  const [eventCode, setEventCode] = useState('000');
  const [zone, setZone] = useState('000');
  const [group, setGroup] = useState('00');

  useEffect(() => {
    console.log('X', code);
    if (!code || code.length === 0) return;
    // 18113000002
    const z = code.slice(code.length - 3, code.length);
    const g = code.slice(code.length - 5, code.length - 3);
    const e = code.slice(code.length - 8, code.length - 5);

    console.log('X', z, g, e);

    setEventCode(e);
    setZone(z);
    setGroup(g);
  }, [code]);

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={12}>
        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
          <InputLabel>Alarm Event</InputLabel>
          <Select
            label="Alarm Event"
            value={eventCode}
            onChange={(e) => onCodeChange(`${e.target.value}${group}${zone}`)}
          >
            <ListSubheader>Medical Alarms</ListSubheader>
            {Object.keys(EventCodes['100']).map((itemKey) => (
              <MenuItem value={`${itemKey}`}>{EventCodes['100'][itemKey]}</MenuItem>
            ))}
            <ListSubheader>Fire Alarms</ListSubheader>
            {Object.keys(EventCodes['110']).map((itemKey) => (
              <MenuItem value={`${itemKey}`}>{EventCodes['110'][itemKey]}</MenuItem>
            ))}
            <ListSubheader>Panic Alarms</ListSubheader>
            {Object.keys(EventCodes['120']).map((itemKey) => (
              <MenuItem value={`${itemKey}`}>{EventCodes['120'][itemKey]}</MenuItem>
            ))}
            <ListSubheader>Bulglar Alarms</ListSubheader>
            {Object.keys(EventCodes['130']).map((itemKey) => (
              <MenuItem value={`${itemKey}`}>{EventCodes['130'][itemKey]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={4} sm={12}>
        <Box style={{ display: 'flex' }}>
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>Digit 1</InputLabel>
            <Select
              label="Digit 1"
              value={group.slice(0, 1)}
              onChange={(e) => onCodeChange(`${eventCode}${e.target.value + group.slice(1, group.length)}${zone}`)}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
                <MenuItem value={digit}>{digit}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box width={3} />
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>Digit 0</InputLabel>
            <Select
              label="Digit 0"
              value={group.slice(1, 2)}
              onChange={(e) => onCodeChange(`${eventCode}${group.slice(0, 1) + e.target.value}${zone}`)}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
                <MenuItem value={digit}>{digit}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item md={5} sm={12}>
        <Box style={{ display: 'flex', width: '100%' }}>
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>Digit 3</InputLabel>
            <Select
              label="Digit 3"
              value={zone.slice(0, 1)}
              onChange={(e) => onCodeChange(`${eventCode}${group}${e.target.value + zone.slice(1, zone.length)}`)}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
                <MenuItem value={digit}>{digit}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box width={3} />
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>Digit 1</InputLabel>
            <Select
              label="Digit 1"
              value={zone.slice(1, 2)}
              onChange={(e) => onCodeChange(
                `${eventCode}${group}${
                  zone.slice(0, 1) + e.target.value + zone.slice(2, zone.length)
                }`,
              )}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
                <MenuItem value={digit}>{digit}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box width={3} />
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel>Digit 0</InputLabel>
            <Select
              label="Digit 0"
              value={zone.slice(2, 3)}
              onChange={(e) => onCodeChange(`${eventCode}${group}${zone.slice(0, 2) + e.target.value}`)}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
                <MenuItem value={digit}>{digit}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const AlarmAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const actionCpy: AlarmActionType = action as any;
  const classes = useStyle();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [provider, setProvider] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountPassword, setAccountPassword] = useState('');
  const [alarmTypeEconded, setAlarmTypeEncoded] = useState('');

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);

  const [showCustomEncoded, setShowCustomEncoded] = useState(false);

  useEffect(() => {
    dispatch(
      update_action({
        action: {
          ...action,
          payload: {
            ...action.payload,
            alarm_provider: provider,
            account_number: accountNumber,
            account_name: accountName,
            account_password: accountPassword,
            alarm_type_encoded: alarmTypeEconded,
          } as any,
        },
        isChild,
        parentAction,
      }),
    );
  }, [provider, accountName, accountNumber, accountPassword, alarmTypeEconded]);

  useEffect(() => {
    const init = async () => {
      try {
        if (actionCpy.payload) {
          setProvider(actionCpy.payload.alarm_provider);
          setAccountName(actionCpy.payload.account_name);
          setAccountNumber(actionCpy.payload.account_number);
          setAccountPassword(actionCpy.payload.account_password);
          setAlarmTypeEncoded(actionCpy.payload.alarm_type_encoded);
        }
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={12}>
        <FormControl className={classes.form_root} variant="outlined" size="small">
          <InputLabel>Provider</InputLabel>
          <Select
            label="Provider"
            variant="outlined"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
          >
            <MenuItem value="alarm_nz">Alarm NZ</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={8} sm={12}>
        <FormControl className={classes.form_root}>
          <TextField
            label="Account Name"
            variant="outlined"
            size="small"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.form_root}>
          <TextField
            label="Account Number"
            variant="outlined"
            size="small"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.form_root}>
          <TextField
            label="Account Password"
            variant="outlined"
            size="small"
            type="password"
            value={accountPassword}
            onChange={(e) => setAccountPassword(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showCustomEncoded}
                onChange={(e) => setShowCustomEncoded(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            label="Custom Code"
          />
        </FormControl>
        {showCustomEncoded ? (
          <FormControl className={classes.form_root}>
            <TextField
              label="Alarm Encoded Phrase"
              variant="outlined"
              size="small"
              value={alarmTypeEconded}
              onChange={(e) => setAlarmTypeEncoded(e.target.value)}
            />
          </FormControl>
        ) : (
          <FormControl className={classes.form_root}>
            <AlarmCodeGenerator
              code={alarmTypeEconded}
              onCodeChange={(code) => {
                console.log('codeee', code);
                setAlarmTypeEncoded(code);
              }}
            />
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};
