/* eslint-disable camelcase */
import { BACKUP_RESTORE_STEP_TYPES } from '../backup-restore';
import { V2InfoMapRoot } from './v2types';

export interface BackupLocationConfigState {
  ui: BackupUIConfigState;
  info: {
    v2: V2InfoMapRoot,
    docs: string[];
    backup_point: BackupPoint;
    loc_var_map: {
      [loc_dc_id: string]: {
        [device_id: string]: LocationVarMapItem;
      };
    };
    pro_dc_map: {
      [dc_id: string]: ProDCMapItem;
    };
    air_dc_map: {
      [dc_id: string]: AirDCMapItem;
    };
    air_device_map: any;
    loc_var_dc_map: any;
    pro_device_map: any;
    project_info: any;
    userviews_map: any;
    created_at: any;
    userview_items_count: {
      [userview_id: string]: number;
    };
    pro_device_count: {
      [pro_dc_id: string]: number;
    };
    air_device_count: {
      [air_dc_id: string]: number;
    };
  };
  history: {
    past_tasks_list: {[key: string]: HistoryTaskItem};
    last_past_task: HistoryLastTaskItem;
  };
  form_state: {
    clone_step_location_var: any;
    clone_step_pro_dc: CloneStepProAirDC;
    clone_step_air_dc: CloneStepAirDC;
  };
}

export interface CloneStepProAirDC {
  is_done_fill: boolean;
  values: {
    [dc_id: string]: {
      value: string;
      isDirty: boolean;
    };
  };
}

export interface CloneStepAirDC {
  is_done_fill: boolean;
  values: {
    [dc_id: string]: {
      value: string;
      isDirty: boolean;
      devices: any;
    };
  };
}

export interface BackupUIConfigState {
  is_init: boolean;
  is_done: boolean;
  initializing_error: any;
  right_panel: {
    configurable_steps_in_order: BACKUP_RESTORE_STEP_TYPES[];
    next_step_to_configure: BACKUP_RESTORE_STEP_TYPES;
    expanded_step: BACKUP_RESTORE_STEP_TYPES;
    steps: {
      [ui_type: string]: BackupUIRightPanel;
    };
  };
  left_panel: BackupUILeftPanel;
}

export interface BackupUIRightPanel {
  is_done: boolean;
  is_running: boolean;
  is_configurable: boolean;
  ui_type: BACKUP_RESTORE_STEP_TYPES;
  label: string;
  errors?: any[];
}

interface BackupUILeftPanel {
  active_tab: number;
  log_path: string;
  tabs: {
    [key: string]: {
      label: string;
      key: number;
      type: LEFT_PANEL_TABS;
    };
  };
}

export enum LEFT_PANEL_TABS {
  CONTAINER_CREATE = 'container_create',
  TERMINAL = 'terminal',
}

export interface BackupPoint {
  _id: string;
  view_type: string;
  name: string;
  description: string;
  backup_serial: string;
  created_at: number;
  updated_at: number;
  __v: number;
}

export interface LocationVarMapItem {
  id?:string;
  prodc_id: string;
  name: string;
  tags: any[];
  system_tags: string[];
  shadow_type: string;
  cat: string;
  sub_cat: string;
  device_type: string;
  settings: any;
}

export interface ProDCMapItem {
  id: string;
  serial_name: string;
  name: string;
  description: string;
  dc_type: number;
  dc_status: number;
}

export interface AirDCMapItem {
  id: string;
  email: string;
  name: string;
  country_code: string;
  description: string;
  adc_type: number;
  adc_status: number;
}

export interface HistoryTaskItem {
  _id: string;
  created_by: string;
  project_id: string;
  backup_serial: string;
  status: string;
  type: string;
  write_type: string;
  log_ref: string;
  created_at: number;
  updated_at: number;
  __v: number;
}

export interface HistoryLastTaskItem {
  _id: string;
  created_by: string;
  project_id: string;
  backup_serial: string;
  status: string;
  type: string;
  write_type: string;
  log_ref: string;
  info_state: string;
  created_at: number;
  updated_at: number;
  __v: number;
  task_result: any;
}

export interface TaskInfo {
  _id: string;
  created_by: string;
  project_id: string;
  backup_serial: string;
  status: string;
  type: string;
  write_type: string;
  log_ref: string;
  info_state: string;
  created_at: number;
  updated_at: number;
  __v: number;
  task_result: any;
}

export enum PROJECT_CLONE_API_TASK_TYPE {
  locationVariables = 'lvar',
  ProDevice = 'prod',
  AirDevice = 'aird',
  userViews = 'uv',
  Logics = 'lgc',
  userviewItems = 'uvi',
  NodeRedJsonIdUpdate = 'nred',
}
