/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import AddIcon from '@mui/icons-material/Add';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './ListMembers.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { MemberCardComponent } from '../../../../components/shared/MemberCard/MemberCard';
import { InvitationCardComponent } from '../../../../components/shared/InvitationCard/InvitationCard';
import { getProjectMembersGraphQL } from '../../../../services/member/member.service';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { appConsoleLog, AppUtils, getPermissionString } from '../../../../utility/appUtils';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import {
  projectInvitationAcceptRejectGraphQL,
  projectInvitationsListGraphQL,
} from '../../../../services/invitation/invitation.service';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
  SuccessSnackbarActions,
  SuccessSnackbarOptions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: <></>,
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1ca086',
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    // minWidth: 72,
    // marginRight: theme.spacing(4),
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#818181',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#1ca086',
      opacity: 1,
    },
    '&$selected': {
      color: '#1ca086',
      // fontWeight: 'bold',
    },
    '&:focus': {
      color: '#1ca086',
    },
  },
  selected: {},
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<any>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
    // overflow: 'scroll',
  },
}));

export const ListMembersComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);

  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [resultCount, setResultCount] = useState('');
  const [memberList, memberListList] = useState([]);

  const [projectIdStatus, projectIdStatusSet] = useState(false);
  const [accessLevel, accessLevelSet] = useState(-1);
  const [permissionType, permissionTypeSet] = useState(-1);

  const [invitations, setInvitationsList] = useState([]);
  const [invitationsCount, setInvitationsCount] = useState(0);

  const [myAccessLevel, setMyAccessLevel] = useState(0);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    if (
      !selectedProject
      || !selectedProject.selected_project
      || !selectedProject.selected_project.id
    ) {
      projectIdStatusSet(false);
      handleChange(null, 6);
    } else {
      projectIdStatusSet(true);
      getProjectMembersGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
        accessLevel,
        permissionType,
      )
        .then((res) => {
          appConsoleLog(res.projectListUsers);
          setResultCount(res.projectListUsers.users ? res.projectListUsers.users.length : 0);
          // find my access_level for the project
          if (res.projectListUsers.users) {
            const meOnProject = res.projectListUsers.users.find(
              // eslint-disable-next-line @typescript-eslint/no-shadow
              (user: any) => user.user_email === userProfile.user_profile.email,
            );
            if (meOnProject) {
              setMyAccessLevel(meOnProject.access_level);
            }
          }
          memberListList(res.projectListUsers.users ? res.projectListUsers.users : 0);
        })
        .catch((err) => {
          // alert("Something went wrong!")
        });
    }
    // if(!selectedProject.selected_project.id || selectedProject.selected_project.id === 0){
    //   return;
    // }
  }, [selectedProject.selected_project]);

  useEffect(() => {
    projectInvitationsListGraphQL(userProfile?.apollo_client)
      .then((res) => {
        setInvitationsList(
          res.projectInvitationsList.invitations ? res.projectInvitationsList.invitations : [],
        );
        setInvitationsCount(
          res.projectInvitationsList.invitations ? res.projectInvitationsList.invitations.length : 0,
        );
      })
      .catch((err) => {
        // alert("Something went wrong!")
      });
  }, []);

  // Invitation Status
  // Pending = 1
  // Aproved = 2
  // Rejected = 3
  const projectInvitationAcceptReject = async (permission_id: string, new_status: number) => {
    try {
      setLoading(true);
      await projectInvitationAcceptRejectGraphQL(
        userProfile?.apollo_client,
        permission_id,
        new_status,
      )
        .then(async (res) => {
          const index = invitations.findIndex((prop) => prop.perm_id === permission_id);
          invitations.splice(index, 1);
          setInvitationsCount(invitations.length ? invitations.length : 0);
          setLoading(false);
          if (new_status === 2) {
            snackbar.enqueueSnackbar(APP_TEXT.invitations.INVITATION_APPROVE_SUCCESS, {
              key: 'key1',
              ...SuccessSnackbarOptions,
              action: SuccessSnackbarActions(snackbar.closeSnackbar),
            });
          } else {
            snackbar.enqueueSnackbar(APP_TEXT.invitations.INVITATION_REJECT_SUCCESS, {
              key: 'key1',
              ...SuccessSnackbarOptions,
              action: SuccessSnackbarActions(snackbar.closeSnackbar),
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.invitations.INVITATION_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (error) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.invitations.INVITATION_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            {APP_TEXT.members.All_TITLE}
            {' '}
            {'    '}
            {' '}
            <span style={{ opacity: 0.4 }}>{resultCount}</span>
            <span style={{ opacity: 0.4, fontSize: '14px' }}>
              {Number(resultCount) > 1 ? ' members' : ' member'}
            </span>
          </Typography>
          <Box>
            <DefaultButton
              disabled={loading}
              onClick={() => navigateRoute(
                `/app/member/create?projectId=${selectedProject.selected_project.id}&my_access=${myAccessLevel}`,
              )}
            >
              <AddIcon />
              {APP_TEXT.members.ADD_MEMBER}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <AntTabs
          value={projectIdStatus ? value : 6}
          onChange={handleChange}
          aria-label="ant example"
          className={styles.tabsTop}
          variant="scrollable"
        >
          <AntTab disabled={!projectIdStatus} label={APP_TEXT.members.ALL} />
          <AntTab disabled={!projectIdStatus} label={APP_TEXT.members.MEMBER_TYPE1} />
          <AntTab disabled={!projectIdStatus} label={APP_TEXT.members.MEMBER_TYPE2} />
          <AntTab disabled={!projectIdStatus} label={APP_TEXT.members.MEMBER_TYPE3} />
          <AntTab disabled={!projectIdStatus} label={APP_TEXT.members.MEMBER_TYPE4} />
          <AntTab disabled={!projectIdStatus} label={APP_TEXT.members.MEMBER_TYPE5} />
          <AntTab
            label={
              APP_TEXT.members.MEMBER_TYPE6 + (invitationsCount > 0 ? ` (${invitationsCount})` : '')
            }
          />
        </AntTabs>
        <TabPanel value={value} index={0}>
          <div className="row tab-in-container">
            {memberList.map((member: any) => {
              let profileBgStyle: React.CSSProperties = {
                backgroundImage:
                  "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
              };
              if (member.profile_photo) {
                profileBgStyle = {
                  backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                    'photo',
                    member.profile_photo,
                  )}")`,
                };
              }
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 mt-3"
                  onClick={() => {
                    navigateRoute(
                      `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                    );
                    // navgiate({
                    //   pathname: ``,
                    //   state: { user_data: member },
                    // });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <MemberCardComponent
                    isMe={member.user_email === userProfile.user_profile.email}
                    title={member.user_name ? member.user_name : member.user_email}
                    permissionType={
                      member.permission_status === 1
                        ? `${getPermissionString(member.access_level)} - ${
                          APP_TEXT.members.PENDING
                        }`
                        : getPermissionString(member.access_level)
                    }
                    desciption={member.user_title ? member.user_title : 'N/A'}
                    profileBgStyle={profileBgStyle}
                  />
                </div>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="row tab-in-container">
            {memberList.map((member: any) => {
              if (member && member.access_level === 1 && member.permission_status === 2) {
                let profileBgStyle: React.CSSProperties = {
                  backgroundImage:
                    "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
                };

                if (member.profile_photo) {
                  profileBgStyle = {
                    backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                      'photo',
                      member.profile_photo,
                    )}")`,
                  };
                }
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-3"
                    onClick={() => {
                      navigateRoute(
                        `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                      );
                    }}
                  >
                    <MemberCardComponent
                      title={member.user_name ? member.user_name : member.user_email}
                      permissionType={getPermissionString(member.access_level)}
                      desciption={member.user_title ? member.user_title : 'N/A'}
                      profileBgStyle={profileBgStyle}
                    />
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="row tab-in-container">
            {memberList.map((member: any) => {
              if (member && member.access_level === 2 && member.permission_status === 2) {
                let profileBgStyle: React.CSSProperties = {
                  backgroundImage:
                    "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
                };

                if (member.profile_photo) {
                  profileBgStyle = {
                    backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                      'photo',
                      member.profile_photo,
                    )}")`,
                  };
                }
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-3"
                    onClick={() => {
                      navigateRoute(
                        `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                      );
                    }}
                  >
                    <MemberCardComponent
                      title={member.user_name ? member.user_name : member.user_email}
                      permissionType={getPermissionString(member.access_level)}
                      desciption={member.user_title ? member.user_title : 'N/A'}
                      profileBgStyle={profileBgStyle}
                    />
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="row tab-in-container">
            {memberList.map((member: any) => {
              if (member && member.access_level === 3 && member.permission_status === 2) {
                let profileBgStyle: React.CSSProperties = {
                  backgroundImage:
                    "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
                };

                if (member.profile_photo) {
                  profileBgStyle = {
                    backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                      'photo',
                      member.profile_photo,
                    )}")`,
                  };
                }
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-3"
                    onClick={() => {
                      navigateRoute(
                        `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                      );
                    }}
                  >
                    <MemberCardComponent
                      title={member.user_name ? member.user_name : member.user_email}
                      permissionType={getPermissionString(member.access_level)}
                      desciption={member.user_title ? member.user_title : 'N/A'}
                      profileBgStyle={profileBgStyle}
                    />
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="row tab-in-container">
            {memberList.map((member: any) => {
              if (member && member.access_level === 4 && member.permission_status === 2) {
                let profileBgStyle: React.CSSProperties = {
                  backgroundImage:
                    "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
                };

                if (member.profile_photo) {
                  profileBgStyle = {
                    backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                      'photo',
                      member.profile_photo,
                    )}")`,
                  };
                }
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-3"
                    onClick={() => {
                      navigateRoute(
                        `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                      );
                    }}
                  >
                    <MemberCardComponent
                      title={member.user_name ? member.user_name : member.user_email}
                      permissionType={getPermissionString(member.access_level)}
                      desciption={member.user_title ? member.user_title : 'N/A'}
                      profileBgStyle={profileBgStyle}
                    />
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <div className="row tab-in-container">
            {memberList.map((member: any) => {
              if (member && member.permission_status === 1) {
                let profileBgStyle: React.CSSProperties = {
                  backgroundImage:
                    "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
                };
                if (member.profile_photo) {
                  profileBgStyle = {
                    backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                      'photo',
                      member.profile_photo,
                    )}")`,
                  };
                }
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-3"
                    onClick={() => {
                      navigateRoute(
                        `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                      );
                    }}
                  >
                    <MemberCardComponent
                      title={member.user_name ? member.user_name : member.user_email}
                      permissionType={`${getPermissionString(member.access_level)} - ${
                        APP_TEXT.members.PENDING
                      }`}
                      desciption={member.user_title ? member.user_title : 'N/A'}
                      profileBgStyle={profileBgStyle}
                    />
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div className="row tab-in-container">
            {invitations.length > 0 ? (
              <div>
                {invitations.map((invitation: any) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 mt-3" key={invitation.id}>
                    <InvitationCardComponent
                      title={invitation.name}
                      desciption={invitation.description}
                      approveAction={() => projectInvitationAcceptReject(invitation.perm_id, 2)}
                      rejectAction={() => projectInvitationAcceptReject(invitation.perm_id, 3)}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="col-md-12">
                <h2 className="custom-link1">{APP_TEXT.invitations.INVITATIONS_AVAILABLE}</h2>
              </div>
            )}
          </div>
        </TabPanel>
      </Box>
    </Layout>
  );
};

export default ListMembersComponent;
