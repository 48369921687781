import { useNavigate } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
import { COLORS, FormContainer } from '@smartlife/common-ui';
import ListLayout from 'src/layout/ListLayout';

const ListEntryForm = () => {
  const navigate = useNavigate();
  return (
    <ListLayout>
      <FormContainer
        onCancel={async () => navigate(-1)}
        onSuccessSubmit={async () => navigate(-1)}
      />
    </ListLayout>
  );
};

export default ListEntryForm;
