import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';

import img1 from 'src/assets/images/ev_car.jpg';
import img2 from 'src/assets/images/inverter.png';
import img3 from 'src/assets/images/car_charge.jpg';

import clsx from 'clsx';
import { iEnodeIntegration } from '../enode.service';

const useStyles = makeStyles({
  car: {
    background: `url(${img1})`,
  },
  inverter: {
    background: `url(${img2})`,
  },
  charger: {
    background: `url(${img3})`,
  },
  cardRoot: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: 100,
    height: 100,
    borderRadius: 5,
    border: '1px solid #D9DFE0',
  },
});

const IntegrateCard: React.FC<{
  title: string;
  children: any;
  disabled?: boolean;
  onClick: () => void;
}> = ({
  title, children, disabled, onClick,
}) => (
  <Box
    style={{
      display: 'flex',
      width: 100,
      marginRight: 10,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography style={{ color: '#3194A2' }} variant="subtitle1">
      {title}
    </Typography>
    {children}
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      size="small"
      style={{ marginTop: 5 }}
    >
      Integrate
    </Button>
  </Box>
);

export const VendorListConfiguration: React.FC<{
  dcId: string;
  displayName: string;
  integration: iEnodeIntegration;
  setSuccess: any;
}> = ({
  dcId, integration, displayName, setSuccess,
}) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  // let url = 'https://link.sandbox.enode.io?link_state=MWFiMjNjZDRAMTc1YjFkZmUtMzQ0NC00MjYwLWFmNjAtOGE1YzNmMzRkNWFmOjg4YjQ5NzE5NTliMzkxMDM0YjZjNjM1Y2I0MmUxOWJiNDc3YWRiYjgxYzM3NzU1MTc2Zjg1MDc4MjZkNDQwODA%3D&requested_scope=all&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fenode-cb%3Fvendor%3Dvehicle';

  const getEnodeUrlRequest = async (dcId: string, vendor: string) => {
    // return url;
    // `${window.location.href}&type=ENODE&showSync=true&dcId=${dcId}&displayName=${displayName}&vendor=${vendor}`,
    const redirectUri = new URL(`${window.location.origin}/enode-cb?vendor=${vendor}`);
    const data = await integration.getEnodeUrl(dcId, vendor, redirectUri.href);
    if (integration.isValidHttpUrl(data && data.result && data.result.url)) {
      return data.result.url;
    }
    throw new Error('Invalid Enode Response');
  };

  const openPopupWindow = (url: string) => {
    const popup = window.open(url, 'popup', 'width=800,height=600');

    function listner(e) {
      if (e.origin !== location.origin) return;
      const message = e.data;

      if (message === 'done') {
        popup.close();
        syncDevices(dcId);
      }
    }

    function unload(e) {
      window.removeEventListener('message', listner);
      window.removeEventListener('unload', unload);
    }

    window.addEventListener('message', listner);
    window.addEventListener('unload', unload);
  };

  const syncApi = async (dcId: string, vendor: string) => {
    try {
      setLoading(true);
      enqueueSnackbar('Openning the Enode Integrator Window....', { variant: 'info' });
      const url = await getEnodeUrlRequest(dcId, vendor);
      openPopupWindow(url);
    } catch (err) {
      enqueueSnackbar('Failed to get enode url', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const syncDevices = async (dcId: string) => {
    try {
      enqueueSnackbar('Syncing...', { variant: 'info' });
      const res = await integration.syncDevices(dcId);
      setSuccess(true);
    } catch (err) {
      enqueueSnackbar('Syncing Failed...', { variant: 'error' });
    }
  };

  return (
    <>
      <Typography variant="h6">1. Available Integrate Options</Typography>
      <Box height={10} />
      <Box style={{ display: 'flex' }}>
        <IntegrateCard
          title="Vehicle"
          disabled={!dcId || loading}
          onClick={() => syncApi(dcId, 'vehicle')}
        >
          <Box className={clsx(styles.car, styles.cardRoot)} />
        </IntegrateCard>
        <IntegrateCard
          title="Inverters"
          disabled={!dcId || loading}
          onClick={() => syncApi(dcId, 'inverter')}
        >
          <Box className={clsx(styles.inverter, styles.cardRoot)} />
        </IntegrateCard>
        <IntegrateCard
          title="Chargers"
          disabled={!dcId || loading}
          onClick={() => syncApi(dcId, 'charger')}
        >
          <Box className={clsx(styles.charger, styles.cardRoot)} />
        </IntegrateCard>
      </Box>
      <Box height={20} />
      <Typography variant="h6">2. Advanced Settings</Typography>
      <Box>
        <Box height={10} />
        <Button disabled={!dcId} variant="outlined" color="info" onClick={() => syncDevices(dcId)}>
          Sync Device Collection With Enode
        </Button>
      </Box>
    </>
  );
};
