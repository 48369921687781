import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { IAirDevice, ITuyaDevice } from '../types';
import {
  gqlAirDeviceCreate, gqlAirDeviceGet, gqlAirDeviceInfo, gqlAirDeviceUpdate,
} from './graphql';

const airDeviceInfo = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  airDCId: string,
  airDeviceExternalId: string,
): Promise<ITuyaDevice | null> => {
  const res = await apolloClient.query({
    query: gqlAirDeviceInfo,
    variables: {
      airDCId,
      airDeviceExternalId,
    },
  });

  const device = res.data.airDeviceInfo;
  if (device == null) {
    return null;
  }
  device.status = JSON.parse(device.status);
  return device;
};

export const airDeviceGet = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  deviceId: string,
): Promise<IAirDevice> => {
  const res = await apolloClient.query({
    query: gqlAirDeviceGet,
    variables: {
      payload: deviceId,
    },
  });
  const dev = res.data.airDeviceGet.airDevice;
  if (dev != null) {
    dev.settings = JSON.parse(dev.settings);
    dev.config = JSON.parse(dev.config);
  }
  return dev;
};

const airDeviceCreate = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  deviceInfo: IAirDevice,
): Promise<IAirDevice> => {
  const settingsJson = JSON.stringify(deviceInfo.settings);
  const configJson = JSON.stringify(deviceInfo.config);
  const res = await apolloClient.mutate({
    mutation: gqlAirDeviceCreate,
    variables: {
      payload: {
        airdc_id: deviceInfo.airdc_id,
        tuya_device_id: deviceInfo.tuya_device_id,
        name: deviceInfo.name,
        tags: deviceInfo.tags,
        system_tags: deviceInfo.system_tags,
        shadow_type: deviceInfo.shadow_type,
        cat: deviceInfo.cat,
        sub_cat: deviceInfo.sub_cat,
        device_type: deviceInfo.device_type,
        settings: settingsJson,
        config: configJson,
      },
    },
  });
  return res.data.airDeviceCreate;
};

const airDeviceUpdate = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  deviceInfo: IAirDevice,
): Promise<void> => {
  const settingsJson = JSON.stringify(deviceInfo.settings);
  const configJson = JSON.stringify(deviceInfo.config);
  await apolloClient.mutate({
    mutation: gqlAirDeviceUpdate,
    variables: {
      payload: {
        item_id: deviceInfo.id,
        item: {
          name: deviceInfo.name,
          tags: deviceInfo.tags,
          cat: deviceInfo.cat,
          sub_cat: deviceInfo.sub_cat,
          device_type: deviceInfo.device_type,
          shadow_type: deviceInfo.shadow_type,
          system_tags: deviceInfo.system_tags,
          settings: settingsJson,
          config: configJson,
        },
      },
    },
  });
};

export default {
  airDeviceInfo,
  airDeviceCreate,
  airDeviceUpdate,
  airDeviceGet,
};
