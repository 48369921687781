/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
// /* eslint-disable max-len */
// /* eslint-disable camelcase */
// /* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useState, useCallback, useMemo,
} from 'react';
import { useSnackbar } from 'notistack';
import { debounce, isArray } from 'lodash';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import qs from 'query-string';
import {
  Grid, InputAdornment, Tab, Tabs,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { CLOUD_INTEGRATIONS, CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';

import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { ConfirmDelete } from '../../../../components/shared/CustomDeleteModal/ConfirmDelete';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
    overflow: 'auto',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ShellyContainerSettings = (rnProps: {dcName: string; dcId: string; projectId: string}) => pluginHook.shellyPlugin.DcSettingsComponents(rnProps);
const EnodeContainerSetting = pluginHook.enodePlugin.uiComponents.getDcSettingUi;
// TODO: move the defition of DeviceCardComponent into shared director of plugin. This is not Shelly Specific!
const ShellyDeviceCard = (rnProps: any) => pluginHook.shellyPlugin.DeviceCardComponent(rnProps);
const EnodeDeviceCard = (rnProps: any) => pluginHook.enodePlugin.uiComponents.getDeviceCardUi(rnProps);

const CloudDeviceCard: React.FC<{
  device: any;
  selectedProjectID: string;
  selectedDCID: string;
  selectedDCName: string;
  cintType: any;
}> = ({
  device, selectedDCID, selectedDCName, selectedProjectID, cintType,
}) => {
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  if (cintType == 3) {
    return (
      <EnodeDeviceCard
        category={`${device.cat} - ${device.sub_cat}`}
        id={device.id}
        cintId={device.cint_device_id}
        name={device.name}
        bgColor="white"
        cursor="default"
        key={device.id}
        snackbar={snackbar}
        showStatus
        status={device.device_status}
        onClick={() => {
          navgiate(
            `/app/device-container/cloud-int/update/device?projectId=${selectedProjectID}&dcId=${selectedDCID}&deviceId=${device.id}&dcName=${selectedDCName}&cint_type=${cintType}`,
          );
        }}
      />
    );
  }

  if (cintType == 4) {
    return (
      <ShellyDeviceCard
        category={`${device.cat} - ${device.sub_cat}`}
        id={device.id}
        cintId={device.cint_device_id}
        name={device.name}
        bgColor="white"
        cursor="default"
        key={device.id}
        snackbar={snackbar}
        showStatus
        status={device.device_status}
        onClick={() => {
          navgiate(
            `/app/device-container/cloud-int/update/device?projectId=${selectedProjectID}&dcId=${selectedDCID}&deviceId=${device.id}&dcName=${selectedDCName}&cint_type=${cintType}`,
          );
        }}
      />
    );
  }

  return null;
};

export const SingleContainerCloudIntegration: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);

  const selectedProjectID = useQueryStringFirst('projectId');
  const selectedDCID = useQueryStringFirst('dcId');
  const selectedDCName = useQueryStringFirst('name');
  const cintType = useQueryStringFirst('cint_type');
  const tab = useQueryStringFirst('tab');

  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const snackbar = useSnackbar();

  const [categorizedDcList, setCatgorizedDcList] = useState<Record<string, CiDevice[]>>({});
  const [dcDetails, setDCDetails] = useState<any>();
  const [deviceContainerName, setDeviceContainerName] = useState(
    qs.parse(window.location.search).name as string,
  );

  const [refreshDeviceList, RefreshDeviceListNow] = useState(0);

  const classes = useStyles();

  const [deleteState, setDeleteState] = useState<boolean>(false);

  const [value, setValue] = React.useState(tab === '1' ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const categorizedSearchedDevices = useMemo(() => {
    const newCategorizedList: Record<string, CiDevice[]> = {};
    Object.keys(categorizedDcList).map((catogery) => {
      const devices = categorizedDcList[catogery];
      if (isArray(devices)) {
        const filteredDevices = devices.filter(
          (device) => device.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1,
        );
        if (filteredDevices.length > 0) {
          newCategorizedList[catogery] = filteredDevices;
        }
      } else {
        newCategorizedList[catogery] = devices;
      }
      return catogery;
    });
    return newCategorizedList;
  }, [categorizedDcList, searchQuery]);

  const debouncedGetSelectorData = useCallback(
    debounce((keyword: string) => setSearchQuery(keyword), 100),
    [],
  );
  const onDcSearch = (val = '') => {
    debouncedGetSelectorData(val);
  };

  const catogorizeDcs = (devices: CiDevice[]) => {
    const categories: Record<string, CiDevice[]> = {};
    devices.map((dc) => {
      if (Object.keys(categories).indexOf(dc.cat) === -1) {
        categories[dc.cat] = [dc];
      } else {
        categories[dc.cat] = [...categories[dc.cat], dc];
      }
      return dc;
    });
    setCatgorizedDcList(categories);
  };

  useEffect(() => {
    if (value === 0) {
      const init = async () => {
        try {
          const res = await pluginHook.commonIntegrationService.getCloudDeviceCollectionDevices(
            selectedDCID,
          );
          if (res.result && res.result.devices && Array.isArray(res.result.devices)) {
            catogorizeDcs(res.result.devices);
          }
        } catch (err) {
          enqueueSnackbar('Failed to fetch devices', { variant: 'error' });
        }
      };
      init();
    }
  }, [value]);

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>{deviceContainerName}</Typography>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label="shelly device container tabs">
            <Tab label="Linked Devices" {...a11yProps(0)} />
            <Tab label="Setting" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            <div className={`${classes.demo1} width-full-fixed`}>
              <div>
                <SearchBox minimal>
                  <TextField
                    style={{
                      width: '100%',
                    }}
                    variant="standard"
                    id="input-with-icon-textfield"
                    placeholder={APP_TEXT.device_container.SEARCH_DC}
                    onKeyUp={(event: any) => {
                      onDcSearch(event.target.value);
                    }}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SearchBox>
              </div>
              <div className="row" style={{ padding: 10, paddingTop: 0 }}>
                {Object.keys(categorizedSearchedDevices).map((key: any) => (
                  <>
                    <Box height={30} />
                    <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                      {key}
                    </Typography>
                    {/* <Divider /> */}
                    <Grid container spacing={2}>
                      {categorizedSearchedDevices[key]
                        && categorizedSearchedDevices[key].map((device, i: number) => (
                          <Grid item md={3} sm={6} xs={12}>
                            <CloudDeviceCard
                              device={device}
                              selectedDCID={selectedDCID}
                              selectedDCName={selectedDCName}
                              selectedProjectID={selectedProjectID}
                              cintType={cintType}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </>
                ))}
              </div>
              <Typography className={classes.padding} />
              <ConfirmDelete
                itemId={selectedDCID}
                open={deleteState}
                title="Confirm to delete?"
                item="device collection"
                itemTextBoxLabel="Device Collection Name"
                name={deviceContainerName}
                setOpen={setDeleteState}
                delete={() => {
                  console.log('delete hub');
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {cintType == CLOUD_INTEGRATIONS.ENODE && (
              <EnodeContainerSetting dcName={deviceContainerName} dcId={selectedDCID} projectId={selectedProjectID} />
            )}
            {cintType == CLOUD_INTEGRATIONS.SHELLY && (
              <ShellyContainerSettings
                dcName={deviceContainerName}
                dcId={selectedDCID}
                projectId={selectedProjectID}
              />
            )}
          </TabPanel>
        </Box>
      </Box>
    </Layout>
  );
};

export default SingleContainerCloudIntegration;
