import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Tag } from "src/plugins/shared/uvitem.config";

const tags: Record<'tags1', Record<string, Tag>> = {
    tags1: {
        percentage: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'percentage',
            description: 'Percentage (0 - 100 %)',
            display_suffix: '%', 
            config: {
              scale: 1,
              decimal_points: 0,
              steps: 1,
              min: 0,
              max: 100,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
}

export default tags;