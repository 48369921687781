import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from 'react';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';
import { isArray } from 'lodash';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { LocationEnergyMonitoringStats } from './atoms/LocationEnergyMonitoringStats';
import { LocationEnergyMonitoringConfig } from './atoms/LocationEnergyMonitoringConfig';
import { EnergyGroup } from '../services/cloud-integration.service';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '2%',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
}));

export const EnergyMonitoring = () => {
  const projectId = useQueryStringFirst('projectId');
  const classes = useStyles();
  const [groups, setGroups] = useState<EnergyGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>(() => {
    const groupId = localStorage.getItem(`__monitoring_group__${projectId}`);
    if (groupId) {
      return groupId;
    }
    return '';
  });
  const [tab, setTab] = useState(selectedGroup ? 0 : 1);
  const init = useCallback(async () => {
    try {
      const res = await pluginHook.commonIntegrationService.listEnergyGroups(projectId);
      if (res && isArray(res.result)) {
        setGroups(res.result);
      }
    } catch (err) {}
  }, [projectId]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (selectedGroup && selectedGroup.length > 0) {
      localStorage.setItem(`__monitoring_group__${projectId}`, selectedGroup);
    }
  }, [selectedGroup]);

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>Energy Monitoring</Typography>
          {tab == 0 && (
            <FormControl size="small" style={{ width: 200 }}>
              <InputLabel>Monitoring Group</InputLabel>
              <Select
                label="Monitoring Group"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                {groups.map((group) => (
                  <MenuItem value={group._id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <Box sx={{ borderBottom: 1, marginBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={(e, val) => setTab(val)} aria-label="basic tabs example">
            <Tab label="Energy Monitoring" value={0} />
            <Tab label="Setting" value={1} />
          </Tabs>
        </Box>
        <Box height={10} />
        {tab == 0 && (!selectedGroup || selectedGroup.length == 0) && (
          <Alert color="warning">Please select a group</Alert>
        )}
        {tab == 0 && selectedGroup && (
          <LocationEnergyMonitoringStats key={selectedGroup} selectedGroup={selectedGroup} allGroups={groups} />
        )}
        {tab == 1 && <LocationEnergyMonitoringConfig />}
      </Box>
    </Layout>
  );
};
