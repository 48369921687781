import UiComponents from './ui';
import { Component, EventManager, iComponent } from '../shared/Plugin';
import { iAirIntegration } from './air.service';
import { DeviceEnergyMonitoringConfigProps } from './ui/DeviceEnergyMonitoringConfig';

export class ReactComponents extends Component implements iComponent {
  private integration: iAirIntegration;

  private uiComponents: UiComponents;

  constructor(arg: EventManager, integration: iAirIntegration) {
    super();
    this.eventManager = arg;
    this.integration = integration;
    this.uiComponents = new UiComponents();
  }

  getDcCreateUi = (rnProps: {
    backup_view: boolean;
    onSuccessCreate?: (type: 'air', created: any) => void;
  }) => this.uiComponents.GetDcCreateComponent(this.integration, rnProps);

  getDeviceEnergyConfigUi = (rnProps: DeviceEnergyMonitoringConfigProps) => this.uiComponents.GetDeviceEnergyMonitoringConfigComponent(this.integration, rnProps);
}
