/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { PROJECT_INIVITATION_ACCEPT_MUTATION, PROJECT_INVITATION_LIST_QUERY } from './graphql';

export const projectInvitationsListGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<any> => {
  const res = await apolloClient.query({
    query: PROJECT_INVITATION_LIST_QUERY,
    fetchPolicy: 'no-cache',
  });
  return res.data;
};

export const projectInvitationAcceptRejectGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  permission_id: string,
  new_status: number,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: PROJECT_INIVITATION_ACCEPT_MUTATION,
    variables: {
      permission_id,
      new_status,
    },
  });
  return res.data;
};
