/* eslint-disable max-len */
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { RootState } from 'src/store/redux/store';

const useIsSysAdmin = () => {
  const auth = useContext(AuthContext);
  useFirebaseConnect(`system_admins/${auth.uid}`);

  const isSystemAdmin = useSelector(
    (store: RootState) => !!(store.firebase.data.system_admins && store.firebase.data.system_admins[auth.uid]),
  );

  return isSystemAdmin;
};

export default useIsSysAdmin;
