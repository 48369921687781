import { useNavigate } from 'react-router';
import { COLORS, CreateList, ListRouts } from '@smartlife/common-ui';
import Layout from 'src/components/layout/Layout';
import { PageHeader } from 'src/components/layout/PageHeader';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ListLayout from 'src/layout/ListLayout';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
});

const CreateNewList = () => {
  const projectId = useQueryStringFirst('projectId');
  const styles = useStyle();
  const navigate = useNavigate();
  return (
    <ListLayout>
      <CreateList onSelectListType={(item) => navigate(`/app/list/${ListRouts.LIST_VIEW_SETTING}?__new=1&__selected_type=${item.type}&projectId=${projectId}`)} />
    </ListLayout>
  );
};

export default CreateNewList;
