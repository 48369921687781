/* eslint-disable import/prefer-default-export */
export const ITEM_DEFAULTS = {
  CAMERA_SERVERS: [{ key: 'streaming_server_1', name: 'Streaming Server 1' }],
  ZONE_MODELS: [
    { display_name: '4x4', value: '4x4' },
    { display_name: '8x8', value: '8x8' },
    { display_name: '16x16', value: '16x16' },
    { display_name: '64x64', value: '64x64' },
  ],
  ALARM_SIZES: [
    { display_name: '8', value: '8' },
    { display_name: '16', value: '16' },
    { display_name: '32', value: '32' },
    { display_name: '64', value: '64' },
  ],
  TV_HDMI_PORTS: [
    { name: 'HDMI 1', value: 'hdmi1' },
    { name: 'HDMI 2', value: 'hdmi2' },
    { name: 'HDMI 3', value: 'hdmi3' },
    { name: 'HDMI 4', value: 'hdmi4' },
  ],
  AC_ONKYO_ZONE_DEFAULTS: {
    soruces: [
      { label: 'BD/DVD', value: '10' },
      { label: 'CBL/SAT', value: '01' },
      { label: 'Game', value: '02' },
      { label: 'STRM Box', value: '11' },
      { label: 'PC', value: '05' },
      { label: 'AUX', value: '03' },
      { label: 'CD', value: '23' },
      { label: 'TV', value: '12' },
      { label: 'Phono', value: '22' },
      { label: 'Tuner - FM', value: '24' },
      { label: 'Net', value: '2B' },
      { label: 'Bluetooth', value: '2E' },
    ],
    zones_all: [
      {
        child_items: {
          freq: 'zone_1_freq',
          mute: 'zone_1_mute',
          power: 'zone_1_pwr',
          source: 'zone_1_src',
          tune: 'zone_1_tune',
          volume: 'zone_1_volume',
        },
        label: 'z1_name',
        value: 1,
      },
      {
        child_items: {
          freq: 'zone_2_freq',
          mute: 'zone_2_mute',
          power: 'zone_2_pwr',
          source: 'zone_2_src',
          tune: 'zone_2_tune',
          volume: 'zone_2_volume',
        },
        label: 'z2_name',
        value: 2,
      },
      {
        child_items: {
          freq: 'zone_3_freq',
          mute: 'zone_3_mute',
          power: 'zone_3_pwr',
          source: 'zone_3_src',
          tune: 'zone_3_tune',
          volume: 'zone_3_volume',
        },
        label: 'z3_name',
        value: 3,
      },
    ],
  },
  IR_DEFAULTS: {
    av_remotes: {
      APPLE_TV: {
        display_name: 'Apple TV',
        shadow_type: 'apple_tv',
        device: 'apple_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_DOWN: 'KEY_DOWN',
              KEY_ENTER: 'KEY_ENTER',
              KEY_LEFT: 'KEY_LEFT',
              KEY_MENU: 'KEY_MENU',
              KEY_PLAY_PAUSE: 'Transport_PLAY_PAUSE',
              KEY_RIGHT: 'KEY_RIGHT',
              KEY_UP: 'KEY_UP',
            },
          },
        },
      },
      ONKYO_BLURAY: {
        display_name: 'Onkyo Blu-Ray',
        device: 'onkyo_bluray',
        shadow_type: 'onkyo_bluray',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              GREEN: 'b',
              KEY_0: '0',
              KEY_1: '1',
              KEY_2: '2',
              KEY_3: '3',
              KEY_4: '4',
              KEY_5: '5',
              KEY_6: '6',
              KEY_7: '7',
              KEY_8: '8',
              KEY_9: '9',
              KEY_A: 'a',
              KEY_AB: 'a_b',
              KEY_ANGLE: 'angle',
              KEY_AUDIO: 'audio',
              KEY_AV: 'no_av_key_found',
              KEY_B: 'b',
              KEY_BLUE: 'd',
              KEY_C: 'c',
              KEY_CLEAR: 'clear',
              KEY_D: 'd',
              KEY_DISPLAY: 'display',
              KEY_DOWN: 'down',
              KEY_ENTER: 'enter',
              KEY_FASTFORWARD: 'ff',
              KEY_HOME: 'Key_HOME',
              KEY_LEFT: 'left',
              KEY_MARKER: 'no_marker_key_found',
              KEY_MODE: 'mode',
              KEY_OPENCLOSE: 'open_close',
              KEY_PAUSE: 'pause',
              KEY_PIP: 'pip',
              KEY_PLAY: 'play',
              KEY_POPUPMENU: 'pop_up_menu',
              KEY_POWER: 'power',
              KEY_POWEROFF: 'power_off',
              KEY_POWERON: 'power_on',
              KEY_RED: 'a',
              KEY_REPEAT: 'repeat',
              KEY_RESOLUTION: 'resolution',
              KEY_RETURN: 'return',
              KEY_REWIND: 'fr',
              KEY_RIGHT: 'right',
              KEY_SEARCH: 'search',
              KEY_SETUP: 'setup',
              KEY_STOP: 'stop',
              KEY_SUBTITLE: 'subtitle',
              KEY_TOPMENU: 'top_menu',
              KEY_UP: 'up',
              KEY_YELLOW: 'c',
              KEY_ZOOM: 'no_zoom_key_found',
            },
          },
          v2: {
            info: { version: '1.2.0', version_key: 'v2' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_A: 'a',
              KEY_AB: 'a_b',
              KEY_ANGLE: 'angle',
              KEY_AUDIO: 'Key_AUDIO',
              KEY_AV: 'Input_SOURCE',
              KEY_B: 'b',
              KEY_BLUE: 'Colour_BLUE',
              KEY_C: 'c',
              KEY_CLEAR: 'clear',
              KEY_D: 'd',
              KEY_DISPLAY: 'Key_DISPLAY',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_ENTER: 'enter',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_HOME: 'Key_HOME',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MARKER: 'no_marker_key_found',
              KEY_MENU: 'Key_MENU',
              KEY_MODE: 'mode',
              KEY_OPENCLOSE: 'Key_OPEN_CLOSE',
              KEY_PAUSE: 'Transport_PAUSE',
              KEY_PIP: 'Key_PIP',
              KEY_PLAY: 'Transport_PLAY',
              KEY_POPUPMENU: 'Key_MENU_POP_UP',
              KEY_POWER: 'Power',
              KEY_POWEROFF: 'Power_OFF',
              KEY_POWERON: 'Power_ON',
              KEY_RED: 'Colour_RED',
              KEY_REPEAT: 'repeat',
              KEY_RESOLUTION: 'resolution',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: 'search',
              KEY_SELECT: 'Cursor_OK',
              KEY_SETUP: 'setup',
              KEY_STOP: 'Transport_STOP',
              KEY_SUBTITLE: 'Key_SUBTITLE',
              KEY_TOPMENU: 'top_menu',
              KEY_UP: 'Cursor_UP',
              KEY_YELLOW: 'Colour_YELLOW',
              KEY_ZOOM: 'no_zoom_key_found',
            },
          },
        },
      },
      PANASONIC_BLURAY: {
        display_name: 'Panasonic Blu-Ray',
        device: 'panasonic_bluray',
        shadow_type: 'onkyo_bluray',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_A: 'a',
              KEY_AB: 'a_b',
              KEY_ANGLE: 'angle',
              KEY_AUDIO: 'Key_AUDIO',
              KEY_AV: 'Input_SOURCE',
              KEY_B: 'b',
              KEY_BLUE: 'Colour_BLUE',
              KEY_C: 'c',
              KEY_CLEAR: 'clear',
              KEY_D: 'd',
              KEY_DISPLAY: 'Key_DISPLAY',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_ENTER: 'enter',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_HOME: 'Key_HOME',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MARKER: 'no_marker_key_found',
              KEY_MENU: 'Key_MENU',
              KEY_MODE: 'mode',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_OPENCLOSE: 'Key_OPEN_CLOSE',
              KEY_PAUSE: 'Transport_PAUSE',
              KEY_PIP: 'Key_PIP',
              KEY_PLAY: 'Transport_PLAY',
              KEY_POPUPMENU: 'Key_MENU_POP_UP',
              KEY_POWER: 'Power',
              KEY_POWEROFF: 'Power_OFF',
              KEY_POWERON: 'Power_ON',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RED: 'Colour_RED',
              KEY_REPEAT: 'repeat',
              KEY_RESOLUTION: 'resolution',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: 'search',
              KEY_SELECT: 'Cursor_OK',
              KEY_SETUP: 'setup',
              KEY_STOP: 'Transport_STOP',
              KEY_SUBTITLE: 'Key_SUBTITLE',
              KEY_TITLE: 'no_title_key_found',
              KEY_TOPMENU: 'top_menu',
              KEY_UP: 'Cursor_UP',
              KEY_YELLOW: 'Colour_YELLOW',
              KEY_ZOOM: 'no_zoom_key_found',
            },
          },
        },
      },
      SKY_TV: {
        display_name: 'Sky TV',
        shadow_type: 'sky_tv',
        device: 'sky_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'KEY_0',
              KEY_1: 'KEY_1',
              KEY_2: 'KEY_2',
              KEY_3: 'KEY_3',
              KEY_4: 'KEY_4',
              KEY_5: 'KEY_5',
              KEY_6: 'KEY_6',
              KEY_7: 'KEY_7',
              KEY_8: 'KEY_8',
              KEY_9: 'KEY_9',
              KEY_ACTIVE: 'KEY_ACTIVE',
              KEY_BLUE: 'KEY_BLUE',
              KEY_BOX_OFFICE: 'KEY_BOXOFFICE',
              KEY_CHANNELDOWN: 'KEY_CHANNELDOWN',
              KEY_CHANNELUP: 'KEY_CHANNELUP',
              KEY_DOWN: 'KEY_DOWN',
              KEY_FASTFORWARD: 'KEY_FASTFORWARD',
              KEY_GREEN: 'KEY_GREEN',
              KEY_GUIDE: 'KEY_GUIDE',
              KEY_HELP: 'KEY_HELP',
              KEY_INFO: 'KEY_INFO',
              KEY_LEFT: 'KEY_LEFT',
              KEY_MUTE: 'KEY_MUTE',
              KEY_PAUSE: 'KEY_PAUSE',
              KEY_PLANNER: 'KEY_PLANNER',
              KEY_PLAY: 'KEY_PLAY',
              KEY_POWER: 'KEY_POWER',
              KEY_RECORD: 'KEY_RECORD',
              KEY_RED: 'KEY_RED',
              KEY_RETURN: 'KEY_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'KEY_RIGHT',
              KEY_SELECT: 'KEY_SELECT',
              KEY_SETUP: 'KEY_SETUP',
              KEY_SKY: 'KEY_SKY',
              KEY_STOP: 'KEY_STOP',
              KEY_UP: 'KEY_UP',
              KEY_VOLUMEDOWN: 'KEY_VOLUMEDOWN',
              KEY_VOLUMEUP: 'KEY_VOLUMEUP',
              KEY_YELLOW: 'KEY_YELLOW',
            },
          },
        },
      },
      VODAFONE_TV: {
        display_name: 'Vodafone TV',
        shadow_type: 'vodafone_tv',
        device: 'vodafone_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'KEY_0',
              KEY_1: 'KEY_1',
              KEY_2: 'KEY_2',
              KEY_3: 'KEY_3',
              KEY_4: 'KEY_4',
              KEY_5: 'KEY_5',
              KEY_6: 'KEY_6',
              KEY_7: 'KEY_7',
              KEY_8: 'KEY_8',
              KEY_9: 'KEY_9',
              KEY_BACK: 'KEY_BACK',
              KEY_BLUE: 'KEY_BLUE',
              KEY_CHANNELDOWN: 'KEY_CHANNELDOWN',
              KEY_CHANNELUP: 'KEY_CHANNELUP',
              KEY_DOWN: 'KEY_DOWN',
              KEY_EXIT: 'KEY_EXIT',
              KEY_FASTFORWARD: 'KEY_FASTFORWARD',
              KEY_GREEN: 'KEY_GREEN',
              KEY_LEFT: 'KEY_LEFT',
              KEY_MENU: 'KEY_MENU',
              KEY_MUTE: 'KEY_MUTE',
              KEY_NEXT: 'KEY_NEXT',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'KEY_POWER',
              KEY_PREVIOUS: 'KEY_PREVIOUS',
              KEY_RECORD: 'KEY_RECORD',
              KEY_RED: 'KEY_RED',
              KEY_REWIND: 'KEY_REWIND',
              KEY_RIGHT: 'KEY_RIGHT',
              KEY_SEARCH: 'KEY_SEARCH',
              KEY_SELECT: 'KEY_SELECT',
              KEY_SOURCE: 'KEY_SOURCE',
              KEY_UP: 'KEY_UP',
              KEY_VOLUMEDOWN: 'KEY_VOLUMEDOWN',
              KEY_VOLUMEUP: 'KEY_VOLUMEUP',
              KEY_YELLOW: 'KEY_YELLOW',
              VODAFONE_KEY: '',
            },
          },
          v2: {
            info: { version: '1.2.0', version_key: 'v2' },
            key_map: {
              KEY_0: 'KEY_0',
              KEY_1: 'KEY_1',
              KEY_2: 'KEY_2',
              KEY_3: 'KEY_3',
              KEY_4: 'KEY_4',
              KEY_5: 'KEY_5',
              KEY_6: 'KEY_6',
              KEY_7: 'KEY_7',
              KEY_8: 'KEY_8',
              KEY_9: 'KEY_9',
              KEY_BACK: 'KEY_BACK',
              KEY_BLUE: 'KEY_BLUE',
              KEY_CHANNELDOWN: 'KEY_CHANNELDOWN',
              KEY_CHANNELUP: 'KEY_CHANNELUP',
              KEY_CURSOR_OK: 'Cursor_OK',
              KEY_DOWN: 'KEY_DOWN',
              KEY_EXIT: 'KEY_EXIT',
              KEY_FASTFORWARD: 'KEY_FASTFORWARD',
              KEY_GREEN: 'KEY_GREEN',
              KEY_LEFT: 'KEY_LEFT',
              KEY_MENU: 'KEY_MENU',
              KEY_MUTE: 'KEY_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'KEY_POWER',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'Transport_RW',
              KEY_RED: 'KEY_RED',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'KEY_RIGHT',
              KEY_SEARCH: 'KEY_SEARCH',
              KEY_SELECT: 'KEY_SELECT',
              KEY_SOURCE: 'KEY_SOURCE',
              KEY_UP: 'KEY_UP',
              KEY_VOLUMEDOWN: 'KEY_VOLUMEDOWN',
              KEY_VOLUMEUP: 'KEY_VOLUMEUP',
              KEY_YELLOW: 'KEY_YELLOW',
              VODAFONE_KEY: '',
            },
          },
        },
      },
      DISH_TV: {
        display_name: 'Dish TV',
        shadow_type: 'dish_tv',
        device: 'dish_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'KEY_0',
              KEY_1: 'KEY_1',
              KEY_2: 'KEY_2',
              KEY_3: 'KEY_3',
              KEY_4: 'KEY_4',
              KEY_5: 'KEY_5',
              KEY_6: 'KEY_6',
              KEY_7: 'KEY_7',
              KEY_8: 'KEY_8',
              KEY_9: 'KEY_9',
              KEY_BACK: 'KEY_BACK',
              KEY_BLUE: 'KEY_BLUE',
              KEY_CHANNELDOWN: 'KEY_CHANNELDOWN',
              KEY_CHANNELUP: 'KEY_CHANNELUP',
              KEY_DOWN: 'KEY_DOWN',
              KEY_EXIT: 'KEY_EXIT',
              KEY_FASTFORWARD: 'KEY_FASTFORWARD',
              KEY_GREEN: 'KEY_GREEN',
              KEY_LEFT: 'KEY_LEFT',
              KEY_MENU: 'KEY_MENU',
              KEY_MUTE: 'KEY_MUTE',
              KEY_NEXT: 'KEY_NEXT',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'KEY_POWER',
              KEY_PREVIOUS: 'KEY_PREVIOUS',
              KEY_RECORD: 'KEY_RECORD',
              KEY_RED: 'KEY_RED',
              KEY_REWIND: 'KEY_REWIND',
              KEY_RIGHT: 'KEY_RIGHT',
              KEY_SEARCH: 'KEY_SEARCH',
              KEY_SELECT: 'KEY_SELECT',
              KEY_SOURCE: 'KEY_SOURCE',
              KEY_UP: 'KEY_UP',
              KEY_VOLUMEDOWN: 'KEY_VOLUMEDOWN',
              KEY_VOLUMEUP: 'KEY_VOLUMEUP',
              KEY_YELLOW: 'KEY_YELLOW',
              VODAFONE_KEY: '',
            },
          },
          v2: {
            info: { version: '1.2.0', version_key: 'v2' },
            key_map: {
              KEY_0: 'KEY_0',
              KEY_1: 'KEY_1',
              KEY_2: 'KEY_2',
              KEY_3: 'KEY_3',
              KEY_4: 'KEY_4',
              KEY_5: 'KEY_5',
              KEY_6: 'KEY_6',
              KEY_7: 'KEY_7',
              KEY_8: 'KEY_8',
              KEY_9: 'KEY_9',
              KEY_BACK: 'KEY_BACK',
              KEY_BLUE: 'KEY_BLUE',
              KEY_CHANNELDOWN: 'KEY_CHANNELDOWN',
              KEY_CHANNELUP: 'KEY_CHANNELUP',
              KEY_CURSOR_OK: 'Cursor_OK',
              KEY_DOWN: 'KEY_DOWN',
              KEY_EXIT: 'KEY_EXIT',
              KEY_FASTFORWARD: 'KEY_FASTFORWARD',
              KEY_GREEN: 'KEY_GREEN',
              KEY_LEFT: 'KEY_LEFT',
              KEY_MENU: 'KEY_MENU',
              KEY_MUTE: 'KEY_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'KEY_POWER',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'Transport_RW',
              KEY_RED: 'KEY_RED',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'KEY_RIGHT',
              KEY_SEARCH: 'KEY_SEARCH',
              KEY_SELECT: 'KEY_SELECT',
              KEY_SOURCE: 'KEY_SOURCE',
              KEY_UP: 'KEY_UP',
              KEY_VOLUMEDOWN: 'KEY_VOLUMEDOWN',
              KEY_VOLUMEUP: 'KEY_VOLUMEUP',
              KEY_YELLOW: 'KEY_YELLOW',
              VODAFONE_KEY: '',
            },
          },
        },
      },
    },
    tv_remotes: {
      LG_TV: {
        display_name: 'LG TV',
        shadow_type: 'lg_tv',
        device: 'lg_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              Cursor_OK: 'Cursor_OK',
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_AV: 'Input_HDMI_1',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_INPUT_TV: 'Input_TV',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_OFF: 'Power_OFF',
              KEY_POWER_ON: 'Power_ON',
              KEY_RECORD: 'Transport_REC',
              KEY_RED: 'Colour_RED',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SOURCE: 'Input_SOURCE',
              KEY_TRANSPORT_PAUSE: 'Transport_PAUSE',
              KEY_TRANSPORT_PLAY: 'Transport_PLAY',
              KEY_TRANSPORT_STOP: 'Transport_STOP',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
              Key_EXIT: 'Key_EXIT',
              Key_GUIDE: 'Key_GUIDE',
              Key_INFO: 'Key_INFO',
              Key_MENU: 'Key_MENU',
              Key_RETURN: 'Key_RETURN',
              Key_SMART: 'Key_SMART',
            },
          },
          v2: {
            info: { version: '1.2.0', version_key: 'v2' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_AV: 'Input_SOURCE',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_CURSOR_OK: 'Cursor_OK',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_EXIT: 'Key_EXIT',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_GUIDE: 'Key_GUIDE',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_INFO: 'Key_INFO',
              KEY_INPUT_TV: 'Input_TV',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MENU: 'Key_MENU',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_OFF: 'Power_OFF',
              KEY_POWER_ON: 'Power_ON',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'Transport_REC',
              KEY_RED: 'Colour_RED',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SMART: 'Key_SMART',
              KEY_SOURCE: 'Input_SOURCE',
              KEY_TRANSPORT_PAUSE: 'Transport_PAUSE',
              KEY_TRANSPORT_PLAY: 'Transport_PLAY',
              KEY_TRANSPORT_STOP: 'Transport_STOP',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
            },
          },
        },
      },
      PANASONIC_TV: {
        display_name: 'Panasonic TV',
        shadow_type: 'panasonic_tv',
        device: 'panasonic_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_BACK: '',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_EXIT: 'Key_EXIT',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_GUIDE: 'Key_GUIDE',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_HDMI_5: '',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MENU: 'Key_MENU',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_OFF: 'Power_off',
              KEY_POWER_ON: 'Power_on',
              KEY_PREVIOUS: '',
              KEY_RECORD: 'Transport_REC',
              KEY_RED: 'Colour_RED',
              KEY_REWIND: 'recall',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: '',
              KEY_SELECT: 'Key_RETURN',
              KEY_SMART: 'Key_SMART',
              KEY_SOURCE: '',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
            },
          },
          v2: {
            info: { version: '1.2.0', version_key: 'v2' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_AV: 'Input_SOURCE',
              KEY_BACK: '',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_EXIT: 'Key_EXIT',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_GUIDE: 'Key_GUIDE',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_HDMI_5: '',
              KEY_INPUTSOURCE: 'Input_SOURCE',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MENU: 'Key_MENU',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_ON: 'Power_ON',
              KEY_POWER_OFF: 'Power_OFF',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'Transport_REC',
              KEY_RED: 'Colour_RED',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: '',
              KEY_CURSOR_OK: 'Cursor_OK',
              KEY_SMART: 'Key_SMART',
              KEY_SOURCE: 'Input_SOURCE',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
            },
          },
        },
      },
      SAMSUNG_TV: {
        display_name: 'Samsung TV',
        shadow_type: 'samsung_tv',
        device: 'samsung_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_AV: 'Input_SOURCE',
              KEY_BACK: '',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_EXIT: 'Key_EXIT',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_GUIDE: 'Key_GUIDE',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_HDMI_5: '',
              KEY_INPUTSOURCE: 'Input_SOURCE',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MENU: 'Key_MENU',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_ON: 'Power_ON',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'KEY_RECORD',
              KEY_RED: 'Colour_RED',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: '',
              KEY_SELECT: 'Key_RETURN',
              KEY_SMART: 'Key_SMART',
              KEY_SOURCE: 'Input_SOURCE',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
            },
          },
          v2: {
            info: { version: '1.2.0', version_key: 'v2' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_AV: 'Input_SOURCE',
              KEY_BACK: '',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_EXIT: 'Key_EXIT',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_GUIDE: 'Key_GUIDE',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_HDMI_5: '',
              KEY_INPUTSOURCE: 'Input_SOURCE',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MENU: 'Key_MENU',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_OFF: 'Power_OFF',
              KEY_POWER_ON: 'Power_ON',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'KEY_RECORD',
              KEY_RED: 'Colour_RED',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: '',
              KEY_CURSOR_OK: 'Cursor_OK',
              KEY_SMART: 'Key_SMART',
              KEY_SOURCE: 'Input_SOURCE',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
            },
          },
        },
      },
      SONY_TV: {
        display_name: 'Sony TV',
        shadow_type: 'sony_tv',
        device: 'sony_tv',
        versions: {
          v1: {
            info: { version: '1.0.0', version_key: 'v1' },
            key_map: {
              KEY_0: 'Number_0',
              KEY_1: 'Number_1',
              KEY_2: 'Number_2',
              KEY_3: 'Number_3',
              KEY_4: 'Number_4',
              KEY_5: 'Number_5',
              KEY_6: 'Number_6',
              KEY_7: 'Number_7',
              KEY_8: 'Number_8',
              KEY_9: 'Number_9',
              KEY_AV: 'Input_SOURCE',
              KEY_BACK: '',
              KEY_BLUE: 'Colour_BLUE',
              KEY_CHANNELDOWN: 'Channel_DOWN',
              KEY_CHANNELUP: 'Channel_UP',
              KEY_DOWN: 'Cursor_DOWN',
              KEY_EXIT: 'Key_EXIT',
              KEY_FASTFORWARD: 'Transport_FF',
              KEY_GREEN: 'Colour_GREEN',
              KEY_GUIDE: 'Key_GUIDE',
              KEY_HDMI_1: 'Input_HDMI_1',
              KEY_HDMI_2: 'Input_HDMI_2',
              KEY_HDMI_3: 'Input_HDMI_3',
              KEY_HDMI_4: 'Input_HDMI_4',
              KEY_HDMI_5: '',
              KEY_INPUTSOURCE: 'Input_SOURCE',
              KEY_LEFT: 'Cursor_LEFT',
              KEY_MENU: 'Key_MENU',
              KEY_MUTE: 'Volume_MUTE',
              KEY_NEXT: 'Transport_SKIPFWD',
              KEY_PLAYPAUSE: 'Transport_PLAY_PAUSE',
              KEY_POWER: 'Power',
              KEY_POWER_OFF: 'Power_OFF',
              KEY_POWER_ON: 'Power_ON',
              KEY_PREVIOUS: 'Transport_SKIPBACK',
              KEY_RECORD: 'KEY_RECORD',
              KEY_RED: 'Colour_RED',
              KEY_RETURN: 'Key_RETURN',
              KEY_REWIND: 'Transport_RW',
              KEY_RIGHT: 'Cursor_RIGHT',
              KEY_SEARCH: '',
              KEY_CURSOR_OK: 'Cursor_OK',
              KEY_SMART: 'Key_SMART',
              KEY_SOURCE: 'Input_SOURCE',
              KEY_UP: 'Cursor_UP',
              KEY_VOLUMEDOWN: 'Volume_DOWN',
              KEY_VOLUMEUP: 'Volume_UP',
              KEY_YELLOW: 'Colour_YELLOW',
            },
          },
        },
      },
    },
  },
  ITEM_CREATOR: {
    HUB_APIS: [
      {
        name: 'Node-RED', ip: '127.0.0.1', port: '1880', remark: 'nodered',
      },
      {
        name: 'Hub setup tool', ip: '127.0.0.1', port: '8000', remark: 'hubsetup',
      },
      {
        name: 'OpenHAB', ip: '127.0.0.1', port: '8080', remark: 'openhab',
      },
      {
        name: 'Custom tunnel', ip: '', port: '', remark: 'custom1',
      },
    ],
    AIR_DEVICES: {
      enabled_tuya_category: [],
      configs: {
        switch: {
          title: 'Switch',
          features: {
            tags: ['AirDevice', 'ToggleButton'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AirSwitch'] },
          },
        },
        press_button: {
          title: 'Press Button',
          features: {
            tags: ['AirDevice', 'PressButton'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AirPressButton'] },
          },
        },
        dimmer: {
          title: 'Dimmer',
          features: {
            tags: ['AirDevice', 'Slider'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AirSlider'] },
          },
        },
        rgb_light: {
          title: 'RGB Light',
          features: {
            tags: ['AirDevice', 'RgbLight'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AirRGBLight'] },
          },
        },
        sensor: {
          title: 'Sensor',
          features: {
            tags: ['AirDevice', 'Sensor'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AirSensor'] },
          },
        },
        door_lock: {
          title: 'Door Lock',
          features: {
            tags: ['AirDevice', 'DoorLock'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AirDoorLock'] },
            sub_types: ['WIFI', 'Zigbee', 'Bluetooth'],
          },
        },
        ac: {
          title: 'Air Conditioner',
          features: {
            tags: ['AirDevice', 'AirConditioner'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AcController'] },
            sub_types: ['infrared'],
            fields: [
              {
                type: 'state_checkbox',
                field: 'show_power',
                editable: true,
                default: false,
                labels: 'Power button ON/OFF',
                extra: 'switch',
              },
            ],
          },
        },
        roller_blinds: {
          title: 'Roller Blinds',
          features: {
            tags: ['AirDevice', 'RollerBlinds'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'RollerBlinds'] },
            sub_types: ['WIFI'],
          },
          fields: [
            {
              type: 'state_checkbox',
              field: 'show_state',
              editable: true,
              default: false,
              labels: 'Show state',
              extra: '',
            },
          ],
        },
        door_control: {
          title: 'Door Control',
          features: {
            tags: ['AirDevice', 'DoorControl'],
            state_weight: 1,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'DoorControl'] },
            sub_types: ['WIFI'],
          },
        },
        camera: {
          title: 'AIR Camera',
          features: {
            tags: ['AirDevice', 'Camera', 'AIRCamera'],
            state_weight: 0,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AIRCamera'] },
            custom_field_visibility: true,
          },
        },
        fan: {
          title: 'Fan',
          features: {
            tags: ['AirDevice', 'FAB', 'AIRFan'],
            state_weight: 0,
            shadow_type: 'airDevice',
            device_extra_fields: { system_tags: ['AirDevice', 'AIRFan'] },
            custom_field_visibility: true,
          },
        },
      },
    },
    AIR_DEVICE_FIELDS_CAN_REMOVE: ['all'],
    AIR_DEVICE_FIELDS_CODE_IGNORE_STARTWITH: ['countdown'],
    AIR_UV_DEVICE_MAP: {
      default_uv_type: 'switch',
      uv_types: {
        switch: {
          title: 'Switch',
          channels: { switch: true },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-light1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        press_button: {
          title: 'Press Button',
          channels: { switch: true },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-light1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        dimmer: {
          title: 'Dimmer',
          channels: { bright_value: true, switch: true },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-light1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        rgb_light: {
          title: 'RGB Light',
          channels: { bright_value: true, color: true, switch: true },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-light1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        sensor: {
          title: 'Sensor',
          channels: {
            sensor_value: { types: ['boolean', 'numeric', 'map', 'other'], scale_defalut: 10 },
          },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-light1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        door_lock: {
          title: 'Door Lock',
          channels: {},
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-light1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        ac: {
          title: 'Air Conditioner',
          channels: {
            switch: { type: 'input', default_channel: 'switch', data_type: 'boolean' },
            mode: {
              type: 'input',
              enum: ['dehumidification', 'cold', 'auto', 'wind_dry', 'heat'],
              default_channel: 'mode',
              data_type: 'enum',
            },
            temp: { type: 'input', default_channel: 'temp', data_type: 'integer' },
            fan: {
              type: 'input',
              enum: ['low', 'mid', 'high', 'auto'],
              default_channel: 'fan',
              data_type: 'enum',
            },
            power: { type: 'sensor', default_channel: 'power', data_type: 'boolean' },
            wind: { type: 'sensor', default_channel: 'wind', data_type: 'integer' },
          },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-ac1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        roller_blinds: {
          title: 'Roller Blinds',
          channels: {
            control: {
              type: 'input',
              enum: ['open', 'stop', 'close'],
              default_channel: 'control',
              data_type: 'enum',
            },
            percent_control: {
              type: 'input',
              default_channel: 'percent_control',
              data_type: 'integer',
            },
            percent_state: { type: 'sensor', default_channel: 'percent_state', data_type: 'integer' },
          },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-door4' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        door_control: {
          title: 'Door Control',
          channels: { switch: { type: 'input', default_channel: 'switch_1', data_type: 'boolean' } },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-rollergate1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
        camera: {
          title: 'AIR Camera',
          channels: {},
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-rollergate1' },
              { type: 'item_notification_type', default: '3' },
              {
                type: 'control_buttons_selector2',
                default: '',
                extra:
                  'contact_closure,press_and_hold,toggle,thirdprty_contact_closure,thirdprty_press_and_hold,thirdprty_toggle,switch,press_button,sensor',
              },
              { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
            ],
          },
        },
        fan: {
          title: 'Fan',
          channels: {
            switch: { type: 'input', default_channel: 'switch', data_type: 'boolean' },
            mode: {
              type: 'input',
              default_channel: 'mode',
              data_type: 'enum',
              enum: ['nature', 'sleep', 'fresh', 'smart'],
            },
            fan: {
              type: 'input',
              default_channel: 'fan_speed',
              data_type: 'enum',
              enum: ['1', '2', '3', '4', '5'],
            },
          },
          uv_settings: {
            fields: [
              { type: 'rule_name', default: '' },
              { type: 'macro_name', default: '' },
              { type: 'icon_name', default: 'calc-rollergate1' },
              { type: 'item_notification_type', default: '3' },
            ],
          },
        },
      },
    },
    UV_OTHER_ITEMS: {
      title: 'Other Item',
      items: {
        integrated_camera: {
          title: 'Integrated Camera',
          item_type: 'integrated_camera',
          icon: 'calc-camera2',
          features: {
            uv_settings: {
              fields: [
                { type: 'rule_name', default: '' },
                { type: 'macro_name', default: '' },
                { type: 'icon_name', default: 'calc-camera2' },
                { type: 'camera_selector', default: '', extra: 'Camera,AIRCamera' },
                {
                  type: 'control_buttons_selector2',
                  default: '',
                  extra:
                    'contact_closure,press_and_hold,toggle,thirdprty_contact_closure,thirdprty_press_and_hold,thirdprty_toggle,switch,press_button,sensor',
                },
                { type: 'alarm_devices', default: '', extra: 'ArrowHead' },
                { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
              ],
            },
          },
        },
        integrated_audio: {
          title: 'Integrated Audio',
          item_type: 'integrated_audio',
          icon: 'calc-hifi1',
          features: {
            uv_settings: {
              fields: [
                { type: 'description', default: '' },
                { type: 'rule_name', default: '' },
                { type: 'macro_name', default: '' },
                { type: 'icon_name', default: 'calc-hifi1' },
                { type: 'item_notification_type', default: '3' },
                { type: 'audio_devices', default: '', extra: 'Au1' },
                { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
              ],
            },
          },
        },
        media_device: {
          title: 'Media Device',
          item_type: 'media_device',
          icon: ' calc-apple-tv6',
          features: {
            uv_settings: {
              fields: [
                { type: 'description', default: '' },
                { type: 'rule_name', default: '' },
                { type: 'macro_name', default: '' },
                { type: 'icon_name', default: 'calc-hifi1' },
                { type: 'item_notification_type', default: '3' },
                { type: 'audio_selector', extra: 'Au1,Au2' },
                { type: 'video_display_selector', extra: 'V1' },
                {
                  type: 'control_buttons_selector2',
                  default: '',
                  extra:
                    'press_and_hold,toggle,thirdprty_contact_closure,thirdprty_press_and_hold,thirdprty_toggle,switch,press_button',
                },
                { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
              ],
            },
          },
        },
        app_browser_item: {
          title: 'App Browser Item',
          item_type: 'app_browser_item',
          icon: 'calc-itemfolder',
          features: {
            uv_settings: {
              fields: [
                { type: 'icon_name', default: 'calc-itemfolder' },
                { type: 'app_browser_item', field: 'app_browser_item', labels: 'App browser item' },
              ],
            },
          },
        },
        logic_item: {
          title: 'Logic Item',
          item_type: 'logic_item',
          icon: 'calc-forward',
          features: {
            uv_settings: {
              fields: [
                { type: 'icon_name', default: 'calc-forward' },
                { type: 'macro_name', default: '' },
              ],
              macro_selector_single: true,
            },
          },
        },
        list_view_v_01: {
          title: 'Smart Lists',
          item_type: 'list_view_v_01',
          icon: 'calc-boardroom4',
          features: {
            uv_settings: {
              fields: [
                { type: 'icon_name', default: 'calc-boardroom4' },
                { type: 'smartlists', field: 'smartlists' },
              ],
            },
          },
        },
      },
    },
    UV_ITEM_FIELDS: {
      rule_name: 'rule_name',
      macro_name: 'macro_name',
      icon_name: 'icon_name',
      item_notification_type: 'item_notification_type',
      audio_selector: 'audio_selector',
      video_display_selector: 'video_display_selector',
      control_buttons_selector: 'control_buttons_selector',
      control_buttons_selector2: 'control_buttons_selector2',
      camera_selector: 'camera_selector',
      uv_item_selector: 'uv_item_selector',
      app_browser_item: 'app_browser_item',
      custom_validator1: 'custom_validator1',
      audio_devices: 'audio_devices',
      alarm_devices: 'alarm_devices',
      zone_selector: 'zone_selector',
      description: 'description',
      child_notification_item_selector: 'child_notification_item_selector',
      smartlists: 'smartlists',
    },
    DEVICE_FIELDS: {
      debounce: 'debounce',
      display_labels: 'display_labels',
      display_labels_view_type: 'display_labels_view_type',
      field_selector: 'field_selector',
      interval: 'interval',
      timeout: 'timeout',
      pin_common: 'pin_common',
      pin_unique: 'pin_unique',
      relay_value: 'relay_value',
      zones: 'zones',
      zs_modal_selector: 'zs_modal_selector',
      ah_size_selector: 'ah_size_selector',
      ir_remote: 'ir_remote',
      ip_address: 'ip_address',
      network_port: 'network_port',
      sound_volume: 'sound_volume',
      sound_volume_step: 'sound_volume_step',
      custom_selector: 'custom_selector',
      state_checkbox: 'state_checkbox',
      stream_server: 'stream_server',
      text_input: 'text_input',
      text_input_feature: 'text_input_feature',
      json_field: 'json_field',
      location_variable: 'location_variable',
      timeout_interval: 'timeout_interval',
    },
    item_info_flows: {
      gpio_items: {
        title: 'GPIO Items',
        value: 'gpio_items',
        children: {
          contact_closure: {
            title: 'Contact Closure',
            value: 'contact_closure',
            icon: 'calc-change-password',
            features: {
              state_weight: 3,
              shadow_type: 'contact_closure',
              tags: ['Input'],
              device_extra_fields: { system_tags: ['ProDevice', 'Pincc'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-motionsensor2' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'display_labels',
                  field: 'display_labels',
                  editable: true,
                  default: ['TEXT ON', 'TEXT OFF'],
                  labels: 'Display labels',
                  extra: '',
                },
                {
                  type: 'display_labels_view_type',
                  field: 'display_labels_view_type',
                  editable: true,
                  default: 'input_status',
                  labels: 'Display labels view type',
                  extra: '',
                },
                {
                  type: 'pin_unique',
                  field: 'pin',
                  editable: true,
                  default: '',
                  labels: 'Pin',
                  extra: 'contact_closure',
                },
              ],
            },
          },
          press_and_hold: {
            title: 'Press Button',
            value: 'press_and_hold',
            icon: 'calc-next',
            features: {
              state_weight: 3,
              shadow_type: 'relay',
              tags: ['Press Button'],
              device_extra_fields: { system_tags: ['ProDevice', 'PressButton', 'Pinrelay'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-light1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'display_labels',
                  field: 'display_labels',
                  editable: true,
                  default: ['', ''],
                  labels: 'Display labels',
                  extra: '',
                },
                {
                  type: 'pin_unique',
                  field: 'pin',
                  editable: true,
                  default: '',
                  labels: 'Pin',
                  extra: 'relay',
                },
                {
                  type: 'relay_value',
                  field: 'relay_values',
                  editable: true,
                  default: [1, 0],
                  labels: 'Relay Values',
                  extra: '',
                },
                {
                  type: 'timeout_interval',
                  field: 'timeout|interval',
                  editable: true,
                  default: 'timeout:1000',
                  labels: 'Timeout Duration|Pulse Duration',
                  extra: '',
                },
              ],
            },
          },
          toggle: {
            title: 'Toggle Button',
            value: 'toggle',
            icon: 'calc-switch8',
            features: {
              state_weight: 3,
              shadow_type: 'relay',
              tags: ['Toggle'],
              device_extra_fields: { system_tags: ['Pinrelay', 'ProDevice', 'ToggleButton'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-switch8' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'display_labels',
                  field: 'display_labels',
                  editable: true,
                  default: ['TEXT ON', 'TEXT OFF'],
                  labels: 'Display labels',
                  extra: '',
                },
                {
                  type: 'pin_unique',
                  field: 'pin',
                  editable: true,
                  default: '',
                  labels: 'Pin',
                  extra: 'relay',
                },
                {
                  type: 'relay_value',
                  field: 'relay_values',
                  editable: true,
                  default: [1, 0],
                  labels: 'Relay Values',
                  extra: '',
                },
              ],
            },
          },
        },
      },
      location_variables: {
        title: 'Location Variables',
        value: 'location_variables',
        children: {
          numeric_variable: {
            title: 'Numeric Variable',
            value: 'numeric_variable',
            icon: 'calc-info',
            features: {
              state_weight: 1,
              shadow_type: 'numeric_variable',
              tags: [],
              device_extra_fields: { system_tags: ['LocVariable', 'locvarN'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-info' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'location_variable',
                  field: 'state',
                  editable: true,
                  default: 0,
                  labels: 'Initial Value',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'enable_editing',
                  editable: true,
                  default: true,
                  labels: 'Value editing',
                  extra: 'switch',
                },
              ],
            },
          },
          string_variable: {
            title: 'String Variable',
            value: 'string_variable',
            icon: 'calc-info',
            features: {
              state_weight: 1,
              shadow_type: 'string_variable',
              tags: [],
              device_extra_fields: { system_tags: ['LocVariable', 'locvarS'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-info' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'location_variable',
                  field: 'state',
                  editable: true,
                  default: 'ON',
                  labels: 'Initial Value',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'enable_editing',
                  editable: true,
                  default: true,
                  labels: 'Value editing',
                  extra: 'switch',
                },
              ],
            },
          },
          boolean_variable: {
            title: 'Boolean Variable',
            value: 'boolean_variable',
            icon: 'calc-info',
            features: {
              state_weight: 1,
              shadow_type: 'boolean_variable',
              tags: [],
              device_extra_fields: { system_tags: ['LocVariable', 'locvarB'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-info' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'custom_selector',
                  field: 'display_type:label:toggle',
                  editable: true,
                  default: 'label',
                  labels: 'Item Type',
                  extra: 'Label|Toggle',
                },
                {
                  type: 'location_variable',
                  field: 'state',
                  editable: true,
                  default: true,
                  labels: 'Initial Value',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'enable_editing',
                  editable: true,
                  default: true,
                  labels: 'Value editing',
                  extra: 'switch',
                },
              ],
            },
          },
        },
      },
      ir: {
        title: 'IR Remotes',
        value: 'ir',
        children: {
          av_remotes: {
            title: 'A/V Remotes',
            value: 'av_remotes',
            icon: 'calc-tvroom5',
            features: {
              state_weight: 1,
              shadow_type: 'ir',
              tags: [],
              device_extra_fields: { system_tags: ['A', 'IrRemote'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'item_notification_type', default: '3' },
                  { type: 'audio_selector', extra: 'Au1,Au2' },
                  { type: 'video_display_selector', extra: 'V1' },
                ],
              },
              fields: [
                {
                  type: 'pin_common',
                  field: 'pin',
                  editable: true,
                  default: '',
                  labels: 'pin value',
                  extra: 'ir',
                },
                {
                  type: 'ir_remote',
                  field: '-',
                  editable: true,
                  default: {},
                  labels: 'IR Remote',
                  extra: '',
                },
              ],
            },
          },
          tv_remotes: {
            title: 'TV Remotes',
            value: 'tv_remotes',
            icon: 'calc-tvroom5',
            features: {
              state_weight: 1,
              shadow_type: 'ir',
              tags: [],
              device_extra_fields: { system_tags: ['V1', 'Au2', 'A', 'IrRemote'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'item_notification_type', default: '3' },
                  { type: 'audio_selector', extra: 'Au1' },
                ],
              },
              fields: [
                {
                  type: 'pin_common',
                  field: 'pin',
                  editable: true,
                  default: '',
                  labels: 'pin value',
                  extra: 'ir',
                },
                {
                  type: 'ir_remote',
                  field: '-',
                  editable: true,
                  default: {},
                  labels: 'IR  Remote',
                  extra: '',
                },
              ],
            },
          },
        },
      },
      ac: {
        title: 'Audio/Video Controllers',
        value: 'ac',
        children: {
          hifi: {
            title: 'Audio Distribution',
            value: 'hifi',
            icon: 'calc-hifi2',
            features: {
              state_weight: 10,
              shadow_type: 'tcp',
              tags: [],
              device_extra_fields: {
                system_tags: ['Au1', 'HifiTcp', 'tcp'],
                commands: {
                  zone: ['volume', 'mute', 'pwr', 'src'],
                  commands_fields: ['index', 'parent', 'type', 'name', 'label', 'timestamp'],
                  extra: {},
                },
              },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'ip',
                  editable: true,
                  default: '192.168.1.1',
                  labels: 'Ip Address',
                  extra: '',
                },
                {
                  type: 'network_port',
                  field: 'port',
                  editable: true,
                  default: '20245',
                  labels: 'Network port',
                  extra: '',
                },
                {
                  type: 'sound_volume_step',
                  field: 'volume_step',
                  editable: true,
                  default: 1,
                  labels: 'Volume step',
                  extra: '',
                },
                {
                  type: 'sound_volume',
                  field: 'volume_',
                  editable: true,
                  default: { volume_max: 100, volume_min: 0 },
                  labels: 'Maximum Volume|Minimum Volume',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'device:axium:hifi:custom-value',
                  editable: true,
                  default: 'axium',
                  labels: 'Select Device',
                  extra: 'Axium|HIFI|Custom',
                },
                {
                  type: 'zs_modal_selector',
                  field: 'modal',
                  editable: true,
                  default: '8x8',
                  labels: 'matrix modal',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'zones',
                  editable: true,
                  default: [],
                  labels: 'Zone',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'sources',
                  editable: true,
                  default: [],
                  labels: 'Sources',
                  extra: '',
                },
              ],
            },
          },
          onkyo: {
            title: 'A/V Receiver',
            value: 'onkyo',
            icon: 'calc-hifi1',
            features: {
              state_weight: 10,
              shadow_type: 'tcp',
              tags: [],
              device_extra_fields: {
                system_tags: ['Au1', 'OnkyoTcp', 'tcp'],
                commands: {
                  zone: ['volume', 'mute', 'pwr', 'src', 'tune', 'freq'],
                  commands_fields: ['index', 'parent', 'type', 'name', 'label', 'timestamp'],
                  extra: {
                    commands: [
                      {
                        index: 99,
                        parent: 'DeviceIotId',
                        type: 'String',
                        name: 'remote_command_DeviceIotId',
                        label: 'Remote command',
                        timestamp: 0,
                      },
                    ],
                  },
                },
              },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'ip',
                  editable: true,
                  default: '192.168.1.1',
                  labels: 'Ip Address',
                  extra: '',
                },
                {
                  type: 'network_port',
                  field: 'port',
                  editable: true,
                  default: '20245',
                  labels: 'Network port',
                  extra: '',
                },
                {
                  type: 'sound_volume',
                  field: 'volume_',
                  editable: true,
                  default: { volume_max: 100, volume_min: 0 },
                  labels: 'Maximum Volume|Minimum Volume',
                  extra: '',
                },
                {
                  type: 'sound_volume_step',
                  field: 'volume_step',
                  editable: true,
                  default: 1,
                  labels: 'Volume step',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'device:onkyo:marantz:yamaha:custom-value',
                  editable: true,
                  default: 'onkyo',
                  labels: 'Select Device',
                  extra: 'Onkyo|Marantz|Yamaha|Custom',
                },
                {
                  type: 'custom_selector',
                  field: 'modal:3x12',
                  editable: true,
                  default: '3x12',
                  labels: 'Select Modal',
                  extra: '3x12',
                },
                {
                  type: 'zones',
                  field: 'zones',
                  editable: true,
                  default: [
                    {
                      child_items: {
                        freq: 'zone_1_freq',
                        mute: 'zone_1_mute',
                        power: 'zone_1_pwr',
                        source: 'zone_1_src',
                        tune: 'zone_1_tune',
                        volume: 'zone_1_volume',
                      },
                      label: 'z1_name',
                      value: 1,
                    },
                    {
                      child_items: {
                        freq: 'zone_2_freq',
                        mute: 'zone_2_mute',
                        power: 'zone_2_pwr',
                        source: 'zone_2_src',
                        tune: 'zone_2_tune',
                        volume: 'zone_2_volume',
                      },
                      label: 'z2_name',
                      value: 2,
                    },
                    {
                      child_items: {
                        freq: 'zone_3_freq',
                        mute: 'zone_3_mute',
                        power: 'zone_3_pwr',
                        source: 'zone_3_src',
                        tune: 'zone_3_tune',
                        volume: 'zone_3_volume',
                      },
                      label: 'z3_name',
                      value: 3,
                    },
                  ],
                  labels: 'Zone',
                  extra: 'custom-values',
                },
                {
                  type: 'zones',
                  field: 'sources',
                  editable: true,
                  default: [
                    { label: 'BD/DVD', value: '10' },
                    { label: 'CBL/SAT', value: '01' },
                    { label: 'Game', value: '02' },
                    { label: 'STRM Box', value: '11' },
                    { label: 'PC', value: '05' },
                    { label: 'AUX', value: '03' },
                    { label: 'CD', value: '23' },
                    { label: 'TV', value: '12' },
                    { label: 'Phono', value: '22' },
                    { label: 'Tuner - FM', value: '24' },
                    { label: 'Net', value: '2B' },
                    { label: 'Bluetooth', value: '2E' },
                  ],
                  labels: 'Sources',
                  extra: 'custom-values_normal,tuner',
                },
              ],
            },
          },
          matrix: {
            title: 'Video Distribution',
            value: 'matrix',
            icon: 'calc-hifi1',
            features: {
              state_weight: 10,
              shadow_type: 'tcp',
              tags: [],
              device_extra_fields: {
                system_tags: ['V1', 'MatrixTcp', 'tcp'],
                commands: {
                  zone: ['src'],
                  commands_fields: ['index', 'parent', 'type', 'name', 'label', 'timestamp'],
                  extra: { commands: [] },
                },
              },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'ip',
                  editable: true,
                  default: '192.168.1.1',
                  labels: 'Ip Address',
                  extra: '',
                },
                {
                  type: 'network_port',
                  field: 'port',
                  editable: true,
                  default: '20245',
                  labels: 'Network port',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'device:avg_matrix:hda_matrix:custom-value',
                  editable: true,
                  default: 'avg_matrix',
                  labels: 'Select Device',
                  extra: 'AVG|HDA|Custom',
                },
                {
                  type: 'zs_modal_selector',
                  field: 'modal',
                  editable: true,
                  default: '8x8',
                  labels: 'Matrix modal',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'zones',
                  editable: true,
                  default: [],
                  labels: 'Zone',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'sources',
                  editable: true,
                  default: [],
                  labels: 'Sources',
                  extra: '',
                },
              ],
            },
          },
        },
      },
      third_items: {
        title: '3rd party devices',
        value: 'third_items',
        children: {
          thirdprty_contact_closure: {
            title: 'Input',
            value: 'thirdprty_contact_closure',
            icon: 'calc-tvroom5',
            features: {
              shadow_type: 'contact_closure',
              tags: ['Input', 'ProDevice'],
              state_weight: 3,
              device_extra_fields: { system_tags: ['ProDevice', 'Input', 'OpenHubDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-light1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'display_labels_view_type',
                  field: 'display_labels_view_type',
                  editable: true,
                  default: 'input_status',
                  labels: 'Display labels view type',
                  extra: '',
                },
                {
                  type: 'display_labels',
                  field: 'display_labels',
                  editable: true,
                  default: ['TEXT ON', 'TEXT OFF'],
                  labels: 'Display labels',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'openhab_item_type:String:Number',
                  editable: true,
                  default: 'String',
                  labels: 'Item Type',
                  extra: 'String|Number',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_state',
                  editable: true,
                  default: false,
                  labels: 'Show state',
                  extra: '',
                },
              ],
            },
          },
          thirdprty_press_and_hold: {
            title: 'Press Button',
            value: 'thirdprty_press_and_hold',
            icon: 'calc-tvroom5',
            features: {
              state_weight: 3,
              shadow_type: 'press_and_hold',
              tags: ['Press Button'],
              device_extra_fields: { system_tags: ['ProDevice', 'PressButton', 'OpenHubDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-light1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'openhab_item_type:Switch',
                  editable: true,
                  default: 'Switch',
                  labels: 'Item Type',
                  extra: 'Switch',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_state',
                  editable: true,
                  default: false,
                  labels: 'Show state',
                  extra: '',
                },
              ],
            },
          },
          thirdprty_toggle: {
            title: 'Toggle Button',
            value: 'thirdprty_toggle',
            icon: 'calc-switch8',
            features: {
              state_weight: 3,
              shadow_type: 'toggle',
              oh_type: 'Switch',
              tags: ['Toggle'],
              device_extra_fields: { system_tags: ['ProDevice', 'ToggleButton', 'OpenHubDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-switch8' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'openhab_item_type:Switch',
                  editable: true,
                  default: 'Switch',
                  labels: 'Item Type',
                  extra: 'Switch',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_state',
                  editable: true,
                  default: false,
                  labels: 'Show state',
                  extra: '',
                },
              ],
            },
          },
          thirdprty_slider: {
            title: 'Slider',
            value: 'thirdprty_slider',
            icon: 'calc-switch8',
            features: {
              state_weight: 4,
              shadow_type: 'slider',
              tags: ['Slider'],
              device_extra_fields: { system_tags: ['ProDevice', 'Slider', 'OpenHubDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-light1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'openhab_item_type:Number:String:Dimmer',
                  editable: true,
                  default: 'String',
                  labels: 'Item Type',
                  extra: 'Number|String|Dimmer',
                },
                {
                  type: 'custom_selector',
                  field: 'position:vertical:horizontal',
                  editable: true,
                  default: 'vertical',
                  labels: 'Position',
                  extra: 'Vertical|Horizontal',
                },
                {
                  type: 'sound_volume',
                  field: 'volume_',
                  editable: true,
                  default: { volume_max: 100, volume_min: 0 },
                  labels: 'Maximum Value|Minimum Value',
                  extra: '',
                },
                {
                  type: 'sound_volume_step',
                  field: 'volume_step',
                  editable: true,
                  default: 2,
                  labels: 'Value step',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_controls',
                  editable: true,
                  default: true,
                  labels: 'Show controls',
                  extra: 'switch',
                },
              ],
            },
          },
          thirdprty_ac_controller: {
            title: 'AC Controller',
            value: 'thirdprty_ac_controller',
            icon: 'calc-ac2',
            features: {
              state_weight: 0,
              shadow_type: 'ac_controller',
              tags: ['AcController'],
              device_extra_fields: { system_tags: ['ProDevice', 'AcController', 'OpenHubDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-ac1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'state_checkbox',
                  field: 'show_power',
                  editable: true,
                  default: false,
                  labels: 'Power button ON/OFF',
                  extra: 'switch',
                },
              ],
            },
          },
        },
      },
      camera: {
        title: 'Camera',
        value: 'camera',
        children: {
          camera: {
            title: 'Camera',
            value: 'camera',
            icon: 'calc-camera2',
            features: {
              state_weight: 1,
              shadow_type: '-',
              tags: [],
              device_extra_fields: { system_tags: ['Camera', 'B'] },
              fields: [
                {
                  type: 'stream_server',
                  field: 'stream_server',
                  editable: true,
                  default: { stream_type: 'local', url: '', streaming_server: 'streaming_server_1' },
                  labels: '',
                  extra: '',
                },
              ],
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-camera2' },
                  {
                    type: 'control_buttons_selector2',
                    default: '',
                    extra:
                      'contact_closure,press_and_hold,toggle,thirdprty_contact_closure,thirdprty_press_and_hold,thirdprty_toggle,switch,press_button,sensor',
                  },
                  { type: 'alarm_devices', default: '', extra: 'ArrowHead' },
                  { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
                ],
              },
            },
          },
        },
      },
      alarm: {
        title: 'Alarm',
        value: 'alarm',
        children: {
          aapesx1a: {
            title: 'Alarm AAP ESX 1',
            value: 'aapesx1a',
            icon: 'calc-bell2-active',
            features: {
              state_weight: 10,
              shadow_type: 'tcp',
              tags: [],
              device_extra_fields: {
                system_tags: ['ArrowHead', 'tcp', 'Alarm'],
                commands: {
                  area: [
                    'alarm',
                    'alarm_zone',
                    'status_last_armed_by_user',
                    'last_disarmed_by_user',
                    'status_readiness',
                  ],
                  commands_fields: ['index', 'parent', 'type', 'name', 'label', 'timestamp'],
                  extra: {},
                },
              },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'camera_selector', default: '', extra: 'Camera,AIRCamera' },
                  {
                    type: 'control_buttons_selector2',
                    default: '',
                    extra:
                      'contact_closure,press_and_hold,toggle,thirdprty_contact_closure,thirdprty_press_and_hold,thirdprty_toggle,switch,press_button,sensor',
                  },
                  { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
                  {
                    type: 'child_notification_item_selector',
                    default: '',
                    extra: 'notify_alert:4,notify_warning:5,notify_info:3',
                  },
                ],
              },
              fields: [
                {
                  type: 'custom_selector',
                  field: 'alarm_model:aapesx1a:other',
                  editable: true,
                  default: 'aapesx1a',
                  labels: 'Select Alarm Model',
                  extra: 'AAP ESX 1A|Other',
                },
                {
                  type: 'text_input_feature',
                  field: 'user_name',
                  editable: true,
                  default: 'User 1',
                  labels: 'User Name',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'code',
                  editable: true,
                  default: '123',
                  labels: 'Code',
                  extra: '',
                },
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'ip',
                  editable: true,
                  default: '192.168.1.220',
                  labels: 'Ip Address',
                  extra: '',
                },
                {
                  type: 'network_port',
                  field: 'port',
                  editable: true,
                  default: '9000',
                  labels: 'Network port',
                  extra: '',
                },
                {
                  type: 'ah_size_selector',
                  field: 'modal',
                  editable: true,
                  default: '8',
                  labels: 'Size',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'areas',
                  editable: true,
                  default: [{ label: 'Area 1', value: 1, key: 1655183675996 }],
                  labels: 'Areas',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'zones',
                  editable: true,
                  default: [{
                    label: 'Zone 1', value: 1, key: 1655183685224, area: 1,
                  }],
                  labels: 'Zones',
                  extra:
                    'custom-area-selector,custom-values_Motion Sensor,Heat/Smokes,General Contact Closure',
                },
                {
                  type: 'zones',
                  field: 'outputs',
                  editable: true,
                  default: [
                    {
                      label: 'Garage Door',
                      value: 4,
                      key: 1655183695230,
                      type: 'Press',
                      state_show: true,
                    },
                  ],
                  labels: 'Outputs',
                  extra: 'custom-zone-state,custom-zone-drop_Label,Press,Toggle',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_arm_away',
                  editable: true,
                  default: true,
                  labels: 'Show Arm Away Button',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_arm_home',
                  editable: true,
                  default: true,
                  labels: 'Show Arm Home Button',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_panic',
                  editable: true,
                  default: true,
                  labels: 'Show Panic Button',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_disarm',
                  editable: true,
                  default: true,
                  labels: 'Show Disaram Button',
                  extra: '',
                },
              ],
            },
          },
          paradox_sp: {
            title: 'Alarm Paradox SP',
            value: 'paradox_sp',
            icon: 'calc-bell2-active',
            features: {
              state_weight: 10,
              shadow_type: 'tcp',
              tags: [],
              device_extra_fields: {
                system_tags: ['ArrowHead', 'tcp', 'Alarm'],
                commands: {
                  area: [
                    'alarm',
                    'alarm_zone',
                    'status_last_armed_by_user',
                    'last_disarmed_by_user',
                    'status_readiness',
                  ],
                  commands_fields: ['index', 'parent', 'type', 'name', 'label', 'timestamp'],
                  extra: {},
                },
              },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-hifi1' },
                  { type: 'camera_selector', default: '', extra: 'Camera,AIRCamera' },
                  {
                    type: 'control_buttons_selector2',
                    default: '',
                    extra:
                      'contact_closure,press_and_hold,toggle,thirdprty_contact_closure,thirdprty_press_and_hold,thirdprty_toggle,switch,press_button,sensor',
                  },
                  { type: 'uv_item_selector', default: '', extra: 'app_browser_item' },
                  {
                    type: 'child_notification_item_selector',
                    default: '',
                    extra: 'notify_alert:4,notify_warning:5,notify_info:3',
                  },
                ],
              },
              fields: [
                {
                  type: 'custom_selector',
                  field: 'alarm_model:sp5500:other',
                  editable: true,
                  default: 'sp5500',
                  labels: 'Select Alarm Model',
                  extra: 'SP5500|Other',
                },
                {
                  type: 'text_input_feature',
                  field: 'user_name',
                  editable: true,
                  default: 'User 1',
                  labels: 'User Name',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'code',
                  editable: true,
                  default: '0000',
                  labels: 'Code',
                  extra: '',
                },
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'ip',
                  editable: true,
                  default: '192.168.1.220',
                  labels: 'Ip Address',
                  extra: '',
                },
                {
                  type: 'network_port',
                  field: 'port',
                  editable: true,
                  default: '10000',
                  labels: 'Network port',
                  extra: '',
                },
                {
                  type: 'ah_size_selector',
                  field: 'modal',
                  editable: true,
                  default: '8',
                  labels: 'Size',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'areas',
                  editable: true,
                  default: [{ label: 'Area 1', value: 1, key: 1655183675996 }],
                  labels: 'Areas',
                  extra: '',
                },
                {
                  type: 'zones',
                  field: 'zones',
                  editable: true,
                  default: [{
                    label: 'Zone 1', value: 1, key: 1655183685224, area: 1,
                  }],
                  labels: 'Zones',
                  extra:
                    'custom-area-selector,custom-values_Motion Sensor,Heat/Smokes,General Contact Closure',
                },
                {
                  type: 'zones',
                  field: 'outputs',
                  editable: true,
                  default: [
                    {
                      label: 'Garage Door',
                      value: 4,
                      key: 1655183695230,
                      type: 'Press',
                      state_show: true,
                    },
                  ],
                  labels: 'Outputs',
                  extra: 'custom-zone-state,custom-zone-drop_Label,Press,Toggle',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_arm_away',
                  editable: true,
                  default: true,
                  labels: 'Show Arm Away Button',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_arm_home',
                  editable: true,
                  default: true,
                  labels: 'Show Arm Home Button',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_panic',
                  editable: true,
                  default: true,
                  labels: 'Show Panic Button',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_disarm',
                  editable: true,
                  default: true,
                  labels: 'Show Disaram Button',
                  extra: '',
                },
              ],
            },
          },
        },
      },
      knx: {
        title: 'KNX',
        value: 'knx',
        children: {
          knx_gateway: {
            title: 'Gateway',
            value: 'knx_gateway',
            icon: 'calc-alarm1',
            features: {
              state_weight: 10,
              shadow_type: 'knx',
              knx_device: 'Gateway',
              tags: ['KNX', 'KNX Gateway'],
              device_extra_fields: { system_tags: ['KNX', 'KNXGateway'], commands: { extra: {} } },
              uv_settings: { fields: [] },
              fields: [
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'gateway_ip',
                  editable: true,
                  default: '192.168.1.1',
                  labels: 'Gateway IP',
                  extra: '',
                },
                {
                  type: 'network_port',
                  field: 'gateway_port',
                  editable: true,
                  default: '20245',
                  labels: 'Gateway Port',
                  extra: '',
                },
                {
                  type: 'ip_address',
                  field: 'local_ip',
                  editable: true,
                  default: '0.0.0.0',
                  labels: 'Local IP(Hub IP)',
                  extra: '',
                },
              ],
            },
          },
          knx_dimmer: {
            title: 'Dimmer',
            value: 'knx_dimmer',
            icon: 'calc-lamp2',
            features: {
              state_weight: 10,
              shadow_type: 'knx',
              knx_device: 'LightDimmer',
              tags: ['KNX', 'KNX Device'],
              device_extra_fields: { system_tags: ['KNX', 'KNXDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-light1' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_switch',
                  editable: true,
                  default: '',
                  labels: 'Control Group Address for Switch',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_switch_state',
                  editable: true,
                  default: '',
                  labels: 'State Group Address for Switch',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_brightness',
                  editable: true,
                  default: '',
                  labels: 'Control Group Address for Brightness',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_brightness_state',
                  editable: true,
                  default: '',
                  labels: 'State Group Address for Brightness',
                  extra: '',
                },
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'custom_selector',
                  field: 'position:vertical:horizontal',
                  editable: true,
                  default: 'vertical',
                  labels: 'Position',
                  extra: 'Vertical|Horizontal',
                },
                {
                  type: 'sound_volume',
                  field: 'volume_',
                  editable: true,
                  default: { volume_max: 100, volume_min: 0 },
                  labels: 'Maximum Value|Minimum Value',
                  extra: '',
                },
                {
                  type: 'sound_volume_step',
                  field: 'volume_step',
                  editable: true,
                  default: 2,
                  labels: 'Value step',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_controls',
                  editable: true,
                  default: true,
                  labels: 'Show controls',
                  extra: 'switch',
                },
              ],
            },
          },
          knx_switch: {
            title: 'Switch',
            value: 'knx_switch',
            icon: 'calc-switch8',
            features: {
              state_weight: 3,
              shadow_type: 'knx',
              knx_device: 'Switch',
              tags: ['KNX', 'KNX Device'],
              device_extra_fields: { system_tags: ['KNX', 'KNXDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-switch8' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address',
                  editable: true,
                  default: '',
                  labels: 'Control Group Address ',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_state',
                  editable: true,
                  default: '',
                  labels: 'State Group Address',
                  extra: '',
                },
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_state',
                  editable: true,
                  default: false,
                  labels: 'Show state',
                  extra: '',
                },
              ],
            },
          },
          knx_light: {
            title: 'Light Switch',
            value: 'knx_light',
            icon: 'calc-light1',
            features: {
              state_weight: 3,
              shadow_type: 'knx',
              knx_device: 'Light',
              tags: ['KNX', 'KNX Device'],
              device_extra_fields: { system_tags: ['KNX', 'KNXDevice'] },
              uv_settings: {
                fields: [
                  { type: 'rule_name', default: '' },
                  { type: 'macro_name', default: '' },
                  { type: 'icon_name', default: 'calc-switch8' },
                  { type: 'item_notification_type', default: '3' },
                ],
              },
              fields: [
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_switch',
                  editable: true,
                  default: '',
                  labels: 'Control Group Address',
                  extra: '',
                },
                {
                  type: 'text_input_feature',
                  field: 'knx_group_address_switch_state',
                  editable: true,
                  default: '',
                  labels: 'State Group Address',
                  extra: '',
                },
                {
                  type: 'debounce',
                  field: 'debounce',
                  editable: true,
                  default: 500,
                  labels: 'Debounce',
                  extra: '',
                },
                {
                  type: 'state_checkbox',
                  field: 'show_state',
                  editable: true,
                  default: false,
                  labels: 'Show state',
                  extra: '',
                },
              ],
            },
          },
        },
      },
    },
  },
};