/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component use to list logs in Dashboard summary
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to list logs in Dashboard summary
 * @author Dinuka Bandara
 * @since -
 */
import React from 'react';
import styles from './DeviceContainerDashboardLogs.module.css';

type ComponentProps = {
  title: string;
  date: any;
};

/**
 * Dashboard log item card
 *
 * @param {Object} props                      component props
 * @param {Strint} props.title                Name/Title of the log
 * @param {number} props.date                 Last updated time of the log in unix
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const DeviceContainerDashboardLogs: React.FunctionComponent<ComponentProps> = ({
  title,
  date,
}) => (
  <div className={`${styles.root} row col-md-12 mb-1 pl-3 pr-3`}>
    <div className="login-content">
      <div className={styles.logHeader}>{title}</div>
      <div className={styles.logDate}>{date}</div>
    </div>
  </div>
);
