import { useEffect, useRef, useState } from 'react';
import {
  Component, EventManager, InternalEventManager, iComponent,
} from '../shared/Plugin';
import ShellyIntegration, { iShellyIntegration } from './shelly.service';

import UiComponents from './ui';
import { CiDevice } from '../shared/services/cloud-integration.service';

export class ReactComponents extends Component implements iComponent {
  private integration: iShellyIntegration;

  private uiComponents: UiComponents;

  constructor(arg: EventManager, integration: iShellyIntegration) {
    super();
    this.eventManager = arg;
    this.integration = integration;
    this.uiComponents = new UiComponents();
  }

  getDeviceCardUi = (rnProps: {
    category: any;
    id?: string;
    cintId?: string;
    name: string;
    bgColor?: string;
    cursor?: string;
    key?: any;
    showStatus?: boolean;
  }) => {
    const ReactComponent = this.uiComponents.GetDeviceCardUiComponent(rnProps);
    return ReactComponent;
  };

  getDeviceContainerCreateUi = (rnProps: {
    backup_view: boolean;
    onSuccessCreate?: (type: 'shelly', created: any) => void;
  }) => {
    const ReactComponent = this.uiComponents.GetDeviceCreateForm(
      this.eventManager,
      this.internalEvents,
      this.integration,
      rnProps,
    );
    return ReactComponent;
  };

  getDeviceContainerSettings = (rnProps: {dcName: string; dcId: string, projectId: string}) => {
    const ReactComponent = this.uiComponents.GetDeviceContainerSettingsForm(
      this.integration,
      rnProps,
    );
    return ReactComponent;
  };

  getUserViewCreateExtraFields = (rnProps: {
    selectedDevice: CiDevice & {dc_type: string};
    uvItem: object;
    appendSettings: (payload: any) => void;
    updateCustomFieldErrors?: (
      payload: {
        [key: string]: {
          invalid?: boolean;
          message?: string;
        };
      },
      forceReset?: boolean
    ) => void;
  }) => {
    const ReactComponent = this.uiComponents.GetUserViewCreateExtraFields(rnProps);
    return ReactComponent;
  };
}
