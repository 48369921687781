/* eslint-disable max-len */
/* eslint-disable space-before-blocks */
/* eslint-disable camelcase */
import React, { useEffect, useReducer } from 'react';
import { string } from 'yup/lib/locale';
import { airCountries } from '../../config/airContries';
import { appConsoleError, cloneObject } from '../../utility/appUtils';
import { ISelectedProject } from './types';

const getInitialProjectState = () => ({
  selected_project: {
    id: null,
    title: '',
    description: '',
    image: '',
    project_status: 0,
    project_type: 0,
    access_level: 0,
    demo_mode: false,
    restore_bsid: null,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

export const ProjectContext = React.createContext<ISelectedProject>(getInitialProjectState());

const projectReducer = (state: ISelectedProject, action: any) => {
  const { type, payload } = action;
  // appConsoleLog(payload)
  // appConsoleLog(action);

  const initState = getInitialProjectState();
  // appConsoleLog(initState);
  // eslint-disable-next-line camelcase
  const selected_project:any = cloneObject(action.selected_project);
  if (selected_project && selected_project.project_country_code && selected_project.project_country_code !== ''){
    const airCountry = airCountries.find((row:any) => row.abbr === selected_project.project_country_code);
    selected_project.country_code_num = airCountry && airCountry.code ? airCountry.code : '64';
  }
  // appConsoleLog(selected_project);
  return {
    ...state,
    selected_project: {
      ...selected_project,
      // ...action.selected_project,
    },
  };
};

export function ProjectProviderComponent({ children, ...props }: any) {
  const [state, dispatch] = useReducer(projectReducer, getInitialProjectState());
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ProjectContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ProjectContext.Provider>
  );
}
