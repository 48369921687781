import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import hubIcon from '../../../assets/images/icons/hub.png';

const useStyles = makeStyles({
  root: {
    width: '50%',
    margin: '0 auto',
    textAlign: 'center',
  },
  icon: {
    backgroundImage: 'url("../../../assets/images/hub.png")',
    height: 58,
  },
  title: {
    color: '#06454D',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '120%',
  },
});

const DCCreateSuccess: React.FC<{onComplete: () => void; onBack?: () => void}> = ({
  onComplete,
  onBack,
}) => {
  const styles = useStyles();
  return (
    <Box className={styles.root}>
      <img src={hubIcon} style={{ height: 40 }} />
      <Typography className={styles.title}>Device Container Added Succesfully</Typography>
      <br />
      <Button variant="contained" color="primary" onClick={onComplete}>
        Go to Hub
      </Button>
      {onBack && (
        <>
          <br />
          <Button color="primary" onClick={onBack}>
            Back
          </Button>
        </>
      )}
    </Box>
  );
};

export default DCCreateSuccess;
