/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef, useState } from 'react';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Close } from '@mui/icons-material';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';
import styles from './FieldEnableCard.module.css';
import { IDFField } from '../../../../../config/deviceConfig/types';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';
import { appConsoleLog } from '../../../../../utility/appUtils';

type ComponentProps = {
  fieldTypeName: string;
  fieldConfig: IDFField;
  validateForm: number;
  deviceInfo: any;
  resetForm: IFormResetRef;
  children: any;
  onRemove: (payload: any) => void;
};
interface IformData {
  state_checkbox: boolean;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldFieldEnableCard: React.FunctionComponent<ComponentProps> = (props) => {
  const { children } = props;
  const handleRemoveField = () => {
    props.onRemove({
      action: 'remove',
      form: props.fieldTypeName,
      data: {
        function_code: props.fieldConfig.function_code,
      },
      errors: [],
    });
  };

  return (
    <div className={styles.title}>
      <div>
        <div className={styles.flexRow}>
          <div className={styles.flexSpacer}>
            <div className="field-enable-card-data">{children}</div>
          </div>
          <IconButton size="small" className="ml-8" onClick={handleRemoveField} color="primary">
            <Close fontSize="large" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
