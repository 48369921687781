/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Base Layout/container and definition for the create/edit logic module.
 *
 * @author Maduka Dilshan
 */

import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
  DeviceCollectionSearchOut, RuleEngineState, toDenormalize, toNormalize,
} from '@smartlife-redux-state/common';
import { insertInOrder } from '@smartlife-redux-state/orm';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { dcSearchGraphQL } from 'src/services/device-container/device-container.service';
import Layout from '../../../components/layout/Layout';
import { PageHeader } from '../../../components/layout/PageHeader';
import LogicStepperContainer, { LogicStep } from './components/LogicStepper';
import PageContentLayout, { PageConentHeader, PageContent } from './components/PageContentLayout';
import CreateRuleContent from './components/CreateRuleContent';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import { RootState, useDispatch, useSelector } from '../../../store/redux/store';
import {
  hydrate_logic_creation,
  reset_logic_creation,
  validate_main_action,
} from '../../../store/redux/features/logic-engine';
import { AuthContext } from '../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { set_logics } from '../../../store/redux/features/logics';
import { VerifyLogic } from './components/VerifyLogic';

const useStyle = makeStyles({
  stepRoot: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
  },
  stepTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  stepTabTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
    marginRight: '2%',
  },
  stepTabLine: {
    height: '100%',
    position: 'relative',
    flex: '1',
    '&:after': {
      content: "''",
      position: 'absolute',
      borderBottom: '1px solid #D9DFE0',
      height: '100%',
      width: '100%',
      transform: 'translateY(-45%)',
    },
  },
  lastTabLine: {
    flex: 1,
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'center',
    backgroun: 'black',
    border: '8px solid var(--light-green-1)',
    borderRadius: '50%',
    marginRight: '2%',
    color: 'var(--light-green)',
  },

  circleIcon: {
    color: 'white',
  },

  contentRoot: {
    flex: 1,
    // marginBottom: '4%',
    overflow: 'scroll',
    padding: '2% 10%',
  },
});

const CreateRule = () => {
  const styles = useStyle();

  const logic_id = useQueryString('logicId') as string;
  const projectId = useQueryString('projectId') as string;
  const authUser: any = useContext(AuthContext);
  // const selectedProject = useContext(ProjectContext);
  const [show_verify, setShowVerify] = useState(false);
  const [skipHydrate, setSkipHidrate] = useState(false);
  const { result: logicList } = useSelector((state: RootState) => state.logics.result);
  const orm = useSelector((reduxState: RootState) => reduxState.orm);

  const user = useContext(ApolloAuthContext);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(reset_logic_creation());
    dispatch({
      type: 'RESET_NORMALIZED_STATE',
    });
  }, []);

  useEffect(() => {
    if (logic_id) {
      init(logic_id);
    }
    // if(logic_id && logic_id.length > 0) {
    //   const found = logicList.find(item => item.logic_id == logic_id);
    //   if(found && found.input_json) {
    //     console.log(JSON.parse(found.input_json));
    //     const parsed = JSON.parse(found.input_json) as RuleEngineState;
    //     parsed.meta.is_editing = true;
    //     dispatch(hydrate_logic_creation(parsed))
    //   }
    // }
  }, [logic_id]);

  async function init(id: string) {
    try {
      const { token } = authUser.access_token;
      const res = await axios.post(
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/get`,
        {
          logic_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.data && res.data.result) {
        let parsed = JSON.parse(res.data.result.input_json) as RuleEngineState;

        let forceNormalized = false;
        if (parsed['@@_______REDUX_ORM_STATE_FLAG']) {
          parsed = toDenormalize(JSON.parse(res.data.result.input_json));
        } else {
          forceNormalized = true;
          setSkipHidrate(true);

          const inputJson = JSON.parse(res.data.result.input_json);
          const dcs = await dcSearchGraphQL(
            user.apollo_client,
            projectId,
            null,
            null,
            '',
            20,
            0,
          );

          if (!dcs) throw new Error('Unable to fetch device containers');

          const dcsList = (dcs.dcSearch as DeviceCollectionSearchOut).result;
          inputJson.device_containers = {
            result: dcsList,
          };
          const normlized = toNormalize(inputJson);
          // toNormalize(JSON.parse(res.data.result.input_json));
          insertInOrder(normlized, dispatch);
        }

        parsed.meta.is_editing = true;
        if (forceNormalized) {
          // console.log('@ORM<<<<<<<<<<', orm);
          // const deNormalized = toDenormalize(orm);
          // dispatch(hydrate_logic_creation(deNormalized));
          setTimeout(() => {
            setUpdateStoreFlag(true);
          }, 500);
        } else {
          dispatch(hydrate_logic_creation(parsed));
          dispatch(validate_main_action());
          setShowVerify(true);
        }

        dispatch(set_logics([res.data.result]));
      } else {
        throw new Error('Something went wrong');
      }
      // return res.data.result as any
    } catch (err: any) {
      enqueueSnackbar(`Failed to get logic - ${err.message}`, { variant: 'error' });
    }
  }

  const [updateStoreFlag, setUpdateStoreFlag] = useState(false);
  useEffect(() => {
    if (updateStoreFlag) {
      updateStore();
    }
  }, [updateStoreFlag]);

  function updateStore() {
    const deNormalized = toDenormalize(orm);
    deNormalized.meta.is_editing = true;
    dispatch(hydrate_logic_creation(deNormalized));
    dispatch(validate_main_action());
    setShowVerify(true);
    setUpdateStoreFlag(false);
  }

  if (show_verify) {
    return <VerifyLogic changeVerifyVisiblity={setShowVerify} />;
  }

  return (
    <Layout>
      <LogicStepperContainer initialActiveStep={0} canSkipStep={false}>
        <PageHeader>
          <Box className={styles.stepRoot}>
            <LogicStep>
              {({ activeStep, setActive }) => {
                const index = 0;
                return (
                  <Box className={styles.stepTab}>
                    <Box className={styles.iconRoot} onClick={() => setActive(index)}>
                      {activeStep === index ? (
                        <CheckCircleIcon />
                      ) : (
                        <FiberManualRecordIcon className={styles.circleIcon} />
                      )}
                    </Box>
                    <Typography className={styles.stepTabTitle}>When</Typography>
                    <Box className={styles.stepTabLine} />
                  </Box>
                );
              }}
            </LogicStep>
            <LogicStep>
              {({ activeStep, setActive }) => {
                const index = 1;
                return (
                  <Box className={styles.stepTab}>
                    <Box className={styles.iconRoot} onClick={() => setActive(index)}>
                      {activeStep === index ? (
                        <CheckCircleIcon />
                      ) : (
                        <FiberManualRecordIcon className={styles.circleIcon} />
                      )}
                    </Box>
                    <Typography className={styles.stepTabTitle}>Check</Typography>
                    <Box className={styles.stepTabLine} />
                  </Box>
                );
              }}
            </LogicStep>
            <LogicStep>
              {({ activeStep, setActive }) => {
                const index = 2;
                return (
                  <Box className={styles.stepTab}>
                    <Box className={styles.iconRoot} onClick={() => setActive(index)}>
                      {activeStep === index ? (
                        <CheckCircleIcon />
                      ) : (
                        <FiberManualRecordIcon className={styles.circleIcon} />
                      )}
                    </Box>
                    <Typography className={styles.stepTabTitle}>Do</Typography>
                    <Box className={styles.lastTabLine} />
                  </Box>
                );
              }}
            </LogicStep>
          </Box>
        </PageHeader>

        <Box className={styles.contentRoot}>
          <CreateRuleContent />
        </Box>
      </LogicStepperContainer>
    </Layout>
  );
};

export default CreateRule;
