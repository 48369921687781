/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, useEffect, useReducer } from 'react';
import './styles.css';

interface IUVContext {
  projectId: string;
  userViewId: string;
  userViewName: string;
  userViewPath: string;
  updated: number;
  dispatch: (payload:any) => void;
}
const getInitialState = (initialData: any): IUVContext => ({
  projectId: '',
  userViewId: '',
  userViewName: '',
  userViewPath: 'root',
  updated: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
  ...initialData,
});

export const UVContext = React.createContext<IUVContext>(getInitialState({}));

export enum UVReducerTypes {
  INITIALISE = 'INITIALISE',
  CHANGE = 'CHANGE',
}
const UVReducer = (state: IUVContext, action: {type: string; payload: any}) => {
  const { type, payload } = action;

  switch (type) {
    case 'INITIALISE': {
      const initState = getInitialState({});
      return {
        ...state,
        ...initState,
      };
    }
    case 'CHANGE': {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export function UVProviderComponent({ children, initialData }: any) {
  const [state, dispatch] = useReducer(UVReducer, getInitialState(initialData));

  return <UVContext.Provider value={{ ...state, dispatch }}>{children}</UVContext.Provider>;
}
