/**
 * @deprecated
 */
class ShellyIntegratorsService {
    public lang = {}

    public logo = {
        ITG_UTE: '../assets/img/integrator_logos/ute_logo.svg',
        ITG_SLL: 'https://smartlife-pro2-develop.web.app/static/media/Smartlife-invert-logo.3889828d.png',
    }

    public langGet = (urlParams: URLSearchParams) => {	
        const urllang = urlParams.get('lang');
        if(Object.keys({}).includes(urllang)){return urllang}
        return 'EN';
    }

    public HTTP = {
        auth({ email, password }) {
            return new Promise<any>(null);
        },
        listDevices(token, url) {
            return new Promise<any>(null);
        },
        checkIntegrators(itg) {
            return new Promise<any>(null);
        },
        getDKey(token, url, id) {
            return new Promise<any>(null);
        },
        manageIntegrator(token, url, id, tag, cb, action, access = 0) {
            return new Promise(null);
        }
    }

    public HELPERS = {
        formatDeviceList(dl) {
            return null;
        },
        prepareDKeyITG(deviceDKey: any, integratorTag: string) {
            return null;
        }
    }
}

export default ShellyIntegratorsService;