/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useState, useCallback, useRef,
} from 'react';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import {
  Add, EditOutlined, Save, Delete, Visibility, VisibilityOff,
} from '@mui/icons-material';
import { debounce } from 'lodash';
import moment from 'moment';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import qs from 'query-string';
import {
  Button, Divider, FormControlLabel, IconButton, InputAdornment,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { init } from 'logrocket';
import { ApolloFunctions } from 'src/store/Apollo/ApolloFunctions';
import { projectGetGraphQL } from 'src/services/location/location.service';
import { IconTextCard2Component } from '../../../../components/shared/IconTextCard2/IconTextCard2';
import { proDeviceSearchGraphQL } from '../../../../services/pro-device/pro-device.service';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import { useQueryNumber } from '../../../../utility/CustomHooks/useQueryNumber';
import { DeviceCard } from '../../../../components/shared/DeviceCard/DeviceCard';
import { IProDevice } from '../../ProDeviceCreateEdit/types';
import hubImage from '../../../../assets/images/placeholders/hub-img.png';

import { appConsoleLog, AppUtils } from '../../../../utility/appUtils';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import styles from './SingleContainerPro.module.css';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import {
  proDCClearHubGraphQL,
  proDCDeleteGraphQL,
  proDCEnableDisableAllDevicesGraphQL,
  proDCGetGraphQL,
  proDCGetStatus,
  proDCUpdateGraphQL,
} from '../../../../services/pro-container/pro-container.service';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
  SuccessSnackbarActions,
  SuccessSnackbarOptions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import { getProDcTunnels } from '../../../../services/device-container/device-container.service';
import RemoteConnect from './RemoteConnect';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import FirebaseAuthFunctions from '../../../../store/Auth/providers/FirebaseAuthFunctions';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { ITEM_DEFAULTS } from '../../../../config/deviceConfig/item_defaults';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';
import {
  ConfirmDelete,
  ConfirmProcessMinimum,
} from '../../../../components/shared/CustomDeleteModal/ConfirmDelete';
import { CustomSwitch } from '../../../../components/shared/Switch/Swtich';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.defaultProps = {
  children: null,
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1ca086',
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#818181',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#1ca086',
      opacity: 1,
    },
    '&$selected': {
      color: '#1ca086',
      // fontWeight: 'bold',
    },
    '&:focus': {
      color: '#1ca086',
    },
  },
  selected: {},
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<any>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

type IContainerDetails = {
  location_name: string;
  location_description: string;
  serial_name?: string;
  dc_type?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
    overflow: 'scroll',
  },
}));
interface IState {
  dc?: any;
}
export const SingleContainerProComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);

  const selectedProjectID = useQueryString('projectId') as string;
  const selectedDCID = useQueryString('dcId') as string;
  const selectedDCName = useQueryString('name') as string;

  const [dcNameFromNetwork, setDcNameFromNetwork] = useState('');

  // const projectName = qs.parse(window.location.search)['name'];

  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [proResultCount, setProResultCount] = useState('');
  const [proDeviceList, setProDeviceList] = useState([]);
  const [categorizedDcList, setCatgorizedDcList] = useState<any>({});
  const [dcDetails, setDCDetails] = useState<IContainerDetails>();
  const [projectName, setProjectName] = useState(qs.parse(window.location.search).name);

  const [refreshDeviceList, RefreshDeviceListNow] = useState(0);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [sshTunnels, setSshTunel] = useState(null);
  const [hostTunnels, setHostTunnels] = useState(null);
  const [isSysAdmin, setisSysAdmin] = useState(false);
  const [hubStatus, setHubStatus] = useState('');

  const [isKnxGatewayExist, setisKnxGatewayExist] = useState(false);

  const [hubMetaInfo, setHubMetaInfo] = useState({
    firmware_version: null,
    online_date_time: null,
    ip: null,
  });

  const [deleteState, setDeleteState] = useState<boolean>(false);
  const [clearState, setClearState] = useState(false);
  const [deviceStateEnable, setDeviceStateEnable] = useState(false);
  const [deviceStateDisable, setDeviceStateDisable] = useState(false);

  const [formDefaults, setFormDefaults] = useState({
    monitoring: true,
  });
  const formRef = useRef<any>();

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const [shouldDeleteHub, setShouldDeleteHub] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const refetchTunnels = async () => {
    getProDcTunnels(userProfile?.apollo_client, selectedDCID)
      .then((res) => {
        if (res.proDcTunnels && res.proDcTunnels.tunnels) {
          const data = res.proDcTunnels.tunnels;
          // .filter(
          //  (tunnel: any) => tunnel.remark == "ssh"
          // );
          const data1 = res.proDcTunnels.host_list;
          setisSysAdmin(true);
          setSshTunel(data);
          setHostTunnels(data1);
        }
      })
      .catch((err) => appConsoleLog(err));
  };

  const hubAvaibility = async () => {
    try {
      const res = await proDCGetStatus(userProfile.apollo_client, selectedDCID);

      if (res.data && res.data.proDCGetStatus) {
        setHubStatus(res.data.proDCGetStatus.update_status);
      } else {
        throw new Error('');
      }
    } catch (err) {
      setHubStatus('unkown');
      appConsoleLog(err);
    }
  };

  useEffect(() => {
    async function initFunc() {
      if (selectedDCID) {
        appConsoleLog(selectedProjectID, selectedDCID);

        getProDcTunnels(userProfile?.apollo_client, selectedDCID)
          .then((res) => {
            if (res.proDcTunnels && res.proDcTunnels.tunnels) {
              const data = res.proDcTunnels.tunnels;
              // .filter(
              //  (tunnel: any) => tunnel.remark == "ssh"
              // );
              const data1 = res.proDcTunnels.host_list;
              setisSysAdmin(true);
              setSshTunel(data);
              setHostTunnels(data1);
            }
          })
          .catch((err) => appConsoleLog(err));

        searchDcs();

        proDCGetGraphQL(userProfile?.apollo_client, selectedDCID)
          .then(async (resp) => {
            const res = resp.proDCGet.proDeviceCollection;
            setDCDetails({
              location_name: res.name,
              location_description: res.description,
              serial_name: res.serial_name,
              dc_type: res.dc_type,
            });
            if (!selectedDCName) {
              setDcNameFromNetwork(res.name);
            }
            setHubMetaInfo({
              firmware_version: res.firmware_version,
              online_date_time: Number(res.online_date_time),
              ip: res.ip,
            });
            //   const hubInfo = await FirebaseAuthFunctions.getProHubFirebaseInfo(res.serial_name);
            //  // console.log("HUB INFO", hubInfo);
            //   setHubMetaInfo(hubInfo);
          })
          .catch((err) => {
            appConsoleLog(err);
          });

        const hubInfo = await FirebaseAuthFunctions.getProHubFirebaseData(selectedDCName);
        if (hubInfo && typeof hubInfo.offline_alert_notify === 'boolean') {
          setFormDefaults({
            monitoring: hubInfo.offline_alert_notify,
          });
        } else {
          setFormDefaults({
            monitoring: true,
          });
        }

        hubAvaibility();
      }
    }

    initFunc();
  }, [selectedDCID, refreshDeviceList]);

  const formValues: IContainerDetails = {
    location_name: dcDetails?.location_name ?? '',
    location_description: dcDetails?.location_description ?? '',
  };

  const searchDcs = async (keyword = '') => {
    try {
      const res = await proDeviceSearchGraphQL(
        userProfile?.apollo_client,
        selectedProjectID,
        selectedDCID,
        '',
        '',
        '',
        '',
        -1,
        [],
        [],
        keyword,
        500,
        0,
      );

      setProResultCount(res.proDeviceSearch.result ? res.proDeviceSearch.result.length : 0);
      setProDeviceList(res.proDeviceSearch.result ? res.proDeviceSearch.result : []);
      catogorizeDcs(res.proDeviceSearch.result);
    } catch (err) {
      appConsoleLog(err);
    }
  };

  const onSubmitForm = async (values: IContainerDetails, payload: any) => {
    proDCUpdateGraphQL(
      userProfile?.apollo_client,
      selectedDCID,
      values.location_name.trim(),
      values.location_description.trim(),
    )
      .then(async (res) => {
        snackbar.enqueueSnackbar(APP_TEXT.device_container.UPDATE_SUCCESS, {
          key: 'key1',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        });

        proDCGetGraphQL(userProfile?.apollo_client, selectedDCID)
          .then((resp) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const res = resp.proDCGet.proDeviceCollection;
            setDCDetails({
              location_name: res.name,
              location_description: res.description,
              serial_name: res.serial_name,
              dc_type: res.dc_type,
            });
            RefreshDeviceListNow(Date.now());
            setProjectName(res.name);
          })
          .catch((err) => {
            appConsoleLog(err);
          });
      })
      .catch((err) => {
        appConsoleLog(err);
        snackbar.enqueueSnackbar(APP_TEXT.device_container.UPDATE_FAILED, {
          key: 'login-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      });
  };

  const handleDelete = async () => {
    setShouldDeleteHub(false);

    proDCDeleteGraphQL(userProfile?.apollo_client, selectedDCID)
      .then(async (res) => {
        snackbar.enqueueSnackbar(APP_TEXT.device_container.DELETE_SUCCESS, {
          key: 'key1',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        });
        navgiate(`/app/device-containers?projectId=${selectedProjectID}`);
      })
      .catch((err) => {
        snackbar.enqueueSnackbar(APP_TEXT.device_container.DELETE_FAILED, {
          key: 'login-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      });
  };

  const handleClearHub = async (shoudlThrow = false) => {
    try {
      const client = ApolloFunctions.createAuthApolloClient(
        user.access_token.token,
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/graphqlv2`,
      );
      const res = await proDCClearHubGraphQL(client, selectedDCID, 3);
      snackbar.enqueueSnackbar('Hub is clearing in background...', { variant: 'info' });
    } catch (err) {
      if (shoudlThrow) throw err;
      snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const enableAllDevices = async () => {
    try {
      await enableDisableDevices(2); // status 2 = active
      snackbar.enqueueSnackbar('Enabling devices in background...', { variant: 'info' });
    } catch (err) {
      snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const disableAllDevices = async () => {
    try {
      await enableDisableDevices(3); // status 3 = active
      snackbar.enqueueSnackbar('Disabling devices in background...', { variant: 'info' });
    } catch (err) {
      snackbar.enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const enableDisableDevices = async (status) => {
    const client = ApolloFunctions.createAuthApolloClient(
      user.access_token.token,
      `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/graphqlv2`,
    );
    await proDCEnableDisableAllDevicesGraphQL(client, selectedDCID, status);
  };

  const onGenrateSuccess = () => {
    getProDcTunnels(userProfile?.apollo_client, selectedDCID)
      .then((res) => {
        if (res.proDcTunnels && res.proDcTunnels.tunnels) {
          const data = res.proDcTunnels.tunnels.filter((tunnel: any) => tunnel.remark === 'ssh');
          setisSysAdmin(true);
          if (data.length > 0) setSshTunel(data);
        }
      })
      .catch((err) => appConsoleLog(err));
  };

  const catogorizeDcs = (dcs: any[]) => {
    const categories: any = {};
    setisKnxGatewayExist(false);
    dcs.map((dc) => {
      if (Object.keys(categories).indexOf(dc.cat) === -1) {
        categories[dc.cat] = [dc];
      } else {
        categories[dc.cat] = [...categories[dc.cat], dc];
      }
      if (dc && dc.sub_cat === 'knx_gateway' && dc.cat === 'knx') {
        setisKnxGatewayExist(true);
      }
      return dc;
    });

    console.log('CAT', categories);
    setCatgorizedDcList(categories);
  };

  const debouncedGetSelectorData = useCallback(
    debounce((keyword: string) => searchDcs(keyword), 100),
    [],
  );
  const onDcSearch = (val = '') => {
    debouncedGetSelectorData(val);
  };

  const onSubmitFormMonitoring = async (monitoring) => {
    setFormDefaults({
      monitoring: !monitoring,
    });
    const hubInfo = await FirebaseAuthFunctions.updateProHubFirebaseMonitoring(
      selectedDCName,
      !monitoring,
    );
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            {`${selectedDCName || dcNameFromNetwork} `}
            {' '}
            <span
              style={{
                fontSize: '13px',
                verticalAlign: 'middle',
                color: hubStatus === 'online' ? 'green' : 'gray',
                textTransform: 'capitalize',
              }}
            >
              {hubStatus}
            </span>
            {hubMetaInfo.firmware_version && hubMetaInfo.online_date_time && (
              <p style={{ margin: '0' }}>
                <span
                  style={{
                    fontSize: '13px',
                    verticalAlign: 'middle',
                    color: 'gray',
                  }}
                >
                  {` ${hubMetaInfo.firmware_version}V - `}
                </span>
                <span
                  style={{
                    fontSize: '13px',
                    verticalAlign: 'middle',
                    color: 'gray',
                  }}
                >
                  {` ${moment
                    .unix(hubMetaInfo.online_date_time / 1000)
                    .format(' h:mm a dddd, MMMM Do YYYY')}`}
                </span>
                <span
                  style={{
                    fontSize: '10px',
                    verticalAlign: 'middle',
                    color: 'gray',
                  }}
                >
                  {` | IP = ${hubMetaInfo.ip}`}
                </span>
              </p>
            )}
          </Typography>
          <Box>
            <DefaultButton
              disabled={loading}
              onClick={() => {
                navigateRoute(
                  `/app/device-container/pro/update/device?projectId=${selectedProjectID}&dcId=${selectedDCID}&dcName=${projectName}&dcType=${dcDetails.dc_type}&isKnxGatewayExist=${isKnxGatewayExist}`,
                );
              }}
            >
              <AddIcon />
              {dcDetails
                && (dcDetails.dc_type === 9
                  ? APP_TEXT.device_container.ADD_VARIABLE
                  : APP_TEXT.device_container.ADD_DEVICE)}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className={`${classes.demo1} width-full-fixed`}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            className="width-full-fixed"
          >
            <AntTab label={`${APP_TEXT.device_container.SINGLE_AIR_TAB1} (${proResultCount})`} />
            <AntTab label={APP_TEXT.device_container.SINGLE_AIR_TAB3} />
            {isSysAdmin && <AntTab label={APP_TEXT.device_container.SINGLE_AIR_TAB4} />}
          </AntTabs>
          <TabPanel value={value} index={0}>
            <div>
              <SearchBox minimal>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder={APP_TEXT.device_container.SEARCH_DC}
                  onKeyUp={(event: any) => {
                    onDcSearch(event.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </SearchBox>
            </div>
            <div className="row">
              {Object.keys(categorizedDcList).map((key: any) => (
                <>
                  <Box height={30} />
                  <Typography variant="h6">
                    {ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows[key].title /* key.toUpperCase() */}
                  </Typography>
                  {/* <Divider /> */}
                  <div className="row" key={key}>
                    {categorizedDcList[key]
                      && categorizedDcList[key].map((device: any, i: number) => (
                        <div className="col-md-4 col-lg-4 col-xl-3 mt-4">
                          <DeviceCard
                            dcName={projectName}
                            dcCatType={dcDetails && dcDetails.dc_type}
                            device={device}
                            refreshList={() => {
                              RefreshDeviceListNow(Date.now());
                            }}
                            isKnxGatewayExist={isKnxGatewayExist}
                            onClick={() => {
                              // TODO: "go to device home page and linked items eidt page";
                              // appConsoleLog("x");
                              // if(dcDetails && dcDetails.dc_type != 9) {
                              //   navigateRoute(
                              // eslint-disable-next-line max-len
                              //     `../device-container/device?projectId=${selectedProjectID}&dcType=pro&deviceId=${device.id}`
                              //   );
                              // }
                            }}
                            key={device.id}
                          />
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {dcDetails && dcDetails.dc_type !== 9 && (
              <div
                style={{
                  border: '1px solid #AEB9C5',
                  padding: '10px',
                  borderRadius: '5px',
                  marginBottom: '20px',
                }}
              >
                <Typography variant="subtitle2">DEVICES</Typography>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 10 }}>
                    <Button
                      variant="outlined"
                      color="warning"
                      disabled={loading}
                      onClick={() => setDeviceStateDisable(true)}
                    >
                      Disable All Devices
                    </Button>
                  </div>
                  {/* <div>
                    <Button
                      variant="outlined"
                      color="info"
                      disabled
                      onClick={() => setDeviceStateEnable(true)}
                    >
                      Enable All Devices
                    </Button>
                  </div> */}
                </div>
              </div>
            )}
            {dcDetails && (
              <div style={{ fontWeight: 500, color: 'gray' }}>
                Hub Serial -
                {' '}
                {dcDetails.serial_name}
              </div>
            )}
            <Formik
              onSubmit={onSubmitForm}
              initialValues={formValues}
              validationSchema={Yup.object().shape({
                location_name: Yup.string().required('Please enter the hub name.'),
                location_description: Yup.string().required('Required'),
              })}
              // onSubmit={onSubmitForm}
            >
              {({
                // eslint-disable-next-line @typescript-eslint/no-shadow
                errors,
                handleBlur,
                // eslint-disable-next-line @typescript-eslint/no-shadow
                handleChange,
                touched,
                values,
                submitForm,
              }) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <TextField
                          error={Boolean(touched.location_name && errors.location_name)}
                          helperText={touched.location_name && errors.location_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.location_name}
                          className={styles.fieldSpace}
                          name="location_name"
                          label="Hub Name"
                          size="medium"
                          variant="outlined"
                        />
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: 10 }}>
                            <Button
                              color="primary"
                              onClick={submitForm}
                              disabled={loading}
                              variant="contained"
                            >
                              {APP_TEXT.device_container.SAVE_BUTTON}
                            </Button>
                          </div>
                          {dcDetails && dcDetails.dc_type !== 9 ? (
                            <div style={{ marginRight: 10 }}>
                              <Button
                                variant="outlined"
                                color="warning"
                                disabled={loading}
                                onClick={() => setClearState(true)}
                              >
                                {APP_TEXT.device_container.CLEAR_HUB}
                              </Button>
                            </div>
                          ) : (
                            <div />
                          )}
                          {dcDetails && dcDetails.dc_type !== 9 ? (
                            <div>
                              <Button
                                variant="outlined"
                                color="error"
                                disabled={loading}
                                onClick={() => setDeleteState(true)}
                              >
                                {APP_TEXT.device_container.DELETE_HUB_BUTTON}
                              </Button>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <TextField
                          error={Boolean(
                            touched.location_description && errors.location_description,
                          )}
                          helperText={touched.location_description && errors.location_description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.location_description}
                          rows={6}
                          maxRows={6}
                          multiline
                          className={styles.fieldSpace}
                          name="location_description"
                          label={APP_TEXT.location.FORM_FIELD_DESCRIPTION}
                          size="medium"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
            {isSysAdmin ? (
              <div className="col-md-8">
                <h3>Hub Monitoring</h3>
                <div className="col-md-8">
                  <FormControlLabel
                    className="pl-8"
                    control={(
                      <CustomSwitch
                        name="monitoring"
                        value={formDefaults.monitoring}
                        onChange={(e: any) => {
                          onSubmitFormMonitoring(formDefaults.monitoring);
                        }}
                      />
                    )}
                    label={`Monitoring ${formDefaults.monitoring ? 'Enabled' : 'Disabled'}`}
                  />
                </div>
              </div>
            ) : (
              <div />
            )}

            <ConfirmProcessMinimum
              open={deviceStateEnable}
              title="Confirmation"
              fullText="Are you sure you want to enable all devices?"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onConfirm={enableAllDevices}
              setOpen={setDeviceStateEnable}
            />
            <ConfirmProcessMinimum
              open={deviceStateDisable}
              title="Confirmation"
              fullText="Are you sure you want to disable all devices?"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onConfirm={disableAllDevices}
              setOpen={setDeviceStateDisable}
            />
            <ConfirmDelete
              itemId={selectedDCID}
              open={deleteState}
              title="Confirm to delete?"
              item="device collection"
              itemTextBoxLabel="Device Collection Name"
              name={projectName}
              setOpen={setDeleteState}
              delete={handleDelete}
            />
            <ConfirmDelete
              itemId={selectedDCID}
              open={clearState}
              title="Confirm to clear hub?"
              item="device collection"
              itemTextBoxLabel="Device Collection Name"
              fullText={`You are about to clear the hub ${projectName}. If you wisht to proceed please type the name of the device collection & click delete`}
              name={projectName}
              setOpen={setClearState}
              actionText="Clear Hub"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              delete={handleClearHub}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RemoteConnect
              prodc_id={selectedDCID}
              sshTunnels={sshTunnels}
              hostTunnels={hostTunnels}
              onGenrateSuccess={onGenrateSuccess}
              refetch={refetchTunnels}
            />
          </TabPanel>
          <Typography className={classes.padding} />
          <ConfirmDelete
            itemId={selectedDCID}
            open={deleteState}
            title="Confirm to delete?"
            item="device collection"
            itemTextBoxLabel="Device Collection Name"
            name={projectName}
            setOpen={setDeleteState}
            delete={handleDelete}
          />
        </div>
      </Box>
    </Layout>
  );
};

export default SingleContainerProComponent;
