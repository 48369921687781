/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { Chip, TextField, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isArray } from 'lodash';
import styles from './Tag.module.css';
import proDeviceUpdateService from '../services/pro-device.service';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { FORM_DEBOUNCE_TIME } from '../../../../config/deviceConfig/Values';

type ComponentProps = {
  label?: string;
  projectId: string;
  tagsSelected: string[];
  onChange?: (values: string[]) => void;
};
const emptySearchTags: ReadonlyArray<string> = ['NONE'];
// eslint-disable-next-line import/prefer-default-export
export const TagComponent: React.FunctionComponent<ComponentProps> = (
  props = { label: 'Tags', projectId: '', tagsSelected: [] },
) => {
  const userProfile = useContext(ApolloAuthContext);

  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [searchTags, setSearchtags] = useState<ReadonlyArray<string>>([]);

  // keyword debounce
  const [deb, setDeb] = useState<BehaviorSubject<string>>();
  useEffect(() => {
    const b = new BehaviorSubject<string>('');
    setDeb(b);
    const fetchData = async (keyword: string) => {
      try {
        const data = await proDeviceUpdateService.tagSearch(
          userProfile.apollo_client,
          props.projectId,
          keyword,
        );
        setSearchtags(data);
      } catch (err) {
        setSearchtags([]);
      }
    };

    const sub = b.pipe(debounceTime(FORM_DEBOUNCE_TIME)).subscribe((v: string) => {
      fetchData(v);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      JSON.stringify(tags) !== JSON.stringify(props.tagsSelected)
      && isArray(props.tagsSelected)
    ) {
      console.log('xcxc', props.tagsSelected);
      setTags(props.tagsSelected);
    }
  }, [props.tagsSelected]);

  const options = isArray(searchTags) && searchTags.length > 0 ? searchTags : emptySearchTags;

  console.log(options, tags);

  return (
    <div className={styles.root}>
      <Autocomplete
        size="small"
        className="dynamic-ui-form-control-sizing"
        multiple
        id="tags-filled"
        options={options as any}
        defaultValue={tags}
        value={tags}
        onChange={(e, v) => {
          setTags(v as any);
          if (props.onChange) {
            props.onChange(v as any);
          }
        }}
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
          deb?.next(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label={props.label || 'Tags'}
            placeholder="Tags"
          />
        )}
      />
    </div>
  );
};
