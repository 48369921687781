import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Grid, InputAdornment, Tab, Tabs, TextField, Typography,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'axios';
import { isArray } from 'lodash';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { RootState } from '../../../store/redux/store';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { AuthContext } from '../../../store/Auth/AuthContext';
import BackupCard from './components/BackupCard';
import { SearchBox } from '../../../components/shared/SearchBox/SearchBox';
import { DefaultButton } from '../LogicsEngine/components/ui/DefaultButton';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import { PageHeader } from '../../../components/layout/PageHeader';
import Layout from '../../../components/layout/Layout';
import { appConsoleLog } from '../../../utility/appUtils';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  root: {
    width: '100%',
    margin: '0 auto',
    padding: '0 2%',
    overflow: 'scroll',
  },
  headerBtns: {
    display: 'flex',
    alignItems: 'center',
  },
});

const BackupList = () => {
  const auth = useContext(AuthContext);
  useFirebaseConnect(`system_admins/${auth.uid}`);

  const isSystemAdmin = useSelector((store: RootState) => (
    !!(store.firebase.data.system_admins && store.firebase.data.system_admins[auth.uid])));

  const styles = useStyle();
  const navgiate = useNavigate();
  const projectId = useQueryString('projectId') as string;

  const authUser: any = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);

  const [type, setType] = useState('backups'); // backups, templates
  const [backups, setBackups] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [activeTab, setActiveTab] = useState(0);

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log('ACTIVE TAB', activeTab);
    if (activeTab === 0) {
      setType('backups');
    }
    if (activeTab === 1) {
      setType('templates');
    }
  }, [activeTab]);

  useEffect(() => {
    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id) { fetchData(type); }
  }, [type, selectedProject.selected_project.id]);

  async function fetchData(backupType: string) {
    try {
      setLoading(true);

      if (backupType === 'backups') {
        const { token } = authUser.access_token;
        const res = await axios.post(
          `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
          }/rest/backups/v1/project-backup/project/point/list`,
          {
            project_id: selectedProject.selected_project.id,
            query: {
              limit: 20,
              skip: 0,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setBackups(
          res.data && res.data.result && isArray(res.data.result.result)
            ? res.data.result.result
            : [],
        );
      }
      if (backupType === 'templates') {
        const { token } = authUser.access_token;
        const res = await axios.post(
          `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
          }/rest/backups/v1/project-backup/point/universal-template/list`,
          {
            query: {
              limit: 20,
              skip: 0,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setTemplates(
          res.data && res.data.result && isArray(res.data.result.result)
            ? res.data.result.result
            : [],
        );
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const refetch = (backupType) => {
    fetchData(backupType);
  };

  function onKeywordChange(value) {
    appConsoleLog(value);
  }

  if (loading) {
    return (
      <Layout>
        <PageHeader>
          <Box className={styles.headerRoot}>
            <Typography className={styles.headerTitle}>Backups</Typography>
            <Box className={styles.headerBtns}>
              <DefaultButton
                onClick={() => {
                  navgiate(`/app/backup-restore/restore-sessions/list?projectId=${projectId}`);
                }}
              >
                Restore Sessions
              </DefaultButton>
              <Box width={10} />
              <DefaultButton
                onClick={() => {
                  navgiate(`/app/backup-restore/create?projectId=${projectId}`);
                }}
              >
                <AddIcon />
                Create a Backup Point
              </DefaultButton>
            </Box>
          </Box>
        </PageHeader>
        <Box className={styles.root}>
          <Box>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Backups" value={0} />
              <Tab disabled={!isSystemAdmin} label="Universal Templates" value={1} />
            </Tabs>
            <Box height={20} />
            <SearchBox>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search by name, tags, devices"
                onKeyUp={(event: any) => {
                  onKeywordChange(event.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </SearchBox>
          </Box>
          <Box height={20} />
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Skeleton variant="rectangular" style={{ width: '100%', height: '15vh' }} />
            </Grid>
            <Grid item md={4}>
              <Skeleton variant="rectangular" style={{ width: '100%', height: '15vh' }} />
            </Grid>
            <Grid item md={4}>
              <Skeleton variant="rectangular" style={{ width: '100%', height: '15vh' }} />
            </Grid>
          </Grid>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>Backups</Typography>
          <Box className={styles.headerBtns}>
            <DefaultButton
              onClick={() => {
                navgiate(`/app/backup-restore/restore-sessions/list?projectId=${projectId}`);
              }}
            >
              Restore Sessions
            </DefaultButton>
            <Box width={10} />
            <DefaultButton
              onClick={() => {
                navgiate(`/app/backup-restore/create?projectId=${projectId}`);
              }}
            >
              <AddIcon />
              Create a Backup Point
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={styles.root}>
        <Box>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Backups" value={0} />
            <Tab disabled={!isSystemAdmin} label="Universal Templates" value={1} />
          </Tabs>
          <Box height={20} />
          <SearchBox>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by name, tags, devices"
              onKeyUp={(event: any) => {
                onKeywordChange(event.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchBox>
        </Box>
        <Box height={20} />
        {activeTab === 0 ? (
          <Grid container spacing={2}>
            {backups.map((backup, index) => (
              <Grid item md={4} sm={12}>
                <BackupCard
                  isSysAdmin={isSystemAdmin}
                  title={backup.name}
                  desc={backup.description}
                  date={backup.updated_at}
                  serial={backup.backup_serial}
                  type={type}
                  refetch={refetch}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {templates.map((template, index) => (
              <Grid item md={4} sm={12}>
                <BackupCard
                  isSysAdmin={isSystemAdmin}
                  title={template.name}
                  desc={template.description}
                  date={template.updated_at}
                  serial={template.backup_serial}
                  type={type}
                  refetch={refetch}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Layout>
  );
};

export default BackupList;
