/* eslint-disable camelcase */
export interface TemplateConfigMap {
  device_collection_map: {
    pro_dc_map: {[key: string]: ProDCConfig};
    air_dc_map: {[key: string]: AirDCConfig};
    air_device_map: {
      [air_dc_id: string]: {
        [air_device_id: string]: AirDeviceConfig;
      };
    };
  };
  backup_point: any;
}

export interface ProDCConfig {
  serial_name: string;
  name: string;
  description: string;
  dc_type: number;
  dc_status: number;
}

export interface AirDCConfig {
  email: string;
  name: string;
  country_code: string;
  description: string;
  adc_type: number;
  adc_status: number;
}

export interface AirDeviceConfig {
  id: string;
  airdc_id: string;
  tuya_device_id: string;
  name: string;
  tags: string[];
  shadow_type: string;
  cat: string;
  sub_cat: string;
  device_type: string;
}

export interface ILocationCreatPayloadFromTemplate {
  backup_serial: string;
  project_info: {
    project_name: string;
    project_description: string;
    demo: boolean;
  };
  pro_dc_map: {
    [dc_id: string]: {
      serial_name: string;
      display_name: string;
      description: string;
      type: number;
      notDirty: boolean;
    };
  };
  air_dc_map: {
    [air_dc_id: string]: {
      email: string;
      display_name: string;
      description: string;
      type: number;
      password: string;
      devices: {
        [old_air_id: string]: string;
      };
      notDirty: boolean;
    };
  };
}

export interface BackupRestoreState {
  pro_dc_map: {
    [pro_dc_id: string]: string;
  };
  air_dc_map: {
    [air_dc_id: string]: string;
  };
  air_device_map: {
    [air_dc_id: string]: {
      [air_device_id: string]: string;
    };
  };
}

export enum BACKUP_RESTORE_STEP_TYPES {
  LOCATION_VARIABLES_SETUP = 'location_variables',
  PRO_DC_SETUP = 'pro_dc_setup',
  AIR_DC_SETUP = 'air_dc_setup',
  AIR_DC_DEVICE_SETUP = 'air_dc_setup.devices',
  UV_SETUP = 'uv_setup',
  UV_ITEM_SETUP = 'userview_items',
  LOGIC_SETUP = 'logic_setup',
  NODE_RED = 'node_red',
}
