/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Button, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import styles from './VideoDisplaySelector.module.css';
import { UVDeviceFinder } from '../UVDeviceFinder/UVDeviceFinder';
import { ICommDevice } from '../UVDeviceFinder/types';
import { IUserViewItem } from '../../types/types';
import { ITEM_DEFAULTS } from '../../../../config/deviceConfig/item_defaults';
import { UVAudioSelector } from '../AudioSelector/AudioSelector';

type ComponentProps = {
  fieldConfig: any;
  uvItem: IUserViewItem | null;
  onChange: (payload:any) => void;
  updateCustomFieldErrors: (error: any) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVVideoDisplaySelector: React.FunctionComponent<ComponentProps> = (props) => {
  const hdmiPorts = ITEM_DEFAULTS.TV_HDMI_PORTS;

  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState<string>('');
  const [selectedVideoDevice, setVideoDevice] = useState<ICommDevice | null>(null);
  const [selectedHdmi, selectHdmi] = useState<string>('');

  useEffect(() => {
    if (props.uvItem && props.uvItem.settings && props.uvItem.settings.video_display_selector) {
      const videoDevice = props.uvItem.settings.video_display_selector;
      if (videoDevice.device_id && selectedVideoDeviceId !== videoDevice.device_id) {
        setSelectedVideoDeviceId(videoDevice.device_id);
        selectHdmi(videoDevice.hdmi_port);
      }
    }
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.uvItem]);
  useEffect(() => {
    // if(selectedHdmi == null || selectedHdmi.length == 0) return;

    if (props.fieldConfig == null) {
      return;
    }
    let data: any = {};
    const errors: any = {};
    if (selectedVideoDevice) {
      data = {
        video_display_selector: {
          device_id: selectedVideoDevice.id,
          hdmi_port: selectedHdmi,
        },
      };
    } else {
      data = {
        video_display_selector: {},
      };
    }
    props.onChange({
      action: 'data',
      form: 'video_display_selector',
      data,
      errors,
    });
  }, [selectedHdmi]);

  useEffect(() => {
    if (props.fieldConfig == null) {
      return;
    }
    let data: any = {};
    const errors: any = {};
    if (selectedVideoDevice) {
      data = {
        video_display_selector: {
          device_id: selectedVideoDevice.id,
          hdmi_port: selectedHdmi,
        },
      };
    } else {
      data = {
        video_display_selector: {},
      };
    }
    props.onChange({
      action: 'data',
      form: 'video_display_selector',
      data,
      errors,
      clean_logic: true,
    });
  }, [selectedVideoDevice]);

  const setNewVideoDevice = (device: ICommDevice, isManually: boolean) => {
    if (device == null || !device.id || !device.settings) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    device.settings = JSON.parse(device.settings);

    if (isManually) {
      if (hdmiPorts.length > 0) {
        selectHdmi(hdmiPorts[0].value);
      }
    }

    setSelectedVideoDeviceId(device.id);
    setVideoDevice(device);
    props.updateCustomFieldErrors({
      video_display_selector: {
        invalid: false,
      },
    });
  };
  const removeDevice = () => {
    setSelectedVideoDeviceId('');
    setVideoDevice(null);
  };

  function buildView() {
    if (selectedVideoDevice && selectedVideoDevice.cat === 'ac') {
      return (
        <UVAudioSelector
          key="video_display_selector"
          onChangePayloadRootKey="video_display_selector"
          fieldConfig={props.fieldConfig}
          uvItem={props.uvItem}
          onChange={(payload: any) => {
            props.onChange(payload);
            // appendSettings(payload);
          }}
          label="Video Display Selector"
          device={selectedVideoDevice}
          hideFinder
          updateCustomFieldErrors={(error: any) => {
            props.updateCustomFieldErrors(error);
          }}
        />
      );
    } if (selectedVideoDevice) {
      return (
        <div className="row">
          <div className="col-md-3 pl-12">
            <FormControl
              size="small"
              variant="outlined"
              className="dynamic-ui-form-control-full-width"
            >
              <InputLabel id="hdmi-custom_selector-label1">Select HDMI Port</InputLabel>
              <Select
                labelId="hdmi-custom_selector-label1"
                value={selectedHdmi}
                name="hdmi-custom_selector"
                error={selectedVideoDevice && (selectedHdmi == null || selectedHdmi.length === 0)}
                onChange={(e: any) => {
                  selectHdmi(e.target.value);
                }}
                label="Select HDMI Port"
              >
                {hdmiPorts.map((item, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-12 pl-12">
            <Button onClick={removeDevice} color="primary">
              Remove Device
              <Delete fontSize="large" />
            </Button>
          </div>
        </div>
      );
    }
    return <></>;
  }

  return (
    <div className={`${styles.root} root-border`}>
      <UVDeviceFinder
        // eslint-disable-next-line react/destructuring-assignment
        systemTags={props.fieldConfig.system_tags}
        defaultValue={selectedVideoDeviceId.length > 0 ? `pro_${selectedVideoDeviceId}` : ''}
        label="Video device"
        withCustomSearch={false}
        defaultDevice={selectedVideoDevice}
        withBorder={false}
        onDeviceSelected={(device, isManually) => {
          setNewVideoDevice(device, isManually);
        }}
      />
      {buildView()}
    </div>
  );
};
