/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { IAirDevice } from '../../containers/app/AirDeviceCreateEdit/types';
import { appConsoleLog } from '../../utility/appUtils';
import { gqlAirDCTuyaDeviceListByUsername, gqlSearchAirDevices } from './graphql';

const airDCGetGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const parsed_itemd_id = item_id;
  const res = await apolloClient.query({
    query: gql`
      query {
        airDCGet(
              airdc_id : "${parsed_itemd_id}",
        ){
          airDeviceCollection{
             id
              created_by
              creator_email
              project_id
              country_code
              tuya_userid
              name
              description
              email
              image
              adc_type
              adc_status
              created_at
              updated_at
          }
        }
        }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

export const airDCTuyaDeviceListByUsername = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  username: string,
  password: string,
  country_code: string,
) => {
  const res = await apolloClient.query({
    query: gqlAirDCTuyaDeviceListByUsername,
    variables: {
      username,
      password,
      country_code,
    },
  });
  return res.data;
};

const airDCTuyaDeviceListGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  airdc_id: string,
): Promise<any> => {
  const parsed_airdc_id = airdc_id;
  const res = await apolloClient.query({
    query: gql`
      query {
        airDCTuyaDeviceList(
              airdc_id : "${parsed_airdc_id}",
        ){
          items{
            active_time
            biz_type
            category
            create_time
            icon
            id
            ip
            local_key
            model
            name
            node_id
            online
            owner_id
            product_id
            product_name
            status
            sub
            time_zone
            uid
            update_time
            uuid
          }
        }
        }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const airDCSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  adc_type: number,
  adc_status: number,
  keyword: string,
  limit: number,
  skip: number,
): Promise<any> => {
  const res = await apolloClient.query({
    query: gql`
      query {
        airDCSearch(
          project_id:"${project_id}",
          adc_type:${adc_type},
          adc_status:${adc_status},
          keyword :"${keyword}",
          limit: ${limit},
          skip: ${skip},
        ){
          limit
          skip
          resultCount
          result{
            id
            created_by
            creator_email
            project_id
            country_code
            tuya_userid
            name
            description
            email
            image
            adc_type
            adc_status
            created_at
            updated_at
          }
          }
        }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const airDCSendVerificationGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  email: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
            airDCSendVerification(
                input:{
                  email: "${email}",
                }
            ){
              email
              status
            }
          }
        `,
  });
  appConsoleLog(res);
  return res;
};

const airDCCreateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  email: string,
  code: string,
  project_id: string,
  password: string,
  country_code: string,
  name: string,
  description: string,
  adc_type: number,
  adc_status: number,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
            airDCCreate(
                input:{
                  email: "${email}",
                  code: "${code}",
                  project_id: "${project_id}",
                  password: "${password}",
                  country_code:"${country_code}",
                  name: "${name}",
                  description:"${description}",
                  adc_type:${adc_type},
                  adc_status: ${adc_status},
                }
            ){
              id
              created_by
              creator_email
              project_id
              country_code
              tuya_userid
              name
              description
              email
              image
              adc_type
              adc_status
              created_at
              updated_at
            }
          }
        `,
  });
  appConsoleLog(res);
  return res;
};

const airDCUpdateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
  name: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation AirDCUpdate($input: AirDeviceCollectionUpdateIn) {
          airDCUpdate(input: $input) {
            id
            update_status
            updated_at
          }
        }
      `,
    variables: {
      input: {
        item_id,
        item: {
          name,
        },
      },
    },
  });
  return res.data;
};

const airDCDeleteGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
            airDCDelete(
            item_id: "${item_id}",
          ){
            id
            delete_status
        }
      }
        `,
  });
  return res.data;
};

const airDeviceDeleteGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
      mutation {
        airDeviceDelete(
          item_id: "${item_id}",
        ){
          id
          delete_status
      }
    }
      `,
  });
  return res.data;
};

const airDeviceSearch = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
  AirDCId: string,
  tags: string[],
  keyword: string,
  limit: number,
  skip: number,
): Promise<{limit: number; skip: number; result: IAirDevice[]}> => {
  const res = await apolloClient.query({
    query: gqlSearchAirDevices,
    variables: {
      project_id: projectId,
      adc_id: AirDCId,
      tags,
      keyword,
      limit,
      skip,
    },
  });

  return res.data.airDeviceSearch;
};

export {
  airDCGetGraphQL,
  airDCSearchGraphQL,
  airDCSendVerificationGraphQL,
  airDCCreateGraphQL,
  airDCUpdateGraphQL,
  airDCDeleteGraphQL,
  airDCTuyaDeviceListGraphQL,
  airDeviceDeleteGraphQL,
  airDeviceSearch,
};
