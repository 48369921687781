import React from 'react';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import IconButton from '@mui/material/IconButton';
import styles from './UVDragSelector.module.css';

export interface IUVDragSelectorItem {
  id: string;
  title: string;
  subTitle: string;
  payload?: any;
}
type ComponentProps = {
  item: IUVDragSelectorItem;
  dragStartHandler: (payload: any) => void;
  removeItemHandler: (payload: any) => void;
  type: string;
  itemIndex: number;
  withArrow?: boolean;
  deleteIcon?: any;
  isSelected?: boolean;
};

export const DragSelectorCardItem: React.FunctionComponent<ComponentProps> = ({
  item,
  type,
  itemIndex,
  withArrow,
  dragStartHandler,
  removeItemHandler,
  isSelected = false,
  deleteIcon = <ArrowRightAlt fontSize="large" />,
}) => {
  const startDragging = (event: any) => {
    // appConsoleLog("startDragging", event);
    dragStartHandler({ item, type, index: itemIndex });
  };

  return (
    <div
      className={`${styles.DragSelectorItemCard} ${isSelected ? ' uv-item-selected ' : ''}`}
      draggable
      onDragStart={startDragging}
    >
      <div>
        <p className={`${styles.title} noselect1`}>
          {item.title}
          {item.payload.dc_name ? (
            <span>
              (
              {item.payload.dc_name}
              )
            </span>
          ) : <span />}
        </p>
        <span className={styles.subTitle}>{item.subTitle}</span>
      </div>
      <div className={styles.spacer} />
      {type === 'selected' ? (
        <div>
          <IconButton
            onClick={() => {
              removeItemHandler(item);
            }}
            size="large"
          >
            {deleteIcon}
          </IconButton>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

DragSelectorCardItem.defaultProps = {
  withArrow: false,
  deleteIcon: null,
  isSelected: false,
};
