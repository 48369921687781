/* eslint-disable import/prefer-default-export */
export const UV_COLORS = [
  '#FFFFFF',
  '#D5F5E3',
  '#E5E7E9',
  '#D6EAF8',
  '#D4E6F1',
  '#A9CCE3',
  '#F2D7D5',
  '#E6B0AA',
  '#D5F5E3',
  '#A2D9CE',
  '#FCF3CF',
  '#FDEBD0',
  '#F5CBA7',
  '#D7BDE2',
  '#EBDEF0',
];
