/* eslint-disable camelcase */
import React, { useContext, useEffect, useReducer } from 'react';

import { ApolloProvider } from '@apollo/client';
import { AuthContext } from '../Auth/AuthContext';
import { ApolloFunctions } from './ApolloFunctions';
import { IAuthProfile } from './types';
import { appConsoleError, cloneObject } from '../../utility/appUtils';

const getInitialAuthState = () => ({
  isIntialized: false,
  apollo_client: ApolloFunctions.createOpenApolloClient(),
  user_profile: {
    id: -1,
    fb_uid: '',
    user_name: '',
    profile_photo: '',
    email: '',
    invited_by: '',
    user_status: '',
    created_at: 0,
    updated_at: 0,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

export const ApolloAuthContext = React.createContext<IAuthProfile>(getInitialAuthState());

export enum profileReducerTypes {
  INITIALISE = 'INITIALISE',
  UPDATE_APOLLO_PROFILE = 'UPDATE_APOLLO_PROFILE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO',
}
const profileReducer = (state: IAuthProfile, action: {type: string; payload: any}) => {
  const { type, payload } = action;

  switch (type) {
    case 'INITIALISE': {
      const initState = getInitialAuthState();
      return {
        ...state,
        ...initState,
        isIntialized: true,
      };
    }
    case profileReducerTypes.UPDATE_APOLLO_PROFILE: {
      return {
        ...state,
        ...payload,
        isIntialized: true,
      };
    }
    case 'UPDATE_PROFILE': {
      const user_profile = cloneObject(state.user_profile);
      return {
        ...state,
        user_profile: {
          ...user_profile,
          ...payload.user_profile,
        },
        isIntialized: true,
      };
    }
    case 'UPDATE_USER_PHOTO': {
      const user_profile = cloneObject(state.user_profile);
      user_profile.profile_photo = payload;
      return { ...state, user_profile, isIntialized: true };
    }
    default: {
      return { ...state };
    }
  }
};

export function ApolloProviderComponent({ children, ...props }: any) {
  const authUser = useContext(AuthContext);

  const [state, dispatch] = useReducer(profileReducer, getInitialAuthState());
  useEffect(() => {
    try {
      if (authUser == null) {
        dispatch({ type: profileReducerTypes.INITIALISE, payload: {} });
      } else {
        // eslint-disable-next-line no-debugger
        const apolloClient = ApolloFunctions.createAuthApolloClient(authUser.access_token.token);

        dispatch({
          type: profileReducerTypes.UPDATE_APOLLO_PROFILE,
          payload: {
            apollo_client: apolloClient,
            user_profile: authUser.profile,
          },
        });
      }
    } catch (err) {
      appConsoleError(err);
    }
  }, [authUser]);

  if (!state.isIntialized) {
    return <>Loading...</>;
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ApolloAuthContext.Provider value={{ ...state, dispatch }}>
      <ApolloProvider client={state.apollo_client}>{children}</ApolloProvider>
    </ApolloAuthContext.Provider>
  );
}
