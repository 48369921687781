/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Radio option use when creating project for select project type/ template
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the custom radio component which use in location create step to
 * choose an template
 * @author Mayantha
 * @since 1.0.0
 */
import React from 'react';
import styles from './CreateLocationSelector.module.css';
import notSelectedIcon from '../../../assets/images/icons/checkmark-not-selected.png';
import selectedIcon from '../../../assets/images/icons/checkmark-selected.png';
import selectedDisabledIcon from '../../../assets/images/icons/checkmark-selected-disabled.png';

type ComponentProps = {
  title: string;
  description: string;
  selected: boolean;
  onClick: any;
  disabled: boolean;
  selectedDisabled: boolean;
};

/**
 * Location template selector option button
 *
 * @callback onRadioToggleCB
 * @param {Object} props                    component props
 * @param {String} props.title              Text to display inside the radio component
 * @param {String} props.description        Additional text to display in the radio component
 * @param {Boolean} props.selected          Flag that use to determine if the radio is
 * selected or not
 * @param {onRadioToggleCB} props.onClick   Fires when user click the radio button
 * @param {Boolean} props.selectedDisabled  Flag that determine if the button is clickable or not
 *
 * @returns JSX.Element
 */
const CreateLocationSelector: React.FunctionComponent<ComponentProps> = ({
  title, description, selected, onClick, disabled, selectedDisabled,
}) => (
  <div
    onKeyDown={(e) => {
      if (e.key === '13' && !disabled) {
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
    onClick={disabled ? null : onClick}
    className={
          // eslint-disable-next-line no-nested-ternary
          `${disabled
            ? styles.projectTyleSelectorDisabled
            : selected
              ? styles.projectTyleSelectorSelected
              : styles.projectTyleSelectorNone
          } noselect ${
            styles.root}`
        }
  >
    <div className={styles.selectCont}>
      <div className={styles.iconMain}>
        {selectedDisabled ? (
          <img
            alt={title}
            className={styles.iconStyle}
            src={selectedDisabledIcon}
            height="25px"
          />
        ) : (
          <img
            alt={title}
            className={styles.iconStyle}
            src={selected ? selectedIcon : notSelectedIcon}
            height="25px"
          />
        )}
      </div>
      <div className={styles.textCont}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
      </div>
      <div className="flexSpacer" />
    </div>
  </div>
);

export default CreateLocationSelector;
