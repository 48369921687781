/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Service file or userview API operations
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define userview services
 * @author Maduka Dilshan
 * @since 0.0.1
 */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import { IAirDevice } from '../../../containers/app/AirDeviceCreateEdit/types';
import { IProDevice } from '../../../containers/app/ProDeviceCreateEdit/types';
import { IUserViewItem, IUserviewPathItems } from '../types/types';
import { ICommDevice } from '../UIItems/UVDeviceFinder/types';
import {
  gqlDeleteFolder,
  gqlDeleteItem,
  gqlDeviceState,
  gqlGetUserviewItemFullDetails,
  gqlSearchProjectCloudDevice,
  gqlSearchProjectDevice,
  gqlSearchUserviewItems,
  gqlUpdateFolder,
  gqlUpdateItem,
  gqlUserviewItemCreate,
  gqlUserviewListFolderItems,
  ITEM_ORDER_GET,
  ITEM_ORDER_SET_ALL,
} from './graphql';

/**
 * Get userview item by id
 * @param {Object} apolloClient   ApolloClient instance
 * @param {String} userviewitemId Userview id
 * @returns Promise<Object>
 */
const userviewItemGet = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  userviewitemId: string,
): Promise<{
  item: IUserViewItem;
  proDevice: ICommDevice;
  airDevice: ICommDevice;
  cintDevice: CiDevice;
}> => {
  const res = await apolloClient.query({
    query: gqlGetUserviewItemFullDetails,
    variables: {
      userview_item_id: userviewitemId,
    },
  });

  const { item } = res.data.userviewItemGet;
  const proDevice: ICommDevice = res.data.userviewItemGet.pro_device;
  const airDevice: ICommDevice = res.data.userviewItemGet.air_device;
  const cintDevice: CiDevice = res.data.userviewItemGet.cint_device;
  return {
    item, proDevice, airDevice, cintDevice,
  };
};

const userviewListFolderItems = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  userviewId: string,
  folderId: string,
  limit: number,
  skip: number,
): Promise<IUserviewPathItems> => {
  const res = await apolloClient.query({
    query: gqlUserviewListFolderItems,
    variables: {
      userview_id: userviewId,
      folder_id: folderId,
      limit,
      skip,
    },
  });

  return res.data.userviewListFolderItems;
};

export const userViewGetOrder = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  parentRef: string,
  orderFor: number,
) => {
  const res = await apolloClient.query({
    query: ITEM_ORDER_GET,
    variables: {
      parent_ref: parentRef,
      order_for: orderFor,
    },
  });
  return res;
};

export const userViewOrderSetAll = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
  parentRef: string,
  orderFor: number,
  itemIds: string[],
) => {
  // eslint-disable-next-line no-debugger
  await apolloClient.mutate({
    mutation: ITEM_ORDER_SET_ALL,
    variables: {
      project_id: projectId,
      parent_ref: parentRef,
      order_for: orderFor,
      item_ids: itemIds,
    },
  });
};

export const searchProjectDevice = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
  systemTags: string[],
  tags: string[],
  keyword: string,
  airDeviceIds: string[],
  proDeviceIds: string[],
  cintDeviceIds: string[],
  limit: number,
  skip: number,
// eslint-disable-next-line camelcase
): Promise<{pro_devices: IProDevice[]; air_devices: IAirDevice[]; cint_devices: CiDevice[]}> => {
  const res = await apolloClient.query({
    query: gqlSearchProjectDevice,
    variables: {
      project_id: projectId,
      system_tags: systemTags,
      tags,
      keyword,
      air_device_ids: airDeviceIds,
      pro_device_ids: proDeviceIds,
      cint_device_ids: cintDeviceIds,
      limit,
      skip,
    },
  });

  return res.data.projectDeviceSearch;
};

export const searchProjectCloudDevice = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectId: string,
  systemTags: string[],
  tags: string[],
  keyword: string,
  cintDeviceIds: string[],
  limit: number,
  skip: number,
// eslint-disable-next-line camelcase
): Promise<{cint_devices: CiDevice[]}> => {
  const res = await apolloClient.query({
    query: gqlSearchProjectCloudDevice,
    variables: {
      project_id: projectId,
      system_tags: systemTags,
      tags,
      keyword,
      cint_device_ids: cintDeviceIds,
      limit,
      skip,
    },
  });

  return res.data.projectDeviceSearch;
};

const searchUserviewItemsInUserview = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  userviewId: string,
  itemTypes: string[],
  uvitemIds: number[],
  limit: number,
  skip: number,
): Promise<{
  limit: number;
  skip: number;
  resultCount: number;
  result: IUserViewItem[];
}> => {
  const res = await apolloClient.query({
    query: gqlSearchUserviewItems,
    variables: {
      userview_id: userviewId,
      item_types: itemTypes,
      uv_item_ids: uvitemIds,
      limit,
      skip,
    },
  });

  return res.data.userviewSearchItems;
};

const getDeviceState = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  type: string,
  deviceId: string,
): Promise<{state: string}> => {
  const res = await apolloClient.query({
    query: gqlDeviceState,
    variables: {
      type,
      device_id: deviceId,
    },
  });

  return res.data.deviceState;
};

const userviewUpdateShortcut = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  folderId: string,
  item: IUserViewItem,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gqlUpdateItem,
    variables: {
      item_id: folderId,
      item: {
        display_name: item.display_name,
        item_visibility: item.item_visibility,
        icon_name: item.icon_name,
        item_color: item.item_color,
      },
    },
  });
  return res.data.userviewItemFolderUpdate;
};

const userviewUpdateFolder = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  folderId: string,
  item: IUserViewItem,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gqlUpdateFolder,
    variables: {
      item_id: folderId,
      item: {
        display_name: item.display_name,
        item_visibility: item.item_visibility,
        item_notification_type: item.item_notification_type,
        rule_name: item.rule_name,
        macro_name: item.macro_name,
        icon_name: item.icon_name,
        item_color: item.item_color,
      },
    },
  });
  return res.data.userviewItemFolderUpdate;
};

const userviewUpdateItem = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  itemId: string,
  item: IUserViewItem,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gqlUpdateItem,
    variables: {
      item_id: itemId,
      item: {
        display_name: item.display_name,
        item_visibility: item.item_visibility,
        item_notification_type: item.item_notification_type,
        rule_name: item.rule_name,
        macro_name: item.macro_name,
        icon_name: item.icon_name,
        item_color: item.item_color,
        settings: item.settings,
        config: item.config,
      },
    },
  });
  return res.data.userviewItemFolderUpdate;
};

const userviewItemCreate = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item: IUserViewItem,
): Promise<IUserViewItem> => {
  const res = await apolloClient.mutate({
    mutation: gqlUserviewItemCreate,
    variables: {
      payload: item,
    },
  });
  return res.data.userviewItemCreate;
};

const userviewFolderDelete = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  folderId: string,
): Promise<boolean> => {
  await apolloClient.mutate({
    mutation: gqlDeleteFolder,
    variables: {
      userview_item_id: folderId,
    },
  });
  return true;
};

const userviewItemDelete = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  folderId: string,
): Promise<boolean> => {
  await apolloClient.mutate({
    mutation: gqlDeleteItem,
    variables: {
      userview_item_id: folderId,
    },
  });
  return true;
};
export default {
  userviewListFolderItems,
  userviewItemCreate,
  searchProjectDevice,
  searchProjectCloudDevice,
  userviewUpdateFolder,
  userviewUpdateItem,
  userviewFolderDelete,
  userviewItemDelete,
  userviewItemGet,
  searchUserviewItemsInUserview,
  getDeviceState,
  userviewUpdateShortcut,
};
