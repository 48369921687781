import { memo, useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { AuthContext } from '../Auth/AuthContext';
import PluginHook from 'src/plugins';
import { EventType } from 'src/plugins/shared/PluginTypes';

export const pluginHook = new PluginHook(null);

const PluginProvider: React.FC<any> = ({ children }) => {
  const auth = useContext(AuthContext);
  useLayoutEffect(() => {
    pluginHook.EventManager.notify(EventType.ON_NEW_AUTH_TOKEN, auth.access_token.token);
  });
  return <>{children}</>;
};

export default PluginProvider;
