import {
  Box, IconButton, Tooltip, Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import useStepSummery from './hooks/useStepSummery';
import useRestoreHelpers from './hooks/useRestoreHelpers';

// eslint-disable-next-line max-len
// {"donePercentage":100,"totalCollections":1,"totalItems":2,"totalRemItems":0,"pendingItems":0,"errorItems":0,"completedItems":2,"pendingComps":{},"errorComps":{},"collections":{},"extra_config":{"folder_item_order":[]}}

const RestoreStepBottomSummery = ({ sessionId, type, prefix = '' }) => {
  const stepSummery = useStepSummery();
  const { downloadReport } = useRestoreHelpers();
  return (
    <Box style={{ marginBottom: 10 }}>
      <Typography style={{ fontFamily: 'monospace' }}>
        {prefix}
        {' '}
        Done Percentage:
        <span style={{ fontWeight: 700, fontSize: 15, fontFamily: 'monospace' }}>
          {stepSummery[type].donePercentage}
          %
        </span>
        <span>
          <Tooltip title="Refresh stats">
            <IconButton
              size="small"
              onClick={() => {
              // downloadReport(sessionId, type);
                stepSummery.reCheckStats(sessionId, type);
              }}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
        <span>
          <Tooltip title="Download Report">
            <IconButton onClick={() => {
              downloadReport(sessionId, type);
            }}
            >
              <DownloadIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      </Typography>
    </Box>
  );
};

export default RestoreStepBottomSummery;
