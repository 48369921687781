import React, { useEffect, useRef, useState } from 'react';
import { CLOUD_INTEGRATIONS, CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import {
  Box, FormControl, Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useCloudDeviceMap } from 'src/plugins/shared/hooks/useCloudDeviceMap';
import { useCloudDeviceMapFormData } from 'src/plugins/shared/hooks/useCloudDeviceMapFormData';
import { FieldMapComponent } from 'src/plugins/shared/react-components/FieldMapComponent';
import NotificationMap from 'src/plugins/shared/react-components/NotificationMap';
import { itemConfig } from '../item-config';
import { Component } from '../../shared/uvitem.config';

const UserViewCreateExtraInternal: React.FC<{
  selectedDevice: CiDevice & {dc_type: string};
  uvItem: any;
  appendSettings: (payload: any) => void;
  updateCustomFieldErrors?: (
    payload: {
      [key: string]: {
        invalid?: boolean;
        message?: string;
      };
    },
    forceReset?: boolean
  ) => void;
}> = ({
  selectedDevice, uvItem, appendSettings, updateCustomFieldErrors
}) => {
  const {
    settings, itemMaps, overrideSettingType, setOverrideSettingType,
  } = useCloudDeviceMap(
    selectedDevice,
    CLOUD_INTEGRATIONS.SHELLY,
    itemConfig,
    (allConfig, selectedDevice, overrideSettingType) => {
      if (overrideSettingType) {
        if (overrideSettingType == 'sensor') {
          return allConfig.sensor;
        }
        if (overrideSettingType == 'relay') {
          return allConfig.relay;
        }
      }
      if (selectedDevice.cat === 'relay') {
        return allConfig.relay;
      }
      if (
        selectedDevice.cat === 'sensor'
        || selectedDevice.cat === 'emeter'
        || selectedDevice.cat === 'inputs_reader'
      ) {
        return allConfig.sensor;
      }
      if (selectedDevice.cat === 'light') {
        return allConfig.dimmer;
      }
    },
  );

  
  useEffect(() => {
    if (uvItem?.settings?.client_side_settings?.used_as) {
      setOverrideSettingType(uvItem?.settings?.client_side_settings?.used_as);
    }
  }, [uvItem]);

  const {
    selectedMap,
    selectedMapId,
    finalSettings,
    filledTagMap,
    notifications,
    setNotifications,
    setFilledTagMap,
    setSelectedMapId,
  } = useCloudDeviceMapFormData(itemMaps, settings, selectedDevice, uvItem, overrideSettingType);

  const validatefinalSettings = (settings: any) => {
    if (!settings) {
      return {
        invalid: true,
        message: 'Invalid Mapping Fields',
      }
    }
    if (Object.keys(selectedMap.ui_tags).length !== Object.keys(settings.ui_data).length) {
      return {
        invalid: true,
        message: 'Please fill all channel mappings.',
      }
    }

    return {
      invalid: false,
    }
  }

  useEffect(() => {
    if (!finalSettings) {
      updateCustomFieldErrors({
        shelly_mappings: {
          ...validatefinalSettings(finalSettings),
        }
      });
      return;
    }

    if (finalSettings) {
      if (finalSettings.client_side_settings) {
        finalSettings.client_side_settings.used_as = overrideSettingType;
      } else {
        finalSettings.client_side_settings = {
          used_as: overrideSettingType,
        };
      }

      updateCustomFieldErrors({
        shelly_mappings: {
          ...validatefinalSettings(finalSettings),
        }
      });
      appendSettings({
        data: finalSettings,
      });
    }
  }, [finalSettings, overrideSettingType]);

  return (
    <Grid
      container
      spacing={1}
      style={{
        backgroundColor: '#EBF1F2',
        marginTop: 10,
        border: '1px solid #EBF1F2',
        padding: 10,
      }}
    >
      <Grid item md={4} style={{ paddingLeft: 0 }}>
        <FormControl size="small" style={{ width: '100%' }}>
          <InputLabel>Template Type</InputLabel>
          <Select
            value={overrideSettingType}
            label="Template Type"
            onChange={(e: any) => setOverrideSettingType(e.target.value)}
          >
            <MenuItem value={selectedDevice.cat}>
              Default (
              {selectedDevice.cat}
              )
            </MenuItem>
            {!(
              selectedDevice.cat === 'sensor'
              || selectedDevice.cat === 'emeter'
              || selectedDevice.cat === 'inputs_reader'
            ) ? (
              <MenuItem value="sensor">Use as a Sensor</MenuItem>
              ) : (
                <MenuItem value="relay">Use as a Switch</MenuItem>
              )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={8}>
        <FormControl size="small" style={{ width: '100%' }}>
          <InputLabel>Select an item map</InputLabel>
          <Select
            value={selectedMapId}
            label="Select an item map"
            onChange={(e: any) => setSelectedMapId(e.target.value)}
          >
            {Object.keys(itemMaps).map((key) => (
              <MenuItem key={key} value={key}>
                {itemMaps[key].componentName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={12}>
        <FieldMapComponent
          selectedMap={selectedMap}
          filledTagMap={filledTagMap}
          setFilledTagMap={setFilledTagMap}
          settings={settings}
        />
      </Grid>
      <Grid item md={12}>
        {selectedMap && (
          <NotificationMap
            notifications={notifications}
            setNotifications={setNotifications}
            settings={settings}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const UserViewCreateExtraFields: React.FC<{
  selectedDevice: CiDevice & {dc_type: string};
  uvItem: object;
  appendSettings: (payload: any) => void;
  updateCustomFieldErrors?: (
    payload: {
      [key: string]: {
        invalid?: boolean;
        message?: string;
      };
    },
    forceReset?: boolean
  ) => void;
}> = React.memo(({
  selectedDevice, uvItem, appendSettings, updateCustomFieldErrors,
}) => {
  return <UserViewCreateExtraInternal
    key={selectedDevice && selectedDevice.id}
    appendSettings={appendSettings}
    selectedDevice={selectedDevice}
    uvItem={uvItem}
    updateCustomFieldErrors={updateCustomFieldErrors}
  />
});
