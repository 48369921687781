/* eslint-disable no-useless-catch */
/* eslint-disable no-nested-ternary */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component use to list pro device
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to list pro device
 * @author Dinuka Bandara
 * @since -
 */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Chip, Stack, Switch, Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { cloneDeep } from 'lodash';
import proDeviceService from 'src/containers/app/ProDeviceCreateEdit/services/pro-device.service';
import styles from './DeviceCard.module.css';

import { UVConfirmDialog } from '../../UserViewComponents/UIItems/Dialogs/UVConfirmDialog/UVConfirmDialog';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import { IProDevice } from '../../../containers/app/ProDeviceCreateEdit/types';
import {
  proDeviceDeleteGraphQL,
  proDeviceGetGraphQL,
} from '../../../services/pro-device/pro-device.service';
import { ErrorSnackbarOptions, ErrorSnackbarActions } from '../../Alters/Snackbar/SnackbarOptions';
import { appApiErrorMessage } from '../../../utility/appUtils';
import { ITEM_DEFAULTS } from '../../../config/deviceConfig/item_defaults';

type ComponentProps = {
  device: IProDevice;
  dcCatType: any;
  dcName: any;
  refreshList: () => void;
  onClick: () => void;
  isKnxGatewayExist: boolean;
};

/**
 * Pro Device Card
 *
 * @callback onClickCard
 * @callback onRefrechCalled
 * @param {Object} props                      component props
 * @param {Object} props.device               device payload IProDevice
 * @param {String} props.dcCatType            device category
 * @param {onClickCard} props.onClick         Fires when user click the card button
 * @param {onRefrechCalled} props.refreshList Callback fires after user delete the device
 * @param {Boolean} props.disabled            Flag that determine if the button is clickable or not
 * @param {Boolean} isKnxGatewayExist
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const DeviceCard: React.FunctionComponent<ComponentProps> = ({
  device,
  dcName,
  dcCatType,
  refreshList,
  onClick,
  isKnxGatewayExist,
}) => {
  // console.log(device);
  const snackbar = useSnackbar();
  const userProfile = useContext(ApolloAuthContext);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [deleteState, setDeleteState] = useState<boolean>(false);
  const [deviceConfig, setDeviceConfig] = useState<{
    icon: string;
    title: string;
  }>({ icon: '', title: '' });
  useEffect(() => {
    const ITEMS_LIB: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
    if (ITEMS_LIB[device.cat] && ITEMS_LIB[device.cat].children[device.sub_cat]) {
      const conf = ITEMS_LIB[device.cat].children[device.sub_cat];
      setDeviceConfig(conf);
    }
  }, []);
  const navigateRoute = (path: string) => {
    navigate(path);
  };
  const deleteDeviceConfirm = async () => {
    try {
      if (!device.id) {
        throw new Error('Device id not valid');
      }

      await proDeviceDeleteGraphQL(userProfile.apollo_client, device.id);

      refreshList();
    } catch (err: any) {
      let errorMessage = 'Unexpected error';
      if (typeof err === 'string') {
        errorMessage = err;
      } else if (err && err.message) {
        errorMessage = appApiErrorMessage(err.message);
      }
      snackbar.enqueueSnackbar(errorMessage, {
        key: 'dc-device-delete-update-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuAction?: string) => {
    setAnchorEl(null);
    if (menuAction === 'delete') {
      setDeleteState(true);
    } else if (menuAction === 'edit') {
      navigateRoute(
        `/app/device-container/pro/update/device?projectId=${device.project_id}&dcId=${device.prodc_id}&deviceId=${device.id}&dcName=${dcName}&dcType=${dcCatType}&isKnxGatewayExist=${isKnxGatewayExist}&knxGateway=${device.sub_cat === 'knx_gateway'}`,
      );
    } else if (menuAction === 'view') {
      navigateRoute(
        `/app/device-container/device?projectId=${device.project_id}&dcType=pro&deviceId=${device.id}&isKnxGatewayExist=${isKnxGatewayExist}&knxGateway=${device.sub_cat === 'knx_gateway'}`,
      );
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  const [enabled, setEnabled] = useState(device.device_status !== 3);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const handleEnableDisable = async (newvalue: boolean) => {
    try {
      const type = newvalue ? 2 : 3;
      setLoadingToggle(true);
      const res = await proDeviceGetGraphQL(userProfile.apollo_client, device.id);
      const item = JSON.parse(JSON.stringify(res));

      const settings = JSON.parse(item.settings);
      if (typeof settings !== 'string') {
        item.settings = settings;
      } else {
        // eslint-disable-next-line no-useless-catch
        try {
          const settingObj = JSON.parse(settings);
          item.settings = settingObj;
        } catch (err) {
          console.log('err', err);
        }
      }

      // eslint-disable-next-line dot-notation
      item['device_status'] = type;
      console.log(item);
      // return;
      await proDeviceService.proDeviceUpdate(userProfile.apollo_client, item);
      setEnabled(newvalue);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoadingToggle(false);
    }
  };

  return (
    <div className={`${styles.root} col-md-12`}>
      <div className={styles.actionsMenu}>
        <Stack direction="row" spacing={1}>
          <Chip
            // eslint-disable-next-line no-nested-ternary
            label={enabled ? 'Active' : 'Disabled'}
            color={enabled ? 'primary' : 'warning'}
            variant="filled"
            size="small"
          />
          <Switch
            disabled={loadingToggle}
            defaultChecked
            size="small"
            checked={enabled}
            onChange={(e) => handleEnableDisable(e.target.checked)}
          />
        </Stack>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleClose('')}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem key="view" onClick={() => handleClose('view')}>
            Device Links
          </MenuItem>
          <MenuItem key="edit" onClick={() => handleClose('edit')}>
            Edit
          </MenuItem>
          <MenuItem key="delete" onClick={() => handleClose('delete')}>
            Delete
          </MenuItem>
        </Menu>
        <UVConfirmDialog
          dialogOpen={deleteState}
          dialogCloseEvent={(e: any) => {
            setDeleteState(false);
            if (e === true) {
              deleteDeviceConfirm();
            }
          }}
          title="Device Delete"
          text={`Are you sure you want to delete '${device.name},' device?`}
        />
      </div>
      <div
        role="button"
        tabIndex={0}
        className={styles.data}
        onClick={onClick}
        onKeyDown={(e) => e.key === '13' && onClick()}
      >
        <span
          className={`pl-8 calc calc-icon-uv-primary ${styles.imgBgStyle} ${deviceConfig.icon}`}
        />
        <div className={`${styles.type} ml-1`}>
          {device.name}
          {' '}
          <Tooltip title="Copy the device ID">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(device.id);
                snackbar.enqueueSnackbar('ID Coppied to Clipboard', {
                  variant: 'success',
                });
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={`${styles.type} ml-1`}>{deviceConfig.title}</div>
      </div>
    </div>
  );
};
