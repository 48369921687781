/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Base Layout/container for the content of the Dashboard Module.
 *
 * @author Maduka Dilshan
 */

import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    background: 'rgba(235, 241, 242, 0.1)', // "#EBF1F2",
    width: '100%',
    height: '100%',
    borderRadius: '13px',
    margin: 0,
    // opacity: 0.1,
    padding: '2%',
    display: 'flex',
    flexDirection: 'column',
  },
});

/**
 * Creating a Layout according to the defualt system theme.
 * This will not have any business logic. Use child components to define business logics
 * @param children React Children components
 * @returns
 */
const Layout: React.FC<{children: any}> = ({ children }) => {
  const styles = useStyles();
  return <Box className={styles.root}>{children}</Box>;
};

export default Layout;
