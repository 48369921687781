import { COMPONENT_UNIT_TYPES } from 'src/plugins/shared/item.config';
import { Component, Tag } from '../../shared/uvitem.config';

const commonTagFields = {
  main_key: null,
  command_key: null,
  can_send_command: true,
};

const dimmerTags: Record<string, Tag> = {
  bright_value: {
    type: COMPONENT_UNIT_TYPES.Slider,
    tag: 'bright_value',
    description: 'Brightness Channel',
    config: {
      steps: 1,
      min: 1,
      max: 100,
      scale: 1,
    },
    ...commonTagFields,
    command_v: 'v1',
    display_suffix: '%',
  },
  switch: {
    type: COMPONENT_UNIT_TYPES.Switch,
    tag: 'switch',
    description: 'Switch Channel',
    config: {
      mapping: {
        true: 'ON',
        false: 'OFF',
      },
      scale: 1,
    },
    ...commonTagFields,
    command_v: 'v1',
  },
};

const config: Record<string, Component> = {
  dimmer_map_1: {
    id: 'dimmer_map_1',
    componentName: 'Dimmer',
    created_at: '2023.04.10',
    default_inline_ui_data_map: null, // TODO: to be filled by @Mayantha
    default_inner_ui_data_map: null, // TODO: to be filled by @Mayantha
    ui_tags: dimmerTags,
    item_type: 'shelly.dimmer',
    ui_data_maps: {
      inline_maps: {}, // TODO: to be filled by @Mayantha
      inner_maps: {}, // TODO: to be filled by @Mayantha
    },
  },
};

export default config;
