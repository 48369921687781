/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Base Layout/container for Page Header.
 *
 * @author Maduka Dilshan
 */
import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles({
  root: {
    background: 'white',
    BorderBottom: '1px solid black',
    height: '90px',
    verticalAlign: 'middle',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    color: 'var(--primary-dark-green)',
    boxShadow: '2px 5px 8px rgba(4, 134, 150, 0.05)',
    width: '100%',
    justifyContent: 'center',
  },
});

/**
 * Define the container for the header of the main container.
 * use children components to define business logics.
 * @param children React children components
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const PageHeader: React.FC<{ children: any }> = ({
  children,
  ...rest
}) => {
  const styles = useStyle();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box className={styles.root} {...rest}>
      {children}
    </Box>
  );
};
