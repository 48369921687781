import { gql } from '@apollo/client';

export const gqlAirDeviceInfo = gql`
  query airDeviceInfo($airDCId: String, $airDeviceExternalId: String) {
    airDeviceInfo(airdc_id: $airDCId, external_device_id: $airDeviceExternalId) {
      active_time
      biz_type
      category
      create_time
      icon
      id
      ip
      local_key
      model
      name
      node_id
      online
      owner_id
      product_id
      product_name
      status
      functions {
        category
        functions {
          code
          desc
          name
          type
          values
        }
      }
      sub
      time_zone
      uid
      update_time
      uuid
    }
  }
`;

export const gqlAirDeviceCreate = gql`
  mutation AirDeviceCreate($payload: AirDeviceIn) {
    airDeviceCreate(input: $payload) {
      id
      created_by
      project_id
      airdc_id
      tuya_device_id
      name
      tags
      system_tags
      shadow_type
      cat
      sub_cat
      device_type
      device_status
      settings
      config
      created_at
      updated_at
    }
  }
`;

export const gqlAirDeviceUpdate = gql`
  mutation airDeviceUpdate($payload: AirDeviceUpdateIn) {
    airDeviceUpdate(input: $payload) {
      id
      update_status
      updated_at
    }
  }
`;

export const gqlAirDeviceGet = gql`
  query airDeviceGet($payload: String) {
    airDeviceGet(item_id: $payload) {
      airDevice {
        id
        created_by
        project_id
        airdc_id
        tuya_device_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        device_type
        device_status
        settings
        config
        created_at
        updated_at
      }
    }
  }
`;
