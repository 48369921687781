type ParsedDraggbleId = {
  queueIndex: string;
  mainActionId: string;
  cardType: string;
  childActionId: string;
};

type ParsedDroppableId = {
  queueIndex: string;
  mainActionId: string;
  cardType: string;
};

const actionDragDropIdResolver = <T extends 'dragable' | 'dropable'>(
  id: string,
): T extends 'dragable' ? ParsedDraggbleId : T extends 'dropable' ? ParsedDroppableId : unknown => {
  const splitted = id.split('__');

  const parsed: any = {};

  splitted.map((item) => {
    const [key, value] = item.split('=');
    parsed[key] = value;
    return 0;
  });

  // eslint-disable-next-line no-nested-ternary
  return parsed;
};

export default actionDragDropIdResolver;
