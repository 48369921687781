/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AirDevice,
  Check,
  DeviceCollectionSearchOut,
  DeviceCollectionSearchOutItem,
  LOGIC_CARD_VISIBILITY,
  OPERATORS,
  ProDevice,
} from '@smartlife-redux-state/common';
import { update_check } from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState, useSelector } from '../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../LogicStepper';
import { DeviceSelector } from '../shared/DeviceSelector';
import { ExpressionSelector } from '../shared/ExpressionSelector';
import ExsAlarmChildChildActions from '../shared/EsxAlarmController';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

const DeviceCheckForm: React.FC<{check: Check}> = ({ check }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dc = useSelector((state: RootState) => {
    if (!check) return null;
    const item = state.rule_engine.checks.content.find((checkItem) => checkItem.id === check.id);
    if (item) {
      return item.dc;
    }
    return null;
  });

  function onDcSelect(dcArg: DeviceCollectionSearchOutItem) {
    dispatch(
      update_check({
        check: {
          ...check,
          dc: dcArg,
        },
      }),
    );
  }

  function onDeviceSelect(device: AirDevice | ProDevice) {
    console.log('ON DEVICE SELECTED', device);
    dispatch(
      update_check({
        check: {
          ...check,
          val: null,
          variable: null,
          possible_variable_type: null,
          device: !device
            ? null
            : {
              ...device,
            },
        },
      }),
    );
  }

  function onExpressionChange(op: OPERATORS, value: any, variable: {field: string; type: string}) {
    if (variable && variable.type === 'esx') {
      const field = JSON.parse(variable.field);
      if (field[0] && field[0].state_value) {
        const checkDevice = check && check.device ? check.device : null;
        if (checkDevice && checkDevice.id) {
          const chanelVar = `${
            field[0].type === 'area'
              ? 'status_readiness'
              : field[0].type === 'area_state'
                ? 'status_alarm'
                : field[0].type === 'status'
                  ? 'system_comms_status'
                  : 'triggered'
          }`;

          let pathVar;
          if (field[0].chanel) {
            pathVar = `child_items/${field[0].type === 'area_state' ? 'area' : field[0].type}_${
              field[0].chanel < 10 ? `0${field[0].chanel}` : field[0].chanel
            }_${chanelVar}_${checkDevice.id}/state`;
          } else {
            pathVar = `child_items/${chanelVar}_${checkDevice.id}/state`;
          }

          dispatch(
            update_check({
              check: {
                ...check,
                op: OPERATORS.EQUAL,
                val:
                  field[0].type === 'area'
                    ? field[0].state_value.toUpperCase()
                    : field[0].state_value,
                possible_variable_type: variable.type,
                variable: pathVar,
                variable_data: variable,
                // meta: {
                //   ...check.meta,
                //   card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
                // },
              },
            }),
          );
        }
      }
    } else {
      dispatch(
        update_check({
          check: {
            ...check,
            op,
            val: value != null ? value : null,
            possible_variable_type: variable.type,
            variable: variable.field,
            // meta: {
            //   ...check.meta,
            //   card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
            // },
          },
        }),
      );
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceSelector
          selectedDC={dc}
          selectedDevice={check ? check.device : null}
          onDcSelect={onDcSelect}
          onDeviceSelect={onDeviceSelect}
          logic_step={LogicStepsTypes.CHECK}
        />
      </Grid>
      {check.device
      && (check.device.sub_cat === 'aapesx1a' || check.device.sub_cat === 'paradox_sp') ? (
        <Grid item xs={12} md={12}>
          <ExsAlarmChildChildActions
            action={check}
            alarmType={check.device.sub_cat}
            onChange={onExpressionChange}
          />
        </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <ExpressionSelector
              selectedOperator={check.op}
              selectedVariable={{
                field: check.variable,
                type: check.possible_variable_type,
              }}
              selecteValue={check.val}
              onChange={onExpressionChange}
              device={check.device}
              dc={dc}
            />
          </Grid>
        )}
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const DeviceCheck: React.FC<{check: Check}> = ({ check }) => {
  const classes = useStyles();
  const [dcs, setDcs] = useState<any[]>([]);
  const [devices, setDevices] = useState<any[]>([]);
  return (
    <Box>
      <DeviceCheckForm check={check} />
    </Box>
  );
};
