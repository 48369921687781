/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  Alert, Box, FormHelperText, Grid, IconButton, Tooltip, Typography,
} from '@mui/material';
import Editor from '@monaco-editor/react';
import { Action } from '@smartlife-redux-state/common';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef, useState } from 'react';
import { update_action } from 'src/store/redux/features/logic-engine';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dedent from 'dedent';
import { useFirebaseConnect } from 'react-redux-firebase';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { RootState } from 'src/store/redux/store';
import { isArray } from 'lodash';
import { AddVariable } from './AddVariable';
import { VarItem } from './VarItem';

// eslint-disable-next-line import/prefer-default-export
export const DirivedValueBuilder: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const dispatch = useDispatch();
  const [runFeedback, setRunFeedback] = useState<any>();
  const monacoRef = useRef(null);
  function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
  }

  function handleEditorDidMount(editor, monaco) {
    monacoRef.current = editor;
  }

  const items = useMemo(() => action.variable_value ?? [], [action]);

  function showValue() {
    const editorVal = action.payload && (action.payload as any).editorVal ? (action.payload as any).editorVal : '';
    const lhs = editorVal.split('=')[0];

    const rhs = editorVal.split('=')[1];

    if (!rhs) {
      setRunFeedback({
        msg: 'The right side of the expression is not found',
        code: 'error',
      });
      return;
    }

    if (lhs !== 'DerivedValue ' && lhs !== 'DerivedValue') {
      setRunFeedback({
        msg: 'The left side of the expression must be "DirivedValue = "',
        code: 'error',
      });
      return;
    }

    setRunFeedback({
      msg: 'The expression is valid',
      code: 'success',
    });
  }

  function handleEditorChange(value, event) {
    dispatch(
      update_action({
        action: {
          ...action,
          payload: {
            ...action.payload,
            editorVal: value,
          } as any,
        },
        isChild,
        parentAction,
      }),
    );
    setRunFeedback(null);
  }

  function handleRemoveVariable(index) {
    const filtered = action.variable_value.filter((_, i) => i !== index);
    dispatch(update_action({
      action: {
        ...action,
        variable_value: filtered,
      },
      isChild,
      parentAction,
    }));
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Box style={{ width: '90%' }}>
          {runFeedback && <Alert severity={runFeedback.code}>{runFeedback.msg}</Alert>}
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontWeight: '700', marginBottom: 0 }}>
            Build Your Expression
          </Typography>
          <Tooltip title="Test your expression">
            <IconButton onClick={showValue} size="small">
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Box height={10} />
          <Grid container spacing={1}>
            {items.map((item, index) => (
              <Grid
                item
                md={3}
                sm={4}
                xs={6}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <VarItem
                  item={item}
                  key={index}
                  onRemove={() => {
                    handleRemoveVariable(index);
                  }}
                />
              </Grid>
            ))}
            <Grid item md={2} sm={4}>
              <AddVariable
                action={action}
                isChild={isChild}
                parentAction={parentAction}
                onAddNewVariable={({
                  type,
                  varName,
                  firebasePath,
                  staticVarType,
                  staticVarValue,
                }) => {
                  const existingVariables = isArray(action.variable_value)
                    ? action.variable_value
                    : [];
                  dispatch(
                    update_action({
                      action: {
                        ...action,
                        variable_value: [
                          ...existingVariables,
                          type === 'static'
                            ? {
                              name: varName,
                              static_value: staticVarType
                                ? Number(staticVarValue)
                                : staticVarValue,
                            }
                            : {
                              firebasePath,
                              name: varName,
                            },
                        ],
                      },
                      isChild,
                      parentAction,
                    }),
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box height={10} />
        <div style={{ display: 'none' }}>
          <Editor
            theme="vs-dark"
            height="0"
            defaultLanguage="javascript"
            value={dedent`
            let DerivedValue;
          `}
          />
        </div>
        <Editor
          theme="vs-dark"
          height="5vh"
          width="90%"
          defaultLanguage="javascript"
          value={dedent`
                  ${
                    action.payload && (action.payload as any).editorVal
                      ? (action.payload as any).editorVal
                      : 'DerivedValue = '
                  }
              `}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
        />
      </Box>
      <Box height={10} />
      <Box style={{ width: '90%' }}>
        <Typography color="orangered">
          NOTE: You can only use (), +, -, *, / in the expression
        </Typography>
      </Box>
    </Box>
  );
};
