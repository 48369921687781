/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  FORM_DEBOUNCE_TIME,
  FORM_KEY_NEED_TO_DELETE,
} from '../../../../../config/deviceConfig/Values';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IDFField } from '../../../../../config/deviceConfig/types';
import { appConsoleError, appConsoleLog } from '../../../../../utility/appUtils';
import { FormResetAction, IFormResetRef } from '../../../ProDeviceCreateEdit/types';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.timeout;

type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  deviceInfo: any;
  onChange: (payload: any, isInitializing?: boolean) => void;
  resetForm: IFormResetRef;
};
interface IformData {
  stream_type: string;
  url: string;
  streaming_server: string;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldStreamingServerInput: React.FunctionComponent<
  ComponentProps
> = (props) => {
  const [isLocal, setIslocal] = useState(true);

  const [deb, setDeb] = useState<
    BehaviorSubject<{
      values: IformData;
      errors: any;
      isLocalUrl: boolean;
    } | null>
  >();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};
  const defaults = props.fieldConfig.default;

  const changeStreamType = (type: string) => {
    setIslocal(type === 'local');
  };
  const sendDefaults = (isLocalUrl: boolean) => {
    if (!props.fieldConfig || !deb) {
      return;
    }

    deb.next({
      values: {
        stream_type: defaults.stream_type,
        url: defaults.url,
        streaming_server: defaults.streaming_server,
      },
      errors: {},
      isLocalUrl,
    });
  };

  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      appConsoleLog(props.resetForm);
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        const infoData = props.resetForm.info;
        if (infoData.settings) {
          let streaming_server = '';

          if (infoData.settings.stream_type === 'url') {
            setIslocal(false);

            streaming_server = FORM_KEY_NEED_TO_DELETE;
          } else {
            setIslocal(true);
            if (infoData.settings.streaming_server) {
              streaming_server = infoData.settings.streaming_server;
            }
          }

          formRef.current.setValues({
            stream_type: infoData.settings.stream_type,
            url: infoData.settings[props.fieldConfig.field],
            streaming_server,
          });
        }
      } else {
        formRef.current.resetForm();
      }
    }
  }, [props.resetForm]);

  const initilized = useRef(0);
  useEffect(() => {
    const b = new BehaviorSubject<{
      values: IformData;
      errors: any;
      isLocalUrl: boolean;
    } | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any; isLocalUrl: boolean} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }

        const { values, errors, isLocalUrl } = v;
        const infoData: any = {
          settings: {},
        };
        if (isLocalUrl) {
          infoData.settings[props.fieldConfig.field] = values.url;
          infoData.settings.stream_type = 'local';

          const serverIdx = ITEM_DEFAULTS.CAMERA_SERVERS.findIndex(
            (item) => item.key === values.streaming_server,
          );
          if (serverIdx === -1) {
            appConsoleError('Streaming server index not valid');
            return;
          }
          infoData.settings.streaming_server = values.streaming_server;
        } else {
          infoData.settings[props.fieldConfig.field] = values.url;
          infoData.settings.stream_type = 'url';
          infoData.settings.streaming_server = FORM_KEY_NEED_TO_DELETE;
        }

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    sendDefaults(false);
    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  const formRef = useRef<any>();
  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);
  let isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;
  if (isEditable) {
    if (props.deviceInfo.settings[`has_${props.fieldConfig.field}`] === false) {
      isEditable = false;
    }
  }

  return isEditable === true ? (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={{
          stream_type: defaults.stream_type,
          url: defaults.url,
          streaming_server: defaults.streaming_server,
        }}
        validationSchema={Yup.object().shape({
          url: Yup.string().required('Required'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values, submitForm,
        }) => {
          deb?.next({
            values,
            errors,
            isLocalUrl: isLocal,
          });
          return (
            <form>
              <div className="row">
                <div className="text-fields-flex">
                  <div>
                    <FormControl
                      component="fieldset"
                      onChange={(event: any) => {
                        changeStreamType(event.target.value);
                        deb?.next({
                          values,
                          errors,
                          isLocalUrl: isLocal,
                        });
                      }}
                    >
                      <RadioGroup
                        row
                        aria-label="local-url"
                        name="local-url"
                        value={isLocal ? 'local' : 'url'}
                      >
                        <FormControlLabel
                          value="local"
                          key="cams-mode1"
                          control={<Radio color="primary" />}
                          label="Local"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="url"
                          key="cams-mode2"
                          control={<Radio color="primary" />}
                          label="Port forwarded url"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <TextField
                  className="dynamic-ui-form-control-full-width "
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.url}
                  name="url"
                  label="URL"
                  size="small"
                  variant="outlined"
                />
              </div>

              {isLocal ? (
                <div className="text-fields-flex">
                  <div>
                    <FormControl
                      size="small"
                      variant="outlined"
                      className="dynamic-ui-form-control"
                    >
                      <InputLabel id="streaming_server-label1">Streaming server</InputLabel>
                      <Select
                        labelId="streaming_server-label1"
                        value={values.streaming_server}
                        name="streaming_server"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        label="Streaming server"
                      >
                        {ITEM_DEFAULTS.CAMERA_SERVERS.map((item) => (
                          <MenuItem key={item.key} value={item.key}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
