import {Box, Typography} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

export const RestoreDone = () => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        margin: '2% auto',
      }}
    >
      <AssignmentTurnedInIcon
        style={{
          fontSize: '5rem',
          color: '#32727A',
        }}
      />
      <Typography
        style={{
          fontSize: '3rem',
          color: '#32727A',
          fontWeight: '700',
        }}
      >
        All Done
      </Typography>
      <Typography
        style={{
          fontSize: '1.3rem',
          color: '#32727A',
          fontWeight: '400',
          textAlign: 'center',
        }}
      >
        There is no pro devices left to clone. Please move on to the next step to continue
      </Typography>
    </Box>
  );
};
