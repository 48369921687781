import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Tag } from "src/plugins/shared/uvitem.config";

const tags: Record<'tags1' | 'tags2' | 'tags3', Record<string, Tag>> = {
    tags1: {
        current_tag: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'current_tag',
            description: 'Current [f = (x(A) X 1) A]',
            display_suffix: 'A', 
            config: {
              scale: 1,
              decimal_points: 1,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags2: {
        current_tag: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'current_tag',
            description: 'f = (x(A) X 240V) W',
            display_suffix: 'W', 
            config: {
              scale: 240,
              decimal_points: 1,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags3: {
        current_tag: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'current_tag',
            description: 'f = (x(A) X 240V)/1000 kW',
            display_suffix: 'kW', 
            config: {
              scale: 240 / 1000,
              decimal_points: 2,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
}

export default tags;