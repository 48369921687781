/* eslint-disable @typescript-eslint/ban-types */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Actions creators wrapped in the redux-thunk
 * for async data fetch. Later use in project slice to define actions and reducers
 *
 * @author Maduka Dilshan
 */

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  projectGetGraphQL,
  searchProjectsGraphQL,
} from '../../../../services/location/location.service';
import { GetProjectListFilter, Project } from './types';

export const getAvaibleProjects = createAsyncThunk<
  Project[],
  GetProjectListFilter,
  {
    rejectValue: Object;
    extra: {
      apolloClient: ApolloClient<NormalizedCacheObject>;
    };
  }
>('projects/getall', async (filters, thunkAPI) => {
  // TODO: get projects
  // console.log(thunkAPI.extra.apolloClient);
  const res = await searchProjectsGraphQL(
    thunkAPI.extra.apolloClient,
    filters.keyword,
    filters.limit,
    filters.skip,
    filters.project_status,
    filters.project_type,
  );
  return res.projectSearch.result;
});

export const getSelectedProject = createAsyncThunk(
  'projects/selectedProject',
  async (projectId: number, thunkAPI) => {
    const res = { data: {} }; // TODO: replace with gql query
    return res.data;
  },
);
