import React from 'react';
import { Box, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    borderRadius: '5px',
    backgroundColor: 'white',
    padding: '4% 10px',
    border: '1px dashed #D9DFE0',
    color: '#D9DFE0',
    '&:hover': {
      backgroundColor: '#D5E3E5',
      cursor: 'pointer',
      color: '#FFFFFF',
    },
  },
  typo: {
    fontWeight: 500,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const DivButton: React.FC<{onClick: () => void}> = ({ onClick }) => {
  const classes = useStyle();
  return (
    <Box className={classes.root} height={10} onClick={onClick}>
      <Typography className={classes.typo}>ADD AN ITEM</Typography>
    </Box>
  );
};
