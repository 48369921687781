/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AirDevice,
  DeviceCollectionSearchOutItem,
  LOGIC_CARD_VISIBILITY,
  OPERATORS,
  ProDevice,
  Trigger,
} from '@smartlife-redux-state/common';
import {
  select_dc,
  update_trigger,
} from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState, useSelector } from '../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../LogicStepper';
import { DeviceSelector } from '../shared/DeviceSelector';
import { ExpressionSelector } from '../shared/ExpressionSelector';
import ExsAlarmChildChildActions from '../shared/EsxAlarmController';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

const DeviceTriggerForm: React.FC<{trigger: Trigger}> = ({ trigger }) => {
  console.log(trigger);
  const classes = useStyles();
  const dispatch = useDispatch();
  const dc = useSelector((state: RootState) => {
    const found = state.rule_engine.triggers.find((trig) => trig.id === trigger.id);
    return found ? found.dc : null;
  });

  function onDcSelect(dc: DeviceCollectionSearchOutItem) {
    dispatch(
      select_dc({
        dc: { ...dc, has_verification_error: null },
        trigger_id: trigger.id,
      }),
    );
  }

  function onDeviceSelect(device: AirDevice | ProDevice) {
    console.log('on device selected', device);

    dispatch(
      update_trigger({
        trigger: {
          ...trigger,
          device: !device
            ? null
            : {
              ...device,
            },
          variable: null,
          possible_variable_type: null,
          condition: null,
          meta: {
            ...trigger.meta,
            is_enabled: true,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
          },
        },
      }),
    );
  }

  function onExpressionChange(op: OPERATORS, value: any, variable: {field: string; type: string}) {
    console.log('on expression selected', op, value, variable);
    if (variable && variable.type === 'esx') {
      const field = JSON.parse(variable.field);
      if (field[0] && field[0].state_value) {
        const triggerDevice = trigger && trigger.device ? trigger.device : null;
        if (triggerDevice && triggerDevice.id) {
          const chanelVar = `${
            field[0].type === 'area'
              ? 'status_readiness'
              : field[0].type === 'area_state'
                ? 'status_alarm'
                : field[0].type === 'status'
                  ? 'system_comms_status'
                  : 'triggered'
          }`;

          let pathVar;
          if (field[0].chanel) {
            pathVar = `child_items/${field[0].type === 'area_state' ? 'area' : field[0].type}_${
              field[0].chanel < 10 ? `0${field[0].chanel}` : field[0].chanel
            }_${chanelVar}_${triggerDevice.id}/state`;
          } else {
            pathVar = `child_items/${chanelVar}_${triggerDevice.id}/state`;
          }

          dispatch(
            update_trigger({
              trigger: {
                ...trigger,
                op: OPERATORS.EQUAL,
                condition:
                  field[0].type === 'area'
                    ? field[0].state_value.toUpperCase()
                    : field[0].state_value,
                variable_data: variable,
                variable: pathVar,
                possible_variable_type: variable.type,
              },
            }),
          );
        }
      }
    } else {
      dispatch(
        update_trigger({
          trigger: {
            ...trigger,
            op: op == null ? OPERATORS.EQUAL : op,
            condition: value,
            variable: variable.field,
            possible_variable_type: variable.type,
          },
        }),
      );
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceSelector
          selectedDC={dc}
          selectedDevice={trigger ? trigger.device : null}
          onDcSelect={onDcSelect}
          onDeviceSelect={onDeviceSelect}
          logic_step={LogicStepsTypes.WHEN}
        />
      </Grid>
      {trigger.device
      && (trigger.device.sub_cat === 'aapesx1a' || trigger.device.sub_cat === 'paradox_sp') ? (
        <Grid item xs={12} md={12}>
          <ExsAlarmChildChildActions
            action={trigger}
            alarmType={trigger.device.sub_cat}
            onChange={onExpressionChange}
          />
        </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <ExpressionSelector
              selectedVariable={{ field: trigger.variable, type: trigger.possible_variable_type }}
              selecteValue={trigger.condition}
              selectedOperator={trigger.op}
              onChange={onExpressionChange}
              device={trigger.device}
              dc={dc}
            />
          </Grid>
        )}
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const DeviceTrigger: React.FC<{trigger: Trigger}> = ({ trigger }) => {
  const classes = useStyles();
  const [dcs, setDcs] = useState<any[]>([]);
  const [devices, setDevices] = useState<any[]>([]);

  return (
    <Box>
      <DeviceTriggerForm trigger={trigger} />
    </Box>
  );
};
