/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line max-classes-per-file
import { EventManager, InternalEventManager } from 'src/plugins/shared/Plugin';
import { CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import ShellyIntegration, { iShellyIntegration } from '../shelly.service';
import CreateShellyDeviceContainer from './DeviceContainerCreate';
import ShellyDeviceCarda from './DeviceCard';
import { ContainerSettings } from './ContainerSettings';
import { UserViewCreateExtraFields } from './UserviewCreateExtraFields';

export class PluginComponentFactory {
  // eslint-disable-next-line class-methods-use-this
  public GetDeviceCreateForm(
    eventManager: EventManager,
    internalEventManager: InternalEventManager,
    integration: iShellyIntegration,
    rnProps: {
      // eslint-disable-next-line camelcase
      backup_view: boolean;
      onSuccessCreate?: (type: 'shelly', created: any) => void;
    },
  ) {
    return (
      <CreateShellyDeviceContainer
        // eventManager={eventManager}
        // internalEventManager={internalEventManager}
        integration={integration}
        {...rnProps}
      />
    );
  }

  public GetDeviceCardUiComponent(rnProps: {
    category: any;
    id?: string;
    cintId?:string;
    name: string;
    bgColor?: string;
    cursor?: string;
    showStatus?: boolean;
  }) {
    return <ShellyDeviceCarda {...rnProps} />;
  }

  public GetDeviceContainerSettingsForm(
    integration: iShellyIntegration,
    rnProps: {
      dcName: string;
      dcId: string;
      projectId: string;
    },
  ) {
    return <ContainerSettings {...rnProps} integration={integration} />;
  }

  public GetUserViewCreateExtraFields(rnProps: {
    selectedDevice: CiDevice & {dc_type: string};
    uvItem: object;
    appendSettings: (payload: any) => void;
  }) {
    const fn1 = (p: any) => { console.log('RX1', p); rnProps.appendSettings(p) };
    return <UserViewCreateExtraFields {...rnProps} appendSettings={fn1}/>;
  }
}

export default PluginComponentFactory;
