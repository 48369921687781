import React from 'react';
import IconButton from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './TagCard.module.css';

type ComponentProps = {
  title: string;
  deleteAction: any;
};

// eslint-disable-next-line import/prefer-default-export
export const TagCardComponent: React.FunctionComponent<ComponentProps> = ({
  title,
  deleteAction,
}) => (
  <div className={`${styles.root} nopadding row col-md-12 mr-2`}>
    <div className=" col-md-10 nopaddingOnly mt-1">
      <div className={`${styles.title} mb-1 mt-1 ml-1`}>{title}</div>
    </div>
    <div className=" col-md-2 nopaddingOnly mt-1">
      <IconButton aria-label="delete" color="secondary" onClick={deleteAction} size="large">
        <DeleteIcon />
      </IconButton>
    </div>
  </div>
);
