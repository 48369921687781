/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Listing All available rules/logics created and filters for
 * filtering them, button for create new logics
 *
 * @author Mayantha Jayawardena
 */
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { APP_TEXT } from '../../../utility/AppTexts/DefaultAppText.utils';
// import { DefaultButton } from './components/ui/DefaultButton';
import Layout from '../../../components/layout/Layout';
import { PageHeader } from '../../../components/layout/PageHeader';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import HubFinderBody from './components/HubFinderBody';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
});
const HubFinder = () => {
  const styles = useStyle();
  const navgiate = useNavigate();
  const projectId = useQueryString('projectId') as string;

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>{APP_TEXT.settings.HF_LABEL1}</Typography>
        </Box>
      </PageHeader>
      <HubFinderBody />
    </Layout>
  );
};

export default HubFinder;
