import { cloneDeep } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v2InfoUpdate } from 'src/store/redux/features/backup-location-config';
import { updateConfigurableContainer } from 'src/store/redux/features/configurable-containers';
import { RootState } from 'src/store/redux/store';

const useMoveDevices = () => {
  const configInited = useRef(0);
  const dispatch = useDispatch();

  const moveDevices = (preConfig: string, configurableList, devicePendingComp, flag: 'prodc' | 'lvar', selectedDevices?: {[deviceId: string]: boolean}) => {
    if (configInited.current === 1 && !selectedDevices) return;
    if (!(preConfig && preConfig.length > 0 && preConfig.startsWith('{'))) return;

    if (!devicePendingComp) return;

    const pendingCopy = cloneDeep(devicePendingComp);

    console.log(devicePendingComp.pendingComps, JSON.parse(preConfig));

    if (configurableList && Object.keys(configurableList).length > 0) {
      configInited.current = 1;
      const parsed: {[oldId: string]: string} = JSON.parse(preConfig);
      // eslint-disable-next-line no-debugger
      // debugger;
      const asNew = {};
      let clientMeta = {};
      // Get pending components
      Object.keys(devicePendingComp.pendingComps).map(
        // eslint-disable-next-line array-callback-return
        (containerId) => {
          const containerDevices = devicePendingComp.pendingComps[containerId];
          if (!parsed[containerId]) return;

          if (!containerDevices) return;

          const container = configurableList[parsed[containerId]];

          if (selectedDevices) {
            clientMeta = pendingCopy.pendingComps;
            Object.keys(selectedDevices).map((deviceId) => {
              const deviceRoot = containerDevices[deviceId];

              if (deviceRoot && deviceRoot.data) {
                asNew[deviceId] = {
                  device: deviceRoot.data,
                  mappings: {
                    pin: null,
                    channel: null,
                  },
                };
                if (!clientMeta[deviceId.split('_')[1]]) clientMeta[deviceId.split('_')[1]] = {};
                clientMeta[deviceId.split('_')[1]][deviceId] = {
                  ...devicePendingComp.pendingComps[deviceId.split('_')[1]][deviceId],
                  clientSideMeta: {
                    dropped_contatainer_id: container.dc.id,
                    dropped_on: null,
                  },
                };
              }
              return 0;
            });
          } else {
            Object.keys(containerDevices).map((deviceId) => {
              const deviceRoot = containerDevices[deviceId];

              if (deviceRoot && deviceRoot.data) {
                asNew[deviceId] = {
                  device: deviceRoot.data,
                  mappings: {
                    pin: null,
                    channel: null,
                  },
                };
                if (!clientMeta[deviceId.split('_')[1]]) clientMeta[deviceId.split('_')[1]] = {};
                clientMeta[deviceId.split('_')[1]][deviceId] = {
                  ...devicePendingComp.pendingComps[deviceId.split('_')[1]][deviceId],
                  clientSideMeta: {
                    dropped_contatainer_id: container.dc.id,
                    dropped_on: null,
                  },
                };
              }
              return 0;
            });
          }

          dispatch(
            updateConfigurableContainer({
              item: {
                deviceMap: {
                  toExisting: container.deviceMap.toExisting,
                  asNew: {
                    ...container.deviceMap.asNew,
                    ...asNew,
                  },
                },
              },
              key: container.dc.id,
              flag: 'UPDATE',
            }),
          );

          dispatch(
            v2InfoUpdate({
              value: {
                ...pendingCopy,
                pendingComps: {
                  ...pendingCopy.pendingComps,
                  ...clientMeta,
                },
              },
              key: flag,
              mergeErrorComps: false,
            }),
          );
        },
      );
    }
  };

  return { configInited, moveDevices };
};

export default useMoveDevices;
