import { EventManager, InternalEventManager } from 'src/plugins/shared/Plugin';
import ShellyIntegration, { iEnodeIntegration } from '../enode.service';
import CreateEnodeDeviceContainer from './DeviceContainerCreate';
import { ContainerSettings } from './ContainerSettings';
import { CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import { UserViewCreateExtraFields } from './UserviewCreateExtraFields';
import EnodeDeviceCard from './DeviceCard';

export class PluginComponentFactory {
  public GetDcCreateComponent(
    integration: iEnodeIntegration,
    rnProps: {
      backup_view: boolean;
      onSuccessCreate?: (type: 'shelly', created: any) => void;
    },
  ) {
    return <CreateEnodeDeviceContainer integration={integration} {...rnProps} />;
  }

  public GetDeviceCardUiComponent(rnProps: {
    category: any;
    id?: string;
    cintId?:string;
    name: string;
    bgColor?: string;
    cursor?: string;
  }) {
    return <EnodeDeviceCard {...rnProps} />
  }

  public GetDeviceContainerSettingsForm(
    integration: iEnodeIntegration,
    rnProps: {
      dcName: string;
      dcId: string;
      projectId: string;
    },
  ) {
    return <ContainerSettings {...rnProps} integration={integration} />;
  }

  public GetUserViewCreateExtraFields(integraton: iEnodeIntegration,  rnProps: {
    selectedDevice: CiDevice & {dc_type: string};
    uvItem: object;
    appendSettings: (payload: any) => void;
  }) {
    const fn1 = (p: any) => { console.log('RX1', p); rnProps.appendSettings(p) };
    return <UserViewCreateExtraFields integration={integraton} {...rnProps} appendSettings={fn1}/>;
  }
}

export default PluginComponentFactory;
