import { gql } from '@apollo/client';

export const gqlAppInfo = gql`
  query {
    appInfo {
      env
      api_version
      env_version
      storage_bucket_image_root
      db_name
      item_defaults
    }
  }
`;

export const gqlProDeviceCreate = gql`
  mutation ProDeviceCreate($payload: ProDeviceIn) {
    proDeviceCreate(input: $payload) {
      id
      created_by
      project_id
      prodc_id
      name
      tags
      system_tags
      shadow_type
      cat
      sub_cat
      item_type
      device_status
      settings
      created_at
      updated_at
    }
  }
`;

export const gqlProDeviceUpdate = gql`
  mutation proDeviceUpdate($payload: ProDeviceUpdateIn) {
    proDeviceUpdate(input: $payload) {
      id
      update_status
      updated_at
    }
  }
`;

export const gqlProDCGetPins = gql`
  query proDCGetPins($prodcId: String, $pinType: String) {
    proDCGetPins(prodc_id: $prodcId, pin_type: $pinType) {
      pins {
        display_name
        pin
        available
        item_id
      }
    }
  }
`;

export const gqlTagSearch = gql`
  query tagSearch($projectId: String, $keyword: String, $limit: Int, $skip: Int) {
    tagSearch(project_id: $projectId, keyword: $keyword, limit: $limit, skip: $skip) {
      result {
        name
      }
    }
  }
`;
