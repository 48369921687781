/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import {
  Button, Typography, IconButton, Box,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { Action } from '@smartlife-redux-state/common';
import { isArray } from 'lodash';
import { RootState, useSelector } from '../../../../../store/redux/store';
import { FormContainer } from './components/FormContainer';
import { ActionSelector } from './components/ActionSelector';
import {
  action_reorder,
  add_empty_action,
  define_check_expression,
  delete_action,
  duplicate_action_queue,
} from '../../../../../store/redux/features/logic-engine/slice';
import { DivButton } from '../ui/DivButton';
import { CofirmDialog } from '../ui/Confirm';
import { Board } from '../board';

const useStyle = makeStyles({
  line_root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  line: {
    alignSelf: 'center',
    background: '#E0E0E0',
    width: '40%',
    height: '0.5px',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const BoardAction = () => {
  const classes = useStyle();
  const [deleteActionId, setDeleteActionid] = useState(null);
  const actions = useSelector((state: RootState) => state.rule_engine.actions);
  const dispatch = useDispatch();

  function onNewAction(nextTo = 0) {
    dispatch(add_empty_action());
  }

  function onDuplicateActionQueue(action: Action) {
    dispatch(duplicate_action_queue({ action_queue: action }));
  }

  function onDeleteAction(actionId: string) {
    setDeleteActionid(actionId);
  }

  function deleteAction() {
    dispatch(delete_action({ actionId: deleteActionId }));
    setDeleteActionid(null);
  }

  useEffect(() => {
    setExpressoin();
  }, []);

  function setExpressoin() {
    dispatch(define_check_expression());
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    console.log('_________', result, provided);
    dispatch(action_reorder({
      result,
      provided,
    }));
  }

  return (
    <Box>
      <Board onDragEnd={onDragEnd}>
        {actions.map((action, index) => (
          <div key={action.id}>
            <FormContainer
              key={action.id}
              action={() => (
                <>
                  <IconButton onClick={() => onNewAction(0)} size="large">
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={() => onDuplicateActionQueue(action)} size="large">
                    <FileCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => onDeleteAction(action.id)} size="large">
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            >
              <Box style={{ width: '100%' }}>
                <Box style={{ marginLeft: '5px', marginBottom: '2%' }}>
                  <Typography variant="h6">
                    Action Queue #
                    {index + 1}
                  </Typography>
                  {/* <FormHelperText>
                    This is the first action which will execute in this action
                    queue
                  </FormHelperText> */}
                </Box>

                <ActionSelector action={action} queueIndex={index} />
                {/* {action && isArray(action.child_actions) && (
                  action.child_actions.map((childAction) => (
                    <ActionSelector action={childAction} isChild parentAction={action} />
                  ))
                )} */}
              </Box>
            </FormContainer>
            {/* actions.length != index + 1 &&  */}
            <div className={classes.line_root}>
              <hr className={classes.line} />
              <Button color="primary" disabled variant="contained">
                <Typography>AND</Typography>
              </Button>
              <hr className={classes.line} />
            </div>
          </div>
        ))}
      </Board>
      <DivButton
        onClick={() => {
          onNewAction();
        }}
      />
      <CofirmDialog
        dialogOpen={!!deleteActionId}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteAction();
          } else {
            setDeleteActionid(null);
          }
        }}
        title={"Confirm to delete the 'Action' ?"}
        text="It will delete the action and you will not able to redo it!"
      />
    </Box>
  );
};
