/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * The root file defined RE14 Item listing and creation components
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the root of RE14 item listing and creation components
 * @author Maduka Dilshan
 * @since 0.1.15
 */

import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  deleteProjectDiagram,
  getProjectDiagrams,
} from '../../../services/location/location.service';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { appConsoleLog } from '../../../utility/appUtils';

import RE14CreateEdit from './Dialog';
import Re14Item from './Re14Item';

/**
 * Re14 link list and link creation dialog component
 * @param {Object} props component props
 * @param {String} props.projectId selected location id
 * @returns JSX.Element
 */
const Re14Component: React.FC<{ projectId: string }> = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const [links, setLinks] = useState([]);
  const [isEdtting, setIsEditting] = useState(false);
  const [editingLink, setEditingLink] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    setOpenDialog(false);
  };

  const onSuccess = (vals: any) => {
    if (isEdtting) {
      const updated: any[] = [];
      links.map((link) => {
        if (link.id === vals.id) {
          const cpy = JSON.parse(JSON.stringify(link));
          cpy.name = vals.name;
          cpy.description = vals.description;
          updated.push(cpy);
        } else {
          updated.push(link);
        }
        return 0;
      });
      setLinks(updated);
      return;
    }
    setLinks([vals, ...links]);
  };

  const onEdit = (link: any) => {
    setIsEditting(true);
    setEditingLink(link);
    setOpenDialog(true);
  };

  // callback which runs after user click on delete link
  const onDeleteItem = async (diagramId: string) => {
    try {
      await deleteProjectDiagram(
        userProfile.apollo_client,
        diagramId,
      );
      const filtered = links.filter((link) => link.id !== diagramId);
      setLinks(filtered);
      enqueueSnackbar('Link Deleted', { variant: 'success' });
    } catch (err:any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  // get the list of diagram links on selected project change and set to the state
  useEffect(() => {
    async function init() {
      try {
        const res = await getProjectDiagrams(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
        );
        if (res.data.projectDiagrams.diagrams) {
          setLinks(res.data.projectDiagrams.diagrams);
        }
      } catch (err) {
        appConsoleLog(err);
      }
    }
    if (selectedProject.selected_project) {
      init();
    }
  }, [selectedProject.selected_project.id]);

  return (
    <div className="row">
      <div className="col-md-12" style={{ marginTop: '2%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>RE14 Design Links</h3>
          <Button
            color="primary"
            onClick={async () => {
              await setIsEditting(false);
              await setEditingLink(null);
              setOpenDialog(true);
            }}
          >
            Add RE14 Design Link
          </Button>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          {links.length === 0 && (
            <div className="col-md-12">No Links Found :(</div>
          )}
          {links.map((link) => (
            <div className="col-md-3" key={link.id}>
              <Re14Item link={link} onEdit={onEdit} onDelete={onDeleteItem} />
            </div>
          ))}
        </div>
      </div>
      <RE14CreateEdit
        open={openDialog}
        isEditing={isEdtting}
        onClose={onClose}
        onSuccess={onSuccess}
        editingLink={editingLink}
      />
    </div>
  );
};

export default Re14Component;
