/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  forwardRef, useContext, useEffect, useImperativeHandle, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import { UVContext, UVReducerTypes } from '../UVContext';

import uvService, { userViewGetOrder } from '../service/uv.service';
import { ApolloAuthContext } from '../../../store/Apollo/ApolloContext';
import {
  IUserView, IUserViewItem, IUserViewPath, UserViewItemCategory,
} from '../types/types';
import { ErrorSnackbarOptions, ErrorSnackbarActions } from '../../Alters/Snackbar/SnackbarOptions';
import { LoadingComponent } from '../../shared/Loading/Loading';
import { getFolderIdFromPathRef, pathRefToUserviewPaths } from '../utils';

import styles from './UserViewcurrentDirectory.module.css';
import { UVFolderItem } from '../UIItems/UVFolderItem/UVFolderItem';
import { FolderCreateDialog } from '../UIItems/Dialogs/FolderCreateDialog/FolderCreateDialog';
import { ItemCreateDialog } from '../UIItems/Dialogs/ItemCreateDialog/ItemCreateDialog';
import { UVItem } from '../UIItems/UVFolderItem/UVItem';
import { ItemSortDialog } from '../UIItems/Dialogs/ItemSortDialog/ItemSortDialog';
import { appConsoleError, appConsoleLog } from '../../../utility/appUtils';
import { ShortcutEditDialog } from '../UIItems/Dialogs/ShortcutEdit/ShortcutEdit';

enum ShortcutOpenState {
  closed = 1,
  edit = 2,
}
enum FolderOpenState {
  closed = 1,
  newFolder = 2,
  editFolder = 3,
}
enum ItemOpenState {
  closed = 1,
  newItem = 2,
  editItem = 3,
}
enum ItemSortOpenState {
  closed = 1,
  open = 2,
}
type ComponentProps = {
  projectId: string;
  userViewId: string;
  userViewPath: string;
  parent_folder_id: string;
  userviewFetchHandler: (userview: IUserView) => void;
};
export interface IUVCurrentDirectoryRef {
  action: (payload: UVCurrentDirectoryRefActions) => void;
}
export enum UVCurrentDirectoryRefActions {
  folderCraete = 1,
  itemCreate = 2,
  sortFolderItems = 3,
}
export const UserViewcurrentDirectory = forwardRef((props: ComponentProps, ref) => {
  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);

  const [items, setItems] = useState<IUserViewItem[]>([]);
  const [cardOrder, setCardOrder] = useState([]);
  const [currentFolder, setCurentfolder] = useState<IUserViewItem>();
  const [currentFolderId, setCurrentFolderId] = useState<string>('root');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [currentPathParts, setCurrentPath] = useState<IUserViewPath[]>([]);
  const [isFolderCreateOpen, OpenFolderCreate] = useState({
    isOpen: FolderOpenState.closed,
    folderId: '',
  });
  const [isItemCreateOpen, OpenItemCreate] = useState({
    isOpen: ItemOpenState.closed,
    item_id: '',
    itemCat: -1,
  });
  const [isShortcutEditOpen, OpenShortcutEdit] = useState({
    isOpen: ShortcutOpenState.closed,
    shortcutId: '',
  });
  const [isSortItemsOpen, OpenItemSort] = useState(ItemSortOpenState.closed);
  const [itemCreateRef, RefreshItemCreateRef] = useState(0);
  const [itemSortRef, RefreshItemSortRef] = useState(0);

  useEffect(() => {
    if (isItemCreateOpen.isOpen === ItemOpenState.closed) {
      RefreshItemCreateRef(Date.now());
    }
  }, [isItemCreateOpen]);
  useImperativeHandle(ref, () => ({
    action(data: UVCurrentDirectoryRefActions) {
      if (data === UVCurrentDirectoryRefActions.folderCraete) {
        OpenFolderCreate({ isOpen: FolderOpenState.newFolder, folderId: '' });
      } else if (data === UVCurrentDirectoryRefActions.itemCreate) {
        OpenItemCreate({
          isOpen: ItemOpenState.newItem,
          item_id: '',
          itemCat: -1,
        });
      } else if (data === UVCurrentDirectoryRefActions.sortFolderItems) {
        OpenItemSort(ItemSortOpenState.open);
      }
    },
  }));

  useEffect(() => {
    if (props.projectId !== '' /* -1 */ && props.userViewId !== '' /* -1 */) {
      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          projectId: props.projectId,
          userViewId: props.userViewId,
          userViewPath: props.userViewPath,
          updated: Date.now(),
        },
      });
    }
  }, [props.projectId, props.userViewId, props.userViewPath]);

  useEffect(() => {
    if (userViewContext.projectId !== '' /* -1 */ && userViewContext.userViewId !== '' /* -1 */) {
      fetchFolderView();
    }
  }, [
    userViewContext.projectId,
    userViewContext.userViewId,
    userViewContext.userViewPath,
    userViewContext.updated,
  ]);

  const fetchFolderView = async () => {
    try {
      setLoading(true);

      const currentPath = userViewContext.userViewPath;

      let folderId = getFolderIdFromPathRef(currentPath);
      if (folderId === '' || !folderId) {
        // ROOT folder,
        folderId = props.parent_folder_id ? props.parent_folder_id : '';
      }
      const response = await uvService.userviewListFolderItems(
        userProfile.apollo_client,
        userViewContext.userViewId,
        folderId,
        100,
        0,
      );

      const res = await userViewGetOrder(userProfile.apollo_client, folderId, 2);

      if (res.data && res.data.itemOrderGet && res.data.itemOrderGet.items) {
        const card_order: any = [];
        const list: {[key: string]: any} = res.data.itemOrderGet.items;
        // eslint-disable-next-line no-restricted-syntax
        for (const [_, val] of Object.entries(list)) {
          card_order.push(val.ref_val);
        }
        setCardOrder(card_order);
      }

      props.userviewFetchHandler(response.user_view);
      setItems(response.items.result);

      setCurentfolder(response.folder);
      setCurrentFolderId(`${folderId}`);
      if (response.folder != null) {
        const paths = pathRefToUserviewPaths(
          response.folder.path_ref,
          response.folder.display_path_ref,
        );

        if (userViewContext.userViewName !== response.user_view.name) {
          userViewContext.dispatch({
            type: UVReducerTypes.CHANGE,
            payload: {
              userViewName: response.user_view.name,
              updated: Date.now(),
            },
          });
        }
        setCurrentPath(paths);
      } else {
        setCurrentPath([
          {
            display_name: '',
            folder_id: '',
            path_ref: 'root',
          },
        ]);
      }
      // get folder items
    } catch (err) {
      appConsoleError(err);

      snackbar.enqueueSnackbar('Unexpacted error', {
        key: 'uv-folder-cd-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const getPageStateParams = (userViewPath = '') => {
    let params = `?userViewId=${userViewContext.userViewId}`;
    params += `&projectId=${userViewContext.projectId}`;

    if (userViewPath === '') {
      // eslint-disable-next-line no-param-reassign
      userViewPath = userViewContext.userViewPath;
    }
    params += `&userViewPath=${userViewPath}`;
    params += `&userViewPathName=HOME${currentFolder?.display_path_ref}`;
    params += `&userViewName=${userViewContext.userViewName}`;
    return params;
  };
  const navigateRoute = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      <div className={styles.pathList}>
        {currentPathParts.map((pathItem, index) => {
          const isActive = pathItem.folder_id === currentFolderId;
          return (
            <div
              key={`path_${pathItem.folder_id}`}
              className={styles.pathItem + (isActive ? ' active' : '')}
              onClick={() => {
                navigateRoute(`/app/user-view${getPageStateParams(pathItem.path_ref)}`);
              }}
            >
              {index > 0 ? <span className={styles.pathSep}>/</span> : <span />}
              <span className={styles.pathDisplayName}>{pathItem.display_name}</span>
            </div>
          );
        })}
      </div>

      {loading ? (
        <div>
          <LoadingComponent color="#06454d" />
        </div>
      ) : (
        <div className={`row ${styles.itemsList}`}>
          {cardOrder.map((card_id) => {
            const item = items.find((itemPayload) => itemPayload.id === card_id);

            if (!item) {
              return <></>;
            }
            if (item.item_cat === UserViewItemCategory.folder) {
              return (
                <div className="col-md-3 mb-3">
                  <UVFolderItem
                    key={`fol${item.id}${Date.now()}`}
                    userviewItem={item}
                    onClick={() => {
                      navigateRoute(`/app/user-view${getPageStateParams(item.path_ref)}`);
                    }}
                    onEdit={(itemToEdit) => {
                      OpenFolderCreate({
                        isOpen: FolderOpenState.editFolder,
                        folderId:
                          itemToEdit.id !== undefined && itemToEdit.id.length > 0
                            ? itemToEdit.id
                            : '',
                      });
                    }}
                  />
                </div>
              );
            }
            if (
              item.item_cat === UserViewItemCategory.linkedItem
              || item.item_cat === UserViewItemCategory.linkedFolder
              || item.item_cat === UserViewItemCategory.linkedUV
            ) {
              return (
                <div className="col-md-3 mb-3">
                  <UVItem
                    key={`item${item.id}${Date.now()}`}
                    shortcut
                    userviewItem={item}
                    onEdit={(itemToEdit) => {
                      appConsoleLog(itemToEdit);
                      OpenShortcutEdit({
                        isOpen: ShortcutOpenState.edit,
                        shortcutId:
                          itemToEdit.id !== undefined && itemToEdit.id.length > 0
                            ? itemToEdit.id
                            : '',
                      });
                    }}
                    // onEdit={(itemToEdit) => { appConsoleLog(itemToEdit); }}
                  />
                </div>
              );
            }
            if (
              item.item_cat === UserViewItemCategory.item
            ) {
              return (
                <div className="col-md-3 mb-3">
                  <UVItem
                    key={`item${item.id}${Date.now()}`}
                    userviewItem={item}
                    onEdit={(itemToEdit) => {
                      const cpy = cloneDeep(itemToEdit);
                      OpenItemCreate({
                        isOpen: ItemOpenState.editItem,
                        item_id: itemToEdit.id && itemToEdit.id.length > 0 ? itemToEdit.id : '',
                        itemCat: cpy.item_cat,
                      });
                    }}
                  />
                </div>
              );
            }
            if (
              item.item_cat === UserViewItemCategory.otherItem
              || item.item_cat === UserViewItemCategory.listOfItems
              || item.item_cat === UserViewItemCategory.listViewItemLink
            ) {
              return (
                <div className="col-md-3 mb-3">
                  <UVItem
                    key={`item${item.id}${Date.now()}`}
                    userviewItem={item}
                    onEdit={(itemToEdit) => {
                      const cpy = cloneDeep(itemToEdit);
                      cpy.item_cat = UserViewItemCategory.otherItem;
                      OpenItemCreate({
                        isOpen: ItemOpenState.editItem,
                        item_id: itemToEdit.id && itemToEdit.id.length > 0 ? itemToEdit.id : '',
                        itemCat: cpy.item_cat,
                      });
                    }}
                  />
                </div>
              );
            }
            return <></>;
          })}
        </div>
      )}

      <ShortcutEditDialog
        dialogOpen={isShortcutEditOpen.isOpen !== ShortcutOpenState.closed}
        dialogCloseEvent={(ok: boolean) => {
          OpenShortcutEdit({ isOpen: ShortcutOpenState.closed, shortcutId: '' });
        }}
        shortcutId={isShortcutEditOpen.shortcutId}
        key="folderCreateRef112"
      />

      <FolderCreateDialog
        dialogOpen={isFolderCreateOpen.isOpen !== FolderOpenState.closed}
        dialogCloseEvent={(ok: boolean) => {
          OpenFolderCreate({ isOpen: FolderOpenState.closed, folderId: '' });
        }}
        folderId={isFolderCreateOpen.folderId}
        key="folderCreateRef11"
      />
      <ItemCreateDialog
        dialogOpen={isItemCreateOpen.isOpen !== ItemOpenState.closed}
        dialogCloseEvent={(ok: boolean) => {
          appConsoleLog(['item create close ', ok, ItemOpenState.closed, isItemCreateOpen]);
          OpenItemCreate({
            isOpen: ItemOpenState.closed,
            item_id: '',
            itemCat: -1,
          });
        }}
        itemId={isItemCreateOpen.item_id}
        itemCat={isItemCreateOpen.itemCat}
        key={itemCreateRef}
      />

      <ItemSortDialog
        dialogOpen={isSortItemsOpen !== ItemSortOpenState.closed}
        dialogCloseEvent={(ok: boolean) => {
          RefreshItemSortRef(Date.now());
          OpenItemSort(ItemSortOpenState.closed);
          if (ok) fetchFolderView();
        }}
        folderId={currentFolderId}
        key={itemSortRef}
      />
    </div>
  );
});
