/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';

import { IDFField } from '../../../../../config/deviceConfig/types';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.custom_selector;
type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  deviceInfo: any;
  resetForm: IFormResetRef;
  onChange: (payload:any, isInitializing?: boolean) => void;
};
interface IformData {
  custom_selector: string;
  custom_selector_custom_value: string;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldCustomeSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const [deb, setDeb] = useState<BehaviorSubject<{values: IformData; errors: any} | null>>();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};

  const sendDefaults = () => {
    if (!props.fieldConfig || !deb) {
      return;
    }
    deb.next({
      values: {
        custom_selector: props.fieldConfig.default,
        custom_selector_custom_value: '',
      },
      errors: {},
    });
  };
  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        const fieldName = props.fieldConfig.field.split(':')[0];
        const values = props.resetForm.info.settings[fieldName];
        let customValue = '';
        if (props.resetForm.info.settings[`${fieldName}custom_selector_custom_value`]) {
          customValue = props.resetForm.info.settings[`${fieldName}custom_selector_custom_value`];
        }

        formRef.current.setValues({
          custom_selector: values,
          custom_selector_custom_value: customValue,
        });
      } else {
        formRef.current.resetForm();
      }
    }
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.resetForm]);
  const initilized = useRef(0);
  useEffect(() => {
    const b = new BehaviorSubject<{values: IformData; errors: any} | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any} | null) => {
        if (v == null || props.fieldConfig == null) {
          return;
        }

        const { values, errors } = v;
        const infoData: any = {
          settings: {},
        };
        const fieldName = props.fieldConfig.field.split(':')[0];
        infoData.settings[fieldName] = values.custom_selector;
        if (values.custom_selector === 'custom-value') {
          infoData.settings[`${fieldName}custom_selector_custom_value`] = values.custom_selector_custom_value;
          if (values.custom_selector_custom_value.trim().length < 1) {
            errors[fieldName] = `${fieldName} Custom value required`;
          }
        } else {
          infoData.settings[`${fieldName}custom_selector_custom_value`] = '';
        }

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    sendDefaults();
    return () => {
      sub.unsubscribe();
    };
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.fieldConfig]);
  const formRef = useRef<any>();
  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  // eslint-disable-next-line react/destructuring-assignment
  }, [props.validateForm]);
  // eslint-disable-next-line react/destructuring-assignment
  const fieldName = props.fieldConfig.field.split(':')[0];
  // eslint-disable-next-line react/destructuring-assignment
  let isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;
  if (isEditable) {
    if (props.deviceInfo.settings[`has_${fieldName}`] === false) {
      isEditable = false;
    }
  }
  // eslint-disable-next-line react/destructuring-assignment
  const itemLabels = props.fieldConfig.extra.split('|');
  // eslint-disable-next-line react/destructuring-assignment
  const itemValues = props.fieldConfig.field.split(':');
  const items: any[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < itemLabels.length; i++) {
    items.push({
      display_name: itemLabels[i],
      value: itemValues[i + 1],
    });
  }

  return isEditable === true ? (
    <div>
      <Formik
        initialValues={{
          // eslint-disable-next-line react/destructuring-assignment
          custom_selector: props.fieldConfig?.default,
          custom_selector_custom_value: '',
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          custom_selector: Yup.string().required('Required'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values,
        }) => {
          deb?.next({
            values,
            errors,
          });
          return (
            <form>
              <div className="text-fields-flex">
                <div>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className="dynamic-ui-form-control"
                  >
                    <InputLabel
                      id="custom_selector-label1"
                      error={Boolean(touched.custom_selector && errors.custom_selector)}
                    >
                      {props.fieldConfig.labels}
                    </InputLabel>
                    <Select
                      labelId="custom_selector-label1"
                      value={values.custom_selector}
                      name="custom_selector"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      label={props.fieldConfig.labels}
                    >
                      {items.map((item: any) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.display_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {values.custom_selector === 'custom-value' ? (
                <div className="text-fields-flex">
                  <div>
                    <TextField
                      className="dynamic-ui-form-control-full-width mt-1"
                      helperText="Custom value"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.custom_selector_custom_value}
                      name="custom_selector_custom_value"
                      label={props.fieldConfig.labels.replace('Select', 'Custom')}
                      size="small"
                      variant="outlined"
                    />
                  </div>
                </div>
              ) : (
                <div />
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
