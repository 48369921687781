/* eslint-disable no-param-reassign */
import { useNavigate, useLocation } from 'react-router-dom';
import { DeviceCollectionType } from '../../../../components/UserViewComponents/UIItems/UVDeviceFinder/types';
import { ITEM_DEFAULTS } from '../../../../config/deviceConfig/item_defaults';
import { IDeviceCategory, IDFeatures, IDFField } from '../../../../config/deviceConfig/types';
import { FORM_KEY_NEED_TO_DELETE } from '../../../../config/deviceConfig/Values';
import { appConsoleLog } from '../../../../utility/appUtils';
import { ITuyaFuntion } from '../../AirDeviceCreateEdit/types';

const defaultCategory = (
  cat: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows.gpio_items.value,
): IDeviceCategory => ({
  category: cat,
  subCategory: '',
  sub3Category: '',
  isItem: false,
  config: null,
});
const defaultAirCategory = (): IDeviceCategory => ({
  category: 'AirItem',
  subCategory: '',
  sub3Category: '',
  isItem: false,
  config: null,
});
const defaultProDeviceInfo = (
  projectId: string,
  prodcId: string,
  prodcDeviceId: string,
  tags: string[] | undefined,
  defaultInfo: any,
): any => {
  if (!tags) {
    tags = [];
  }

  const info = {
    id: prodcDeviceId,
    project_id: projectId,
    prodc_id: prodcId,
    name: '',
    tags,
    shadow_type: '-',
    settings: {},
  };
  return updateProDeviceInfoOnlyPresentData(info, defaultInfo);
};

const defaultAirDeviceInfo = (
  projectId: string,
  airdcId: string,
  airdcDeviceId: string,
  tags: string[] | undefined,
  defaultInfo: any,
): any => {
  if (!tags) {
    tags = [];
  }

  const info = {
    id: airdcDeviceId,
    project_id: projectId,
    airdc_id: airdcId,
    name: '',
    tags,
    shadow_type: '-',
    settings: {},
  };
  return updateProDeviceInfoOnlyPresentData(info, defaultInfo);
};

const updateProDeviceInfoOnlyPresentData = (source: any, payload: any) => {
  if (!payload) {
    return source;
  }
  let data: any = {};
  data = {
    ...source,
    ...payload,
  };

  if (data.settings) {
    const sourceSettings = source.settings;
    const payloadSettings = payload.settings;
    data = {
      ...data,
      settings: {
        ...sourceSettings,
        ...payloadSettings,
      },
    };

    // deleting child fields if values are marked to delete
    const settingsKeys = Object.keys(data.settings);
    settingsKeys.forEach((key) => {
      if (data.settings[key] === FORM_KEY_NEED_TO_DELETE) {
        delete data.settings[key];
      }
    });
  }

  return data;
};

const updateAirDeviceInfoOnlyPresentData = (source: any, payload: any) => {
  if (!payload) {
    return source;
  }
  let data: any = {};
  data = {
    ...source,
    ...payload,
  };

  if (data.settings) {
    const sourceSettings = source.settings;
    const payloadSettings = payload.settings;
    data = {
      ...data,
      settings: {
        ...sourceSettings,
        ...payloadSettings,
      },
    };

    // deleting child fields if values are marked to delete
    const settingsKeys = Object.keys(data.settings);
    settingsKeys.forEach((key) => {
      if (data.settings[key] === FORM_KEY_NEED_TO_DELETE) {
        delete data.settings[key];
      }
    });
  }

  return data;
};

const getFieldsConfigFromCat = (cat: string, subCat: string): {config: any; isItem: boolean} => {
  const obj: any = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows;
  const objCat2 = obj[cat].children[subCat];
  let isItem = false;
  if (objCat2.children === undefined || Object.keys(objCat2.children).length === 0) {
    isItem = true;
  }
  return { config: objCat2, isItem };
};

const useDcTypeFromUrl = (): string => {
  const location = useLocation();

  if (location.search && location.search.includes('airDCId')) {
    return DeviceCollectionType.airDevice;
  }
  return DeviceCollectionType.proDevice;
};
export default {
  defaultCategory,
  defaultAirCategory,
  defaultProDeviceInfo,
  defaultAirDeviceInfo,
  updateProDeviceInfoOnlyPresentData,
  updateAirDeviceInfoOnlyPresentData,
  getFieldsConfigFromCat,
  useDcTypeFromUrl,
};
