/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
import {Alert, Box, Grid, Typography} from '@mui/material';
import {isArray, cloneDeep} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/store/redux/store';
import {proDCGetPins} from 'src/containers/app/ProDeviceCreateEdit/services/pro-device.service';
import {v2PullDroppedItem} from 'src/store/redux/features/backup-location-config';
import {
  DeviceFromRestoreContainer,
  DEVICE_POSITIONS,
  removeDevice,
  updateConfigurableContainer,
} from '../../../../../store/redux/features/configurable-containers';
import {
  airDCTuyaDeviceListGraphQL,
  airDeviceSearch,
} from '../../../../../services/air-device/air-device.service';
import {dcSearchGraphQL} from '../../../../../services/device-container/device-container.service';
import {proDeviceSearchGraphQL} from '../../../../../services/pro-device/pro-device.service';
import {ApolloAuthContext} from '../../../../../store/Apollo/ApolloContext';
import {ProjectContext} from '../../../../../store/Project/ProjectContext';
import {Card} from '../../../LogicsEngine/components/board/Card';
import {Group} from '../../../LogicsEngine/components/board/Group';
import Device, {DEVICE_STATUS_IN_RESTORE_STEP} from './shared/Device';
import DeviceContainer, {DEVICE_CONTAINER_LOC} from './shared/DeviceContainer';
import {DeviceConfigContext} from './shared/context/deviceConfigContext';
import DeviceInfo from './shared/DeviceInfo';
import {CloneSuccessRateContext} from './shared/context/cloneSuccessRateContext';
import {pluginHook} from 'src/store/Plugins/PluginProvider';
import {CiDeviceContainer} from 'src/plugins/shared/services/cloud-integration.service';
import {DeviceCollectionSearchOutItem} from '@smartlife-redux-state/common';

const ConfigurableDeviceContainer: React.FC<{
  type: 'locvar' | 'prodc' | 'airdc' | 'cintd';
  dcCat: 1 | 2; // not used when type is 'cintd'
  ref?: any;
}> = forwardRef(({type, dcCat}, ref) => {
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const clickedDeviceInfo = useContext(DeviceConfigContext);

  const [loadingDevices, setLoadingDevices] = useState(false);

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const configurableList = useSelector((state: RootState) => state.configurable_containers);
  const configurableListKeys = Object.keys(configurableList);

  const [configurableStepListkeys, setConfigurableStepListkeys] = useState([]);

  useImperativeHandle(ref, () => ({
    invokeFetchDevice() {
      // eslint-disable-next-line no-debugger
      // debugger;
      fetchDevices(dcList, true);
    },
  }));

  useEffect(() => {
    if (type === 'locvar' && dcCat === 1) {
      const configurableLocDcList = Object.keys(configurableList).filter(key => {
        const dc = configurableList[key];
        if (
          (dc.dc as DeviceCollectionSearchOutItem).dc_cat === 1 &&
          (dc.dc as DeviceCollectionSearchOutItem).dc_type === 9
        )
          return true;
        return false;
      });
      setConfigurableStepListkeys(configurableLocDcList);
    }

    if (type === 'prodc' && dcCat === 1) {
      const configurableLocDcList = Object.keys(configurableList).filter(key => {
        const dc = configurableList[key];
        if (
          (dc.dc as DeviceCollectionSearchOutItem).dc_cat === 1 &&
          (dc.dc as DeviceCollectionSearchOutItem).dc_type !== 9
        )
          return true;
        return false;
      });
      setConfigurableStepListkeys(configurableLocDcList);
    }

    if (type === 'airdc' && dcCat === 2) {
      const configurableLocDcList = Object.keys(configurableList).filter(key => {
        const dc = configurableList[key];
        if (
          (dc.dc as DeviceCollectionSearchOutItem).dc_cat === 2 &&
          (dc.dc as DeviceCollectionSearchOutItem).dc_type !== 9
        )
          return true;
        return false;
      });
      setConfigurableStepListkeys(configurableLocDcList);
    }

    if (type === 'cintd') {
      const configurableLocDcList = Object.keys(configurableList).filter(key => {
        const dc = configurableList[key];
        if ((dc.dc as CiDeviceContainer).cint_type) return true;
        return false;
      });
      setConfigurableStepListkeys(configurableLocDcList);
    }
  }, [configurableList]);

  const [dcList, setDCList] = useState([]);

  useEffect(() => {
    if (!selectedProject || !selectedProject.selected_project) return;
    getDcs();
  }, [selectedProject]);

  useEffect(() => {
    if (dcList.length > 0) {
      fetchDevices(dcList);
      if (dcList) {
        dcList.map(dc => {
          dispatch(
            updateConfigurableContainer({
              item: {
                dc,
                existingDevices: {},
                deviceMap: {
                  asNew: {},
                  toExisting: {},
                },
              },
              key: dc.id,
              flag: 'CREATE',
            })
          );
          return 0;
        });
      }
    }
  }, [dcList]);

  const getDcs = async () => {
    try {
      if (Object.keys(configurableList).length > 0 && type !== 'cintd') return;

      if (type == 'cintd') {
        const cintRes = await pluginHook.commonIntegrationService.getCloudDeviceCollections({
          limit: 100,
          project_id: selectedProject.selected_project.id,
          skip: 0,
        });

        if (cintRes.result?.result && isArray(cintRes.result?.result)) {
          // NOTE: only accept shelly containers for now
          const filtered = cintRes.result?.result.filter(dc => dc.cint_type === '4');
          setDCList(filtered);
        }
        return;
      }

      const res = await dcSearchGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
        -1,
        -1,
        '',
        100,
        0
      );
      setDCList(res.dcSearch.result ? res.dcSearch.result : []);
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  const fetchDevices = async (containers: any[] = [], reset = false) => {
    try {
      let dcs = containers;
      setLoadingDevices(true);

      if (reset) {
        if (type == 'cintd') {
          let res = await pluginHook.commonIntegrationService.getCloudDeviceCollections({
            limit: 100,
            project_id: selectedProject.selected_project.id,
            skip: 0,
          });
          let cDcs = res.result.result ?? [];
          dcs = cDcs.filter(dc => dc.cint_type === '4');
        } else {
          let res = await dcSearchGraphQL(
            userProfile?.apollo_client,
            selectedProject.selected_project.id,
            -1,
            -1,
            '',
            100,
            0
          );
          dcs = res.dcSearch.result ? res.dcSearch.result : [];
        }
      }

      const devices = {};

      for (let i = 0; i < dcs.length; i++) {
        const dc = dcs[i];

        let existingDevices = {};
        let availableDevices = null; // only for air devices

        if (type === 'cintd') {
          const response =
            await pluginHook.commonIntegrationService.getCloudDeviceCollectionDevices(dc.id);
          if (response && response.result && isArray(response.result.devices)) {
            devices[dc.id] = response.result.devices;
            response.result.devices.map(device => (existingDevices[device.id] = device));
          }
        } else if (dc && dc.dc_cat === 2) {
          const response = await airDCTuyaDeviceListGraphQL(userProfile?.apollo_client, dc.id);
          if (
            response &&
            response.airDCTuyaDeviceList &&
            isArray(response.airDCTuyaDeviceList.items)
          ) {
            devices[dc.id] = response.airDCTuyaDeviceList.items;
            const avaibleDeviceMap = {};
            response.airDCTuyaDeviceList.items.map(
              device => (avaibleDeviceMap[device.id] = device)
            );

            availableDevices = avaibleDeviceMap;
          }
          const res = await airDeviceSearch(
            userProfile?.apollo_client,
            selectedProject.selected_project.id,
            dc.id,
            [],
            '',
            100,
            0
          );
          devices[dc.id] = res.result;
          if (res.result) {
            res.result.map(device => (existingDevices[device.id] = device));
          }
        } else if (dc && dc.dc_cat === 1) {
          const res = await proDeviceSearchGraphQL(
            userProfile?.apollo_client,
            selectedProject.selected_project.id,
            dc.id,
            '',
            '',
            '',
            '',
            -1,
            [],
            [],
            '',
            100,
            0
          );

          devices[dc.id] = res.proDeviceSearch.result ? res.proDeviceSearch.result : [];

          if (res.proDeviceSearch.result) {
            res.proDeviceSearch.result.map(device => (existingDevices[device.id] = device));
          }
        }
        if (reset) {
          dispatch(
            updateConfigurableContainer({
              item: {
                existingDevices,
                availableDevices,
                deviceMap: {
                  asNew: {},
                  toExisting: {},
                },
              },
              key: dc.id,
              flag: 'UPDATE',
            })
          );
        } else {
          dispatch(
            updateConfigurableContainer({
              item: {
                existingDevices,
                availableDevices,
              },
              key: dc.id,
              flag: 'UPDATE',
            })
          );
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    } finally {
      setLoadingDevices(false);
    }
  };

  const [proDcPins, setProDcPins] = useState<any>({});

  useEffect(() => {
    if (type === 'prodc') {
      Object.keys(configurableList).map(dcId => {
        const dc = configurableList[dcId];

        if (
          (dc.dc as DeviceCollectionSearchOutItem).dc_cat === 1 &&
          (dc.dc as DeviceCollectionSearchOutItem).dc_type !== 9
        ) {
          getDCPins(dcId, 'relay');
        }
        return 0;
      });
    }
  }, [configurableList, type]);

  const getDCPins = async (dcId: string, pinType: string) => {
    try {
      const res = await proDCGetPins(userProfile.apollo_client, dcId, pinType);

      setProDcPins({...proDcPins, [dcId]: res.pins});
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    }
  };

  const onSendBack = (device, isExistingDevice, isavailableDevice, configContainerId: any) => {
    const existingOrAvailabelDeviceId = device.id;
    const configContainer = configurableList[configContainerId];

    let droppedItemInfo: DeviceFromRestoreContainer = null;

    if (isExistingDevice) {
      droppedItemInfo = configContainer.deviceMap.toExisting[existingOrAvailabelDeviceId].device;

      dispatch(
        removeDevice({
          type: DEVICE_POSITIONS.TO_EXISTING,
          configurableDcId: configContainerId,
          key: existingOrAvailabelDeviceId,
        })
      );
    } else if (isavailableDevice) {
      droppedItemInfo = configContainer.deviceMap.toAvailable[existingOrAvailabelDeviceId].device;
      dispatch(
        removeDevice({
          type: DEVICE_POSITIONS.TO_AVAILABLE,
          configurableDcId: configContainerId,
          key: existingOrAvailabelDeviceId,
        })
      );
    }

    dispatch(
      v2PullDroppedItem({
        type: type as any,
        dcId: droppedItemInfo.id.split('_')[1],
        deviceId: droppedItemInfo.id,
      })
    );
    console.log(device, isExistingDevice, isavailableDevice, configContainerId);
  };

  function isDropDisabled(dc: DeviceCollectionSearchOutItem | CiDeviceContainer) {
    if (dc && (dc as DeviceCollectionSearchOutItem).dc_cat === 2) {
      return true;
    }

    if (dc && (dc as CiDeviceContainer).cint_type) {
      return true;
    }

    return false;
  }

  function getDroppableType(dc: DeviceCollectionSearchOutItem | CiDeviceContainer) {
    if ((dc as CiDeviceContainer).cint_type) {
      return 'cintd'; // TODO: maybe change this based on the cint type eg: 'cint_shelly', 'cint_enode' etc
    }

    if ((dc as DeviceCollectionSearchOutItem).dc_cat === 2) {
      return 'air';
    }

    if (
      (dc as DeviceCollectionSearchOutItem).dc_cat === 1 &&
      (dc as DeviceCollectionSearchOutItem).dc_type === 9
    ) {
      return 'pro_var';
    }

    return 'pro';
  }

  return (
    <Grid container spacing={2}>
      {configurableStepListkeys.map(dcKey => {
        if (!configurableList || !configurableList[dcKey]) return <></>;
        const {dc, existingDevices, deviceMap, availableDevices} = configurableList[dcKey];
        return (
          <Grid item md={12} key={dcKey}>
            <DeviceContainer
              title={`${dc.name}`}
              key={dc.id}
              dc={dc}
              position={DEVICE_CONTAINER_LOC.IN_CONFIGURABLE_CONTAINER}
              isContentLoading={loadingDevices}
            >
              {({searchText}) => (
                <Grid container spacing={1}>
                  {clickedDeviceInfo.showPanel &&
                  clickedDeviceInfo.selectedDevice &&
                  clickedDeviceInfo.selectedDevice.id &&
                  ((clickedDeviceInfo.isClickedOnAvailableDevice &&
                    clickedDeviceInfo.selectedDevice.configContainerId === dc.id) ||
                    clickedDeviceInfo.selectedDevice.id.split('_')[1] === dc.id) ? (
                    <>
                      {clickedDeviceInfo.showPanel &&
                        clickedDeviceInfo.isClickedOnAvailableDevice &&
                        availableDevices &&
                        availableDevices[clickedDeviceInfo.selectedDevice.id] && (
                          <Grid item md={12}>
                            <DeviceInfo
                              relayPins={[]}
                              device={clickedDeviceInfo.selectedDevice}
                              deviceConfig={clickedDeviceInfo.deviceConfig}
                              configurableDeviceContainerId={dc.id}
                              isExistingDevice={false}
                              isavailableDevice
                              onPullBack={(device, isExistingDevice, isavailableDevice) =>
                                onSendBack(device, isExistingDevice, isavailableDevice, dcKey)
                              }
                            />
                          </Grid>
                        )}
                      {clickedDeviceInfo.showPanel &&
                        !clickedDeviceInfo.isClickedOnAvailableDevice &&
                        clickedDeviceInfo.selectedDevice &&
                        (clickedDeviceInfo.selectedDevice.id.indexOf(dc.id) > -1 ||
                          deviceMap.asNew[clickedDeviceInfo.selectedDevice.id]) && (
                          <Grid item md={12}>
                            <DeviceInfo
                              relayPins={proDcPins[dc.id] ? proDcPins[dc.id] : []}
                              device={clickedDeviceInfo.selectedDevice}
                              deviceConfig={clickedDeviceInfo.deviceConfig}
                              configurableDeviceContainerId={dc.id}
                              isExistingDevice={
                                !clickedDeviceInfo.isClickedOnAvailableDevice &&
                                clickedDeviceInfo.selectedDevice.id.indexOf(dc.id) > -1
                              }
                              isavailableDevice={false}
                              onPullBack={(device, isExistingDevice, isavailableDevice) =>
                                onSendBack(device, isExistingDevice, isavailableDevice, dcKey)
                              }
                            />
                          </Grid>
                        )}
                    </>
                  ) : (
                    <>
                      <Grid item md={12}>
                        <Group
                          droppableId={`configContainer${dc.id}`}
                          isDropDisabled={isDropDisabled(dc)}
                          droppableType={getDroppableType(dc)}
                        >
                          {() => (
                            <>
                              {(dc as DeviceCollectionSearchOutItem).dc_cat === 2 && (
                                <Typography>Linked Devices In Air Account</Typography>
                              )}

                              <Grid container spacing={1}>
                                {isArray(Object.keys(existingDevices)) &&
                                  Object.keys(existingDevices).length === 0 &&
                                  (dc as DeviceCollectionSearchOutItem).dc_cat === 2 && (
                                    <Grid item md={12}>
                                      <Alert severity="info">No Linked Devices</Alert>
                                    </Grid>
                                  )}
                                {isArray(Object.keys(existingDevices)) &&
                                  Object.keys(existingDevices).length > 0 &&
                                  Object.keys(existingDevices).map(deviceKey => {
                                    const device = existingDevices[deviceKey];
                                    if (searchText && searchText.length > 0) {
                                      if (!(device.name.toLowerCase().indexOf(searchText) > -1)) {
                                        return <></>;
                                      }
                                    }
                                    return (
                                      <Grid item md={3} sm={6} key={device.id}>
                                        <Card
                                          draggableId={`devicedrag${device.id}`}
                                          index={1}
                                          dragDisabled
                                        >
                                          {({provided, snapshot}) => (
                                            <Box>
                                              <Device
                                                deviceStatus={
                                                  DEVICE_STATUS_IN_RESTORE_STEP.EXISTING_DEVICE_IN_CONFIGURABLE_CONTAINER
                                                }
                                                device={device}
                                                dragHandlerProps={provided.dragHandleProps}
                                                bgColor={
                                                  deviceMap &&
                                                  deviceMap.toExisting &&
                                                  deviceMap.toExisting[device.id] &&
                                                  deviceMap.toExisting[device.id].device
                                                    ? '#CEE2FD'
                                                    : 'white'
                                                }
                                                dragDisabled
                                              />
                                            </Box>
                                          )}
                                        </Card>
                                      </Grid>
                                    );
                                  })}
                                <RenderDroppedCards
                                  deviceMap={deviceMap}
                                  deviceMapContainerId={dcKey}
                                  type={type}
                                  searchText={searchText}
                                />
                              </Grid>
                            </>
                          )}
                        </Group>
                      </Grid>
                      {availableDevices &&
                        dc &&
                        (dc as DeviceCollectionSearchOutItem).dc_cat === 2 && (
                          <Grid item md={12}>
                            <Group
                              droppableId={`configContainerAvaibleDevices${dc.id}`}
                              droppableType="air"
                              isDropDisabled
                            >
                              {() => (
                                <>
                                  {dc && (dc as DeviceCollectionSearchOutItem).dc_cat === 2 && (
                                    <Typography>Available Devices In Air Account</Typography>
                                  )}

                                  <Grid container spacing={1}>
                                    {isArray(Object.keys(availableDevices)) &&
                                      Object.keys(availableDevices).length === 0 && (
                                        <Grid item md={12}>
                                          <Alert severity="error">
                                            No Available Devices. Please delete this and reconfigure
                                            the container.
                                          </Alert>
                                        </Grid>
                                      )}
                                    {isArray(Object.keys(availableDevices)) &&
                                      Object.keys(availableDevices).map(tuyaId => {
                                        const device = availableDevices[tuyaId];
                                        if (searchText && searchText.length > 0) {
                                          if (
                                            !(device.name.toLowerCase().indexOf(searchText) > -1)
                                          ) {
                                            return <></>;
                                          }
                                        }
                                        return (
                                          <Grid item md={3} sm={6} key={device.id}>
                                            <Card
                                              draggableId={`devicedragavailable${device.id}`}
                                              index={1}
                                              dragDisabled
                                            >
                                              {({provided, snapshot}) => (
                                                <Box>
                                                  <Device
                                                    containerId={dc && dc.id}
                                                    deviceStatus={
                                                      DEVICE_STATUS_IN_RESTORE_STEP.EXISTING_DEVICE_IN_CONFIGURABLE_CONTAINER
                                                    }
                                                    avaibleDevice={device}
                                                    dragHandlerProps={provided.dragHandleProps}
                                                    dragDisabled
                                                    bgColor={
                                                      deviceMap &&
                                                      deviceMap.toAvailable &&
                                                      deviceMap.toAvailable[device.id] &&
                                                      deviceMap.toAvailable[device.id].device
                                                        ? '#CEE2FD'
                                                        : 'white'
                                                    }
                                                  />
                                                </Box>
                                              )}
                                            </Card>
                                          </Grid>
                                        );
                                      })}
                                  </Grid>
                                </>
                              )}
                            </Group>
                          </Grid>
                        )}
                    </>
                  )}
                </Grid>
              )}
            </DeviceContainer>
          </Grid>
        );
      })}
    </Grid>
  );
});

const RenderDroppedCards: React.FC<{
  deviceMap: any;
  deviceMapContainerId: string;
  type: string;
  searchText: string;
}> = React.memo(({deviceMap, deviceMapContainerId, type, searchText}) => {
  const dispatch = useDispatch();
  return (
    <>
      {Object.keys(deviceMap.asNew).map(mappedItem => {
        const {device} = deviceMap.asNew[mappedItem];
        if (searchText && searchText.length > 0) {
          if (!(device.name.toLowerCase().indexOf(searchText) > -1)) {
            return <></>;
          }
        }
        return (
          <Grid item md={3} sm={6} key={mappedItem}>
            <Card draggableId={`devicedrag${mappedItem}`} index={1}>
              {({provided, snapshot}) => (
                <Box>
                  <Device
                    deviceStatus={DEVICE_STATUS_IN_RESTORE_STEP.DROPPED_DEVICE_FROM_TEMPLATE}
                    device={cloneDeep({...device, id: mappedItem})}
                    dragHandlerProps={provided.dragHandleProps}
                    handleOnPullClick={() => {
                      console.log({
                        type: type as any,
                        dcId: device && device.prodc_id ? device.prodc_id : device.airdc_id,
                        deviceId: mappedItem,
                      });
                      // return;
                      dispatch(
                        removeDevice({
                          type: DEVICE_POSITIONS.AS_NEW,
                          configurableDcId: deviceMapContainerId,
                          key: mappedItem,
                        })
                      );

                      dispatch(
                        v2PullDroppedItem({
                          type: type === 'locvar' ? 'lvar' : (type as any),
                          dcId: device && device.prodc_id ? device.prodc_id : device.airdc_id,
                          deviceId: mappedItem,
                        })
                      );
                    }}
                  />
                </Box>
              )}
            </Card>
          </Grid>
        );
      })}
    </>
  );
});

export default ConfigurableDeviceContainer;
