/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import TextField from '@mui/material/TextField';
import styles from './UVAppBrowserItem.module.css';
import { IUserViewItem } from '../../../types/types';
import { IAppBrowserItem } from './types';

type ComponentProps = {
  fieldConfig: any;
  uvItem: IUserViewItem | null;
  submitRefresh: number;
  onChange: (payload:any) => void;
  updateCustomFieldErrors: (error: any) => void;
};
interface IForm {
  ios_custom_url: string;
  android_activity_name: string;
  ios_url: string;
  android_url: string;
  web_url: string;
}
const getDefaultFormData = () => ({
  ios_custom_url: '',
  android_activity_name: '',
  ios_url: '',
  android_url: '',
  web_url: '',
});
// eslint-disable-next-line import/prefer-default-export
export const UVAppBrowserItem: React.FunctionComponent<ComponentProps> = ({
  fieldConfig,
  uvItem,
  submitRefresh,
  onChange,
  updateCustomFieldErrors,
}) => {
  const [formDefaults, setFormDefaults] = useState<IForm>(getDefaultFormData());
  const formRef = useRef<any>();

  useEffect(() => {
    if (uvItem) {
      console.log(uvItem);

      if (uvItem.settings && uvItem.settings.app_browser_item) {
        const data: IAppBrowserItem = uvItem.settings.app_browser_item;
        formRef.current.setValues({
          ios_custom_url: data.ios_custom_url,
          android_activity_name: data.android_activity_name,
          ios_url: data.ios_url,
          android_url: data.android_url,
          web_url: data.web_url,
        });
      }
    }
  }, [uvItem]);
  const onSubmitForm = (values: IForm) => {
    const keys = Object.keys(values);
    let isOneValuePresent = false;
    keys.forEach((k) => {
      const v = ({ ...values } as any)[k] as any;
      if (v && v.trim().length > 0) {
        isOneValuePresent = true;
      }
    });
    if (isOneValuePresent) {
      updateCustomFieldErrors({
        app_browser_item: {
          invalid: false,
        },
      });
    } else {
      updateCustomFieldErrors({
        app_browser_item: {
          invalid: true,
          message: 'One url value Expected',
        },
      });
    }
    const data = {
      app_browser_item: { ...values },
    };
    onChange({
      action: 'data',
      form: 'app_browser_item',
      data,
      errors: {},
    });
  };
  useEffect(() => {
    if (submitRefresh > 0) {
      formRef.current.submitForm();
      console.log('Form submit');
    }
  }, [submitRefresh]);
  return (
    <div className="row">
      <Formik
        initialValues={formDefaults}
        validationSchema={Yup.object().shape({
          ios_custom_url: Yup.string().max(400, 'Maximum iOS custom url length 400'),
          android_activity_name: Yup.string().max(400, 'Maximum Android activity name length 400'),
          ios_url: Yup.string().max(400, 'Maximum Ios url length 400'),
          android_url: Yup.string().max(400, 'Maximum android url length 400'),
          web_url: Yup.string().max(400, 'Maximum web url length 400'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
        innerRef={formRef}
      >
        {({
          errors, handleBlur, handleChange, touched, values, submitForm,
        }) => (
          <form>
            <div className={styles.textInput}>
              <TextField
                className="uv-ui-form-control-full-width"
                error={Boolean(touched.ios_custom_url && errors.ios_custom_url)}
                helperText={touched.ios_custom_url && errors.ios_custom_url}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.ios_custom_url}
                name="ios_custom_url"
                label="iOS custom url"
                size="small"
                variant="outlined"
              />
            </div>
            <div className={styles.textInput}>
              <TextField
                className="uv-ui-form-control-full-width"
                error={Boolean(touched.android_activity_name && errors.android_activity_name)}
                helperText={touched.android_activity_name && errors.android_activity_name}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.android_activity_name}
                name="android_activity_name"
                label="Android activity name"
                size="small"
                variant="outlined"
              />
            </div>
            <div className={styles.textInput}>
              <TextField
                className="uv-ui-form-control-full-width"
                error={Boolean(touched.ios_url && errors.ios_url)}
                helperText={touched.ios_url && errors.ios_url}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.ios_url}
                name="ios_url"
                label="iOS url"
                size="small"
                variant="outlined"
              />
            </div>
            <div className={styles.textInput}>
              <TextField
                className="uv-ui-form-control-full-width"
                error={Boolean(touched.android_url && errors.android_url)}
                helperText={touched.android_url && errors.android_url}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.android_url}
                name="android_url"
                label="Android url"
                size="small"
                variant="outlined"
              />
            </div>
            <div className={styles.textInput}>
              <TextField
                className="uv-ui-form-control-full-width"
                error={Boolean(touched.web_url && errors.web_url)}
                helperText={touched.web_url && errors.web_url}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.web_url}
                name="web_url"
                label="Web url"
                size="small"
                variant="outlined"
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
