/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { DeleteForever, ArrowForward } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import {
  IconButton,
  InputAdornment,
  TextField,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Box,
  Typography,
  Switch,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './AddEditMember.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import CustomOptionSelector from '../../../../components/shared/CustomOptionSelector/CustomOptionSelector';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import {
  addProjectMemeberGraphQL,
  deleteProjectUserGraphQL,
  getProjectMemeberInfo,
  projectUserPermissionUpdateGraphQL,
} from '../../../../services/member/member.service';
import { LoadingComponent } from '../../../../components/shared/Loading/Loading';

import GreenButton from '../../../../components/shared/buttons/AuthButton/GreenButton';

import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import { userviewSearchGraphQL } from '../../../../services/user-view/user-view.service';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import { appConsoleLog } from '../../../../utility/appUtils';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import { useQueryNumber } from '../../../../utility/CustomHooks/useQueryNumber';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';

interface IState {
  user_data?: any;
}
type IMemberFormValue = {
  member_email: string;
  member_description: string;
  member_type: any;
};

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    padding: '20px',
    overflow: 'scroll',
  },
});

export const AddEditMemberComponent: React.FunctionComponent<any> = () => {
  const member_email = useQueryString('user_email') as string;
  const myAccessLevel = useQueryNumber('my_access');
  const user = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyle();
  const location = useLocation();
  // const user_data = (location.state as IState)?.user_data;

  const [userViewList, setUserViewList] = useState([]);
  const [accessLevel, accessLevelSet] = useState(-1);
  const [permissionType, permissionTypeSet] = useState(-1);
  const [selectedUserViews, setSelectedUserView] = useState([] as any);

  const [editUserSelected, editUserSelectedSet] = useState(false);
  const [editUser, setEditUser] = useState<{
    access_level: number;
    invite_to_userviews: any;
    user_email?: string;
    permission_status?: any;
    permission_id: string;
    description: string;
    permission_enabled: number;
  }>(null);

  // const [editUserEmail] = useState(useQueryString("user_email"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    if (
      !selectedProject
      || !selectedProject.selected_project
      || !selectedProject.selected_project.id
    ) {
      navigateRoute('/app');
    }
  }, [selectedProject]);

  useEffect(() => {
    async function init() {
      if (member_email && member_email.length > 0) {
        try {
          const res = await getProjectMemeberInfo(
            userProfile.apollo_client,
            selectedProject.selected_project.id,
            member_email,
          );
          if (!res.projectUserInfo) throw new Error('Memeber Not Found');

          const { project_permission } = res.projectUserInfo;
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const editUser = {
            access_level: project_permission.access_level,
            user_email: project_permission.user_email,
            permission_status: project_permission.permission_status,
            invite_to_userviews: project_permission.invite_to_userviews,
            permission_id: project_permission.id,
            description: project_permission.user_title,
            permission_enabled: project_permission.permission_enabled,
          };

          setEditUser(editUser);
          setSelectedUserView(editUser.invite_to_userviews);
        } catch (err: any) {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
        editUserSelectedSet(true);
      }
    }
    init();
    console.log(userProfile.user_profile);
  }, [member_email]);

  const forceUpdate = React.useReducer(() => ({}), {})[1];

  const userViewSelect = (userViewId: any) => {
    const index = selectedUserViews.indexOf(userViewId, 0);
    if (index > -1) {
      // setSelectedUserView([...selectedUserViews].splice(index, 1));
      selectedUserViews.splice(index, 1);
    } else {
      // setSelectedUserView([...userViewId]);
      selectedUserViews.push(userViewId);
    }
    forceUpdate();
  };

  useEffect(() => {
    if (
      !selectedProject
      || !selectedProject.selected_project
      || !selectedProject.selected_project.id
    ) {
      navigateRoute('/app');
    }
    if (!selectedProject.selected_project.id) {
      return;
    }
    userviewSearchGraphQL(
      userProfile?.apollo_client,
      selectedProject.selected_project.id,
      1,
      '',
      100,
      0,
    )
      .then((res) => {
        setUserViewList(res.userviewSearch.result ? res.userviewSearch.result : 0);
        appConsoleLog(userViewList);
      })
      .catch((err) => {
        // alert("Something went wrong!")
      });
  }, []);

  const deleteMember = async () => {
    try {
      setLoading(true);
      appConsoleLog('deleteMember');

      await deleteProjectUserGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
        editUser.permission_id,
      )
        .then(async (res) => {
          setLoading(false);
          snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_DELETE_SUCCESS, {
            key: 'key1',
            ...SuccessSnackbarOptions,
            action: SuccessSnackbarActions(snackbar.closeSnackbar),
          });
          navigateRoute(`/app/members?projectId=${selectedProject.selected_project.id}`);
        })
        .catch((err) => {
          setLoading(false);
          appConsoleLog(err);
          snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_DELETE_ERROR, {
            key: 'key3',
            ...ErrorSnackbarOptions,
            action: ErrorSnackbarActions(snackbar.closeSnackbar),
          });
        });
    } catch (error) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  const onSubmitForm = async (
    values: IMemberFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    if (!values.member_type || values.member_type === 0) {
      snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_PERMISSION_TYPE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
      return false;
    }
    setLoading(true);
    try {
      if (!selectedProject.selected_project.id) {
        return null;
      }

      let isOwner = false;
      const allUvIds = [];
      if ([1, 2, 3].indexOf(Number(values.member_type)) > -1) {
        isOwner = true;
        userViewList.map((item) => allUvIds.push(item.id));
      }

      console.log(selectedUserViews, isOwner, values.member_type, allUvIds);
      if (!isOwner && selectedUserViews.length === 0) {
        throw new Error('You have to select atleast one user view');
      }

      if (editUserSelected) {
        await projectUserPermissionUpdateGraphQL(
          userProfile?.apollo_client,
          selectedProject.selected_project.id,
          values.member_email.trim().toLowerCase(),
          values.member_description.trim(),
          values.member_type,
          isOwner ? allUvIds : selectedUserViews,
          editUser.permission_enabled,
        )
          .then(async (res) => {
            setLoading(false);
            snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_UPDATE_SUCCESS, {
              key: 'key1',
              ...SuccessSnackbarOptions,
              action: SuccessSnackbarActions(snackbar.closeSnackbar),
            });
            resetForm();
            navigateRoute(`/app/members?projectId=${selectedProject.selected_project.id}`);
          })
          .catch((err) => {
            setLoading(false);
            appConsoleLog(err);
            snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_UPDATE_ERROR, {
              key: 'key3',
              ...ErrorSnackbarOptions,
              action: ErrorSnackbarActions(snackbar.closeSnackbar),
            });
          });
      } else {
        await addProjectMemeberGraphQL(
          userProfile?.apollo_client,
          selectedProject.selected_project.id,
          values.member_email.trim().toLowerCase(),
          values.member_description.trim(),
          values.member_type,
          isOwner ? allUvIds : selectedUserViews, // selectedUserViews
        )
          .then(async (res) => {
            setLoading(false);
            snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_CREATE_SUCCESS, {
              key: 'key1',
              ...SuccessSnackbarOptions,
              action: SuccessSnackbarActions(snackbar.closeSnackbar),
            });
            resetForm();
            navigateRoute(`/app/members?projectId=${selectedProject.selected_project.id}`);
          })
          .catch((err) => {
            setLoading(false);
            appConsoleLog(err);
            snackbar.enqueueSnackbar(APP_TEXT.members.MEMBER_CREATE_ERROR, {
              key: 'key3',
              ...ErrorSnackbarOptions,
              action: ErrorSnackbarActions(snackbar.closeSnackbar),
            });
          });
      }
    } catch (err: any) {
      setLoading(false);
      snackbar.enqueueSnackbar(err.message, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
    return null;
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            {editUserSelected ? APP_TEXT.members.EDIT_MEMBER : APP_TEXT.members.ADD_MEMBER}
            {editUser && editUser.user_email === userProfile.user_profile.email && (
              <Chip label="My Self" size="small" style={{ marginLeft: '1%' }} />
            )}
          </Typography>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className="custom-card2 pb-5 mb-5">
          <div className="custom-card2-container">
            <div className="custom-card2-in ">
              <div className="custom-card2-title" style={{ display: 'flex', alignItems: 'center' }}>
                <h2>{APP_TEXT.members.MEMBER_DETAILS}</h2>
                {!editUserSelected && myAccessLevel === 5 && (
                  <Chip
                    label="You Don't have permission to add members"
                    style={{ marginLeft: '2%' }}
                    icon={<ErrorOutlineIcon />}
                    color="secondary"
                  />
                )}
                {((myAccessLevel === 4 && editUserSelected) // shareable users can't update/delete
                  // eslint-disable-next-line max-len
                  || (editUserSelected && myAccessLevel > editUser.access_level)) && ( // low level access can't manipulate high level access
                  <Chip
                    label="You Don't have permission to update this member"
                    style={{ marginLeft: '2%' }}
                    icon={<ErrorOutlineIcon />}
                    color="secondary"
                  />
                )}
                <hr />
              </div>
              <Formik
                initialValues={{
                  member_email: editUser && editUser.user_email ? editUser.user_email : '',
                  member_description: editUser && editUser.description ? editUser.description : '',
                  member_type: editUser && editUser.access_level ? `${editUser.access_level}` : '',
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  member_email: Yup.string().email().required('Required'),
                  member_description: Yup.string().required('Required'),
                  member_type: Yup.string().required('Required'),
                })}
                onSubmit={onSubmitForm}
              >
                {({
                  errors, handleBlur, handleChange, touched, values, submitForm,
                }) => (
                  <form>
                    <div className="custom-card-card2 mt-2">
                      <div className="m-0 col-md-12 nopaddingOnly row">
                        <div className="col-md-12 nopaddingOnly">
                          <div className="col-md-6 nopaddingOnly">
                            <div>
                              <TextField
                                className={styles.fieldSpace}
                                error={Boolean(touched.member_email && errors.member_email)}
                                disabled={editUserSelected}
                                helperText={touched.member_email && errors.member_email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.member_email}
                                name="member_email"
                                label={APP_TEXT.members.FORM_FIELD_MEMBER_EMAIL}
                                size="medium"
                                type="text"
                                variant="outlined"
                                data-testid="email"
                              />
                            </div>
                            <div>
                              <FormControl className={styles.fieldSpace}>
                                <InputLabel id="demo-simple-select-label">
                                  {APP_TEXT.members.FORM_FIELD_MEMBER_LOCATION_PERMISSION}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="member_type"
                                  disabled={
                                    !!(editUser
                                    && editUser.access_level === 1
                                    && editUser.user_email === userProfile.user_profile.email)
                                  }
                                  value={values.member_type}
                                  onChange={handleChange}
                                >
                                  <MenuItem disabled={myAccessLevel > 1} value={1}>
                                    {' '}
                                    {APP_TEXT.members.MEMBER_TYPE1}
                                  </MenuItem>
                                  <MenuItem disabled={myAccessLevel > 2} value={2}>
                                    {' '}
                                    {APP_TEXT.members.MEMBER_TYPE2}
                                  </MenuItem>
                                  <MenuItem disabled={myAccessLevel > 3} value={3}>
                                    {' '}
                                    {APP_TEXT.members.MEMBER_TYPE3}
                                  </MenuItem>
                                  <MenuItem disabled={myAccessLevel > 4} value={4}>
                                    {' '}
                                    {APP_TEXT.members.MEMBER_TYPE7}
                                  </MenuItem>
                                  <MenuItem disabled={myAccessLevel >= 5} value={5}>
                                    {' '}
                                    {APP_TEXT.members.MEMBER_TYPE4}
                                  </MenuItem>
                                  {/* <MenuItem value={4}>
                                        {" "}
                                        {APP_TEXT.members.MEMBER_TYPE5}
                                      </MenuItem> */}
                                </Select>
                                {errors.member_type && touched.member_type && (
                                <div className={styles.inputFeedback}>{errors.member_type}</div>
                                )}
                              </FormControl>
                            </div>
                          </div>
                          <div className="col-md-6  ">
                            <TextField
                              className={styles.fieldSpace}
                              error={Boolean(
                                touched.member_description && errors.member_description,
                              )}
                              helperText={touched.member_description && errors.member_description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.member_description}
                              name="member_description"
                              label={APP_TEXT.members.FORM_FIELD_MEMBER_DESCIPTION}
                              size="medium"
                              type="text"
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton edge="end" size="large" />
                                  </InputAdornment>
                                ),
                              }}
                              data-testid="password"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="custom-card2-title mt-2"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h2>{APP_TEXT.members.ASSIGN_USER_VIEWS}</h2>
                      {editUser && (
                      <Tooltip
                        title={
                            editUser.permission_enabled === 1
                              ? 'Disabled the user access'
                              : 'Enabled the user access'
                          }
                      >
                        <Switch
                          checked={!!(editUser && editUser.permission_enabled === 1)}
                          onChange={() => {
                            setEditUser({
                              ...editUser,
                              permission_enabled: editUser.permission_enabled === 1 ? 2 : 1,
                            });
                          }}
                        />
                      </Tooltip>
                      )}
                      <hr />
                    </div>
                    <div className="custom-card-body">
                      <div className="row" style={{ padding: '2%' }}>
                        {userViewList.map((userVIew: any) => {
                          if ([1, 2, 3].indexOf(Number(values.member_type)) > -1) {
                            // Owner, assigne all UVs by default
                            return (
                              <div className="col-md-4 nopaddingOnly">
                                <CustomOptionSelector
                                  title={userVIew.name ? userVIew.name : 'N/A'}
                                  selected
                                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                                  onClick={() => {}}
                                  disabled
                                  selectedDisabled={false}
                                />
                              </div>
                            );
                          }
                          return (
                            <div className="col-md-4 nopaddingOnly">
                              <CustomOptionSelector
                                title={userVIew.name ? userVIew.name : 'N/A'}
                                selected={
                                  !!(Number(values.member_type) >= 4
                                  && selectedUserViews.indexOf(userVIew.id, 0) >= 0)
                                }
                                onClick={() => userViewSelect(userVIew.id)}
                                disabled={!(Number(values.member_type) >= 4)}
                                selectedDisabled={false}
                              />
                            </div>
                          );
                        })}

                        {/*
                            <div className={"col-md-4 nopaddingOnly"}>
                              <CustomOptionSelector
                                title="test 2"
                                selected={selectedUserViews.includes(2)}
                                onClick={() => userViewSelect(2)}
                                disabled={values.member_type === 3 ? false : true}
                                selectedDisabled={false}
                              ></CustomOptionSelector>
                            </div> */}
                      </div>
                      <div className="row" style={{ padding: '2%' }}>
                        <div className="col-md-4 nopaddingOnly">
                          <GreenButton
                            text={
                              editUserSelected
                                ? APP_TEXT.members.EDIT_MEMBER_BTN
                                : APP_TEXT.members.ADD_MEMBER_BTN
                            }
                            onClick={submitForm}
                            disable={
                              loading
                              // eslint-disable-next-line max-len
                              || (editUserSelected && myAccessLevel === 4) // a sharable user can't edit
                              || (editUserSelected && myAccessLevel > editUser.access_level) // low level access can't delete high level access
                            }
                            data-testid="submitButton"
                          >
                            {loading ? (
                              <LoadingComponent />
                            ) : (
                              <ArrowForward />
                            )}
                          </GreenButton>
                        </div>
                        <div className="col-md-5 nopaddingOnly" />
                        <div className="col-md-3 nopaddingOnly">
                          {editUserSelected ? (
                            <ClearLeftIconButton
                              text={APP_TEXT.members.DELETE_MEMBER_BTN}
                              onClick={deleteMember}
                              disable={
                                myAccessLevel === 4 // a sharable user can't delete
                                || loading
                                || myAccessLevel > editUser.access_level
                                || editUser.user_email === userProfile.user_profile.email // you can't delete yourself
                              }
                              color="red"
                            >
                              {loading ? (
                                <LoadingComponent />
                              ) : (
                                <DeleteForever />
                              )}
                            </ClearLeftIconButton>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Layout>
  );
};

export default AddEditMemberComponent;
