/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, {
  useContext, useEffect, useState, useCallback,
} from 'react';
import Skeleton from '@mui/material/Skeleton';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import * as Yup from 'yup';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApolloFunctions } from 'src/store/Apollo/ApolloFunctions';
import Box from '@mui/material/Box';
import qs from 'query-string';
import TextField from '@mui/material/TextField';
import {
  Button, IconButton, InputAdornment, Tooltip,
} from '@mui/material';
import {
  Delete, EditOutlined, Save, Visibility, VisibilityOff, VpnKey,
} from '@mui/icons-material';
import { Formik } from 'formik';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import clsx from 'clsx';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './SingleContainerAir.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import AirLiveCard from '../../../../components/shared/AirLiveCard/AirLiveCard';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { appConsoleError, appConsoleLog, AppUtils } from '../../../../utility/appUtils';
import {
  airDCDeleteGraphQL,
  airDCGetGraphQL,
  airDCTuyaDeviceListGraphQL,
  airDCUpdateGraphQL,
  airDeviceSearch,
} from '../../../../services/air-device/air-device.service';
import { IconTextCard2Component } from '../../../../components/shared/IconTextCard2/IconTextCard2';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import { useQueryNumber } from '../../../../utility/CustomHooks/useQueryNumber';
import { IAirDevice } from '../../AirDeviceCreateEdit/types';
import { DeviceCardAir } from '../../../../components/shared/DeviceCard/DeviceCardAir';
import hubImage from '../../../../assets/images/placeholders/hub-img.png';
import { ITEM_DEFAULTS } from '../../../../config/deviceConfig/item_defaults';
import {
  ErrorSnackbarActions,
  ErrorSnackbarOptions,
  SuccessSnackbarActions,
  SuccessSnackbarOptions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import { PageHeader } from '../../../../components/layout/PageHeader';
import Layout from '../../../../components/layout/Layout';
import { IAirContainerCredentials } from './types';
import {
  airDCPasswordGet,
  airDCPasswordReset,
  airDCSync,
} from '../../../../services/device-container/air-device-container.service';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';
import { ConfirmDelete } from '../../../../components/shared/CustomDeleteModal/ConfirmDelete';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: null,
};

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1ca086',
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#818181',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#1ca086',
      opacity: 1,
    },
    '&$selected': {
      color: '#1ca086',
      // fontWeight: 'bold',
    },
    '&:focus': {
      color: '#1ca086',
    },
  },
  selected: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<any>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '0 !important',
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    // backgroundColor: theme.palette.background.paper,
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
    overflow: 'scroll',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    /* Utility/Mid Gray */
    border: '1px solid #D9DFE0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '18px',
    margin: '10px 0',
  },
}));
interface IState {
  dc?: any;
}

export const SingleContainerAirComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);
  const selectedProjectID = useQueryString('projectId') as string;
  const selectedDCID = useQueryString('dcId') as string;

  const [isInit, setInit] = useState(false);

  // const projectName = qs.parse(window.location.search)["name"];
  const [dcDetails, setDCDetails] = useState<IContainerDetails>();
  const [projectName, setProjectName] = useState(qs.parse(window.location.search).name);

  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [airLiveResultCount, setAirLiveResultCount] = useState('');
  const [airLiveList, airLiveListList] = useState([]);

  const [proDeviceSelected, proDeviceSelectedSet] = useState(false);
  const [proDeviceDetails, editProSet] = useState({});
  const [registerdDevices, setRegisteredDevices] = useState<IAirDevice[]>([]);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [shouldDeleteHub, setShouldDeleteHub] = useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [deleteState, setDeleteState] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [refreshDeviceList, RefreshDeviceListNow] = useState(0);

  const [isdemo, setIsDemo] = useState(false);

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (selectedDCID) {
      init();
    }
  }, [selectedProjectID, selectedDCID, refreshDeviceList]);

  useEffect(() => {
    initAllAirDevices();
  }, [selectedDCID]);

  const init = useCallback(async () => {
    try {
      const [allAirDevices, registeredAirDevices_] = await Promise.all([
        airDCTuyaDeviceListGraphQL(userProfile?.apollo_client, selectedDCID),
        airDeviceSearch(
          userProfile?.apollo_client,
          selectedProjectID,
          selectedDCID,
          [],
          '',
          300,
          0,
        ),
      ]);

      setAirLiveResultCount(
        allAirDevices.airDCTuyaDeviceList.items
          ? allAirDevices.airDCTuyaDeviceList.items.length
          : 0,
      );
      airLiveListList(
        allAirDevices.airDCTuyaDeviceList.items ? allAirDevices.airDCTuyaDeviceList.items : [],
      );

      setRegisteredDevices(registeredAirDevices_.result);
      console.log('registeredAirDevices', registeredAirDevices_);
    } catch (err) {
      appConsoleError(err);
    } finally {
      setInit(true);
    }
  }, []);

  const initAllAirDevices = useCallback(async () => {
    airDCGetGraphQL(userProfile?.apollo_client, selectedDCID)
      .then((resp) => {
        const res = resp.airDCGet.airDeviceCollection;
        // console.log("XX", res);
        if (res && res.adc_type && res.adc_type === 8) setIsDemo(true);
        setDCDetails({
          location_name: res.name,
          email: res.email,
        });
      })
      .catch((err) => {
        appConsoleLog(err);
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedGetSelectorData = useCallback(
    debounce((keyword: string) => setSearchQuery(keyword), 100),
    [],
  );
  const onDeviceSearch = (val = '') => {
    debouncedGetSelectorData(val);
  };

  const catList = ITEM_DEFAULTS.ITEM_CREATOR.AIR_DEVICES.enabled_tuya_category;

  type IContainerDetails = {
    location_name: string;
    email: string;
  };
  const formValues: IContainerDetails = {
    location_name: dcDetails?.location_name ?? '',
    email: dcDetails?.email ?? '',
  };

  const onSubmitForm = async (values: IContainerDetails) => {
    airDCUpdateGraphQL(userProfile?.apollo_client, selectedDCID, values.location_name.trim())
      .then(async (res) => {
        snackbar.enqueueSnackbar(APP_TEXT.device_container.UPDATE_SUCCESS, {
          key: 'key1',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        });

        airDCGetGraphQL(userProfile?.apollo_client, selectedDCID)
          .then((resp) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const res = resp.airDCGet.airDeviceCollection;
            setDCDetails({
              location_name: res.name,
              email: res.email,
            });
            setProjectName(res.name);
          })
          .catch((err) => {
            appConsoleLog(err);
          });
      })
      .catch((err) => {
        appConsoleLog(err);
        snackbar.enqueueSnackbar(APP_TEXT.device_container.UPDATE_FAILED, {
          key: 'login-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      });
  };

  const handleDelete = async () => {
    airDCDeleteGraphQL(userProfile?.apollo_client, selectedDCID)
      .then(async (res) => {
        snackbar.enqueueSnackbar(APP_TEXT.device_container.DELETE_SUCCESS, {
          key: 'key1',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        });
        navgiate(`/app/device-containers?projectId=${selectedProjectID}`);
      })
      .catch((err) => {
        snackbar.enqueueSnackbar(APP_TEXT.device_container.DELETE_FAILED, {
          key: 'login-error',
          ...ErrorSnackbarOptions,
          action: ErrorSnackbarActions(snackbar.closeSnackbar),
        });
      });
  };

  const liveDeviceFiltered = airLiveList.filter((airData: any) => {
    const b = true;
    // if (catList.length > 0) {
    //   b = catList.includes("" + airData.category);
    // }
    return b;
  });

  /**
   * Account credential functions
   */
  const [isShowCredentialInfo, ShowCredentialInfo] = useState(false);
  const [credentials, setCredentials] = useState<IAirContainerCredentials>(null);
  const handleShowCredentials = async () => {
    try {
      if (!isShowCredentialInfo) {
        const res = await airDCPasswordGet(userProfile?.apollo_client, selectedDCID);
        setCredentials(res);
      }

      ShowCredentialInfo(!isShowCredentialInfo);
    } catch (err) {
      console.error(err);
    }
  };
  const handleResetPassword = async () => {
    // ShowCredentialInfo(false);
    try {
      await airDCPasswordReset(userProfile?.apollo_client, selectedDCID);
      const res = await airDCPasswordGet(userProfile?.apollo_client, selectedDCID);
      setCredentials(res);
      snackbar.enqueueSnackbar('Account password successfully reset', {
        key: 'air-device-pw-reset-s',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
      // ShowCredentialInfo(true);
    } catch (err) {
      console.error(err);
    }
  };

  const syncDevices = async () => {
    try {
      setLoading(true);
      snackbar.enqueueSnackbar('Syncing devices...', { variant: 'info' });
      const client = ApolloFunctions.createAuthApolloClient(
        user.access_token.token,
        `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/graphqlv2`,
      );
      await airDCSync(client, selectedDCID);

      await init();
      await initAllAirDevices();

      snackbar.enqueueSnackbar('Succefully synced!', {
        key: 'air-device-sync-s',
        variant: 'success',
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to sync', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>{projectName}</Typography>
          <Box>
            <DefaultButton disabled={loading} onClick={() => setValue(1)}>
              <AddIcon />
              {APP_TEXT.device_container.ADD_DEVICE}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className={`${classes.root} ml-1`}>
          <div className={`${classes.demo1} width-full-fixed`}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
              className={styles.tabsTop}
              variant="scrollable"
            >
              <AntTab
                label={`${APP_TEXT.device_container.SINGLE_AIR_TAB1} (${registerdDevices.length})`}
              />
              <AntTab
                label={`${APP_TEXT.device_container.SINGLE_AIR_TAB2} (${liveDeviceFiltered.length})`}
              />
              <AntTab label={APP_TEXT.device_container.SINGLE_AIR_TAB3} />
            </AntTabs>
            <TabPanel value={value} index={0}>
              {isInit ? (
                <>
                  <div>
                    <SearchBox minimal>
                      <TextField
                        id="input-with-icon-textfield"
                        placeholder="Search registered devices"
                        onKeyUp={(event: any) => {
                          onDeviceSearch(event.target.value);
                        }}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SearchBox>
                  </div>
                  <div className="row">
                    {registerdDevices.map((airData, index: number) => {
                      const profileBgStyle: React.CSSProperties = {
                        backgroundImage:
                          "url('https://img.icons8.com/carbon-copy/50/000000/bullet-camera.png')",
                        width: '50px',
                      };
                      return (
                        <>
                          {airData.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 && (
                            <div className="col-md-3 mt-3" key={airData.id}>
                              <DeviceCardAir
                                device={airData}
                                refreshList={() => {
                                  RefreshDeviceListNow(Date.now());
                                }}
                                onClick={() => {
                                  // TODO: "go to device home page and linked items eidt page";
                                  // appConsoleLog("");
                                  // navigateRoute(
                                  // eslint-disable-next-line max-len
                                  //   `../device-container/device?projectId=${selectedProjectID}&dcType=air&deviceId=${airData.id}`
                                  // );
                                }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-md-4">
                    <Skeleton variant="rectangular" height={130} />
                    <Skeleton />
                    <Skeleton width="60%" />
                  </div>
                  <div className="col-md-4">
                    <Skeleton variant="rectangular" height={130} />
                    <Skeleton />
                    <Skeleton width="60%" />
                  </div>
                  <div className="col-md-4">
                    <Skeleton variant="rectangular" height={130} />
                    <Skeleton />
                    <Skeleton width="60%" />
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <SearchBox minimal>
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder="Search available devices"
                    onKeyUp={(event: any) => {
                      onDeviceSearch(event.target.value);
                    }}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </SearchBox>
              </div>
              <div className="row">
                {liveDeviceFiltered.map((airDev: any) => {
                  const found_on_registered = registerdDevices.filter(
                    (item) => item.tuya_device_id === airDev.id,
                  );
                  return (
                    airDev.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 && (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <div
                        className="col-md-4 mt-3"
                        key={airDev.id}
                        onClick={() => {
                          if (!isdemo && found_on_registered.length > 0) return;
                          navigateRoute(
                            '/app/device-container/air/update/device'
                              + `?projectId=${selectedProjectID}&dcId=${selectedDCID}&airDeviceExternalId=${airDev.id}&dcName=${projectName}`,
                          );
                        }}
                      >
                        <AirLiveCard
                          productName={airDev.product_name}
                          name={airDev.name}
                          id={airDev.id}
                          category={airDev.category}
                          bgColor={found_on_registered.length > 0 ? 'var(--green-30)' : 'white'}
                          cursor={
                            (!isdemo && found_on_registered.length) > 0 ? 'not-allowed' : 'pointer'
                          }
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Formik
                onSubmit={onSubmitForm}
                initialValues={formValues}
                validationSchema={Yup.object().shape({
                  location_name: Yup.string().required('Required'),
                })}
                // onSubmit={onSubmitForm}
              >
                {({
                  errors,
                  handleBlur,
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  handleChange,
                  touched,
                  values,
                  submitForm,
                }) => (
                  <div className="row">
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          className={styles.fieldSpace}
                          name="email"
                          label={APP_TEXT.auth.EMAIL}
                          size="medium"
                          variant="outlined"
                          disabled
                        />
                        <TextField
                          error={Boolean(touched.location_name && errors.location_name)}
                          helperText={touched.location_name && errors.location_name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.location_name}
                          className={styles.fieldSpace}
                          name="location_name"
                          label={APP_TEXT.auth.NAME}
                          size="medium"
                          variant="outlined"
                        />
                        <div style={{ display: 'flex' }}>
                          <Button
                            color="primary"
                            onClick={submitForm}
                            disabled={loading}
                            variant="contained"
                          >
                            {APP_TEXT.device_container.SAVE_BUTTON}
                          </Button>
                          <Box ml={1} />
                          <Button
                            color="info"
                            onClick={syncDevices}
                            disabled={loading}
                            variant="contained"
                          >
                            {APP_TEXT.device_container.SYNC_BUTTON}
                          </Button>
                          <Box ml={1} />
                          <Button
                            variant="outlined"
                            color="error"
                            disabled={loading}
                            onClick={() => setDeleteState(true)}
                          >
                            {APP_TEXT.device_container.DELETE_ACCOUNT}
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-12">
                            <Button
                              color="secondary"
                              onClick={handleShowCredentials}
                              startIcon={
                                isShowCredentialInfo ? (
                                  <FiEyeOff color="secondary" />
                                ) : (
                                  <FiEye color="secondary" />
                                )
                              }
                            >
                              {isShowCredentialInfo ? 'Hide' : 'Show Account credentials'}
                            </Button>
                          </div>
                          {isShowCredentialInfo && credentials != null ? (
                            <div className="col-sm-12">
                              <div className={classes.info}>
                                <div>
                                  Air User Id:
                                  {' '}
                                  <span style={{ fontWeight: 700 }}>{credentials.tuya_userid}</span>
                                </div>
                              </div>
                              <div className={classes.info}>
                                <div>
                                  Email:
                                  {' '}
                                  <span style={{ fontWeight: 700 }}>{credentials.email}</span>
                                </div>
                                <div>
                                  <Tooltip title="Copy the Email">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(credentials.email);
                                        snackbar.enqueueSnackbar('Email Coppied to Clipboard', {
                                          variant: 'success',
                                        });
                                      }}
                                    >
                                      <FileCopyIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className={classes.info}>
                                <div>
                                  Password:
                                  {' '}
                                  <span style={{ fontWeight: 700 }}>{credentials.password}</span>
                                </div>
                                <div>
                                  <Tooltip title="Copy the password">
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(credentials.password);
                                        snackbar.enqueueSnackbar('Password Coppied to Clipboard', {
                                          variant: 'success',
                                        });
                                      }}
                                    >
                                      <FileCopyIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                              <div>
                                <Button
                                  variant="outlined"
                                  onClick={handleResetPassword}
                                  startIcon={<VpnKey color="secondary" />}
                                >
                                  Reset password
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </TabPanel>
            <Typography className={classes.padding} />
          </div>
        </div>
        <ConfirmDelete
          itemId={selectedDCID}
          open={deleteState}
          title="Confirm to delete?"
          item="device collection"
          itemTextBoxLabel="Device Collection Name"
          name={projectName}
          setOpen={setDeleteState}
          delete={handleDelete}
        />
      </Box>
    </Layout>
  );
};

export default SingleContainerAirComponent;
