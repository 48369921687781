/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { searchLogicList } from './async-thunks';
import { LogicListState } from './types';

// eslint-disable-next-line camelcase
const initial_state: LogicListState = {
  status: null,
  result: {
    limit: 100,
    skip: 0,
    resultCount: null,
    currentItems: null,
    result: [],
  },
  meta: {
    error: null,
    loading: false,
  },
};

const logicSlice = createSlice({
  name: 'logics',
  initialState: initial_state,
  reducers: {
    set_logics: (state, action: {payload: any[]}) => {
      state.result.currentItems = action.payload.length;
      state.result.result = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchLogicList.fulfilled, (state, action) => {
      console.log('LIST FULLFIELD', action);
      const data = action.payload;
      state.result = data.result;
      state.status = data.status;
      state.meta.loading = false;
    });
    builder.addCase(searchLogicList.rejected, (state, action) => {
      const data = action.payload;
      state.meta = {
        error: data,
        loading: false,
      };
    });
    builder.addCase(searchLogicList.pending, (state, action) => {
      state.meta.loading = true;
    });
  },
});

export const { reducer } = logicSlice;
// eslint-disable-next-line camelcase
export const { set_logics } = logicSlice.actions;
