import { useMemo } from 'react';
import { EnergyMonitoringConfig } from '../../shared/react-components/atoms/DeviceEnergyMonitoringConfig';
import { ITuyaDeviceStatus } from 'src/containers/app/AirDeviceCreateEdit/types';
import { isArray } from 'lodash';

export type DeviceEnergyMonitoringConfigProps = {
  functions: ITuyaDeviceStatus[];
  hdc: any;
  onSaveHdc: (hdcs: any) => void;
};

export const DeviceEnergyMonitoringConfig: React.FC<DeviceEnergyMonitoringConfigProps> = ({
  functions,
  hdc,
  onSaveHdc,
}) => {

  const channels = useMemo(() => {
    if (isArray(functions)) {
      const channels = {};
      functions.map((fn) => {
        channels[fn.code] = {
          k: fn.code,
          t: null,
          channel_type: 'elec_total',
        };
      });

      return channels;
    }
    return {};
  }, [functions]);

  return <EnergyMonitoringConfig channels={channels} hdc={hdc} onSave={onSaveHdc} />;
};
