/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Slicer for the Project state. Also exported reducers, action creators of the project state.
 *
 * @author Maduka Dilshan
 */

import { createSlice } from '@reduxjs/toolkit';
import { getAvaibleProjects, getSelectedProject } from './async-thunks';
import { ProjectState } from './types';

const initial_state: ProjectState = {
  projects: null,
  project_ids: [],
  project_user_views_ids: [],
  project_device_containers_ids: [],
  selected_project: {
    permission: null,
    project: null,
    meta: {
      initialized: false,
      error: null,
    },
  },
  meta: {
    initialized: false,
    error: null,
  },
};

// Project State Slice
export const projectsSlice = createSlice({
  name: 'projects',
  initialState: initial_state,
  reducers: {
    init: (state, action) => {
      // TODO: init projects list
      state.meta.initialized = true;
    },
    setSelected: (state, action) => {
      // TODO: set selected project
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvaibleProjects.fulfilled, (state, action) => {
      if (Array.isArray(action.payload)) {
        const ids: string[] = [];
        const result = action.payload.reduce((map: any, obj) => {
          map[obj.id] = obj;
          ids.push(obj.id);
          return map;
        }, {});
        state.projects = result;
        state.project_ids = ids;
        state.meta.initialized = true;
        state.meta.error = null;
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    builder.addCase(getAvaibleProjects.rejected, (state, action) => {});
    builder.addCase(getSelectedProject.fulfilled, (state, action) => {
      // TODO: update state
    });
    builder.addCase(getSelectedProject.rejected, (state, action) => {
      // TODO: update state
    });
  },
});

// Action creators and reducer exports
export const { init } = projectsSlice.actions;
export const { reducer } = projectsSlice;
