import { gql } from '@apollo/client';

export const DCSEARCH = gql`
  query DcSearch(
    $project_id: String
    $dc_type: Int
    $dc_status: Int
    $keyword: String
    $limit: Int
    $skip: Int
  ) {
    dcSearch(
      project_id: $project_id
      dc_type: $dc_type
      dc_status: $dc_status
      keyword: $keyword
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        dc_cat
        created_by
        project_id
        name
        description
        image
        unique_key
        dc_type
        dc_status
        created_at
        updated_at
      }
    }
  }
`;

export const GETDEVICELINKEDITEMS = gql`
  query DeviceGetLinkedItems($device_id: String, $collection_type: String) {
    deviceGetLinkedItems(
      device_id: $device_id
      collection_type: $collection_type
    ) {
      items {
        id
        created_by
        project_id
        userview_id
        parent_item_id
        display_path_ref
        path_ref
        pro_device_id
        air_device_id
        item_cat
        item_type
        dc_id
        item_status
        item_visibility
        item_notification_type
        display_name
        name_id
        rule_name
        macro_name
        icon_name
        item_color
        settings
        config
        created_at
        updated_at
      }
    }
  }
`;

export const PRO_DC_TUNNELS = gql`
  query ProDcTunnels($prodc_id: String) {
    proDcTunnels(prodc_id: $prodc_id) {
      host_list {
        host
        remark
        id
        u
        p
        target
      }
      tunnels {
        host
        port
        type
        remark
        id
        u
        p
        target
      }
    }
  }
`;

export const PRO_DC_TUNNEL_CREATE = gql`
  mutation proDcTunnelCreate(
    $prodc_id: String
    $remark: String
    $type: String
    $ip: String
    $port: String
  ) {
    proDcTunnelCreate(
      prodc_id: $prodc_id
      remark: $remark
      type: $type
      ip: $ip
      port: $port
    ) {
      status
    }
  }
`;

export const PRO_DC_TUNNEL_DELETE = gql`
  mutation ProDcTunnelDelete($prodc_id: String, $type: String, $id: String) {
    proDcTunnelDelete(prodc_id: $prodc_id, type: $type, id: $id) {
      status
    }
  }
`;

export const AIR_DC_PASSWORD_GET = gql`
  query airDCPasswordGet($airdc_id: String) {
    airDCPasswordGet(airdc_id: $airdc_id) {
      dc_id
      tuya_userid
      email
      password
    }
  }
`;

export const AIR_DC_PASSWORD_RESET = gql`
  mutation airDCAccountResetPassword($airdc_id: String) {
    airDCAccountResetPassword(airdc_id: $airdc_id) {
      id
    }
  }
`;

export const AIR_DC_CREATE_NEW_ACCOUNT = gql`
  mutation airDCAccountCreate($input: AirDCCreateIn) {
    airDCAccountCreate(input: $input) {
      id
      created_by
      creator_email
      project_id
      country_code
      tuya_userid
      name
      description
      email
      image
      adc_type
      adc_status
      created_at
      updated_at
    }
  }
`;

export const AIR_DC_SYNC_NOW_V2 = gql`
  mutation SyncAirDevices($syncType: String!, $airDcId: String!) {
    SyncAirDevices(syncType: $syncType, air_dc_id: $airDcId) {
      status
      ref_id
    }
  }
`;
