import DCFormLayout from 'src/plugins/shared/react-components/DeviceContainerSettingLayout';
import {
  Alert, Box, Button, FormControl, Grid, TextField, TextareaAutosize,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { iEnodeIntegration } from '../enode.service';
import { VendorListConfiguration } from './VendorConfig';

const useStyles = makeStyles({
  root: {
    paddingTop: 30,
  },
  formControl: {
    width: '100%',
  },
  textArea: {
    backgroundColor: '#F9F9F9',
    border: '1px solid #D9DFE0',
    borderRadius: 5,
    paddingLeft: 29,
    paddingRight: 29,
    paddingTop: 13,
    paddingBottom: 13,
  },
  btnContainer: {
    display: 'flex',
    paddingTop: 10,
  },
});

export const ContainerSettings: React.FC<{
  dcName: string;
  dcId: string;
  projectId: string;
  integration: iEnodeIntegration;
}> = ({
  dcName, dcId, projectId, integration,
}) => {
  const styles = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const sendShellyUrlRequest = async (dcId: string) => {
      try {
        // TODO: open enode url
        return;
        const res = await integration.getEnodeUrl(dcId, '', '');
      } catch (err) {
        enqueueSnackbar('Failed to get enode url', { variant: 'error' });
      }
    };
    sendShellyUrlRequest(dcId);
  }, []);

  return (
    <DCFormLayout
      integration={integration}
      dcId={dcId}
      projectId={projectId}
      title={`Settings: ${dcName}`}
      titleSize="sm"
    >
      {(displayName) => (
        <VendorListConfiguration
          dcId={dcId}
          displayName={displayName}
          integration={integration}
          setSuccess={() => {}}
        />
      )}
    </DCFormLayout>
  );
};
