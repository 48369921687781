import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import uvService from 'src/components/UserViewComponents/service/uv.service';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { DeviceCollectionType } from 'src/components/UserViewComponents/UIItems/UVDeviceFinder/types';
import { useSnackbar } from 'notistack';
import CloudIntegration, { CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import { Component, Tag } from 'src/plugins/shared/uvitem.config';
import ReactJson from 'react-json-view';

const ExtraField: React.FC<{
  tagData: Tag;
  label: string;
  devices: (CiDevice & {label: string; groupLabel: string})[];
  defaultDeviceId?: string;
  defaultChannel?: string;
  onDeviceSelect: (device: CiDevice & {label: string; groupLabel: string}) => void;
  onChannelFKeySelect: (fKey: string, channelKey: string) => void;
}> = ({
  tagData, label, devices, defaultChannel, defaultDeviceId, onDeviceSelect, onChannelFKeySelect,
}) => {
  const [channels, setChannels] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const extractChannels = (selectedDevice: CiDevice & {label: string; groupLabel: string}) => {
    if (!selectedDevice || !selectedDevice.settings) return;

    let { settings } = selectedDevice;
    if (typeof settings === 'string') {
      try {
        settings = JSON.parse(selectedDevice.settings);
      } catch (err) {
        enqueueSnackbar('Failed to parse device setting', { variant: 'error' });
      }
    }

    if (settings && settings.sf) {
      setChannels(settings.sf);
    }
  };

  const defaultDevice = useMemo(() => {
    if (!defaultDeviceId) return;

    if (!devices) return;

    const device = devices.find((device) => device.id === defaultDeviceId);
    return device;
  }, [defaultDeviceId, devices]);

  const defaultChannelFkey = useMemo(() => Object.keys(channels).find((fkey) => channels[fkey].k === defaultChannel), [channels, defaultChannel]);

  useEffect(() => {
    if (defaultDevice) {
      extractChannels(defaultDevice);
    }
  }, [defaultDevice]);

  return (
    <Box style={{backgroundColor: '#EBF1F2', padding: 10, paddingTop: 20, marginBottom: 10, borderRadius: 5,}}>
      <Typography style={{ color: '#2AA198' }}>{label}</Typography>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <FormControl size="small" style={{ flex: 0.47 }}>
          <Autocomplete
            key={defaultDevice?.id}
            defaultValue={defaultDevice}
            onChange={(_, val: any) => {
              onDeviceSelect(val);
              extractChannels(val);
            }}
            size="small"
            options={devices}
            groupBy={(opt) => opt.groupLabel}
            renderInput={(params) => <TextField {...params} label="Device" />}
            disableClearable
          />
        </FormControl>
        <FormControl size="small" style={{ flex: 0.5 }}>
          <InputLabel>Channel</InputLabel>
          <Select
            key={defaultChannelFkey}
            defaultValue={defaultChannelFkey}
            label="Channel"
            onChange={(e) => {
              onChannelFKeySelect(e.target.value as string, channels[e.target.value as string]?.k);
            }}
          >
            {channels
              && Object.keys(channels).map((cKey) => (
                <MenuItem key={cKey} value={cKey}>
                  {channels[cKey].k}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box height={10} />
          <ReactJson
            name="Field Configurations"
            collapsed
            enableClipboard={false}
            displayObjectSize={false}
            displayDataTypes={false}
            src={{
              display_suffix: tagData.display_suffix ?? '',
              ...tagData.config,
            }}
          />
    </Box>
  );
};

export const ExtraFieldMap: React.FC<{
  filledExtraTagMap: Record<
    string,
    {
      deviceId: string;
      channelKey: string;
    }
  >;
  selectedMap: Component;
  integration: CloudIntegration;
  setFilledExtraTagMap: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          deviceId: string;
          channelKey: string;
        }
      >
    >
  >;
}> = ({
  selectedMap, filledExtraTagMap, integration, setFilledExtraTagMap,
}) => {
  const extraUiTags = selectedMap && selectedMap.extra_ui_tags;
  const auth = useContext(ApolloAuthContext);
  const projectId = useQueryStringFirst('projectId');

  const [devices, setDevices] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!extraUiTags || Object.keys(extraUiTags).length == 0) {
      return;
    }

    async function getDevices() {
      try {
        setLoading(true);

        const res = await uvService.searchProjectCloudDevice(
          auth.apollo_client,
          projectId,
          [],
          [],
          '',
          [],
          200,
          0,
        );
        const itms: any[] = [];

        res.cint_devices.forEach((element: any) => {
          element.dc_type = DeviceCollectionType.cintDevice;
          itms.push({
            ...element,
            label: element.name,
            groupLabel:
              element.cint_type == '3'
                ? 'Enode Devices'
                : element.cint_type == '4'
                  ? 'Shelly Devices'
                  : 'Unknown Cloud Devices',
          });
        });
        await setDevices(itms);
      } catch (err) {
        enqueueSnackbar('Cannot get device list', {
          key: 'uv-item-create-error',
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    getDevices();
  }, [extraUiTags]);

  if (!extraUiTags || Object.keys(extraUiTags).length == 0) {
    return null;
  }

  return (
    <Box style={{ border: '1px solid #EBF1F2', backgroundColor: 'white', padding: 10 }}>
      <Typography style={{ marginBottom: 5, fontWeight: 500 }}>Extra Channel Mapping</Typography>
      {Object.keys(extraUiTags).map((extraTagKey, index) => (
        <ExtraField
          tagData={extraUiTags[extraTagKey]}
          key={extraTagKey}
          label={`${index + 1}.${extraUiTags[extraTagKey]?.description}`}
          devices={devices}
          defaultDeviceId={filledExtraTagMap[extraTagKey]?.deviceId}
          defaultChannel={filledExtraTagMap[extraTagKey]?.channelKey}
          onChannelFKeySelect={(_, channelKey) => setFilledExtraTagMap((current) => ({
            ...current,
            [extraTagKey]: {
              ...current[extraTagKey],
              channelKey,
            },
          }))}
          onDeviceSelect={(device) => setFilledExtraTagMap((current) => ({
            ...current,
            [extraTagKey]: {
              ...current[extraTagKey],
              deviceId: device.id,
            },
          }))}
        />
      ))}
    </Box>
  );
};
