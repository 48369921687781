/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { BackupRestoreInfoRes } from './types';

export const getInfo = createAsyncThunk<
  {data: BackupRestoreInfoRes; type: string},
  {backup_serial: string; response_type: string},
  {
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/get-info-map', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/get-info-map',
    filters,
  );
  return { data: res.data as BackupRestoreInfoRes, type: filters.response_type };
});

export const initInfo = createAsyncThunk<
  {data: BackupRestoreInfoRes; type: string},
  {backup_serial: string; response_type: string},
  {
    rejectValue: Object;
    extra: {
      axiosClient: AxiosInstance;
    };
  }
>('backup-location-config/init-info-map', async (filters, thunkAPI) => {
  const res = await thunkAPI.extra.axiosClient.post(
    'rest/backups/restore/v1/project-backup/get-info-map',
    filters,
  );
  return { data: res.data as BackupRestoreInfoRes, type: filters.response_type };
});
