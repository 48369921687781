import { gql } from '@apollo/client';

export const PROJECT_SEARCH_QUERY = gql`
  query ProjectSearch(
    $project_status: Int
    $project_type: Int
    $keyword: String
    $limit: Int
    $skip: Int
  ) {
    projectSearch(
      project_status: $project_status
      project_type: $project_type
      keyword: $keyword
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        created_by
        name
        description
        image
        project_status
        project_type
        last_updated
        access_level
        created_at
        updated_at
        restore_bsid
        demo_mode
      }
    }
  }
`;

export const PROJECT_GET_QUERY = gql`
  query ProjectGet($project_id: String) {
    projectGet(project_id: $project_id) {
      project {
        id
        created_by
        name
        description
        image
        project_status
        project_type
        demo_mode
        restore_bsid
        created_at
        updated_at
        demo_mode
        project_contact_no1
        project_timezone
        project_tz_info
        project_address
        project_country
        project_country_code
        project_city
      }
      permission {
        id
        created_by
        project_id
        user_id
        user_email
        user_title
        access_level
        permission_status
        invite_to_userviews
        last_updated
        created_at
        updated_at
      }
    }
  }
`;

export const PROJECT_CREATE_MUTATION = gql`
  mutation ProjectCreate($input: ProjectIn) {
    projectCreate(input: $input) {
      id
      created_by
      name
      description
      image
      project_status
      project_type
      created_at
      updated_at
      demo_mode
    }
  }
`;

export const PROJECT_UPDATE_MUTATION = gql`
  mutation ProjectUpdate($input: ProjectUpdateIn) {
    projectUpdate(input: $input) {
      id
      update_status
      updated_at
    }
  }
`;

export const DELETE_PROJECT_MUTATION = gql`
  mutation ProjectDelete($item_id: String) {
    projectDelete(item_id: $item_id) {
      id
      delete_status
    }
  }
`;

export const PROJECT_DEVICE_UV_MAP = gql`
  query GetDeviceUvMap($project_id: String) {
    ProjectDeviceUvMap(project_id: $project_id) {
      map_json
    }
  }
`;

export const PROJECT_DIAGRAMS = gql`
  query ProjectDiagrams($project_id: String) {
    projectDiagrams(project_id: $project_id) {
      diagrams {
        id
        name
        description
        diagram_uid
        created_at
        updated_at
        url
      }
    }
  }
`;

export const PROJECT_DIAGRAM_CREATE_EDIT_URL = gql`
  query ProjectDiagramCreateEditUrl($diagram_id: String) {
    projectDiagramCreateEditUrl(diagram_id: $diagram_id) {
      url
    }
  }
`;

export const PROJECT_DIAGRAM_CREATE = gql`
  mutation ProjectDiagramCreate($input: ProjectDiagramIn) {
    projectDiagramCreate(input: $input) {
      id
      name
      description
      diagram_uid
      created_at
      updated_at
      url
    }
  }
`;

export const PROJECT_DIAGRAM_DELETE = gql`
  mutation ProjectDiagramDelete($diagram_id: String) {
    projectDiagramDelete(diagram_id: $diagram_id) {
      id
      delete_status
    }
  }
`;

export const PROJECT_DIAGRAM_UPDATE = gql`
  mutation ProjectDiagramUpdate($diagram_id: String, $item: ProjectDiagramIn) {
    projectDiagramUpdate(diagram_id: $diagram_id, item: $item) {
      id
      update_status
      updated_at
    }
  }
`;
