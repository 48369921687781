/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 * @author Maduka Dilshan
 */
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  TextField,
  Tooltip,
  Chip,
  Paper,
  InputAdornment,
  Skeleton,
  MenuItem,
  Select,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import LinkIcon from '@mui/icons-material/Link';
import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import axios from 'axios';
import React, {useEffect, useState, useCallback, useContext, useRef} from 'react';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import IconButton from '@mui/material/IconButton';

import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {cloneDeep, debounce, isArray} from 'lodash';
import TimerIcon from '@mui/icons-material/Timer';
import {
  buildActionPayload,
  buildLogicPayload,
  buildWhenPayload,
  NormalizedState,
  RuleEngineState,
  toDenormalize,
  toNormalize,
} from '@smartlife-redux-state/common';
import PauseIcon from '@mui/icons-material/Pause';
import LinearProgress from '@mui/material/LinearProgress';
import {insertInOrder} from '@smartlife-redux-state/orm';
import {RootState, useSelector} from '../../../../store/redux/store';
import {searchLogicList} from '../../../../store/redux/features/logics';
import {ProjectContext} from '../../../../store/Project/ProjectContext';
import {AuthContext} from '../../../../store/Auth/AuthContext';
import {CofirmDialog} from './ui/Confirm';
import {SearchBox} from '../../../../components/shared/SearchBox/SearchBox';
import {
  hydrate_logic_creation,
  reset_logic_creation,
  validate_main_action,
} from '../../../../store/redux/features/logic-engine';
import {pluginHook} from 'src/store/Plugins/PluginProvider';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '2%',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  searchBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%',
  },
  bradiusLeft: {
    borderTopLeftRadius: '5px',
    // borderBottomLeftRadius: "5px",
  },
  bradiusRight: {
    borderTopRightRadius: '5px',
    // borderBottomRightRadius: "5px",
  },
  head: {
    backgroundColor: '#7F8FA4',
    color: 'white',
  },
  body: {},
  srow: {
    fontFamily: 'monospace',
    '&:nth-of-type(even)': {
      backgroundColor: '#cdd5d6', // "#F9F9F9",
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
  },
  collapse_inner: {
    width: '100%',
  },
});

const Row: React.FC<any> = props => {
  const {row} = props;
  const style = useStyles();
  const navgiate = useNavigate();
  const dispatch = useDispatch();
  const authUser: any = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const {enqueueSnackbar} = useSnackbar();
  const ormRef = useRef<NormalizedState>();
  const orm = useSelector((reduxState: RootState) => reduxState.orm);
  ormRef.current = orm;
  const [delete_logic_id, setDeleteLogicId] = useState(null);
  const [confirmDuplication, setConfirmDuplication] = useState(null);
  const [duplcationProgress, setDuplicationProgress] = useState(false);

  const [logicState, setLogicState] = useState<string>(row.status);

  function onDeleteLogic(id: string) {
    setDeleteLogicId(id);
  }

  async function testLogic(id: string) {
    try {
      enqueueSnackbar('Sending command to run the logic...', {
        variant: 'info',
      });
      const {token} = authUser.access_token;
      const res = await axios.post(
        process.env.REACT_APP_LOGIC_TEST_URL,
        {
          logic_id: id,
          project_id: selectedProject.selected_project.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      enqueueSnackbar('Command sent!', {variant: 'success'});
    } catch (err: any) {
      enqueueSnackbar(`Command failed! - ${err.message}`, {variant: 'error'});
    }
  }

  function duplicateLogic(logic_id: string) {
    setConfirmDuplication(logic_id);
  }

  async function makeDuplication(logic_id: string) {
    try {
      await hydrateLogicState(logic_id);
    } catch (err: any) {
      enqueueSnackbar(`Failed to duplicate logic - ${err.message}`, {
        variant: 'error',
      });
    }
  }

  async function deleteLogic(id: string) {
    try {
      enqueueSnackbar('Deleting logic...', {
        variant: 'info',
      });
      const {token} = authUser.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/delete`,
        {
          logic_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      props.refetch();
      enqueueSnackbar('Logic deleted!', {variant: 'success'});
    } catch (err: any) {
      enqueueSnackbar(`Delete failed! - ${err.message}`, {variant: 'error'});
    }
  }

  async function hydrateLogicState(logic_id: string) {
    setDuplicationProgress(true);
    const {token} = authUser.access_token;
    const res = await axios.post(
      `${
        process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
      }/rest/logic-engine/v1/logic-item/get`,
      {
        logic_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data && res.data.result) {
      const state = JSON.parse(res.data.result.input_json) as RuleEngineState | NormalizedState;
      let parsed: any = null;
      if (res.data.result.input_json.indexOf('@@_______REDUX_ORM_STATE_FLAG') > -1) {
        const deNorm = toDenormalize(state as NormalizedState);
        parsed = deNorm;
      } else {
        parsed = state;
      }
      if (!parsed.meta) {
        parsed.meta = {
          loadin_device: false,
          is_editing: true,
        };
      }
      parsed.meta.is_editing = true;

      const {actions} = parsed;
      const {checks} = parsed;
      const {triggers} = parsed;

      const parsedTriggers = buildWhenPayload(triggers);
      const parsedChecks = buildLogicPayload(checks.list, checks.content, checks.expression);
      const parsedActions = buildActionPayload(actions);

      const state_cpy = cloneDeep(parsed);
      state_cpy.devices = {result: []}; // .result = [];
      // state_cpy.device_containers = { result: [] }; // .result = [];
      state_cpy.device_form_resovler = {};

      const norm = toNormalize(state_cpy);
      insertInOrder(norm, dispatch);

      const payload = {
        project_id: selectedProject.selected_project.id,
        status: 'stop',
        name: `${row.name} [DUPLICATED - ${new Date().toUTCString()}]`,
        // alexa_friendly_name: alexaName,
        input_json: JSON.stringify(state_cpy),
        triggers: parsedTriggers,
        rules: parsedChecks.rules,
        logic: parsedChecks.logic,
        actions: parsedActions,
      };

      setTimeout(async () => {
        try {
          await createLogic(payload);
          setDuplicationProgress(false);
        } catch (err: any) {
          setDuplicationProgress(false);
          throw new Error(err);
        }
      }, 3000);

      // await createLogic(payload);
    } else {
      setDuplicationProgress(false);
      throw new Error('Something went wrong');
    }
  }

  async function createLogic(payload: any) {
    try {
      const cpy = cloneDeep(payload);
      cpy.input_json = JSON.stringify(ormRef.current);
      const {token} = authUser.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/create`,
        cpy,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(reset_logic_creation());
      enqueueSnackbar('Logic duplicated', {variant: 'success'});
      enqueueSnackbar('Loading Logic List', {variant: 'info'});

      dispatch(
        searchLogicList({
          project_id: selectedProject.selected_project.id,
          limit: 10,
          skip: 0,
          keyword: '',
          logic_status: '',
          logic_version: '',
        })
      );
      setConfirmDuplication(null);
    } catch (err: any) {
      enqueueSnackbar(err.message, {variant: 'error'});
    }
  }

  async function stopLogic(logicId: string) {
    try {
      const {token} = authUser.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/disable`,
        {
          logic_id: logicId,
          reason: 'user manually stopped',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLogicState('stop');
    } catch (err: any) {
      enqueueSnackbar('Unable to stop logic', {variant: 'error'});
    }
  }

  return (
    <>
      <TableRow key={row._id} className={style.srow}>
        {/* <TableCell>{row.logic_id || " - "}</TableCell> */}
        <TableCell>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <IconButton onClick={() => props?.onGetLinkedItems(row.logic_id)}>
              <Tooltip title="Userview Item links">
                <LinkIcon />
              </Tooltip>
            </IconButton>
            {logicState === 'active' && (
              <IconButton onClick={() => stopLogic(row.logic_id)}>
                <Tooltip title="Stop Logic">
                  <PauseIcon style={{color: 'orange'}} />
                </Tooltip>
              </IconButton>
            )}
            <div style={{display: 'flex', alignItems: 'center'}}>
              {isArray(row.trigger_schedules) && row.trigger_schedules.length > 0 && (
                <TimerIcon fontSize="small" />
              )}{' '}
              {row.trigger_sunset_shedules_count > 0 && <WbSunnyIcon fontSize="small" />}{' '}
              {row.name || ' - '}
              {` (${row.logic_version})`}
            </div>
          </div>
        </TableCell>
        <TableCell>
          {row.voice_triggers && isArray(row.voice_triggers) && row.voice_triggers.length > 0 ? (
            <div>
              {row.voice_triggers.map((item, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={item.friendly_name}
                  size="small"
                  style={{margin: '2px'}}
                />
              ))}
            </div>
          ) : (
            <div> - </div>
          )}
        </TableCell>

        <TableCell>{moment.unix(row.updated_at).format(' Do MMM YYYY, h:mm a')}</TableCell>
        <TableCell>
          {logicState === 'active' ? (
            <Chip label="Active" color="primary" size="small" />
          ) : logicState === 'stop' ? (
            <Chip label="Disabled" color="error" size="small" />
          ) : (
            'Unkown'
          )}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              navgiate(`/app/logics/edit?projectId=${props.project_id}&logicId=${row.logic_id}`);
            }}
          >
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => testLogic(row.logic_id)} size="large">
            <Tooltip title="Run this logic">
              <PlayCircleFilled />
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => duplicateLogic(row.logic_id)} size="large">
            <Tooltip title="Duplicate this logic">
              <FileCopyIcon />
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setDeleteLogicId(row.logic_id)} size="large">
            <Tooltip title="Delete this logic">
              <DeleteForever />
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>
      <CofirmDialog
        dialogOpen={!!delete_logic_id}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            deleteLogic(row.logic_id);
          } else {
            setDeleteLogicId(null);
          }
        }}
        title="Confirm to delete the Logic ?"
        text={`It will delete the logic ${row.name} and you will not able to redo it!`}
      />
      <CofirmDialog
        dialogOpen={!!confirmDuplication}
        dialogCloseEvent={(e: any) => {
          if (e === true) {
            makeDuplication(row.logic_id);
          } else {
            setConfirmDuplication(null);
          }
        }}
        disableActions={duplcationProgress}
        title={
          duplcationProgress ? (
            <>
              Duplicating....
              <LinearProgress />
            </>
          ) : (
            'Confirm to duplicate the Logic ?'
          )
        }
        text={`It will duplicate the logic '${row.name}'. Notice that duplicated logics won't automatically run. You will need to manually change the logic status by updating the logic.`}
      />
    </>
  );
};

const RulesListTable: React.FC = () => {
  const style = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [t_pageSize, setTPageSize] = useState(10);
  const selectedProject = useContext(ProjectContext);
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [version, setVersion] = useState('');
  const {result, skip, resultCount, currentItems, limit} = useSelector(
    (state: RootState) => state.logics.result
  );
  const {loading} = useSelector((state: RootState) => state.logics.meta);

  const onKeywordChangeDebounced = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    debounce((keyword: string) => {
      listLogics(keyword);
    }, 300),
    []
  );

  const onKeywordChange = (value: string) => {
    setKeyword(value);
    onKeywordChangeDebounced(value);
  };

  useEffect(() => {
    if (selectedProject.selected_project && selectedProject.selected_project.id) {
      listLogics(keyword);
    }
  }, [page, t_pageSize, selectedProject, status, version]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const listLogics = (keyword: string) => {
    dispatch(
      searchLogicList({
        project_id: selectedProject.selected_project.id,
        limit: t_pageSize,
        skip: page * t_pageSize,
        keyword,
        logic_status: status,
        logic_version: version,
      })
    );
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = event => {
    setTPageSize(parseInt(event.target.value, 10));
  };

  const [linkedItems, setLinkedItems] = useState<
    {
      id: string;
      created_by: string;
      project_id: string;
      userview_id: string;
      userview_name: string;
      parent_item_id: string;
      item_cat: number;
      item_type: string;
      item_status: number;
      item_visibility: number;
      item_notification_type: number;
      display_name: string;
      name_id: string;
      rule_name: string;
      macro_name: string;
      icon_name: string;
      created_at: string;
      updated_at: string;
      mongo_ref1: string;
      parent_folder: {
        path_ref: string;
        display_name: string;
        display_path_ref: string;
        id: string;
        parent_item_id: string;
        userview_id: string;
      };
    }[]
  >([]);
  const {enqueueSnackbar} = useSnackbar();

  const getLogicLinkedUserviewItems = async (logicId: string) => {
    try {
      const axiosClient = pluginHook.commonIntegrationService.axiosClient;

      const res = await axiosClient.post('/logic-engine/v1/logic-item/linked-items', {
        logic_id: logicId,
      });
      const {data} = res;
      const result: any[] = data?.result?.uv_items || [];
      if (result.length === 0) {
        enqueueSnackbar('This logic is not linked to any userview item', {variant: 'info'});
      }
      setLinkedItems(result);
    } catch (err) {
      enqueueSnackbar('Unable to fetch Linked Userview Items', {variant: 'error'});
    }
  };

  return (
    <div className={style.root}>
      <Box mt={3}>
        <Box>
          <SearchBox>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  size="small"
                  style={{width: '100%'}}
                  id="input-with-icon-textfield"
                  placeholder="Logic Name, Tag, Device Name"
                  onKeyUp={(event: any) => {
                    onKeywordChange(event.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <FormControl style={{width: '100%'}} variant="outlined">
                  <Select
                    labelId="logic_status_label"
                    displayEmpty
                    size="small"
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="stop">Disabled</MenuItem>
                  </Select>
                  <FormHelperText>Logic Status</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl style={{width: '100%'}} variant="outlined">
                  <Select
                    displayEmpty
                    size="small"
                    value={version}
                    onChange={e => {
                      setVersion(e.target.value);
                    }}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="v1">Legacy</MenuItem>
                    <MenuItem value="v2">Current Version</MenuItem>
                  </Select>
                  <FormHelperText>Logic Version</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </SearchBox>
        </Box>
        <Box height={20} />
        <Box>
          {loading ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(style.head, style.bradiusLeft)}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={clsx(style.head, style.bradiusRight)} align="right">
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5, 6].map(item => (
                  <TableRow key={item} className={style.srow}>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="rectangular" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Paper style={{overflowX: 'auto'}}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {/* <TableCell className={clsx(style.head, style.bradiusLeft)}>
                    Logic Id
                  </TableCell> */}
                    <TableCell className={style.head}>Name</TableCell>
                    <TableCell className={style.head}>Voice Trigger Names</TableCell>
                    <TableCell className={style.head}>Last Update</TableCell>
                    <TableCell className={style.head} padding="checkbox">
                      Status
                    </TableCell>
                    <TableCell className={style.head} padding="checkbox">
                      Action
                    </TableCell>
                    <TableCell className={style.head} padding="checkbox">
                      Test
                    </TableCell>
                    <TableCell className={style.head} padding="checkbox">
                      Duplicate
                    </TableCell>
                    <TableCell className={style.head} padding="checkbox">
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.map(rules => (
                    <Row
                      onGetLinkedItems={(logicId: string) => {
                        getLogicLinkedUserviewItems(logicId);
                      }}
                      row={rules}
                      key={rules._id}
                      project_id={selectedProject.selected_project.id}
                      refetch={listLogics}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
          <Dialog maxWidth="sm" fullWidth open={linkedItems.length > 0}>
            <DialogTitle>Linked Userview Items</DialogTitle>
            <DialogContent>
              <ul>
                {linkedItems.map(item => {
                  return (
                    <li key={item.id}>
                      <Tooltip
                        title={item?.parent_folder?.display_path_ref + '/' + item.display_name}
                      >
                        <a
                          style={{color: 'blue'}}
                          target="_blank"
                          href={`/app/user-view?userViewId=${item.userview_id}&projectId=${selectedProject.selected_project.id}&userViewPath=${item.parent_folder.path_ref}&userViewPathName=${item.parent_folder.display_path_ref}&userViewName=${item.userview_name}`}
                        >
                          {item.display_name}
                        </a>
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setLinkedItems([])}>Done</Button>
            </DialogActions>
          </Dialog>
          <TablePagination
            component="div"
            count={resultCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={t_pageSize}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Box>
    </div>
  );
};

export default RulesListTable;
