import DCFormLayout from 'src/plugins/shared/react-components/DeviceContainerSettingLayout';
import {
  Button,
  FormControl,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  LinearProgress,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {parseCustomApiError} from 'src/utility/parseError';
import {iShellyIntegration} from '../shelly.service';
import IntegratorsSpace from '../external/IntegratorsSpace';

const useStyles = makeStyles({
  root: {
    paddingTop: 30,
  },
  formControl: {
    width: '100%',
  },
  textArea: {
    backgroundColor: '#F9F9F9',
    border: '1px solid #D9DFE0',
    borderRadius: 5,
    paddingLeft: 29,
    paddingRight: 29,
    paddingTop: 13,
    paddingBottom: 13,
  },
  btnContainer: {
    display: 'flex',
    paddingTop: 10,
    width: '100%',
    justifyContent: 'space-between',
  },
});
const demoTemplatesMap = {
  emeter_SHEM: {
    devices: [
      {
        type: 'emeter',
        sub_cat: 'SHEM',
        name: 'Energy meter 01',
      },
    ],
  },
  emeter_SPEM_003CEBEU: {
    devices: [
      {
        type: 'emeter',
        sub_cat: 'SPEM-003CEBEU',
        name: 'Energy meter 3 clamp',
      },
    ],
  },
  inputs_reader_SHBTN_2: {
    devices: [
      {
        type: 'inputs_reader',
        sub_cat: 'SHBTN-2',
        name: 'Input reader 01',
      },
    ],
  },
  inputs_reader_SNSN_0024X: {
    devices: [
      {
        type: 'inputs_reader',
        sub_cat: 'SNSN-0024X',
        name: 'Input reader 3 channels',
      },
    ],
  },
  light_SHDM_2: {
    devices: [
      {
        type: 'light',
        sub_cat: 'SHDM-2',
        name: 'Light 01',
      },
    ],
  },
  relay_SHSW_25: {
    devices: [
      {
        type: 'relay',
        sub_cat: 'SHSW-25',
        name: 'Relay 01',
      },
    ],
  },
  relay_SNSW_001P16EU: {
    devices: [
      {
        type: 'relay',
        sub_cat: 'SNSW-001P16EU',
        name: 'Relay 02',
      },
    ],
  },
  relay_SPSW_104PE16EU: {
    devices: [
      {
        type: 'relay',
        sub_cat: 'SPSW-104PE16EU',
        name: 'Relay 03',
      },
    ],
  },
  relay_SPSW_201PE16EU: {
    devices: [
      {
        type: 'relay',
        sub_cat: 'SPSW-201PE16EU',
        name: 'Relay 03',
      },
    ],
  },
  relay_SPSW_202PE16EU: {
    devices: [
      {
        type: 'relay',
        sub_cat: 'SPSW-202PE16EU',
        name: 'Relay 04',
      },
    ],
  },
  sensor_SHMOS_02: {
    devices: [
      {
        type: 'sensor',
        sub_cat: 'SHMOS-02',
        name: 'Sensor 01',
      },
    ],
  },
  sensor_SNSN_0013A: {
    devices: [
      {
        type: 'sensor',
        sub_cat: 'SNSN-0013A',
        name: 'Sensor 01',
      },
    ],
  },
};

export const ContainerSettings: React.FC<{
  dcName: string;
  dcId: string;
  projectId: string;
  integration: iShellyIntegration;
}> = ({dcName, dcId, projectId, integration}) => {
  const styles = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [shellyUrl, setShellyUrl] = useState('');

  // Template states
  const [loading, setLoading] = useState(false);

  const [selectedDemoTemplate, setDemoTemplate] = useState('');

  useEffect(() => {
    const sendShellyUrlRequest = async (dcId: string) => {
      try {
        const res = await integration.getShellyDeviceManagementUrl(dcId);
        setShellyUrl(res.result.url);
      } catch (err) {
        enqueueSnackbar('Failed to get shelly url', {variant: 'error'});
      }
    };
    sendShellyUrlRequest(dcId);
  }, []);

  useEffect(() => {
    async function timeoutFn() {
      try {
        const res = await integration.getShellyDeviceManagementUrl(dcId);
        setShellyUrl(res.result.url);
      } catch (err) {
        enqueueSnackbar('Failed to get shelly url', {variant: 'error'});
      }
    }
    const timeout = setTimeout(timeoutFn, 480000);
    return () => clearTimeout(timeout);
  }, [shellyUrl]);

  const syncAll = async () => {
    try {
      enqueueSnackbar('Syncing...', {variant: 'info'});
      await integration.syncShellyContainer(dcId);
      enqueueSnackbar('Container Synced Succesfully!', {variant: 'success'});
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar('Failed to sync', {variant: 'info'});
    }
  };

  const CreateDemoDevices = async () => {
    try {
      setLoading(true);
      await integration.createDemoDevices(dcId, selectedDemoTemplate);
      enqueueSnackbar('Demo devices Succesfully created!', {variant: 'success'});
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data);
      enqueueSnackbar(msg, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  };
  return (
    <DCFormLayout
      integration={integration}
      dcId={dcId}
      projectId={projectId}
      title={`Settings: ${dcName}`}
      titleSize="sm"
    >
      {(__, _, shellyEmail) =>
        shellyUrl && (
          <>
            <Typography variant="h6" mb={2}>
              1. Integrator Window
            </Typography>
            <IntegratorsSpace
              accountEmail={shellyEmail}
              integration={integration}
              src={shellyUrl}
              showUnlink
            />
            <Typography variant="h6" mt={2}>
              2. Advanced Settings
            </Typography>
            <Button variant="outlined" onClick={syncAll}>
              Sync Container
            </Button>
            <Typography variant="h6" mt={2}>
              3. Demo Devices
            </Typography>

            <Grid container spacing={1} paddingTop={2}>
              <Grid item md={3}>
                <FormControl style={{width: '100%'}} size="small">
                  <InputLabel>Template</InputLabel>
                  <Select
                    label="Template"
                    value={'' + selectedDemoTemplate}
                    onChange={e => setDemoTemplate(e.target.value)}
                  >
                    {Object.keys(demoTemplatesMap).map(templateKey => {
                      const template = demoTemplatesMap[templateKey];
                      return (
                        <MenuItem key={templateKey} value={templateKey}>
                          {templateKey.split('_').join(' ').toUpperCase()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3}>
                {!loading && (
                  <Button variant="outlined" onClick={CreateDemoDevices}>
                    Create Demo devices
                  </Button>
                )}
                {loading && (
                  <>
                    <LinearProgress />
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )
      }
    </DCFormLayout>
  );
};
