/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
  createTheme,
  Theme as AugmentedTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
  Theme,
} from '@mui/material/styles';

import React, { useEffect, useState } from 'react';
import { THEMES } from './Theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const ThemeContext = React.createContext({
  themeName: 'light',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: (themeName: string) => {},
});

type ComponentProps = {children: any};
export const ThemeContextComponent: React.FunctionComponent<ComponentProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<string>('light');

  const setCSSVariables = (theme: any) => {
    for (const value in theme) {
      document.documentElement.style.setProperty(`--${value}`, theme[value]);
    }
  };

  const toggleTheme = (newThemeName: string) => {
    setThemeName(newThemeName);
  };

  useEffect(() => {
    setCSSVariables(THEMES[themeName]);
  }, [themeName]);

  const theme = createTheme(adaptV4Theme({
    palette: {
      primary: { main: '#048696' },
    },
  }));
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ThemeContext.Provider value={{ toggleTheme, themeName }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};
