export const CRON_TYPES = [
  { type: 'type1', placeholder: 'May 13th @6pm', title: 'Specific Date' },
  { type: 'type2', placeholder: 'Every Monday Midnight', title: 'Scheduler' },
  // { type: "type3", placeholder: "Same as reoccuring but only run once", title: "Once" },
  {
    type: 'type4',
    placeholder: '5 4 * * * (“At 04:05.”)',
    title: 'Custom CRON value',
  },
  {
    type: 'type5',
    placeholder: 'Every day on sunset',
    title: 'Scheduler based on sunset/sunrise',
  },
];
export const CRON_SUB_TYPES = {
  type2: [
    {
      type: 'type1',
      placeholder: 'Every Monday 8:00 AM',
      title: 'Specific day of week with specific time,every week',
    },
    {
      type: 'type2',
      placeholder: 'Every day 8:00 AM',
      title: 'Specific time every days', // fri,sat,sun
    },
    {
      type: 'type3',
      placeholder: 'Every hour',
      title: 'Specified Every number of Hours/Minutes',
    },
    {
      type: 'type4',
      placeholder: 'Next Wed@6.30pm',
      title: 'Next day specific time',
    },
    {
      type: 'type5',
      placeholder: 'Every Monday before sunset 30m',
      title: 'Specific day of week before sunset with offset',
    },
    {
      type: 'type6',
      placeholder: 'Every Monday, 30m after sunset ',
      title: 'Specific day of week after sunset with offset',
    },
  ],
};
/*
All crons can run once or more than  with specified number of times can run
0 - mean forever run
>0 integer - mean only run specified number of times
*/

// In Node-Scheduler => day of week (0 - 7) (0 or 7 is Sun)
export const DaysOfWeek = [
  { label: 'Mon', value: '1' },
  { label: 'Tue', value: '2' },
  { label: 'Wed', value: '3' },
  { label: 'Thu', value: '4' },
  { label: 'Fri', value: '5' },
  { label: 'Sat', value: '6' },
  { label: 'Sun', value: '0' },
];

//
export const HoursInDay = new Array(12).fill(undefined).map((v, index) => `${index + 1}`);
export const MinutesInHour = new Array(60).fill(undefined).map((v, index) => `${index}`);
