/* eslint-disable no-nested-ternary */
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  IDFField,
  IZone,
  IZoneAvailable,
  zonesUsedValuesMap,
} from '../../../../../config/deviceConfig/types';

type ComponentProps = {
  fieldConfig: IDFField;
  handleBlur: any;
  handleChange: any;
  touched: any;
  errors: any;
  arrayHelpers: any;
  label: string;
  formDataZoneValues: any;
  zoneKeyList: IZoneAvailable[];
  index: number;
  zone: IZone;
  zoneModal: string;
  areas: any;
};
// eslint-disable-next-line import/prefer-default-export
export const ZoneSelectorItem: React.FunctionComponent<ComponentProps> = ({
  fieldConfig,
  formDataZoneValues,
  handleBlur,
  handleChange,
  touched,
  errors,
  arrayHelpers,
  label,
  zoneKeyList,
  index,
  zone,
  zoneModal,
  areas = [],
}) => {
  // console.log(label, areas);
  // const [display, setDisplay] = useState(true);
  // const ZList: IZoneAvailable[] = useMemo(() => {
  //   let zlist: IZoneAvailable[] = [];
  //   if (fieldConfig.extra.includes('custom-values')) {
  //     zlist = [];
  //   } else {
  //     zlist = zoneKeyList;
  //     if (formDataZoneValues && formDataZoneValues.forEach) {
  //       const zonesValeus = zonesUsedValuesMap(formDataZoneValues);
  //       zlist = zoneKeyList.map((item) => {
  //         const itemX = item;
  //         itemX.asigned_key = zonesValeus[item.value] || '';
  //         return itemX;
  //       });
  //     }
  //   }

  //   return zlist;
  // }, [zoneModal, formDataZoneValues, zoneKeyList]);

  let zoneTypes: string[] = [];

  if (
    fieldConfig.extra.split('_').length > 1
    && fieldConfig.extra.split('_')[1].split(',').length > 0
  ) {
    zoneTypes = fieldConfig.extra.split('_')[1].split(',');
  }

  if (zone && typeof zone.state_show !== 'boolean') {
    // eslint-disable-next-line no-param-reassign
    zone.state_show = true;
  }

  return (
    <div key={index} className="dynamic-ui-form-zone-item">
      {/* <Field name={`zones.${index}`} /> */}

      <div className="row">
        {fieldConfig.extra.includes('custom-area-selector') ? (
          <>
            <div className="col-md-2">
              <Select
                className="dynamic-ui-form-control-full-width"
                // labelId={`zone-value-label${index}`}
                value={zone.value}
                name={`zones.${index}.value`}
                size="small"
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                label="label"
              >
                {zoneKeyList.map((item) => (
                  <MenuItem
                    // disabled={item.asigned_key !== '' && item.asigned_key !== zone.key}
                    value={item.value}
                    key={`zone-value-label${index}-${item.value}`}
                  >
                    {item.value.toString()}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="col-md-2">
              <div>
                <FormControl size="small" className="dynamic-ui-form-control-full-width">
                  <InputLabel id="modal-label1" error={Boolean(touched.modal && errors.modal)}>
                    Area:
                  </InputLabel>
                  <Select
                    className="dynamic-ui-form-control-full-width"
                    labelId={`zone-value-label${index}`}
                    value={zone.area}
                    name={`zones.${index}.area`}
                    size="small"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    label="Area"
                  >
                    {areas.map((item: any) => (
                      <MenuItem value={item.value} key={`zone-value-label${index}-${item.value}`}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* </div> */}
              </div>
            </div>
          </>
        ) : (
          <div className="col-md-2">
            {/* <InputLabel
        id={`zone-value-label${index}`}
        error={Boolean(touched.modal && errors.modal)}
      >
        {"Zone Modal:"}
      </InputLabel> */}

            {/* eslint-disable-next-line no-nested-ternary */}
            {fieldConfig.extra.includes('custom-values') ? (
              <TextField
                className="dynamic-ui-form-control-full-width"
                error={Boolean(
                  touched
                    && touched.zones
                    && touched.zones[index]
                    && touched.zones[index].value
                    && errors
                    && errors.zones
                    && errors.zones[index],
                )}
                helperText={
                  touched
                  && touched.zones
                  && touched.zones[index]
                  && touched.zones[index].value
                  && errors
                  && errors.zones
                  && errors.zones[index]
                  && errors.zones[index].value
                    ? errors.zones[index].value
                    : null
                }
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={zone.value}
                name={`zones.${index}.value`}
                label="Value"
                size="small"
                variant="outlined"
              />
            ) // </div>
              : fieldConfig.extra.includes('custom-zone-switch') ? (
              // <div className="col-md-1">
                <Select
                  className="dynamic-ui-form-control-full-width"
                  labelId={`zone-value-label${index}`}
                  value={zone.value}
                  name={`zones.${index}.value`}
                  size="small"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  label={label}
                >
                  {zoneKeyList.map((item) => (
                    <MenuItem
                      disabled={item.asigned_key !== '' && item.asigned_key !== zone.key}
                      value={item.value}
                      key={`zone-value-label${index}-${item.value}`}
                    >
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              ) // </div>
                : zoneKeyList.length === 0 ? (
                  <div />
                ) : (
                  <Select
                    className="dynamic-ui-form-control-full-width dynamic-ui-form-control-text-center"
                    labelId={`zone-value-label${index}`}
                    value={zone.value}
                    name={`zones.${index}.value`}
                    onBlur={handleBlur}
                    size="small"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    label={label}
                  >
                    {zoneKeyList.map((item) => (
                      <MenuItem
                        disabled={item.asigned_key !== '' && item.asigned_key !== zone.key}
                        value={item.value}
                        key={`zone-value-label${index}-${item.value}`}
                      >
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
          </div>
        )}

        <div className="col-md-3">
          <TextField
            className="dynamic-ui-form-control-full-width"
            error={Boolean(
              touched
                && touched.zones
                && touched.zones[index]
                && touched.zones[index].label
                && errors
                && errors.zones
                && errors.zones[index],
            )}
            helperText={
              touched
              && touched.zones
              && touched.zones[index]
              && touched.zones[index].label
              && errors
              && errors.zones
              && errors.zones[index]
              && errors.zones[index].label
                ? errors.zones[index].label
                : null
            }
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
            }}
            value={zone.label}
            name={`zones.${index}.label`}
            label="Display Name"
            size="small"
            variant="outlined"
          />
        </div>

        {zoneTypes.length > 0 ? (
          <div className="col-md-2">
            <Select
              className="dynamic-ui-form-control-full-width dynamic-ui-form-control-text-center"
              labelId={`zone-type-label${index}`}
              value={zone.type || zoneTypes[0]}
              name={`zones.${index}.type`}
              onBlur={handleBlur}
              size="small"
              onChange={(e) => {
                handleChange(e);
              }}
              label={label}
            >
              {zoneTypes.map((item) => (
                <MenuItem value={item} key={`zone-type-label${index}-${item}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : (
          <div />
        )}

        {fieldConfig.extra.includes('custom-zone-state') ? (
          <div className="col-md-1">
            <FormControlLabel
              label="Show"
              control={(
                <Checkbox
                  checked={typeof zone.state_show !== 'boolean' ? false : zone.state_show}
                  onChange={(e) => {
                    // console.log(!display);
                    handleChange(e);
                    // setDisplay(!display);
                  }}
                  name={`zones.${index}.state_show`}
                  onBlur={handleBlur}
                  color="primary"
                />
              )}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="col-md-2">
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => arrayHelpers.remove(index)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => {
              arrayHelpers.insert(index + 1, {
                label: '',
                value: '',
                key: Date.now(),
              });
            }}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
