/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component which use to list member invitations
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to use list member invitations
 * @author Mayantha
 * @since -
 */
import React from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import styles from './InvitationCard.module.css';

type ComponentProps = {
  title: string;
  approveAction: any;
  rejectAction: any;
  desciption: string;
};

/**
 * Card component which use to list member invitations
 *
 * @callback onApprove
 * @callback onReject
 * @param {Object} props                  component props
 * @param {String} props.title            Text displays in the Option
 * @param {String} props.description      Additional text to display
 * @param {onReject} props.selected       callback emit when user reject invitation
 * @param {approveAction} props.disabled  callback emit when user approve invitation
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const InvitationCardComponent: React.FunctionComponent<ComponentProps> = ({
  title,
  approveAction,
  rejectAction,
  desciption,
}) => (
  <div className={`${styles.root} nopaddingOnly row col-md-12 mr-2`}>
    <div className=" col-md-8 pb-2 mt-2">
      <div className={`${styles.title} mb-1 mt-1`}>{title}</div>
      {/* <div className={styles.permissionType+ " mb-1"}>{permissionType}</div> */}
      <hr className="nopadding mt-1" />
      <div className={`${styles.desciption} mt-1`}>{desciption}</div>
    </div>
    <div className=" col-md-4  pb-1 pt-2">
      <div className=" col-md-12  pb-1">
        <Button
          variant="contained"
          color="primary"
          onClick={approveAction}
          startIcon={<DoneIcon />}
        />
      </div>
      <div className=" col-md-12  pb-1">
        <Button
          variant="contained"
          color="secondary"
          onClick={rejectAction}
          startIcon={<CloseIcon />}
        />
      </div>
    </div>
  </div>
);
