/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete, Checkbox, CircularProgress, Grid, TextField,
} from '@mui/material';
import axios from 'axios';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'src/store/Auth/AuthContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DeviceProfileSelector = (props) => {
  const { deviceSettings, currentProfileId } = props;

  const snackbar = useSnackbar();
  const authUser = useContext(AuthContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const [withCategory, enableCatSearch] = useState<boolean>(true);
  const [withSubCategory, enableSubCatSearch] = useState<boolean>(true);

  const [keyword, setKeyword] = useState('');

  const [catList, setCatList] = useState([
    { id: 1, label: props.parentCategory },
    { id: 2, label: props.parentSubCategory },
  ]);
  const [selectedCatList, setSelectedCatList] = useState<any[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<any>(null);

  useEffect(() => {
    setSelectedCatList(catList);
  }, [catList]);

  useEffect(() => {
    // if (props.deviceType || props.comType === 'uvi') {
    //   loadProfileList(true);
    // }
    console.log('xxxx', props.deviceType);
    if (!currentProfileId || currentProfileId.length === 0) loadProfileList(true);
  }, [props.comType, props.deviceType, currentProfileId]);

  useEffect(() => {
    if (open) {
      loadProfileList(false, '');
    }
  }, [open]);

  useEffect(() => {
    if (currentProfileId && currentProfileId.length > 0) {
      loadProfileList(false, '', currentProfileId);
    }
  }, [currentProfileId]);

  const loadProfileList = async (
    setSelected = false,
    keyword = '',
    currentProfileId = null,
    isInit = false,
  ) => {
    try {
      const { token } = authUser.access_token;
      let tempIds = [];
      if (isInit) {
        tempIds = [props.currentProfileId, selectedProfileId];
      }

      setLoading(true);

      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/com-templates/v1/list`,
        {
          limit: 100,
          skip: 0,
          comp_type: props.comType,
          parent_type: props.parentType,
          parent_id: props.parentProfileId,
          keyword,
          template_ids: tempIds,
          parent_category: withCategory ? props.parentCategory : '',
          parent_sub_category: withSubCategory ? props.parentSubCategory : '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (res.data.data.result) {
        setOptions(res.data.data.result);
        if (isInit) {
          setSelectedProfileId(props.currentProfileId);
        }
        if (currentProfileId) {
          // eslint-disable-next-line no-debugger
          // debugger;
          const found = res.data.data.result.find((item) => item._id === props.currentProfileId);
          if (found) {
            setSelectedProfileId(props.currentProfileId);
            setSelectedProfile(found);
            props.onChangeProfile(found);
          }
        } else if (setSelected) {
          // eslint-disable-next-line no-debugger
          // debugger;

          if (props.comType === 'uvi') {
            let firstItem: any;
            if (props.deviceType.length > 0) {
              const relatedItems = res.data.data.result.filter(
                (item) => item.template_data.data.air_device_type === props.deviceType,
              );
              const found = relatedItems.find((item) => {
                let canSetFields = true;
                const funcs = deviceSettings.selected_functions;
                Object.keys(item.template_data.data.fields).forEach((nkey) => {
                  if (!funcs[item.template_data.data.fields[nkey]]) {
                    canSetFields = false;
                  }
                });

                if (canSetFields) {
                  return true;
                }
                return false;
              });
              firstItem = found;
            } else {
              // eslint-disable-next-line prefer-destructuring
              firstItem = res.data.data.result[0];
            }
            // const firstItem = props.deviceType.length > 0
            //   ? res.data.data.result.find(
            //     (item) => item.template_data.data.air_device_type === props.deviceType,
            //   )
            //   : res.data.data.result[0];
            if (firstItem) {
              setSelectedProfileId(firstItem._id);
              setSelectedProfile(firstItem);
              props.onChangeProfile(firstItem);
            }
            return;
          }

          // eslint-disable-next-line max-len
          const firstItem = props.deviceType.length > 0
            ? res.data.data.result.find(
              (item) => item.template_data.deviceType === props.deviceType,
            )
            : res.data.data.result[0];
          if (firstItem) {
            setSelectedProfileId(firstItem._id);
            setSelectedProfile(firstItem);
            props.onChangeProfile(firstItem);
          }
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const setProfileData = (item: any) => {
    if (item) {
      setSelectedProfile(item);
      setSelectedProfileId(item._id);
      // setName(item.name);
      if (props.onChangeProfile) {
        props.onChangeProfile(item);
      }
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={props.comType === 'uvi' ? 12 : 5}>
        <Autocomplete
          multiple
          size="small"
          id="checkboxes-tags-demo"
          options={catList}
          value={selectedCatList}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="With Categories" />}
          onChange={(event, value) => {
            setSelectedCatList(value);
          }}
        />
      </Grid>
      <Grid item md={props.comType === 'uvi' ? 12 : 6}>
        <Autocomplete
          key={currentProfileId}
          size="small"
          sx={{ width: 300 }}
          value={selectedProfile}
          open={open}
          onInputChange={(e, val, reason) => {
            if (reason === 'input') {
              loadProfileList(false, val);
            }
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => option.name}
          // eslint-disable-next-line max-len
          getOptionDisabled={(option) => {
            if (props.comType === 'uvi') {
              return option.template_data.data.air_device_type !== props.deviceType;
            }
            if (props.comType === 'd') {
              return option.template_data.deviceType !== props.deviceType;
            }
            return false;
          }}
          filterOptions={(x) => x}
          options={options}
          loading={isLoading}
          renderOption={(props, option) => (
            <li {...props} key={option._id}>
              {option.name}
            </li>
          )}
          disabled={
            props.comType !== 'uvi'
            && props.comType !== 'd'
            && (!props.deviceType || props.deviceType.length === 0)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Profile"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(e, val) => {
            setProfileData(val);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DeviceProfileSelector;
