import type { CloudDeviceUVIntegration } from '../../shared/uvitem.config';
import VehicleConfig from './vehicle.config';
import InverterConfig from './inverter.config';
import ChargerConfig from './charger.config';

export enum DEVICE_HIGH_LEVEL_CATOGORIES {
  VEHICLE = 'vehicle',
  INVERTER = 'inverter',
  CHARGER = 'charger',
}

export const itemConfig: CloudDeviceUVIntegration = {
  [DEVICE_HIGH_LEVEL_CATOGORIES.VEHICLE]: VehicleConfig,
  [DEVICE_HIGH_LEVEL_CATOGORIES.INVERTER]: InverterConfig,
  [DEVICE_HIGH_LEVEL_CATOGORIES.CHARGER]: ChargerConfig,
};


// Currently using the default field map from shared service of plugin system.
// TODO: use this later for more customisable usage of uv create form

export const uvSettings = {
  [DEVICE_HIGH_LEVEL_CATOGORIES.VEHICLE]: {
    fields: [
      { type: 'rule_name', default: '' },
      { type: 'macro_name', default: '' },
      { type: 'icon_name', default: 'calc-light1' },
    ],
  },
  [DEVICE_HIGH_LEVEL_CATOGORIES.INVERTER]: {
    fields: [
      { type: 'rule_name', default: '' },
      { type: 'macro_name', default: '' },
      { type: 'icon_name', default: 'calc-light1' },
    ],
  },
  [DEVICE_HIGH_LEVEL_CATOGORIES.CHARGER]: {
    fields: [
      { type: 'rule_name', default: '' },
      { type: 'macro_name', default: '' },
      { type: 'icon_name', default: 'calc-light1' },
    ],
  }
}

export const uvSettingsBasedOnDeviceType = {
  'vehicle': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.VEHICLE],
  'inverter': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.INVERTER],
  'charger': uvSettings[DEVICE_HIGH_LEVEL_CATOGORIES.CHARGER],
}