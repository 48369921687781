/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import TextField from '@mui/material/TextField';
import { ProDevice, DeviceCollectionSearchOutItem } from '@smartlife-redux-state/common';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useContext } from 'react';

import { useDispatch } from 'react-redux';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { proDeviceGetGraphQL } from '../../../../../services/pro-device/pro-device.service';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../store/Project/ProjectContext';
import {
  add_empty_action,
  reset_logic_creation,
  searchDc,
  update_action,
} from '../../../../../store/redux/features/logic-engine';
import { RootState, useSelector } from '../../../../../store/redux/store';
// import { update_av_item_logic } from "../../../../../store/redux/features/logic-engine/slice";
import { IUserViewItem } from '../../../types/types';
import { UVAudioSelector } from '../../AudioSelector/AudioSelector';
import { UVAirGangSelector } from '../../Fields/UVAirGangSelector/UVAirGangSelector';
import { UVAppBrowserItem } from '../../Fields/UVAppBrowserItem/UVAppBrowserItem';
import { UVAppBrowserItemSelector } from '../../Fields/UVAppBrowserItemSelector/UVAppBrowserItemSelector';
import { UVChildItemNotificationSelector } from '../../Fields/UVChildItemNotificationSelector/UVChildItemNotificationSelector';
import { UVControlButtonsSelector } from '../../Fields/UVControlButtonsSelector/UVControlButtonsSelector';
import { DeviceCollectionType } from '../../UVDeviceFinder/types';
import { ICommDevice } from '../../UVItemFinder/types';
import { UVVideoDisplaySelector } from '../../VideoDisplaySelector/VideoDisplaySelector';
import styles from './ItemCreateDialog.module.css';

type ComponentProps = {
  selectedDevice: ICommDevice | null;
  uvItem: IUserViewItem | null;
  fieldConfigs: any;
  submitRefresh: number;
  appendSettings: (payload: any) => void;
  updateCustomFieldErrors: (err: any) => void;
};
const ItemCreatorExtraFieldGen: React.FunctionComponent<ComponentProps> = ({
  uvItem,
  selectedDevice,
  fieldConfigs,
  appendSettings,
  submitRefresh,
  updateCustomFieldErrors,
}) => {
  let canUseGangSelector = false;
  const snackbar = useSnackbar();
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const [avMacroInfo, setAvMacroInfo] = useState({
    audio: null,
    video: null,
  });
  const dispatch = useDispatch();
  const ruleEngine = useSelector((state: RootState) => state.rule_engine);
  const [selectedDeviceType, setDeviceType] = useState<any>();
  const [currentSettings, setCurrentSettings] = useState<any>({});
  const [currentSettingsForProfile, setCurrentSettingsForProfile] = useState<any>({});
  const [templateId, setTemplateId] = useState<string>(null);

  useEffect(() => {
    // console.log("FIELD CONFIG CHANGED", fieldConfigs);
    dispatch(reset_logic_creation());
    dispatch(
      searchDc({
        keyword: '',
        project_id: selectedProject.selected_project.id,
      }),
    );
    if (fieldConfigs.video_display_selector.enable) {
      dispatch(add_empty_action());
    }
    if (fieldConfigs.audio_selector.enable) {
      dispatch(add_empty_action());
    }
  }, [fieldConfigs]);

  useEffect(() => {
    function avVariableBuilder(zoneValue: number, device: ProDevice, sourceValue: number) {
      const settings = JSON.parse(device.settings);
      const zones = settings.zones ? settings.zones : [];

      const zone: {label: string; value: number; key: number} = zones.find(
        (item) => item.value === zoneValue,
      );

      return [
        {
          delay: 1,
          index: moment().unix(),
          state: 'pwr',
          state_value: '01',
          zone,
        },
        {
          delay: 1,
          index: moment().unix(),
          state: 'src',
          state_value: sourceValue,
          zone,
        },
      ];
    }
    /**
     * This function uses to figure out the real HDMI value from the item config that
     * is needed to replace with the value selected from VideoDisplaySelector HDMI
     * @param device
     * @param hdmiValue
     * @returns
     */
    function irVaribaleBuilder(device: ProDevice, hdmiValue: string) {
      const settings = JSON.parse(device.settings);
      // eslint-disable-next-line max-len
      const keyMap = ITEM_DEFAULTS.IR_DEFAULTS[device.sub_cat][settings.device.toUpperCase()].versions[
        settings.key_map_version
      ];

      let key = '';
      switch (hdmiValue) {
        case 'hdmi1':
          key = 'KEY_HDMI_1';
          break;
        case 'hdmi2':
          key = 'KEY_HDMI_2';
          break;
        case 'hdmi3':
          key = 'KEY_HDMI_3';
          break;
        case 'hdmi4':
          key = 'KEY_HDMI_4';
          break;
        default:
          key = '';
          break;
      }

      const HDMIPayload = {
        key,
        label: keyMap.key_map[key],
      };

      return [
        {
          delay: 8,
          index: moment().unix(),
          remote_button: [
            {
              ...HDMIPayload,
            },
            {
              key: 'KEY_POWER_ON',
              label: 'Power ON',
            },
          ],
        },
        {
          delay: 1,
          index: moment().unix(),
          remote_button: [HDMIPayload],
        },
      ];
    }
    /**
     * This function uses to build the state of AudioSelector. The return value is same as
     * when create the Ac controller action with power state and source set from logic view
     */
    async function audioFn() {
      if (
        avMacroInfo.audio
        && avMacroInfo.audio.data.audio_device
        && avMacroInfo.audio.data.audio_device.device_id
      ) {
        const action = ruleEngine.actions[0]; // Assuming index 0 is alway belongs to audio selector

        const device: ProDevice = await proDeviceGetGraphQL(
          userProfile.apollo_client,
          avMacroInfo.audio.data.audio_device.device_id,
        );
        const dc: DeviceCollectionSearchOutItem = ruleEngine.device_containers.result.find(
          (item) => item.id === device.prodc_id,
        );

        const cpy = { ...action };
        cpy.device = device;
        cpy.dc = dc;

        if (device.cat === 'ac') {
          let variables = null;
          if (
            avMacroInfo.audio
            && avMacroInfo.audio.data.audio_device
            && avMacroInfo.audio.data.audio_device.zone
            && avMacroInfo.audio.data.audio_device.source
            && !avMacroInfo.audio.data.audio_device.clean_logic
          ) {
            variables = avVariableBuilder(
              avMacroInfo.audio ? avMacroInfo.audio.data.audio_device.zone : null,
              device,
              avMacroInfo.audio ? avMacroInfo.audio.data.audio_device.source : null,
            );
          }

          cpy.variable = variables ? JSON.stringify(variables) : 'x'; // HACK- x will cause to throw an error when parsing logic. so that user won't able to create/update uv item without zone/source selected for ac
          cpy.possible_variable_type = 'ac';
        }

        // update action
        if (device.sub_cat === 'tv_remotes') {
          dispatch(
            update_action({
              isChild: false,
              action: {
                ...cpy,
                device: null,
                dc: null,
                variable: null,
              },
            }),
          );
        } else {
          dispatch(
            update_action({
              isChild: false,
              action: cpy,
            }),
          );
        }
      }
    }
    /**
     * Same as audioFn but here it can be either AC or IR
     */
    async function videoFn() {
      if (
        avMacroInfo.video
        && avMacroInfo.video.data.video_display_selector
        && avMacroInfo.video.data.video_display_selector.device_id
      ) {
        const action = ruleEngine.actions[1]; // Assuming index 1 is alway belongs to video selector

        const device: ProDevice = await proDeviceGetGraphQL(
          userProfile.apollo_client,
          avMacroInfo.video.data.video_display_selector.device_id,
        );
        const dc: DeviceCollectionSearchOutItem = ruleEngine.device_containers.result.find(
          (item) => item.id === device.prodc_id,
        );

        const cpy = { ...action };
        cpy.device = device;
        cpy.dc = dc;

        if (device.cat === 'ac') {
          let variables = null;
          if (
            avMacroInfo.video
            && avMacroInfo.video.data.video_display_selector
            && avMacroInfo.video.data.video_display_selector.zone
            && avMacroInfo.video.data.video_display_selector.source
            && !avMacroInfo.video.data.video_display_selector.clean_logic
          ) {
            variables = avVariableBuilder(
              avMacroInfo.video ? avMacroInfo.video.data.video_display_selector.zone : null,
              device,
              avMacroInfo.video ? avMacroInfo.video.data.video_display_selector.source : null,
            );
          }
          cpy.variable = variables ? JSON.stringify(variables) : null;
          cpy.possible_variable_type = 'ac';
        }

        if (device.cat === 'ir') {
          let variables = null;
          if (
            avMacroInfo.video
            && avMacroInfo.video.data.video_display_selector
            && avMacroInfo.video.data.video_display_selector.hdmi_port
            && !avMacroInfo.video.data.video_display_selector.clean_logic
          ) {
            variables = irVaribaleBuilder(
              device,
              avMacroInfo.video && avMacroInfo.video.data.video_display_selector
                ? avMacroInfo.video.data.video_display_selector.hdmi_port
                : null,
            );
          }

          cpy.variable = variables ? JSON.stringify(variables) : null;
          cpy.possible_variable_type = 'ir_remote';
        }

        // update action
        dispatch(
          update_action({
            isChild: false,
            action: cpy,
          }),
        );
      }
    }
    if (avMacroInfo.audio) {
      audioFn();
    }
    if (avMacroInfo.video) {
      videoFn();
    }
  }, [avMacroInfo]);

  let deviceSettings: any = {};
  if (
    selectedDevice
    && selectedDevice.dc_type === DeviceCollectionType.airDevice
    && selectedDevice.settings
  ) {
    canUseGangSelector = true;
    deviceSettings = JSON.parse(selectedDevice.settings);
  }

  const [description, setDescription] = useState('');
  const [updatedUVItem, setUpdatedUVItem] = useState(JSON.parse(JSON.stringify(uvItem)));
  useEffect(() => {
    if (uvItem && uvItem.settings?.description) {
      setDescription(uvItem.settings?.description);
    }
    if (uvItem && uvItem.settings?.uvi_template_id) {
      setTemplateId(uvItem.settings.uvi_template_id);
    }
  }, [uvItem]);

  const [comProfile, setComProfile] = useState<any>();
  useEffect(() => {
    if (
      currentSettings
      && comProfile
      && comProfile.template_data
      && comProfile.template_data.data
      && comProfile.template_data.data.fields
    ) {
      let canSetFields = true;

      if (deviceSettings.selected_functions) {
        const funcs = deviceSettings.selected_functions;

        Object.keys(comProfile.template_data.data.fields).forEach((nkey) => {
          if (!funcs[comProfile.template_data.data.fields[nkey]]) {
            canSetFields = false;
          }
        });
        if (canSetFields) {
          // setTemplateId(comProfile._id);

          appendSettings({
            ...currentSettings,
            data: {
              ...comProfile.template_data.data,
              uvi_template_id: comProfile._id,
            },
          });

          setCurrentSettingsForProfile({
            ...currentSettings,
            data: {
              ...comProfile.template_data.data,
              uvi_template_id: comProfile._id,
            },
          });
          let uvData = {
            settings: {},
          };

          if (uvItem) {
            uvData = { ...uvData, ...uvItem };
            if (uvItem.settings) {
              uvData = { ...uvData, settings: uvItem.settings };
            }
          }
          uvData.settings = { ...uvData.settings, ...comProfile.template_data.data };
          setUpdatedUVItem(uvData);
        } else {
          snackbar.enqueueSnackbar("Selected UV Item profile doesn't match with the device", {
            variant: 'error',
          });
        }
      }
    }
  }, [currentSettings, comProfile]);

  return (
    <div>
      {fieldConfigs.description.enable ? (
        <div className={styles.textInput}>
          <TextField
            className="uv-ui-form-control-full-width"
            onChange={(e) => {
              appendSettings({ data: { description: e.target.value } });
              setDescription(e.target.value);
            }}
            value={description}
            name="description"
            label="Description"
            size="small"
            variant="outlined"
          />
        </div>
      ) : (
        <div />
      )}
      {fieldConfigs.audio_selector.enable ? (
        <div>
          <UVAudioSelector
            key="audio_selector"
            onChangePayloadRootKey="audio_device"
            fieldConfig={fieldConfigs.audio_selector}
            uvItem={uvItem}
            onChange={(payload: any) => {
              setAvMacroInfo((state) => ({ ...state, audio: payload }));
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
          />
        </div>
      ) : (
        <div />
      )}

      {canUseGangSelector ? (
        <div>
          <UVAirGangSelector
            key="air-gang-selector"
            fieldConfig={fieldConfigs.gang_selector}
            uvItem={uvItem}
            submitRefresh={submitRefresh}
            device={selectedDevice}
            profileProps={{
              currentSettings: currentSettingsForProfile,
              deviceSettings,
              selectedDevice,
              templateId,
            }}
            onChange={(payload: any) => {
              appendSettings(payload);
              setCurrentSettings(payload);
            }}
            onDeviceTypeChange={(payload: any) => {
              setDeviceType(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
            onChangeProfile={(profile) => {
              setComProfile(profile);
            }}
          />
        </div>
      ) : (
        <div />
      )}

      {fieldConfigs.app_browser_item.enable ? (
        <div>
          <UVAppBrowserItem
            key="app_browser_item"
            fieldConfig={fieldConfigs.video_display_selector}
            uvItem={uvItem}
            submitRefresh={submitRefresh}
            onChange={(payload: any) => {
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
          />
        </div>
      ) : (
        <div />
      )}
      {fieldConfigs.video_display_selector.enable ? (
        <div>
          <UVVideoDisplaySelector
            key="video_display_selector"
            fieldConfig={fieldConfigs.video_display_selector}
            uvItem={uvItem}
            onChange={(payload: any) => {
              setAvMacroInfo((state) => ({ ...state, video: payload }));
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
          />
        </div>
      ) : (
        <div />
      )}

      {fieldConfigs.camera_selector.enable ? (
        <div>
          <UVControlButtonsSelector
            key="camera_selector"
            fieldConfig={fieldConfigs.camera_selector}
            uvItem={uvItem}
            payloadKey="camera_selector"
            fieldKey="camera_list"
            label="Camera"
            onChange={(payload: any) => {
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
          />
        </div>
      ) : (
        <div />
      )}
      {/* {fieldConfigs.audio_devices.enable || fieldConfigs.control_buttons_selector.enable && (
          <div>A Logic(Macro) will be created once you save this item.
          If you want to change the generated logic(Macro) please use the logic engine module</div>
        )} */}
      {fieldConfigs.audio_devices.enable ? (
        <div>
          <UVControlButtonsSelector
            key="audio_devices_selector"
            fieldConfig={fieldConfigs.audio_devices}
            uvItem={uvItem}
            payloadKey="audio_devices_selector"
            fieldKey="audio_device_list"
            label="Audio Devices"
            onChange={(payload: any) => {
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
            zoneSelector
          />
        </div>
      ) : (
        <div />
      )}
      {fieldConfigs.alarm_devices.enable ? (
        <div>
          <UVControlButtonsSelector
            key="alarm_devices_selector"
            fieldConfig={fieldConfigs.alarm_devices}
            uvItem={uvItem}
            payloadKey="audio_devices_selector"
            fieldKey="alarm_device_list"
            label="Alarm Devices"
            onChange={(payload: any) => {
              console.log(payload);
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
            alarmOutSelector
          />
        </div>
      ) : (
        <div />
      )}
      {fieldConfigs.control_buttons_selector.enable ? (
        <div>
          <UVControlButtonsSelector
            key="control_buttons_selector"
            fieldConfig={fieldConfigs.control_buttons_selector}
            uvItem={uvItem}
            payloadKey="control_buttons_selector"
            fieldKey="btn_list"
            label="Control Buttons"
            onChange={(payload: any) => {
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
          />
        </div>
      ) : (
        <div />
      )}
      {((!selectedDevice || selectedDevice.dc_type === 'pro')
        && fieldConfigs.control_buttons_selector2.enable)
      || (selectedDevice
        && selectedDevice.dc_type === 'air'
        && fieldConfigs.control_buttons_selector2.enable
        && selectedDeviceType === 'camera') ? (
          <div>
            <UVAppBrowserItemSelector
              key="control_buttons_selector2"
              title="Control Buttons"
              fieldKey="btn_list2"
              fieldConfig={fieldConfigs.control_buttons_selector2}
              uvItem={uvItem}
              onChange={(payload: any) => {
                appendSettings(payload);
              }}
              updateCustomFieldErrors={(error: any) => {
                updateCustomFieldErrors(error);
              }}
            />
          </div>
        ) : (
          <div />
        )}
      {((!selectedDevice || selectedDevice.dc_type === 'pro')
        && fieldConfigs.uv_item_selector.enable)
      || (selectedDevice
        && selectedDevice.dc_type === 'air'
        && fieldConfigs.uv_item_selector.enable
        && selectedDeviceType === 'camera') ? (
          <div>
            <UVAppBrowserItemSelector
              key="uv_item_selector"
              title="App Browser Items"
              fieldKey="uv_item_selector"
              fieldConfig={fieldConfigs.uv_item_selector}
              uvItem={uvItem}
              onChange={(payload: any) => {
                appendSettings(payload);
              }}
              updateCustomFieldErrors={(error: any) => {
                updateCustomFieldErrors(error);
              }}
            />
          </div>
        ) : (
          <div />
        )}

      {fieldConfigs.child_notification_item_selector.enable ? (
        <div>
          <UVChildItemNotificationSelector
            key="child_notification_item_selector"
            title="Child item notifications"
            fieldKey="child_notification_item_selector"
            fieldConfig={fieldConfigs.child_notification_item_selector}
            uvItem={uvItem}
            onChange={(payload: any) => {
              appendSettings(payload);
            }}
            updateCustomFieldErrors={(error: any) => {
              updateCustomFieldErrors(error);
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default ItemCreatorExtraFieldGen;
