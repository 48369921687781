/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Check, TIME_FRAME } from '@smartlife-redux-state/common';
import { update_check } from 'src/store/redux/features/logic-engine';
import { useDispatch } from 'react-redux';
import { isJson } from './TimeCheck';

export const DaysOfWeek = [
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
  { label: 'Sunday', value: '0' },
];

const useStyles = makeStyles({
  formRoot: {
    width: '100%',
  },
  dayCard: {
    display: 'flex',
    alignItems: 'center',
    background: '#818181',
    borderRadius: '2px',
    height: '100%',
    width: '100%',
    // margin: "2px",
    padding: '8px',
    color: '#ffffff',
    cursor: 'pointer',
  },
  dayCardActive: {
    background: '#048696',
  },
});

export const DateCheck: React.FC<{check: Check}> = ({ check }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [time_state, setTimeState] = useState({
    days: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      0: false,
    },
  });

  useEffect(() => {
    if (check.variable && isJson(check.variable)) {
      const parsed = JSON.parse(check.variable);
      setTimeState(parsed);
    } else if (!check.variable || !isJson(check.variable)) {
      dispatch(
        update_check({
          check: {
            ...check,
            variable: JSON.stringify({
              ...time_state,
            }),
          },
        }),
      );
    }
  }, [check]);

  function handleDayItemClick(value: any) {
    dispatch(
      update_check({
        check: {
          ...check,
          variable: JSON.stringify({
            days: {
              ...time_state.days,
              [value]: !time_state.days[value],
            },
          }),
        },
      }),
    );
    // setTimeState({
    //   ...time_state,
    //   days: {
    //     ...time_state.days,
    //     [value]: !time_state.days[value],
    //   },
    // });
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Grid container spacing={1}>
          {DaysOfWeek.map((d, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={index}>
              <div
                className={clsx(
                  classes.dayCard,
                  time_state.days[d.value] ? classes.dayCardActive : '',
                )}
                onClick={() => handleDayItemClick(d.value)}
              >
                {d.label}
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
