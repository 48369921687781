/* eslint-disable consistent-return */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Content of the each logic step that render according to the active step.
 * Here we reuse <LogicStep /> from the `./LogicStepper`
 * and was able to prevent nasted conditional rendering.
 *
 * @author Maduka Dilshan
 */

/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import React, {
  useContext, useState, useCallback, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import NavigationPrompt from 'react-router-navigation-prompt';
import { insertInOrder } from '@smartlife-redux-state/orm';
import {
  buildActionPayload,
  buildLogicPayload,
  buildWhenPayload,
  toDenormalize,
  toNormalize,
} from '@smartlife-redux-state/common';
import extractDepMap from 'src/store/redux/features/logic-engine/helpers/extractDependcyMap';
import parseCustomApiError from 'src/utility/parseError';
import { LogicStep } from './LogicStepper';
import PageContentLayout, { PageConentHeader, PageContent } from './PageContentLayout';
import { FillButton } from './ui/FillButton';
import { BoardWhen } from './board-usage/When';
import { BoardCheck } from './board-usage/Check';
import { BoardAction } from './board-usage/Action';
import { RootState } from '../../../../store/redux/store';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import {
  define_check_expression,
  reset_logic_creation,
} from '../../../../store/redux/features/logic-engine/slice';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';

import { CofirmDialog } from './ui/Confirm';
import { VerifyLogic } from './VerifyLogic';
import useBeforeUnload from '../../../../hooks/useBeforeUnload';
import useCustomPrompt from '../../../../hooks/useCustomPrompt';

const useStyle = makeStyles({
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '2%',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  bottom: {
    width: '100%',
    display: 'flex',
  },
  detailsBox: {
    width: '100%',
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "flex-end",
    // border: "1px solid #D9DFE0",
    padding: '2% 0',
    justifyContent: 'space-between',
    // borderRadius: "5px",
  },
});

const MemoizedCreateRuleContent: React.FC<{
  logicName: string;
  logicState: string;
  alexaName: string;
  isEditing: boolean;
  isSubmitting: boolean;
  setIsSubmitting: any;
  setAlexaName: (value) => void;
  setLogicName: (value) => void;
  setLogicState: (value) => void;
  onLogicCreate: () => void;
}> = React.memo(
  ({
    logicName,
    logicState,
    alexaName,
    isSubmitting,
    isEditing = false,
    setIsSubmitting,
    setAlexaName,
    setLogicName,
    setLogicState,
    onLogicCreate,
  }) => {
    const styles = useStyle();
    const navgiate = useNavigate();
    const [deleteActionId, setDeleteActionid] = useState(null);
    const dispatch = useDispatch();

    const selectedProject = useContext(ProjectContext);

    function setExpressoin() {
      dispatch(define_check_expression());
    }

    function deleteAction() {
      dispatch(reset_logic_creation());
      navgiate(`/app/logics?projectId=${selectedProject.selected_project.id}`);
      setDeleteActionid(null);
    }

    return (
      <PageContentLayout className={styles.root}>
        <Grid container spacing={3} className={styles.detailsBox}>
          <Grid item md={9} xs={12}>
            <TextField
              value={logicName}
              onChange={(e) => setLogicName(e.target.value)}
              label="Logic Name"
              size="small"
              required
              style={{ width: '100%' }}
            />
          </Grid>

          {/* <Grid item md={4} xs={12}>
            <TextField
              value={alexaName}
              onChange={(e) => {
                console.log("xxx", e.target.value);
                setAlexaName(e.target.value);
              }}
              label="Alexa Name"
              size="small"
              style={{ width: "100%" }}
            />
          </Grid> */}

          <Grid item md={3} xs={12}>
            <FormControl size="small" style={{ width: '100%' }}>
              <InputLabel>Logic State</InputLabel>
              <Select
                value={logicState}
                label="Logic State"
                onChange={(e: any) => setLogicState(e.target.value)}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="stop">Disabled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <LogicStep>
          {({ activeStep, setActive }) => {
            const index = 0;
            if (index !== activeStep) return <></>;
            return (
              <>
                <PageConentHeader>
                  <Typography variant="h5" component="h2">
                    When
                  </Typography>
                </PageConentHeader>
                <PageContent className={styles.content}>
                  <Box>
                    <BoardWhen />
                  </Box>
                  <Box className={styles.bottom}>
                    <FillButton onClick={() => setActive(1)}>Continue</FillButton>
                    <Box width={20} />
                    <FillButton
                      onClick={() => {
                        deleteAction();
                        // setDeleteActionid(1);
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </FillButton>
                  </Box>
                </PageContent>
              </>
            );
          }}
        </LogicStep>
        <LogicStep>
          {({ activeStep, setActive }) => {
            const index = 1;
            if (index !== activeStep) return <></>;

            return (
              <>
                <PageConentHeader>
                  <Typography variant="h5" component="h2">
                    Check
                  </Typography>
                </PageConentHeader>
                <PageContent className={styles.content}>
                  <Box>
                    <BoardCheck />
                  </Box>
                  <Box className={styles.bottom}>
                    <FillButton
                      onClick={() => {
                        setActive(2);
                        setExpressoin();
                      }}
                    >
                      Continue
                    </FillButton>
                    <Box width={20} />
                    <FillButton
                      onClick={() => {
                        deleteAction();
                        // setDeleteActionid(1);
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </FillButton>
                  </Box>
                </PageContent>
              </>
            );
          }}
        </LogicStep>
        <LogicStep>
          {({ activeStep, setActive }) => {
            const index = 2;
            if (index !== activeStep) return <></>;
            return (
              <>
                <PageConentHeader>
                  <Typography variant="h5" component="h2">
                    Action Queues
                  </Typography>
                </PageConentHeader>
                <PageContent className={styles.content}>
                  <Box>
                    <BoardAction />
                  </Box>

                  <Box className={styles.bottom}>
                    <FillButton
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onClick={() => {
                        onLogicCreate();
                      }}
                    >
                      {isEditing ? 'Update Logic' : 'Create Logic'}
                    </FillButton>
                    <Box width={20} />
                    <FillButton
                      loading={false}
                      onClick={() => {
                        deleteAction();
                        // setDeleteActionid(1);
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </FillButton>
                  </Box>
                </PageContent>
              </>
            );
          }}
        </LogicStep>
        <CofirmDialog
          dialogOpen={!!deleteActionId}
          dialogCloseEvent={(e: any) => {
            if (e === true) {
              deleteAction();
            } else {
              setDeleteActionid(null);
            }
          }}
          title="Are you sure to cancel ? "
          text="Cancelling will reset the logic creat form"
        />
      </PageContentLayout>
    );
  },
);

const CreateRuleContent = () => {
  const navgiate = useNavigate();
  const [logicName, setLogicName] = useState('');
  const [logicState, setLogicState] = useState('active');
  const [alexaName, setAlexaName] = useState('');
  const [sendCreateReq, setSendCreatReq] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [showPrompt, setShowPrompt] = useState(false);

  const logic_id = useQueryString('logicId') as string;

  const logic = useSelector((state: RootState) => {
    const found = state.logics.result.result.find((item) => item.logic_id === logic_id);
    return found || null;
  });

  const mounted = useRef(0);

  useEffect(() => {
    if (logic_id && logic_id.length > 0 && logic && mounted.current === 0) {
      console.log('((((((((');
      setLogicName(logic.name);
      setLogicState(logic.status);
      setAlexaName(logic.alexa_friendly_name);
      mounted.current = 1;
    }
  }, [logic_id, logic]);

  const authUser = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const dispatch = useDispatch();

  const state = useSelector((reduxState: RootState) => reduxState.rule_engine);
  const orm = useSelector((reduxState: RootState) => reduxState.orm);

  const { actions } = state;
  const { checks } = state;
  const { triggers } = state;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // const normlized = toNormalize(state);
    // insertInOrder(normlized, dispatch);
  }, [state]);

  const onDelayedLogicCreate = () => {
    setIsSubmitted(true);
    const normlized = toNormalize(state);
    insertInOrder(normlized, dispatch);
    setTimeout(() => {
      setSendCreatReq(true);
    }, 1000);
  };

  useEffect(() => {
    if (sendCreateReq) {
      onLogicCreate();
    }
  }, [sendCreateReq]);

  const onLogicCreate = useCallback(() => {
    try {
      console.log(orm);
      const deNorm = toDenormalize(orm);
      console.log('DENORM', deNorm);
      if (!logicName || logicName.length === 0) {
        throw new Error('Logic Name Required');
      }
      // eslint-disable-next-line prefer-regex-literals
      const friendlyPhraseReg = new RegExp(/^[a-zA-Z\s]+$/);
      // eslint-disable-next-line array-callback-return
      triggers.map((trigger) => {
        if (trigger.type === 'voice') {
          try {
            const payload = JSON.parse(trigger.variable);
            if (!friendlyPhraseReg.test(payload.friendly_name)) {
              throw new Error('Invalid Voice Phrase!. Please check triggers');
            }
          } catch (err) {
            throw new Error('Invalid Voice Phrase!. Please check triggers');
          }
        }
      });
      // eslint-disable-next-line max-len
      const activeActions = actions.filter(
        (action) => (action.meta && action.meta.is_enabled) || !action.meta,
      );

      if (activeActions.length === 0) {
        throw new Error('You must have atleast one active action');
      }

      setShowPrompt(false);
      const parsedTriggers = buildWhenPayload(triggers);
      const parsedChecks = buildLogicPayload(checks.list, checks.content, checks.expression);

      // console.log('Before Action', actions);
      // const variables = JSON.parse(actions[0].variable);
      // const vars = concatTvCommandsWithZeroDelay(0, variables);
      // console.log('>>>>>>>>>>', vars);
      const parsedActions = buildActionPayload(actions);

      const { userviewIdsMap, logicIdsMap } = extractDepMap(orm);

      console.log(logicName, alexaName, logicState);
      const state_cpy = cloneDeep(state);
      state_cpy.devices = { result: [] }; // .result = [];
      state_cpy.device_containers = { result: [] }; // .result = [];
      state_cpy.device_form_resovler = {};
      const payload = {
        project_id: selectedProject.selected_project.id,
        status: logicState,
        name: logicName,
        // alexa_friendly_name: alexaName,
        input_json: JSON.stringify(orm),
        other_dep_map: {
          userview_ids_map: userviewIdsMap,
          logics_ids_map: logicIdsMap,
        },
        triggers: parsedTriggers,
        rules: parsedChecks.rules,
        logic: parsedChecks.logic,
        actions: parsedActions,
      };
      console.log('Other Map');
      console.log('Final Payload', payload);

      if (state.meta.is_editing) {
        return editLogic(payload);
      }
      createLogic(payload);
    } catch (err: any) {
      // eslint-disable-next-line max-len
      const msg = parseCustomApiError(err.response && err.response.data ? err.response.data : err);
      enqueueSnackbar(msg, { variant: 'error' });
      // enqueueSnackbar(err.message, { variant: 'error' });
      setIsSubmitted(false);
    } finally {
      setSendCreatReq(false);
    }
    return null;
  }, [actions, checks, triggers, logicName, logicState, alexaName, orm]);

  // function concatTvCommandsWithZeroDelay(iterator: number, variables: any[]) {
  //   console.log(iterator, variables);
  //   // eslint-disable-next-line no-debugger
  //   // debugger;
  //   const variable = variables[iterator];
  //   const nextVariable = variables[iterator + 1];

  //   if (!nextVariable) return variables;

  //   if (variable.delay === 0) {
  //     const remoteKeys = [...variable.remote_button, ...nextVariable.remote_button];
  //     const { delay } = nextVariable;
  //     const { index } = nextVariable;

  //     let variablesCpy = cloneDeep(variables);
  //     variablesCpy.splice(0, 2);

  //     variablesCpy = [{ remoteKeys, delay, index }, ...variablesCpy];

  //     return concatTvCommandsWithZeroDelay(iterator, variablesCpy);
  //   }
  //   return concatTvCommandsWithZeroDelay(iterator + 1, variables);
  // }

  async function createLogic(payload: any) {
    try {
      const { token } = authUser.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      dispatch(reset_logic_creation());
      navgiate(`/app/logics?projectId=${selectedProject.selected_project.id}`);
      enqueueSnackbar('Logic created', { variant: 'success' });
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data && err.response.data.error);
      enqueueSnackbar(msg, { variant: 'error' });
      // enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setIsSubmitted(false);
    }
  }

  async function editLogic(payload: any) {
    try {
      // eslint-disable-next-line no-param-reassign
      payload.logic_id = logic_id;
      const { token } = authUser.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/logic-engine/v1/logic-item/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      dispatch(reset_logic_creation());
      navgiate(`/app/logics?projectId=${selectedProject.selected_project.id}`);
      enqueueSnackbar('Logic updated', { variant: 'success' });
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data && err.response.data.error);
      enqueueSnackbar(msg, { variant: 'error' });
      // enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setIsSubmitted(false);
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      setShowPrompt(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <MemoizedCreateRuleContent
      logicName={logicName}
      logicState={logicState}
      alexaName={alexaName}
      isEditing={state.meta.is_editing}
      setAlexaName={setAlexaName}
      setLogicName={setLogicName}
      setLogicState={setLogicState}
      onLogicCreate={onDelayedLogicCreate}
      isSubmitting={isSubmitted}
      setIsSubmitting={setIsSubmitted}
    />
  );
};

export default CreateRuleContent;
