/* eslint-disable max-len */
/* eslint-disable camelcase */
import {
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { cloneDeep, isArray } from 'lodash';
import { useFormikContext } from 'formik';
import { Action, ActionTypes } from '@smartlife-redux-state/common';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { ProjectContext } from '../../../../../../../store/Project/ProjectContext';
import { searchLogicList } from '../../../../../../../store/redux/features/logics';
import { update_action } from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState, useDispatch, useSelector } from '../../../../../../../store/redux/store';

const ChildRaw: React.FC<{
  index: number;
  action: Action;
  isChild: boolean;
  parentAction: Action;
  result: any;
}> = ({
  index, action, isChild, parentAction, result,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [logics_loading, setLogicLoading] = useState(false);
  const currentLogicId = useQueryStringFirst('logicId');

  const [options, setOptions] = useState([]);

  useEffect(() => {
    let list = [];
    if (isArray(result)) {
      list = result.map((lgc) => ({ logic_id: currentLogicId === lgc.logic_id ? 'CURRENT_LOGIC' : lgc.logic_id, name: currentLogicId === lgc.logic_id ? `${lgc.name} (CURRENT LOGIC)` : lgc.name }));
      setOptions(list);
    }
  }, [result]);

  return (
    <fieldset style={{ padding: '3%', borderRadius: '5px', width: '100%' }}>
      <legend>
        {index === 0 ? (
          <Typography variant="button">
            Change the status of below list of logics to
            {' '}
            <Chip label="RUN" color="primary" size="small" />
          </Typography>
        ) : (
          <Typography variant="button">
            Change the status of below list of logics to
            {' '}
            <Chip label="TERMINATE" color="primary" size="small" />
          </Typography>
        )}
      </legend>
      <Grid container spacing={2}>
        {/* <Grid item md={12}>
        {index == 0 ? (
          <Typography>
            Change the status of below list of logics to "ACTIVE"
          </Typography>
        ) : (
          <Typography>
            Change the status of below list of logics to "DISABLED"
          </Typography>
        )}
      </Grid> */}
        <Grid item md={12} xs={12}>
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(e, value) => {
              console.log(value);
              let variable_cpy = cloneDeep(action.variable as any) as any[];
              if (!isArray(variable_cpy)) variable_cpy = [];

              variable_cpy = value;

              dispatch(
                update_action({
                  action: {
                    ...action,
                    variable: variable_cpy as any,
                  },
                  isChild,
                  parentAction,
                }),
              );
            }}
            getOptionLabel={(option) => option.name}
            options={options}
            isOptionEqualToValue={(option, value) => {
              const selectedLgcs = action.variable;
              if (selectedLgcs && isArray(selectedLgcs)) {
                const found = selectedLgcs.find((lgc) => lgc.logic_id === option.logic_id);
                if (found) {
                  return true;
                }
                return false;
              }
              return false;
            }}
            value={action.variable ? isArray(action.variable) && action.variable : []}
            loading={logics_loading}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Search logic"
                // onChange={(e) => console.log('xcxcxcx', e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {logics_loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Chip label={option.name.substring(0, 30)} {...getTagProps({ index })} />
            ))}
            multiple
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const LogicStatusAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
  flag: ActionTypes;
}> = ({
  action, isChild = false, parentAction, flag,
}) => {
  console.log('xxxxxx', action, flag);
  const selectedProject = useContext(ProjectContext);
  const dispatch = useDispatch();
  const { resultCount, result } = useSelector((state: RootState) => state.logics.result);

  const [logics_loading, setLogicLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!action.variable || !isArray(action.variable)) {
      dispatch(
        update_action({
          action: {
            ...action,
            variable: [] as any,
          },
          isChild,
          parentAction,
        }),
      );
    }

    dispatch(
      searchLogicList({
        project_id: selectedProject.selected_project.id,
        limit: 100,
        skip: 0,
        keyword: '',
        logic_status: '',
        logic_version: '',
      }),
    );
  }, []);

  useEffect(() => {
    if (!result || !resultCount || result.length < resultCount) {
      dispatch(
        searchLogicList({
          project_id: selectedProject.selected_project.id,
          limit: 100,
          skip: 0,
          keyword: '',
          logic_status: '',
          logic_version: '',
        }),
      );
    }
  }, [open]);

  return (
    <Grid container spacing={1}>
      {action && (
        <ChildRaw
          index={flag === ActionTypes.LOGIC_RUN_STATUS ? 0 : 1}
          action={action}
          isChild={isChild}
          parentAction={parentAction}
          result={result}
        />
      )}
    </Grid>
  );
};
