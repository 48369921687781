/**
 * Copyright (C) Smartlife 2021 The Smartlife Connet Project.
 *
 * Card component used for list Air devices
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define the air device card
 * @author Maduka Dilshan
 * @since 1.0.0
 */
import React from 'react';
import PropsTypes from 'prop-types';
import styles from './AirLiveCard.module.css';

type ComponentProps = {
  productName: string;
  category: any;
  id?: string;
  name: string;
  bgColor?: string;
  cursor?: string;
};

/**
 * Card component use for list air devices
 *
 * @param {Object} props              component props
 * @param {String} props.productName  air device product name
 * @param {String} props.category     air device category
 * @param {String} props.id           air device id (non the tuya id but the db id)
 * @param {String} props.name         air device custom name
 * @param {String} props.bgColor      background color of the card
 * @param {String} props.cursor       cursor style when hovering over the card
 *
 * @returns JSX.Element
 */
const AirLiveCard: React.FunctionComponent<ComponentProps> = ({
  productName,
  id,
  name,
  category,
  bgColor,
  cursor,
}) => (
  <div
    className={styles.root}
    style={{
      background: bgColor || 'white',
      cursor: cursor || 'pointer',
    }}
  >
    <div className={styles.data}>
      <div>
        <div className={`${styles.title} ml-1`}>{name}</div>
        <div className={`${styles.type} ml-1`}>
          {category}
          {' '}
          {productName}
        </div>
      </div>
      <div>
        <hr style={{ marginTop: 0 }} />
        <div className={`${styles.type} ml-1`}>{id}</div>
      </div>
    </div>
  </div>
);

AirLiveCard.propTypes = {
  productName: PropsTypes.string.isRequired,
  category: PropsTypes.string.isRequired,
  name: PropsTypes.string.isRequired,
  id: PropsTypes.string,
  bgColor: PropsTypes.string,
  cursor: PropsTypes.string,
};

AirLiveCard.defaultProps = {
  id: null,
  bgColor: 'white',
  cursor: 'pointer',
};

export default AirLiveCard;
