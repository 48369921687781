/* eslint-disable no-irregular-whitespace */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import LogRocket from 'logrocket';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './store/Apollo/ApolloFunctions';

import store from './store/redux/store';
import { ApolloProviderComponent } from './store/Apollo/ApolloContext';
import DashboardLayout from './layout/Dashboard';

(window as any).process = {};

// LogRocket.init('pkpgf3/smartlife-connect-admin');

// LogRocket.init('pkpgf3/smartlife-connect-admin');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// </React.StrictMode>,
// <React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
