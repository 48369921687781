/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import { Save } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert, Box, Grid, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './ProjectSelector.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ProjectCardComponent } from '../../../../components/shared/ProjectCard/ProjectCard';
import { searchProjectsGraphQL } from '../../../../services/location/location.service';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';
import { AppUtils } from '../../../../utility/appUtils';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { Project } from '../../../../store/redux/features/project/types';
import { RootState } from '../../../../store/redux/store';
import { MatProjectCard } from '../../../../components/shared/ProjectCard/MatProjectCard';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
  },
});

export const ProjectSelectorComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();

  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [uploading, setUploading] = useState<boolean>(false);
  const [resultCount, setResultCount] = useState(0);
  const [locationList, setLocationList] = useState([]);

  const [keyword, keywordSet] = useState('');
  const [order, orderSet] = useState('created_at DESC');
  const [limit, limitSet] = useState(4);
  const [skip, skipSet] = useState(0);
  const [id, idSet] = useState(-1);
  const [name, nameSet] = useState('');
  const [projectStatus, projectStatusSet] = useState(1);
  const [projectType, projectTypeSet] = useState(-1);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = useStyle();

  const projectState = useSelector<
    RootState,
    {
      [key: string]: Project;
    }
  >((state) => state.projects.projects);
  const projectsIds = useSelector<RootState, string[]>((state) => state.projects.project_ids);

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  /**
   * @deprecated
   */
  useEffect(() => {
    function fetchData() {
      searchProjectsGraphQL(
        userProfile?.apollo_client,
        keyword,
        limit,
        skip,
        projectStatus,
        projectType,
      )
        .then((res) => {
          setResultCount(res.projectSearch.result ? res.projectSearch.result.length : 0);
          setLocationList(res.projectSearch.result ? res.projectSearch.result : 0);
        })
        .catch((err) => {
          // alert("Something went wrong!")
        });
    }
    // fetchData();
  }, []);

  async function emptyProject() {
    await selectedProject.dispatch({
      selected_project: {
        id: null,
        title: null,
        description: null,
        image: null,
        project_status: null,
        project_type: null,
        access_level: null,
        restore_bsid: null,
      },
    });
  }

  useEffect(() => {
    emptyProject();
  }, [user]);

  const projectSelector = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    id: string,
    title: string,
    description: string,
    image: string,
    project_status: string,
    project_type: string,
    access_level: string,
    demo_mode: boolean,
    restore_bsid: string,
  ) => {
    await selectedProject.dispatch({
      selected_project: {
        id,
        title,
        description,
        image,
        project_status,
        project_type,
        access_level,
        demo_mode,
        restore_bsid,
      },
    });
    navigateRoute(`/app/project/dashboard?projectId=${id}`);
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>Recent Locations</Typography>
          <Box style={{ marginRight: '2%' }}>
            <DefaultButton
              disabled={loading}
              onClick={() => navigateRoute('/app/create-project')}
            >
              <AddIcon />
              {APP_TEXT.location.ADD_BTN}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Grid container spacing={2}>
              {projectsIds.map((id: any, index: any) => {
                if (index > 3) return;
                if (!projectState) return;
                const location = projectState[id];
                // eslint-disable-next-line consistent-return
                return (
                  <Grid
                    item
                    md={3}
                    xs={12}
                    style={{ marginBottom: '2%' }}
                    onClick={() => {
                      projectSelector(
                        location.id.toString(),
                        location.name,
                        location.description,
                        location.image,
                        location.access_level.toString(),
                        location.project_type.toString(),
                        location.project_status.toString(),
                        location.demo_mode,
                        location.restore_bsid,
                      );
                    }}
                  >
                    <MatProjectCard
                      title={location.name}
                      description={location.description}
                      img={AppUtils.getStorageBucketFileUrl('photo', location.image)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Link to="/app/all-projects" className="custom-link1">
              {APP_TEXT.location.ALL_LINK}
              {' '}
              -&gt;
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default ProjectSelectorComponent;
