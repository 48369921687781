/* eslint-disable camelcase */
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AirDevice,
  Check,
  DeviceCollectionSearchOutItem,
  LOGIC_CARD_VISIBILITY,
  OPERATORS,
  ProDevice,
} from '@smartlife-redux-state/common';
import { update_check } from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState } from '../../../../../../../store/redux/store';
import { LogicStepsTypes } from '../../../LogicStepper';
import { DeviceSelector } from '../shared/DeviceSelector';
import { ExpressionSelector } from '../shared/ExpressionSelector';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

const LocationCheckForm: React.FC<{check: Check}> = ({ check }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const locaction_container = useSelector(
    (state: RootState) => state.rule_engine.location_variable_container,
  );

  function onLocationVaribleContainer(dc: DeviceCollectionSearchOutItem) {
    dispatch(
      update_check({
        check: {
          ...check,
          dc,
        },
      }),
    );
  }

  function onLocationVariable(device: ProDevice | AirDevice) {
    try {
      const settings = device ? JSON.parse(device.settings) : null;
      const var_type = settings ? settings.item_type : null;

      let type = null;
      switch (var_type) {
        case 'numeric_variable':
          type = 'Number';
          break;
        case 'string_variable':
          type = 'String';
          break;
        case 'boolean_variable':
          type = 'Boolean';
          break;
        case null:
          break;
        default:
          throw new Error('Variable type not supported');
      }
      dispatch(
        update_check({
          check: {
            ...check,
            val: null,
            device: device || null,
            // since the location varibles only have numeric values, and state
            // set it when selecting the varible. No need to use <ExpressionVaribaleSelector />
            possible_variable_type: type,
            variable: 'state',
            meta: {
              ...check.meta,
              card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
            },
          },
        }),
      );
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  }

  function onExpressionChange(op: OPERATORS, value: any, variable: {field: string; type: string}) {
    console.log('on expresion selected', op, value, variable);
    dispatch(
      update_check({
        check: {
          ...check,
          op,
          val: value,
          // variable: variable.field,
          // possible_variable_type: variable.type
        },
      }),
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceSelector
          selectedDC={locaction_container}
          selectedDevice={check ? check.device : null}
          onDcSelect={onLocationVaribleContainer}
          onDeviceSelect={onLocationVariable}
          behaveAs="variables"
          logic_step={LogicStepsTypes.CHECK}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ExpressionSelector
          selectedOperator={check ? check.op : OPERATORS.EQUAL}
          selectedVariable={{
            field: check.variable,
            type: check.possible_variable_type,
          }}
          selecteValue={check.val}
          onChange={onExpressionChange}
          dc={locaction_container}
          device={check ? check.device : null}
        />
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const LocationCheck: React.FC<{check: Check}> = ({ check }) => {
  const classes = useStyles();
  const [dcs, setDcs] = useState<any[]>([]);
  const [devices, setDevices] = useState<any[]>([]);
  return (
    <Box>
      <LocationCheckForm check={check} />
    </Box>
  );
};
