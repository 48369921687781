/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useNavigate } from 'react-router';
import { MemberCardComponent } from 'src/components/shared/MemberCard/MemberCard';
import { getProjectMembersGraphQL } from 'src/services/member/member.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { APP_TEXT } from 'src/utility/AppTexts/DefaultAppText.utils';
import { AppUtils, getPermissionString } from 'src/utility/appUtils';
import { WidgetHeaderLayout } from './WidgetHeaderLayout';

const useStyle = makeStyles({
  root: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(2px)',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    width: '100%',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    // lineeight: 25px;
  },
  content_root: {
    width: '100%',
    // padding: '10%',
    // margin: 'auto 0',
    overflow: 'scroll !important',
  },
  graphText1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '89.0963px',
    lineHeight: '62px',
    /* or 70% */
    /* display: flex; */
    alignItems: 'center',
    letterSpacing: '-0.194456px',
    /* Primary/Gradient Green */
    color: 'var(--utility-dark-green)',
  },
  graphText2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '26.7289px',
    lineHeight: '42px',
    /* identical to box height, or 156% */

    /* Utility/Black */
    verticalAlign: 'top',
    color: 'var(--utility-dark-gray)',
    opacity: '0.5',
  },

  graphText3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18.3704px',
    lineHeight: '120%',
    /* or 22px */

    textAlign: 'center',

    /* Utility/Black */
    color: 'var(--utility-dark-gray)',
    opacity: 0.5,
  },
});
// eslint-disable-next-line import/prefer-default-export
export const DeviceStatus = () => {
  const styles = useStyle();

  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();

  const [memberList, setMemberList] = useState([]);
  const [hasError, setHasError] = useState(false);

  const [myAccessLevel, setMyAccessLevel] = useState(0);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const res = await getProjectMembersGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
        -1,
        -1,
      );

      if (res.projectListUsers.users) {
        const meOnProject = res.projectListUsers.users.find(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (user: any) => user.user_email === userProfile.user_profile.email,
        );
        if (meOnProject) {
          setMyAccessLevel(meOnProject.access_level);
        }
      }

      setMemberList(res.projectListUsers.users ? res.projectListUsers.users : []);
    } catch (err) {
      setHasError(true);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  return (
    <div className={styles.root}>
      <WidgetHeaderLayout>
        <Box className={styles.header}>
          <Typography className={styles.title}>Project Members</Typography>
          <Button onClick={() => navigateRoute(`/app/members?projectId=${selectedProject.selected_project.id}`)}>See All</Button>
        </Box>
      </WidgetHeaderLayout>
      <div className={clsx(styles.content_root, 'row')}>
        {memberList.slice(0, 3).map((member: any) => {
          let profileBgStyle: React.CSSProperties = {
            backgroundImage:
              "url('https://www.vhv.rs/dpng/d/256-2569650_men-profile-icon-png-image-free-download-searchpng.png')",
          };
          if (member.profile_photo) {
            profileBgStyle = {
              backgroundImage: `url("${AppUtils.getStorageBucketFileUrl(
                'photo',
                member.profile_photo,
              )}")`,
            };
          }
          return (
            <div
              className="col-md-12 mt-3"
              onClick={() => {
                navigateRoute(
                  `/app/member/update?projectId=${selectedProject.selected_project.id}&user_email=${member.user_email}&my_access=${myAccessLevel}`,
                );
              }}
              style={{ cursor: 'pointer' }}
              key={member.user_email}
            >
              <MemberCardComponent
                isMe={member.user_email === userProfile.user_profile.email}
                title={member.user_name ? member.user_name : member.user_email}
                permissionType={
                  member.permission_status === 1
                    ? `${getPermissionString(member.access_level)} - ${APP_TEXT.members.PENDING}`
                    : getPermissionString(member.access_level)
                }
                desciption={member.user_title ? member.user_title : 'N/A'}
                profileBgStyle={profileBgStyle}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
