import { createContext, useMemo, useReducer } from 'react';

const initialState = {
  lgc_rate: 100,
  uvi_rate: 100,
  disable_on_error: 'on',
  default_alexa_user: null,
  default_email: null,
  dcMap: {},
};

export const CloneSuccessRateContext = createContext({
  ...initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: (payload: any) => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'ALEXA_USER': {
      return {
        ...state,
        default_alexa_user: action.payload,
      };
    }
    case 'LOGIC_EMAIL': {
      return {
        ...state,
        default_email: action.payload,
      };
    }
    case 'DC_MAP_UPDATE': {
      return {
        ...state,
        dcMap: action.payload,
      }
    }
    default:
      return state;
  }
};

export const CloneSuccessRateProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const values = useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <CloneSuccessRateContext.Provider value={values as any}>
      {props.children}
    </CloneSuccessRateContext.Provider>
  );
};
