/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './AllProjects.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { ProjectCardComponent } from '../../../../components/shared/ProjectCard/ProjectCard';
import { searchProjectsGraphQL } from '../../../../services/location/location.service';
import ClearLeftIconButton from '../../../../components/shared/buttons/ClearLeftIconButton/ClearLeftIconButton';

import { appConsoleLog, AppUtils } from '../../../../utility/appUtils';
import { SearchBox } from '../../../../components/shared/SearchBox/SearchBox';
import { MatProjectCard } from '../../../../components/shared/ProjectCard/MatProjectCard';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';
import { DefaultButton } from '../../LogicsEngine/components/ui/DefaultButton';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const AllProjectsComponent: React.FunctionComponent<any> = () => {
  const user = useContext(AuthContext);

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [resultCount, setResultCount] = useState('');
  const [locationList, setLocationList] = useState([]);

  const [keyword, keywordSet] = useState('');
  const [order, orderSet] = useState('created_at DESC');
  const [limit, limitSet] = useState(100);
  const [skip, skipSet] = useState(0);
  const [id, idSet] = useState(-1);
  const [name, nameSet] = useState('');
  const [projectStatus, projectStatusSet] = useState(1);
  const [projectType, projectTypeSet] = useState(-1);

  const classes = useStyle();

  useEffect(() => {
    async function init() {
      try {
        await searchProjectsGraphQL(
          userProfile?.apollo_client,
          keyword,
          limit,
          skip,
          projectStatus,
          projectType,
        )
          .then((res) => {
            setResultCount(res.projectSearch.result ? res.projectSearch.result.length : 0);
            setLocationList(res.projectSearch.result ? res.projectSearch.result : 0);
          })
          .catch((err) => {
            // alert("Something went wrong!");
          });
      } catch (error) {
        appConsoleLog(error);
      }
    }
    init();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  async function emptyProject() {
    await selectedProject.dispatch({
      selected_project: {
        id: null,
        title: null,
        description: null,
        image: null,
        project_status: null,
        project_type: null,
        access_level: null,
        restore_bsid: null,
      },
    });
  }

  useEffect(() => {
    emptyProject();
  }, []);

  const onSearch = async (value: string) => {
    try {
      keywordSet(value);
      await searchProjectsGraphQL(
        userProfile?.apollo_client,
        keyword,
        limit,
        skip,
        projectStatus,
        projectType,
      )
        .then((res) => {
          setResultCount(res.projectSearch.result ? res.projectSearch.result.length : 0);
          setLocationList(res.projectSearch.result ? res.projectSearch.result : 0);
        })
        .catch((err) => {
          // alert("Something went wrong!");
        });
    } catch (error) {
      appConsoleLog(error);
    }
  };

  const projectSelector = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    id: string,
    title: string,
    description: string,
    image: string,
    project_status: string,
    project_type: string,
    access_level: string,
    demo_mode: boolean,
    restore_bsid: string,
  ) => {
    await selectedProject.dispatch({
      selected_project: {
        id,
        title,
        description,
        image,
        project_status,
        project_type,
        access_level,
        demo_mode,
        restore_bsid,
      },
    });
    navigateRoute(`/app/project/dashboard?projectId=${id}`);
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>Recent Locations</Typography>
          <Box style={{ marginRight: '2%' }}>
            <DefaultButton disabled={loading} onClick={() => navigateRoute('/app/create-project')}>
              <AddIcon />
              {APP_TEXT.location.ADD_BTN}
            </DefaultButton>
          </Box>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <Box>
          <SearchBox>
            <TextField
              id="input-with-icon-textfield"
              placeholder={APP_TEXT.location.LOCATION_SEARCH}
              onKeyUp={(event: any) => {
                onSearch(event.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </SearchBox>
        </Box>
        <Box>
          <Grid container spacing={1}>
            {locationList.map((location: any) => (
              <Grid
                item
                md={3}
                xs={12}
                onClick={() => {
                  projectSelector(
                    location.id,
                    location.name,
                    location.description,
                    location.image,
                    location.access_level,
                    location.project_type,
                    location.project_status,
                    location.demo_mode,
                    location.restore_bsid,
                  );
                }}
              >
                <MatProjectCard
                  title={location.name}
                  description={location.description}
                  img={AppUtils.getStorageBucketFileUrl('photo', location.image)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};
