import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ReactJson from 'react-json-view';
import { Component } from '../uvitem.config';

export const FieldMapComponent: React.FC<{
  selectedMap: Component;
  filledTagMap: any;
  setFilledTagMap: React.Dispatch<any>;
  settings: Record<
    string,
    {
      k: string;
      t: string;
      command?: string;
    }
  >;
}> = ({
  selectedMap, filledTagMap, settings, setFilledTagMap,
}) => (
  <Box style={{ border: '1px solid #EBF1F2', backgroundColor: 'white', padding: 10 }}>
    <Typography style={{ marginBottom: 5 }}>Channel Mapping</Typography>
    {selectedMap
      && Object.keys(selectedMap.ui_tags).map((tag) => (
        <Grid
          item
          md={12}
          style={{
            backgroundColor: '#EBF1F2', padding: 10, paddingTop: 20, marginBottom: 10, borderRadius: 5,
          }}
        >
          <FormControl size="small" style={{ width: '100%' }}>
            <InputLabel>{selectedMap.ui_tags[tag].description}</InputLabel>
            <Select
              label={selectedMap.ui_tags[tag].description}
              value={filledTagMap[tag]}
              onChange={(e: any) => {
                setFilledTagMap((tags) => ({ ...tags, [tag]: e.target.value }));
              }}
            >
              {Object.keys(settings).map((key) => {
                if (selectedMap.ui_tags[tag].can_send_command && !settings[key].command) return null;
                return (
                  <MenuItem key={key} value={key}>
                    {settings[key].k}
                    (
                    {settings[key].t}
                    )
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box height={10} />
          <ReactJson
            name="Field Configurations"
            collapsed
            enableClipboard={false}
            displayObjectSize={false}
            displayDataTypes={false}
            src={{
              display_suffix: selectedMap.ui_tags[tag].display_suffix ?? '',
              ...selectedMap.ui_tags[tag].config,
            }}
          />
        </Grid>
      ))}
  </Box>
);
