import React, { useEffect } from 'react';
import { CLOUD_INTEGRATIONS, CiDevice } from 'src/plugins/shared/services/cloud-integration.service';
import {
  FormControl, Grid, InputLabel, MenuItem, Select,
} from '@mui/material';
import { useCloudDeviceMap } from 'src/plugins/shared/hooks/useCloudDeviceMap';
import { useCloudDeviceMapFormData } from 'src/plugins/shared/hooks/useCloudDeviceMapFormData';
import { FieldMapComponent } from 'src/plugins/shared/react-components/FieldMapComponent';
import NotificationMap from 'src/plugins/shared/react-components/NotificationMap';
import { itemConfig } from '../item-config';
import { ExtraFieldMap } from '../../shared/react-components/ExtraFieldMapComponent';
import { iEnodeIntegration } from '../enode.service';
// import { Component } from '../item-config/uvitem.config';

const UserViewCreateExtraInternal: React.FC<{
  selectedDevice: CiDevice & {dc_type: string};
  uvItem: object;
  integration: iEnodeIntegration;
  appendSettings: (payload: any) => void;
}> = ({
  selectedDevice, uvItem, integration, appendSettings,
}) => {
  const { settings, itemMaps } = useCloudDeviceMap(
    selectedDevice,
    CLOUD_INTEGRATIONS.ENODE,
    itemConfig,
    (allConfig, selectedDevice) => {
      if (selectedDevice.device_type === 'inverter') {
        return allConfig.inverter;
      }
      if (selectedDevice.device_type === 'vehicle') {
        // TODO: confirm this against enode data payload
        return allConfig.vehicle;
      }
      if (selectedDevice.device_type === 'charger') {
        // TODO: confirm this against enode data payload
        return allConfig.charger;
      }
    },
  );

  const {
    selectedMap,
    selectedMapId,
    finalSettings,
    filledTagMap,
    notifications,
    filledExtraTagMap,
    setFilledExtraTagMap,
    setNotifications,
    setFilledTagMap,
    setSelectedMapId,
  } = useCloudDeviceMapFormData(itemMaps, settings, selectedDevice, uvItem);

  useEffect(() => {
    if (finalSettings) {
      appendSettings({
        data: finalSettings,
      });
    }
  }, [finalSettings]);

  return (
    <Grid container spacing={1} style={{ marginTop: 10 }}>
      <Grid item md={12} style={{ paddingLeft: 0 }}>
        <FormControl size="small" style={{ width: '100%' }}>
          <InputLabel>Select an item map</InputLabel>
          <Select
            value={selectedMapId}
            label="Select an item map"
            onChange={(e: any) => setSelectedMapId(e.target.value)}
          >
            {Object.keys(itemMaps).map((key) => (
              <MenuItem key={key} value={key}>
                {itemMaps[key].componentName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={12}>
        {selectedMap && (
          <FieldMapComponent
            selectedMap={selectedMap}
            filledTagMap={filledTagMap}
            setFilledTagMap={setFilledTagMap}
            settings={settings}
          />
        )}
      </Grid>
      <Grid item md={12}>
        {selectedMap && selectedMap.extra_ui_tags && (
          <ExtraFieldMap
            filledExtraTagMap={filledExtraTagMap}
            selectedMap={selectedMap}
            integration={integration}
            setFilledExtraTagMap={setFilledExtraTagMap}
          />
        )}
      </Grid>
      <Grid item md={12}>
        {selectedMap && (
          <NotificationMap
            notifications={notifications}
            setNotifications={setNotifications}
            settings={settings}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const UserViewCreateExtraFields: React.FC<{
  integration: iEnodeIntegration;
  selectedDevice: CiDevice & {dc_type: string};
  uvItem: object;
  appendSettings: (payload: any) => void;
}> = React.memo(({
  selectedDevice, uvItem, integration, appendSettings,
}) => (
  <UserViewCreateExtraInternal
    integration={integration}
    key={selectedDevice && selectedDevice.id}
    appendSettings={appendSettings}
    selectedDevice={selectedDevice}
    uvItem={uvItem}
  />
));
