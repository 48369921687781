/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 * @author Maduka Dilshan
 */
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import { debounce, orderBy as lodash_orderBy } from 'lodash';
import firebase from 'firebase';
import clsx from 'clsx';
import axios from 'axios';
import {
  useEffect, useState, useCallback, useContext,
} from 'react';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { LogsFilter } from './LogsFilter';

import { AuthContext } from '../../../../store/Auth/AuthContext';

const useStyles = makeStyles({
  root: {
    width: '94%',
    margin: '0 auto',
  },

  bradiusLeft: {
    borderTopLeftRadius: '5px',
    // borderBottomLeftRadius: "5px",
  },
  bradiusRight: {
    borderTopRightRadius: '5px',
    // borderBottomRightRadius: "5px",
  },
  head: {
    backgroundColor: '#7F8FA4',
    color: 'white',
  },
  body: {},
  srow: {
    fontFamily: 'monospace',
    '&:nth-of-type(even)': {
      backgroundColor: '#cdd5d6', // "#F9F9F9",
    },
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
  },
  collapse_inner: {
    width: '100%',
    verticalAlign: 'scroll',
  },
});

const Row: React.FC<any> = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const style = useStyles();

  const [img, setImg] = useState(null);
  useEffect(() => {
    let val = null;
    // eslint-disable-next-line array-callback-return
    Object.keys(row).map((key, index) => {
      if (key === 'image_url') {
        val = row[key];
      }
    });
    if (val) {
      setImg(val);
    }
  }, []);

  return (
    <>
      <TableRow key={row._id} className={style.srow}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{moment.unix(row.updated_at).format(' h:mm a dddd, Do MMM YYYY')}</TableCell>
        {/* <TableCell>{row.project_name || ' - '}</TableCell> */}
        <TableCell>{row.plain_message || ' - '}</TableCell>
        <TableCell>
          <Tooltip title={row.creator_name}>
            <Typography style={{ fontSize: 12.5 }}>
              {(row.creator_name as string).substring(0, 10)}
              {row.creator_name.length > 10 && '...'}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{row.creator_access_level}</TableCell>
        <TableCell align="right">{row.event_type}</TableCell>
      </TableRow>
      <TableRow className={style.srow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box padding={3} className={style.collapse_inner}>
              <Grid container spacing={3}>
                <Grid item sm={12} md={4}>
                  <Typography variant="h6" gutterBottom component="div">
                    Details
                  </Typography>
                  <ul>
                    {Object.keys(row).map((key, index) => {
                      const prop = row[key];
                      if (typeof prop === 'object' && !Array.isArray(prop)) return <></>;

                      if (Array.isArray(prop) && typeof prop[0] === 'object') return <></>;

                      if (key === 'image_url') {
                        return <></>;
                      }

                      return (
                        <li>
                          <span style={{ fontWeight: 'bold' }}>{key}</span>
                          {' '}
                          -
                          {prop.toString()}
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
                <Grid item sm={12} md={8}>
                  <Typography variant="h6" gutterBottom component="div">
                    Thread Messages
                  </Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={clsx(style.head, style.bradiusLeft)}>
                          Created At
                        </TableCell>
                        <TableCell className={style.head}>Creator_ID</TableCell>
                        <TableCell className={style.head}>Creator_UID</TableCell>
                        <TableCell className={clsx(style.head, style.bradiusRight)}>
                          Message
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.thread_messages
                      && Array.isArray(row.thread_messages)
                      && row.thread_messages.length > 0 ? (
                          row.thread_messages.map((msg) => (
                            <TableRow key={msg._id} className={style.srow}>
                              <TableCell>
                                {moment.unix(msg.created_at).format(' h:mm a dddd, MMMM Do YYYY')}
                              </TableCell>
                              <TableCell>{msg.creator_id || ' - '}</TableCell>
                              <TableCell>{msg.creator_uid || ' - '}</TableCell>
                              <TableCell>{msg.message || ' - '}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow className={style.srow}>
                            <TableCell colSpan={4}>No Data</TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                  {img && (
                    <Box mb={2} mt={2}>
                      <Typography variant="h6" gutterBottom component="div">
                        Image
                      </Typography>
                      <img
                        src={img}
                        alt="ML"
                        style={{
                          width: 'auto',
                          height: '100px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setOpenDialog(true)}
                      />
                      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                        <DialogTitle>Image Preview</DialogTitle>
                        <DialogContent>
                          <img src={img} alt="ml" style={{ width: 'auto', height: '50vh' }} />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setOpenDialog(false)}>cancel</Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const LogsTable: React.FC<{initial_uv_id: string; project_id: string}> = ({
  initial_uv_id,
  project_id,
}) => {
  const style = useStyles();
  const user = useContext(AuthContext);

  const [uvs, setUvs] = useState([]);
  const [excluedEventTypes, setExcludeEventTypes] = useState([]);
  const [notifiList, setNotifiList] = useState([]);
  const [selectedMinDate, setSeletedMinState] = useState(-1);
  const [selectedMaxDate, setSeletedMaxState] = useState(-1);

  const [t_total, setTTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [t_pageSize, setTPageSize] = useState(10);

  const [logs, setLogs] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedLog, setSelectedLog] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const init = async () => {
    const res = await firebase.database().ref(`chats/projects/${project_id}`).once('value');
  };

  const debouncedFetchLogs = useCallback(
    debounce((limit: number, skip: number, token: string) => fetchLogs(limit, skip, token), 50),
    [uvs, excluedEventTypes, notifiList, selectedMinDate, selectedMaxDate],
  );

  useEffect(() => {
    const ref = firebase.database().ref(`chats/projects/${project_id}`);
    const listner = ref.on('value', (snapshot) => {
      const { token } = user.access_token;
      debouncedFetchLogs(t_pageSize, page * t_pageSize, token);
      // if (uvs.length === 0) {
      //   debouncedFetchLogs(t_pageSize, page * t_pageSize, token);
      //   return;
      // }
      // let found = false;
      // // eslint-disable-next-line no-restricted-syntax
      // for (const key of Object.keys(snapshot.val().userviews)) {
      //   if (uvs.indexOf(Number(key)) > -1) {
      //     found = true;
      //   }
      // }
      // if (found) debouncedFetchLogs(t_pageSize, page * t_pageSize, token);
    });
    return () => ref.off('value', listner);

    // init();
  }, [t_pageSize, page, user, debouncedFetchLogs]);

  useEffect(() => {
    const { token } = user.access_token;
    if (!token) return;

    fetchLogs(t_pageSize, page * t_pageSize, token);
  }, [
    user.access_token.token,
    page,
    t_pageSize,
    uvs,
    excluedEventTypes,
    notifiList,
    selectedMaxDate,
    selectedMinDate,
  ]);

  const fetchLogs = async (limit: number, skip: number, token: string) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/event-log/v1/chat/message/list`,
        {
          project_ids: [project_id], // 70,
          userview_ids: uvs, // [147, 138],
          skip_event_types: excluedEventTypes,
          notification_levels: notifiList,
          timestamp_start: selectedMinDate,
          timestamp_end: selectedMaxDate,
          limit,
          skip,
        },
        {
          headers: {
            authorization: token,
          },
        },
      );
      if (res.data.data.result) {
        if (res.data.data.result.length > 0) {
          const data = [...res.data.data.result] || [];
          const sorted = lodash_orderBy(data, 'updated_at', 'desc');
          setLogs(sorted);
        } else {
          setLogs([]);
        }
      }
      // eslint-disable-next-line max-len
      if (res.data.data.resultCount !== null || res.data.data.resultCount !== undefined) setTTotal(res.data.data.resultCount);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setTPageSize(parseInt(event.target.value, 10));
  };

  const onFilterChange = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    uvs: number[],
    min_date: any,
    max_date: any,
    exclude_types: string[] = [],
    notifi_types: number[] = [],
  ) => {
    if (max_date === -1 && min_date !== -1) {
      enqueueSnackbar('Please select a max date', { variant: 'error' });
      return;
    }

    if (min_date === -1 && max_date !== -1) {
      enqueueSnackbar('Please select a min date', { variant: 'error' });
      return;
    }
    console.log('xcxcxc', min_date, max_date);
    setUvs(uvs);
    setExcludeEventTypes(exclude_types);
    setNotifiList(notifi_types);

    if (min_date && min_date !== -1) {
      const unix = moment(min_date).unix();
      setSeletedMinState(unix);
    } else {
      setSeletedMinState(-1);
    }

    if (max_date && max_date !== -1) {
      const unix = moment(max_date).unix();
      setSeletedMaxState(unix);
    } else {
      setSeletedMaxState(-1);
    }
  };

  return (
    <div className={style.root}>
      <LogsFilter onFilterChange={onFilterChange} />
      <Box mt={3}>
        <Box>
          {isLoading ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(style.head, style.bradiusLeft)}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={style.head}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell className={clsx(style.head, style.bradiusRight)} align="right">
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <TableRow key={item} className={style.srow}>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="rectangular" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={clsx(style.head, style.bradiusLeft)} />
                  <TableCell className={style.head}>Last Updated</TableCell>
                  {/* <TableCell className={style.head}>Project</TableCell> */}
                  <TableCell className={style.head} style={{ flexGrow: 1 }}>
                    Log Message
                  </TableCell>
                  <TableCell className={style.head} style={{ width: '10px' }}>
                    Creator
                  </TableCell>
                  <TableCell className={style.head}>Creator Level</TableCell>
                  <TableCell className={clsx(style.head, style.bradiusRight)} align="right">
                    Event Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => (
                  <Row row={log} key={log._id} />
                ))}
              </TableBody>
            </Table>
          )}
          <TablePagination
            component="div"
            count={t_total}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={t_pageSize}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </Box>
      </Box>
    </div>
  );
};

export default LogsTable;
