/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import Editor from '@monaco-editor/react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { RootState } from 'src/store/redux/store';
import dedent from 'dedent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const VarItem: React.FC<{item: any; disableEditor?: boolean; onRemove?: () => void}> = ({
  item,
  disableEditor,
  onRemove,
}) => {
  const firebase_path = item ? item.firebasePath : null;
  useFirebaseConnect(item ? firebase_path : null);
  const firebase_value = useSelector((state: RootState) => {
    try {
      const patharray = firebase_path
        .split('/')
        .filter((parthPart) => parthPart !== '' && parthPart !== ' ');
      if (patharray.length === 5) {
        return state.firebase.data[patharray[0]][patharray[1]][patharray[2]][patharray[3]][
          patharray[4]
        ];
      }
      return null;
    } catch (err) {
      return null;
    }
  });

  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        padding: '2%',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <IconButton size="small" onClick={onRemove}>
        <RemoveCircleIcon />
      </IconButton>
      <Typography variant="subtitle2">{`${item.name} (${firebase_value})`}</Typography>
      {firebase_path && (
        <Tooltip title="Copy shadow in path">
          <IconButton
            onClick={() => {
              window.prompt('Copy ShadowIn path to clipboard: Ctrl+C, Enter', firebase_path);
            }}
          >
            <ContentCopyIcon style={{ fontSize: '12px' }} />
          </IconButton>
        </Tooltip>
      )}
      {!disableEditor && (
        <div style={{ display: 'none' }}>
          <Editor
            theme="vs-dark"
            height="0"
            defaultLanguage="javascript"
            value={dedent`
            /** 
             * Last Value ${item.static_value ? item.static_value : firebase_value}
             * @type {${item.static_value ? typeof item.static_value : typeof firebase_value}}
            */
            let ${item.name} = ${item.static_value ? item.static_value : firebase_value};
          `}
          />
        </div>
      )}
    </div>
  );
};
