import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { USERVIEW_NOTIFICATION_TYPES } from 'src/config/deviceConfig/Values';

const SingleNotificationMap: React.FC<{
  value: string;
  channel: string;
  onChangeChannel: (value: string) => void;
  onChangeValue: (value: string) => void;
  removeFromList: () => void;
  settings: Record<
    string,
    {
      k: string;
      t: string;
      command?: string;
      used?: boolean; // front end only flag use to disable on select element
    }
  >;
}> = ({
  value, channel, settings, onChangeChannel, onChangeValue, removeFromList,
}) => (
  <Grid container spacing={1} style={{ marginBottom: 10 }}>
    <Grid item md={6} sm={12}>
      <FormControl size="small" style={{ width: '100%' }}>
        <InputLabel>Channel</InputLabel>
        <Select label="Channel" value={channel} onChange={(e) => onChangeChannel(e.target.value)}>
          {Object.keys(settings).map((key) => (
            <MenuItem disabled={settings[key].used} key={key} value={settings[key].k}>
              {settings[key].k}
              (
              {settings[key].t}
              )
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item md={5} sm={12}>
      <FormControl size="small" style={{ width: '100%' }}>
        <InputLabel>Notification</InputLabel>
        <Select label="Notification" value={value} onChange={(e) => onChangeValue(e.target.value)}>
          {USERVIEW_NOTIFICATION_TYPES.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item md={1} sm={12}>
      <IconButton onClick={removeFromList}>
        <CloseIcon />
      </IconButton>
    </Grid>
  </Grid>
);

const NotificationMap: React.FC<{
  settings: Record<
    string,
    {
      k: string;
      t: string;
      command?: string;
    }
  >;
  notifications: {
    value: any;
    channel: any;
    id: string;
  }[];
  setNotifications: React.Dispatch<
    React.SetStateAction<
      {
        value: any;
        channel: any;
        id: string;
      }[]
    >
  >;
}> = ({ settings, notifications, setNotifications }) => {

  // We just filter the settings to add a extra fields 'used' which use to enable disable menuitems
  const filteredSettings = useMemo(() => {
    const modified: Record<
      string,
      {
        k: string;
        t: string;
        command?: string;
        used?: boolean; // front end only flag use to disable on select element
      }
    > = {};
    Object.keys(settings).map((key) => {
      const found = notifications.find((item) => item.channel === settings[key].k);

      if (found) {
        modified[key] = { ...settings[key], used: true };
      } else {
        modified[key] = { ...settings[key] };
      }
    });

    return modified;
  }, [settings, notifications]);

  return (
    <Box style={{ border: '1px solid #EBF1F2', backgroundColor: 'white', padding: 10 }}>
      <Typography style={{marginBottom: 10}}>Notification Mapping</Typography>
      {notifications.map((notification) => (
        <SingleNotificationMap
          value={notification.value}
          channel={notification.channel}
          settings={filteredSettings}
          onChangeValue={(val) => {
            setNotifications((prevState) => {
              const modified = prevState.map((item) => {
                if (item.id === notification.id) {
                  return { ...item, value: val };
                }
                return item;
              });
              return modified;
            });
          }}
          onChangeChannel={(channel) => {
            setNotifications((prevState) => {
              const modified = prevState.map((item) => {
                if (item.id === notification.id) {
                  return { ...item, channel };
                }
                return item;
              });
              return modified;
            });
          }}
          removeFromList={() => {
            const filtered = notifications.filter(item => item.id != notification.id)
            setNotifications(filtered);
          }}
        />
      ))}
      <Button
        onClick={() => {
          setNotifications((prevState) => [
            ...prevState,
            {
              value: null,
              channel: null,
              id: uuid(),
            },
          ]);
        }}
      >
        Link To Notification +
        {' '}
      </Button>
    </Box>
  );
};

export default NotificationMap;
