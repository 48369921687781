/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, TextField,
} from '@mui/material';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FORM_DEBOUNCE_TIME } from '../../../../../config/deviceConfig/Values';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IDFField } from '../../../../../config/deviceConfig/types';
import { IFormResetRef, FormResetAction } from '../../../ProDeviceCreateEdit/types';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.timeout_interval;

type ComponentProps = {
  fieldConfig: IDFField;
  validateForm: number;
  // eslint-disable-next-line react/no-unused-prop-types
  deviceInfo: any;
  resetForm: IFormResetRef;
  onChange: (payload: any, isInitializing?: boolean) => void;
};
interface IformData {
  value: number;
  timeout_interval_type: string;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldTimeoutInterval: React.FunctionComponent<ComponentProps> = (props) => {
  const [deb, setDeb] = useState<
    BehaviorSubject<{
      values: IformData;
      errors: any;
      fields: string[];
    } | null>
  >();

  const [fields, setFields] = useState([]);
  const [labels, setLabels] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSubmitForm = (formData: IformData) => {};
  const sendDefaults = (b: BehaviorSubject<any>) => {
    if (!props.fieldConfig || !b) {
      return;
    }
    const valSep = props.fieldConfig.default.split(':');

    b.next({
      values: {
        value: +valSep[1],
        timeout_interval_type: valSep[0],
      },
      errors: {},
      fields,
    });
  };
  /**
   * Resetting or changing form data from parent
   */
  const refFormReset = useRef<IFormResetRef>({
    ref: 0,
    action: '',
    info: {},
  });
  useEffect(() => {
    if (
      formRef.current
      && props.resetForm.ref > 0
      && props.resetForm.ref > refFormReset.current.ref
    ) {
      refFormReset.current = props.resetForm;
      if (props.resetForm.action === FormResetAction.toCurrentInfo) {
        formRef.current.setValues({
          value: props.resetForm.info.settings[props.resetForm.info.settings.timeout_interval_type],
          timeout_interval_type: props.resetForm.info.settings.timeout_interval_type,
        });
      } else {
        formRef.current.resetForm();
      }
    }
  }, [props.resetForm]);
  const initilized = useRef(0);
  useEffect(() => {
    setFields(props.fieldConfig.field.split('|'));
    setLabels(props.fieldConfig.labels.split('|'));

    const b = new BehaviorSubject<{
      values: IformData;
      errors: any;
      fields: string[];
    } | null>(null);
    setDeb(b);
    const sub = b
      .pipe(
        debounceTime(FORM_DEBOUNCE_TIME),
        map((v) => JSON.stringify(v)),
        distinctUntilChanged(),
        map((v) => JSON.parse(v)),
      )
      .subscribe((v: {values: IformData; errors: any; fields: string[]} | null) => {
        // eslint-disable-next-line max-len
        if (v == null || props.fieldConfig == null || !v.values.timeout_interval_type) {
          const er = {
            timeout_interval_type: 'Please select Timeout or Pulse duration',
          };
          if (initilized.current === 0) {
            props.onChange(
              {
                action: 'data',
                form: FIELD_NAME,
                data: {},
                errors: er,
              },
              true,
            );
            initilized.current = 1;
          } else {
            props.onChange({
              action: 'data',
              form: FIELD_NAME,
              data: {},
              errors: er,
            });
          }
          return;
        }

        const { values, errors } = v;
        const infoData: any = {
          settings: {},
        };

        fields.forEach((f) => {
          infoData.settings[f] = 'null';
        });

        infoData.settings[values.timeout_interval_type] = +values.value;
        infoData.settings.timeout_interval_type = values.timeout_interval_type;

        if (initilized.current === 0) {
          props.onChange(
            {
              action: 'data',
              form: FIELD_NAME,
              data: infoData,
              errors,
            },
            true,
          );
          initilized.current = 1;
        } else {
          props.onChange({
            action: 'data',
            form: FIELD_NAME,
            data: infoData,
            errors,
          });
        }
      });

    // send once default data
    sendDefaults(b);
    return () => {
      sub.unsubscribe();
    };
  }, [props.fieldConfig]);

  const formRef = useRef<any>();
  useEffect(() => {
    if (formRef && formRef.current !== undefined && props.validateForm !== 0) {
      formRef.current.submitForm();
    }
  }, [props.validateForm]);
  const isEditable = props.fieldConfig != null && props.fieldConfig.editable === true;

  const defaultValues = props.fieldConfig.default.split(':');
  return isEditable === true ? (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={{
          value: +defaultValues[1],
          timeout_interval_type: defaultValues[0],
        }}
        validationSchema={Yup.object().shape({
          value: Yup.number().required('Delay value required'),
          timeout_interval_type: Yup.string().required('Delay type required'),
        })}
        validateOnChange
        onSubmit={onSubmitForm}
      >
        {({
          errors, handleBlur, handleChange, touched, values, submitForm,
        }) => {
          deb?.next({
            values,
            errors,
            fields,
          });
          return (
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="timeout_interval_type"
                        name="timeout_interval_type"
                        value={values.timeout_interval_type || fields[0]}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        {fields.map((v: any, i: number) => (
                          <FormControlLabel
                            // eslint-disable-next-line react/no-array-index-key
                            key={v + i + Date.now()}
                            control={<Radio color="primary" value={v} />}
                            label={labels[fields.indexOf(v)]}
                            labelPlacement="start"
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <TextField
                    className="dynamic-ui-form-control "
                    error={Boolean(touched.value && errors.value)}
                    helperText={touched.value && errors.value}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values.value}
                    name="value"
                    label={`${values.timeout_interval_type} value (Milliseconds)`}
                    size="small"
                    variant="outlined"
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  ) : (
    <div />
  );
};
