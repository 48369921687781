/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useState } from 'react';
import AlarmIcon from '@mui/icons-material/Alarm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Formik } from 'formik';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FolderIcon from '@mui/icons-material/Folder';
import { Tooltip } from '@mui/material';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../Alters/Snackbar/SnackbarOptions';
import { UVContext, UVReducerTypes } from '../../UVContext';
import uvService from '../../service/uv.service';
import { IUserViewItem } from '../../types/types';
import { UVConfirmDialog } from '../Dialogs/UVConfirmDialog/UVConfirmDialog';
import { appConsoleError, appConsoleLog } from '../../../../utility/appUtils';
import styles from './UVFolderItem.module.css';

type ComponentProps = {
  userviewItem: IUserViewItem;
  // eslint-disable-next-line react/require-default-props
  onClick?: () => void;
  onEdit: (item: IUserViewItem) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const UVFolderItem: React.FunctionComponent<ComponentProps> = ({
  userviewItem,
  onClick,
  onEdit,
}) => {
  const snackbar = useSnackbar();

  const userProfile = useContext(ApolloAuthContext);
  const userViewContext = useContext(UVContext);

  const [deleteState, setDeleteState] = useState<boolean>(false);

  const onDeleteFolder = async () => {
    try {
      if (userviewItem === undefined || !userviewItem.id) {
        throw new Error('Folder id not valid');
      }
      await uvService.userviewFolderDelete(userProfile.apollo_client, userviewItem.id);
      snackbar.enqueueSnackbar('Folder deleted', {
        key: 'uv-folder-delete-success',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      userViewContext.dispatch({
        type: UVReducerTypes.CHANGE,
        payload: {
          updated: Date.now(),
        },
      });
    } catch (err) {
      appConsoleError(err);

      snackbar.enqueueSnackbar('Cannot delete folder', {
        key: 'uv-folder-create-error',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuAction?: string) => {
    setAnchorEl(null);
    if (menuAction === 'delete') {
      setDeleteState(true);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.innerRoot} style={{ backgroundColor: userviewItem.item_color }}>
        <div className={styles.moreIconHolder}>
          <div style={{ paddingTop: '10px', paddingLeft: '10px', display: 'flex' }}>
            <div>
              <FolderIcon style={{ color: '#048696' }} />
            </div>
            {userviewItem.item_visibility !== 1 && (
              <Tooltip title="Visibility of this item is hidden">
                <VisibilityOffIcon />
              </Tooltip>
            )}
          </div>
          <IconButton
            className={styles.moreIconButton}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleClose('')}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem
            key="edit"
            onClick={() => {
              handleClose('');
              onEdit(userviewItem);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem key="delete" onClick={() => handleClose('delete')}>
            Delete
          </MenuItem>
        </Menu>
        <div
          className={styles.iconContainer}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <div>
            <span className={`calc calc-icon-uv-primary ${userviewItem.icon_name}`} />
          </div>
        </div>
        <div>
          <UVConfirmDialog
            dialogOpen={deleteState}
            dialogCloseEvent={(e: any) => {
              setDeleteState(false);
              if (e === true) {
                onDeleteFolder();
              }
            }}
            title="Confirm to delete?"
            text="it will delete folder and folder items"
          />
        </div>
        <div
          className={styles.data}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <div className={styles.title}>{userviewItem.display_name}</div>
        </div>

        <div>
          <div className={styles.folderActions}>
            {/* <IconButton */}
            {/*  onClick={() => { */}
            {/*    onEdit(userviewItem); */}
            {/*  }} */}
            {/* > */}
            {/*  <EditIcon fontSize="large" /> */}
            {/* </IconButton> */}
            {/* <IconButton */}
            {/*  onClick={() => { */}
            {/*    setDeleteState(true); */}
            {/*    appConsoleLog("deleteState", deleteState); */}
            {/*  }} */}
            {/* > */}
            {/*  <DeleteIcon fontSize="large" /> */}
            {/* </IconButton> */}
          </div>
        </div>
      </div>
    </div>
  );
};
