/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import React, { useEffect, useState } from 'react';
import { cloneDeep, keys } from 'lodash';
import { Action } from '@smartlife-redux-state/common';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { update_action } from 'src/store/redux/features/logic-engine';
import { RootState, useSelector } from '../../../../../../../../store/redux/store';
import { ITEM_DEFAULTS } from '../../../../../../../../config/deviceConfig/item_defaults';
import { Board } from '../../../../board';
import { Card } from '../../../../board/Card';
import { Group } from '../../../../board/Group';
import { FormContainer } from '../../FormContainer';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  child_head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  child_root: {
    // backgroundColor: '#d5e3e5 !important',
    margin: '0 !important',
    padding: '0 !important',
    width: '100%',
  },
});

enum AUDIO_DISTRIBUTER_STATES {
  VOLUME = 'volume',
  MUTE = 'mute',
  POWER = 'pwr',
  SOURCE = 'src',
  TUNE = 'tune',
  FREQ = 'freq',
}

function AudioDistributerValueSelector(
  state: AUDIO_DISTRIBUTER_STATES,
  row: any,
  setVariableState: any,
  index,
  sources: any[] = [],
) {
  const classes = useStyles();
  switch (state) {
    case AUDIO_DISTRIBUTER_STATES.VOLUME:
    case AUDIO_DISTRIBUTER_STATES.TUNE:
    case AUDIO_DISTRIBUTER_STATES.FREQ:
      return (
        <TextField
          id={`delay_${Math.random()}`}
          type="number"
          className={classes.formControl}
          label={`${state} state`}
          value={row.state_value}
          onChange={(e: any) => {
            setVariableState(index, e.target.value);
          }}
          variant="outlined"
          size="small"
        />
      );
    case AUDIO_DISTRIBUTER_STATES.MUTE:
      return (
        <FormControl size="small" className={classes.formControl} variant="outlined">
          <InputLabel>{`${state} state`}</InputLabel>
          <Select
            label={`${state} state`}
            value={row.state_value}
            onChange={(e) => setVariableState(index, e.target.value)}
          >
            <MenuItem value="00">UNMUTE</MenuItem>
            <MenuItem value="01">MUTE</MenuItem>
          </Select>
        </FormControl>
      );
    case AUDIO_DISTRIBUTER_STATES.POWER:
      return (
        <FormControl size="small" className={classes.formControl} variant="outlined">
          <InputLabel>{`${state} state`}</InputLabel>
          <Select
            label={`${state} state`}
            value={row.state_value}
            onChange={(e) => setVariableState(index, e.target.value)}
          >
            <MenuItem value="01">ON</MenuItem>
            <MenuItem value="00">OFF</MenuItem>
          </Select>
        </FormControl>
      );
    case AUDIO_DISTRIBUTER_STATES.SOURCE:
      return (
        <FormControl size="small" className={classes.formControl} variant="outlined">
          <InputLabel>{`${state} state`}</InputLabel>
          <Select
            value={row.state_value}
            label={`${state} state`}
            onChange={(e) => setVariableState(index, e.target.value)}
          >
            {sources.map((src, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem value={src.value} key={i}>
                {src.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    default:
      return <></>;
  }
}

const ChildRow = ({
  row,
  index,
  zones,
  sources,
  variables,
  setKeyHandler,
  setDelayHandler,
  setKeyValueHandler,
  setZoneHandler,
  deleteChild,
  classes,
  isLast,
}: any) => (
  <Grid
    container
    spacing={2}
    key={row.index}
    style={{
      marginTop: '5px',
      padding: '5px',
    }}
  >
    <Grid item xs={12} md={3}>
      <Autocomplete
        size="small"
        onChange={(_, value) => {
          setZoneHandler(index, value || null);
        }}
        value={row.zone}
        className={classes.formControl}
        id={`zone_${row.index}`}
        options={zones}
        getOptionLabel={(option: any) => option.label}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="Zone" variant="outlined" />}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <Autocomplete
        size="small"
        onChange={(_, value) => {
          setKeyHandler(index, value || null);
        }}
        value={row.state}
        className={classes.formControl}
        id={`remote_Key_${row.index}`}
        options={variables}
        getOptionLabel={(option: string) => option}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="Remote Key" variant="outlined" />}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      {AudioDistributerValueSelector(row.state, row, setKeyValueHandler, index, sources)}
    </Grid>
    <Grid item xs={12} md={2}>
      {!isLast && (
        <TextField
          id={`delay_${row.index}`}
          type="number"
          error={row.delay !== 0 && !row.delay}
          className={classes.formControl}
          label="Delay(sec)"
          value={row.delay}
          onChange={(e: any) => {
            setDelayHandler(index, e.target.value);
          }}
          variant="outlined"
          size="small"
          // disabled={isLast}
        />
      )}
    </Grid>
    <Grid item xs={12} md={1}>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={() => deleteChild(index)} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
);

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const AudioDistributerActions: React.FC<{
  onChange: any;
  action: Action;
  isChild?: boolean;
  parentAction?: Action;
}> = ({
  onChange, action, isChild, parentAction,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [zones, setZones] = useState<any[]>([]);
  const [sources, setSources] = useState<any[]>([]);
  const [variables, setVaribles] = useState<any[]>([]);

  const [child, setChild] = useState<any[]>(
    action.variable && isJson(action.variable) ? JSON.parse(action.variable) : [],
  );
  // console.log(remoteKeyMap);

  const AddNewChild = () => {
    const newArray = [
      ...child,
      {
        delay: 1,
        index: Date.now(),
      },
    ];
    setChild(newArray);
  };

  const deleteChild = (index) => {
    const newArray = [...child];
    newArray.splice(index, 1);
    setChild(newArray);
  };

  const setZoneHandler = (index, zone) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      zone,
    };
    setChild(newArray);
  };

  const setKeyHandler = (index, key) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state: key,
    };
    setChild(newArray);
  };

  // eslint-disable-next-line camelcase
  const setKeyValueHandler = (index, key_value) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      state_value: key_value,
    };
    setChild(newArray);
  };

  const setDelayHandler = (index, val) => {
    const newArray = [...child];
    const data = newArray[index];
    newArray[index] = {
      ...data,
      delay: parseInt(val, 10) < 1 ? 0 : parseInt(val, 10),
    };
    setChild(newArray);
  };

  useEffect(() => {
    const { device } = action;
    if (!device || !device.settings) return;

    const device_settings = JSON.parse(device.settings);
    if (device_settings) {
      const { zones } = device_settings;
      const { sources } = device_settings;

      setZones(zones || []);
      setSources(sources || []);

      // eslint-disable-next-line max-len
      const variables = ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows.ac.children[device.sub_cat].features
        .device_extra_fields.commands.zone;
      setVaribles(variables || []);
    }
  }, [action]);

  useEffect(() => {
    if (child) {
      let value;
      if (child.length === 0) {
        value = JSON.stringify([]);
      } else {
        value = JSON.stringify([...child]);
        // value = JSON.stringify(
        //   child.map((row) => ({
        //     remote_button: row.remote_button && row.remote_button.key,
        //     delay: row.delay,
        //   }))
        // );
        console.log('AC_CONTROLLERS', '', { field: value, type: 'ac' }, child);
        onChange('AC_CONTROLLERS', '', { field: value, type: 'ac' });
      }
      // onChange("IR_REMOTE", "", { field: value, type: "ir_remote" });
    }
  }, [child]);

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const actionCpy = cloneDeep(action);
    const variables = JSON.parse(actionCpy.variable);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const temp = variables[sourceIndex];
    variables[sourceIndex] = variables[destinationIndex];
    variables[destinationIndex] = temp;
    actionCpy.variable = JSON.stringify(variables);

    dispatch(
      update_action({
        action: actionCpy,
        isChild,
        parentAction,
      }),
    );

    setChild(variables);
  };

  return (
    <Grid
      container
      style={{
        border: '1px solid #D9DFE0',
        padding: '10px',
        borderRadius: '10px',
        marginTop: '10px',
      }}
    >
      <Box className={classes.child_head}>
        <Typography>Distributer Actions</Typography>
        <IconButton onClick={() => AddNewChild()} size="large">
          <AddIcon />
        </IconButton>
      </Box>

      <Board onDragEnd={onDragEnd}>
        <Group droppableId="AVChildList">
          {() => (
            <>
              {child
                && child.map((row, index) => (
                  <Card draggableId={`AVChildRow__${index}`} index={index}>
                    {({ provided, snapshot }) => (
                      <FormContainer
                        dragHandlerProps={provided.dragHandleProps}
                        className={classes.child_root}
                      >
                        <ChildRow
                          row={row}
                          index={index}
                          zones={zones}
                          variables={variables}
                          sources={sources}
                          setKeyHandler={setKeyHandler}
                          setDelayHandler={setDelayHandler}
                          setKeyValueHandler={setKeyValueHandler}
                          setZoneHandler={setZoneHandler}
                          deleteChild={deleteChild}
                          classes={classes}
                          isLast={child.length === index + 1}
                        />
                      </FormContainer>
                    )}
                  </Card>
                ))}
            </>
          )}
        </Group>
      </Board>

      {/* {child
        && child.map((row, index) => (
          <ChildRow
            row={row}
            index={index}
            zones={zones}
            variables={variables}
            sources={sources}
            setKeyHandler={setKeyHandler}
            setDelayHandler={setDelayHandler}
            setKeyValueHandler={setKeyValueHandler}
            setZoneHandler={setZoneHandler}
            deleteChild={deleteChild}
            classes={classes}
            isLast={child.length === index + 1}
          />
        ))} */}
    </Grid>
  );
};

AudioDistributerActions.defaultProps = {
  isChild: false,
  parentAction: null,
};

export default AudioDistributerActions;
