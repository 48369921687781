/* eslint-disable prefer-destructuring */
import qs from 'query-string';

const getQueryStringValue = (key: string, queryString = window.location.search) => {
  try {
    const values = qs.parse(queryString);
    return Object.keys(values).length > 0 ? values[key] : null;
  } catch (error) {
    return null;
  }
};

function useQueryNumber(key: string): number {
  let res: any = getQueryStringValue(key);
  if (res) {
    if (res.forEach) {
      res = res[0];
    }
    res = +res;
    return res;
  }
  return -1;
}

function useQueryStringFirst(key: string) {
  let res: any = getQueryStringValue(key);
  if (res) {
    if (res.forEach) {
      res = res[0];
    }
    return res;
  }
  return '';
}
export { useQueryNumber, useQueryStringFirst };
