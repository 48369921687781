import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FiFileText } from 'react-icons/fi';
import moment from 'moment';
import clsx from 'clsx';
import { WidgetHeaderLayout } from './WidgetHeaderLayout';
import { DeviceContainerDashboardListItem } from '../../../../../../components/shared/DeviceContainerDashboardListItem/DeviceContainerDashboardListItem';
import airIcon from '../../../../../../assets/images/icons/dc-air.png';
import hubIcon from '../../../../../../assets/images/icons/dc-hub.png';
import { DeviceContainerDashboardLogs } from '../../../../../../components/shared/DeviceContainerDashboardLogs/DeviceContainerDashboardLogs';

const useStyles = makeStyles({
  root: {
    background: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(2px)',
    borderRadius: '10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    width: '100%',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    // lineeight: 25px;
  },
  content_root: {
    // display: "flex",
    // justifyContent: "flex-start",
    // width: "100%",
    // flex: 1,
    width: '100%',
    padding: '10px',
  },
});
type ComponentProps = {
  dcList: any;
};
// eslint-disable-next-line import/prefer-default-export
export const LoggerComponent: React.FC<ComponentProps> = ({ dcList = [] }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <WidgetHeaderLayout>
        <Box className={styles.header}>
          <Typography className={styles.title}>
            <FiFileText />
            {' '}
            Logs
          </Typography>
        </Box>
      </WidgetHeaderLayout>
      <div className={clsx(styles.content_root)}>
        {dcList.map((dc: any, index: number) => (
          <div key={dc.id}>
            <DeviceContainerDashboardLogs
              title={dc.name ? dc.name : 'N/A'}
              date={moment.unix(dc.updated_at / 1000).format(' h:mm a dddd, MMMM Do YYYY')}
            />
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};
