import { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ApolloAuthContext } from '../store/Apollo/ApolloContext';
import { AuthContext } from '../store/Auth/AuthContext';

const GuestGuard: React.FC<{children: React.ReactChild}> = ({ children }) => {
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const userProfile = useContext(ApolloAuthContext);

  console.log('GUEST GARUD');

  if (user && userProfile && userProfile.user_profile && userProfile.user_profile.user_name) {
    return <Navigate to="/app" />;
  }

  //   if (
  //     userProfile.user_profile != null &&
  //     userProfile.user_profile.user_name != null
  //   ) {
  //     return <Redirect to="/app" />;
  //   }

  return <>{children}</>;
};

export default GuestGuard;
