export function parseCustomApiError(error: any) {
  if (error && error.message) {
    return error.message.replace('>', '');
  }
  if (error && error.error && error.error.message) {
    return error.error.message.replace('>', '');
  }
  return 'Something Went Wrong';
}

export default parseCustomApiError;
