/* eslint-disable camelcase */
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Skeleton,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import {
  useContext, useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Action,
  NotificationActionPayload,
  NotificationAction as NotificationActionTypes,
} from '@smartlife-redux-state/common';
import { cloneDeep } from 'lodash';
import { USERVIEW_NOTIFICATION_TYPES } from '../../../../../../../config/deviceConfig/Values';
import { userviewSearchGraphQL } from '../../../../../../../services/user-view/user-view.service';
import { ApolloAuthContext } from '../../../../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../../../../store/Project/ProjectContext';
import { update_action } from '../../../../../../../store/redux/features/logic-engine/slice';
import { AddVariable } from './shared/AddVariable';
import { VarItem } from './shared/VarItem';

const useStyle = makeStyles({
  form_root: {
    width: '100%',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const NotificationAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const actionCpy: NotificationActionTypes = action as any;
  const classes = useStyle();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [allUvs, setAllUvs] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [uvIds, setUvIds] = useState([]);
  const [notifi_type, setNotifiType] = useState('1');
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');

  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);

  const items = useMemo<
    Record<
      string,
      {
        path: string;
        deviceId: string;
      }
    >
  >(() => action.variable_value ?? {}, [action]);

  useEffect(() => {
    dispatch(
      update_action({
        action: {
          ...action,
          payload: {
            ...action.payload,
            title,
            plain_message: desc,
            userview_ids: uvIds,
            notification_level: notifi_type,
          } as any,
        },
        isChild,
        parentAction,
      }),
    );
  }, [uvIds, notifi_type, title, desc]);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await userviewSearchGraphQL(
          userProfile.apollo_client,
          selectedProject.selected_project.id,
          1,
          '',
          100,
          0,
        );
        if (!res.userviewSearch) throw new Error('Failed to get user views');
        setAllUvs(res.userviewSearch.result);
        if (actionCpy.payload) {
          setNotifiType(actionCpy.payload.notification_level);
          setTitle(actionCpy.payload.title);
          setDesc(actionCpy.payload.plain_message);
          setUvIds(actionCpy.payload.userview_ids);
        }
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedProject
      && selectedProject.selected_project
      && selectedProject.selected_project.id
    ) {
      init();
    } else {
      // TODO: Close the dialog or move backward
    }
  }, []);

  const handleUVChange = (event) => {
    setUvIds(event.target.value);
  };

  function handleRemoveVariable(key) {
    const filtered = cloneDeep(action.variable_value);
    delete filtered[key];
    dispatch(update_action({
      action: {
        ...action,
        variable_value: filtered,
      },
      isChild,
      parentAction,
    }));
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <FormHelperText style={{ color: '#1976D2' }}>
          * Notification type actions may not execute in the defined order.
        </FormHelperText>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={1}>
          {Object.keys(items).map((varKey) => (
            <Grid
              item
              md={3}
              sm={4}
              xs={6}
                          // eslint-disable-next-line react/no-array-index-key
              key={varKey}
            >
              <VarItem
                item={items[varKey]}
                key={varKey}
                disableEditor
                onRemove={() => {
                  handleRemoveVariable(varKey);
                }}
              />
            </Grid>
          ))}
          <Grid item md={2}>
            <AddVariable
              bypassValidation
              action={action}
              isChild={isChild}
              parentAction={parentAction}
              onAddNewVariable={({ varName, firebasePath, device }) => {
                dispatch(
                  update_action({
                    action: {
                      ...action,
                      variable_value: {
                        ...action.variable_value,
                        [varName]: {
                          firebasePath,
                          name: varName,
                          deviceId: device.id,
                        },
                      },
                    },
                    isChild,
                    parentAction,
                  }),
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <FormControl className={classes.form_root}>
          <TextField
            label="Title"
            variant="outlined"
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item md={12}>
        <FormControl className={classes.form_root}>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Message"
            minRows={3}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item md={6}>
        {allUvs && (
          <FormControl className={classes.form_root} variant="outlined" size="small">
            <InputLabel>User Views</InputLabel>
            <Select
              multiple
              value={uvIds}
              label="User Views"
              onChange={handleUVChange}
              // input={<Input />}
              variant="outlined"
              disableUnderline
              renderValue={(selected: any) => {
                const arr = [];
                selected.map((selectedone) => {
                  const uv = allUvs.find((item) => item.id === selectedone);
                  if (uv) {
                    arr.push(uv.name);
                  }
                  return null;
                });
                // const uv: any = allUvs.find((item) => item.id == selected);
                return arr.join(', ');
              }}
            >
              {isLoading ? (
                <Skeleton variant="rectangular" height={50} />
              ) : (
                allUvs.map((uv) => (
                  <MenuItem key={uv.id} value={uv.id}>
                    <Checkbox checked={uvIds && uvIds.indexOf(uv.id) > -1} />
                    <ListItemText secondary={uv.name} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item md={6}>
        <FormControl size="small" variant="outlined" className={classes.form_root}>
          <InputLabel id="1custom_selector-label1">Notification Type</InputLabel>
          <Select
            labelId="1custom_selector-label1"
            value={notifi_type}
            name="item_notification_type"
            onChange={(e: any) => {
              console.log(e);
              setNotifiType(e.target.value);
            }}
            label="Notification Type"
          >
            {USERVIEW_NOTIFICATION_TYPES.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
