import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { useSnackbar } from 'notistack';
import { isArray } from 'lodash';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import { airDCSearchGraphQL, airDeviceSearch } from 'src/services/air-device/air-device.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import airDeviceService from 'src/containers/app/AirDeviceCreateEdit/services/air-device.service';
import { IAirDevice } from 'src/containers/app/AirDeviceCreateEdit/types';
import {
  ChannelInEnergyGroup,
  CiDevice,
  CiDeviceContainer,
  EnergyGroup,
  EnergyGroupResponse,
} from '../../services/cloud-integration.service';

const useStyles = makeStyles({
  cardRoot: {
    backgroundColor: 'white',
    height: '100%',
    padding: 10,
    borderRadius: 5,
    cursor: 'pointer',
  },
  dialog: {
    width: 700,
  },
  newCardRoot: {
    border: '1px dotted gray',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 80,
  },
  formCtrl: {
    width: '100%',
  },
  formHelperTxt: {
    color: 'red',
  },
});

const EnergyMonitoringGroupNewChannel: React.FC<{
  groupId: string;
  dcs: CiDeviceContainer[];
  airDcs: any[];
  channelsInGroup: ChannelInEnergyGroup[];
  refetchGroup: () => void;
}> = ({
  groupId, dcs = [], airDcs = [], channelsInGroup, refetchGroup,
}) => {
  const projectId = useQueryStringFirst('projectId');
  const userProfile = useContext(ApolloAuthContext);
  const [selectedDc, setSelectedDc] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<string>();
  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [devices, setDevices] = useState<(CiDevice | IAirDevice)[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const channels = useMemo(() => {
    const device = devices.find((device: any) => device.id === selectedDevice);

    // TODO: This Ad HOC code should removed once backend Air module moved into the cloud integration
    // The if block is just to make existing air module works with energy monitoring
    if (device && (device as IAirDevice).airdc_id) {
      const airDevice = device as IAirDevice;
      const { settings } = airDevice;

      let deviceSettings = settings;

      if (typeof deviceSettings === 'string') {
        try {
          deviceSettings = JSON.parse(settings);

          // There's an issue with the backend where in some cases it serialized the payload twice. Need to handle that case
          if (typeof deviceSettings === 'string') {
            deviceSettings = JSON.parse(deviceSettings);
          }
        } catch (err) {
          enqueueSnackbar('Failed to parse device settings', { variant: 'error' });
        }
      }

      // TODO: get the channels from air device info api
      const channels = deviceSettings.selected_functions;
      const fns = [];

      if (isArray(Object.keys(channels))) {
        Object.keys(channels).map((channelKey) => {
          const channel = channels[channelKey];
          const isUsed = channelsInGroup.find((item) => item.state_field === channel.code);
          fns.push({
            channel_type: 'elec_total', // just so that this will full fill the requirment that only "elec_total" channels can added to monitor energy.
            disabled: isUsed,
            k: channel.code,
            t: null,
          });
        });
      }

      return fns;
    }
    if (device) {
      const { settings } = device;
      const sfs = settings.sf;

      if (sfs) {
        const fns = [];
        Object.keys(sfs).map((key) => {
          const fn = sfs[key];
          if (fn.channel_type === 'elec_total') {
            const isUsed = channelsInGroup.find((item) => item.state_field === fn.k);
            fn.disabled = isUsed;

            fns.push(fn);
          }
        });

        return fns;
      }
    }
    return [];
  }, [selectedDevice]);

  useEffect(() => {
    const init = async () => {
      try {
        // TODO: This Ad HOC code should removed once backend Air module moved into the cloud integration
        // The if block is just to make existing air module works with energy monitoring
        if (selectedDc.startsWith('air__')) {
          const res = await airDeviceSearch(
            userProfile?.apollo_client,
            projectId,
            selectedDc.split('air__')[1],
            [],
            '',
            100,
            0,
          );

          if (isArray(res?.result)) {
            setDevices(res.result);
          }
        } else {
          const res = await pluginHook.commonIntegrationService.getCloudDeviceCollectionDevices(
            selectedDc,
          );
          if (isArray(res?.result?.devices)) {
            setDevices(res.result.devices);
          }
        }
      } catch (err) {}
    };
    if (selectedDc && selectedDc.length > 1) {
      init();
    }
  }, [selectedDc]);

  const onAdd = async () => {
    try {
      const res = await pluginHook.commonIntegrationService.addChannelToEnergyGroup({
        group_id: groupId,
        device_id: selectedDevice,
        state_field: selectedChannel,
      });
      enqueueSnackbar('Channel Added', { variant: 'success' });
      refetchGroup();
    } catch (err) {
      enqueueSnackbar('Failed to add channel', { variant: 'error' });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={3} sm={3}>
        <FormControl size="small" style={{ width: '100%' }}>
          <InputLabel>Device Container</InputLabel>
          <Select
            label="Device Container"
            value={selectedDc}
            onChange={(e) => setSelectedDc(e.target.value)}
          >
            {dcs.map((dc) => (
              <MenuItem key={dc.id} value={dc.id}>
                {dc.name}
              </MenuItem>
            ))}
            {airDcs.map((dc) => (
              <MenuItem key={dc.id} value={`air__${dc.id}`}>
                {dc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={3} sm={3}>
        <FormControl size="small" style={{ width: '100%' }}>
          <InputLabel>Devices</InputLabel>
          <Select
            label="Devices"
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value)}
          >
            {devices.map((device) => (
              <MenuItem key={device.id} value={device.id}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={4} sm={4}>
        <FormControl size="small" style={{ width: '100%' }}>
          <InputLabel>Channel</InputLabel>
          <Select
            label="Channel"
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e.target.value)}
          >
            {channels.map((channel) => (
              <MenuItem disabled={channel.disabled} key={channel.k} value={channel.k}>
                {channel.k}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} sm={2}>
        <Button onClick={onAdd} variant="outlined">
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

const EnergyMonitoringGroupChennelSelector: React.FC<{
  group: EnergyGroupResponse;
  refetchGroup: () => void;
}> = ({ group, refetchGroup }) => {
  const projectId = useQueryStringFirst('projectId');
  const [dcs, setDcs] = useState([]);
  const [airDcs, setAirDcs] = useState([]);
  const [devices, setDevices] = useState();
  const [resetKey, setResetKey] = useState(Date.now());

  const userProfile = useContext(ApolloAuthContext);

  useEffect(() => {
    const init = async () => {
      try {
        const res = await pluginHook.commonIntegrationService.getCloudDeviceCollections({
          project_id: projectId,
          limit: 100,
          skip: 0,
        });
        if (isArray(res?.result?.result)) {
          setDcs(res.result.result);
        }

        const airDcs = await airDCSearchGraphQL(
          userProfile?.apollo_client,
          projectId,
          -1,
          -1,
          '',
          100,
          0,
        );
        console.log('****', airDcs);
        if (isArray(airDcs?.airDCSearch?.result)) {
          setAirDcs(airDcs?.airDCSearch?.result);
        }
      } catch (err) {}
    };
    if (projectId) {
      init();
    }
  }, [projectId]);

  const deleteChannelInGroup = async (deviceId: string, stateField: string) => {
    try {
      const res = await pluginHook.commonIntegrationService.removeChannelFromEnergyGroup({
        group_id: group.result.group._id,
        device_id: deviceId,
        state_field: stateField,
      });
      refetchGroup();
      setResetKey(Date.now());
    } catch (err) {}
  };

  if (!group) return null;

  return (
    <Box style={{ width: '100%', marginTop: 20 }}>
      <Typography variant="subtitle2">Channels Configuration</Typography>
      <Grid container spacing={1}>
        {isArray(group?.result?.channels)
          && group.result.channels.map((channel) => (
            <Grid item md={6} key={channel.state_field}>
              <Paper
                style={{
                  padding: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Typography variant="subtitle2">
                  {channel.name}
                  {' '}
                  /
                  {channel.state_field}
                </Typography>
                <IconButton
                  onClick={() => deleteChannelInGroup(channel.device_id, channel.state_field)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Paper>
            </Grid>
          ))}
      </Grid>
      <Box height={20} />
      <EnergyMonitoringGroupNewChannel
        key={resetKey}
        groupId={group?.result?.group?._id}
        channelsInGroup={group?.result?.channels}
        dcs={dcs}
        airDcs={airDcs}
        refetchGroup={refetchGroup}
      />
    </Box>
  );
};

const EnergyMonitoringGroupConfig: React.FC<{
  open: boolean;
  selectedGroupId: string | null;
  onOpenChange: (val: boolean) => void;
  refetchGroupList: () => void;
}> = ({
  open, selectedGroupId, onOpenChange, refetchGroupList,
}) => {
  const styles = useStyles();
  const projectId = useQueryStringFirst('projectId');
  const [working, setWorking] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [group, setGroup] = useState<EnergyGroupResponse>();

  const formInitials = useMemo(() => {
    if (group && group.result && group.result.group) {
      return {
        name: group.result.group.name,
        description: group.result.group.description,
        project_id: group.result.group.project_id,
        group_type: group.result.group.group_type,
      };
    }
    return {
      name: '',
      description: '',
      project_id: projectId,
      group_type: '',
    };
  }, [group]);

  const getGroup = useCallback(async () => {
    try {
      if (!(selectedGroupId && selectedGroupId.length > 1)) return;
      const res = await pluginHook.commonIntegrationService.getEnergyGroup(selectedGroupId);
      setGroup(res);
    } catch (err) {
      enqueueSnackbar('Failed to get energy group', { variant: 'error' });
    }
  }, [selectedGroupId]);

  const deleteGroup = async (id: string) => {
    try {
      await pluginHook.commonIntegrationService.removeEnergyGroup(id);
      refetchGroupList();
      onOpenChange(false);
      enqueueSnackbar('Group Deleted', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Failed to delete the group', { variant: 'error' });
    }
  };

  useEffect(() => {
    getGroup();
  }, [getGroup]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitials,
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Must have at least 3 characters.')
        .required('*Group name is required.'),
      project_id: Yup.string().required('Project ID is required'),
      group_type: Yup.string()
        .oneOf(['dg', 'sg'], 'Invalid Group Type')
        .required('*Group Type is required.'),
    }),
    onSubmit: async (values) => {
      try {
        setWorking(true);
        const res = await pluginHook.commonIntegrationService.createEnergyGroup({
          ...(values as any),
        });
        refetchGroupList();
        onOpenChange(false);
      } catch (err) {
        enqueueSnackbar('Failed to create energy group', { variant: 'error' });
      } finally {
        setWorking(false);
      }
    },
  });

  return (
    <Dialog open={open} classes={{ paper: styles.dialog }}>
      <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">Configure Group</Typography>
        {group?.result?.group?._id && (
          <Tooltip title="Delete Group">
            <IconButton
              style={{ color: 'red' }}
              onClick={() => deleteGroup(group?.result?.group?._id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        )}
      </DialogTitle>
      <DialogContent>
        <Box height={5} />
        <Grid container spacing={1}>
          <Grid item md={8}>
            <form>
              <FormControl size="small" className={styles.formCtrl}>
                <TextField
                  disabled={!!group}
                  size="small"
                  error={!!(formik.touched.name && formik.errors.name)}
                  label="Group Name"
                  {...formik.getFieldProps('name')}
                />
                <FormHelperText className={styles.formHelperTxt}>
                  {formik.errors.name}
                </FormHelperText>
              </FormControl>
              <Box height={5} />
              <FormControl size="small" className={styles.formCtrl}>
                <TextField
                  disabled={!!group}
                  size="small"
                  label="Description"
                  {...formik.getFieldProps('description')}
                />
              </FormControl>
              <Box height={5} />
              <FormControl size="small" className={styles.formCtrl}>
                <InputLabel>Group Type</InputLabel>
                <Select
                  disabled={!!group}
                  error={!!(formik.touched.group_type && formik.errors.group_type)}
                  label="Group Type"
                  {...formik.getFieldProps('group_type')}
                >
                  <MenuItem value="dg">Location</MenuItem>
                  <MenuItem value="sg">Sub Group</MenuItem>
                </Select>
                <FormHelperText className={styles.formHelperTxt}>
                  {formik.errors.group_type}
                </FormHelperText>
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <Box>
          <Box>
            <EnergyMonitoringGroupChennelSelector group={group} refetchGroup={() => getGroup()} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenChange(false)}>Cancel</Button>
        {group ? (
          <Button onClick={() => onOpenChange(false)}>Done</Button>
        ) : (
          <Button disabled={working} onClick={() => formik.submitForm()}>
            {working ? 'Creating....' : 'Save'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const GroupCard: React.FC<{onClick: () => void; group: EnergyGroup}> = ({ onClick, group }) => {
  const styles = useStyles();
  return (
    <Box onClick={onClick} className={styles.cardRoot}>
      <Typography variant="h6">{group?.name}</Typography>
      <Typography variant="subtitle2">
        {group?.group_type == 'dg' ? 'Default group' : 'Sub group'}
      </Typography>
      <Typography>
        Last Updated:
        {' '}
        {moment.unix(group?.updated_at).format('YYYY-MM-DD:hh:mm:ss')}
      </Typography>
    </Box>
  );
};

const NewGroupCard: React.FC<{onClick: () => void}> = ({ onClick }) => {
  const styles = useStyles();
  return (
    <Box onClick={onClick} className={styles.newCardRoot}>
      <Typography>Add New Group</Typography>
      <AddIcon />
    </Box>
  );
};

export const EnergyMonitoringGroups = () => {
  const projectId = useQueryStringFirst('projectId');
  const [show, setShow] = useState(false);
  const [selectedGroupId, setSelectGroupId] = useState<string>();
  const [groups, setGroups] = useState<EnergyGroup[]>([]);

  const init = useCallback(async () => {
    try {
      const res = await pluginHook.commonIntegrationService.listEnergyGroups(projectId);
      if (res && isArray(res.result)) {
        setGroups(res.result);
      }
    } catch (err) {}
  }, [projectId]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <EnergyMonitoringGroupConfig
        open={show}
        key={selectedGroupId}
        selectedGroupId={selectedGroupId}
        onOpenChange={(val) => setShow(val)}
        refetchGroupList={() => init()}
      />
      <Grid container spacing={1}>
        {groups.map((group) => (
          <Grid item md={3} sm={6} xs={12}>
            <GroupCard
              group={group}
              onClick={() => {
                setSelectGroupId(group._id);
                setShow(true);
              }}
            />
          </Grid>
        ))}

        <Grid item md={3} sm={6} xs={12}>
          <NewGroupCard
            onClick={() => {
              setSelectGroupId(null);
              setShow(true);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
