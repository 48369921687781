import { isArray } from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import Chart from 'chart.js/auto';
import { Bar, Chart as MultiChart } from 'react-chartjs-2';
import { useMemo } from 'react';
import moment from 'moment';
import { usePopulatedGraphData } from '../../hooks/usePopulatedGraphData';

ChartJS.register(Filler);

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  require('chartjs-adapter-moment'),
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Energy Usage',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        displayFormats: {
          quarter: 'HH:mm',
        },
      },
    },
  },
};

export const LocationEnergyMonitoringGraph: React.FC<{
  rawData: {
    x: number;
    y: {
      [range: string]: {
        cost: number;
        value: number;
        values?: {
          // for graph api 6
          a: number;
          b: number;
        };
      };
    };
  }[];
  diffMode?: boolean;
  diffLabels?: {
    aPeak: string;
    aOffPeak: string;
    bPeak: string;
    bOffPeak: string;
  };
  resolution: 'hour' | 'day' | 'week';
  startUnix: moment.Moment;
  endUnix: moment.Moment;
}> = ({
  rawData, resolution, diffMode, diffLabels, startUnix, endUnix
}) => {

  const defaultYFill = diffMode ? {
    Peak: {
      value: 0,
      cost: 0,
      costs: {
        a: 0,
        b: 0
      },
      values: {
        a: 0,
        b: 0
      }
    },
    OffPeak: {
      value: 0,
      cost: 0,
      costs: {
        a: 0,
        b: 0
      },
      values: {
        a: 0,
        b: 0
      }
    },
    TOTAL: {
      value: 0,
      cost: 0,
      costs: {
        a: 0,
        b: 0
      },
      values: {
        a: 0,
        b: 0
      }
    },
  }: {
    Peak: {
      value: 0,
      cost: 0,
    },
    OffPeak: {
      value: 0,
      cost: 0,
    },
    TOTAL: {
      value: 0,
      cost: 0,
    },
  }

  const populatedRawData = usePopulatedGraphData(rawData, resolution, defaultYFill, startUnix, endUnix);

  const tableData = useMemo(() => {
    if (!isArray(populatedRawData) || populatedRawData.length === 0) return null;

    const labels = [];
    const datasets: {
      label: string,
      type: string,
      data: any[],
      [key: string]: any
    }[] = [
      {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        label: 'OffPeak',
        type: 'bar' as const,
        data: [],
        backgroundColor: '#4b9090',
      },
      {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        label: 'Peak',
        data: [],
        type: 'bar' as const,
        backgroundColor: '#b09e45',
      },
    ];

    if (diffMode) {
      datasets.push({
        label: diffLabels ? diffLabels.aPeak : 'Group A Peak',
        data: [],
        type: 'line' as const,
        fill: true,
        pointRadius: 0,
        backgroundColor: '#fff2b1',
      });

      datasets.push({
        label: diffLabels ? diffLabels.bPeak : 'Group B Peak',
        data: [],
        type: 'line' as const,
        fill: true,
        pointRadius: 0,
        backgroundColor: '#fff8d8',
      });

      datasets.push({
        label: diffLabels ? diffLabels.aOffPeak : 'Group A Off Peak',
        data: [],
        type: 'line' as const,
        fill: true,
        pointRadius: 0,
        backgroundColor: '#c2ffff',
      });

      datasets.push({
        label: diffLabels ? diffLabels.bOffPeak : 'Group B Off Peak',
        data: [],
        type: 'line' as const,
        fill: true,
        pointRadius: 0,
        backgroundColor: '#e1ffff',
      });
    }

    populatedRawData.map((data) => {
      let label = `${data.x}`;
      try {
        label = moment.unix(data.x).format('YY-MM-DD HH:mm');
      } catch {}
      labels.push(label);

      if (!data.y) {
        datasets[0].data.push(0);
        datasets[1].data.push(0);
      }

      let isFoundOffPeak = false;
      let isFoundPeak = false;

      Object.keys(data.y).map((rangeKey) => {
        if (rangeKey === 'OffPeak') {
          datasets[0].data.push(data.y[rangeKey].value);

          if (diffMode && data.y[rangeKey].values) {
            datasets[4].data.push(data.y[rangeKey].values.a);
            datasets[5].data.push(data.y[rangeKey].values.b);
          }
          
          isFoundOffPeak = true;
        }

        if (rangeKey === 'Peak') {
          datasets[1].data.push(data.y[rangeKey].value);

          if (diffMode && data.y[rangeKey].values) {
            datasets[2].data.push(data.y[rangeKey].values.a);
            datasets[3].data.push(data.y[rangeKey].values.b);
          }

          isFoundPeak = true;
        }
      });

      if (!isFoundOffPeak) {
        datasets[0].data.push(0);
        datasets[4] && datasets[4].data.push(0);
        datasets[5] && datasets[5].data.push(0);
      }
      if (!isFoundPeak) {
        datasets[1].data.push(0);
        datasets[2] && datasets[2].data.push(0);
        datasets[3] && datasets[3].data.push(0);
      }
    });

    return {
      labels,
      datasets,
    };
  }, [populatedRawData]);

  if (tableData) {
    return (
      <MultiChart<any>
        type="bar"
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top' as const,
            },
            title: {
              display: true,
              text: diffMode ? "Energy Comparison" : 'Energy Usage',
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: resolution,
                displayFormats: {
                  hour: 'hh:mm',
                  day: 'MMM DD',
                  week: "[Week] wo",
                },
                parser: 'YY-MM-DD HH:mm',
              },
            },
          },
        }}
        data={tableData}
      />
    );
  }

  return null;
};
