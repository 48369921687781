/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { CommonState, Notification } from './types';

const initial_state: CommonState = {
  notification: [],
};

export const commonStateSlice = createSlice({
  name: 'common-state',
  initialState: initial_state,
  reducers: {
    enqueueSnackbar: (state, action: {payload: Notification}) => {
      state.notification.push(action.payload);
    },
    closeSnackbar: (state, action: {payload: {key: number}}) => {
      // eslint-disable-next-line max-len
      state.notification = state.notification.map((notifi) => (!action.payload.key || notifi.options.key === action.payload.key
        ? { ...notifi, dismissed: true }
        : { ...notifi }));
    },
    removeSnackbar: (state, action: {payload: {key: number}}) => {
      state.notification = state.notification.filter(
        (notifi) => notifi.options.key !== action.payload.key,
      );
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = commonStateSlice.actions;

export const { reducer } = commonStateSlice;
