/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  IconButton,
  InputAdornment,
  TextField,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
  FormControl,
  Theme,
  Box,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './CreateEditUserView.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { addProjectMemeberGraphQL } from '../../../../services/member/member.service';

import { LoadingComponent } from '../../../../components/shared/Loading/Loading';
import GreenButton from '../../../../components/shared/buttons/AuthButton/GreenButton';

import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import {
  userviewCreateGraphQL,
  userviewGetGraphQL,
  userviewUpdateGraphQL,
} from '../../../../services/user-view/user-view.service';
import { appConsoleLog } from '../../../../utility/appUtils';
import { UVIconSelector } from '../../../../components/UserViewComponents/UIItems/UVIconSelector/UVIconSelector';
import useQueryString from '../../../../utility/CustomHooks/useQueryString';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';

type IUserVIewFormValue = {
  user_view_name: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '2%',
    overflowY: 'scroll',
  },
}));

export const CreateEditUserViewComponent: React.FunctionComponent<any> = () => {
  const uvId = useQueryString('uv_id') as string;
  const [isUpdateView, setUpdateView] = useState(false);
  const userProfile = useContext(ApolloAuthContext);
  const selectedProject = useContext(ProjectContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedIcon, setSelectedIcon] = useState('calc-door2');
  const [selectedColor, setSelectedColor] = useState('#FFFFFF');

  const [selectedUserViews, setSelectedUserView] = useState([0] as any);

  const [formval, setFormVal] = useState({
    user_view_name: '',
  });

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  useEffect(() => {
    if (
      !selectedProject
      || !selectedProject.selected_project
      || !selectedProject.selected_project.id
    ) {
      navigateRoute('/app');
    }

    if (uvId && uvId !== '') {
      setUpdateView(true);
      init(uvId);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const init = async (uvId: string) => {
    const res = await userviewGetGraphQL(userProfile.apollo_client, uvId);
    if (res.userviewGet) {
      const { name, icon_name, item_color } = res.userviewGet.userview;
      setFormVal({
        user_view_name: name,
      });
      setSelectedIcon(icon_name);
      setSelectedColor(item_color);
    }
  };

  const userViewSelect = (userViewId: any) => {
    const index = selectedUserViews.indexOf(userViewId, 0);
    if (index > -1) {
      selectedUserViews.splice(index, 1);
    } else {
      selectedUserViews.push(userViewId);
    }
  };

  const onSubmitForm = async (
    values: IUserVIewFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    setLoading(true);
    try {
      if (!selectedProject.selected_project.id) {
        return;
      }

      let res;
      if (isUpdateView) {
        res = await userviewUpdateGraphQL(
          userProfile?.apollo_client,
          uvId,
          values.user_view_name.trim(),
          '',
          1,
          1,
          selectedIcon,
          selectedColor,
        );
      } else {
        res = await userviewCreateGraphQL(
          userProfile?.apollo_client,
          selectedProject.selected_project.id,
          values.user_view_name.trim(),
          '',
          1,
          1,
          selectedIcon,
          selectedColor,
        );
      }

      setLoading(false);
      snackbar.enqueueSnackbar(
        isUpdateView
          ? APP_TEXT.user_view.USER_VIEW_EDIT_SUCCESS
          : APP_TEXT.user_view.USER_VIEW_CREATE_SUCCESS,
        {
          key: 'key1',
          ...SuccessSnackbarOptions,
          action: SuccessSnackbarActions(snackbar.closeSnackbar),
        },
      );
      resetForm();
      setSelectedUserView([]);
      navigateRoute(`/app/user-views?projectId=${selectedProject.selected_project.id}`);
    } catch (err) {
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.user_view.USER_VIEW_CREATE_ERROR, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  return (
    <Layout>
      <PageHeader>
        <Box className={classes.headerRoot}>
          <Typography className={classes.headerTitle}>
            {isUpdateView ? APP_TEXT.user_view.UPDATE_USER_VIEW : APP_TEXT.user_view.ADD_USER_VIEW}
          </Typography>
        </Box>
      </PageHeader>
      <Box className={classes.contentRoot}>
        <div className="custom-card2 col-md-12 pb-5">
          <div className="custom-card2-container">
            <div className="custom-card2-in ">
              <div className="custom-card2-title">
                <h2>{APP_TEXT.user_view.USER_VIEW_DETAILS}</h2>
                <hr />
              </div>
              <Formik
                onSubmit={onSubmitForm}
                initialValues={formval}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  user_view_name: Yup.string().trim().required('Please enter the user view name.'),
                })}
              >
                {({
                  errors, handleBlur, handleChange, touched, values, submitForm,
                }) => (
                  <form>
                    <div className="custom-card2-body mt-2">
                      <div className="m-0 col-md-12 nopaddingOnly row">
                        <div className="col-md-12 nopaddingOnly">
                          <div className="col-md-6 nopaddingOnly">
                            <div>
                              <TextField
                                className={styles.fieldSpace}
                                error={Boolean(touched.user_view_name && errors.user_view_name)}
                                helperText={touched.user_view_name && errors.user_view_name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.user_view_name}
                                name="user_view_name"
                                label={APP_TEXT.user_view.FORM_FIELD_USER_VIEW_NAME}
                                size="medium"
                                type="text"
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton edge="end" size="large" />
                                    </InputAdornment>
                                  ),
                                }}
                                data-testid=""
                              />
                            </div>
                            <UVIconSelector
                              initialIcon={selectedIcon}
                              initialColor={selectedColor}
                              onChange={(icon, color) => {
                                setSelectedIcon(icon);
                                setSelectedColor(color);
                              }}
                            />
                          </div>
                          <div className="col-md-6  " />
                        </div>
                      </div>
                    </div>

                    <div className="custom-card2-body">
                      <div
                        className="row"
                        style={{
                          justifyContent: 'flex-end',
                          marginRight: '1px',
                        }}
                      >
                        <div className="col-md-3 nopaddingOnly">
                          <GreenButton
                            text={
                              isUpdateView
                                ? APP_TEXT.user_view.UPDATE_USER_VIEW_BTN
                                : APP_TEXT.user_view.ADD_USER_VIEW_BTN
                            }
                            onClick={submitForm}
                            disable={loading}
                            data-testid="submitButton"
                          >
                            {loading ? (
                              <LoadingComponent />
                            ) : (
                              <ArrowForward />
                            )}
                          </GreenButton>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Layout>
  );
};

export default CreateEditUserViewComponent;
