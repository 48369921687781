/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Reusable button created only with material-ui base <Button /> component API.
 *
 * @author Maduka Dilshan
 */
import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-arround',
    border: '2px solid var(--button-boarder)',
    borderRadius: '5px',
    fontWeight: 'bold',
    height: '48px',
  },
});

/**
 * Default button for the logic engine which uses the system theme.
 * If need to override the styles, use @material-ui's makeStyle API and passdown the classes
 *
 * @param onClick CallBack Function Trigger when button is clicked
 * @param children React Children component.
 * @param className Custom material-ui classNames
 * @param disabled Boolean which enable or disable the button action
 *
 * @returns React Function Component
 *
 */
// eslint-disable-next-line import/prefer-default-export
export const DefaultButton: React.FC<{
  onClick: () => void;
  children: any;
  disabled?: boolean;
  className?: string;
}> = ({
  onClick, disabled, children, className, ...rest
}) => {
  const styles = useStyle();
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={clsx(styles.root, className)}
      color="primary"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <>{children}</>
    </Button>
  );
};

DefaultButton.defaultProps = {
  disabled: false,
  className: '',
};
