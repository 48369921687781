/* eslint-disable camelcase */
import React, { useContext, useState, useEffect } from 'react';
import {
  Typography,
  Box,
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Layout from '../../../components/layout/Layout';
import { PageHeader } from '../../../components/layout/PageHeader';
import { AuthContext } from '../../../store/Auth/AuthContext';
import { ProjectContext } from '../../../store/Project/ProjectContext';
import { useQueryNumber } from '../../../utility/CustomHooks/useQueryNumber';
import useQueryString from '../../../utility/CustomHooks/useQueryString';
import PageContentLayout, { PageConentHeader } from '../LogicsEngine/components/PageContentLayout';

const useStyle = makeStyles({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    marginBottom: '4%',
  },
  root: {
    // height: "100%",
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
  },
  formRoot: {
    width: '100%',
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const TemplateCreateForm = () => {
  const classes = useStyle();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [initialForm, setInitialForm] = useState({
    project_name: '',
    backup_name: '',
    backup_desc: '',
  });

  const authUser: any = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);

  const backup_serial = useQueryString('backup_serial') as string;
  const backup_type = useQueryString('type') as string;
  const universal_temp_update = useQueryNumber('universal_temp_update');
  const backup_title = useQueryString('t') as string;
  const backup_desc = useQueryString('desc') as string;

  useEffect(() => {
    setInitialForm({
      project_name: '',
      backup_name: backup_title,
      backup_desc,
    });
  }, [backup_serial, backup_type, backup_title, backup_desc]);

  const { enqueueSnackbar } = useSnackbar();
  const navgiate = useNavigate();

  return (
    <Formik
      initialValues={initialForm}
      enableReinitialize
      validationSchema={Yup.object().shape({
        backup_name: Yup.string().required('Template Name is required'),
      })}
      onSubmit={async (values) => {
        try {
          setIsSubmitting(true);
          const { token } = authUser.access_token;
          if (universal_temp_update === 1) {
            const res = await axios.post(
              `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
              }/rest/backups/v1/project-backup/point/update`,
              {
                backup_serial,
                name: values.backup_name,
                description: values.backup_desc,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
          } else {
            const res = await axios.post(
              `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
              }/rest/backups/v1/project-backup/point/universal-template/create`,
              {
                backup_serial,
                name: values.backup_name,
                description: values.backup_desc,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
          }
        } catch (err: any) {
          enqueueSnackbar(err.message, { variant: 'error' });
        } finally {
          enqueueSnackbar(
            universal_temp_update ? 'Universal Template Updated' : 'Universal Template Created',
            { variant: 'success' },
          );
          setIsSubmitting(false);
          navgiate(`/app/backup-restore?projectId=${selectedProject.selected_project.id}`);
        }
      }}
    >
      {({
        errors, values, handleChange, touched, handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              name="backup_name"
              value={values.backup_name}
              onChange={handleChange}
              label="Backup Name"
              variant="outlined"
              className={classes.formRoot}
              error={Boolean(touched.backup_name && errors.backup_name)}
              helperText={touched.backup_name && errors.backup_name}
            />
            <Box height={15} />
            <FormControl
              variant="outlined"
              error={Boolean(touched.backup_name && errors.backup_name)}
              className={classes.formRoot}
            >
              <TextareaAutosize
                placeholder="Backup Description"
                name="backup_desc"
                onChange={handleChange}
                minRows={5}
                maxRows={6}
                value={values.backup_desc}
              />
            </FormControl>
            <Box height={15} />
            <Box className={classes.btns}>
              <Button
                color="primary"
                onClick={() => {
                  navgiate(
                    `/app/backup-restore?projectId=${selectedProject.selected_project.id}`,
                  );
                }}
              >
                Cancel
              </Button>
              <Button disabled={isSubmitting} color="primary" variant="contained" type="submit">
                {universal_temp_update ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const TemplateCreate = () => {
  const styles = useStyle();
  const authUser: any = useContext(AuthContext);
  useFirebaseConnect(`system_admins/${authUser.uid}`);

  const universal_temp_update = useQueryNumber('universal_temp_update');

  return (
    <Layout>
      <PageHeader>
        <Box className={styles.headerRoot}>
          <Typography className={styles.headerTitle}>
            {universal_temp_update ? 'Edit Universal Template' : 'Create Universal Template'}
          </Typography>
        </Box>
      </PageHeader>
      <Box className={styles.contentRoot}>
        <PageContentLayout className={styles.root}>
          <PageConentHeader>
            <Typography variant="h5" component="h2">
              {universal_temp_update ? 'Edit Template' : 'Create Template'}
            </Typography>
          </PageConentHeader>
          <Box mt={3}>
            <TemplateCreateForm />
          </Box>
        </PageContentLayout>
      </Box>
    </Layout>
  );
};

export default TemplateCreate;
