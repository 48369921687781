import {CiDevice} from 'src/plugins/shared/services/cloud-integration.service';
import {FieldArray, Formik, useFormik} from 'formik';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {ICommDevice} from '../../../UVDeviceFinder/types';
import {useEffect, useMemo, useState} from 'react';
import {useSnackbar} from 'notistack';
import {IUserViewItem} from 'src/components/UserViewComponents/types/types';

const LabelValueMappingForSingleChannel: React.FC<{
  channelName: string;
  initialValue: {value: string; label: string}[];
  mapFromDeviceSettings: {value: string; label: string}[];
  onSave: (data: {[key: string]: {value: string; label: string}[]}) => void;
  onCancel: () => void;
}> = ({channelName, initialValue = [], mapFromDeviceSettings, onSave, onCancel}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [initialFormValue, setInitialFormValue] =
    useState<{[key: string]: {label: string; value: string}[]}>();

  useEffect(() => {
    setInitialFormValue({
      [channelName]: initialValue,
    });
  }, [channelName, initialValue]);

  if (!initialFormValue) return null;

  return (
    <div
      style={{
        marginTop: 10,
        padding: 5,
      }}
    >
      {mapFromDeviceSettings && (
        <Alert style={{marginBottom: 10}}>
          This field has pre configured map in device setting.{' '}
          <Button
            size="small"
            onClick={() => {
              setInitialFormValue({
                [channelName]: mapFromDeviceSettings,
              });
            }}
          >
            Use default map
          </Button>
        </Alert>
      )}
      <Formik
        initialValues={initialFormValue}
        enableReinitialize
        validationSchema={Yup.object().shape({
          [channelName]: Yup.array().of(
            Yup.object().shape({
              value: Yup.string().required('Value is required'),
              label: Yup.string().required('Label is required'),
            })
          ),
        })}
        onSubmit={values => {
          console.log(values);
          onSave(values);
        }}
      >
        {formik => (
          <FieldArray
            name={channelName}
            render={arrayHelpers => (
              <div>
                {formik.values[channelName].map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: 10,
                    }}
                  >
                    <TextField
                      size="small"
                      type="text"
                      label="Value"
                      name={`${channelName}.${index}.value`}
                      value={item.value}
                      onChange={formik.handleChange}
                      style={{width: '100%'}}
                      error={
                        formik.touched[channelName] &&
                        formik.touched[channelName][index] &&
                        formik.errors[channelName] &&
                        formik.errors[channelName][index] &&
                        formik.errors[channelName][index]['value']
                      }
                    />
                    <Box width={5} />
                    <TextField
                      size="small"
                      type="text"
                      name={`${channelName}.${index}.label`}
                      value={item.label}
                      onChange={formik.handleChange}
                      style={{width: '100%'}}
                      label="Label"
                      error={
                        formik.touched[channelName] &&
                        formik.touched[channelName][index] &&
                        formik.errors[channelName] &&
                        formik.errors[channelName][index] &&
                        formik.errors[channelName][index]['label']
                      }
                    />
                    <IconButton size="small" onClick={() => arrayHelpers.remove(index)}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </div>
                ))}
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<AddCircleIcon />}
                  onClick={() => arrayHelpers.push({value: '', label: ''})}
                >
                  Add Record
                </Button>

                <Box style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                  <Button
                    onClick={() => {
                      if (
                        formik.errors &&
                        formik.errors[channelName] &&
                        formik.errors[channelName]?.length > 0
                      ) {
                        enqueueSnackbar('Please make sure every field is filled', {
                          variant: 'error',
                        });
                        return;
                      }
                      formik.handleSubmit();
                    }}
                  >
                    Save Changes
                  </Button>
                  <Button onClick={() => onCancel()} color="inherit">
                    Cancel
                  </Button>
                </Box>
              </div>
            )}
          />
        )}
      </Formik>
    </div>
  );
};

const LabelValueMappingForCloudDevices: React.FC<{
  mode: 'uv' | 'device';
  initialMap: {
    [key: string]: {
      value: string;
      label: string;
    }[];
  };
  deviceSettings: any;
  onSave: (data: {[key: string]: {value: string; label: string}[]}) => void;
  onCancel: () => void;
}> = ({mode, initialMap, deviceSettings, onSave, onCancel}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [show, setShow] = useState<string | null>(null);

  const settings = deviceSettings;

  console.log('xx', settings);

  const channels =
    (settings?.sf as Record<
      string,
      {k: string; description?: string; t?: string; [key: string]: any}
    >) ?? {};

  return (
    <Box>
      <Table>
        <TableHead>
          <TableCell>Channel</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Action</TableCell>
        </TableHead>
        {Object.keys(channels).map(fKey => (
          <TableRow>
            <TableCell>{channels[fKey].k}</TableCell>
            <TableCell>{channels[fKey].description ?? '-'}</TableCell>
            <TableCell>{channels[fKey].t ?? '-'}</TableCell>
            <TableCell>
              <Button onClick={() => setShow(fKey)}>Config</Button>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Dialog open={show && show.length > 0}>
        <DialogTitle>
          Value Map For{' '}
          <span style={{color: '#46A0AE'}}>{channels && channels[show] && channels[show].k}</span>
        </DialogTitle>
        <DialogContent>
          {channels && channels[show] && (
            <LabelValueMappingForSingleChannel
              channelName={channels[show].k}
              initialValue={
                (initialMap && initialMap[channels[show].k]) ?? [{value: '', label: ''}]
              }
              mapFromDeviceSettings={
                (mode == 'uv' &&
                  settings?.label_value_map &&
                  settings?.label_value_map[channels[show].k]) ??
                null
              }
              onCancel={() => {
                setShow(null);
              }}
              onSave={value => {
                onSave(value);
                setShow(null);
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const LabelValueMapping: React.FC<{
  mode?: 'uv' | 'device';
  deviceType: 'location_variables' | 'cint';
  device:
    | null
    | ICommDevice
    | (CiDevice & {
        dc_type: string;
      });
  initialMap: {[key: string]: {value: string; label: string}[]} | string;
  // uvItem: IUserViewItem;
  appendSettings: (payload: {
    data: {
      label_value_map: {
        [key: string]: {label: string; value: string}[];
      };
    };
  }) => void;
}> = ({mode = 'uv', deviceType, device, initialMap, appendSettings}) => {
  const [show, setShow] = useState(false);

  const [mapData, setMapData] = useState<any>(initialMap);

  const settings = useMemo(() => {
    if (!device) return {};
    let settings = device.settings;
    if (typeof settings === 'string') {
      try {
        settings = JSON.parse(settings);
      } catch (err) {}
    }

    return settings;
  }, [device]);

  return (
    <Box>
      <Button onClick={() => setShow(true)}>Value Mapping</Button>
      <Dialog open={show} maxWidth="sm" fullWidth>
        <DialogTitle
          style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
        >
          <Typography variant="h6">Value Mapping</Typography>
          <IconButton onClick={() => setShow(false)}>
            <HighlightOffIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {deviceType === 'location_variables' && (
            <LabelValueMappingForSingleChannel
              channelName="defaultChannel"
              initialValue={(mapData && mapData['defaultChannel']) ?? []}
              onCancel={() => setShow(false)}
              mapFromDeviceSettings={
                (mode == 'uv' &&
                  settings?.label_value_map &&
                  settings?.label_value_map['defaultChannel']) ??
                null
              }
              onSave={value => {
                appendSettings({
                  data: {
                    label_value_map: {
                      ...value,
                    },
                  },
                });
                setMapData(value);
                setShow(false);
              }}
            />
          )}
          {deviceType === 'cint' && (
            <LabelValueMappingForCloudDevices
              mode={mode}
              deviceSettings={settings}
              initialMap={mapData}
              onCancel={() => setShow(false)}
              onSave={value => {
                appendSettings({
                  data: {
                    label_value_map: {
                      ...mapData,
                      ...value,
                    },
                  },
                });
                setMapData(old => ({...old, ...value}));
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LabelValueMapping;
