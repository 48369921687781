/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import './ListItem.css';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { config } from 'rxjs';
import { ApolloAuthContext } from '../../../../../store/Apollo/ApolloContext';
import { proDcTunnelDelete } from '../../../../../services/device-container/device-container.service';
import useQueryString from '../../../../../utility/CustomHooks/useQueryString';
import { appConsoleLog } from '../../../../../utility/appUtils';

// ssh -L 8000:0.0.0.0:8000 proxy8.remoteiot.com -p 25891 -l 'pi'

/**
 * 8000:0.0.0.0:8000 proxy8.remoteiot.com -p 25891 -l 'pi'
 * ITEM.port:default_gateway:ITEM.port  tunnel.host  -p tunnel.port -l 'pi'
 */

// eslint-disable-next-line import/prefer-default-export
export const ListItem: React.FC<{
  key: any;
  config_obj?: any;
  tunnel: any;
  isHost?: boolean;
  isNonSecureTunnel?: boolean;
  deletable?: boolean;
  refetch?: () => void;
}> = ({
  key, config_obj, tunnel, isHost, isNonSecureTunnel, deletable, refetch,
}) => {
  const userProfile = useContext(ApolloAuthContext);
  const [shh, setSsh] = useState('');
  const [localhost, setLocalhost] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dc_id = useQueryString('dcId') as any;

  useEffect(() => {
    if (isHost) {
      const url = tunnel.host;
      setSsh(`http://${url}`);
      setLocalhost(`http://${url}`);
      return;
    }
    if (isNonSecureTunnel) {
      const url = tunnel.host;
      setSsh(url);
      setLocalhost(url);
      return;
    }

    if (tunnel == null) {
      setSsh('Expired. Please re generate');
      return;
    }
    const url = tunnel.host.split('http://')[1].split(':')[0];
    if (config_obj && config_obj.type === 'secure') {
      setSsh(
        `ssh -L ${config_obj.port}:${config_obj.local_ip}:${config_obj.port} ${url} -p ${tunnel.port} -l connect`,
      );
    } else {
      setSsh(
        `ssh -L ${config_obj.port}:0.0.0.0:${config_obj.port} ${url} -p ${tunnel.port} -l connect`,
      );
    }
    setLocalhost(`http://localhost:${config_obj.port}`);
  }, [tunnel]);

  const onCopy = () => {
    navigator.clipboard.writeText(shh);
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  const onOpen = () => {
    window.open(localhost);
  };

  const onDelete = async () => {
    try {
      if (dc_id === '') throw new Error('Device Collection id not found');
      enqueueSnackbar('Now Deleting Tunnel...', { variant: 'info' });
      await proDcTunnelDelete(userProfile.apollo_client, dc_id, tunnel.type, `${tunnel.id}`);
      refetch();
      enqueueSnackbar('Tunnel Deleted', { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <div className="row hub_apis_item">
      <div className="col-md-2">
        <p style={{ fontWeight: 500 }}>
          {isHost
            ? `Host #${tunnel.id}`
            : isNonSecureTunnel
              ? `Tunnel #${tunnel.id}`
              : config_obj.name}
        </p>
      </div>
      {isHost || isNonSecureTunnel ? (
        <a
          className="col-md-8 hub_apis_host_link"
          target="_blank"
          href={localhost}
          rel="noopener noreferrer"
        >
          {shh}
        </a>
      ) : (
        <div className="col-md-8 hub_apis_ssh_link">{shh}</div>
      )}
      <div className="col-md-1 col-sm-6">
        {deletable ? (
          <Tooltip title="Delete this connection">
            <IconButton aria-label="delete" onClick={onDelete} size="large">
              <DeleteIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Copy command">
            <IconButton onClick={onCopy} disabled={!tunnel} size="large">
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="col-md-1 col-sm-6">
        <Tooltip title="Access Remote Device">
          <IconButton aria-label="delete" onClick={onOpen} disabled={!tunnel} size="large">
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

ListItem.defaultProps = {
  config_obj: null,
  isHost: false,
  isNonSecureTunnel: false,
  deletable: false,
  refetch: () => { appConsoleLog('default refetch at list Item'); },
};
