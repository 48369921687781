/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component which uses in device information to list links to the userviews
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component
 * @author Mayantha
 * @since -
 */
import React from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import styles from './LinkedDeviceCard.module.css';

type ComponentProps = {
  linkedItemName: string;
  UserViewName: string;
  goTo: () => void;
  icon?: any;
};
const ITEM_HEIGHT = 48;

/**
 * Card component which use to list device links inside device information view
 *
 * @callback GoTo
 * @param {Object} props                component props
 * @param {String} props.linkedItemName Text displays in the Option
 * @param {String} props.UserViewName   Userview Name where the given device is linked
 * @param {String} props.icon           given icon to render in the card
 * @param {GoTo} props.goTo             callback emit when user approve invitation
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const LinkedDeviceCardComponent: React.FunctionComponent<ComponentProps> = ({
  linkedItemName,
  UserViewName,
  goTo,
  icon,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`${styles.root} nopaddingOnly row`}>
      <div className=" col-md-12 pt-2">
        <div className={styles.titleIcon}>
          <FiMoreVertical size={20} onClick={handleClick} />
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem
              key="Delete"
              onClick={() => {
                handleClose();
                goTo();
              }}
            >
              Go To
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div tabIndex={0} role="button" onClick={goTo} onKeyDown={(e) => e.key === '13' && goTo()} style={{ cursor: 'pointer' }}>
        <div className=" col-md-12 ">
          <div className={` col-md-12 nopaddingOnly ${styles.bottomLine}`}>
            <div className={`${styles.title} pb-1`}>{linkedItemName}</div>
          </div>
        </div>
        <div className=" col-md-12 pb-2 pt-2">
          <div className=" col-md-2 nopaddingOnly">
            {/* <FiServer size={20} /> */}
            <span className={`calc ${icon} ${styles.calcIconView}`} />
          </div>
          <div className=" col-md-10 nopaddingOnly">
            <div className={`${styles.desciption} `}>{UserViewName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

LinkedDeviceCardComponent.defaultProps = {
  icon: '',
};
