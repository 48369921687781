/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useMemo, useReducer } from 'react';

const initialState = {
  selectedDevice: null,
  showPanel: false,
  deviceConfig: null,
  isClickedOnAvailableDevice: false,
};

export const DeviceConfigContext = createContext({
  ...initialState,
  dispatch: (payload: any) => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PANEL': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const DeviceConfigContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const values = useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <DeviceConfigContext.Provider value={values as any}>
      {props.children}
    </DeviceConfigContext.Provider>
  );
};
