/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  FormControl, Grid, CircularProgress, TextField, Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ProDevice, AirDevice, DeviceCollectionSearchOutItem } from '@smartlife-redux-state/common';
import {
  cloneDeep, isArray, orderBy, capitalize,
} from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { ITEM_DEFAULTS } from '../../../../../../../config/deviceConfig/item_defaults';
import { proDeviceGetGraphQL } from '../../../../../../../services/pro-device/pro-device.service';
import { ApolloAuthContext } from '../../../../../../../store/Apollo/ApolloContext';
import { searchDc, searchDevices } from '../../../../../../../store/redux/features/logic-engine';
import { select_device } from '../../../../../../../store/redux/features/logic-engine/slice';
import { RootState } from '../../../../../../../store/redux/store';
import useQueryString from '../../../../../../../utility/CustomHooks/useQueryString';
import { airDeviceGet } from '../../../../../AirDeviceCreateEdit/services/air-device.service';
import { LogicStepsTypes } from '../../../LogicStepper';

function filterDevicesForLogicStep(step: LogicStepsTypes, devices: (ProDevice | AirDevice)[]) {
  let cpy = cloneDeep(devices);
  // debugger;
  if (step === LogicStepsTypes.WHEN || step === LogicStepsTypes.CHECK) {
    const filterd = cpy.filter(
      (device) => device.cat !== 'ir' && device.cat !== 'ac' && device.cat !== 'camera',
    );
    cpy = filterd;
  }

  return cpy;
}

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

const DeviceAutoSelector: React.FC<{
  selectedDevice: AirDevice | ProDevice;
  project_id: string;
  dc: DeviceCollectionSearchOutItem;
  logic_step: LogicStepsTypes;
  disbaledDevice?: AirDevice | ProDevice;
  disableClearable?: boolean;
  onDeviceSelect: (device: AirDevice | ProDevice) => void;
  behaveAs?: string;
}> = ({
  dc,
  selectedDevice = null,
  project_id,
  onDeviceSelect,
  disbaledDevice,
  disableClearable,
  logic_step,
  behaveAs = 'devices',
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const devices = useSelector((state: RootState) => state.rule_engine.devices);
  const device_loading = useSelector((state: RootState) => state.rule_engine.meta.loadin_device);
  const loading = open && device_loading;
  const dispatch = useDispatch();

  // const mapped_devices = devices
  //   ? [...devices.air_devices, ...devices.pro_devices]
  //   : [];

  const mapped_devices = devices && isArray(devices.result)
    ? filterDevicesForLogicStep(logic_step, [...devices.result])
    : [];

  useEffect(() => {
    console.log('IS OPEN', open);
    if (open) {
      if (dc && dc.dc_type === 9) {
        dispatch(
          searchDevices({
            air_dc_id: [],
            pro_dc_id: [dc.id],
            cint_dc_id: [],
            keyword: '',
            project_id,
          }),
        );
        return;
      }
      dispatch(
        searchDevices({
          air_dc_id: dc && dc.dc_cat !== -1 && dc.dc_type === 1 ? [dc.id] : [],
          pro_dc_id: dc && dc.dc_cat !== -1 && dc.dc_type === 4 ? [dc.id] : [],
          cint_dc_id: dc && dc.dc_cat === -1 ? [dc.id] : [],
          keyword: '',
          project_id,
        }),
      );
    }
  }, [open]);

  function onDeviceSelected(device: AirDevice | ProDevice) {
    onDeviceSelect(device);
  }

  return (
    <Autocomplete
      onChange={(_, value) => onDeviceSelected(value as AirDevice | ProDevice)}
      disableClearable={disableClearable}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option: any) => option.name}
      options={orderBy(mapped_devices, ['cat'], ['asc'])}
      getOptionDisabled={(option) => disbaledDevice && option.id === disbaledDevice.id}
      groupBy={(option) => {
        if (option.shadow_type === 'cloudIntegration') {
          return capitalize(option.cat);
        }
        if (option.shadow_type === 'airDevice') {
          const type = (option as AirDevice).device_type;
          try {
            const modified = type && typeof type === 'string' ? type.replace('_', ' ') : 'Unknown';
            const capitalized = modified.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()); // modified.charAt(0).toUpperCase() + modified.slice(1);
            return capitalized;
          } catch (err) {
            return (option as AirDevice).device_type;
          }
        }
        return ITEM_DEFAULTS.ITEM_CREATOR.item_info_flows[option.cat].title;
      }}
      loading={loading}
      value={selectedDevice}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={behaveAs === 'variables' ? 'Location Variable' : 'Device'}
          onChange={(e) => console.log(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

// eslint-disable-next-line import/prefer-default-export
export const DeviceSelector: React.FC<{
  logic_step: LogicStepsTypes;
  selectedDC?: DeviceCollectionSearchOutItem;
  selectedDevice?: AirDevice | ProDevice;
  disbaledDevice?: AirDevice | ProDevice;
  disableDeviceClearable?: boolean;
  onDcSelect?: (dc: DeviceCollectionSearchOutItem) => void;
  onDeviceSelect?: (device: AirDevice | ProDevice) => void;
  behaveAs?: string;
}> = ({
  logic_step,
  selectedDC = null,
  selectedDevice = null,
  disbaledDevice = null,
  disableDeviceClearable = false,
  onDcSelect,
  onDeviceSelect,
  behaveAs = 'devices',
}) => {
  const classes = useStyles();
  const projectId = useQueryString('projectId') as string;
  const [open, setOpen] = useState(false);

  const userProfile = useContext(ApolloAuthContext);

  const [check_device_existance, setDeviceExistance] = useState(false);
  // const [is_valid_device, setIsValidDevice] = useState(true);

  // useEffect(() => {
  //   async function getDevice(
  //     type: "AirDevice" | "ProDevice",
  //     device_id: string
  //   ) {
  //     try {
  //       if (type == "AirDevice") {
  //         const res = await airDeviceGet(userProfile.apollo_client, device_id);
  //         if (res) {
  //           setIsValidDevice(true);
  //           return;
  //         }
  //       }
  //       if (type == "ProDevice") {
  //         const res = await proDeviceGetGraphQL(
  //           userProfile.apollo_client,
  //           device_id
  //         );
  //         if (res) {
  //           setIsValidDevice(true);
  //           return;
  //         }
  //       }
  //     } catch (err) {
  //       setIsValidDevice(false);
  //     } finally {
  //       setDeviceExistance(true);
  //     }
  //   }
  //   if (!check_device_existance && selectedDevice) {
  //     // validate the device existanance
  //     getDevice(selectedDevice.__typename, selectedDevice.id);
  //   }
  // }, [selectedDevice, check_device_existance]);

  // const [selectedDc, setSelectedDc] =
  //   useState<DeviceCollectionSearchOutItem>(null);

  const dc = useSelector((state: RootState) => {
    if (behaveAs === 'devices') {
      return state.rule_engine.device_containers?.result.filter(
        // dc_cat === -1 is when the container is converted and is cloud integration
        (conat) => conat.dc_cat === -1 || conat.dc_type === 1 || conat.dc_type === 4,
      );
    }
    if (behaveAs === 'variables') {
      return state.rule_engine.location_variable_container
        ? [state.rule_engine.location_variable_container]
        : [];
    }
    return null;
  });
  const loading = open && dc.length === 0;
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      dispatch(
        searchDc({
          keyword: '',
          project_id: projectId,
        }),
      );
    }
  }, [loading]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function onDcSelected(dc: DeviceCollectionSearchOutItem) {
    console.log('DC SELECTED', dc);
    if (dc) {
      onDcSelect(dc);
      // setSelectedDc(dc);
    } // dispatch(select_dc({ dc }));
  }

  function _onDeviceSelected(device: AirDevice | ProDevice) {
    if (!device) {
      dispatch(select_device({ device: null }));
    }
    dispatch(select_device({ device: { ...device, has_verification_error: null } }));
    if (onDeviceSelect) onDeviceSelect(!device ? null : { ...device, has_verification_error: null });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <Autocomplete
            onChange={(_, value) => onDcSelected(value as DeviceCollectionSearchOutItem)}
            disableClearable
            size="small"
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: any) => option.name}
            options={dc}
            groupBy={(option) => {
              if (option.dc_cat === -1) {
                return 'Cloud Integrations';
              }
              if (option.dc_type === 1) {
                return 'Smartlife Air Collections';
              }
              if (option.dc_type === 4) {
                return 'Smartlife Connect Hubs';
              }
              if (option.dc_type === 9) {
                return 'Location Variable Collections';
              }
              return '';
            }}
            loading={loading}
            value={selectedDC}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={behaveAs === 'variables' ? 'Variable Collection' : 'Device Collection'}
                onChange={(e) => console.log(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          {/* <Select label="Device" onOpen={() => console.log("select clicked")}>
            <MenuItem value={1}>Sample Device</MenuItem>
          </Select> */}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <DeviceAutoSelector
            dc={selectedDC}
            selectedDevice={selectedDevice}
            project_id={projectId}
            disbaledDevice={disbaledDevice}
            onDeviceSelect={_onDeviceSelected}
            behaveAs={behaveAs}
            logic_step={logic_step}
            disableClearable={disableDeviceClearable}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
