import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import moment from 'moment';
import { EXECUTION_LIMIT } from '@smartlife-redux-state/common';
import { HoursInDay, MinutesInHour } from '../CRON_TYPES';

export const TIME_SELECTOR_TIME_FORMAT = 'HH:mm';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

type ComponentProps = {
  typekey: string;
  initialValue: {
    type: EXECUTION_LIMIT;
    value: number;
  };
  onChange: (data: {type: EXECUTION_LIMIT; value: number}) => void;
};
export const CronRunCountSelector: React.FC<ComponentProps> = ({
  initialValue,
  typekey,
  onChange,
}) => {
  const countx = initialValue.value;
  const countTypex = initialValue.type;

  const classes = useStyles();

  return (
    <Box pt={2}>
      <Grid container>
        <FormControl>
          <FormLabel>Number of Executions : </FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="unlimited"
            value={countTypex}
            // eslint-disable-next-line consistent-return
            onChange={(event) => {
              switch (event.target.value) {
                case EXECUTION_LIMIT.ONCE:
                  onChange({
                    value: 1,
                    type: event.target.value as any,
                  });
                  break;
                case EXECUTION_LIMIT.CUSTOM:
                  onChange({
                    value: 10,
                    type: event.target.value as any,
                  });
                  break;
                case EXECUTION_LIMIT.UNLIMITED:
                  onChange({
                    value: 0,
                    type: event.target.value as any,
                  });
                  break;
                default:
                  return null;
              }
              // setCountType(event.target.value);
            }}
          >
            <FormControlLabel value="once" control={<Radio color="primary" />} label="Once" />
            <FormControlLabel
              value="custom"
              disabled={typekey.indexOf('type3') > -1}
              control={<Radio color="primary" />}
              label="Custom"
            />
            <FormControlLabel
              value="unlimited"
              disabled={typekey.indexOf('type3') > -1}
              control={<Radio color="primary" />}
              label="Unlimited"
            />
          </RadioGroup>
        </FormControl>
        <Box m={1} />
        {countTypex === 'custom' ? (
          <form className={classes.formRoot} noValidate autoComplete="off">
            <div>
              <TextField
                id="standard-error-helper-text"
                label="Number of executions"
                value={countx}
                error={countx == null}
                variant="outlined"
                size="small"
                type="number"
                onChange={(e) => {
                  onChange({
                    value: e.target.value.length > 0 ? Number(e.target.value) : null,
                    type: countTypex,
                  });
                }}
              />
            </div>
          </form>
        ) : (
          <div />
        )}
      </Grid>
    </Box>
  );
};
