/* eslint-disable camelcase */
import { ConfigurableContainerState } from '../../../configurable-containers';
import { V2BStepackupInfoRoot } from '../../v2types';

/**
 * Example POST req / {
    "session_id": "61c55242b7b38277e69d29bf",
    "type": "lvar",
    "info_json": {
        "GsVziSrNV2o9f": {
            "1_GsVziSrNV2o9f_IJpmpSrNc5ung": {
                "new_id": "",
                "clone_type": "create_new",
                "data": {
                    "name":"test44444",
                    "settings":{
                        "state":12
                    }
                }
            },
             "1_GsVziSrNV2o9f_g0nwrSrVKDBug": {
                "new_id": "1_BLZhqSrwp24lf_Lqnp1SsUOP33g",
                "clone_type": "select_existing"
            },
             "1_GsVziSrNV2o9f_UuLcVSrwj0ohg": {
                "new_id": "",
                "clone_type": "disable"
            }
        }
    },
    "info_state": "redux info"
}
 */
function toAPIPostFriendyLocVarInfoJson(info: V2BStepackupInfoRoot, skipStep = false) {
  const apiFriendly = {} as any;
  Object.keys(info.pendingComps).map((locVarContainerId) => {
    apiFriendly[locVarContainerId] = {};
    const templateContainer = info.pendingComps[locVarContainerId];

    if (skipStep) {
      return Object.keys(templateContainer).map((templateDeviceId, index) => {
        apiFriendly[locVarContainerId][templateDeviceId] = {
          new_id: '',
          clone_type: 'disable',
        };
        return null;
      });
    }

    return Object.keys(templateContainer).map((templateDeviceId, index) => {
      const templateDevice = templateContainer[templateDeviceId];

      const clientMeta = templateDevice.clientSideMeta;

      if (clientMeta) {
        const currentDcId = clientMeta.dropped_contatainer_id;
        const { dropped_on } = clientMeta;

        if (!dropped_on) {
          // dropped as a new
          const deviceCloneInfo = {
            new_id: '',
            clone_type: 'create_new',
          };
          apiFriendly[locVarContainerId][templateDeviceId] = deviceCloneInfo;
        } else {
          // dropped on a existing device
          const deviceCloneInfo = {
            new_id: dropped_on,
            clone_type: 'select_existing',
          };

          apiFriendly[locVarContainerId][templateDeviceId] = deviceCloneInfo;
        }
      } else {
        // const deviceCloneInfo = {
        //   new_id: '',
        //   clone_type: 'disable',
        // };

        // apiFriendly[locVarContainerId][templateDeviceId] = deviceCloneInfo;
      }

      // eslint-disable-next-line consistent-return
      return null;
    });
  });

  return apiFriendly;
}

export default toAPIPostFriendyLocVarInfoJson;
