/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  Button,
  Grid,
  Typography,
  Alert,
  IconButton,
  Box,
  Skeleton,
  Tabs,
  Tab,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { clone, isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import DescriptionIcon from '@mui/icons-material/Description';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import axios from 'axios';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { RootState } from 'src/store/redux/store';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { dcSearchGraphQL } from 'src/services/device-container/device-container.service';
import parseCustomApiError from 'src/utility/parseError';
import clsx from 'clsx';
import {
  TemplateNodeRedListContext,
  TemplateNodeRedListProvider,
} from './context/templateNodeRedList';

const useStyle = makeStyles({
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  templateNodeRedCard: {
    borderRadius: 10,
    transition: 'all 0.5s',
    padding: 10,
    // cursor: 'pointer',
    backgroundColor: '#EBF1F2',
  },
  activeNcard: {
    backgroundColor: '#108696',
    color: 'white',
  },
});

const SelectNodeRedFromTemplate = ({ pro_dc, sessionId }) => {
  const classes = useStyle();
  const state = useContext(TemplateNodeRedListContext);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useContext(AuthContext);

  const [selected, setSelected] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [firstRequestSentSuccess, setFirstRequestSentSuccess] = useState(false);
  const upload = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/nodered/flows/backup/automateUpload`,
        {
          session_id: sessionId,
          nodered_backup_id: selected,
          new_pro_dc_id: pro_dc.id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.access_token.token}`,
          },
        },
      );
      setFirstRequestSentSuccess(true);
      setSelected(null);
      enqueueSnackbar('NodeRed Configured Successfully', { variant: 'success' });
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data);
      enqueueSnackbar(msg, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  function downloadObjectAsJson(exportObj, exportName) {
    try {
      const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(exportObj),
      )}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `${exportName}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    } catch (err) {
      enqueueSnackbar('Unable to download JSON', { variant: 'error' });
    }
  }

  const [show, setShow] = useState(false);
  const downlaodFlow = async (dc_id: string, dc_name: string, type: '1' | '0') => {
    try {
      setShow(false);
      const { token } = auth.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/nodered/flows/download`,
        {
          session_id: sessionId,
          pro_dc_id_key: dc_id,
          // eslint-disable-next-line eqeqeq
          type: type == '1' ? 'updated' : 'fresh-from-backup',
          // eslint-disable-next-line eqeqeq
          new_pro_dc_id: type == '1' ? pro_dc.id : '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.data && res.data.result && res.data.result.flows) {
        const parsed = JSON.parse(res.data.result.flows);
        downloadObjectAsJson(
          parsed,
          // eslint-disable-next-line no-useless-concat
          `${type === '1' ? 'Updated ' : 'Original '
          }NodeRed-Flow-dc-id=${type === '1' ? pro_dc.name : dc_name}`,
        );
      }
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data);
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  if (state.loading) {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>Loading...</Box>
    );
  }

  if (state.hasError) {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>Something Went Wrong :(</Typography>
        <FormHelperText>We are unable to fetch nodered flows of the template</FormHelperText>
      </Box>
    );
  }

  if (state.notFound) {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography>The template does not have any nodered flows.</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" disabled={!selected || loading} onClick={upload}>
            {loading ? 'Configuring...' : firstRequestSentSuccess ? 'Re Configure' : 'Configure'}
          </Button>
        </Box>
      </Grid>
      {state.list.map((item) => {
        const isSelected = selected === item.id;
        if (!item.have_nodered_flows) return <></>;
        return (
          <Grid item md={4} sm={12} key={item.id}>
            <Box
              className={clsx(
                classes.templateNodeRedCard,
                // selected === item.id && classes.activeNcard,
              )}
            >
              <Typography>{item.name}</Typography>
              <FormHelperText>
                Serial:
                {item.serial_name}
              </FormHelperText>
              <Box style={{ display: 'flex' }}>
                <Button
                  variant={isSelected ? 'contained' : 'outlined'}
                  size="small"
                  color="primary"
                  onClick={() => setSelected(item.id)}
                >
                  {isSelected ? 'Selected' : 'Select'}
                </Button>
                <Box style={{ width: '5px' }} />
                <Button variant="outlined" size="small" color="info" onClick={() => setShow(true)}>
                  Download
                </Button>
              </Box>
            </Box>
            <Dialog open={show} onClose={() => setShow(false)}>
              <DialogTitle>Select One</DialogTitle>
              <DialogContent>
                <FormControl>
                  <MenuItem value="0" onClick={() => downlaodFlow(item.id, item.name, '0')}>
                    1. Download original flow from backup point
                  </MenuItem>
                  <MenuItem value="1" onClick={() => downlaodFlow(item.id, item.name, '1')}>
                    2. Download updated flow from current location
                  </MenuItem>
                </FormControl>
              </DialogContent>
            </Dialog>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ConfigNodeRedChild: React.FC<{
  pro_dc: any;
  sessionId: string;
}> = ({ pro_dc, sessionId }) => {
  const selectedProject = useContext(ProjectContext);
  const authUser: any = useContext(AuthContext);
  const [file_content, setFileContent] = useState(null);
  const [file_name, setFileName] = useState(null);
  const [isSubmited, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstUpload, setFirstUpload] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (file_content) {
  //     updateFileContent((state) => ({ ...state, [pro_dc.id]: file_content }));
  //   }
  // }, [file_content]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    const file = acceptedFiles[0];
    if (file) {
      let content: string | ArrayBuffer = '';
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = function (evt) {
        content = evt.target.result;
        try {
          const parsed = JSON.parse(content.toString());
          setFileContent(JSON.stringify(parsed));
          setFileName(file.name);
        } catch (err) {
          enqueueSnackbar("There's something wrong in the file. Make sure the syntax is correct", {
            variant: 'error',
          });
        }
      };
      reader.onerror = function (evt) {
        enqueueSnackbar('Error reading file', { variant: 'error' });
      };
    }
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    validator: fileTypehValidator,
  });

  function downloadObjectAsJson(exportObj, exportName) {
    try {
      const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(exportObj),
      )}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `${exportName}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    } catch (err) {
      enqueueSnackbar('Unable to download JSON', { variant: 'error' });
    }
  }

  const onSubmit = async () => {
    const pro_dc_id = pro_dc.id;
    const flow = file_content;
    try {
      console.log('Uploading NodeRed for connect container ID ', pro_dc_id);
      setLoading(true);
      const { token } = authUser.access_token;
      // eslint-disable-next-line no-await-in-loop
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/nodered/flows/upload`,
        {
          session_id: sessionId,
          pro_dc_id,
          flows: flow,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const dc = pro_dc;

      downloadObjectAsJson(
        JSON.parse(res.data.result.nodered_flows),
        `NodeRed Flows for ${dc}` ? dc.name : pro_dc_id,
      );
      setFirstUpload(false);
    } catch (err: any) {
      const msg = parseCustomApiError(err.response.data && err.response.data.error);
      enqueueSnackbar(msg, { variant: 'error' });
      setSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  function fileTypehValidator(file) {
    if (file.type !== 'application/json') {
      return {
        code: 'unsupported-file',
        message: 'Only JSON files are accepted',
      };
    }

    return null;
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.map((item) => item.errors.map((err) => enqueueSnackbar(err.message, { variant: 'error' })));
    }
  }, [fileRejections]);

  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const downlaodFlow = async () => {
    try {
      const { token } = authUser.access_token;
      const res = await axios.post(
        `${
          process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]
        }/rest/backups/v2/restore/clone/nodered/flows/download`,
        {
          session_id: sessionId,
          pro_dc_id_key: '',
          type: 'fresh-from-backup',
          new_pro_dc_id: pro_dc.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.data && res.data.result && res.data.result.flows) {
        const parsed = JSON.parse(res.data.result.flows);
        // downloadObjectAsJson(parsed, `Updated-NodeRed-Flow-dc-id=${pro_dc.id}`);
      }
    } catch (err: any) {
      const msg = parseCustomApiError(err.response && err.response.data);
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Select From Backup Point" />
          <Tab label="Manually Upload" />
        </Tabs>
      </Box>
      {tab === 0 && (
        <div style={{ marginTop: 10 }}>
          <SelectNodeRedFromTemplate pro_dc={pro_dc} sessionId={sessionId} />
        </div>
      )}
      {tab === 1 && (
        <div style={{ marginTop: 10 }}>
          {file_content && (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ fontWeight: 600 }}>Selected File : </p>
                <p>
                  <DescriptionIcon fontSize="small" />
                </p>
                {' '}
                <p>{file_name}</p>
              </div>
            </div>
          )}
          <div style={{ margin: '0 20px 20px 20px', display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
              variant="outlined"
              size="small"
              color="info"
              onClick={downlaodFlow}
            >
              Download
            </Button> */}
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={onSubmit}
              disabled={loading || !file_content}
              // disabled={
              //     Object.keys(file_contents).length !== Object.keys(pro_dcs).length
              //     || isSubmited
              //     || loading
              //   }
            >
              {!firstUpload ? 'Re upload' : loading ? 'Processing...' : 'Upload'}
            </Button>
          </div>
          <div {...getRootProps({ className: 'dropzone' })} style={{ cursor: 'pointer' }}>
            <input {...getInputProps()} accept="application/json" />
            <div />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Drag and drop nodered json file here, or click to select file</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const NodeRed = ({ sessionId }) => {
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [dcs, setDcs] = useState([]);
  const [noDcs, setNoDcs] = useState(false);

  useEffect(() => {
    fetch_dcs();
  }, []);

  async function fetch_dcs() {
    try {
      setLoading(true);
      const res = await dcSearchGraphQL(
        userProfile.apollo_client,
        selectedProject.selected_project.id,
        4,
        -1,
        '',
        100,
        0,
      );
      // const res1 = await dcSearchGraphQL(
      //   userProfile.apollo_client,
      //   selectedProject.selected_project.id,
      //   8,
      //   -1,
      //   '',
      //   100,
      //   0,
      // );

      // let filtered_demo = [];
      // if (res1 && res1.dcSearch.result && isArray(res1.dcSearch.result)) {
      //   const filtered = res1.dcSearch.result.filter((item) => item.dc_cat === 1);
      //   filtered_demo = filtered;
      // }

      let data = [];
      if (res.dcSearch.result) data = [...data, ...res.dcSearch.result];
      // if (filtered_demo) data = [...data, ...filtered_demo];

      if (data.length > 0) {
        setDcs([...data]);
      } else {
        setDcs([]);
      }
      if (data.length === 0) setNoDcs(true);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <Box
        style={{
          width: '100%',
          height: '100px',
        }}
      >
        <Skeleton style={{ width: '100%', height: '100%' }} />
      </Box>
    );
  }

  if (noDcs) return <Box />;

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography style={{ fontWeight: 700 }}>Node-RED Configuration</Typography>
        <Box height={10} />
      </Grid>
      <Grid item md={8}>
        Select the Node-RED Config file and click start configuration to upload Node-RED Config.
      </Grid>
      {dcs.map((dc) => (
        <Grid item md={12} key={dc.id}>
          <fieldset>
            <legend>
              <Typography variant="subtitle2">
                Node-RED For :
                {dc.name}
              </Typography>
            </legend>
            <ConfigNodeRedChild pro_dc={dc} sessionId={sessionId} />
          </fieldset>
        </Grid>
      ))}
    </Grid>
  );
};

export const NodeRedParent = ({ sessionId }) => (
  <TemplateNodeRedListProvider sessionId={sessionId}>
    <NodeRed sessionId={sessionId} />
  </TemplateNodeRedListProvider>
);

export default NodeRedParent;
