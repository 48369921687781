/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isArray, isNull } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import {
  Action,
  AirDevice,
  DeviceCollectionSearchOutItem,
  OPERATORS,
  ProDevice,
  Trigger,
} from '@smartlife-redux-state/common';
import { RootState } from '../../../../../../../store/redux/store';
import { ExpressionVariableSelector } from './expression-variable/ExpressionVariableSelector';
import { DirivedValueBuilder } from '../actions/shared/DirivedValueBuilder';

const OP_FOR_HTML_SELECTOR = [
  {
    label: '=',
    value: OPERATORS.EQUAL,
  },
  {
    label: '!=',
    value: OPERATORS.NOT_EQUAL,
  },
  {
    label: '<',
    value: OPERATORS.LESS_THAN,
  },
  {
    label: '>',
    value: OPERATORS.GREATER_THAN,
  },
  {
    label: '<=',
    value: OPERATORS.LESS_THAN_EQUAL,
  },
  {
    label: '>=',
    value: OPERATORS.GREATER_THAN_EQUAL,
  },
  {
    label: 'R1 < x < R2',
    value: OPERATORS.IN_BETWEEN,
  },
  {
    label: 'R1 <= x <= R2',
    value: OPERATORS.IN_BETWEEN_INCLUSIVE,
  },
  {
    label: 'R1 <= x < R2',
    value: OPERATORS.IN_BETWEEN_LEFT_INCLUSIVE,
  },
  {
    label: 'R1 < x <= R2',
    value: OPERATORS.IN_BETWEEN_RIGHT_INCLUSIVE,
  },
];

const STRING_OP_FOR_HTML_SELECTOR = [
  {
    label: '=',
    value: OPERATORS.EQUAL,
  },
  {
    label: '!=',
    value: OPERATORS.NOT_EQUAL,
  },
];

const BOOL_OP_FOR_HTML_SELECTOR = [
  {
    label: '=',
    value: OPERATORS.EQUAL,
  },
  {
    label: '!=',
    value: OPERATORS.NOT_EQUAL,
  },
];

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  between_root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export function useFirebasePathBuilder(
  selectedDC: DeviceCollectionSearchOutItem,
  selectedDevice: AirDevice | ProDevice,
  variable: {field: string; type: string},
  findFeedbackKey = false,
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [firebase_path, setFirebasePath] = useState('');

  useEffect(() => {
    if (!selectedDevice) {
      setFirebasePath('');
      return;
    }

    if (
      selectedDC
      && selectedDevice
      && selectedDevice.id
      && selectedDevice.cat !== 'location_variables'
    ) {
      if (selectedDC.dc_type === 1) {
        if (selectedDevice.cat === 'knx') {
          setFirebasePath(
            `shadow_in/${selectedDC.id}/${selectedDevice.id.split('_')[0]}/${selectedDevice.id}/${
              variable.field
            }`,
          );
        } else {
          setFirebasePath(
            `shadow_in/${selectedDC.id}/${selectedDevice.id.split('_')[0]}/${selectedDevice.id}/${
              variable.field
            }`,
          );
        }
      } else if (selectedDevice.shadow_type === 'cloudIntegration') {
        if (findFeedbackKey) {
          let settings = null;
          try {
            settings = JSON.parse(selectedDevice.settings);
          } catch (err) {
            console.error(err);
          }

          if (settings.sf) {
            const found = Object.keys(settings.sf).find(
              (key) => settings.sf[key].command === variable.field,
            );
            if (found) {
              const feedbackKey = settings.sf[found].k;
              if (feedbackKey) {
                setFirebasePath(
                  `shadow_in/${selectedDC.id}/${selectedDevice.id.split('_')[0]}/${
                    selectedDevice.id
                  }/${feedbackKey}`,
                );
              }
            }
          }
        } else {
          setFirebasePath(
            `shadow_in/${selectedDC.id}/${selectedDevice.id.split('_')[0]}/${selectedDevice.id}/${
              variable.field
            }`,
          );
        }
      } else {
        setFirebasePath(
          `shadow_in/${selectedDC.unique_key}/${selectedDevice.id.split('_')[0]}/${
            selectedDevice.id
          }/${variable.field}`,
        );
      }
      // console.log(selectedDevice, selectedDevice.cat);
    } else if (selectedDevice && selectedDevice.cat === 'location_variables') {
      const splitted_id = selectedDevice.id.split('_');
      const dc_id = splitted_id[1];
      const cluster_id = splitted_id[0];
      const { project_id } = selectedDevice;
      setFirebasePath(`shadow_in/LocVarCol_${project_id}/${cluster_id}/${selectedDevice.id}/state`);
    }
  }, [selectedDC, selectedDevice, variable]);

  return [firebase_path, setFirebasePath];
}

const SingleValue: React.FC<{
  current_value: string;
  OP: string;
  selected_value: string;
  onSelect: (value: string) => void;
}> = ({
  OP, selected_value, onSelect, current_value,
}) => {
  const classes = useStyles();
  const [val, setVal] = useState('');

  useEffect(() => {
    setVal(selected_value === null ? '' : selected_value);
  }, [selected_value]);

  function handleChange(e) {
    setVal(e.target.value);
    onSelect(e.target.value);
  }
  return (
    <Box>
      <FormControl className={classes.formControl} variant="outlined" size="small">
        <InputLabel>Value</InputLabel>
        <OutlinedInput
          label="Value"
          value={val}
          onChange={handleChange}
          startAdornment={(
            <InputAdornment position="start">
              {`X ${current_value ? `(${current_value})` : ''} ${OP}`}
            </InputAdornment>
          )}
        />
      </FormControl>
    </Box>
  );
};

const SingleNumberValue: React.FC<{
  current_value: string;
  OP: string;
  selected_value: string;
  onSelect: (value: string) => void;
}> = ({
  OP, selected_value, onSelect, current_value,
}) => {
  const classes = useStyles();
  const [val, setVal] = useState('0');
  const [err, setErr] = useState(false);

  useEffect(() => {
    setVal(selected_value);
  }, [selected_value]);

  function handleChange(e) {
    setVal(e.target.value);
    try {
      if (Number(e.target.value) < 0) {
        setErr(true);
      } else {
        setErr(false);
        onSelect(e.target.value);
      }
    } catch (err) {
      setErr(true);
    }
  }
  return (
    <Box>
      <FormControl className={classes.formControl} variant="outlined" size="small">
        <InputLabel>Value</InputLabel>
        <OutlinedInput
          label="Value"
          value={val}
          error={err}
          type="Number"
          onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          onChange={(e) => {
            handleChange(e);
          }}
          inputProps={{
            min: 0,
          }}
          startAdornment={(
            <InputAdornment position="start">
              {`X ${current_value ? `(${current_value})` : ''} ${OP}`}
            </InputAdornment>
          )}
        />
        {err && (
          <FormHelperText style={{ color: 'red' }}>
            Value should be 0 or a positive number
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

const BoolSelector: React.FC<{
  current_value: string;
  selected_value: string;
  onSelect: (value: boolean) => void;
}> = ({ selected_value, onSelect, current_value }) => {
  const classes = useStyles();
  function _onSelect(e) {
    if (e.target.value === 'true') {
      onSelect(true);
    } else {
      onSelect(false);
    }
  }
  return (
    <FormControl className={classes.formControl} size="small" variant="outlined">
      <Select
        onChange={_onSelect}
        value={selected_value}
        startAdornment={(
          <InputAdornment position="start">
            {`X ${current_value ? `(${current_value})` : ''} `}
          </InputAdornment>
        )}
      >
        <MenuItem value="true">TRUE</MenuItem>
        <MenuItem value="false">FALSE</MenuItem>
      </Select>
    </FormControl>
  );
};

const BetweenValue: React.FC<{
  OP: string;
  selected_value: string;
  onSelect: (value: string) => void;
}> = ({ OP, selected_value, onSelect }) => {
  const classes = useStyles();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(1);

  const [adornments, setAdornments] = useState({ left: '', right: '' });

  useEffect(() => {
    if (selected_value) {
      console.log('SELECTE VALU', selected_value);
      const vals = selected_value.split(' ');
      const min = Number(vals[0]);
      const max = Number(vals[vals.length - 1]);

      setMin(min);
      setMax(max);
    }
  }, []);

  useEffect(() => {
    switch (OP) {
      case OPERATORS.IN_BETWEEN:
        setAdornments({ left: ' < ', right: ' < ' });
        break;
      case OPERATORS.IN_BETWEEN_INCLUSIVE:
        setAdornments({ left: ' <= ', right: ' <= ' });
        break;
      case OPERATORS.IN_BETWEEN_LEFT_INCLUSIVE:
        setAdornments({ left: ' <= ', right: ' < ' });
        break;
      case OPERATORS.IN_BETWEEN_RIGHT_INCLUSIVE:
        setAdornments({ left: ' < ', right: ' <= ' });
        break;
      default:
    }
  }, [OP]);

  function handleChange(type: string, value: any) {
    if (type === 'min') {
      setMin(value);
    } else {
      setMax(value);
    }
  }

  useEffect(() => {
    if (adornments.left && adornments.right) {
      onSelect(`${min} ${adornments.left} X ${adornments.right} ${max}`);
    }
  }, [min, max, adornments]);

  return (
    <Box className={classes.between_root}>
      <FormControl className={classes.formControl} variant="outlined" size="small">
        <InputLabel>Left</InputLabel>
        <OutlinedInput
          label="Value"
          value={min}
          type="Number"
          onChange={(e) => handleChange('min', e.target.value)}
          endAdornment={<InputAdornment position="end">{adornments.left}</InputAdornment>}
        />
      </FormControl>
      <Typography variant="h5" style={{ margin: '0 5px' }}>
        {' '}
        &#119909;
        {' '}
      </Typography>
      <FormControl className={classes.formControl} variant="outlined" size="small">
        <InputLabel>Right</InputLabel>
        <OutlinedInput
          label="Value"
          value={max}
          type="Number"
          onChange={(e) => handleChange('max', e.target.value)}
          startAdornment={<InputAdornment position="start">{adornments.right}</InputAdornment>}
        />
      </FormControl>
    </Box>
  );
};

const ValueSelecotr: React.FC<{
  current_value: string;
  OP: string;
  type: string;
  selected_value: any;
  variable_field_name: string;
  onSelected: (value: any) => void;
}> = ({
  OP, type, selected_value, onSelected, current_value, variable_field_name,
}) => {
  const classes = useStyles();
  function _onSelect(value: any) {
    onSelected(value);
  }
  if (variable_field_name === 'online') {
    return (
      <FormControl className={classes.formControl} size="small" variant="outlined">
        <Select
          onChange={(e) => _onSelect(e.target.value)}
          value={selected_value}
          startAdornment={(
            <InputAdornment position="start">
              {`X ${current_value ? `(${current_value})` : ''} `}
            </InputAdornment>
          )}
        >
          <MenuItem value={1}>ONLINE</MenuItem>
          <MenuItem value={0}>OFFLINE</MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (type === 'Number') {
    if (OP && OP.indexOf('IN_BETWEEN') > -1) {
      return <BetweenValue OP={OP} selected_value={selected_value} onSelect={_onSelect} />;
    }
    return (
      <SingleNumberValue
        current_value={current_value}
        OP={OP}
        selected_value={selected_value}
        onSelect={(value) => _onSelect(Number(value))}
      />
    );
  }

  if (type === 'String') {
    return (
      <SingleValue
        current_value={current_value}
        OP={OP}
        selected_value={selected_value}
        onSelect={_onSelect}
      />
    );
  }

  if (type === 'Boolean') {
    return (
      <BoolSelector
        current_value={current_value}
        selected_value={selected_value}
        onSelect={_onSelect}
      />
    ); // <SingleValue OP={OP} />;
  }

  return (
    <SingleValue
      current_value={current_value}
      OP={OP}
      selected_value={selected_value}
      onSelect={_onSelect}
    />
  );
};

export const ExpressionSelector: React.FC<{
  selecteValue: any;
  selectedOperator: OPERATORS;
  selectedVariable: {field: string; type: string};
  onChange: (operator: OPERATORS, value: any, variable: {field: string; type: string}) => void;
  device: AirDevice | ProDevice;
  dc: DeviceCollectionSearchOutItem;
  behaveAs?: string;
  extraFields?: {
    action: Action;
    isChild: boolean;
    parentAction: Action;
  },
  hideTypeSelector?: boolean;
  onToggleDerivedState?: (currentState: boolean) => void;
}> = ({
  device,
  selectedOperator,
  selecteValue,
  selectedVariable,
  onChange,
  dc,
  behaveAs = 'expression',
  extraFields,
  hideTypeSelector,
  onToggleDerivedState,
}) => {
  const classes = useStyles();

  const [show_animation, setShowAnimation] = useState(false);

  const [firebase_path, setFirebasePath] = useFirebasePathBuilder(
    dc,
    device,
    selectedVariable,
    behaveAs === 'valueAssigner',
  );
  console.log('path', firebase_path);
  useFirebaseConnect(`${firebase_path}`);

  const firebase_value = useSelector((state: RootState) => {
    try {
      const patharray = firebase_path.split('/').filter((item) => item !== '' && item !== ' ');
      if (patharray.length === 5 || patharray.length === 6) {
        if (device && (device.sub_cat === 'knx_switch' || device.sub_cat === 'knx_light')) {
          return `${
            state.firebase.data[patharray[0]][patharray[1]][patharray[2]][patharray[3]][
              patharray[4]
            ].state
          }`;
        }
        if (device && device.sub_cat === 'knx_dimmer') {
          return state.firebase.data[patharray[0]][patharray[1]][patharray[2]][patharray[3]][
            patharray[4]
          ][patharray[5]];
        }
        return state.firebase.data[patharray[0]][patharray[1]][patharray[2]][patharray[3]][
          patharray[4]
        ];
      }
      return null;
    } catch (err) {
      return null;
    }
  });

  useEffect(() => {
    if (firebase_value) setShowAnimation(true);

    function clearAnimation() {
      // if(show_animation)
      setShowAnimation(false);
    }

    const timout = setTimeout(clearAnimation, 2000);

    if (firebase_value == null || behaveAs !== 'variables') return;

    switch (typeof firebase_value) {
      case 'boolean':
        onChange(selectedOperator, selecteValue, {
          ...selectedVariable,
          type: 'Boolean',
        });
        break;
      case 'number':
        onChange(selectedOperator, selecteValue, {
          ...selectedVariable,
          type: 'Number',
        });
        break;
      default:
        onChange(selectedOperator, selecteValue, {
          ...selectedVariable,
          type: 'String',
        });
    }

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timout);
  }, [firebase_value]);

  const [shouldUseDirivedValue, setShouldUseDirivedValue] = useState(() => {
    if (extraFields && extraFields.action) {
      const { action } = extraFields;

      if (action.type === 'device'
      && action.payload
      && (action.payload as any).editorVal) {
        return true;
      }
    }

    return false;
  });

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        {dc && device && dc.dc_type !== 9 && (
          <ExpressionVariableSelector
            device={device}
            selectedVariable={selectedVariable}
            behaveAs={behaveAs}
            hideTypeSelector={hideTypeSelector}
            onVariableChange={(field, type) => {
              onChange(selectedOperator, selecteValue, { field, type });
              // setVariable({field, type});
            }}
          />
        )}
      </Grid>
      {behaveAs === 'valueAssigner' && (
        <Grid item md={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={shouldUseDirivedValue}
                onChange={() => {
                  setShouldUseDirivedValue((last) => {
                    onToggleDerivedState(!last);
                    return !last;
                  });
                }}
                name="gilad"
              />
          )}
            label="Use derived value"
          />
        </Grid>

      )}
      {behaveAs !== 'justVariableSelector' && (
        <>
          {shouldUseDirivedValue && extraFields ? (
            <Grid item md={12}>
              <DirivedValueBuilder
                action={extraFields.action}
                isChild={extraFields.isChild}
                parentAction={extraFields.parentAction}
              />
            </Grid>
          ) : (
            <>
              <Grid item md={4}>
                <FormControl size="small" variant="outlined" className={classes.formControl}>
                  <InputLabel>{behaveAs === 'valueAssigner' ? 'Assign' : 'Operator'}</InputLabel>
                  {behaveAs === 'valueAssigner' ? (
                    <Select
                      label="Assign"
                      value={selectedOperator}
                      onChange={(event: any) => {
                        onChange(event.target.value, selecteValue, selectedVariable);
                        // handleChangeOp(e)
                      }}
                    >
                      <MenuItem value={OPERATORS.EQUAL}>=</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      label="Operator"
                      value={selectedOperator}
                      onChange={(event: any) => {
                        onChange(event.target.value, selecteValue, selectedVariable);
                        // handleChangeOp(event)
                      }}
                    >
                      {selectedVariable.field === 'online' ? (
                        STRING_OP_FOR_HTML_SELECTOR.map((OP, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <MenuItem value={OP.value} key={index}>
                            {OP.label}
                          </MenuItem>
                        ))
                      ) : selectedVariable.type === 'Number' ? (
                        OP_FOR_HTML_SELECTOR.map((OP, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <MenuItem value={OP.value} key={index}>
                            {OP.label}
                          </MenuItem>
                        ))
                      ) : selectedVariable.type === 'String' ? (
                        STRING_OP_FOR_HTML_SELECTOR.map((OP, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <MenuItem value={OP.value} key={index}>
                            {OP.label}
                          </MenuItem>
                        ))
                      ) : selectedVariable.type === 'Boolean' ? (
                        BOOL_OP_FOR_HTML_SELECTOR.map((OP, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <MenuItem value={OP.value} key={index}>
                            {OP.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">Not Selected</MenuItem>
                      )}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={8}>
                <ValueSelecotr
                  current_value={firebase_value != null ? firebase_value.toString() : null}
                  onSelected={(value) => {
                    // console.log(e);
                    onChange(selectedOperator, value, selectedVariable);
                    // handleChangeVal(e)
                  }}
                  selected_value={selecteValue}
                  OP={selectedOperator}
                  type={selectedVariable.type}
                  variable_field_name={selectedVariable.field}
                />
                {firebase_value != null && (
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '45px',
                  }}
                >
                  {show_animation && <div className="pulsating-circle" />}
                  Current Value &nbsp;
                  <span style={{ fontWeight: 700 }}>
                    {firebase_value != null ? ` ${firebase_value.toString()}` : ' <UNKNOWN>'}
                  </span>
                </div>
                )}
              </Grid>
            </>
          )}
        </>

      )}
    </Grid>
  );
};

ExpressionSelector.defaultProps = {
  behaveAs: 'expression',
};
