/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';
import { IDFField } from '../../../../../config/deviceConfig/types';

const FIELD_NAME = ITEM_DEFAULTS.ITEM_CREATOR.DEVICE_FIELDS.json_field;

type ComponentProps = {
  fieldConfig: IDFField;
  // eslint-disable-next-line react/no-unused-prop-types
  deviceInfo: any;
};
interface IformData {
  debounce: number;
}
// eslint-disable-next-line import/prefer-default-export
export const ProDeviceFieldJsonField: React.FunctionComponent<ComponentProps> = (props) => (
  <div className="col-md-4 json-field-card-parent">
    <div className="json-field-card">
      Data Field:
      {props.fieldConfig.labels}
    </div>
  </div>
);
