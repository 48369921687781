import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Box,
  InputLabel,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CronIntervalVariables, TIME_FRAME } from '@smartlife-redux-state/common';
import moment from 'moment';
import { useState } from 'react';
import { FromToHourList, ToHourIndexVaidator } from './types';

const useClasses = makeStyles({
  formRoot: {
    width: '100%',
  },
});

// eslint-disable-next-line import/prefer-default-export
export const CronInterval: React.FC<{
  variables: CronIntervalVariables;
  onChange: (variables: CronIntervalVariables) => void;
}> = ({ variables, onChange }) => {
  const canVisibleTo = (fromH: number, fromM: string, toHM: string) => {
    const idx1 = ToHourIndexVaidator.indexOf(`${fromH} ${fromM}`);
    const idx2 = ToHourIndexVaidator.indexOf(toHM);
    return idx1 < idx2 || toHM === '12 AM';
  };

  const from24Val = moment(`${variables.range.from.value} ${variables.range.from.meridiem}`, ['h A']);
  const to24Val = moment(`${variables.range.to.value} ${variables.range.to.meridiem}`, ['h A']);

  const timeRangeInUnix = to24Val.unix() - from24Val.unix();

  const [customRange, setCustomRange] = useState(
    variables
      && variables.range
      && variables.range.from
      && (variables.range.from.value > 0 || variables.range.to.value > 0)
      && timeRangeInUnix > 0,
  );

  const classes = useClasses();
  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item md={3} sm={12} xs={12}>
            <Typography>Run Once in Every</Typography>
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <TextField
              size="small"
              label="Frequency"
              variant="outlined"
              type="number"
              value={variables.value}
              onChange={(event) => onChange({
                based_on: variables.based_on,
                value: Number(event.target.value),
                range: {
                  ...variables.range,
                },
              })}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <FormControl size="small" variant="outlined" className={classes.formRoot}>
              <InputLabel>hr/min</InputLabel>
              <Select
                label="hr/min"
                onChange={(event: any) => {
                  onChange({
                    // ...variables,
                    based_on: event.target.value,
                    value: variables.value,
                    range: {
                      ...variables.range,
                    },
                  });
                }}
                value={variables.based_on || 'h'}
              >
                <MenuItem value="h">Hour</MenuItem>
                <MenuItem value="m">Minute</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={customRange}
                      onChange={(e) => {
                        if (customRange) {
                          onChange({
                            based_on: variables.based_on,
                            value: variables.value,
                            range: {
                              from: {
                                value: 12,
                                meridiem: TIME_FRAME.AM,
                              },
                              to: {
                                value: 12,
                                meridiem: TIME_FRAME.AM,
                              },
                            },
                          });
                        }
                        setCustomRange(!customRange);
                      }}
                    />
                  )}
                  label="Between"
                />
              </Grid>
              <Grid item md={9}>
                {variables && variables.range && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={5}>
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          disabled={!customRange}
                          size="small"
                          className={classes.formRoot}
                        >
                          <InputLabel>From</InputLabel>
                          <Select
                            value={`${variables.range.from.value} ${variables.range.from.meridiem}`}
                            label="From"
                            onChange={(e) => {
                              const timeInput = `${e.target.value}`;
                              const nValue = +timeInput.split(' ')[0];
                              const ampmValue: string = timeInput.split(' ')[1];

                              let ToValInd = FromToHourList.indexOf(timeInput);
                              if (ToValInd < FromToHourList.length - 1) {
                                ToValInd += 1;
                              }
                              onChange({
                                based_on: variables.based_on,
                                value: variables.value,
                                range: {
                                  from: {
                                    value: nValue,
                                    meridiem: ampmValue === 'AM' ? TIME_FRAME.AM : TIME_FRAME.PM,
                                  },
                                  to: {
                                    value: +FromToHourList[ToValInd].split(' ')[0],
                                    meridiem:
                                      FromToHourList[ToValInd].split(' ')[1] === 'AM'
                                        ? TIME_FRAME.AM
                                        : TIME_FRAME.PM,
                                  },
                                },
                              });
                            }}
                          >
                            {FromToHourList.map((item, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} md={2}>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        -
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid item>
                          <FormControl
                            variant="outlined"
                            disabled={!customRange}
                            size="small"
                            className={classes.formRoot}
                          >
                            <InputLabel>To</InputLabel>
                            <Select
                              value={`${variables.range.to.value} ${variables.range.to.meridiem}`}
                              label="To"
                              onChange={(e) => {
                                const timeInput = `${e.target.value}`;
                                const nValue = +timeInput.split(' ')[0];
                                const ampmValue: string = timeInput.split(' ')[1];

                                let ToValInd = FromToHourList.indexOf(timeInput);
                                if (ToValInd < FromToHourList.length - 1) {
                                  ToValInd += 1;
                                }
                                onChange({
                                  based_on: variables.based_on,
                                  value: variables.value,
                                  range: {
                                    from: variables.range.from,
                                    to: {
                                      value: nValue,
                                      meridiem: ampmValue === 'AM' ? TIME_FRAME.AM : TIME_FRAME.PM,
                                    },
                                  },
                                });
                              }}
                            >
                              {FromToHourList.map((item, index) => (
                                <MenuItem
                                  value={item}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  disabled={
                                      !canVisibleTo(
                                        variables.range.from.value,
                                        variables.range.from.meridiem,
                                        item,
                                      )
                                    }
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
