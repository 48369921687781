import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Tag } from "src/plugins/shared/uvitem.config";

const tags: Record<'tags1' | 'tags2', Record<string, Tag>> = {
    tags1: {
        temp_tag: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'temp_tag',
            description: 'Temperature [f = (x(Celcius) X 1) Celcius]',
            display_suffix: 'Celcius', 
            config: {
              scale: 1,
              decimal_points: 1,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
    tags2: {
        temp_tag: {
            type: COMPONENT_UNIT_TYPES.Sensor,
            tag: 'temp_tag',
            description: 'Temperature [f = (x(Fahrenheit) X 1) Fahrenheit]',
            display_suffix: 'Fahrenheit', 
            config: {
              scale: 1,
              decimal_points: 1,
            },
            main_key: null,
            command_key: null,
            can_send_command: false,
            command_v: 'v1',
        },
    },
}

export default tags;