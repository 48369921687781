import { gql } from '@apollo/client';

export const gqlSearchAirDevices = gql`
  query airDeviceSearch(
    $project_id: String
    $adc_id: String
    $tags: [String]
    $keyword: String
    $limit: Int
    $skip: Int
  ) {
    airDeviceSearch(
      project_id: $project_id
      adc_id: $adc_id
      tags: $tags
      display_type: -1
      device_type: -1
      device_status: -1
      notify_status: -1
      keyword: $keyword
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        created_by
        project_id
        airdc_id
        tuya_device_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        device_type
        device_status
        settings
        config
        created_at
        updated_at
      }
    }
  }
`;

export const gqlAirDCTuyaDeviceListByUsername = gql`
  query AirDCTuyaDeviceListByUsername($username: String, $password: String, $country_code: String) {
    airDCTuyaDeviceListByUsername(
      username: $username
      password: $password
      country_code: $country_code
    ) {
      items {
        active_time
        biz_type
        category
        create_time
        icon
        id
        ip
        local_key
        model
        name
        node_id
        online
        owner_id
        product_id
        product_name
        status
        sub
        time_zone
        uid
        update_time
        uuid
      }
    }
  }
`;
