/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Editor from '@monaco-editor/react';
import { useRef, useState, useEffect } from 'react';
import dedent from 'dedent';
import { isArray } from 'lodash';
import { useFirebaseConnect } from 'react-redux-firebase';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from '@smartlife-redux-state/common';
import { update_action } from 'src/store/redux/features/logic-engine';
import { AddVariable } from './shared/AddVariable';
import { RootState } from '../../../../../../../store/redux/store';

const VarItem: React.FC<{item: any; arr_index: number}> = ({ item, arr_index }) => {
  const firebase_path = item ? item.firebase_path : null;
  console.log('FIREBASE PATH', firebase_path);
  useFirebaseConnect(item ? firebase_path : null);
  const firebase_value = useSelector((state: RootState) => {
    try {
      const patharray = firebase_path
        .split('/')
        .filter((parthPart) => parthPart !== '' && parthPart !== ' ');
      if (patharray.length === 5) {
        return state.firebase.data[patharray[0]][patharray[1]][patharray[2]][patharray[3]][
          patharray[4]
        ];
      }
      return null;
    } catch (err) {
      return null;
    }
  });

  return (
    <>
      <Typography variant="subtitle2">
        Access Name =
        {item.name}
      </Typography>
      {/* <Typography variant="body1">{item.name}</Typography> */}
      <hr style={{ margin: '5px' }} />
      {firebase_value ? (
        <FormHelperText>
          VALUE =
          {' '}
          {firebase_value}
          {' '}
          / TYPE =
          {' '}
          {typeof firebase_value}
        </FormHelperText>
      ) : item && item.static_value ? (
        <FormHelperText>
          VALUE =
          {' '}
          {item.static_value}
          {' '}
          / TYPE =
          {' '}
          {typeof item.static_value}
        </FormHelperText>
      ) : (
        <FormHelperText>VALUE = null / TYPE = null</FormHelperText>
      )}
      <div style={{ display: 'none' }}>
        <Editor
          theme="vs-dark"
          height="0"
          defaultLanguage="javascript"
          value={dedent`
            /** 
             * Last Value ${item.static_value ? item.static_value : firebase_value}
             * @type {${item.static_value ? typeof item.static_value : typeof firebase_value}}
            */
            let ${item.name} = ${item.static_value ? item.static_value : firebase_value};
          `}
        />
      </div>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const AdvancedFirebaseAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const dispatch = useDispatch();
  const [runFeedback, setRunFeedback] = useState<any>();
  const monacoRef = useRef(null);
  let monacoNative = null;
  function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
  }

  function handleEditorDidMount(editor, monaco) {
    monacoRef.current = editor;
    monacoNative = monaco;
  }

  const [items, setItems] = useState([]);
  function parseItems() {
    try {
      const parsed = JSON.parse(action.variable);
      if (isArray(parsed)) {
        console.log('XXX', parsed);
        setItems(parsed);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  useEffect(() => {
    if (action.variable) {
      parseItems();
    }
  }, [action]);

  function showValue() {
    const editorVal = action.payload && (action.payload as any).editorVal ? (action.payload as any).editorVal : '';
    const lhs = editorVal.split('=')[0];

    const rhs = editorVal.split('=')[1];
    if (!rhs) {
      setRunFeedback({
        msg: 'The right side of the expression is not found',
        code: 'error',
      });
      return;
    }

    const found = items.find((item) => item.name === lhs.trim());

    if (!found) {
      setRunFeedback({
        msg: 'The left side of the expression is not found',
        code: 'error',
      });
      return;
    }

    if (!found.firebase_path) {
      setRunFeedback({
        msg: 'The left side of the expression must be a dynamic variable.',
        code: 'error',
      });
      return;
    }

    setRunFeedback({
      msg: 'The expression is valid',
      code: 'success',
    });
  }

  function handleEditorChange(value, event) {
    // monacoRef.current.setModelMarkers(monacoRef.current.getModel(), 'test', [
    //   {
    //     startLineNumber: 2,
    //     startColumn: 1,
    //     endLineNumber: 2,
    //     endColumn: 1000,
    //     message: 'a message',
    //     severity: monacoNative ? monacoNative.Severity.Warning : null,
    //   },
    // ]);
    dispatch(
      update_action({
        action: {
          ...action,
          payload: {
            editorVal: value,
          } as any,
        },
        isChild,
        parentAction,
      }),
    );
    console.log('here is the current model value:', value);
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Box>{runFeedback && <Alert severity={runFeedback.code}>{runFeedback.msg}</Alert>}</Box>
        <Box height={10} />
        <Grid container>
          {items.map((item, index) => (
            <Grid
              item
              md={3}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                backgroundColor: 'white',
                padding: '1%',
                marginBottom: '5px',
              }}
            >
              <VarItem item={item} key={index} arr_index={index} />
            </Grid>
          ))}
          <Box height={5} />
          <AddVariable action={action} isChild={isChild} parentAction={parentAction} />
        </Grid>
      </Box>
      <Box height={10} />
      <Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontWeight: '700', marginBottom: 0 }}>
            Build Your Expression
          </Typography>
          <Tooltip title="Test your expression">
            <IconButton onClick={showValue} size="small">
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box height={10} />
        <Editor
          theme="vs-dark"
          height="5vh"
          width="90%"
          defaultLanguage="javascript"
          value={dedent`
              ${
                action.payload && (action.payload as any).editorVal
                  ? (action.payload as any).editorVal
                  : ''
              }
          `}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
        />
      </Box>
      <Box height={10} />
      <Box>
        <Typography style={{ fontWeight: '700' }}>Instruction</Typography>
        <ol>
          <li>Add dynamic and static variables</li>
          <li>Use them to build an expression</li>
          <li>The left side of the expression must be a single dynamic variable</li>
          <li>You can use (), +, -, *, / in the expression</li>
          <li>The expression should build according to BODMAS rule</li>
          <li>The system does not support for powers of variables</li>
        </ol>
      </Box>
    </Box>
  );
};

// /**
//  * Here you can write an expression to
//  * calculate and assign the
//  * value to the given variable.
//  *
//  * Once you add some variable
//  * to the left side, you can use
//  * those variables to create an expression.
//  *
//  * Example Expression:
//  *      var1 = brightVal + ( led + loc_var1 ) / 25
//  *
//  * You can use the varibales from the left
//  * list and also you can use
//  * prmitive values such as numbers
//  *
//  */
