import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { CLOUD_INTEGRATIONS } from 'src/plugins/shared/services/cloud-integration.service';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { useNavigate } from 'react-router';
import { Formik, FormikHelpers } from 'formik';
import ShellyIntegration, { iShellyIntegration } from '../shelly.service';
import IntegratorsSpace from '../external/IntegratorsSpace';
import parseCustomApiError from 'src/utility/parseError';
import useIsSysAdmin from 'src/utility/CustomHooks/useIsSysAdmin';
import { DeviceContainerFormLayout } from 'src/plugins/shared/react-components/DeviceContainerFormLayout';

const useStyles = makeStyles({
  root: {
    paddingTop: 30,
  },
  formControl: {
    width: '100%',
  },
  textArea: {
    backgroundColor: '#F9F9F9',
    border: '1px solid #D9DFE0',
    borderRadius: 5,
    paddingLeft: 29,
    paddingRight: 29,
    paddingTop: 13,
    paddingBottom: 13,
  },
  btnContainer: {
    display: 'flex',
    paddingTop: 20,
  },
});

/* eslint-disable camelcase */
const CreateShellyDeviceContainer: React.FC<{
  backup_view: boolean;
  onSuccessCreate?: (type: 'shelly', created: any) => void;
  integration: ShellyIntegration;
}> = ({ integration }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isSysAdmin = useIsSysAdmin();

  const project = useContext(ProjectContext);

  const [shellyAccEmail, setShellyAccEmail] = useState('');
  const [working, setWorking] = useState(false);
  // https://my.shelly.cloud/integrator.html?itg=ITG_SLL&cb=https://smartlife-pro2-develop.ts.r.appspot.com/rest/cloud-int/shelly/v1/int-cb/nLGt3TZWw60Ly
  const [shellyUrl, setShellyUrl] = useState('');
  const dcId = useRef('');

  const [cbPath, setCbPath] = useState(null);

  const sendShellyUrlRequest = async (dcId: string) => {
    try {
      const res = await integration.getShellyDeviceManagementUrl(dcId);
      setShellyUrl(res.result.url);
    } catch (err) {
      enqueueSnackbar('Failed to get shelly url', { variant: 'error' });
    }
  };

  const onContinue = async (
    values: {
      email: any;
      displayName: any;
      description: any;
  },
  ) => {
    try {
      setWorking(true);
      const res = await integration.createDeviceCollection({
        project_id: project.selected_project.id,
        name: values.displayName,
        description: values.description,
        ext_username: values.email,
        cint_type: CLOUD_INTEGRATIONS.SHELLY,
      });
      sendShellyUrlRequest(res.result.id);
      setShellyAccEmail(values.email);
      dcId.current = res.result.id;
    } catch (err: any) {
      if (err.response && err.response.data && isSysAdmin) {
        const msg = parseCustomApiError(err.response && err.response.data);
        enqueueSnackbar(msg, { variant: 'error' });
        return;
      }
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setWorking(false);
      setCbPath(`/app/device-container/cloud-integration?projectId=${project.selected_project.id}&dcId=${dcId.current}&name=${values.displayName}`)
    }
  };

  return (
    <DeviceContainerFormLayout title="Shelly Integration">
      {shellyUrl ? (
        <IntegratorsSpace
          accountEmail={shellyAccEmail}
          integration={integration}
          src={shellyUrl}
          onSuccessCallback={() => {
            if (cbPath)
              navigate(cbPath);
          }}
        />
      ) : (
        <Formik
          initialValues={{
            email: null,
            displayName: null,
            description: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required('Shelly Account Email Is Required.'),
            displayName: Yup.string().required('Display Name is required'),
            description: Yup.string(),
          })}
          onSubmit={(values) => {
            onContinue(values)
          }}
        >
          {({
            errors, values, handleChange, touched, handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid className={styles.root} container spacing={2}>
                <Grid item md={7} sm={12}>
                  <FormControl className={styles.formControl}>
                    <TextField
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      label="Shelly Account Email"
                      type="email"
                      placeholder="Shelly Account Email"
                      size="medium"
                      helperText="* Provide the email of the shelly account you are going to integrate into this container."
                      error={Boolean(touched.email && errors.email)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={7} sm={12}>
                  <FormControl className={styles.formControl}>
                    <TextField
                      name="displayName"
                      value={values.displayName}
                      onChange={handleChange}
                      label="Display Name"
                      placeholder="Display Name"
                      error={Boolean(touched.displayName && errors.displayName)}
                      size="medium"
                    />
                  </FormControl>
                </Grid>
                <Grid item md={7} sm={12}>
                  <FormControl className={styles.formControl}>
                    <TextareaAutosize
                      value={values.description}
                      name="description"
                      onChange={handleChange}
                      className={styles.textArea}
                      aria-label="Description"
                      placeholder="Description"
                      minRows={5}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box className={styles.btnContainer}>
                <Button type="submit" variant="contained" color="primary" disabled={working}>
                  Continue
                </Button>
                <Box width={15} />
                <Button
                  style={{ color: '#7F8FA4', backgroundColor: '#F9F9F9' }}
                  variant="outlined"
                  color="inherit"
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </DeviceContainerFormLayout>
  );
};

export default CreateShellyDeviceContainer;
