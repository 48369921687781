/* eslint-disable camelcase */
import { CommonUiAuthProvider } from '@smartlife/common-ui';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';

const ListProvider: React.FC<{children: any}> = ({ children }) => {
  // eslint-disable-next-line camelcase
  const { access_token } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const project = useContext(ProjectContext);

  const axiosClient = useMemo(
    () => axios.create({
      baseURL: `${process.env.REACT_APP_APOLLO_SERVER_URL.split('/graphql')[0]}/rest`,
      headers: {
        Authorization: `Bearer ${access_token.token}`,
      },
    }),
    [access_token],
  );

  const values = useMemo(
    () => ({
      gqlClient: null,
      axiosClient,
      projectId: project.selected_project.id,
    }),
    [axiosClient, project],
  );

  return (
    <CommonUiAuthProvider
      axiosClient={axiosClient as any}
      projectId={project.selected_project.id}
      showSnackbar={enqueueSnackbar as any}
    >
      {children}
    </CommonUiAuthProvider>
  );
};

export default ListProvider;
