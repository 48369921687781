import React, { useContext } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { ListItem, Button, Hidden } from '@mui/material';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { AuthContext } from 'src/store/Auth/AuthContext';
import { useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/redux/store';
import { NAV_LINK_VISIBILITY_LEVEL } from '.';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    padding: 0,
  },
  tabItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabButton: {
    color: 'gray', // theme.palette.text.secondary,
    fontWeight: '500', // theme.typography.fontWeightMedium,
    // justifyContent: 'flex-start',
    letterSpacing: 0,
    // padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    // padding: '8%',
    // borderBottom: '2px solid var(--green-10)',
    '&:focus': {
      outline: 0,
    },
  },
  button: {
    color: 'gray', // theme.palette.text.secondary,
    fontWeight: '500', // theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    // padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    padding: '8%',
    borderBottom: '2px solid var(--green-10)',
    '&:focus': {
      outline: 0,
    },
  },
  tanIcon: {
    marginRight: 0,
  },
  icon: {
    marginRight: 10, // theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    fontSize: '14px !important',
    padding: '0 !important',
    margin: '0px !important',
  },
  active: {
    background: 'var(--green-10)',
    color: 'var(--green-90)', // theme.palette.primary.main,
    '& $title': {
      fontWeight: '500', // theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: 'var(--green-90)', // theme.palette.primary.main,
    },
  },
}));

const NavItem: React.FC<any> = ({
  className, href, icon: Icon, title, withProjectId, visibilityLevel, ...rest
}) => {
  const classes = useStyles();
  const selectedProject = useContext(ProjectContext);
  const auth = useContext(AuthContext);
  const projectId = useQueryStringFirst('projectId');
  const navigateRoute = useNavigate();

  useFirebaseConnect(`system_admins/${auth.uid}`);

  const isSystemAdmin = useSelector(
    (store: RootState) => !!(store.firebase.data.system_admins && store.firebase.data.system_admins[auth.uid]),
  );

  if (visibilityLevel === NAV_LINK_VISIBILITY_LEVEL.SUPER_ADMIN && !isSystemAdmin) return null;

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Button
        // activeClassName={classes.active}
        disabled={withProjectId && (!projectId || projectId.length === 0)}
        className={clsx([classes.button, window.location.pathname === href ? classes.active : ''])}
        onClick={() => {
          let link = href;

          if (
            selectedProject
            && selectedProject.selected_project
            && selectedProject.selected_project.id
          ) {
            link += `?projectId=${selectedProject.selected_project.id}`;
          }
          navigateRoute(link);
        }}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

export const TabNavItem: React.FC<any> = ({
  className,
  href,
  icon: Icon,
  withProjectId,
  visibilityLevel,
  ...rest
}) => {
  const classes = useStyles();
  const selectedProject = useContext(ProjectContext);
  const auth = useContext(AuthContext);
  const navigateRoute = useNavigate();
  const projectId = useQueryStringFirst('projectId');

  useFirebaseConnect(`system_admins/${auth.uid}`);

  const isSystemAdmin = useSelector(
    (store: RootState) => !!(store.firebase.data.system_admins && store.firebase.data.system_admins[auth.uid]),
  );

  if (visibilityLevel === NAV_LINK_VISIBILITY_LEVEL.SUPER_ADMIN && !isSystemAdmin) return null;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItem className={clsx(classes.tabItem, className)} disableGutters {...rest}>
      <Button
        // activeClassName={classes.active}
        disabled={withProjectId && (!projectId || projectId.length === 0)}
        className={clsx([
          classes.tabButton,
          window.location.pathname === href ? classes.active : '',
        ])}
        onClick={() => {
          let link = href;

          if (withProjectId && selectedProject && selectedProject.selected_project) {
            link += `?projectId=${selectedProject.selected_project.id}`;
          }
          navigateRoute(link);
        }}
      >
        {Icon && <Icon className={classes.tanIcon} size="20" />}
      </Button>
    </ListItem>
  );
};

export default NavItem;
