/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  Button,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { AuthContext } from 'src/store/Auth/AuthContext';
import AIR_COUNTRY_LIST from 'src/config/airDcCountryList';
import {
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './CreateEditDeviceContainer.module.css';
import { MutateAirDcCreateNewAccount } from '../../../../services/device-container/air-device-container.service';

type IAirDCFormValue = {
  name: string;
  description: string;
  adc_type: number;
  country_code: string;
};
type ComponentProps = {
  matStyles: any;
  isBackup?: boolean;
};
const AirDcCreateNewAccount: React.FunctionComponent<ComponentProps> = ({ matStyles, isBackup }) => {
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const auth = useContext(AuthContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  const handleCreate = async (
    values: IAirDCFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    setLoading(true);
    try {
      if (!selectedProject.selected_project.id) {
        throw new Error('Please select project');
      }
      const res = await MutateAirDcCreateNewAccount(userProfile?.apollo_client, {
        ...values,
        project_id: selectedProject.selected_project.id,
        // country_code:
        //   selectedProject.selected_project && selectedProject.selected_project.country_code_num
        //     ? selectedProject.selected_project.country_code_num
        //     : '64',
        adc_status: 1,
      });

      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR__CREATE_SUCCESS, {
        key: 'key1765',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });

      resetForm();
      if (!isBackup) {
        if (res.airDCAccountCreate) {
          navigateRoute(
            `/app/device-container/air?projectId=${selectedProject.selected_project.id}&dcId=${res.id}&name=${res.name}`,
          );
          return;
        }
        navigateRoute(`/app/device-containers?projectId=${selectedProject.selected_project.id}`);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_AIR__CREATE_ERROR, {
        key: 'key361a',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <Formik
        onSubmit={handleCreate}
        initialValues={{
          name: '',
          adc_type: 1,
          description: '',
          country_code:
            selectedProject.selected_project && selectedProject.selected_project.country_code_num
              ? selectedProject.selected_project.country_code_num
              : '64',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required('Please enter an air collection name.').min(3),
          adc_type: Yup.string().required('Required'),
        })}
      >
        {({
          errors, handleBlur, handleChange, touched, values, submitForm,
        }) => (
          <form>
            <div className="custom-card2-body mt-2">
              <div className="m-0 col-md-12 nopaddingOnly row">
                <div className="col-md-12 nopaddingOnly">
                  <div className="col-md-6 nopaddingOnly">
                    <div>
                      <TextField
                        className={styles.fieldSpace}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        label={APP_TEXT.device_container.FORM_FIELD_NAME}
                        size="small"
                        type="text"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" size="large" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <TextField
                      className={styles.fieldSpace}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      name="description"
                      label="Description"
                      size="medium"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" size="large" />
                          </InputAdornment>
                        ),
                      }}
                      data-testid=""
                    />
                    <FormControl className={`${matStyles.formControl}`} size="small">
                      <InputLabel id="demo-simple-select-label">Device Type</InputLabel>
                      <Select
                        variant="outlined"
                        label="Device Type"
                        onChange={handleChange}
                        name="adc_type"
                        value={values.adc_type}
                      >
                        <MenuItem value={1}>Real</MenuItem>
                        <MenuItem value={8}>Demo</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={`${matStyles.formControl}`} size="small">
                      <InputLabel id="demo-simple-select-label">Country</InputLabel>
                      <Select
                        variant="outlined"
                        label="Country"
                        onChange={handleChange}
                        name="country_code"
                        value={values.country_code}
                      >
                        {Object.keys(AIR_COUNTRY_LIST).map((groupCode) => (
                          AIR_COUNTRY_LIST[groupCode].map((country) => <MenuItem value={country.code} key={country.code}>{country.english}</MenuItem>)
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>

            <div className="custom-card2-body">
              <div className="col-md-4 nopaddingOnly">
                {/* <GreenButton
                  text={APP_TEXT.device_container.ADD_AIR_DEVICE_CONTAINER_BTN}
                  onClick={submitForm}
                  disable={loading}
                  data-testid="submitButton"
                >
                  {loading ? (
                    <LoadingComponent></LoadingComponent>
                  ) : (
                    <ArrowForward></ArrowForward>
                  )}
                </GreenButton> */}
                <Button
                  endIcon={<ArrowForward />}
                  onClick={submitForm}
                  disabled={loading}
                  color="primary"
                  variant="contained"
                >
                  {APP_TEXT.device_container.ADD_AIR_DEVICE_CONTAINER_BTN}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

AirDcCreateNewAccount.defaultProps = {
  isBackup: false,
};

export default AirDcCreateNewAccount;
