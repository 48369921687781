/* eslint-disable camelcase */
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import {
  Action,
  AirDevice,
  DeviceCollectionSearchOutItem,
  LOGIC_CARD_VISIBILITY,
  ProDevice,
} from '@smartlife-redux-state/common';
import { useDispatch } from 'react-redux';
import {
  update_action,
  update_check,
} from '../../../../../../../store/redux/features/logic-engine';
import { LogicStepsTypes } from '../../../LogicStepper';
import { DeviceSelector } from '../shared/DeviceSelector';

// location variables path -> devices/LocVarCol_{project_id}/

export interface LocationVariables {
  [variable_id: string]: LocationVariable;
}

export interface LocationVariable {
  info: {
    dc_id: string;
    dc_type: string;
    id: string;
    name: string;
    project_id: string;
    tags: {
      [key: number]: string;
    };
  };
  uv_items: {
    [uv_id: string]: {
      [uv_item: string]: {
        display_name: string;
        id: string;
        item_notification_type: number;
        item_type: string;
        macro_name: string;
        rule_name: string;
        uv_id: string;
        uv_name: string;
      };
    };
  };
}

// location variables values -> shadow_in/LocVarCol_{project_id}/1/
export interface LocationVaraibleShadowIn {
  [key: string]: {
    [cluster_id: number]: {
      [varible_id: string]: {
        name: string;
        state: any;
        timestamp: number;
      };
    };
  };
}

export const FirebaseAction: React.FC<{
  action: Action;
  isChild: boolean;
  parentAction: Action;
}> = ({ action, isChild, parentAction }) => {
  const dispatch = useDispatch();

  function onLocationVaribleContainer(dc: DeviceCollectionSearchOutItem) {
    dispatch(
      update_action({
        action: {
          ...action,
          dc,
        },
        isChild,
        parentAction,
      }),
    );
  }

  function onLocationVariable(device: ProDevice | AirDevice) {
    const settings = device ? JSON.parse(device.settings) : null;
    const var_type = settings ? settings.item_type : null;

    let type = null;
    switch (var_type) {
      case 'numeric_variable':
        type = 'Number';
        break;
      case 'string_variable':
        type = 'String';
        break;
      case 'boolean_variable':
        type = 'Boolean';
        break;
      default:
        type = 'String';
      // throw {message: "Variable type not supported"}
    }

    dispatch(
      update_action({
        action: {
          ...action,
          device,
          possible_variable_type: type,
          variable_value: null,
          meta: {
            ...action.meta,
            is_enabled: true,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
          },
          // since the location varibles only have numeric values, and state
          // set it when selecting the varible. No need to use <ExpressionVaribaleSelector />
        },
        isChild,
        parentAction,
      }),
    );
  }

  function onValueChange(value: string) {
    dispatch(
      update_action({
        action: {
          ...action,
          variable_value: value,
        },
        isChild,
        parentAction,
      }),
    );
  }

  function onTypeChange(type: string) {
    dispatch(
      update_action({
        action: {
          ...action,
          possible_variable_type: type,
        },
        isChild,
        parentAction,
      }),
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DeviceSelector
          selectedDC={action ? action.dc : null}
          selectedDevice={action ? action.device : null}
          onDcSelect={onLocationVaribleContainer}
          onDeviceSelect={onLocationVariable}
          behaveAs="variables"
          logic_step={LogicStepsTypes.ACTION}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
          <InputLabel>Value Type</InputLabel>
          <Select
            label="Value Type"
            value={action.possible_variable_type}
            onChange={(e: any) => {
              onTypeChange(e.target.value);
            }}
          >
            <MenuItem value="Number">Number</MenuItem>
            <MenuItem value="String">String</MenuItem>
            <MenuItem value="Boolean">Boolean</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        {action.possible_variable_type === 'Number' || action.possible_variable_type === 'String' ? (
          <FormControl size="small" variant="outlined" style={{ width: '100%' }}>
            <InputLabel>Value</InputLabel>
            <OutlinedInput
              label="Value"
              value={action.variable_value}
                // type="Number"
              type={action.possible_variable_type === 'Number' ? 'number' : 'string'}
              onChange={(e: any) => {
                onValueChange(e.target.value);
              }}
              onKeyDown={(evt) => {
                // eslint-disable-next-line no-unused-expressions
                if (action.possible_variable_type === 'Number') { ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault(); }
              }}
              startAdornment={(
                <InputAdornment position="start">
                  {action && action.device ? `${action.device.name} = ` : 'variable'}
                </InputAdornment>
                )}
            />
          </FormControl>
        ) : (
          <FormControl style={{ width: '100%' }} size="small" variant="outlined">
            <InputLabel>Value</InputLabel>
            <Select
              label="Value"
              onChange={(e: any) => onValueChange(e.target.value)}
              value={action.variable_value}
              startAdornment={<InputAdornment position="start">{'X = '}</InputAdornment>}
            >
              <MenuItem value="true">TRUE</MenuItem>
              <MenuItem value="false">FALSE</MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};
