/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/**
 * Copyright (C) Smartlife 2021 The Smartlife Pro2 Project
 *
 * Recursive React component which render a given nested structure.
 * in our case the user views and their items/ nested folders etc.
 *
 * @author Maduka Dilshan
 */

import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@mui/material';
import FolderContent from './FolderContent';
import UserViewService from '../../../../../../service/uv.service';
import { ApolloAuthContext } from '../../../../../../../../store/Apollo/ApolloContext';
import { useSelectedLinkedContext } from './SelectedLinkContext';
import { appConsoleLog } from '../../../../../../../../utility/appUtils';

// eslint-disable-next-line camelcase
const Folder: React.FC<any> = ({ current_root, parent_root, uv }) => {
  const userProfile = useContext(ApolloAuthContext);
  const { setSelected } = useSelectedLinkedContext();
  const [childs, setChilds] = useState<any[]>([]);

  useEffect(() => {
    const getFolderItems = async () => {
      try {
        const res = await UserViewService.userviewListFolderItems(
          userProfile.apollo_client,
          uv.id,
          current_root,
          100,
          0,
        );
        // console.log(res);
        if (!res) throw new Error('Failed to load items');
        const data = res.items.result;
        setChilds(data);
      } catch (err) {
        appConsoleLog(err);
      }
    };
    getFolderItems();
  }, []);

  const onItemClick = (
    type: number,
    uv: any,
    current_root: number,
    parent_id: number,
    child: any,
  ) => {
    setSelected({
      item: child,
      uv,
      folder_id: current_root,
      parent_id,
      type,
    });
  };

  if (!childs) return <div>No Childs</div>;
  return (
    <div>
      {childs.map((child) => {
        if (child.item_cat === 1) {
          // item_cat is the real cat type of use for identify items.
          // ( Not the enum SELECTABLE_TYPES )
          // UV item is a device
          // uv item
          return (
            <div>
              <Button
                style={{
                  marginLeft: '10px',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
                onClick={() => onItemClick(1, uv, child.id, parent_root, child)}
              >
                <span
                  className={`calc ${child.icon_name}`}
                  style={{
                    marginRight: '5px',
                    fontSize: '1.2rem',
                    color: 'white',
                    background: 'var(--light-green)',
                    padding: '2%',
                    borderRadius: '5px',
                  }}
                />
                {child.display_name}
              </Button>
            </div>
          );
        }
        if (child.item_cat === 2) {
          // UV item is a sub folder
          return (
            <FolderContent
              uv={uv}
              current_root={child.id}
              parent_root={current_root}
              child={child}
              onClickItem={onItemClick}
            >
              {/* eslint-disable-next-line max-len */}
              {({ uv, current_root, parent_root }: any) => <Folder current_root={current_root} uv={uv} parent_root={parent_root} />}
            </FolderContent>
          );
        }
        if (child.item_cat === 8) {
          return (
            <div>
              <Button
                style={{
                  marginLeft: '10px',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
                onClick={() => onItemClick(8, uv, child.id, parent_root, child)}
              >
                <span
                  className={`calc ${child.icon_name}`}
                  style={{
                    marginRight: '5px',
                    fontSize: '1.2rem',
                    color: 'white',
                    background: 'var(--light-green)',
                    padding: '2%',
                    borderRadius: '5px',
                  }}
                />
                {child.display_name}
              </Button>
            </div>
          );
        }

        return <></>;
      })}
    </div>
  );
};

export default Folder;
