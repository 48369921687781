/* eslint-disable camelcase */
import {
  Box, FormControl, Grid, InputLabel, MenuItem, Select, Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CheckTypes, checkTypesLabels, LOGIC_CARD_VISIBILITY } from '@smartlife-redux-state/common';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { update_check } from '../../../../../../store/redux/features/logic-engine';
import { RootState, useSelector } from '../../../../../../store/redux/store';
import { DateCheck } from './checks/DateCheck';
import { DeviceCheck } from './checks/DeviceCheck';
import { DeviceStaeteComparisonCheck } from './checks/DeviceStateComparisonCheck';
import HubStatusCheck from './checks/HubStatusCheck';
import { LocationCheck } from './checks/LocationCheck';
import { TimeCheck } from './checks/TimeCheck';

const CheckForm: React.FC<{checkType: CheckTypes; check: any}> = ({ checkType, check }) => {
  switch (checkType) {
    case CheckTypes.DEVICE:
      return <DeviceCheck check={check} />;
    case CheckTypes.LOCATION:
      return <LocationCheck check={check} />;
    case CheckTypes.TIME:
      return <TimeCheck check={check} />;
    case CheckTypes.DATE:
      return <DateCheck check={check} />;
    case CheckTypes.COMPAIR:
      return <DeviceStaeteComparisonCheck check={check} />;
    case CheckTypes.HUB_STATUS:
      return <HubStatusCheck check={check} />;
    default:
      return <></>;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100%",
    //   border: "1px solid #7F8FA4",
    //   borderRadius: "5px",
    padding: '5px',
  },
  content: {},
  formControl: {
    width: '100%',
    paddingRight: '5px',
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const CheckSelector: React.FC<{check: {id: string}}> = ({ check }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedCheckType, setSelectedCheckType] = useState(CheckTypes.DEVICE);

  const handleChange = (event) => {
    dispatch(
      update_check({
        check: {
          ...checkContent,
          type: event.target.value,
          dc: null,
          device: null,
          variable: null,
          possible_variable_type: null,
          meta: {
            ...checkContent.meta,
            is_enabled: true,
            card_visibility: LOGIC_CARD_VISIBILITY.ACTIVE,
          },
        },
      }),
    );
    setSelectedCheckType(event.target.value);
  };

  // eslint-disable-next-line max-len
  const checkContent = useSelector((state: RootState) => state.rule_engine.checks.content.find((item) => item.id === check.id));

  return (
    <Grid container className={classes.root}>
      {checkContent.dc && checkContent.dc.has_verification_error && (
        <Grid item md={12}>
          <Alert color="warning">
            There is an issue with Device Container, Make sure the device container is exists or
            select another device container from the dropdown or delete this card. Info -
            {' '}
            {checkContent.dc.has_verification_error}
          </Alert>
          <Box height={15} />
        </Grid>
      )}

      {checkContent.device && checkContent.device.has_verification_error && (
        <Grid item md={12}>
          <Alert color="warning">
            There is an issue with the selected device/variable. If you deleted the device/variable
            make sure to select another item from the dropdown or delete this card. Info -
            {' '}
            {checkContent.device.has_verification_error}
          </Alert>
          <Box height={15} />
        </Grid>
      )}
      <Grid item md={2}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel>Type</InputLabel>
          <Select label="Type" value={checkContent.type} onChange={handleChange}>
            {Object.keys(checkTypesLabels).map((key, index) => (
              <MenuItem disabled={checkTypesLabels[key].label === 'Variable Compare'} value={checkTypesLabels[key].key}>{checkTypesLabels[key].label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={10} className={classes.content}>
        {checkContent && <CheckForm checkType={checkContent.type} check={checkContent} />}
      </Grid>
    </Grid>
  );
};
