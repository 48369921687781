import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { ITEM_DEFAULTS } from '../../../../../config/deviceConfig/item_defaults';

export const UVCreatorDefaultFieldMap = () => ({
  rule_name: {
    enable: false,
  },
  macro_name: {
    enable: false,
  },
  item_notification_type: {
    enable: false,
  },
  app_browser_item: {
    enable: false,
  },
  camera_selector: {
    enable: false,
    system_tags: [] as any[],
  },
  audio_selector: {
    enable: false,
    system_tags: [] as any[],
  },
  video_display_selector: {
    enable: false,
    system_tags: [] as any[],
  },
  control_buttons_selector: {
    enable: false,
    system_tags: [] as any[],
  },
  audio_devices: {
    enable: false,
    system_tags: [] as any[],
  },
  alarm_devices: {
    enable: false,
    system_tags: [] as any[],
  },
  control_buttons_selector2: {
    enable: false,
    item_types: [] as any[],
  },
  uv_item_selector: {
    enable: false,
    item_types: [] as any[],
  },
  description: {
    enable: false,
  },
  smartlists: {
    enable: false,
  },
  child_notification_item_selector: {
    enable: false,
    extra: 'notify_alert:4,notify_warning:5,notify_info:3',
  },
});
export const UVCreatorFieldsMap = (
  field: any,
  currentFieldConfigs: any,
  currentFormDefaults: any,
  currentFieldValidator: any,
  updateCustomFieldErrors: any,
  selectNewIcon: (arg: any) => void,
) => {
  const currentFieldConfigsCpy = cloneDeep(currentFieldConfigs);
  const currentFormDefaultsCpy = cloneDeep(currentFormDefaults);
  const currentFieldValidatorCpy = cloneDeep(currentFieldValidator);

  if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.child_notification_item_selector) {
    currentFieldConfigsCpy.child_notification_item_selector = {
      enable: true,
      items: field.extra.split(',').map((v) => {
        const vv = v.split(':');
        return { name_prefix: vv[0], notification: +vv[1] };
      }),
    };

    // currentFormDefaultsCpy.description = '';
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.description) {
    currentFieldConfigsCpy.description = {
      enable: true,
    };

    currentFormDefaultsCpy.description = '';
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.app_browser_item) {
    currentFieldConfigsCpy.app_browser_item = {
      enable: true,
    };

    currentFormDefaultsCpy.app_browser_item = {};
    updateCustomFieldErrors({
      app_browser_item: { invalid: true, message: 'One url value expected' },
    });
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.camera_selector) {
    currentFieldConfigsCpy.camera_selector = {
      enable: true,
      system_tags: field.extra.split(','),
    };

    currentFormDefaultsCpy.camera_selector = {};
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.control_buttons_selector2) {
    currentFieldConfigsCpy.control_buttons_selector2 = {
      enable: true,
      item_types: field.extra.split(','),
    };

    currentFormDefaultsCpy.uv_item_selector = {};
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.uv_item_selector) {
    currentFieldConfigsCpy.uv_item_selector = {
      enable: true,
      item_types: field.extra.split(','),
    };

    currentFormDefaultsCpy.uv_item_selector = {};
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.audio_devices) {
    currentFieldConfigsCpy.audio_devices = {
      enable: true,
      system_tags: field.extra.split(','),
    };

    currentFormDefaultsCpy.audio_devices = {};
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.alarm_devices) {
    currentFieldConfigsCpy.alarm_devices = {
      enable: true,
      system_tags: field.extra.split(','),
    };

    currentFormDefaultsCpy.audio_devices = {};
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.control_buttons_selector) {
    currentFieldConfigsCpy.control_buttons_selector = {
      enable: true,
      system_tags: field.extra.split(','),
    };

    currentFormDefaultsCpy.control_buttons_selector = {};
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.video_display_selector) {
    currentFieldConfigsCpy.video_display_selector = {
      enable: true,
      system_tags: field.extra.split(','),
    };

    currentFormDefaultsCpy.video_display_selector = {};
    // updateCustomFieldErrors({
    //   video_display_selector: {
    //     invalid: true,
    //     message: "Video device required",
    //   },
    // });
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.audio_selector) {
    currentFieldConfigsCpy.audio_selector = {
      enable: true,
      system_tags: field.extra.split(','),
    };

    currentFormDefaultsCpy.audio_selector = {};
    // updateCustomFieldErrors({
    //   audio_selector: { invalid: true, message: "Audio device required" },
    // });
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.rule_name) {
    currentFieldConfigsCpy.rule_name = {
      enable: true,
    };

    currentFieldValidatorCpy.rule_name = Yup.string().max(15, 'Maximum Rule name length 15');
    currentFormDefaultsCpy.rule_name = field.default;
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.macro_name) {
    currentFieldConfigsCpy.macro_name = {
      enable: true,
    };

    currentFieldValidatorCpy.macro_name = Yup.string().max(15, 'Maximum Rule name length 15');

    currentFormDefaultsCpy.macro_name = field.default;
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.item_notification_type) {
    currentFieldConfigsCpy.item_notification_type = {
      enable: true,
    };

    currentFieldValidatorCpy.item_notification_type = Yup.string().max(
      15,
      'Maximum Rule name length 15',
    );

    currentFormDefaultsCpy.item_notification_type = 1; // +field.default;
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.icon_name) {
    selectNewIcon(field.default);
  } else if (field.type === ITEM_DEFAULTS.ITEM_CREATOR.UV_ITEM_FIELDS.smartlists) {
    currentFieldConfigsCpy.smartlists = {
      enable: true,
    };
  }
  return {
    currentFieldConfigsCpy,
    currentFormDefaultsCpy,
    currentFieldValidatorCpy,
  };
};
