/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl, Grid, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Trigger } from '@smartlife-redux-state/common';
// eslint-disable-next-line camelcase
import { proDCSearchGraphQL } from 'src/services/pro-container/pro-container.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { ProjectContext } from 'src/store/Project/ProjectContext';
import { useSnackbar } from 'notistack';
import { RootState } from 'src/store/redux/store';
import { isArray } from 'lodash';
import { update_check, update_trigger } from '../../../../../../../store/redux/features/logic-engine';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
});

function isJson(str) {
  if (!str) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const HubStatusCheck: React.FC<{check: {id: string}}> = ({ check }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profile = useContext(ApolloAuthContext);
  const project = useContext(ProjectContext);
  const { enqueueSnackbar } = useSnackbar();

  const [dcs, setDcs] = useState<any[]>([]);

  // eslint-disable-next-line max-len
  const checkContent = useSelector((state: RootState) => state.rule_engine.checks.content.find((item) => item.id === check.id));

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const res = await proDCSearchGraphQL(
        profile.apollo_client,
        project.selected_project.id,
        -1,
        -1,
        '',
        100,
        0,
      );
      if (!res.proDCSearch) throw new Error();

      const { result } = res.proDCSearch;
      if (!isArray(result)) throw new Error();

      const filtered = result.filter((dc) => dc.dc_type !== 9);
      setDcs(filtered);
    } catch (err) {
      enqueueSnackbar('Something Went Wrong', { variant: 'error' });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <FormControl size="small" variant="outlined" className={classes.formControl}>
          <InputLabel>Connect Hub</InputLabel>
          <Select
            label="Connect Hub"
            onChange={(e) => {
              dispatch(
                update_check({
                  check: {
                    ...checkContent,
                    variable: e.target.value,
                  },
                }),
              );
            }}
            value={checkContent.variable}
          >
            {dcs.map((dc) => (
              <MenuItem key={dc.id} value={dc.id}>
                {dc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <InputLabel>Hub Status</InputLabel>
          <Select
            label="Hub Status"
            onChange={(e) => {
              dispatch(
                update_check({
                  check: {
                    ...checkContent,
                    val: e.target.value,
                  },
                }),
              );
            }}
            value={checkContent.val}
          >
            <MenuItem value="ONLINE">Online</MenuItem>
            <MenuItem value="OFFLINE">Offline</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default HubStatusCheck;
