import { useState, useEffect } from 'react';
import { ProDevice, AirDevice } from '@smartlife-redux-state/common';
import { AirVariable } from './air-variables/AirVariable';
import { ProVariable } from './pro-variables/ProVariable';

// eslint-disable-next-line import/prefer-default-export
export const ExpressionVariableSelector: React.FC<{
  device: ProDevice | AirDevice;
  selectedVariable: {field: string; type: string};
  behaveAs: string;
  hideTypeSelector?: boolean;
  onVariableChange: (selectedField: string, type: string) => void;
}> = ({
  device, selectedVariable, behaveAs, hideTypeSelector, onVariableChange,
}) => {
  function onDCVarSelect(selectedFiled: string, type: string) {
    console.log('SELECTED FIELDS', selectedFiled);
    onVariableChange(selectedFiled, type);
  }

  if (device == null) {
    return <></>;
  }

  if ((device as AirDevice).airdc_id) {
    return (
      <AirVariable
        selectedVariable={selectedVariable}
        air_device={device as AirDevice}
        behaveAs={behaveAs}
        hideTypeSelector={hideTypeSelector}
        onChange={onDCVarSelect}
      />
    );
  }
  return (
    <ProVariable
      selectedVariable={selectedVariable}
      pro_device={device as ProDevice}
      hideTypeSelector={hideTypeSelector}
      onChange={onDCVarSelect}
    />
  );
};
