import { AirDevice } from '@smartlife-redux-state/common';
import { useContext, useEffect } from 'react';
import { AirAvaibleDevice } from 'src/store/redux/features/configurable-containers';
import airDeviceService from 'src/containers/app/AirDeviceCreateEdit/services/air-device.service';
import { ApolloAuthContext } from 'src/store/Apollo/ApolloContext';
import { useSnackbar } from 'notistack';

const useFigureChannelSet = (
  device: AirDevice | AirAvaibleDevice,
  droppedDevice: any,
  devicePos: 'toExisting' | 'toAvailable',
  dcId: string,
) => {
  const userProfile = useContext(ApolloAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!device || !droppedDevice) return;

    if (devicePos === 'toAvailable') {
      figureChannelsForAvailableDevices(device as AirAvaibleDevice, droppedDevice, dcId);
    }
  }, [device, droppedDevice, devicePos]);

  const figureChannelsForAvailableDevices = async (
    tuyaDevice: AirAvaibleDevice,
    deviceFromTemplate: any,
    containerId: string,
  ) => {
    try {
      // console.log('DROPPED', deviceFromTemplate);
      // const tuyaDeviceInfo = await airDeviceService.airDeviceInfo(
      //   userProfile.apollo_client,
      //   containerId,
      //   tuyaDevice.id,
      // );

      // const templateDeviceInfo = await airDeviceService.airDeviceInfo(
      //   userProfile.apollo_client,
      //   deviceFromTemplate.airdc_id,
      //   deviceFromTemplate.tuya_device_id,
      // );
      // console.log('DEVICE INFO', tuyaDeviceInfo);
      // console.log('TEMPLATE DEVICE INFO', templateDeviceInfo);
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const figureChannelsForExistingDevices = () => {
    throw new Error('Implement me');
  };
};

export default useFigureChannelSet;
