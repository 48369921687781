import React, { useEffect, useRef, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import styles from './UVAirGangSelector.module.css';
import { SentenceCase } from '../../../../../utility/appUtils';

type ComponentProps = {
  label: string;
  channels: any[];
  selectedChannel: string;
  deviceState?: any;
  onChange: (channel: string) => void;
};

// eslint-disable-next-line import/prefer-default-export
export const AirChannelSelector: React.FunctionComponent<ComponentProps> = ({
  label,
  channels,
  selectedChannel,
  deviceState,
  onChange,
}) => {
  const getStateText = (code: string) => {
    if (deviceState) {
      const v = `${JSON.stringify(deviceState.state[code])}`;
      if (v !== 'undefined' && v.length > 0) {
        return `(${v})`;
      }
    }
    return '';
  };
  return (
    <FormControl
      size="small"
      variant="outlined"
      className="dynamic-ui-form-control-full-width mt-8"
    >
      <InputLabel id="gang-custom_selector-label1">{label}</InputLabel>
      <Select
        labelId="gang-custom_selector-label1"
        value={selectedChannel == null ? '' : selectedChannel}
        name="gang-custom_selector"
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        label={label}
      >
        {channels.map((item) => (
          <MenuItem key={`${Date.now()}${item}`} value={item} disabled={false}>
            {SentenceCase(item.split('_').join(' '))}
            {' '}
            {getStateText(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

AirChannelSelector.defaultProps = {
  deviceState: null,
};
