/**
 * Copyright (C) Smartlife 2021 The Smartlife Connect Project.
 *
 * Card component which use to list members
 *
 * Changes Since initial code
 *  1. Linting - Maduka Dilshan
 *
 * @file This file define a card component to use list members
 * @author Mayantha
 * @since -
 */
import { Chip } from '@mui/material';
import React from 'react';
import styles from './MemberCard.module.css';

type ComponentProps = {
  title: string;
  profileBgStyle: any;
  permissionType: string;
  desciption: string;
  isMe?: boolean;
};

/**
 * Card component which use to list members
 *
 * @param {Object} props                  component props
 * @param {String} props.title            Text displays in the Option
 * @param {String} props.desciption       Additional text to display
 * @param {String} props.profileBgStyle   background style for the card
 * @param {String} props.permissionType   permission type of the provided user
 * @param {Boolean} props.isMe            Flag that use to indentify if the provided user is the
 * same logged in user
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line import/prefer-default-export
export const MemberCardComponent: React.FunctionComponent<ComponentProps> = ({
  title,
  profileBgStyle,
  permissionType,
  desciption,
  isMe = false,
}) => (
  <div className={`${styles.root} mr-2`}>
    <div>
      <div className={styles.circleAvatar} style={{ ...profileBgStyle }} />
    </div>
    <div className={styles.content}>
      <div className={styles.title}>
        {title}
        {' '}
        {isMe && <Chip label="Me" size="small" />}
        {' '}
      </div>
      <div className={`${styles.permissionType} mb-1`}>{permissionType}</div>
      <hr className="nopadding mt-1" />
      <div className={`${styles.desciption} mt-1`}>{desciption}</div>
    </div>
  </div>
);

MemberCardComponent.defaultProps = {
  isMe: false,
};
