/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  AppBar, Toolbar, Hidden, IconButton, Badge, Box,
} from '@mui/material';
import HeaderComponent from 'src/components/app/Header/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 100,
    // boxShadow: theme.shadows[1],
    // boxShadow: 'none',
    width: '80%',
    margin: '0 auto',
    backgroundColor: 'white', // theme.palette.background.default,
  },
  toolbar: {
    minHeight: 64,
  },
  logo: {
    marginRight: 2, // theme.spacing(2),
  },
  link: {
    fontWeight: '500', // theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: 2, // theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: 2, // theme.spacing(2),
    marginRight: 2, // theme.spacing(2),
  },
}));

const TopBar: React.FC<{className?: string; onMobileNavOpen: () => void}> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return <HeaderComponent navOnClick={onMobileNavOpen} />;

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">Logo</RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton>
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              Notifi
            </Badge>
          </IconButton>
          <IconButton>Icon</IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen}>Menu</IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.defaultProps = {
  className: '',
};

export default TopBar;
