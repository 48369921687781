import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v2HideItems } from 'src/store/redux/features/backup-location-config';
import { V2BStepackupInfoRoot } from 'src/store/redux/features/backup-location-config/v2types';

const useHideSelectedDevices = (
  pendingData: V2BStepackupInfoRoot,
  checkedDevices: any,
  setCheckDevices: any,
) => {
  const [groupedCheckedItems, setGroupedCheckedItems] = useState<any>({});
  const [showHiddens, setShowHiddens] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(groupedCheckedItems).map((dcId) => {
      const grouped = groupedCheckedItems[dcId];

      dispatch(
        v2HideItems({
          devices: grouped,
          containerId: dcId,
        }),
      );
      setCheckDevices({});
      return 0;
    });
  }, [groupedCheckedItems]);

  const hideSelectedDevices = () => {
    const checkedGrouped = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(pendingData.pendingComps).map((contId) => {
      const container = pendingData.pendingComps[contId];
      // eslint-disable-next-line array-callback-return
      Object.keys(container).map((key) => {
        const item = container[key];

        if (checkedDevices[contId] && checkedDevices[contId][item.old_id]) {
          if (!checkedGrouped[contId]) {
            checkedGrouped[contId] = {};
          }

          checkedGrouped[contId][item.old_id] = true;
        }
      });
    });

    setGroupedCheckedItems(checkedGrouped);
  };

  const forceHide = (hiddenDevices: { [contId: string]: { [deviceId: string]: any } }) => {
    const hiddenItems = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(pendingData.pendingComps).map((contId) => {
      const container = pendingData.pendingComps[contId];
      // eslint-disable-next-line array-callback-return
      Object.keys(container).map((key) => {
        const item = container[key];

        if (hiddenDevices[contId] && hiddenDevices[contId][item.old_id]) {
          if (!hiddenItems[contId]) {
            hiddenItems[contId] = {};
          }

          hiddenItems[contId][item.old_id] = true;
        } else if (hiddenDevices[contId] && Object.keys(hiddenDevices[contId]).length === 0) {
          hiddenItems[contId] = {};
        }
      });
    });

    if (Object.keys(hiddenItems).length > 0) {
      Object.keys(hiddenItems).map((dcId) => {
        const grouped = hiddenItems[dcId];
        console.log('GROUPED', grouped);
        dispatch(
          v2HideItems({
            devices: grouped,
            containerId: dcId,
            forceReset: true,
          }),
        );
        setCheckDevices({});
        return 0;
      });
    }
  };

  return [groupedCheckedItems, hideSelectedDevices, showHiddens, setShowHiddens, forceHide];
};

export default useHideSelectedDevices;
