import { gql } from '@apollo/client';

export const gqlSearchProjectDevice = gql`
  query projectDeviceSearch(
    $project_id: String
    $system_tags: [String]
    $tags: [String]
    $keyword: String
    $air_device_ids: [String]
    $pro_device_ids: [String]
    $cint_device_ids: [String]
    $limit: Int
    $skip: Int
  ) {
    projectDeviceSearch(
      project_id: $project_id
      tags: $tags
      system_tags: $system_tags
      keyword: $keyword
      air_device_ids: $air_device_ids
      pro_device_ids: $pro_device_ids
      cint_device_ids: $cint_device_ids
      limit: $limit
      skip: $skip
    ) {
      pro_devices {
        id
        created_by
        project_id
        prodc_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        item_type
        device_status
        settings
        created_at
        updated_at
        dc_name
      }
      air_devices {
        id
        created_by
        project_id
        airdc_id
        tuya_device_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        device_type
        device_status
        settings
        config
        created_at
        updated_at
        dc_name
      }
      cint_devices {
        cat
        cint_coll
        cint_device_id
        cint_type
        created_at
        dc_name
        device_status
        device_type
        id
        name
        project_id
        settings
        sub_cat
        system_tags
        tags
        updated_at
      }
    }
  }
`;

export const gqlSearchProjectCloudDevice = gql`
  query projectDeviceSearch(
    $project_id: String
    $system_tags: [String]
    $tags: [String]
    $keyword: String
    $cint_device_ids: [String]
    $limit: Int
    $skip: Int
  ) {
    projectDeviceSearch(
      project_id: $project_id
      tags: $tags
      system_tags: $system_tags
      keyword: $keyword
      cint_device_ids: $cint_device_ids
      limit: $limit
      skip: $skip
    ) {
      cint_devices {
        cat
        cint_coll
        cint_device_id
        cint_type
        created_at
        dc_name
        device_status
        device_type
        id
        name
        project_id
        settings
        sub_cat
        system_tags
        tags
        updated_at
      }
    }
  }
`;

export const gqlUserviewListFolderItems = gql`
  query userviewListFolderItems(
    $userview_id: String
    $folder_id: String
    $limit: Int
    $skip: Int
  ) {
    userviewListFolderItems(
      userview_id: $userview_id
      folder_id: $folder_id
      limit: $limit
      skip: $skip
    ) {
      items {
        limit
        skip
        resultCount
        result {
          id
          parent_item_id
          item_cat
          item_type
          item_status
          display_name
          icon_name
          item_color
          display_path_ref
          path_ref
          dc_id
          pro_device_id
          air_device_id
          item_visibility
        }
      }
      folder {
        id
        created_by
        project_id
        userview_id
        parent_item_id
        display_path_ref
        path_ref
        item_cat
        item_type
        item_status
        item_visibility
        item_notification_type
        display_name
        name_id
        rule_name
        macro_name
        icon_name
        item_color
        settings
        config
        created_at
        updated_at
      }
      user_view {
        name
        id
        project_id
        name
        userview_status
        lock_role3
      }
    }
  }
`;

export const gqlSearchUserviewItems = gql`
  query userviewSearchItems(
    $userview_id: String
    $item_types: [String]
    $uv_item_ids: [String]
    $limit: Int
    $skip: Int
  ) {
    userviewSearchItems(
      userview_id: $userview_id
      item_types: $item_types
      uv_item_ids: $uv_item_ids
      limit: $limit
      skip: $skip
    ) {
      limit
      skip
      resultCount
      result {
        id
        project_id
        userview_id

        parent_item_id
        display_path_ref
        path_ref
        pro_device_id
        air_device_id

        item_cat
        item_type

        dc_id

        item_status
        item_visibility
        item_notification_type
        display_name
        name_id

        rule_name
        macro_name

        icon_name
        item_color

        settings
        config

        created_at
        updated_at
      }
    }
  }
`;
export const gqlGetUserviewItemFullDetails = gql`
  query userviewItemGet($userview_item_id: String) {
    userviewItemGet(item_id: $userview_item_id) {
      item {
        id
        parent_item_id
        item_cat
        item_type
        display_name
        item_visibility
        item_notification_type
        rule_name
        macro_name
        icon_name
        item_color
        pro_device_id
        air_device_id
        cint_device_id
        settings
        config
      }
      pro_device {
        id
        prodc_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        device_status
        settings
        created_at
        updated_at
      }
      air_device {
        id
        airdc_id
        name
        tags
        system_tags
        shadow_type
        cat
        sub_cat
        device_status
        settings
        created_at
        updated_at
        tuya_device_id
      }
      cint_device {
        id
        project_id
        cint_coll
        name
        tags
        system_tags
        cint_type
        cint_device_id
        cat
        sub_cat
        device_type
        device_status
        settings
        created_at
        updated_at
        dc_name
      }
    }
  }
`;

export const gqlUserviewItemCreate = gql`
  mutation userviewItemCreate($payload: UserviewItemIn) {
    userviewItemCreate(input: $payload) {
      id
      # created_by
      # project_id
      # userview_id
      # parent_item_id
      # display_path_ref
      # path_ref
      # pro_device_id
      # air_device_id
      # item_cat
      # item_type
      # item_status
      # item_visibility
      # item_notification_type
      # display_name
      # name_id
      # rule_name
      # macro_name
      # icon_name
      # settings
      # config
      # created_at
      # updated_at
    }
  }
`;
export const gqlDeviceState = gql`
  query deviceState($type: String, $device_id: String) {
    deviceState(type: $type, device_id: $device_id) {
      state
    }
  }
`;

export const gqlUpdateFolder = gql`
  mutation userviewItemFolderUpdate(
    $item_id: String
    $item: UserviewItemFolderUpdate
  ) {
    userviewItemFolderUpdate(item_id: $item_id, item: $item) {
      id
      update_status
      updated_at
    }
  }
`;
export const gqlUpdateItem = gql`
  mutation userviewItemItemUpdate($item_id: String, $item: UserviewItemUpdate) {
    userviewItemItemUpdate(item_id: $item_id, item: $item) {
      id
      update_status
      updated_at
    }
  }
`;

export const gqlDeleteFolder = gql`
  mutation userviewItemFolderDelete($userview_item_id: String) {
    userviewItemFolderDelete(item_id: $userview_item_id) {
      id
      delete_status
    }
  }
`;
export const gqlDeleteItem = gql`
  mutation userviewItemDelete($userview_item_id: String) {
    userviewItemDelete(item_id: $userview_item_id) {
      id
      delete_status
    }
  }
`;

export const ITEM_ORDER_GET = gql`
  query ItemOrderGet($parent_ref: String, $order_for: Int) {
    itemOrderGet(parent_ref: $parent_ref, order_for: $order_for) {
      items {
        ref_val
        order_val
      }
    }
  }
`;

export const ITEM_ORDER_SET_ALL = gql`
  mutation ItemOrderSetAll(
    $project_id: String
    $parent_ref: String
    $order_for: Int
    $item_ids: [String]
  ) {
    itemOrderSetAll(
      project_id: $project_id
      parent_ref: $parent_ref
      order_for: $order_for
      item_ids: $item_ids
    ) {
      id
    }
  }
`;
