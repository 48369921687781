import { iAirIntegration } from '../air.service';
import CreateAirDeviceContainer from './DCCreate';
import {
  DeviceEnergyMonitoringConfig,
  DeviceEnergyMonitoringConfigProps,
} from './DeviceEnergyMonitoringConfig';

export class PluginComponentFactory {
  public GetDcCreateComponent(
    integration: iAirIntegration,
    rnProps: {
      backup_view: boolean;
      onSuccessCreate?: (type: 'air', created: any) => void;
    },
  ) {
    return <CreateAirDeviceContainer {...rnProps} />;
  }

  public GetDeviceEnergyMonitoringConfigComponent(
    integration: iAirIntegration,
    rnProps: DeviceEnergyMonitoringConfigProps,
  ) {
    return <DeviceEnergyMonitoringConfig {...rnProps} />;
  }
}

export default PluginComponentFactory;
