/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppUtils } from '../../utility/appUtils';
import { UserViewcurrentDirectory } from './UserViewcurrentDirectory/UserViewcurrentDirectory';
import './userview-dynamic-ui.css';
import { UVProviderComponent, UVReducerTypes } from './UVContext';
import { IUserView } from './types/types';

interface ComponentQueryParams {
  projectId: string;
  userViewId: string;
  userViewPath: string;
}
type ComponentProps = {
  userviewFetchHandler: (userview: IUserView) => void;
  itemCreateRef: any;
  // eslint-disable-next-line camelcase
  parent_folder_id: string;
};

// eslint-disable-next-line import/prefer-default-export
export const UserViewRoot: React.FC<ComponentProps> = (props) => {
  // eslint-disable-next-line camelcase
  const { userviewFetchHandler, itemCreateRef, parent_folder_id } = props;
  const location = useLocation();
  const paramData = AppUtils.queryParamStringToObj(location.search);
  const queryParams: ComponentQueryParams = {
    projectId: paramData.projectId,
    userViewId: paramData.userViewId,
    userViewPath: paramData.userViewPath || 'root',
  };

  return (
    <div>
      <UVProviderComponent>
        <UserViewcurrentDirectory
          projectId={queryParams.projectId}
          userViewId={queryParams.userViewId}
          userViewPath={queryParams.userViewPath}
          userviewFetchHandler={userviewFetchHandler}
          ref={itemCreateRef}
          parent_folder_id={parent_folder_id}
        />
      </UVProviderComponent>
    </div>
  );
};
