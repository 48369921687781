import { COMPONENT_UNIT_TYPES } from "src/plugins/shared/item.config";
import { Component, Tag } from "src/plugins/shared/uvitem.config";

const commonTagFields = {
    type: COMPONENT_UNIT_TYPES.Sensor,
    main_key: null,
    command_key: null,
    can_send_command: false,
}

const VehicleTags: Record<string, Tag> = {
    chargeState__powerDeliveryState : {
        tag: 'chargeState__powerDeliveryState',
        description: 'Production Rate',
        command_v: "v1",
        ...commonTagFields,
    },

}

const config: Record<string, Component> = {
    vehicle_map_1: {
        id: 'vehicle_map_1',
        componentName: "Vehicle Map 1",
        created_at: '2023.04.27',
        default_inline_ui_data_map: null,
        default_inner_ui_data_map: null,
        ui_tags: VehicleTags,
        item_type: 'vehicle.inverter', 
        ui_data_maps: {
          inline_maps: {}, // TODO: to be filled by @Mayantha
          inner_maps: {}, // TODO: to be filled by @Mayantha
        },
    }
}

export default config;