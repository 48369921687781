/* eslint-disable camelcase */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { appConsoleLog } from '../../utility/appUtils';

const tagGetGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  tag_id: number,
): Promise<any> => {
  const res = await apolloClient.query({
    query: gql`
      query {
        tagGet(
              tag_id : ${tag_id},
        ){
          tag{
            id
            created_by
            project_id
            name
            created_at
          }
        }
        }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const tagSearchGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  keyword: any,
  limit: any,
  skip: any,
): Promise<any> => {
  const res = await apolloClient.query({
    query: gql`
      query {
        tagSearch(
          project_id: "${project_id}",
          keyword : "${keyword}",
          limit: ${limit},
          skip: ${skip},
        ){
            limit
            skip
            resultCount
            result{
              id
              created_by
              project_id
              name
              created_at
            }
          }
        }
      `,
    fetchPolicy: 'network-only',
  });
  return res.data;
};

const tagCreateGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  project_id: string,
  name: string,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
            tagCreate(
                input:{
                    project_id: "${project_id}",
                    name: "${name}"
              }
            ){
              id
              created_by
              project_id
              name
              created_at
            }
            }
        `,
  });
  appConsoleLog(res);
  return res;
};

const tagDeleteGraphQL = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  item_id: number,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: gql`
        mutation {
            tagDelete(
              item_id: ${item_id},
            ){
              id
              delete_status
          }
        }
        `,
  });
  appConsoleLog(res);
  return res;
};

export {
  tagGetGraphQL, tagSearchGraphQL, tagCreateGraphQL, tagDeleteGraphQL,
};
