/* eslint-disable import/prefer-default-export */
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

type ComponentProps = {
  isLoading: boolean;
};
export const LoadingLine: React.FunctionComponent<ComponentProps> = ({ isLoading }) => (
  isLoading ? (
    <div>
      <LinearProgress />
    </div>
  ) : (
    <div />
  ));
