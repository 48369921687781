/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  IconButton,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AirPlugin from 'src/plugins/air.plugin';
import ShellyPlugin from 'src/plugins/shelly.plugin';
import { pluginHook } from 'src/store/Plugins/PluginProvider';
import { useQueryStringFirst } from 'src/utility/CustomHooks/useQueryNumber';
import { APP_TEXT } from '../../../../utility/AppTexts/DefaultAppText.utils';
import styles from './CreateEditDeviceContainer.module.css';
import { AuthContext } from '../../../../store/Auth/AuthContext';
import { ApolloAuthContext } from '../../../../store/Apollo/ApolloContext';
import CustomOptionSelector from '../../../../components/shared/CustomOptionSelector/CustomOptionSelector';
import { ProjectContext } from '../../../../store/Project/ProjectContext';
import {
  SuccessSnackbarOptions,
  SuccessSnackbarActions,
  ErrorSnackbarOptions,
  ErrorSnackbarActions,
} from '../../../../components/Alters/Snackbar/SnackbarOptions';
import { proDCCreateGraphQL } from '../../../../services/pro-container/pro-container.service';
import { appConsoleLog, appConsoleError, appApiErrorMessage } from '../../../../utility/appUtils';
import Layout from '../../../../components/layout/Layout';
import { PageHeader } from '../../../../components/layout/PageHeader';

type ComponentProps = {
  backup_view?: boolean;
  onSuccessCreate?: (type: 'air' | 'pro' | any, created: any) => void;
};
type IProDCFormValue = {
  hub_type: number;
  serial_name: string;
  name: string;
  description: string;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5% 3%',
    width: '100%',
  },
  headerTitle: {
    fontWeight: 600,
    color: 'var(--primary-dark-green)',
    fontSize: '1.5rem',
  },
  contentRoot: {
    flex: 1,
    margin: '10px 5%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  sec1: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const ShellyFlow = (props: {
  backup_view: boolean;
  onSuccessCreate?: (type: 'shelly', created: any) => void;
}) => pluginHook.shellyPlugin.DcCreateComponent(props);

const AirFlow = pluginHook.airPlugin.uiComponents.getDcCreateUi;
const EnodeFlow = pluginHook.enodePlugin.uiComponents.getDcCreateUi;

export const CreateEditDeviceContainerComponent: React.FunctionComponent<ComponentProps> = ({
  backup_view,
  onSuccessCreate,
}) => {
  const cType = useQueryStringFirst('type');

  const matStyles = useStyles();
  const user = useContext(AuthContext);
  const selectedProject = useContext(ProjectContext);
  const userProfile = useContext(ApolloAuthContext);
  const navgiate = useNavigate();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const [containerType, containerTypeSelector] = useState(cType || 'PRO');

  const [selectedUserViews, setSelectedUserView] = useState([0] as any);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigateRoute = (path: string) => {
    setAnchorEl(null);
    navgiate(path);
  };

  // useEffect(() => {
  //   appConsoleLog('selectedProject', selectedProject);
  //   if (
  //     !selectedProject
  //     || !selectedProject.selected_project
  //     || !selectedProject.selected_project.id
  //   ) {
  //     navigateRoute('/app');
  //   }
  // });

  const userViewSelect = (userViewId: any) => {
    const index = selectedUserViews.indexOf(userViewId, 0);
    if (index > -1) {
      selectedUserViews.splice(index, 1);
    } else {
      selectedUserViews.push(userViewId);
    }
  };

  const onSubmitProCreateForm = async (
    values: IProDCFormValue,
    {
      resetForm, setErrors, setStatus, setSubmitting,
    }: any,
  ) => {
    setLoading(true);
    console.log('ID => ', selectedProject.selected_project.id);
    try {
      if (!selectedProject.selected_project.id) {
        throw new Error('Please select project');
      }
      const res = await proDCCreateGraphQL(
        userProfile?.apollo_client,
        selectedProject.selected_project.id,
        values.serial_name.trim(),
        values.name.trim(),
        values.description.trim(),
        values.hub_type,
        1,
      );

      snackbar.enqueueSnackbar(APP_TEXT.device_container.DEVICE_CONTAINER_PRO__CREATE_SUCCESS, {
        key: 'key1',
        ...SuccessSnackbarOptions,
        action: SuccessSnackbarActions(snackbar.closeSnackbar),
      });
      resetForm();

      if (backup_view && res.data.proDCCreate) {
        onSuccessCreate('pro', res.data.proDCCreate);
      }
      if (!backup_view) {
        if (res.data.proDCCreate) {
          navigateRoute(
            `/app/device-container/pro?projectId=${selectedProject.selected_project.id}&dcId=${res.data.proDCCreate.id}&name=${res.data.proDCCreate.name}`,
          );
          return;
        }
        navigateRoute(`/app/device-containers?projectId=${selectedProject.selected_project.id}`);
      }
    } catch (err: any) {
      appConsoleError(err);

      let errorMessage = 'Unexpected error';
      if (typeof err === 'string') {
        errorMessage = err;
      } else if (err && err.message && !err.message.includes('<!DOCTYPE')) {
        errorMessage = appApiErrorMessage(err.message);
      }
      snackbar.enqueueSnackbar(errorMessage, {
        key: 'key3',
        ...ErrorSnackbarOptions,
        action: ErrorSnackbarActions(snackbar.closeSnackbar),
      });
    }
    setLoading(false);
  };
  const containerTypeSelectorFunc = (type: string) => {
    containerTypeSelector(type);
  };

  return (
    <Layout>
      {!backup_view && (
        <PageHeader>
          <Box className={matStyles.headerRoot}>
            <div className="wizardTop">
              <div
                className="nopadding"
                style={{
                  fontWeight: 600,
                  color: 'var(--primary-dark-green)',
                  fontSize: '1.5rem',
                }}
              >
                {APP_TEXT.device_container.ADD_DEVICE_CONTAINER2}
              </div>
            </div>
          </Box>
        </PageHeader>
      )}

      <Box className={matStyles.contentRoot}>
        <Box height={10} />
        {containerType !== 'SHELLY' && (
          <div
            className="custom-card2-container"
            style={{
              backgroundColor: 'white',
              padding: '2%',
              borderRadius: '5px',
            }}
          >
            <div className="custom-card2-title">
              <h2>{APP_TEXT.device_container.CONTAINER_TYPE}</h2>
              <hr />
            </div>
            <div className="custom-card2-body mt-2">
              <Box className={matStyles.sec1}>
                <Box>
                  <CustomOptionSelector
                    title={APP_TEXT.device_container.SMARTLIFE_HUB}
                    selected={containerType === 'PRO'}
                    onClick={() => containerTypeSelectorFunc('PRO')}
                  />
                </Box>
                <Box>
                  <CustomOptionSelector
                    title={APP_TEXT.device_container.SMARTLIFE_AIR}
                    selected={containerType === 'AIR'}
                    onClick={() => containerTypeSelectorFunc('AIR')}
                  />
                </Box>
                <Box>
                  <CustomOptionSelector
                    title={APP_TEXT.device_container.SMARTLIFE_SHELLY}
                    selected={containerType === 'SHELLY'}
                    onClick={() => containerTypeSelectorFunc('SHELLY')}
                  />
                </Box>
                <Box>
                  <CustomOptionSelector
                    title={APP_TEXT.device_container.SMARTLIFE_ENODE}
                    selected={containerType === 'ENODE'}
                    onClick={() => containerTypeSelectorFunc('ENODE')}
                  />
                </Box>
              </Box>
            </div>
          </div>
        )}

        <Box height={10} />
        <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {containerType === 'PRO' ? (
            <div className="custom-card2 col-md-12 pb-5 mt-3 mb-5">
              <div className="custom-card2-container">
                <div className="custom-card2-in ">
                  <div className="custom-card2-title">
                    <h2>{APP_TEXT.device_container.HUB_TYPE}</h2>
                    <hr />
                  </div>
                  <Formik
                    onSubmit={onSubmitProCreateForm}
                    initialValues={{
                      hub_type: 4,
                      serial_name: '',
                      name: '',
                      description: '',
                    }}
                    validationSchema={Yup.object().shape({
                      hub_type: Yup.string().required('Required'),
                      serial_name: Yup.string()
                        .trim()
                        .required('Please enter the hub serial.')
                        .min(3),
                      name: Yup.string().trim().required('Please enter the display name.').min(3),
                      description: Yup.string()
                        .trim()
                        .required('Please enter the hub description.')
                        .min(3),
                    })}
                  >
                    {({
                      errors, handleBlur, handleChange, touched, values, submitForm,
                    }) => (
                      <form>
                        <div className="custom-card2-body mt-2">
                          <div className="m-0 col-md-12 nopaddingOnly row">
                            <div className="col-md-12 nopaddingOnly">
                              <div className="col-md-6 nopaddingOnly">
                                <div>
                                  <FormControl className={styles.fieldSpace} variant="outlined">
                                    <InputLabel>{APP_TEXT.device_container.HUB_TYPE}</InputLabel>
                                    <Select
                                      label={APP_TEXT.device_container.HUB_TYPE}
                                      name="hub_type"
                                      // value={values.member_type}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={4}>
                                        {' '}
                                        {APP_TEXT.device_container.HUB_TYPE4}
                                      </MenuItem>
                                      <MenuItem value={8}>
                                        {' '}
                                        {APP_TEXT.device_container.HUB_TYPE5}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div>
                                  {values.hub_type && values.hub_type.toString() !== '' ? (
                                    <div>
                                      <b>Hardware</b>
                                      <br />
                                      CPU: 300 MHz MIPS SoC
                                      <br />
                                      Flash Memory: NAND 24MB
                                      <br />
                                      DDR2 Memory: 30MB
                                      <br />
                                      USB Port: 2
                                      <br />
                                    </div>
                                  ) : (
                                    <div>* Please select Hub Type</div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6  ">
                                <TextField
                                  className={styles.fieldSpace}
                                  error={Boolean(touched.serial_name && errors.serial_name)}
                                  helperText={touched.serial_name && errors.serial_name}
                                  disabled={!(values.hub_type && values.hub_type.toString() !== '')}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.serial_name}
                                  name="serial_name"
                                  label="Hub Serial"
                                  size="medium"
                                  placeholder="SmartLifePro-1210"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                                <TextField
                                  className={styles.fieldSpace}
                                  error={Boolean(touched.name && errors.name)}
                                  disabled={!(values.hub_type && values.hub_type.toString() !== '')}
                                  helperText={touched.name && errors.name}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.name}
                                  name="name"
                                  label="Display Name"
                                  size="medium"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                                <TextField
                                  className={styles.fieldSpace}
                                  error={Boolean(touched.description && errors.description)}
                                  disabled={!(values.hub_type && values.hub_type.toString() !== '')}
                                  helperText={touched.description && errors.description}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.description}
                                  name="description"
                                  label={APP_TEXT.device_container.FORM_FIELD_HUB_DESCRIPTION}
                                  size="medium"
                                  type="text"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton edge="end" size="large" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  data-testid=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="custom-card2-body">
                          <div className="col-md-12 nopaddingOnly">
                            <div className="col-md-3 nopaddingOnly">
                              <Button
                                endIcon={<ArrowForward />}
                                onClick={submitForm}
                                disabled={loading}
                                color="primary"
                                variant="contained"
                              >
                                {APP_TEXT.device_container.ADD_PRO_DEVICE_CONTAINER_BTN}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          ) : containerType === 'AIR' ? (
            <AirFlow backup_view={backup_view} onSuccessCreate={onSuccessCreate} />
          ) : containerType === 'SHELLY' ? (
            <ShellyFlow backup_view={backup_view} onSuccessCreate={onSuccessCreate} />
          ) : containerType === 'ENODE' ? (
            <EnodeFlow backup_view={backup_view} onSuccessCreate={onSuccessCreate} />
          ) : (
            <div />
          )}
        </Box>
      </Box>
    </Layout>
  );
};

CreateEditDeviceContainerComponent.defaultProps = {
  backup_view: false,
  onSuccessCreate: null,
};

export default CreateEditDeviceContainerComponent;
