/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  MenuItem, Select, TextField, FormControl, InputLabel, Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { UV_COLORS } from '../../../../theme/UVTheme/colors';
import { UV_CALC_ICONS } from '../../../../theme/UVTheme/icons-new';
import { appConsoleLog } from '../../../../utility/appUtils';

import styles from './UVIconSelector.module.css';
// import { SketchPicker } from 'react-color'
type ComponentProps = {
  initialIcon: string;
  initialColor: string;
  onChange: (icon: string, color: string) => void;
};
// eslint-disable-next-line import/prefer-default-export
export const UVIconSelector: React.FunctionComponent<ComponentProps> = ({
  initialIcon,
  initialColor,
  onChange,
}) => {
  const [icons, setIcons] = useState<string[]>([]);
  const [selected, setIcon] = useState<string>(initialIcon);
  const [selectedColor, setColor] = useState<string>(initialColor);
  const [iconQuery, setIconQuery] = useState('');

  useEffect(() => {
    setColor(initialColor);
    setIcon(initialIcon);
  }, [initialColor, initialIcon]);

  useEffect(() => {
    const iconsListMap: any = {};
    const mapKeys = Object.keys(UV_CALC_ICONS);
    mapKeys.forEach((mk: any) => {
      UV_CALC_ICONS[mk].icons.forEach((v: any) => {
        const isLike = v.query.indexOf(iconQuery);
        if (isLike > -1) iconsListMap[v.class_name] = '1';
      });
    });
    setIcons(Object.keys(iconsListMap));
  }, [initialIcon, iconQuery]);
  return (
    <div>
      <div>
        <p>Select Icon:</p>
      </div>
      <div className={styles.iconSelectorRow}>
        <div className="col-md-3 pl-0 text-center">
          <div className={styles.selectedIcon} style={{ backgroundColor: selectedColor }}>
            {selected ? (
              <span className={`calc ${selected} ${styles.calcIconViewSelected}`} />
            ) : (
              <p className={styles.iconInvalid}>Please select icon</p>
            )}
          </div>

          {/* <Button
            onClick={() => {
              OpenColorDialog(true);
            }}
            color="primary"
          >
            <Close fontSize="large" />
            Close
          </Button>

          <ColorSelectorDialog
            dialogOpen={isColordialogOpen}
            dialogCloseEvent={() => {
              OpenColorDialog(false);
            }}

          ></ColorSelectorDialog> */}
          <div
            style={{
              width: '100%',
              textAlign: 'start',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            Select Color:
          </div>
          <FormControl variant="outlined">
            <InputLabel>Color</InputLabel>
            <Select
              labelId="custom_color-label1"
              value={selectedColor == null ? '' : selectedColor}
              name="custom_color"
              onChange={(e: any) => {
                setColor(e.target.value);
                onChange(selected, e.target.value);
              }}
              label="Select color"
              renderValue={(item: any) => (
                <div
                  key={item.replace('#', 'i')}
                  className={styles.colorItem}
                  style={{ backgroundColor: item, borderRadius: '50%' }}
                />
              )}
            >
              {UV_COLORS.map((item, index) => (
                <MenuItem
                  key={item.replace('#', `i${index}`)}
                  value={item}
                  selected={item === selectedColor}
                >
                  <span className={styles.colorItem} style={{ backgroundColor: item }} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          className={`col-md-9 ${styles.root}`}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <TextField
            className="uv-ui-form-control-full-width"
            onChange={(e) => {
              setIconQuery(e.target.value.toLowerCase());
            }}
            // style={{ position: "fixed", top: 0, width: "100%" }}
            value={iconQuery}
            name="search-icon"
            label="Search Icons"
            size="small"
            variant="outlined"
          />
          <div className={styles.iconRoot} style={{ width: '100%' }}>
            {icons.map((icon) => {
              const isSelected = selected === icon;
              return (
                <Tooltip title={icon.split('calc-')[1]}>
                  <div
                    key={icon}
                    className={`${styles.iconItem} ${isSelected ? styles.activeIcon : ''}`}
                    onClick={() => {
                      appConsoleLog(icon);
                      setIcon(icon);
                      onChange(icon, selectedColor);
                    }}
                  >
                    <span className={`calc ${icon} ${styles.calcIconView}`} />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
