/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, {
  createContext, useContext, useMemo, useReducer,
} from 'react';
import PropsTypes from 'prop-types';
import { appConsoleLog } from '../../../../../../../../utility/appUtils';

// eslint-disable-next-line no-shadow
enum ACTION_TYPES {
  SELECT = 'SELECT',
}

// The below enum values doesn not indicated the actual value types of enum properties.
// This enum is only use in uv <-> folder view dialog
enum SELECTABLE_TYPES {
  ITEM = 1,
  FOLDER = 2,
  UV = 3,
  OTHER_ITEM = 4,
}

type LINK_STATE = {
  selected_item: any;
  uv: any;
  folder_id: number;
  parent_id: number;
  selected_type: number;
  setSelected?: (payload: {
    item: any;
    uv: any;
    folder_id: number;
    parent_id: number;
    type: number;
  }) => void;
};

const INITIAL_STATE: LINK_STATE = {
  selected_item: null,
  uv: null,
  folder_id: null,
  parent_id: null,
  selected_type: null,
};
const SelectedLinkedContext = createContext<LINK_STATE>({
  ...INITIAL_STATE,
  setSelected: (payload: {
    item: any;
    uv: any;
    folder_id: number;
    parent_id: number;
    type: number;
  }) => { appConsoleLog(payload); },
});

export const useSelectedLinkedContext = () => useContext(SelectedLinkedContext);

const SelectedLinkReducer = (state: LINK_STATE, action: {type: string; payload: any}) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SELECT:
      return {
        ...state,
        selected_item: payload.item,
        folder_id: payload.folder_id,
        parent_id: payload.parent_id,
        selected_type: payload.type,
        uv: payload.uv,
      };
    default:
      return state;
  }
};

const Selectable: React.FC<any> = ({ children, ...rest }) => {
  const [state, dispatch] = useReducer(SelectedLinkReducer, INITIAL_STATE);

  const setSelected = (payload: {
    item: any;
    uv: any;
    folder_id: number;
    parent_id: number;
    type: SELECTABLE_TYPES;
  }) => {
    dispatch({
      type: ACTION_TYPES.SELECT,
      payload,
    });
  };

  const values = useMemo(
    () => ({
      ...state,
      setSelected,
    }),
    [setSelected, state],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SelectedLinkedContext.Provider value={values} {...rest}>
      {children}
    </SelectedLinkedContext.Provider>
  );
};
Selectable.propTypes = {
  children: PropsTypes.oneOfType([
    PropsTypes.node,
    PropsTypes.arrayOf(PropsTypes.node),
  ]),
};

Selectable.defaultProps = {
  children: <></>,
};

export const SelectedPreview: React.FC<any> = () => {
  const selected = useSelectedLinkedContext();

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5%',
      }}
    >
      {selected.selected_item ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2>
            {/* eslint-disable-next-line no-nested-ternary */}
            {selected.selected_type === 1
              ? 'Link this item'
              // eslint-disable-next-line no-nested-ternary
              : selected.selected_type === 2
                ? 'Link this folder'
                : selected.selected_type === 8
                  ? "Link this 'other' item"
                  : 'Link this userview'}
          </h2>
          <span
            className={`calc ${selected.selected_item.icon_name}`}
            style={{
              // margin: "5px",
              fontSize: '5rem',
              color: 'white',
              background: 'var(--light-green)',
              padding: '4%',
              borderRadius: '5px',
            }}
          />
          <h4>{selected.selected_item.display_name}</h4>
        </div>
      ) : (
        <div>Not Selected</div>
      )}
    </div>
  );
};

export default Selectable;
